import { GoogleCalendarSettings } from '../../google-calendar/components/GoogleCalendarSettings';
import React from 'react';
import { OutlookSettings } from '../../outlook/components/OutlookSettings';
import useAuth from '../../auth/authContext';
import { PleaseRelogin } from '../../auth/PleaseRelogin';
import { isGoogleCalendarAvailable, isOutlookAvailable } from '../util';
import useAnalyticsPage from '../../web-analytics/hooks/useAnalyticsPage';
import { Page } from '../../web-analytics/Page';

export const PersonalIntegrations = () => {
  useAnalyticsPage(Page.PERSONAL_INTEGRATIONS);
  const {
    state: { user },
  } = useAuth();
  if (!user) return <PleaseRelogin />;

  return (
    <div>
      <div className="personal-integrations__container">
        {isGoogleCalendarAvailable(user) && <GoogleCalendarSettings userId={user.userId} />}
        {isOutlookAvailable(user) && <OutlookSettings userId={user.userId} />}
      </div>
    </div>
  );
};
