import React, { ChangeEvent } from 'react';
import { CheckboxCheckedIcon, CheckboxUncheckedIcon } from '../../assets/icons';
import clsx from 'clsx';

export const GrowegyCheckbox = ({
  checked,
  defaultChecked,
  text,
  dataTestPrefix,
  disabled,
  checkedClassName,
  onChange,
}: {
  checked?: boolean;
  defaultChecked?: boolean;
  text?: string;
  dataTestPrefix: string;
  disabled?: boolean;
  checkedClassName?: string;
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
}) => (
  <label className="growegy-checkbox__label" data-test={`${dataTestPrefix}__label`}>
    <input
      className="growegy-checkbox"
      type="checkbox"
      defaultChecked={defaultChecked}
      onChange={onChange}
      checked={checked}
      disabled={disabled}
    />
    <div className="growegy-checkbox__icons">
      <CheckboxCheckedIcon
        data-test={`${dataTestPrefix}__icon--checked`}
        className={clsx('growegy-checkbox__icon--checked', checkedClassName)}
      />
      <CheckboxUncheckedIcon
        data-test={`${dataTestPrefix}__icon--unchecked`}
        className="growegy-checkbox__icon--unchecked"
      />
    </div>
    {text && <div>{text}</div>}
  </label>
);
