import { useCallback, useEffect, useMemo, useState } from 'react';
import _ from 'lodash';
import 'react-datepicker/dist/react-datepicker.css';
import { useMutation } from '@tanstack/react-query';

import useApi from '../../api/backendApiContext';
import {
  CreateProgramTaskChange,
  DeleteProgramTaskChange,
  getProgramStatus,
  NoProgramType,
  Program,
  ProgramAttachment,
  ProgramDialogAction,
  ProgramSummary,
  ProgramTask,
  ProgramType,
  ResolvedCampaign,
  ResolvedProgram,
  ResolvedProgramTask,
  UpdateProgramTaskChange,
} from '../types';
// eslint-disable-next-line import/no-cycle
import {
  ProgramEditDeepLink,
  ProgramEditModal,
  ProgramEditModalProps,
} from '../components/ProgramEditModal';
import { useQueryCacheManager } from '../../hooks/useQueryCacheManager';
import { useResolvedProgramOnceForDialog } from '../hooks/useResolvedProgramOnceForDialog';
import { useNotification } from '../../notification/notificationsContext';
import { prepareFormulasToHuman } from '../formulaUtil';
import { createProgramCopy, createProgramFromTask, resolveProgramOwner } from '../util';
import { isUnscheduled, unscheduledEnd, unscheduledStart } from '../unscheduledProgramUtil';
import useAnalyticsPage from '../../web-analytics/hooks/useAnalyticsPage';
import { Page } from '../../web-analytics/Page';
import useAnalytics from '../../web-analytics/webAnalyticsContext';
import { analyticsTrack } from '../../web-analytics/webAnalytics';
import { AnalyticsEvent } from '../../web-analytics/AnalyticsEvent';
import { v4 as uuidv4 } from 'uuid';
import { useMemoCompare } from '../../hooks/useMemoCompare';
import { AppRoutesHelper } from '../../router/AppRoutes';
import { useNavigate } from 'react-router-dom';
import { hasValue } from '../../util/utils';
import { useProgramPermissionHelper } from '../programPermissionHelper';
import useAuth from '../../auth/authContext';
import { NoProgramOwner } from '../../user/types';

export type ModalState = {
  program: ProgramSummary;
  action: 'UPDATE' | 'CREATE' | 'TASK_TO_PROGRAM';
  scrollToTaskId: string | null;
  taskToDeleteOnCreate: TaskKey | null;
  key?: string;
} | null;

export type InternalProps = {
  action: ProgramDialogAction;
  programSummary: ProgramSummary;
  taskToDeleteOnCreate: TaskKey | null;
};

export type TaskKey = {
  programId: string;
  taskId: string;
  version: number;
};

type ProgramDialogProps = {
  action: ProgramDialogAction;
  programSummary: ProgramSummary;
  scrollToTaskId: string | null;
  onHide: () => void;
  onProgramCreated?: (program: ResolvedProgram) => void;
  onProgramUpdated?: (program: ResolvedProgram) => void;
  onProgramDeleted?: (programId: string) => void;
  isDeepLink?: boolean;
  taskToDeleteOnCreate?: TaskKey;
};

const getPage = (action: ProgramDialogAction) => {
  switch (action) {
    case 'CREATE':
      return Page.PROGRAM_CREATE;
    case 'COPY':
      return Page.PROGRAM_COPY;
    case 'TASK_TO_PROGRAM':
      return Page.TASK_TO_PROGRAM;
    case 'UPDATE':
    default:
      return Page.PROGRAM_UPDATE;
  }
};

const ProgramDialog = (props: ProgramDialogProps) => {
  const {
    state: { user },
  } = useAuth();

  const { programApi } = useApi();
  const programCache = useQueryCacheManager();
  const { notifySuccess, notifyError } = useNotification();
  const {
    action: originAction,
    programSummary: originSummary,
    onHide,
    scrollToTaskId,
    isDeepLink,
    onProgramCreated,
    onProgramUpdated,
    onProgramDeleted,
    taskToDeleteOnCreate: origTaskToDeleteOnCreate,
  } = props;
  const [internalProps, setInternalProps] = useState<InternalProps>({
    action: originAction,
    programSummary: originSummary,
    taskToDeleteOnCreate: origTaskToDeleteOnCreate ?? null,
  });

  useEffect(() => {
    if (isDeepLink) {
      setInternalProps({
        action: originAction,
        programSummary: originSummary,
        taskToDeleteOnCreate: origTaskToDeleteOnCreate ?? null,
      });
    }
  }, [isDeepLink, originAction, originSummary, origTaskToDeleteOnCreate]);

  const { action, programSummary, taskToDeleteOnCreate } = internalProps;
  useAnalyticsPage(getPage(action));
  const analytics = useAnalytics();

  const {
    program: { error: programError, data: programData, status: programStatus },
    attachments: { error: attachmentsError, data: attachmentsData, status: attachmentsStatus },
    programType: { error: programTypeError, data: programTypeData, status: programTypeStatus },
    programUsers: { error: programUsersError, data: programUsersData, status: programUsersStatus },
    programTypes,
    campaign: { error: campaignError, data: campaignData, status: campaignStatus },
    refetch,
  } = useResolvedProgramOnceForDialog({ summary: programSummary });

  const reRenderProgramDependencies = useMemoCompare(
    {
      programDataRefreshed: programData,
      attachmentsDataRefreshed: attachmentsData,
      programTypeDataRefreshed: programTypeData,
      programUsersDataRefreshed: programUsersData,
      campaignDataRefreshed: campaignData,
    },
    (a, b) => _.isEqual(a, b)
  );

  const initialValues: ResolvedProgram = useMemo(() => {
    const {
      programDataRefreshed,
      attachmentsDataRefreshed,
      programTypeDataRefreshed,
      programUsersDataRefreshed,
      campaignDataRefreshed,
    } = reRenderProgramDependencies;
    const p = _.omit(programDataRefreshed, 'typeId', 'tasks', 'campaignId');
    const resolvedTasks = programDataRefreshed.tasks.map((t, i) => {
      const taskOwner = resolveProgramOwner(t, programUsersDataRefreshed);
      const resolvedTask: ResolvedProgramTask = { ...t, owner: taskOwner, orderId: i };
      return resolvedTask;
    });
    return {
      ...p,
      attachments: [...attachmentsDataRefreshed],
      type: programTypeDataRefreshed,
      owner: resolveProgramOwner(programDataRefreshed, programUsersDataRefreshed),
      resolvedTasks,
      campaign: campaignDataRefreshed,
      status: getProgramStatus(p.status),
      customFields: p.customFields.map((x) => ({ ...x, localId: uuidv4() })),
    };
  }, [reRenderProgramDependencies]);
  const programPermissionHelper = useProgramPermissionHelper(initialValues);

  useEffect(() => {
    if (programError && programStatus === 'error') {
      notifyError({
        err: programError,
        logMsg: `Failed to load program ${JSON.stringify(programSummary)} from backend`,
        notificationMsg: `Failed to load program`,
      });
    }
  }, [notifyError, programError, programStatus, programSummary]);

  useEffect(() => {
    if (programTypeError && programTypeStatus === 'error') {
      notifyError({
        err: programTypeError,
        logMsg: `Failed to load program type for ${JSON.stringify(programSummary)} from backend`,
        notificationMsg: `Failed to load program type`,
      });
    }
  }, [notifyError, programSummary, programTypeError, programTypeStatus]);

  useEffect(() => {
    if (programUsersError && programUsersStatus === 'error') {
      notifyError({
        err: programUsersError,
        logMsg: `Failed to load program owner for ${JSON.stringify(programSummary)} from backend`,
        notificationMsg: `Failed to load program owner`,
      });
    }
  }, [notifyError, programUsersError, programUsersStatus, programSummary]);

  useEffect(() => {
    if (attachmentsError && attachmentsStatus === 'error') {
      notifyError({
        err: attachmentsError,
        logMsg: `Failed to load program attachments for ${JSON.stringify(
          programSummary
        )} from backend`,
        notificationMsg: `Failed to load attachments`,
      });
    }
  }, [attachmentsError, attachmentsStatus, notifyError, programSummary]);

  useEffect(() => {
    if (campaignError && campaignStatus === 'error') {
      notifyError({
        err: campaignError,
        logMsg: `Failed to load campaign for ${JSON.stringify(programSummary)} from backend`,
        notificationMsg: `Failed to load campaign`,
      });
    }
  }, [notifyError, programSummary, campaignError, campaignStatus]);

  const [showModal, setShowModal] = useState(!isDeepLink);

  const addDeleteProgramAttachments = async (
    idProgram: string,
    attachments: ProgramAttachment[]
  ) => {
    /* eslint-disable no-await-in-loop */
    for (const item of attachments) {
      switch (item.status) {
        case 'NEW': {
          if (item.file) {
            await programApi.addProgramAttachment(idProgram, item.file).then(() => {
              item.status = 'EXISTED';
            });
            analyticsTrack(analytics, AnalyticsEvent.PROGRAM_ATTACHMENT_ADDED);
          }
          break;
        }
        case 'DELETED': {
          await programApi.deleteProgramAttachment(idProgram, item.id, item.name);
          analyticsTrack(analytics, AnalyticsEvent.PROGRAM_ATTACHMENT_DELETED);
          break;
        }
        default: {
          break;
        }
      }
    }
  };

  const getType = (type: ProgramType) =>
    type.id === NoProgramType.id && programTypes ? programTypes.types[0] : type;

  const createProgram = async (change: ResolvedProgram) => {
    const type = getType(change.type);
    const ownerId = change.owner ? change.owner.id : null;
    const owningUserId = ownerId === NoProgramOwner.id ? null : ownerId;
    const status = change.status.id;
    const tasks = [...change.resolvedTasks];
    const { createdProgram, affectedCampaign } = await programApi.createProgram({
      ...change,
      typeId: type.id,
      owningUserId,
      status,
      tasks,
      campaignId: change.campaign ? change.campaign.id : null,
    });
    analyticsTrack(analytics, AnalyticsEvent.PROGRAM_CREATED, { count: 1 });
    await addDeleteProgramAttachments(createdProgram.id, change.attachments);

    const resolvedCreatedProgram = {
      ...change,
      id: createdProgram.id,
      type,
      resolvedTasks: createdProgram.tasks.map((t, i) => ({
        ...t,
        owner: resolveProgramOwner(t, reRenderProgramDependencies.programUsersDataRefreshed),
        orderId: i,
      })),
    };
    onProgramCreated?.({ ...resolvedCreatedProgram });
    return { createdProgram, affectedCampaign };
  };

  const {
    mutateAsync: createMutation,
    isLoading: createInProgress,
    error: createError,
  } = useMutation(async (change: ResolvedProgram) => createProgram(change), {
    onSuccess: async ({ createdProgram, affectedCampaign }) => {
      const p = isUnscheduled(createdProgram)
        ? {
            ...createdProgram,
            startDateTime: unscheduledStart(createdProgram.id),
            endDateTime: unscheduledEnd(createdProgram.id),
          }
        : createdProgram;
      if (p.programKind === 'PROGRAM') await programCache.onCreate(p.id, p);
      else programCache.onCampaignCreate(p);

      if (affectedCampaign) programCache.onCampaignUpdate(affectedCampaign);
    },
  });

  const updateProgram = async (change: ResolvedProgram) => {
    const type = getType(change.type);
    const ownerId = change.owner ? change.owner.id : null;
    const owningUserId = ownerId === NoProgramOwner.id ? null : ownerId;
    const tasks = [...change.resolvedTasks];
    const program = {
      ...change,
      typeId: type.id,
      owningUserId,
      status: change.status.id,
      tasks,
      campaignId: change.campaign ? change.campaign.id : null,
    };
    const taskIdsAfterChange = new Set(tasks.map((t) => t.id).filter(hasValue));
    const originalTasks = programSummary.tasks;
    const deletedTasks = originalTasks.filter((t) => t.id && !taskIdsAfterChange.has(t.id));
    const deletedTaskChanges = deletedTasks
      .filter((x) => !!x.id)
      .map(
        (t): DeleteProgramTaskChange => ({
          changeType: 'DELETE' as const,
          taskId: t.id!,
        })
      );
    const updatedOrCreatedTaskChanges = tasks
      .map((t, i) => ({ ...t, orderId: i }))
      .filter((t) => programPermissionHelper.canEditRegularTaskField(t))
      .map((t): CreateProgramTaskChange | UpdateProgramTaskChange => ({
        changeType: !!t.id ? ('UPDATE' as const) : ('CREATE' as const),
        orderId: t.orderId,
        task: t,
      }));
    const [patchProgramResponse] = await Promise.all([
      programApi.patchProgram(
        program.id,
        program.version!!,
        programPermissionHelper.canEditRegularField() ? program : null,
        [...deletedTaskChanges, ...updatedOrCreatedTaskChanges]
      ),
      addDeleteProgramAttachments(change.id, change.attachments),
    ]);
    const updatedProgram = patchProgramResponse.changedProgram;
    analyticsTrack(analytics, AnalyticsEvent.PROGRAM_UPDATED);
    const updatedResolvedProgram = {
      ...change,
      typeId: type.id,
      owningUserId,
      tasks: updatedProgram.tasks,
      resolvedTasks: updatedProgram.tasks.map((t, i) => ({
        ...t,
        owner: resolveProgramOwner(t, reRenderProgramDependencies.programUsersDataRefreshed),
        orderId: i,
      })),
      campaignId: change.campaign ? change.campaign.id : null,
      version: change.version === undefined ? 1 : change.version + 1,
    };
    onProgramUpdated?.({
      ...updatedResolvedProgram,
      type,
    });
    return patchProgramResponse;
  };

  const {
    isLoading: updateInProgress,
    error: updateError,
    mutateAsync: updateMutation,
  } = useMutation(async (change: ResolvedProgram) => updateProgram(change), {
    // eslint-disable-next-line no-unused-vars,@typescript-eslint/no-unused-vars
    onSuccess: ({ changedProgram, oldParentCampaign, parentCampaign }) => {
      if (oldParentCampaign) programCache.onCampaignUpdate(oldParentCampaign);
      if (parentCampaign) programCache.onCampaignUpdate(parentCampaign);
      programCache.updateOptimistically({ ...programData }, changedProgram);
    },
    onError: async () => programCache.onUpdateError(programData.id),
  });

  const {
    isLoading: deleteInProgress,
    error: deleteError,
    mutateAsync: deleteProgramMutation,
  } = useMutation(async () => programApi.deleteProgram(programData.id), {
    async onSuccess(parentCampaign) {
      analyticsTrack(analytics, AnalyticsEvent.PROGRAM_DELETED);
      await programCache.onDeleteSuccess(programData);
      if (parentCampaign) programCache.onCampaignUpdate(parentCampaign);
    },
    onError: async () => programCache.onDeleteError(programData.id),
  });

  const { mutate: deleteTaskMutation } = useMutation(
    async (taskKey: TaskKey) =>
      programApi.patchProgram(taskKey.programId, taskKey.version, null, [
        { changeType: 'DELETE', taskId: taskKey.taskId },
      ]),
    {
      onSuccess: ({ changedProgram, oldParentCampaign, parentCampaign }) => {
        if (oldParentCampaign) programCache.onCampaignUpdate(oldParentCampaign);
        if (parentCampaign) programCache.onCampaignUpdate(parentCampaign);
        programCache.updateOptimistically(changedProgram, changedProgram);
      },
      onError: async (_, taskKey) => {
        await programCache.onUpdateError(taskKey.programId);
      },
    }
  );

  const disabled: boolean =
    !!programError ||
    !!programTypeError ||
    !!attachmentsError ||
    programStatus !== 'success' ||
    programTypeStatus !== 'success' ||
    attachmentsStatus !== 'success' ||
    updateInProgress ||
    deleteInProgress ||
    createInProgress;

  const handleHideClick: () => void = useCallback(() => {
    setShowModal(false);
    onHide();
  }, [onHide]);

  const navigate = useNavigate();

  const handleApplyClick: (p: ResolvedProgram) => Promise<void> = useCallback(
    async (p: ResolvedProgram) => {
      try {
        const customFields = prepareFormulasToHuman(p);
        const change: ResolvedProgram = { ...p, customFields };
        const mutationResponse:
          | { affectedCampaign: Program | null; createdProgram: Program; action: 'create' }
          | {
              parentCampaign: Program | null;
              oldParentCampaign: Program | null;
              changedProgram: Program;
              action: 'update';
            } =
          action === 'CREATE' || action === 'COPY' || action === 'TASK_TO_PROGRAM'
            ? await createMutation(change).then((r) => ({ ...r, action: 'create' }))
            : await updateMutation(change).then((r) => ({ ...r, action: 'update' }));
        if (action === 'TASK_TO_PROGRAM' && taskToDeleteOnCreate) {
          await deleteTaskMutation(taskToDeleteOnCreate);
        }
        notifySuccess({
          notificationMsg: `${p.programKind === 'CAMPAIGN' ? 'Campaign' : 'Program'} is ${
            action === 'UPDATE' ? 'updated' : 'created'
          }.`,
          logMsg: `Program ${change.name} is ${action === 'UPDATE' ? 'updated' : 'created'}.`,
        });

        if (isDeepLink) {
          if (mutationResponse.action === 'update') await refetch();
          else {
            const newDeepLink = AppRoutesHelper.getProgramDeepLinkRoute(
              mutationResponse.createdProgram.id
            );
            navigate(newDeepLink);
          }
        } else {
          setShowModal(false);
          onHide();
        }
      } catch (err) {
        notifyError({
          err,
          logMsg: `Program dialog failed to ${action.toLowerCase()} program ${JSON.stringify(
            programSummary
          )}`,
          notificationMsg: `Failed to ${action.toLowerCase()} program.`,
        });
      }
    },
    [
      action,
      createMutation,
      notifyError,
      notifySuccess,
      onHide,
      programSummary,
      refetch,
      isDeepLink,
      updateMutation,
      deleteTaskMutation,
      navigate,
      taskToDeleteOnCreate,
    ]
  );

  const handleDeleteClick: () => Promise<void> = useCallback(async () => {
    try {
      await deleteProgramMutation();
      onProgramDeleted?.(programData.id);
      notifySuccess({
        notificationMsg: `${
          programData.programKind === 'CAMPAIGN' ? 'Campaign' : 'Program'
        } is deleted.`,
        logMsg: `Program ${programData.name} is deleted.`,
      });
      setShowModal(false);
      onHide();
    } catch (err) {
      notifyError({
        err,
        logMsg: `Program dialog failed to delete program ${JSON.stringify(programSummary)}`,
        notificationMsg: `Failed to delete program`,
      });
    }
  }, [
    deleteProgramMutation,
    notifyError,
    notifySuccess,
    onHide,
    programData.name,
    programData.programKind,
    programSummary,
    onProgramDeleted,
    programData.id,
  ]);

  const handleCopyClick = useCallback(
    (copyCampaign: boolean) => {
      if (internalProps.action === 'CREATE' || internalProps.action === 'COPY') return;
      setInternalProps(() => ({
        action: 'COPY',
        programSummary: createProgramCopy(programData, copyCampaign),
        taskToDeleteOnCreate: null,
      }));
    },
    [programData, internalProps.action]
  );

  const handleConvertTaskToProgramClick = useCallback(
    (task: ProgramTask, copyCampaign: boolean) => {
      if (internalProps.action === 'CREATE' || internalProps.action === 'COPY') return;
      setInternalProps(() => ({
        action: 'TASK_TO_PROGRAM',
        programSummary: createProgramFromTask(
          programData,
          task,
          user !== null ? user.userId : null,
          copyCampaign
        ),
        taskToDeleteOnCreate:
          task.id !== null && programData.version !== undefined
            ? {
                programId: programData.id,
                version: programData.version,
                taskId: task.id,
              }
            : null,
      }));
    },
    [programData, internalProps.action, user]
  );

  const handleShowCampaignClick = (campaign: ResolvedCampaign) =>
    setInternalProps({
      action: 'UPDATE',
      programSummary: {
        ..._.cloneDeep(campaign),
        typeId: campaign.type.id,
        campaignId: null,
        status: campaign.status.id,
        tasks: [],
      },
      taskToDeleteOnCreate: null,
    });

  const programEditModalProps: ProgramEditModalProps = useMemo(
    () => ({
      showModal,
      disabled,
      initialProgram: initialValues,
      programTypes: programTypes?.types ?? [],
      createError,
      updateError,
      deleteError,
      handleHideClick,
      handleApplyClick,
      handleRefetchClick: refetch,
      handleDeleteClick,
      handleCopyClick,
      handleConvertTaskToProgramClick,
      handleShowCampaignClick,
      action,
      scrollToTaskId,
    }),
    [
      showModal,
      disabled,
      initialValues,
      programTypes,
      createError,
      updateError,
      deleteError,
      handleHideClick,
      handleApplyClick,
      refetch,
      handleDeleteClick,
      handleCopyClick,
      handleConvertTaskToProgramClick,
      action,
      scrollToTaskId,
    ]
  );

  return isDeepLink ? (
    <ProgramEditDeepLink {...programEditModalProps} />
  ) : (
    <ProgramEditModal {...programEditModalProps} />
  );
};

export default ProgramDialog;
