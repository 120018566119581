import useApi from '../../../api/backendApiContext';
import { useQuery } from '@tanstack/react-query';
import { useQueryCacheManager } from '../../../hooks/useQueryCacheManager';
import useAuth from '../../../auth/authContext';
import { ReactQueryConstants } from '../../../commons/constants';
import { QueryStatus } from '../../../commons/components/QueryStatus';
import _ from 'lodash';
import {
  FALLBACK_CATEGORY_COLOR,
  PREDEFINED_PROMPT_CATEGORIES,
  Prompt,
  PromptCategory,
} from '../../types';
import { comparePromptCategories } from '../../util';

export const useFetchedPrompts = () => {
  const { growegyAIApi } = useApi();
  const {
    state: { user },
  } = useAuth();
  const queryCacheManager = useQueryCacheManager();
  const queryKey = queryCacheManager.getPromptsCacheKey();
  const query = useQuery(
    queryKey,
    async () => {
      if (!user) {
        return { prompts: [] as Prompt[], categories: [] as PromptCategory[] };
      }
      const prompts = await growegyAIApi.getPrompts(user.userId);
      const categories: PromptCategory[] = _.uniq(prompts.flatMap((x) => x.categories)).map((x) => {
        const predefinedCategory = PREDEFINED_PROMPT_CATEGORIES.find((ppc) =>
          comparePromptCategories(ppc.name, x)
        );
        return predefinedCategory ?? { name: x, color: FALLBACK_CATEGORY_COLOR, icon: null };
      });
      return { prompts, categories };
    },
    {
      staleTime: ReactQueryConstants.promptsStaleTime,
      refetchInterval: ReactQueryConstants.promptsRefetchTime,
      refetchOnWindowFocus: true,
    }
  );
  const queryStatusComponent = (
    <QueryStatus
      queryKey={queryKey}
      queryStatus={query.status}
      dataTestPrefix="chats"
      failedToLoadText="Something went wrong while loading the prompts library."
    />
  );

  return { query, queryStatusComponent };
};
