import { useQuery } from '@tanstack/react-query';
import { UsersCacheKey } from '../../hooks/useQueryCacheManager';
import useApi from '../../api/backendApiContext';
import { ReactQueryConstants } from '../../commons/constants';

export const useFetchedUsers = () => {
  const { userApi } = useApi();
  return useQuery(UsersCacheKey, async () => userApi.getUsers(), {
    staleTime: ReactQueryConstants.usersStaleTime,
    refetchOnWindowFocus: false,
  });
};
