import { CloseIcon, PaperPlaneIcon } from '../../../assets/icons';
import React, { useCallback, useEffect, useRef } from 'react';
import { Button } from 'react-bootstrap';
import { Tooltip } from 'react-tooltip';
import useAnalytics from '../../../web-analytics/webAnalyticsContext';
import { analyticsTrack } from '../../../web-analytics/webAnalytics';
import { AnalyticsEvent } from '../../../web-analytics/AnalyticsEvent';
import { MutationStatus } from '@tanstack/react-query';
import { MAX_CHAT_MESSAGE_LENGTH, PromptVariableStringValue } from '../../types';
import { Mention, MentionsInput, SuggestionDataItem } from 'react-mentions';
import { useFetchedPromptVariables } from '../../variables/hooks/useFetchedPromptVariables';
import { variableValueToContent } from '../../util';
import { ChatLocalData } from '../chatContext';
import clsx from 'clsx';

const VARIABLE_NAME_VALUE_SEPARATOR = ' / ';
const decimalFormatter = new Intl.NumberFormat('en-US');
export type ComplexContent = Pick<ChatLocalData, 'rawContent' | 'plainTextContent'>;
export const stringToComplexContent = (content: string): ComplexContent => ({
  rawContent: content,
  plainTextContent: content,
});

type VariableSuggestionDataItem = SuggestionDataItem & { name: string; value: string };

export const TextMessageInput = (props: {
  chatId: string;
  complexContent: ComplexContent;
  placeholder?: string;
  tooManyMessages: boolean;
  maxAiResponses: number;
  sendMessageStatus: MutationStatus;
  cancelMessageStatus: MutationStatus;
  onChange: (complexContent: ComplexContent) => void;
  onSend: () => void;
  onCancelSending: () => void;
  onManageVariablesClick?: () => void;
}) => {
  const analytics = useAnalytics();
  const {
    chatId,
    complexContent,
    placeholder,
    tooManyMessages,
    maxAiResponses,
    sendMessageStatus,
    cancelMessageStatus,
    onChange,
    onSend,
    onCancelSending,
    onManageVariablesClick,
  } = props;
  const textAreaRef = useRef<HTMLTextAreaElement | null>(null);
  const sendMessage = useCallback(() => {
    if (tooManyMessages) return;
    onSend();
    textAreaRef.current?.focus();
    analyticsTrack(analytics, AnalyticsEvent.CHAT_MESSAGE_SENT);
  }, [onSend, tooManyMessages, analytics]);
  const cancelSendMessage = useCallback(() => {
    onCancelSending();
    textAreaRef.current?.focus();
    analyticsTrack(analytics, AnalyticsEvent.CHAT_MESSAGE_CANCELLED);
  }, [onCancelSending, analytics]);
  useEffect(() => {
    textAreaRef.current?.focus();
  }, [chatId]);

  const isMessageSending = sendMessageStatus === 'loading';

  const contentIsTooLong = complexContent.plainTextContent.length > MAX_CHAT_MESSAGE_LENGTH;
  const sendButtonTooltip = tooManyMessages
    ? `Each individual chat is restricted to a maximum of ${maxAiResponses} messages. In order to continue with Growegy AI, please create a new chat.`
    : contentIsTooLong
    ? `Prompt is too long: ${decimalFormatter.format(
        complexContent.plainTextContent.length
      )} characters. Max prompt length is ${decimalFormatter.format(
        MAX_CHAT_MESSAGE_LENGTH
      )} characters.`
    : null;

  const {
    query: { data: variables },
  } = useFetchedPromptVariables();

  const stringVariableToSuggestionDataItem = (
    variableName: string,
    variableId: string | null,
    variable: PromptVariableStringValue,
    i: number
  ): VariableSuggestionDataItem => {
    const value = `${variableValueToContent(variable)}`;
    return {
      id: `${variableId}_${i}`,
      name: variableName,
      value,
      display: `${variableName}${VARIABLE_NAME_VALUE_SEPARATOR}${value}`,
    };
  };

  return (
    <div className="message-input__relative-container">
      <div className="message-input__absolute-container">
        <div
          className="message-input__wrapper"
          data-tooltip-id="message-input__send-button-tooltip"
          data-tooltip-place="top"
        >
          <MentionsInput
            disabled={tooManyMessages}
            singleLine={false}
            aria-multiline={true}
            data-test="message-input__text-box"
            value={complexContent.rawContent}
            placeholder={placeholder ?? `Enter a prompt here…`}
            allowSpaceInQuery={true}
            forceSuggestionsAboveCursor={true}
            className="message-input-mentions"
            maxLength={MAX_CHAT_MESSAGE_LENGTH * 2}
            onChange={(event, newValue, newPlainTextValue) => {
              onChange({ rawContent: newValue, plainTextContent: newPlainTextValue });
            }}
            onBlur={(e, clickedSuggestion) => {
              if (clickedSuggestion) e.stopPropagation();
            }}
            onKeyDown={(e) => {
              if (e.key === 'Enter' && !e.shiftKey) {
                e.preventDefault();
                e.stopPropagation();
                if (!isMessageSending && complexContent.plainTextContent) sendMessage();
              }
            }}
            customSuggestionsContainer={(children) => (
              <div
                className={clsx('message-input-mentions__suggestions-wrapper', {
                  'message-input-mentions__suggestions-wrapper--no-manage-variables':
                    !onManageVariablesClick,
                })}
              >
                {onManageVariablesClick && (
                  <Button
                    variant="primary"
                    onClick={onManageVariablesClick}
                    className="message-input-mentions__manage-variables-button"
                    data-test="message-input-mentions__manage-variables-button"
                  >
                    Manage variables
                  </Button>
                )}
                <div className="message-input-mentions__mentions-container">{children}</div>
              </div>
            )}
            inputRef={textAreaRef}
          >
            <Mention
              trigger="["
              data={
                variables
                  ? variables
                      .flatMap((x): SuggestionDataItem[] => {
                        return x.variableValue.type === 'list'
                          ? x.variableValue.values.map((v, i) =>
                              stringVariableToSuggestionDataItem(x.variableValue.name, x.id, v, i)
                            )
                          : [
                              stringVariableToSuggestionDataItem(
                                x.variableValue.name,
                                x.id,
                                x.variableValue,
                                0
                              ),
                            ];
                      })
                      .sort((a, b) => {
                        const aVariable = a as VariableSuggestionDataItem;
                        const bVariable = b as VariableSuggestionDataItem;
                        if (aVariable.name !== bVariable.name)
                          return aVariable.name.localeCompare(bVariable.name);
                        return aVariable.value.localeCompare(bVariable.value);
                      })
                  : []
              }
              appendSpaceOnAdd={true}
              renderSuggestion={(suggestion) => {
                const variableSuggestion = suggestion as VariableSuggestionDataItem;
                return (
                  <div className="d-flex flex-row">
                    <div
                      className="message-input-mentions__mention-variable-name"
                      data-test="message-input-mentions__mention-variable-name"
                    >
                      {variableSuggestion.name}
                    </div>
                    <div
                      className="message-input-mentions__mention-variable-value"
                      data-test="message-input-mentions__mention-variable-value"
                    >
                      {variableSuggestion.value}
                    </div>
                  </div>
                );
              }}
              displayTransform={(id, display) => {
                return display.indexOf(VARIABLE_NAME_VALUE_SEPARATOR) >= 0
                  ? display.substring(
                      display.indexOf(VARIABLE_NAME_VALUE_SEPARATOR) +
                        VARIABLE_NAME_VALUE_SEPARATOR.length
                    )
                  : display;
              }}
              className="message-input-mentions__mention-text"
            />
          </MentionsInput>
          {isMessageSending ? (
            <Button
              type="button"
              className="message-input__send-button"
              onClick={cancelSendMessage}
              data-test="message-input__cancel-send-button"
              disabled={cancelMessageStatus === 'loading'}
            >
              <CloseIcon />
            </Button>
          ) : (
            <Button
              type="button"
              className="message-input__send-button"
              onClick={sendMessage}
              data-test="message-input__send-button"
              disabled={!complexContent.plainTextContent || contentIsTooLong}
            >
              <PaperPlaneIcon />
            </Button>
          )}
        </div>

        <div
          className={clsx('message-input__variable-help', {
            invisible: complexContent.plainTextContent.length === 0,
          })}
          data-test="message-input__variable-help"
        >
          To insert a variable, start typing ‘[’ and choose from the list of variables
        </div>
      </div>
      {sendButtonTooltip && (
        <Tooltip
          id="message-input__send-button-tooltip"
          className="info-button__tooltip"
          noArrow={true}
          place="top"
          positionStrategy="absolute"
        >
          <div className="info-button__tooltip-content">{sendButtonTooltip}</div>
        </Tooltip>
      )}
    </div>
  );
};
