import { Button, ButtonGroup } from 'react-bootstrap';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import Dropzone, { ErrorCode, FileRejection } from 'react-dropzone';
import useApi from '../api/backendApiContext';
import { errorToString, extractBackendError, getHttpErrorCode } from '../util/utils';
import { useFetchedUserSettings } from './hooks/useFetchedUserSettings';
import { extractUserInitials } from '../user/utils';
import useAuth from '../auth/authContext';
import { useQueryCacheManager } from '../hooks/useQueryCacheManager';
import { useFetchedUserAvatar } from './hooks/useFetchedUserAvatar';
import { useNotification } from '../notification/notificationsContext';
import { useCallback } from 'react';
import { hasErrorOfType } from '../util/file-utils';
import { useCurrentUser } from '../user/hooks/useCurrentUser';

const MAX_SIZE_KB = 500;
const MAX_SIZE = MAX_SIZE_KB * 1024;
const TOO_LARGE_FILE_ERROR_MSG = `The file is too big. The maximum size is ${MAX_SIZE_KB} KB.`;

const UserInitials = (props: { size: number; initials: string }) => (
  <div
    className="user-avatar__initials"
    style={{ width: `${props.size}px`, height: `${props.size}px` }}
  >
    <span
      className="user-avatar__initials-label"
      style={{ fontSize: `${props.size / 2.5}px` }}
      data-test={`user-avatar__avatar-default--${props.initials}`}
    >
      {props.initials}
    </span>
  </div>
);

const UserAvatar = (props: { userId: string; size: number; editMode: boolean }) => {
  const { userId, size, editMode } = props;
  const { userApi } = useApi();
  const {
    state: { user },
  } = useAuth();
  const queryClient = useQueryClient();
  const cacheManager = useQueryCacheManager();
  const { notifyError } = useNotification();

  const queryAvatar = useFetchedUserAvatar(userId);
  const userSettings = useFetchedUserSettings(userId);
  const currentUser = useCurrentUser();

  const { mutateAsync: deleteAvatarMutation } = useMutation(async () =>
    userApi.deleteAvatar(userId)
  );
  const { mutateAsync: changeAvatarMutation } = useMutation(
    async (file: File) => userApi.changeAvatar(userId, file),
    {
      onError: (err) => {
        const errMsg = errorToString(err);
        const errCode = getHttpErrorCode(err);
        const notificationMsg = errCode === 413 ? TOO_LARGE_FILE_ERROR_MSG : errMsg;
        notifyError({
          err,
          notificationMsg,
          logMsg: `Failed to change avatar ${extractBackendError(err)}`,
        });
      },
    }
  );

  const onAttachmentFailed = useCallback(
    (fileRejections: FileRejection[]) => {
      if (hasErrorOfType(fileRejections, ErrorCode.FileTooLarge)) {
        notifyError({
          notificationMsg: TOO_LARGE_FILE_ERROR_MSG,
        });
      } else {
        const errorCode =
          fileRejections.length > 0 && fileRejections[0].errors.length > 0
            ? fileRejections[0].errors[0].code
            : 'unknown';
        notifyError({
          notificationMsg: `Something went wrong: ${errorCode}`,
        });
      }
    },
    [notifyError]
  );

  const handleDeleteAvatar = async () => {
    await deleteAvatarMutation();
    await queryClient.invalidateQueries(cacheManager.getUserAvatarCacheName(userId));
  };

  const handleChangeAvatar = async (file: File) => {
    await changeAvatarMutation(file);
    await queryClient.invalidateQueries(cacheManager.getUserAvatarCacheName(userId));
  };

  if (queryAvatar.status === 'loading' || userSettings.status === 'loading') {
    return (
      <div className="user-avatar__avatar">
        <div className="alert alert-secondary" role="alert">
          Loading avatar...
        </div>
      </div>
    );
  }

  return (
    <>
      {(queryAvatar.error || userSettings.error) && (
        <div className="user-avatar__avatar" data-test="user-avatar__error">
          <div className="alert alert-secondary" role="alert">
            {errorToString(queryAvatar.error ?? userSettings.error)}
          </div>
        </div>
      )}
      <div className="user-avatar__avatar" data-test="user-avatar__avatar">
        {queryAvatar.data ? (
          <img
            src={queryAvatar.data}
            alt="Avatar"
            width={size}
            height={size}
            className="user-avatar__avatar"
            data-test="user-avatar__avatar-img"
          />
        ) : (
          <UserInitials
            size={size}
            initials={extractUserInitials(currentUser, user?.email ?? '')}
          />
        )}
        {editMode && (
          <div className="user-avatar__block-menu" data-test="user-avatar__block-menu">
            <ButtonGroup vertical size="sm">
              <Dropzone
                onDropRejected={onAttachmentFailed}
                onDropAccepted={async (acceptedFiles) => handleChangeAvatar(acceptedFiles[0])}
                accept="image/png"
                multiple={false}
                noClick={true}
                noKeyboard={true}
                maxSize={MAX_SIZE}
              >
                {({ getRootProps, getInputProps, open }) => (
                  <div {...getRootProps({ className: 'dropzone' })}>
                    <input {...getInputProps()} data-test="user-avatar__file-replace" />
                    <Button
                      className="btn-light btn-sm"
                      data-test="user-avatar__change"
                      onClick={open}
                    >
                      Change
                    </Button>
                  </div>
                )}
              </Dropzone>
              <Button
                onClick={async () => handleDeleteAvatar()}
                className="btn-light btn-sm"
                data-test="user-avatar__delete"
              >
                Delete
              </Button>
            </ButtonGroup>
          </div>
        )}
      </div>
    </>
  );
};

export default UserAvatar;
