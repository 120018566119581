import moment from 'moment';
import { ResolvedCampaign, ResolvedProgramSummary } from '../../types';
import { hexToRGB } from '../../util';
import { CalendarViewType, UnscheduledViewType } from '../../containers/types';
import clsx from 'clsx';
import { CalendarSmallIcon } from '../../../assets/icons';
import { isUnscheduled } from '../../unscheduledProgramUtil';
import CalendarExtraFields from './CalendarExtraFields';
import { INTERPUNCT_CHARACTER } from '../../../commons/constants';

export const CampaignDataMarker = 'calendar__program-campaign';

export const ProgramCalendarEventContent = (props: {
  programSummary: ResolvedProgramSummary;
  showCampaigns: boolean;
  visibleColumns: string[];
  openCampaignDialog: ((campaign: ResolvedCampaign) => void) | null;
  viewType: CalendarViewType | typeof UnscheduledViewType;
  isCampaignGrouping: boolean;
  shortcutFieldNamesOnly: boolean;
}) => {
  const {
    programSummary,
    showCampaigns,
    visibleColumns,
    openCampaignDialog,
    viewType,
    isCampaignGrouping,
    shortcutFieldNamesOnly,
  } = props;
  let title = programSummary.name;
  if (
    (viewType === 'dayGridDay' || viewType === 'dayGridWeek') &&
    isCampaignGrouping &&
    !programSummary.wholeDay
  ) {
    const start = moment(programSummary.startDateTime).format('h:mma');
    const end = moment(programSummary.endDateTime).format('h:mma');
    title = `${title} ${INTERPUNCT_CHARACTER} ${start}-${end}`;
  }

  return (
    <div
      className="calendar__program-event-content"
      data-test={`calendar__program-event-content-${programSummary.id}`}
      data-program-id={programSummary.id}
    >
      <div className="calendar__program-event-header">
        <CalendarSmallIcon
          className="calendar__task-icon"
          data-test="calendar__task-icon"
          fill={programSummary.type.color}
        />
        <div
          className={clsx('calendar__program-event-title text-truncate text-break', {
            'calendar__program-event-column--unscheduled-title': isUnscheduled(programSummary),
          })}
          data-test="calendar__program-event-name"
        >
          {title}
        </div>
        {showCampaigns && !!programSummary.campaign && (
          <div
            className="calendar__program-campaign"
            data-test="calendar__program-campaign"
            data-marker={CampaignDataMarker}
            style={{
              borderColor: hexToRGB(programSummary.campaign.campaignColor!, 1),
              color: hexToRGB(programSummary.campaign.campaignColor!, 1),
            }}
            onClick={(event) => {
              event.stopPropagation();
              openCampaignDialog && openCampaignDialog(programSummary.campaign!);
            }}
          >
            {programSummary.campaign.name}
          </div>
        )}
      </div>
      <CalendarExtraFields
        programSummary={programSummary}
        visibleColumns={visibleColumns}
        shortcutFieldNamesOnly={shortcutFieldNamesOnly}
      />
    </div>
  );
};
