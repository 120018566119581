import { stringToHslColor } from '../../util/utils';
import React from 'react';
import { User } from '../../user/types';
import { NoProgramOwner, UnknownProgramOwner } from '../../user/types';
import { extractUserInitials, extractUserName } from '../../user/utils';
import { useFetchedUserAvatar } from '../../currentuser/hooks/useFetchedUserAvatar';
import clsx from 'clsx';

const getUserInitials = (u: User): string => {
  if (u.id === UnknownProgramOwner.id) return '?';
  if (u.id === NoProgramOwner.id) return '';
  return extractUserInitials(u, u.email);
};

const UniversalUserAvatar = (props: {
  user: User;
  avatar: string | null;
  size: 'small' | 'large';
}) => {
  const { user, avatar, size } = props;
  return (
    <div className="program-owner-select__avatar-wrapper">
      {avatar ? (
        <img
          src={avatar}
          alt={extractUserName(user)}
          className={clsx('program-owner-select__avatar', {
            'program-owner-select__avatar--small': size === 'small',
            'program-owner-select__avatar--large': size === 'large',
          })}
          data-test={`program-owner-select__avatar-indicator--${user.email}`}
        />
      ) : (
        <div
          className={clsx('program-owner-select__avatar d-flex align-items-center', {
            'program-owner-select__avatar--small': size === 'small',
            'program-owner-select__avatar--large': size === 'large',
          })}
          style={{
            backgroundColor: stringToHslColor(user.email),
          }}
          data-test={`program-owner-select__type-indicator--${user.email}`}
        >
          <span
            className={clsx('program-owner-select__avatar--text', {
              'growegy-font10': size === 'small',
              'growegy-font14': size === 'large',
            })}
          >
            {getUserInitials(user)}
          </span>
        </div>
      )}
    </div>
  );
};

export const UserAvatar = (props: { user: User }) => {
  const { user } = props;
  return <UniversalUserAvatar user={user} avatar={user.avatar} size="small" />;
};

export const LargeUserAvatar = (props: { user: User }) => {
  const { user } = props;
  const { data: avatar } = useFetchedUserAvatar(user.id);
  return <UniversalUserAvatar user={user} avatar={avatar ?? null} size="large" />;
};
