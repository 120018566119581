import { PromptCategory } from '../../types';
import * as React from 'react';
import { hexToRGB } from '../../../program/util';
import { PROGRAM_TYPE_BACKGROUND_ALPHA } from '../../../program-type/util';
import { getIconByName } from '../../../commons/icon-utils';

export const PromptCategoryPanel = (props: {
  category: PromptCategory;
  onClick: (category: PromptCategory) => void;
}) => {
  const { category, onClick } = props;
  const IconComponent = getIconByName(category.icon);
  return (
    <div
      className="prompt-category-panel__container"
      data-test={`prompt-category-panel__container-${category.name}`}
      style={{
        color: category.color,
        background: hexToRGB(category.color, PROGRAM_TYPE_BACKGROUND_ALPHA),
      }}
      onClick={() => onClick(category)}
    >
      <div
        data-test={`prompt-category-panel__icon-${category.name}`}
        className="prompt-category-panel__icon-wrapper"
      >
        {IconComponent ? <IconComponent style={{ fill: category.color }} /> : <></>}
      </div>
      <div
        data-test={`prompt-category-panel__name-${category.name}`}
        className="prompt-category-panel__name"
      >
        {category.name}
      </div>
    </div>
  );
};
