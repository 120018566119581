import useAnalyticsPage from '../../web-analytics/hooks/useAnalyticsPage';
import { Page } from '../../web-analytics/Page';
import React, { useCallback, useMemo, useState } from 'react';
import { useFetchedBillingInvoices } from '../hooks/useFetchedBillingInvoices';
import { useFetchedBillingCustomer } from '../hooks/useFetchedBillingCustomer';
import { useFetchedBillingPaymentMethods } from '../hooks/useFetchedBillingPaymentMethods';
import { BillingDetailsSection } from '../components/BillingDetailsSection';
import { InvoiceTable } from '../components/InvoiceTable';
import { PaymentMethodsTable } from '../components/PaymentMethodsTable';
import { PlanBenefits } from '../components/PlanBenefits';
import { BillingHeader } from '../components/BillingHeader';
import { INVOICE_TYPE_ISSUED, IssuedInvoice } from '../types';
import { ConfirmModal } from '../../commons/components/ConfirmModal';
import { v4 as uuidv4 } from 'uuid';
import { UpgradeToAnnualPlan } from '../components/UpgradeToAnnualPlan';
import { CancellationWizard } from '../components/cancellation/CancellationWizard';
import { useFinalizeInvoicesMutation } from '../hooks/useFinalizeInvoicesMutation';
import { FullScreenSpinner } from '../../commons/components/Spinner';
import { onInvoiceClick } from '../components/PayNowButton';
import useAnalytics from '../../web-analytics/webAnalyticsContext';
import { analyticsTrack } from '../../web-analytics/webAnalytics';
import { AnalyticsEvent } from '../../web-analytics/AnalyticsEvent';
import { getInvoiceClickSource } from '../utils';

const getHeaderInvoicesContent = (isPastDue: boolean) =>
  isPastDue ? 'header-past-due-invoices' : 'header-due-invoices';

export const BillingPortal = () => {
  useAnalyticsPage(Page.BILLING);
  const analytics = useAnalytics();

  const [showModal, setShowModal] = useState<
    | {
        type: 'invoices';
        invoiceIds: string[];
        isPastDue: boolean;
      }
    | {
        type: 'cancel-plan';
        key: string;
      }
    | null
  >(null);

  const {
    queryStatusComponent: customerStatusComponent,
    query: { data: customer, isLoading: isCustomerLoading, isError: isCustomerFetchError },
  } = useFetchedBillingCustomer({ withTracking: true, refetchOnWindowFocus: 'always' });

  const {
    queryStatusComponent: invoicesStatusComponent,
    query: { data: invoices, isLoading: isInvoicesLoading, isError: isInvoicesFetchError },
  } = useFetchedBillingInvoices({ withTracking: true, refetchOnWindowFocus: 'always' });

  const {
    queryStatusComponent: paymentMethodsStatusComponent,
    query: {
      data: paymentMethodsFetchData,
      isLoading: isPaymentMethodsLoading,
      isError: isPaymentMethodsFetchError,
    },
  } = useFetchedBillingPaymentMethods({ withTracking: true });

  const selectedInvoices = useMemo(() => {
    if (showModal?.type !== 'invoices' || !invoices) return [];
    const issuedInvoices = invoices.filter(
      (x) => x.type === INVOICE_TYPE_ISSUED
    ) as IssuedInvoice[];

    return showModal.invoiceIds.flatMap((id) => issuedInvoices.find((x) => x.id === id) || []);
  }, [showModal, invoices]);

  const [expanded, setExpanded] = useState(false);

  const { mutate: finalizeInvoices, status: finalizeInvoicesStatus } =
    useFinalizeInvoicesMutation();

  const onPayNowClick = useCallback(
    (invoices: IssuedInvoice[], isPastDue: boolean) => {
      if (invoices.length === 1) {
        onInvoiceClick(
          null,
          invoices[0],
          analytics,
          getInvoiceClickSource(invoices[0], getHeaderInvoicesContent(isPastDue))
        );
      } else {
        analyticsTrack(analytics, AnalyticsEvent.BILLING_INVOICE_LIST_OPENED, {
          source: isPastDue ? 'past-due-header' : 'due-header',
        });
        setShowModal({
          type: 'invoices',
          invoiceIds: invoices.map((i) => i.id),
          isPastDue,
        });
      }
    },
    [analytics]
  );

  if ((isCustomerLoading || isCustomerFetchError) && !customer)
    return <div className="billing-error">{customerStatusComponent}</div>;
  if ((isInvoicesLoading || isInvoicesFetchError) && !invoices)
    return <div className="billing-error">{invoicesStatusComponent}</div>;
  if ((isPaymentMethodsLoading || isPaymentMethodsFetchError) && !paymentMethodsFetchData)
    return <div className="billing-error">{paymentMethodsStatusComponent}</div>;

  return (
    <div className="billing-container">
      <>
        <div>
          {!!customer && !!invoices && (
            <div>
              <BillingHeader
                customer={customer}
                invoices={invoices.filter((x) => x.type === INVOICE_TYPE_ISSUED) as IssuedInvoice[]}
                onPayNowClick={onPayNowClick}
                onCancelClick={() => {
                  finalizeInvoices();
                  setShowModal({ type: 'cancel-plan', key: uuidv4() });
                }}
                onSeeAnnualPlan={() => setExpanded(true)}
              />
            </div>
          )}

          <PlanBenefits />

          {!!customer && (
            <div className="billing__upgrade-to-annual-wrapper">
              <UpgradeToAnnualPlan
                expanded={expanded}
                onExpandedChanged={setExpanded}
                hasPendingPlanChange={!!customer.pendingPlanChange}
                changePlanOptions={customer.changePlanOptions}
              />
            </div>
          )}

          {!!paymentMethodsFetchData && !!customer && (
            <div className="billing-section">
              <PaymentMethodsTable paymentMethods={paymentMethodsFetchData} customer={customer} />
            </div>
          )}

          {!!customer && (
            <div className="billing-section">
              <BillingDetailsSection customer={customer} />
            </div>
          )}

          {!!invoices && (
            <div className="billing-section">
              <InvoiceTable invoices={invoices} header="Invoices" context="all-invoices" />
            </div>
          )}
        </div>
        {showModal?.type === 'invoices' && !!selectedInvoices.length && (
          <ConfirmModal
            title={`Pay your ${showModal.isPastDue ? 'past due' : ''} invoices as soon as possible`}
            message="All past due invoices have the potential to negatively impact your credit score and we are unable to alter the reports."
            className="billing__invoices-modal"
            shouldCloseOnOverlayClick={false}
            shouldCloseOnEsc={true}
            ariaHideApp={false}
            footer={{
              type: 'hide',
              cancel: () => setShowModal(null),
            }}
          >
            <InvoiceTable
              invoices={selectedInvoices}
              header={showModal.isPastDue ? 'Past due invoices' : 'Invoices'}
              context={getHeaderInvoicesContent(showModal.isPastDue)}
              reverseOrder={true}
            />
          </ConfirmModal>
        )}
        {showModal?.type === 'cancel-plan' && finalizeInvoicesStatus === 'success' && (
          <CancellationWizard key={showModal.key} />
        )}
        {finalizeInvoicesStatus === 'loading' && <FullScreenSpinner />}
      </>
    </div>
  );
};
