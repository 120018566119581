import { AxiosInstance, AxiosResponse } from 'axios';
import {
  Campaign,
  CustomDateTimeField,
  CustomFieldType,
  CustomFormulaField,
  CustomNumberField,
  CustomTextField,
  DueDateFormulaAnchor,
  DueDateFormulaOffsetUnit,
  Program,
  ProgramAttachment,
  ProgramCustomField,
  ProgramCustomFieldMeta,
  ProgramKind,
  ProgramStatus,
  ProgramSummaries,
  ProgramSummary,
  ProgramTask,
  ProgramTaskChange,
  ProgramTaskStatus,
} from './types';
import { errorToString, extractBackendError, isAxiosError } from '../util/utils';
import { buildMathJsScope, encodeToMathJsId, ScopeableCustomField } from './formulaUtil';
import { adjustToLocal, adjustToUtc } from '../util/date-utils';
import { isUnscheduled } from './unscheduledProgramUtil';
import { UnknownProgramOwner } from '../user/types';

type ProgramTypeDto = {
  id: string;
  name: string;
  color: string;
};

type NewProgramTypeDto = {
  id?: string;
  name: string;
  color: string;
};

type ProgramTypesDto = {
  types: Array<ProgramTypeDto>;
  version: number;
};

type NewProgramTypesDto = {
  types: Array<NewProgramTypeDto>;
  version: number;
};

type ProgramCustomFieldDto = {
  type: string;
  name: string;
  value: string;
  prefix: string | null;
  suffix: string | null;
};

type ProgramCustomFieldMetaDto = {
  type: string;
  name: string;
};

type AllProgramCustomFieldMetasDto = {
  customFields: ProgramCustomFieldMetaDto[];
};

export type TaskDueDateFormulaDto = {
  anchor: DueDateFormulaAnchor;
  offset: number;
  offsetUnit: DueDateFormulaOffsetUnit;
};

type ProgramTaskDto = {
  id: string | null;
  name: string;
  description: string | null;
  dueDateTime: string | null;
  dueDateFormula: TaskDueDateFormulaDto | null;
  owningUserId: string | null;
  status: ProgramTaskStatus;
};

type ProgramDataDto = {
  id: string | null;
  programKind: ProgramKind;
  name: string;
  startDateTime: string;
  endDateTime: string;
  startRangeDateTime: string | null;
  endRangeDateTime: string | null;
  wholeDay: boolean;
  typeId: string;
  budget: number;
  leads: number;
  status: ProgramStatus;
  owningUserId: string | null;
  vendor: string;
  notes: string;
  version?: number;
  customParams?: ProgramCustomFieldDto[];
  tasks: ProgramTaskDto[];
  actualLeads: number | null;
  salesforceCampaignId: string | null;
  campaignId: string | null;
  campaignColor: string | null;
};

type BulkCreateProgramsDto = {
  programs: ProgramDataDto[];
};

type BulkCreateProgramsResponseDto = {
  programs: ProgramDto[];
  parentCampaigns: ProgramDto[];
};

type ProgramSummaryDto = { id: string } & ProgramDataDto;

type ProgramDto = ProgramSummaryDto;

type ProgramsDto = {
  programs: ProgramDto[];
};

type CreateProgramResponseDto = {
  program: ProgramDto;
  parentCampaign: ProgramDto | null;
};

type PatchProgramResponseDto = {
  program: ProgramDto;
  parentCampaign: ProgramDto | null;
  oldParentCampaign: ProgramDto | null;
};

type DeleteProgramResponseDto = {
  parentCampaign: ProgramDto | null;
};

type TaskChangeDto = {
  changeType: 'CREATE' | 'UPDATE' | 'DELETE';
  id: string | null;
  orderIndex?: number;
  task?: ProgramTaskDto;
};

type ProgramPatchDto = {
  id: string;
  version: number;
  program: ProgramDataDto | null;
  taskChanges: TaskChangeDto[] | null;
};

type ActionStatusDto = {
  status: number;
  msg: string;
};

type ProgramPageDto = {
  programs: Array<ProgramSummaryDto>;
  lastEvaluatedKey: string | null;
};

type ProgramAttachmentMetadataDto = {
  id: string;
  name: string;
};

type ProgramAttachmentsMetadataDto = {
  attachments: Array<ProgramAttachmentMetadataDto>;
};

type CampaignsDto = {
  campaigns: ProgramDto[];
};

type CampaignProgramsDto = {
  programs: ProgramDto[];
};

export type ProgramApi = ReturnType<typeof getProgramApi>;

const extractProgramCustomParamDto = (field: ProgramCustomField): ProgramCustomFieldDto => {
  switch (field.type) {
    case 'text':
      return {
        name: field.name,
        type: 'text',
        value: field.value,
        prefix: null,
        suffix: null,
      };
    case 'number':
      return {
        name: field.name,
        type: 'number',
        value: `${field.value}`,
        prefix: null,
        suffix: null,
      };
    case 'date-time':
      return {
        name: field.name,
        type: 'date-time',
        value: field.value.toISOString(),
        prefix: null,
        suffix: null,
      };
    case 'formula':
      return {
        name: field.name,
        type: 'formula',
        value: field.value,
        prefix: field.prefix,
        suffix: field.suffix,
      };

    default: {
      const msg = `received unexpected custom param field: ${JSON.stringify(field)}`;
      logger.error(msg);
      throw new Error(msg);
    }
  }
};

const toProgramTaskDto = (programTask: ProgramTask): ProgramTaskDto => {
  const { id, name, description, dueDateTime, dueDateFormula, owningUserId, status } = programTask;
  return {
    id,
    name,
    description,
    dueDateTime: dueDateTime !== null ? adjustToUtc(dueDateTime, true).toISOString() : null,
    dueDateFormula,
    owningUserId,
    status,
  };
};

const toProgramDataDto = (program: Program): ProgramDataDto => {
  const {
    id,
    programKind,
    name,
    budget,
    startDateTime,
    endDateTime,
    wholeDay,
    leads,
    typeId,
    vendor,
    notes,
    status,
    version,
    customFields,
    tasks,
    owningUserId,
    actualLeads,
    salesforceCampaignId,
    campaignId,
    campaignColor,
  } = program;
  const unscheduled = isUnscheduled(program);
  return {
    id: id || null,
    programKind,
    name,
    budget,
    startDateTime: adjustToUtc(startDateTime, wholeDay || unscheduled).toISOString(),
    endDateTime: adjustToUtc(endDateTime, wholeDay || unscheduled).toISOString(),
    startRangeDateTime: null,
    endRangeDateTime: null,
    wholeDay,
    leads,
    typeId,
    vendor,
    notes,
    status,
    owningUserId: owningUserId === UnknownProgramOwner.id ? null : owningUserId,
    version,
    customParams: customFields.map((f) => extractProgramCustomParamDto(f)),
    tasks: tasks.map((t) => toProgramTaskDto(t)),
    actualLeads,
    salesforceCampaignId,
    campaignId,
    campaignColor,
  };
};

type RowCustomField =
  | CustomTextField
  | CustomNumberField
  | CustomDateTimeField
  | Omit<CustomFormulaField, 'result'>;

const extractProgramCustomField = (
  dto: ProgramCustomFieldDto
): { name: string; localId: string } & RowCustomField => {
  const { type, name, value, prefix, suffix } = dto;
  const baseField: ProgramCustomField = {
    localId: '',
    name,
    type: 'text',
    value: '',
  };
  switch (type) {
    case 'text':
      return {
        ...baseField,
        type: 'text',
        value,
      };
    case 'number':
      return {
        ...baseField,
        type: 'number',
        value: parseInt(value, 10),
      };
    case 'date-time':
      return {
        ...baseField,
        type: 'date-time',
        value: new Date(value),
      };
    case 'formula':
      return {
        ...baseField,
        type: 'formula',
        value,
        prefix,
        suffix,
      };

    default: {
      const msg = `received unexpected custom param field: ${JSON.stringify(dto)}`;
      logger.error(msg);
      throw new Error(msg);
    }
  }
};

const toProgramTask = (programTaskDto: ProgramTaskDto): ProgramTask => {
  const { id, name, description, dueDateTime, dueDateFormula, owningUserId, status } =
    programTaskDto;
  return {
    id,
    name,
    description,
    dueDateTime: dueDateTime ? adjustToLocal(new Date(dueDateTime), true) : null,
    dueDateFormula,
    owningUserId,
    status,
  };
};

const toProgram = (program: ProgramDto): Program => {
  const {
    id,
    programKind,
    name,
    budget,
    leads,
    typeId,
    vendor,
    notes,
    status,
    version,
    customParams,
    tasks,
    startDateTime,
    endDateTime,
    startRangeDateTime,
    endRangeDateTime,
    wholeDay,
    owningUserId,
    actualLeads,
    salesforceCampaignId,
    campaignId,
    campaignColor,
  } = program;
  const rowCustomFields = (customParams || []).map((p) => extractProgramCustomField(p));
  const scopeableCustomFields: ScopeableCustomField[] = rowCustomFields.flatMap((f) => {
    switch (f.type) {
      case 'number':
        return [{ type: 'number', name: f.name, value: f.value as number, localId: f.localId }];
      case 'formula':
        return [{ type: 'formula', name: f.name, value: f.value as string, localId: f.localId }];
      default:
        return [] as ScopeableCustomField[];
    }
  });

  const { scope } = buildMathJsScope(program, scopeableCustomFields);

  const customFields: ProgramCustomField[] = rowCustomFields.map((f) => {
    switch (f.type) {
      case 'formula': {
        const scopeName = encodeToMathJsId(f.name, f.localId);
        const result = scope[scopeName] === undefined ? Number.NaN : scope[scopeName];
        return { ...f, localId: '', result };
      }
      default:
        return {
          ...f,
          localId: '',
        };
    }
  });

  const localStartDateTime = adjustToLocal(new Date(startDateTime), wholeDay);
  const localEndDateTime = adjustToLocal(new Date(endDateTime), wholeDay);

  return {
    id,
    programKind,
    name,
    budget,
    startDateTime: localStartDateTime,
    endDateTime: localEndDateTime,
    startRangeDateTime: (() => {
      if (startRangeDateTime) {
        return (() => {
          const localStartRangeDateTime = adjustToLocal(new Date(startRangeDateTime), true);
          const nonLocalStartRangeDateTime = new Date(startRangeDateTime);
          return localStartRangeDateTime.valueOf() <= nonLocalStartRangeDateTime.valueOf()
            ? localStartRangeDateTime
            : nonLocalStartRangeDateTime;
        })();
      } else {
        return localStartDateTime;
      }
    })(),
    endRangeDateTime: (() => {
      if (endRangeDateTime) {
        return (() => {
          const localEndRangeDateTime = adjustToLocal(new Date(endRangeDateTime), true);
          const nonLocalEndRangeDateTime = new Date(endRangeDateTime);
          return localEndRangeDateTime.valueOf() >= nonLocalEndRangeDateTime.valueOf()
            ? localEndRangeDateTime
            : nonLocalEndRangeDateTime;
        })();
      } else {
        return localEndDateTime;
      }
    })(),
    wholeDay,
    leads,
    typeId,
    vendor,
    notes,
    status,
    version,
    customFields,
    tasks: (tasks || []).map((t) => toProgramTask(t)),
    owningUserId,
    actualLeads,
    salesforceCampaignId,
    endDateInClientRepresentation: false,
    campaignId,
    campaignColor,
  };
};

const toProgramSummaries = (
  from: Date,
  to: Date,
  limit: number,
  summaries: ProgramPageDto
): ProgramSummaries => ({
  from,
  to,
  limit,
  lastEvaluatedKey: summaries.lastEvaluatedKey,
  summaries: summaries.programs.map(toProgram).filter((x) => x.programKind === 'PROGRAM'),
});

const toProgramAttachment = (attachment: ProgramAttachmentMetadataDto): ProgramAttachment => {
  const { id, name } = attachment;
  return { id, name, status: 'EXISTED' };
};

const extractCustomFieldType = (type: string): CustomFieldType => {
  switch (type) {
    case 'text':
    case 'number':
    case 'date-time':
    case 'formula':
      return type;
    default:
      return 'text';
  }
};

const toTaskChangeDto = (change: ProgramTaskChange): TaskChangeDto => {
  switch (change.changeType) {
    case 'CREATE':
      return {
        changeType: 'CREATE',
        id: change.task.id,
        orderIndex: change.orderId,
        task: toProgramTaskDto(change.task),
      };
    case 'UPDATE':
      return {
        changeType: 'UPDATE',
        id: change.task.id,
        orderIndex: change.orderId,
        task: toProgramTaskDto(change.task),
      };
    case 'DELETE':
      return {
        changeType: 'DELETE',
        id: change.taskId,
      };
  }
};

export const getProgramApi = (axiosInstance: AxiosInstance) => {
  const createProgram = async (program: Program) => {
    const data = toProgramDataDto(program);
    const response = await logger.wrapWithTelemetry(
      axiosInstance.post<CreateProgramResponseDto>('/programs', data),
      'create program'
    );
    const createdProgram = toProgram(response.data.program);
    const affectedCampaign = response.data.parentCampaign
      ? toProgram(response.data.parentCampaign)
      : null;

    logger.pushEvent('Program created', { id: createdProgram.id });
    return { createdProgram, affectedCampaign };
  };

  const createPrograms = async (programs: Program[]) => {
    const data: BulkCreateProgramsDto = { programs: programs.map((x) => toProgramDataDto(x)) };
    const response = await logger.wrapWithTelemetry(
      axiosInstance.post<BulkCreateProgramsResponseDto>('/programs/bulk', data),
      'create programs'
    );
    const createdPrograms = response.data.programs.map((x) => toProgram(x));
    const affectedCampaigns = response.data.parentCampaigns
      ? response.data.parentCampaigns.map((x) => toProgram(x))
      : [];

    logger.pushEvent('Programs created');
    return { createdPrograms, affectedCampaigns };
  };

  const getProgramSummaries = async (
    from: Date,
    to: Date,
    limit: number,
    lastEvaluatedKey: string | null
  ) => {
    const fromParam = encodeURIComponent(`${from.toISOString().split('.')[0]}Z`);
    const toParam = encodeURIComponent(`${to.toISOString().split('.')[0]}Z`);
    const keyParam =
      lastEvaluatedKey !== null && lastEvaluatedKey.length
        ? `&lastEvaluatedKey=${lastEvaluatedKey}`
        : '';
    const url = `/program-page?from=${fromParam}&to=${toParam}&forward=true&pageLimit=${limit}${keyParam}`;
    const response = await logger.wrapWithTelemetry(
      axiosInstance.get<ProgramPageDto>(url),
      `get program summaries`
    );
    return toProgramSummaries(from, to, limit, response.data);
  };

  const deleteAllPrograms = async () =>
    axiosInstance.delete<ActionStatusDto>('/programs').then((r) => {
      const { status, msg } = r.data;
      if (status !== 200) throw new Error(`failed to delete all programs: ${msg}`);
    });

  const patchProgram = async (
    programId: string,
    programVersion: number,
    program: Program | null,
    taskChanges: ProgramTaskChange[] | null
  ) => {
    const data: ProgramPatchDto = {
      id: programId,
      version: programVersion,
      program: program ? { ...toProgramDataDto(program), tasks: [] } : null,
      taskChanges: taskChanges ? taskChanges.map(toTaskChangeDto) : null,
    };
    try {
      const response = await axiosInstance.patch<PatchProgramResponseDto>(
        `/programs/${programId}`,
        data
      );
      const responseData = response.data;
      const changedProgram = toProgram(responseData.program);
      const parentCampaign = responseData.parentCampaign
        ? toProgram(responseData.parentCampaign)
        : null;
      const oldParentCampaign = responseData.oldParentCampaign
        ? toProgram(responseData.oldParentCampaign)
        : null;
      logger.pushEvent('Program changed', {
        id: changedProgram.id,
        version: `${changedProgram.version}`,
      });
      return { changedProgram, parentCampaign, oldParentCampaign };
    } catch (e) {
      const msg = `Failed to patch program ${programId}: error ${extractBackendError(e)}`;
      logger.error(msg);
      throw new Error(msg);
    }
  };

  const replaceAllProgramsFromCSV = async (csv: string) => {
    try {
      await axiosInstance.put<ProgramsDto>('/programs', csv, {
        headers: { 'Content-Type': 'text/csv' },
      });
      logger.pushEvent('Programs replaced from CSV');
    } catch (e) {
      throw new Error(extractBackendError(e));
    }
  };

  const downloadAllProgramsToCSV = async (): Promise<string> => {
    const response = await axiosInstance.get('programs', {
      headers: { accept: 'text/csv' },
    });
    return response.data;
  };

  const getProgramAttachments = async (id: string): Promise<Array<ProgramAttachment>> => {
    const response = await axiosInstance.get<ProgramAttachmentsMetadataDto>(
      `/programs/${id}/attachments`
    );
    return response.data.attachments.map((attachment) => toProgramAttachment(attachment));
  };

  const addProgramAttachment = async (programId: string, file: File): Promise<AxiosResponse> => {
    const formData = new FormData();
    formData.append('file', file);
    try {
      return await axiosInstance.post<ProgramAttachmentMetadataDto>(
        `/programs/${programId}/attachments`,
        formData,
        {
          headers: { 'Content-Type': 'multipart/form-data' },
        }
      );
    } catch (e) {
      if (e && isAxiosError(e)) {
        const msg = `Attachment ${file.name} ${(e.response?.data as ActionStatusDto)?.msg}`;
        logger.error(msg);
        throw new Error(msg);
      } else {
        const msg = errorToString(e);
        logger.error(msg);
        throw new Error(msg);
      }
    }
  };

  const deleteProgramAttachment = async (programId: string, attachmentId: string, name: string) => {
    try {
      const response = await axiosInstance.delete(
        `programs/${programId}/attachments/${attachmentId}`
      );
      return response.data;
    } catch (e) {
      if (e && isAxiosError(e)) {
        const msg = `Attachment ${name} ${(e.response?.data as ActionStatusDto)?.msg}`;
        logger.error(msg);
        throw new Error(msg);
      } else {
        const msg = errorToString(e);
        logger.error(msg);
        throw new Error(msg);
      }
    }
  };

  const downloadProgramAttachment = async (programId: string, attachmentId: string) => {
    try {
      const response = await axiosInstance.get(
        `programs/${programId}/attachments/${attachmentId}`,
        { responseType: 'blob' }
      );
      return response.data;
    } catch (e) {
      throw new Error(errorToString(e));
    }
  };

  const getProgram = async (id: string) => {
    const { data } = await axiosInstance.get<ProgramDto>(`/programs/${id}`);
    return toProgram(data);
  };

  const deleteProgram = async (id: string) => {
    const response = await axiosInstance.delete<DeleteProgramResponseDto>(`/programs/${id}`);
    const parentCampaign = response.data.parentCampaign;
    logger.pushEvent('Program deleted', { id });

    return parentCampaign ? toProgram(parentCampaign) : null;
  };

  const getProgramTypes = async (): Promise<ProgramTypesDto> => {
    logger.info('Getting programs types');
    return axiosInstance.get<ProgramTypesDto>('/tenants/current/program-types').then((r) => {
      const { version, types } = r.data;
      const hashTypes = types
        .sort((a, b) => a.name.localeCompare(b.name))
        .map((t) => ({ ...t, color: `#${t.color}` }));
      return { version, types: hashTypes };
    });
  };

  const putProgramTypes = async (
    newProgramTypesDto: NewProgramTypesDto
  ): Promise<ProgramTypesDto> => {
    logger.info('Putting programs types');
    const { types, version } = newProgramTypesDto;
    const stripedTypes = types.map((t) => ({
      ...t,
      color: t.color.startsWith('#') ? t.color.slice(1) : t.color,
    }));
    const stripedDto: NewProgramTypesDto = { version, types: stripedTypes };
    return axiosInstance
      .put<ProgramTypesDto>('/tenants/current/program-types', stripedDto)
      .then((r) => r.data);
  };

  const getCampaigns = async (): Promise<Campaign[]> => {
    logger.info('Getting campaigns');
    return axiosInstance.get<CampaignsDto>('/campaigns').then((r) => {
      const { campaigns } = r.data;
      return campaigns.map(toProgram);
    });
  };

  const getCampaignPrograms = async (campaignId: string): Promise<ProgramSummary[]> => {
    logger.info(`Getting programs for campaign ${campaignId}`);
    return axiosInstance.get<CampaignProgramsDto>(`/campaigns/${campaignId}/programs`).then((r) => {
      const { programs } = r.data;
      return programs.map(toProgram);
    });
  };

  const getAllCustomFields = async (): Promise<ProgramCustomFieldMeta[]> => {
    logger.info('Getting all custom fields');
    return axiosInstance.get<AllProgramCustomFieldMetasDto>('/programs/custom-fields').then((r) => {
      const { customFields } = r.data;
      return customFields.map((customFieldMetaDto) => ({
        name: customFieldMetaDto.name,
        type: extractCustomFieldType(customFieldMetaDto.type),
      }));
    });
  };

  return {
    createProgram,
    createPrograms,
    getProgramSummaries,
    deleteAllPrograms,
    patchProgram,
    replaceAllProgramsFromCSV,
    downloadAllProgramsToCSV,
    getProgramAttachments,
    addProgramAttachment,
    deleteProgramAttachment,
    downloadProgramAttachment,
    deleteProgram,
    getProgram,
    getProgramTypes,
    putProgramTypes,
    getCampaigns,
    getCampaignPrograms,
    getAllCustomFields,
  };
};
