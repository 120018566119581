import { AxiosInstance, AxiosResponse } from 'axios';
import { OutlookOauth } from './types';

type ConsentRequestDto = {
  accessToken: string;
};

type OutlookOauthDto = {
  isConfigured: boolean;
  email: string | null;
  isDisconnected: boolean;
};

export const notConnectedOutlookOauth: OutlookOauthDto = {
  isConfigured: false,
  email: null,
  isDisconnected: false,
};

const toOutlookOauth = (dto: OutlookOauthDto | null): OutlookOauth =>
  dto
    ? { isConfigured: true, email: dto.email, isDisconnected: dto.isDisconnected }
    : notConnectedOutlookOauth;

export type OutlookApi = ReturnType<typeof getOutlookApi>;

export const getOutlookApi = (axiosInstance: AxiosInstance) => {
  const exchangeAccessToken = async (userId: string, accessToken: string): Promise<OutlookOauth> =>
    axiosInstance
      .post<ConsentRequestDto, AxiosResponse<OutlookOauthDto>>(
        `/users/${userId}/outlook/user-consent`,
        {
          accessToken,
        }
      )
      .then((r) => toOutlookOauth(r.data));

  const getOauth = async (userId: string): Promise<OutlookOauth> => {
    const dto = await axiosInstance.get<OutlookOauthDto>(`/users/${userId}/outlook/oauth`).then(
      (r) => r.data,
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      (x: any) => {
        if (x.response.status === 404) {
          return null;
        }
        throw x;
      }
    );
    return toOutlookOauth(dto);
  };

  const deleteOauth = async (userId: string) =>
    axiosInstance.delete(`/users/${userId}/outlook/oauth`);

  const scheduleResync = async (userId: string): Promise<void> =>
    axiosInstance.post(`/users/${userId}/outlook/calendar/resync`);

  return {
    userConsent: exchangeAccessToken,
    getOauth,
    deleteOauth,
    scheduleResync,
  };
};
