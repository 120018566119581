import { ErrorMessage, Field } from 'formik';

const AuthInput = (props: {
  name: string;
  type: string;
  autoComplete: string;
  id?: string;
  placeholder: string;
  dataTest: string;
  invalid: boolean;
}) => {
  const { name, type, autoComplete, id, placeholder, invalid, dataTest } = props;

  return (
    <>
      <Field
        type={type}
        name={name}
        id={id}
        autoComplete={autoComplete}
        placeholder={placeholder}
        className={`auth__input growegy-label14 ${invalid ? 'is-invalid' : ''}`}
        data-test={dataTest}
      />
      <ErrorMessage
        component="div"
        name={name}
        className="invalid-feedback"
        data-test={`${dataTest}-error`}
      />
    </>
  );
};

export default AuthInput;
