import {
  AI_MESSAGE_SENDER,
  Chat,
  ChatMessage,
  CLIENT_MESSAGE_SENDER,
  GptModel,
  NEW_CHAT_ID,
  PromptExtension,
  ResolvedChatMessage,
  TOOL_CALL_USER_RESULT_OK,
  TOOL_CALL_USER_RESULT_REJECT,
} from '../../types';
import { MessageList } from './MessageList';
import { ComplexContent, stringToComplexContent, TextMessageInput } from './TextMessageInput';
import { useCallback, useEffect, useRef, useState } from 'react';
import { ENV_E2E, growegyAIConfig, readCommonConfig } from '../../../commons/config';
import { LibraryPromptWizardState, useChatContext } from '../chatContext';
import { useCreateChatMutation } from '../hooks/useCreateChatMutation';
import {
  getFinalPrompt,
  getQuestionOrDefault,
  getTempMessageId,
  NUMBER_PLACEHOLDER,
  PREV_ANSWER_REFERENCE,
} from '../../util';
import { useCreateMessageMutation } from '../hooks/useCreateMessageMutation';
import { useQueryCacheManager } from '../../../hooks/useQueryCacheManager';
import { VariableMessage } from './VariableMessage';
import { MessageListBottomPlaceholder } from './MessageListBottomPlaceholder';
import { useCancelMessageMutation } from '../hooks/useCancelMessageMutation';
import { ANSWER_MESSAGE_STATUS_OK } from '../../growegyAIApi';
import { MutationStatus } from '@tanstack/react-query';
import { GptModelSelect } from './GptModelSelect';
import { createPortal } from 'react-dom';
import { ExecuteToolsMessageInput } from './ExecuteToolsMessageInput';
import { useResolvedChatMessages } from '../hooks/useResolvedChatMessages';

const SMOOTH_SCROLL_SUPPORTED = readCommonConfig().env !== ENV_E2E;
const MAX_OPEN_AI_RESPONSES_LIMIT = growegyAIConfig.maxMessagesPerChat;
const MAX_CLIENT_REQUESTS_LIMIT = growegyAIConfig.maxMessagesPerChat * 2; // in case of network issues or other errors number of requests can be higher than number of responses

export const Messenger = (props: {
  chat: Chat;
  hideIntro?: boolean;
  systemMessage?: string | null;
  textInputPlaceholder?: string;
  slimGptModelSelect?: boolean;
  gptModelSelectDiv: HTMLDivElement | null;
  onChatCreated: (chat: Chat) => void;
  onOpenLibraryClick: () => void;
  onNewChatClick: () => void;
  onManageVariablesClick?: () => void;
}) => {
  const {
    chat,
    hideIntro,
    systemMessage,
    textInputPlaceholder,
    slimGptModelSelect,
    gptModelSelectDiv,
    onChatCreated,
    onOpenLibraryClick,
    onNewChatClick,
    onManageVariablesClick,
  } = props;
  const queryCacheManager = useQueryCacheManager();

  const lastMessageRef = useRef<HTMLDivElement | null>(null);
  const scrollToBottom = useCallback((scrollStyle: 'smooth' | 'auto') => {
    const current = lastMessageRef.current;
    if (!current) return;
    const behavior = SMOOTH_SCROLL_SUPPORTED ? scrollStyle : 'auto';
    if (behavior === 'smooth')
      setTimeout(() => current.scrollIntoView({ behavior: behavior }), 100);
    else current.scrollIntoView({ behavior: behavior });
  }, []);

  const {
    mutateAsync: createMessageMutation,
    status: createMessageStatus,
    variables: createMessageVariables,
  } = useCreateMessageMutation(
    () => setNeedToScroll(true),
    () => setNeedToScroll(true)
  );
  const { mutate: cancelMessageMutation, status: cancelMessageStatus } = useCancelMessageMutation(
    () => {
      setSendMessageCancelled(true);
    },
    () => {
      setSendMessageCancelled(false);
    }
  );

  const { mutateAsync: createChatMutation, status: createChatStatus } = useCreateChatMutation(
    onChatCreated,
    (e, props) => {
      const { firstMessageContent } = props;
      setComplexContent(firstMessageContent);
      setChatContext(NEW_CHAT_ID, {
        rawContent: firstMessageContent.rawContent,
        plainTextContent: firstMessageContent.plainTextContent,
      });
    }
  );

  const { getChatContext, setChatContext, getCurrentModel, setCurrentModel } = useChatContext();
  const currentModel = getCurrentModel();
  const [gptModel, setGptModel] = useState<GptModel>(currentModel);
  const [complexContent, setComplexContent] = useState<ComplexContent>(() => {
    const chatContext = getChatContext(chat.id);
    return { rawContent: chatContext.rawContent, plainTextContent: chatContext.plainTextContent };
  });
  useEffect(() => {
    setChatContext(chat.id, {
      rawContent: complexContent.rawContent,
      plainTextContent: complexContent.plainTextContent,
    });
  }, [chat.id, complexContent, setChatContext]);

  const [libraryPromptWizardState, setLibraryPromptWizardState] =
    useState<LibraryPromptWizardState | null>(getChatContext(chat.id).libraryPromptWizardState);
  const onMessageSend = useCallback(
    async (
      contentToSend: ComplexContent | null,
      source: 'input' | 'sample' | 'retry' | 'library' | 'composer' | 'function',
      promptExtensions?: PromptExtension[]
    ) => {
      try {
        if (!contentToSend && (!promptExtensions || promptExtensions.length === 0)) return;
        setSendMessageCancelled(false);
        let isWizardStep: boolean = false;
        let wizardStepNumber: number | undefined = undefined;
        let isFinalWizardStep: boolean = false;
        let answerContent: string | undefined = undefined;
        const currentStepIndex = libraryPromptWizardState?.currentStepState.index ?? 0;
        if (libraryPromptWizardState && currentStepIndex <= libraryPromptWizardState.steps.length) {
          isWizardStep = true;
          wizardStepNumber = currentStepIndex;
          isFinalWizardStep = currentStepIndex === libraryPromptWizardState.steps.length;
          if (isFinalWizardStep) {
            answerContent = 'Great! Here’s your final prompt:';
          } else {
            let newQuestion = libraryPromptWizardState.steps[currentStepIndex].question;
            if (newQuestion.indexOf(PREV_ANSWER_REFERENCE) >= 0 && currentStepIndex > 0) {
              newQuestion = newQuestion.replaceAll(
                PREV_ANSWER_REFERENCE,
                getQuestionOrDefault(contentToSend?.plainTextContent ?? '', false)
              );
            }
            answerContent = newQuestion;
          }
        }

        const messageClientId = getTempMessageId();
        const prompt: ChatMessage = {
          id: null,
          clientId: messageClientId,
          content: contentToSend?.plainTextContent ?? null,
          sender: CLIENT_MESSAGE_SENDER,
          timestamp: new Date(),
          isWizardStep,
          wizardStepNumber,
          promptId: libraryPromptWizardState?.prompt.id ?? null,
        };
        const answer: ChatMessage = {
          id: null,
          clientId: messageClientId,
          sender: AI_MESSAGE_SENDER,
          timestamp: new Date(),
          content: '',
          isPlaceholder: true,
          isWizardStep,
          wizardStepNumber,
          promptId: null,
        };

        if (source === 'input') setComplexContent(stringToComplexContent(''));
        const chatIdToSendMessage =
          chat.id === NEW_CHAT_ID
            ? (
                await createChatMutation({
                  firstMessageContent: contentToSend ?? stringToComplexContent(''),
                  model: gptModel,
                  type: chat.type,
                })
              ).id
            : chat.id;

        await createMessageMutation({
          chatId: chatIdToSendMessage,
          promptAndAnswer: {
            prompt,
            promptExtensions,
            answer,
            answerStatus: ANSWER_MESSAGE_STATUS_OK,
          },
          systemMessage: systemMessage ?? null,
          answerContent,
        });
        if (libraryPromptWizardState) {
          const newLibraryPromptWizardState: LibraryPromptWizardState = {
            ...libraryPromptWizardState,
            steps: libraryPromptWizardState.steps.map((s, i) =>
              currentStepIndex - 1 === i
                ? { ...s, answer: contentToSend?.plainTextContent ?? '' }
                : s
            ),
            currentStepState: {
              index: currentStepIndex + 1,
              adhocValues: [],
              selectedValues: [],
              addNewMode: false,
              newValueDescription: '',
            },
          };
          setLibraryPromptWizardState(newLibraryPromptWizardState);
          setChatContext(chatIdToSendMessage, {
            libraryPromptWizardState: newLibraryPromptWizardState,
          });

          if (isFinalWizardStep) {
            const finalPromptContent = getFinalPrompt(
              libraryPromptWizardState.prompt.content,
              newLibraryPromptWizardState.steps
            );
            setLibraryPromptWizardState(null);
            setChatContext(chatIdToSendMessage, { libraryPromptWizardState: null });

            const finalWizardMessageClientId = getTempMessageId();
            await createMessageMutation({
              chatId: chatIdToSendMessage,
              systemMessage: systemMessage ?? null,
              promptAndAnswer: {
                prompt: {
                  id: null,
                  clientId: finalWizardMessageClientId,
                  content: finalPromptContent,
                  sender: CLIENT_MESSAGE_SENDER,
                  timestamp: new Date(),
                  promptId: libraryPromptWizardState.prompt.id,
                },
                answer: {
                  id: null,
                  clientId: finalWizardMessageClientId,
                  content: '',
                  sender: AI_MESSAGE_SENDER,
                  timestamp: new Date(),
                  isPlaceholder: true,
                  promptId: null,
                },
                answerStatus: ANSWER_MESSAGE_STATUS_OK,
              },
            });
          }
        }
      } catch (e) {
        logger.error('Send handler failed.', e);
      }
    },
    [
      createChatMutation,
      createMessageMutation,
      chat.id,
      chat.type,
      systemMessage,
      libraryPromptWizardState,
      setChatContext,
      gptModel,
    ]
  );

  const [sendMessageCancelled, setSendMessageCancelled] = useState(false);
  const [needToScroll, setNeedToScroll] = useState(false);
  const {
    query: { data: messages, status: messagesStatus },
    queryStatusComponent: messagesQueryStatusComponent,
  } = useResolvedChatMessages(chat.id, !!libraryPromptWizardState);
  useEffect(() => {
    if (needToScroll) {
      scrollToBottom('smooth');
      setNeedToScroll(false);
    }
  }, [messages, needToScroll, scrollToBottom]);

  useEffect(() => {
    if (messages && messages.length === 0 && libraryPromptWizardState) {
      onMessageSend(stringToComplexContent(libraryPromptWizardState.prompt.content), 'library');
    }
  }, [messages, onMessageSend, libraryPromptWizardState]);

  const messagesSuccessfullyLoaded = messagesStatus === 'success';
  useEffect(() => {
    scrollToBottom('auto');
    setNeedToScroll(false);
  }, [chat.id, messagesSuccessfullyLoaded, scrollToBottom]);

  const onSendAgain = useCallback(
    async (message: ResolvedChatMessage) => {
      queryCacheManager.onMessageDeleted(chat.id, message);
      await onMessageSend(
        message.content ? stringToComplexContent(message.content) : null,
        'retry'
      );
    },
    [chat.id, onMessageSend, queryCacheManager]
  );

  const currentWizardStep =
    libraryPromptWizardState &&
    libraryPromptWizardState.currentStepState.index > 0 &&
    libraryPromptWizardState.currentStepState.index <= libraryPromptWizardState.steps.length
      ? libraryPromptWizardState.steps[libraryPromptWizardState.currentStepState.index - 1]
      : null;

  const showVariableMessage =
    currentWizardStep &&
    !currentWizardStep.isFreeForm &&
    ((currentWizardStep.valueOptions !== null && currentWizardStep.valueOptions.length > 0) ||
      (currentWizardStep.variableName && currentWizardStep.variableName !== NUMBER_PLACEHOLDER));
  const awaitingNextWizardStep = libraryPromptWizardState && createMessageStatus === 'loading';

  const lastMessageResolvedToolCalls =
    messages &&
    messages.length > 0 &&
    (messages[messages.length - 1].resolvedToolCalls?.length ?? 0) > 0
      ? messages[messages.length - 1].resolvedToolCalls
      : null;

  const {
    adjustedCreateMessageStatus,
    sendingClientMessageId,
  }: { adjustedCreateMessageStatus: MutationStatus; sendingClientMessageId: string | null } =
    sendMessageCancelled
      ? { adjustedCreateMessageStatus: 'idle', sendingClientMessageId: null }
      : messages && messages.length > 0 && messages[messages.length - 1].isPlaceholder
      ? {
          adjustedCreateMessageStatus: 'loading',
          sendingClientMessageId: messages[messages.length - 1].clientId,
        }
      : {
          adjustedCreateMessageStatus: createMessageStatus,
          sendingClientMessageId: createMessageVariables
            ? createMessageVariables.promptAndAnswer.prompt.clientId
            : null,
        };

  return (
    <>
      {gptModelSelectDiv &&
        createPortal(
          <GptModelSelect
            gptModel={chat.id === NEW_CHAT_ID ? gptModel : chat.model}
            disabled={chat.id !== NEW_CHAT_ID && createChatStatus !== 'loading'}
            slim={slimGptModelSelect}
            onGptModelChanged={(model) => {
              setCurrentModel(model);
              setGptModel(model);
            }}
          />,
          gptModelSelectDiv
        )}
      <div className="messenger__container" style={{ scrollBehavior: 'smooth' }}>
        {messages ? (
          <>
            <div className="messenger__message-list">
              <div className="flex-grow-1" />

              <MessageList
                ref={lastMessageRef}
                chatType={chat.type}
                messages={messages}
                maxAiResponses={MAX_OPEN_AI_RESPONSES_LIMIT}
                hideIntro={hideIntro}
                onIntroMessageSend={async (x) => onMessageSend(stringToComplexContent(x), 'sample')}
                onSendAgain={onSendAgain}
                onOpenLibraryClick={onOpenLibraryClick}
                onNewChatClick={onNewChatClick}
              />
              {(!showVariableMessage || awaitingNextWizardStep) && <MessageListBottomPlaceholder />}
            </div>
            {!awaitingNextWizardStep &&
              (showVariableMessage ? (
                <VariableMessage
                  key={`step-${libraryPromptWizardState?.currentStepState.index}`}
                  chatId={chat.id}
                  variableName={currentWizardStep.variableName}
                  predefinedValues={currentWizardStep.valueOptions}
                  metadata={currentWizardStep.metadata}
                  onSend={async (content) =>
                    onMessageSend(stringToComplexContent(content), 'composer')
                  }
                />
              ) : lastMessageResolvedToolCalls && lastMessageResolvedToolCalls.length > 0 ? (
                <ExecuteToolsMessageInput
                  onModify={async () =>
                    onMessageSend(
                      stringToComplexContent('Modify'),
                      'function',
                      lastMessageResolvedToolCalls.map((x) => ({
                        resolvedToolCall: x,
                        userResult: TOOL_CALL_USER_RESULT_REJECT,
                      }))
                    )
                  }
                  onProceed={async () =>
                    onMessageSend(
                      stringToComplexContent('Proceed'),
                      'function',
                      lastMessageResolvedToolCalls.map((x) => ({
                        resolvedToolCall: x,
                        userResult: TOOL_CALL_USER_RESULT_OK,
                      }))
                    )
                  }
                />
              ) : (
                <TextMessageInput
                  chatId={chat.id}
                  complexContent={complexContent}
                  placeholder={textInputPlaceholder}
                  onChange={setComplexContent}
                  onSend={async () => onMessageSend(complexContent, 'input')}
                  onCancelSending={() => {
                    if (
                      chat.id !== NEW_CHAT_ID &&
                      adjustedCreateMessageStatus === 'loading' &&
                      cancelMessageStatus !== 'loading' &&
                      !!sendingClientMessageId
                    ) {
                      cancelMessageMutation({
                        chatId: chat.id,
                        clientMessageId: sendingClientMessageId,
                      });
                    }
                  }}
                  onManageVariablesClick={onManageVariablesClick}
                  tooManyMessages={
                    messages.filter((m) => m.sender === AI_MESSAGE_SENDER && !m.isWizardStep)
                      .length >= MAX_OPEN_AI_RESPONSES_LIMIT ||
                    messages.filter((m) => m.sender === CLIENT_MESSAGE_SENDER && !m.isWizardStep)
                      .length >= MAX_CLIENT_REQUESTS_LIMIT
                  }
                  maxAiResponses={MAX_OPEN_AI_RESPONSES_LIMIT}
                  sendMessageStatus={adjustedCreateMessageStatus}
                  cancelMessageStatus={cancelMessageStatus}
                />
              ))}
          </>
        ) : (
          messagesQueryStatusComponent
        )}
      </div>
    </>
  );
};
