import { AxiosInstance, AxiosResponse } from 'axios';
import { GoogleOauth } from './types';

type AuthCodeDto = {
  code: string;
  redirectUrl: string;
};

type GoogleOauthDto = {
  isConfigured: boolean;
  email: string | null;
  isDisconnected: boolean;
};

export const notConnectedGoogleOauth: GoogleOauthDto = {
  isConfigured: false,
  email: null,
  isDisconnected: false,
};

const toGoogleOauth = (dto: GoogleOauthDto | null): GoogleOauth =>
  dto
    ? { isConfigured: true, email: dto.email, isDisconnected: dto.isDisconnected }
    : notConnectedGoogleOauth;

export type GoogleCalendarApi = ReturnType<typeof getGoogleCalendarApi>;

export const getGoogleCalendarApi = (axiosInstance: AxiosInstance) => {
  const exchangeAuthCode = async (
    userId: string,
    authCode: string,
    redirectUrl: string
  ): Promise<GoogleOauth> =>
    axiosInstance
      .post<AuthCodeDto, AxiosResponse<GoogleOauthDto>>(`/users/${userId}/google/user-consent`, {
        code: authCode,
        redirectUrl,
      })
      .then((r) => toGoogleOauth(r.data));

  const getGoogleOauth = async (userId: string): Promise<GoogleOauth> => {
    const dto = await axiosInstance.get<GoogleOauthDto>(`/users/${userId}/google/oauth`).then(
      (r) => r.data,
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      (x: any) => {
        if (x.response.status === 404) {
          return null;
        }
        throw x;
      }
    );
    return toGoogleOauth(dto);
  };

  const deleteGoogleOauth = async (userId: string) =>
    axiosInstance.delete(`/users/${userId}/google/oauth`);

  const scheduleResync = async (userId: string): Promise<void> =>
    axiosInstance.post(`/users/${userId}/google/calendar/resync`);

  return {
    exchangeAuthCode,
    getGoogleOauth,
    deleteGoogleOauth,
    scheduleResync,
  };
};
