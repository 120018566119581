import { Dropdown } from 'react-bootstrap';
import React, { useMemo } from 'react';
import {
  CalendarCustomIcon,
  CalendarDayIcon,
  CalendarMonthIcon,
  CalendarQuarterIcon,
  CalendarWeekIcon,
  ChevronDownIcon,
} from '../../assets/icons';
import { DivButton } from './DivButton';

type AnchorProps = React.HTMLProps<HTMLDivElement>;
export type StandardPeriodType = 'Quarter' | 'Month' | 'Week' | 'Day';
export type CustomPeriodType = 'Custom';
export type PeriodType = StandardPeriodType | CustomPeriodType;

export const PeriodIconOld = ({ period, className }: { period: PeriodType; className: string }) => {
  switch (period) {
    case 'Quarter':
      return <CalendarQuarterIcon className={className} />;
    case 'Month':
      return <CalendarMonthIcon className={className} />;
    case 'Week':
      return <CalendarWeekIcon className={className} />;
    case 'Day':
      return <CalendarDayIcon className={className} />;
    case 'Custom':
      return <CalendarCustomIcon className={className} />;
    default:
      throw new Error(`unsupported period: ${period}`);
  }
};

const PeriodIcon = ({ period }: { period: PeriodType }) => {
  switch (period) {
    case 'Quarter':
      return <CalendarQuarterIcon />;
    case 'Month':
      return <CalendarMonthIcon />;
    case 'Week':
      return <CalendarWeekIcon />;
    case 'Day':
      return <CalendarDayIcon />;
    case 'Custom':
      return <CalendarCustomIcon />;
    default:
      throw new Error(`unsupported period: ${period}`);
  }
};

const getLabel = (period: PeriodType): string => {
  switch (period) {
    case 'Quarter':
      return 'Quarter';
    case 'Month':
      return 'Month';
    case 'Week':
      return 'Week';
    case 'Day':
      return 'Day';
    case 'Custom':
      return 'Select dates';
    default:
      return '';
  }
};

const CustomToggle = (period: PeriodType) =>
  React.forwardRef<HTMLDivElement, AnchorProps>(({ onClick }, ref) => (
    <div
      className="period-picker__selected"
      ref={ref}
      onClick={(e) => {
        e.preventDefault();
        onClick && onClick(e);
      }}
      style={{ textDecoration: 'none' }}
    >
      <PeriodIconOld period={period} className="period-picker__selected-icon" />
      <ChevronDownIcon className="period-picker__selected-chevron" />
    </div>
  ));

const PeriodItem = ({
  period,
  value,
  dataTest,
}: {
  period: PeriodType;
  value: PeriodType;
  dataTest: string;
}) => (
  <Dropdown.Item eventKey={period} bsPrefix="period-picker__dropdown-item">
    <DivButton
      text={getLabel(period)}
      icon={<PeriodIcon period={period} />}
      isSelected={value === period}
      dataTest={dataTest}
    />
  </Dropdown.Item>
);

export const PeriodPicker = (props: {
  onSelect: (v: PeriodType) => void;
  value: PeriodType;
  showDayPeriod: boolean;
  showCustomPeriod: boolean;
}) => {
  const { onSelect, value } = props;

  const toggleElement = useMemo(() => CustomToggle(value), [value]);

  return (
    <Dropdown
      onSelect={async (eventKey) => {
        if (!eventKey) return;
        await onSelect(eventKey as PeriodType);
      }}
      drop="down"
      alignRight={false}
    >
      <Dropdown.Toggle as={toggleElement} className="d-flex align-items-center" />
      <Dropdown.Menu title="">
        <PeriodItem
          period="Quarter"
          value={value}
          dataTest="period-picker__period-button--quarter"
        />
        <PeriodItem period="Month" value={value} dataTest="period-picker__period-button--month" />
        <PeriodItem period="Week" value={value} dataTest="period-picker__period-button--week" />
        {props.showDayPeriod && (
          <PeriodItem period="Day" value={value} dataTest="period-picker__period-button--day" />
        )}
        {props.showCustomPeriod && (
          <PeriodItem
            period="Custom"
            value={value}
            dataTest="period-picker__period-button--custom"
          />
        )}
      </Dropdown.Menu>
    </Dropdown>
  );
};
