import * as yup from 'yup';
import { string } from 'yup';
import { ErrorMessage, Field, Formik } from 'formik';
import { Button, Form } from 'react-bootstrap';
import { User } from '../types';
import { errorToString } from '../../util/utils';
import { SYSTEM_ROLES, SystemRole } from '../../auth/systemRole';
import { ModalHeader } from '../../commons/components/ModalHeader';
import React from 'react';
import { ColoredSelect } from '../../program/components/ColoredSelect';
import { FieldInputProps, FormikHelpers } from 'formik/dist/types';
import { extractUserName } from '../utils';
import { FooterError } from '../../commons/components/FooterError';

const validationSchema = yup.object({
  email: string().email('Must contain a valid email address').required('Enter an email address'),
});

type UserEditProps = {
  disabled: boolean;
  initialValues: User;
  createError: unknown;
  updateError: unknown;
  deleteError: unknown;
  handleHideClick: () => void;
  handleApplyClick: (change: User) => Promise<void>;
  action: 'UPDATE' | 'CREATE' | 'DELETE';
};

const headerTitle = (action: 'UPDATE' | 'CREATE' | 'DELETE', user: User) => {
  switch (action) {
    case 'UPDATE':
      return extractUserName(user);
    case 'CREATE':
      return 'Invite new member';
    case 'DELETE':
      return `Remove ${extractUserName(user)}?`;
    default:
      throw new Error(`Unexpected member edit action: ${action}`);
  }
};

const applyButtonTitle = (action: 'UPDATE' | 'CREATE' | 'DELETE') => {
  switch (action) {
    case 'UPDATE':
      return 'Save';
    case 'CREATE':
      return 'Send invite';
    case 'DELETE':
      return 'Remove member';
    default:
      throw new Error(`Unexpected member edit action: ${action}`);
  }
};

const RoleSelectorField = (props: {
  field: FieldInputProps<SystemRole>;
  form: FormikHelpers<User>;
}) => {
  const {
    field: { value, name: fieldName },
    form: { setFieldValue },
  } = props;

  return (
    <div>
      <ColoredSelect
        initialValue={value}
        dataTestPrefix={`user-edit__role`}
        coloredItems={SYSTEM_ROLES}
        onValueChange={(x) => {
          setFieldValue(fieldName, x);
        }}
        isSearchable={false}
        isRequired={true}
        width={320}
        showInfo={true}
      />
      <div className="growegy-form__input-comment user-edit__input-help--sized">
        {value.description}
      </div>
    </div>
  );
};

export const UserEdit = ({
  disabled,
  initialValues,
  createError,
  deleteError,
  updateError,
  handleHideClick,
  handleApplyClick,
  action,
}: UserEditProps) => {
  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleApplyClick}
      enableReinitialize={true}
    >
      {({ touched, errors, isValid, dirty, handleSubmit, values }) => {
        return (
          <Form onSubmit={handleSubmit} className="d-flex flex-column h-100">
            <ModalHeader title={headerTitle(action, values)} handleClose={handleHideClick} />

            <div className="growegy-modal__form-body">
              {action !== 'DELETE' ? (
                <div>
                  <Form.Group controlId="email" className="growegy-form__row">
                    <label htmlFor="email" className="growegy-form__label">
                      Email
                    </label>
                    <div>
                      <Field
                        type="text"
                        name="email"
                        value={values.email}
                        placeholder="Enter email"
                        disabled={disabled || action !== 'CREATE'}
                        data-test="user-edit__email"
                        className={`growegy-form__input user-edit__input--sized ${
                          touched.email && errors.email ? 'is-invalid' : ''
                        }`}
                      />
                      <ErrorMessage
                        component="div"
                        name="email"
                        className="invalid-feedback"
                        data-test="user-edit__email-validation-error"
                      />
                    </div>
                  </Form.Group>
                  <Form.Group controlId="email" className="growegy-form__row">
                    <label htmlFor="email" className="growegy-form__label">
                      Role
                    </label>
                    <Field
                      name="role"
                      value={values.role}
                      disabled={disabled}
                      data-test="user-edit__role"
                      className="growegy-form__input user-edit__input--sized"
                      component={RoleSelectorField}
                    />
                  </Form.Group>
                </div>
              ) : (
                <span className="growegy-font14 growegy-color-neutral-500">
                  {`You’re removing ${extractUserName(values)} (${values.email}) from your team.`}
                </span>
              )}
            </div>
            <div className="growegy-modal__footer">
              <>
                {createError && (
                  <FooterError
                    dataTest="user-edit__error-create"
                    message={`Failed to invite member: ${errorToString(createError)}`}
                  />
                )}
                {updateError && (
                  <FooterError
                    dataTest="user-edit__error-update"
                    message={`Failed to update member: ${errorToString(updateError)}`}
                  />
                )}
                {deleteError && (
                  <FooterError
                    dataTest="user-edit__error-delete"
                    message={`Failed to delete member: ${errorToString(deleteError)}`}
                  />
                )}
                <Button
                  variant="light"
                  className="button-cancel"
                  onClick={handleHideClick}
                  disabled={disabled}
                  data-test="user-edit__cancel"
                >
                  Cancel
                </Button>
                <Button
                  type="submit"
                  variant={action === 'DELETE' ? 'danger' : 'primary'}
                  className={action !== 'DELETE' ? 'button-apply' : 'button-destructive'}
                  disabled={!isValid || disabled || (action !== 'DELETE' && !dirty)}
                  data-test="user-edit__apply"
                >
                  {applyButtonTitle(action)}
                </Button>
              </>
            </div>
          </Form>
        );
      }}
    </Formik>
  );
};
