import {
  CustomerSubscription,
  futureInvoiceVisualData,
  Invoice,
  INVOICE_TYPE_FUTURE,
  INVOICE_TYPE_ISSUED,
  InvoiceAndBenefits,
  invoiceStatusVisualData,
  IssuedInvoice,
  pastDueVisualData,
} from './types';
import { Benefit, BillingPlan, BillingPlanMonth } from './billingPlans';
import moment from 'moment';

export const isInvoicePaid = (invoice: Invoice): boolean =>
  invoice.type === INVOICE_TYPE_ISSUED && invoice.status === 'PAID';

export const isInvoicePastDue = (invoice: Invoice, now: Date = new Date()): boolean =>
  invoice.type === INVOICE_TYPE_ISSUED &&
  invoice.dueDate.valueOf() < now.valueOf() &&
  (invoice.status === 'OPEN' || invoice.status === 'UNCOLLECTIBLE');

export const isInvoiceNonPastDue = (invoice: Invoice, now: Date = new Date()): boolean =>
  invoice.type === INVOICE_TYPE_FUTURE ||
  (invoice.dueDate.valueOf() >= now.valueOf() && invoice.status === 'OPEN');

export const isInvoiceObligatory = (x: Invoice) =>
  (x.type === INVOICE_TYPE_FUTURE && !x.optional) || isInvoicePayable(x);

export const getInvoiceRenderInfo = (invoice: Invoice) =>
  invoice.type === INVOICE_TYPE_FUTURE
    ? futureInvoiceVisualData(invoice.createdDate)
    : isInvoicePastDue(invoice)
    ? pastDueVisualData
    : invoiceStatusVisualData[invoice.status];

export const isInvoicePayable = (invoice: Invoice) =>
  invoice.type === INVOICE_TYPE_ISSUED &&
  (invoice.status === 'OPEN' || invoice.status === 'UNCOLLECTIBLE');

const mapBenefit = (
  benefit: string,
  reportSent: null | boolean,
  reportingInProgress: null | boolean
) =>
  benefit === Benefit.CREDIT_REPORT && reportSent
    ? Benefit.CREDIT_REPORT_SENT
    : benefit === Benefit.HIGHER_CREDIT_LIMIT && reportSent
    ? Benefit.HIGHER_CREDIT_LIMIT_REPORT_SENT
    : benefit === Benefit.HIGHER_CREDIT_LIMIT && reportingInProgress
    ? Benefit.HIGHER_CREDIT_LIMIT_REPORTING_IN_PROGRESS
    : benefit;

export const generateInvoicesAndBenefits = (
  billingPlan: BillingPlan,
  invoices: Invoice[],
  now?: Date
): { invoicesAndBenefits: InvoiceAndBenefits[]; historicInvoices: Invoice[] } => {
  const invoicesCopy = [...invoices];
  invoicesCopy.sort(
    (left, right) => getInvoiceStart(left).valueOf() - getInvoiceStart(right).valueOf()
  );

  let firstIndex = invoicesCopy.length;
  let currentStart = now ?? new Date();
  for (let i = invoicesCopy.length - 1; i >= 0; i--) {
    const currentInvoice = invoicesCopy[i];
    if (currentInvoice.billingPlanName !== billingPlan.name) break;
    const currentInvoiceStart = getInvoiceStart(currentInvoice);
    if (
      moment(Math.max(getInvoiceMaxEnd(currentInvoice).valueOf(), currentInvoiceStart.valueOf()))
        .add(1, 'month')
        .valueOf() < currentStart.valueOf()
    )
      break;
    currentStart = currentInvoiceStart;
    firstIndex = i;
  }
  const historicInvoices = invoicesCopy.slice(0, firstIndex);
  const continuousInvoices = invoicesCopy.slice(firstIndex);
  const invoicesPerYear = billingPlan.months.filter((x) => x.price !== null).length;
  const yearsToShow = Math.max(
    Math.ceil((continuousInvoices.length + billingPlan.timelineLookAheadMonths) / invoicesPerYear),
    1
  );
  let invoiceCursor = -1;
  return {
    invoicesAndBenefits: Array<BillingPlanMonth[]>(yearsToShow)
      .fill(billingPlan.months)
      .flat()
      .map((x) => {
        if (x.price !== null) invoiceCursor += 1;
        const currentInvoice =
          invoiceCursor >= 0 && invoiceCursor < continuousInvoices.length
            ? continuousInvoices[invoiceCursor]
            : null;
        const invoice = x.price === null ? null : currentInvoice ?? x.price;
        const referenceInvoice = x.price === null ? currentInvoice : null;

        const reportSent =
          (invoice &&
            typeof invoice === 'object' &&
            invoice.type === INVOICE_TYPE_ISSUED &&
            invoice.createdDate &&
            !!now &&
            wasCreditReportSent(invoice.createdDate, now)) ||
          (referenceInvoice && !!now && now > getInvoiceMaxEnd(referenceInvoice));
        const reportingInProgress =
          referenceInvoice &&
          !!now &&
          now > moment(getInvoiceStart(referenceInvoice)).add(1, 'month').toDate() &&
          now <= getInvoiceMaxEnd(referenceInvoice);

        return {
          invoice: invoice,
          referenceInvoice: referenceInvoice,
          regularBenefits: x.regularBenefits.map((benefit) =>
            mapBenefit(benefit, reportSent, reportingInProgress)
          ),
          specialBenefits: x.specialBenefits,
        };
      }),
    historicInvoices,
  };
};

export const wasCreditReportSent = (invoiceCreatedDate: Date, now: Date) => {
  const invoiceFirstReportDate = moment(invoiceCreatedDate)
    .utc()
    .startOf('month')
    .add(moment(invoiceCreatedDate).utc().date() < 15 ? 0 : 1, 'month')
    .date(16)
    .toDate();
  return invoiceFirstReportDate.valueOf() <= now.valueOf();
};

export const getInvoiceStart = (invoice: Invoice): Date => {
  if (invoice.type === INVOICE_TYPE_FUTURE) return invoice.periodStart;
  const startDates = invoice.lines.filter((x) => x.start !== null).map((x) => x.start!!);
  if (startDates.length === 0) return invoice.periodStart;
  return startDates.reduce((acc, curr) => (acc.valueOf() > curr.valueOf() ? curr : acc));
};

export const getInvoiceMaxEnd = (invoice: Invoice): Date => {
  if (invoice.type === INVOICE_TYPE_FUTURE) return invoice.periodEnd;
  const periods = [invoice.periodEnd, ...invoice.lines.flatMap((l) => (l.end ? [l.end] : []))];

  return periods.reduce((acc, curr) => (acc.valueOf() > curr.valueOf() ? acc : curr));
};

export const isPlanAlreadyTerminated = (activeSubscription: CustomerSubscription | null) =>
  activeSubscription === null ||
  (!!activeSubscription.cancelAt && activeSubscription.cancelAt.valueOf() <= new Date().valueOf());

export const getScheduledCancellationDate = (activeSubscription: CustomerSubscription | null) =>
  !isPlanAlreadyTerminated(activeSubscription) && activeSubscription !== null
    ? activeSubscription.cancelAt
    : null;

export const getInvoiceClickSource = (invoice: IssuedInvoice, context: string) =>
  isInvoicePayable(invoice) ? context : `${context}_download`;
