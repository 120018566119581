import { object, string } from 'yup';
import { Form, Formik } from 'formik';
import AuthInput from './AuthInput';
import useAnalyticsPage from '../../web-analytics/hooks/useAnalyticsPage';
import { Page } from '../../web-analytics/Page';
import { Button } from 'react-bootstrap';
import { printAuthErrorMessage } from '../utils';

const validationSchema = object({
  email: string().email('Must contain a valid email address').required('Enter an email address'),
});

type Payload = {
  email: string;
};

const userHasNotConfirmedEmail = (errMsg: unknown): boolean =>
  errMsg === 'User password cannot be reset in the current state.';

const printInitForgotPasswordError = (errMsg: unknown): string => {
  if (userHasNotConfirmedEmail(errMsg)) {
    return (
      'You need to activate your account first using the temporary password ' +
      'sent to your email. If you have any problems, contact support@growegy.com.'
    );
  }
  return printAuthErrorMessage(errMsg);
};

const ForgotPasswordInit = (props: { onSubmit: (email: string) => Promise<void> }) => {
  useAnalyticsPage(Page.FORGOT_PASSWORD_EMAIL);

  const { onSubmit } = props;
  const initialValues: Payload = {
    email: '',
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={async (values, formikHelpers) => {
        formikHelpers.setStatus(null);
        await onSubmit(values.email).catch((err) => formikHelpers.setStatus({ error: err }));
      }}
    >
      {({ touched, errors, isSubmitting, status }) => (
        <Form>
          <div className="auth-dialog">
            <div className="auth-dialog__header">
              <div className="auth-dialog__title">Reset your password</div>
              <div className="auth-dialog__subtitle">
                Enter the email associated with your Growegy account and we'll send you a
                verification code <br /> to reset your password.
              </div>
            </div>
            <div className="auth-dialog__fields">
              <div className="auth-dialog__field">
                <AuthInput
                  type="email"
                  name="email"
                  autoComplete="current-password"
                  placeholder="Enter work email"
                  dataTest="forgot-password-init__email"
                  invalid={!!touched.email && !!errors.email}
                />
              </div>
            </div>
            <div className="auth-dialog__info">
              {status && status.error && (
                <div className="alert-danger fade show" data-test="forgot-password-init__error">
                  {printInitForgotPasswordError(status.error)}
                </div>
              )}{' '}
            </div>
            <div className="auth-dialog__footer">
              <Button
                type="submit"
                className="button-apply"
                disabled={isSubmitting}
                data-test="forgot-password-init__submit"
              >
                Send code
              </Button>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default ForgotPasswordInit;
