import { Prompt, PromptCategory, PromptMark } from '../../types';
import { Button } from 'react-bootstrap';
import useAnalytics from '../../../web-analytics/webAnalyticsContext';
import { analyticsTrack } from '../../../web-analytics/webAnalytics';
import { AnalyticsEvent } from '../../../web-analytics/AnalyticsEvent';
import { PromptWithPlaceholders } from './PromptWithPlaceholders';
import React from 'react';
import { PromptMarkButton } from './PromptMarkButton';
import clsx from 'clsx';
import { categoryColorAsProgramType } from '../../util';
import { ProgramType } from '../../../program/types';
import { PromptPanelCategories } from './PromptPanelCategory';

export const PromptPanel = (props: {
  prompt: Prompt;
  promptMark: PromptMark | null;
  onPromptClicked: (prompt: Prompt) => void;
  onPromptMark: (mark: number) => Promise<void>;
  asteriskMarkIsFlippable?: boolean;
  existedTypes: ProgramType[];
  existedCategories: PromptCategory[];
}) => {
  const analytics = useAnalytics();
  const {
    prompt,
    onPromptClicked,
    promptMark,
    onPromptMark,
    asteriskMarkIsFlippable,
    existedTypes,
    existedCategories,
  } = props;
  const promptCategories: PromptCategory[] = prompt.categories.map((c) => {
    const color = categoryColorAsProgramType({ name: c }, existedTypes, existedCategories);
    return { color, icon: null, name: c };
  });
  return (
    <Button
      data-test={`prompt-panel__button-${prompt.title}`}
      className="prompt-panel__container button-default"
      onClick={() => {
        onPromptClicked(prompt);
        analyticsTrack(analytics, AnalyticsEvent.PROMPT_SELECTED);
      }}
    >
      <div className="w-100">
        <div className="prompt-panel__header">
          <PromptPanelCategories categories={promptCategories} />
          <PromptMarkButton
            className="prompt-mark-button prompt-mark-button--flippable"
            iconClassName={clsx('variables-table__mark-icon', {
              'variables-table__mark-icon--flippable': asteriskMarkIsFlippable,
            })}
            promptMark={promptMark}
            onClick={async (e) => {
              e.preventDefault();
              e.stopPropagation();
              const newVal = ((promptMark?.mark ?? 0) + 1) % 2;
              await onPromptMark(newVal);
            }}
          />
        </div>
        <div data-test={`prompt-panel__title-${prompt.title}`}>
          <span>{prompt.title}</span>
        </div>
      </div>
      <div data-test={`prompt-panel__content-${prompt.title}`} className="prompt-panel__content">
        <PromptWithPlaceholders
          libraryPromptContent={prompt.content}
          className="prompt-with-placeholders__text--light500"
        />
      </div>
    </Button>
  );
};
