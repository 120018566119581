import { Button } from 'react-bootstrap';
import React from 'react';

export const MaxMessagesLimitMessage = (props: {
  maxAiResponses: number;
  onNewChatClick: () => void;
}) => {
  const { maxAiResponses, onNewChatClick } = props;
  return (
    <div className="message-list__message-limit-reached-container">
      {`Each individual chat is restricted to a maximum of ${maxAiResponses} messages.`}
      <br />
      In order to continue with Growegy AI, please create a new chat.
      <Button
        className="button-apply flex-shrink-0"
        onClick={() => onNewChatClick()}
        data-test="message-list__message-limit-reached-new-chat"
      >
        New chat
      </Button>
    </div>
  );
};
