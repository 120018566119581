import { SystemRole, USER_ROLE } from '../auth/systemRole';

export type UserStatus = 'confirmed' | 'unconfirmed' | 'need-to-change-password' | 'unknown';
export type User = {
  id: string;
  email: string;
  role: SystemRole;
  status: UserStatus;
  firstName: string;
  lastName: string;
  avatar: string | null;
};

export type UserRow = User & {
  username: string;
};

export type UserSettings = {
  firstName: string;
  lastName: string;
  title: string;
  location: string;
  version?: number;
};

export const UnknownProgramOwner: User = {
  id: 'unknown-program-owner-id',
  status: 'unknown',
  role: USER_ROLE,
  email: '',
  firstName: '',
  lastName: '',
  avatar: null,
};

export const NoProgramOwner: User = {
  id: 'no-program-owner-id',
  status: 'unknown',
  role: USER_ROLE,
  email: '',
  firstName: '',
  lastName: '',
  avatar: null,
};
