import { ProgramTaskIcon } from '../../../assets/icons';
import { ResolvedProgramSummary, ResolvedProgramTask } from '../../types';
import { renderTaskValue } from '../../util';
import clsx from 'clsx';

export const TaskCalendarEvent = (props: {
  task: ResolvedProgramTask;
  programSummary: ResolvedProgramSummary;
  visibleColumns: string[];
}) => {
  const { task, programSummary, visibleColumns } = props;
  const taskFieldsJoinedValue = visibleColumns
    .flatMap((id) => {
      const value = renderTaskValue(task, id);
      return value ? [value] : [];
    })
    .join(' · ');

  return (
    <div className="calendar__task-event-content">
      <div
        className="calendar__task-event-header"
        data-test={`calendar__task-event-content-${task.name}`}
      >
        <ProgramTaskIcon
          className="calendar__task-icon"
          data-test="calendar__task-icon"
          fill={programSummary.type.color}
        />
        <span
          className={clsx('calendar__task-name', {
            'calendar__task--done': task.status === 'done',
          })}
          data-test="calendar__task-name"
        >
          {task.name}
        </span>
      </div>
      {taskFieldsJoinedValue && (
        <div className="calendar__task-event-column text--truncated">{taskFieldsJoinedValue}</div>
      )}
    </div>
  );
};
