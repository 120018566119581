import { Chat, NEW_CHAT_ID } from '../../types';
import { BinIcon, ChatBalloonIcon, PencilIcon, PlusSmallIcon } from '../../../assets/icons';
import { DivButton } from '../../../commons/components/DivButton';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useFetchedChats } from '../hooks/useFetchedChats';
import clsx from 'clsx';
import { MoreMenu } from '../../../commons/components/MoreMenu';
import { useMutation } from '@tanstack/react-query';
import useApi from '../../../api/backendApiContext';
import useAuth from '../../../auth/authContext';
import { ConfirmModal } from '../../../commons/components/ConfirmModal';
import { useQueryCacheManager } from '../../../hooks/useQueryCacheManager';
import useAnalytics from '../../../web-analytics/webAnalyticsContext';
import { analyticsTrack } from '../../../web-analytics/webAnalytics';
import { AnalyticsEvent } from '../../../web-analytics/AnalyticsEvent';
import { createNewChat } from '../../util';
import { ChatType } from '../../dto';

const ChatListItem = (props: {
  chat: Chat;
  isSelected: boolean;
  onChatSelected: (chat: Chat) => void;
}) => {
  const analytics = useAnalytics();
  const { chat, isSelected, onChatSelected } = props;
  const { growegyAIApi } = useApi();
  const {
    state: { user },
  } = useAuth();

  const queryCacheManager = useQueryCacheManager();
  const { mutate: renameChat } = useMutation(
    async (newTitle: string) => {
      if (!user) return null;
      return growegyAIApi.renameChat(user.userId, chat.id, newTitle);
    },
    {
      onMutate: (newTitle) => {
        queryCacheManager.onChatUpdated({ ...chat, title: newTitle });
      },
      onSuccess: (updatedChat) => {
        if (!updatedChat) return;
        queryCacheManager.onChatUpdated(updatedChat);
        analyticsTrack(analytics, AnalyticsEvent.CHAT_RENAMED);
      },
    }
  );

  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const { mutate: deleteChat } = useMutation(
    async () => {
      if (!user) return;
      onChatSelected(createNewChat(chat.type));
      return growegyAIApi.deleteChat(user.userId, chat.id);
    },
    {
      onMutate: () => {
        queryCacheManager.onChatDeleted(chat);
      },
      onSuccess: () => {
        queryCacheManager.onChatDeleted(chat);
        analyticsTrack(analytics, AnalyticsEvent.CHAT_DELETED);
      },
    }
  );

  const chatNameInputRef = useRef<HTMLInputElement>(null);
  const [isEditing, setIsEditing] = useState(false);
  useEffect(() => {
    if (!isEditing || !chatNameInputRef.current) return;
    chatNameInputRef.current.value = chat.title;
    chatNameInputRef.current.focus();
    chatNameInputRef.current.selectionStart = 0;
    chatNameInputRef.current.selectionEnd = chatNameInputRef.current.value.length;
  }, [isEditing, chat.title]);

  return (
    <>
      <div
        className={clsx('chat-list__item-container', {
          'chat-list__item-container--selected': isSelected,
        })}
        data-test="chat-list__item-container"
        onClick={() => {
          onChatSelected(chat);
        }}
      >
        <ChatBalloonIcon className="flex-shrink-0" />
        <div className="chat-list__item-name">
          {isEditing ? (
            <input
              ref={chatNameInputRef}
              type="text"
              maxLength={100}
              className="growegy-form__input w-100"
              placeholder="Enter chat name"
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  const newTitle = (e.target as HTMLInputElement).value;
                  if (!newTitle) return;
                  renameChat(newTitle);
                  setIsEditing(false);
                } else if (e.key === 'Escape') {
                  setIsEditing(false);
                }
              }}
              onBlur={() => {
                setIsEditing(false);
              }}
              data-test="chat-list__item-name-input"
            />
          ) : (
            <div className="text-truncate w-100" data-test="chat-list__item-name-label">
              {chat.title}
            </div>
          )}
        </div>
        {!isEditing && (
          <MoreMenu
            menuItems={[
              {
                type: 'standard',
                text: 'Rename',
                icon: <PencilIcon />,
                dataTest: 'chat-list__item-rename',
                onClick: () => setIsEditing(true),
              },
              {
                type: 'standard',
                text: 'Delete',
                icon: <BinIcon />,
                dataTest: 'chat-list__item-delete',
                onClick: () => setShowDeleteConfirmation(true),
              },
            ]}
            menuButtonDataTest="chat-list__item-menu-button"
            menuDataTest="chat-list__item-menu"
          />
        )}
      </div>
      {showDeleteConfirmation && (
        <ConfirmModal
          title={`Delete the “${chat.title}” chat?`}
          message="You won’t be able to restore it."
          footer={{
            type: 'show',
            applyButton: {
              label: 'Delete chat',
              variant: 'danger',
              onClick: () => {
                setShowDeleteConfirmation(false);
                return deleteChat();
              },
            },
            cancelButton: {
              label: 'Cancel',
              onClick: () => {
                setShowDeleteConfirmation(false);
              },
            },
          }}
        />
      )}
    </>
  );
};

export const ChatList = (props: {
  chatType: ChatType;
  selectedChatId: string | null;
  onChatSelected: (chat: Chat) => void;
}) => {
  const { chatType, selectedChatId, onChatSelected } = props;
  const {
    query: { data },
    queryStatusComponent,
  } = useFetchedChats(chatType);

  const onChatSelectedCallback = useCallback(
    (chat: Chat) => {
      onChatSelected(chat);
    },
    [onChatSelected]
  );

  return (
    <div className="chat-list__container">
      <>
        <DivButton
          isGray={true}
          className={clsx('chat-list__add-new-btn', {
            'button-default--selected': selectedChatId === NEW_CHAT_ID,
          })}
          icon={<PlusSmallIcon />}
          text="Add new"
          dataTest="chat-list__add-new-btn"
          onClick={() => {
            onChatSelectedCallback(createNewChat(chatType));
          }}
        />
        {data
          ? data.length > 0 && (
              <div className="chat-list__items-with-header">
                <div data-test="chat-list__recent-header" className="chat-list__recent-header">
                  Recent
                </div>
                <div className="chat-list__items">
                  {data.map((x) => (
                    <ChatListItem
                      key={x.id}
                      chat={x}
                      isSelected={selectedChatId === x.id}
                      onChatSelected={onChatSelectedCallback}
                    />
                  ))}
                </div>
              </div>
            )
          : queryStatusComponent}
      </>
    </div>
  );
};
