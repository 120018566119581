import { useMutation } from '@tanstack/react-query';
import { saveDownloadFile } from '../../util/file-utils';
import { analyticsTrack } from '../../web-analytics/webAnalytics';
import { AnalyticsEvent } from '../../web-analytics/AnalyticsEvent';
import useApi from '../../api/backendApiContext';
import useAnalytics from '../../web-analytics/webAnalyticsContext';

export const useDownloadAllProgramsToCsvMutation = (
  onError: (err: unknown) => void,
  onSuccess?: () => void
) => {
  const { programApi } = useApi();
  const analytics = useAnalytics();
  return useMutation(
    async () => {
      const response = await programApi.downloadAllProgramsToCSV();
      saveDownloadFile(new Blob([response], { type: 'text/plain' }), 'programs.csv');
      logger.info('All programs downloaded');
      analyticsTrack(analytics, AnalyticsEvent.PROGRAM_DOWNLOADED_AS_CSV);
    },
    {
      onError: (error) => onError(error),
      onSuccess: () => onSuccess && onSuccess(),
    }
  );
};
