import React, { PropsWithChildren, ReactElement, useState } from 'react';
import { Button } from 'react-bootstrap';
import Modal, { Classes } from 'react-modal';

import { ModalHeader } from './ModalHeader';

type ButtonVariant = 'primary' | 'danger' | 'light';
type ButtonProps = {
  label: string;
  variant?: ButtonVariant;
  disabled?: boolean;
  onClick: () => void;
};

type DialogFooterProps =
  | {
      type: 'show';
      applyButton?: ButtonProps;
      cancelButton?: ButtonProps;
      extraButtons?: ButtonProps[];
    }
  | {
      type: 'hide';
      cancel: () => void;
    };

export const FooterButtons = (props: ButtonProps & { dataTest: string }) => {
  const { variant, disabled, onClick, label, dataTest } = props;
  return (
    <Button
      variant={variant}
      className={
        (variant ?? 'primary') === 'primary'
          ? 'button-apply'
          : (variant ?? 'primary') === 'danger'
          ? 'button-destructive'
          : 'button-cancel'
      }
      disabled={disabled}
      onClick={onClick}
      data-test={dataTest}
    >
      {label}
    </Button>
  );
};

export type ConfirmDialogProps = {
  title: string;
  subTitle?: string;
  titleIcon?: ReactElement;
  message?: string;
  footer: DialogFooterProps;
  className?: string | Classes | undefined;
  overlayClassName?: string | Classes | undefined;
  shouldCloseOnEsc?: boolean | undefined;
  shouldCloseOnOverlayClick?: boolean | undefined;
  ariaHideApp?: boolean | undefined;
  shouldReturnFocusAfterClose?: boolean | undefined;
  onClose?: () => void;
};

export const ConfirmModal = (props: PropsWithChildren<ConfirmDialogProps>) => {
  const {
    title,
    subTitle,
    titleIcon,
    message,
    footer,
    children,
    shouldCloseOnOverlayClick,
    shouldCloseOnEsc,
    ariaHideApp,
    shouldReturnFocusAfterClose,
    className,
    onClose,
  } = props;
  const [showModal, setShowModal] = useState(true);

  const handleCancelClick = () => {
    setShowModal(false);
    if (footer.type === 'hide') {
      footer.cancel();
    } else if (footer.cancelButton) {
      footer.cancelButton.onClick();
    }
    if (onClose) onClose();
  };

  const handleApplyClick = () => {
    setShowModal(false);
    if (footer.type === 'show' && footer.applyButton) {
      footer.applyButton.onClick();
    }
    if (onClose) onClose();
  };
  return (
    <Modal
      isOpen={showModal}
      className={className ?? 'growegy-modal--confirm-dialog'}
      overlayClassName="growegy-modal__overlay growegy-modal__overlay--centered"
      onRequestClose={handleCancelClick}
      shouldCloseOnOverlayClick={shouldCloseOnOverlayClick}
      shouldCloseOnEsc={shouldCloseOnEsc}
      ariaHideApp={ariaHideApp}
      shouldReturnFocusAfterClose={shouldReturnFocusAfterClose}
    >
      <div className="growegy-modal__confirm-layout" data-test="growegy-modal__confirm-layout">
        <ModalHeader
          title={title}
          subTitle={subTitle}
          titleIcon={titleIcon}
          handleClose={handleCancelClick}
        />
        <div
          className="growegy-modal__form-body growegy-modal__form-body--withMessage"
          data-test="growegy-modal__form-body"
        >
          {message && (
            <span data-test="confirm-modal__message" className="growegy-title14--moderat">
              {message}
            </span>
          )}
          {children}
        </div>
        {footer.type === 'show' && (
          <div className="growegy-modal__footer">
            {footer.cancelButton && (
              <FooterButtons
                {...footer.cancelButton}
                variant={footer.cancelButton.variant ?? 'light'}
                onClick={handleCancelClick}
                dataTest="confirm-modal__cancel"
              />
            )}
            {footer.extraButtons &&
              footer.extraButtons.map((x) => (
                <FooterButtons
                  key={x.label}
                  {...x}
                  variant={x.variant ?? 'light'}
                  dataTest={`confirm-modal__${x.label.replace(' ', '_')}`}
                />
              ))}
            {footer.applyButton && (
              <FooterButtons
                {...footer.applyButton}
                variant={footer.applyButton.variant ?? 'primary'}
                onClick={handleApplyClick}
                dataTest="confirm-modal__apply"
              />
            )}
          </div>
        )}
      </div>
    </Modal>
  );
};
