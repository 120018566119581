import useApi from '../../api/backendApiContext';
import { useQueryCacheManager } from '../../hooks/useQueryCacheManager';
import { useQuery } from '@tanstack/react-query';
import { ReactQueryConstants } from '../../commons/constants';
import React from 'react';
import { QueryStatus } from '../../commons/components/QueryStatus';
import { AnalyticsEvent } from '../../web-analytics/AnalyticsEvent';
import { useQueryErrorAnalyticsPublisher } from '../../log/hooks/useQueryErrorAnalyticPublisher';

export const useFetchedBillingCustomer = (props?: {
  withTracking?: boolean;
  refetchOnWindowFocus?: boolean | 'always';
}) => {
  const { billingApi } = useApi();
  const queryCacheManager = useQueryCacheManager();
  const queryKey = queryCacheManager.getBillingCustomerCacheKey();
  const query = useQuery(queryKey, async () => billingApi.getBillingCustomer(), {
    staleTime: ReactQueryConstants.billingStaleTime,
    refetchInterval: ReactQueryConstants.billingRefetchTime,
    refetchOnWindowFocus: props?.refetchOnWindowFocus,
  });

  useQueryErrorAnalyticsPublisher(
    props?.withTracking ? query.error : null,
    'getBillingCustomer',
    AnalyticsEvent.BILLING_PORTAL_FAILED
  );

  const queryStatusComponent = (
    <QueryStatus
      queryKey={queryKey}
      queryStatus={query.status}
      dataTestPrefix="customer"
      failedToLoadText="Something went wrong. Try reloading the page multiple times. If the issue persists, contact support@growegy.com"
    />
  );

  return { query, queryStatusComponent };
};
