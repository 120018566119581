import { PromptCategory } from '../../types';
import React from 'react';
import { hexToRGB } from '../../../program/util';

export const PromptPanelCategory = (props: { category: PromptCategory }) => {
  const { category } = props;
  return (
    <div
      className="prompt-panel__category"
      data-test="prompt-panel__category"
      style={{
        borderColor: hexToRGB(category.color, 1),
        color: hexToRGB(category.color, 1),
      }}
    >
      <span
        className="prompt-panel__category--font"
        data-test={`prompt-panel__category-${category.name}`}
      >
        {category.name}
      </span>
    </div>
  );
};

export const PromptPanelCategories = (props: { categories: PromptCategory[] }) => {
  const { categories } = props;

  return (
    <div data-test="prompt-panel__categories" className="prompt-panel__categories">
      {categories.map((category, i) => {
        return (
          <PromptPanelCategory
            key={`prompt-panel-category__${category.name}__${i}`}
            category={category}
          />
        );
      })}
    </div>
  );
};
