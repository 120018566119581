import { FutureInvoice, Invoice, INVOICE_TYPE_FUTURE, INVOICE_TYPE_ISSUED } from '../types';
import React, { PropsWithChildren, useState } from 'react';
import { FileIcon } from '../../assets/icons';
import { formatDateTimeCell } from '../../util/date-utils';
import { formatMoney } from '../../util/utils';
import { onInvoiceClick } from './PayNowButton';
import { getInvoiceClickSource, getInvoiceRenderInfo } from '../utils';
import useAnalytics from '../../web-analytics/webAnalyticsContext';
import { InvoiceActionButton } from './InvoiceActionButton';
import { ConfirmModal } from '../../commons/components/ConfirmModal';
import moment from 'moment';
import { useIsTabletOrLarger } from '../../responsive/hooks/hooks';

const InvoiceStatus = ({ invoice, inline }: { invoice: Invoice; inline?: boolean }) => {
  const { textColor, statusDisplayName } = getInvoiceRenderInfo(invoice);
  return (
    <div
      className="billing-table__badge"
      data-test="billing-invoices__status-cell"
      style={{
        borderColor: textColor,
        color: textColor,
        display: inline ? 'inline' : undefined,
      }}
    >
      {statusDisplayName}
    </div>
  );
};

const InvoiceDescription = ({ children }: PropsWithChildren) => (
  <div className="d-flex align-items-center justify-content-start">
    <div className="billing__icon">
      <FileIcon className="d-block" />
    </div>
    <div className="growegy-label14--moderrat flex-grow-1">{children}</div>
  </div>
);

const InvoiceHeader = ({ header }: { header: string }) => {
  return (
    <div className="billing-flex-table__header">
      <div className="billing-table__column growegy-label-header--weight500 billing-table__column--size8">
        {header}
      </div>
      <div className="billing-table__column growegy-title12--em billing-table__column--size4">
        Due date
      </div>
      <div className="billing-table__column growegy-title12--em billing-table__column--size3">
        Amount
      </div>
      <div className="billing-table__column growegy-title12--em billing-table__column--invoice-status">
        Status
      </div>
      <div className="billing-table__column billing-table__column--button"></div>
    </div>
  );
};

const InvoiceNotIssuedModal = (props: { invoice: FutureInvoice; onClose: () => void }) => {
  const { invoice, onClose } = props;
  return (
    <ConfirmModal
      title={`This invoice has not been issued yet`}
      subTitle={`It will be issued on ${moment(invoice.createdDate).format('MMMM D, YYYY')}.`}
      footer={{
        type: 'show',
        applyButton: {
          label: 'OK',
          onClick: () => {},
        },
      }}
      onClose={onClose}
    />
  );
};

const InvoiceRowCells = ({ invoice }: { invoice: Invoice }) => (
  <>
    <div
      className="billing-table__column text--truncated billing-table__column--size8"
      data-test="billing-invoices__description-cell"
    >
      <InvoiceDescription>
        {invoice.description ? invoice.description : 'Invoice'}
      </InvoiceDescription>
    </div>
    <div className="billing-table__column billing-table__column--size4">
      <span className="growegy-label14--moderrat" data-test="billing-invoices__date-cell">
        {formatDateTimeCell(invoice.dueDate, 'MMM D, YYYY')}
      </span>
    </div>
    <div className="billing-table__column billing-table__column--size3">
      <span className="growegy-label14--moderrat" data-test="billing-invoices__amount-due-cell">
        {formatMoney(`${invoice.amountDue}`)}
      </span>
    </div>
    <div className="billing-table__column--badge billing-table__column--invoice-status">
      <InvoiceStatus invoice={invoice} />
    </div>
  </>
);

const InvoiceRowCellsNarrow = ({ invoice }: { invoice: Invoice }) => (
  <div
    className="billing-table__column text--truncated flex-grow-1"
    data-test="billing-invoices__description-cell"
  >
    <InvoiceDescription>
      {invoice.description ? invoice.description : 'Invoice'}
      <div className="billing-table__column--description-details">
        <div>
          <span>{formatMoney(`${invoice.amountDue}`)}</span>
          <span className="growegy-title14--moderat" data-test="billing-invoices__date-cell">
            &nbsp;· Due on {formatDateTimeCell(invoice.dueDate, 'MMM D, YYYY')}
          </span>
        </div>
        <InvoiceStatus invoice={invoice} />
      </div>
    </InvoiceDescription>
  </div>
);

const InvoiceRow = ({
  invoice,
  context,
  children,
}: PropsWithChildren<{ invoice: Invoice; context: string }>) => {
  const analytics = useAnalytics();
  const [showModal, setShowModal] = useState(false);
  return (
    <>
      <div
        className="billing-flex-table__row"
        data-test="billing-invoices__row"
        onClick={(e) => {
          if (invoice.type === INVOICE_TYPE_ISSUED) {
            onInvoiceClick(e, invoice, analytics, getInvoiceClickSource(invoice, context));
          } else {
            setShowModal(true);
          }
        }}
      >
        {children}
        <div className="billing-table__column--button">
          {invoice.type === INVOICE_TYPE_ISSUED && (
            <InvoiceActionButton invoice={invoice} context={context} />
          )}
        </div>
      </div>
      {showModal && invoice.type === INVOICE_TYPE_FUTURE && (
        <InvoiceNotIssuedModal invoice={invoice} onClose={() => setShowModal(false)} />
      )}
    </>
  );
};

export const InvoiceTable = ({
  invoices,
  className,
  header,
  context,
  reverseOrder,
}: {
  invoices: Invoice[];
  className?: string;
  header: string;
  context: string;
  reverseOrder?: boolean;
}) => {
  const isTabletOrLarger = useIsTabletOrLarger();
  return (
    <div className={className ?? ''} data-test="billing-invoices">
      {isTabletOrLarger && <InvoiceHeader header={header} />}
      {invoices
        .sort(
          (a, b) => (reverseOrder ? -1 : 1) * (b.createdDate.valueOf() - a.createdDate.valueOf())
        )
        .map((invoice, index) => (
          <InvoiceRow
            invoice={invoice}
            key={invoice.type === INVOICE_TYPE_ISSUED ? invoice.id : `future_${index}`}
            context={context}
          >
            {isTabletOrLarger ? (
              <InvoiceRowCells invoice={invoice} />
            ) : (
              <InvoiceRowCellsNarrow invoice={invoice} />
            )}
          </InvoiceRow>
        ))}
    </div>
  );
};
