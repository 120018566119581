import { useMemo } from 'react';
import ProgramDialog, { ModalState } from './ProgramDialog';
import useAnalyticsPage from '../../web-analytics/hooks/useAnalyticsPage';
import { Page } from '../../web-analytics/Page';
import { useNavigate, useParams } from 'react-router-dom';
import { getEmptyProgramSummary } from '../util';
import { ProgramSummary } from '../types';
import { AppRoute } from '../../router/AppRoutes';

type ProgramDeepLinkParams = {
  programId: string;
};

const stubProgram: (programId: string) => ProgramSummary = (programId: string) => ({
  ...getEmptyProgramSummary('', true, null, null, null, true),
  id: programId,
});

const ProgramDeepLink = () => {
  useAnalyticsPage(Page.PROGRAM_DEEP_LINK);

  const { programId } = useParams<ProgramDeepLinkParams>();
  const modalState: ModalState = useMemo<ModalState>(
    () => ({
      action: programId ? 'UPDATE' : 'CREATE',
      program: stubProgram(programId ?? ''),
      scrollToTaskId: null,
      taskToDeleteOnCreate: null,
    }),
    [programId]
  );
  const navigate = useNavigate();

  return (
    <div className="programs-container" data-test="program-deep-link">
      {modalState && (
        <ProgramDialog
          action={modalState.action}
          programSummary={modalState.program}
          scrollToTaskId={null}
          onHide={() => navigate(AppRoute.home)}
          isDeepLink={true}
        />
      )}
    </div>
  );
};

export default ProgramDeepLink;
