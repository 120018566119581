import { NewTabLink } from './NewTabLink';
import clsx from 'clsx';
import { DividerIcon } from '../../assets/icons';
import { Button } from 'react-bootstrap';
import React, { PropsWithChildren, ReactElement } from 'react';
import { AnalyticsEvent } from '../../web-analytics/AnalyticsEvent';
import { LearnMoreButton } from './LearnMoreButton';
import { ButtonColored } from '../../program/components/ButtonColored';

export const IntegrationTile = (
  props: {
    isActive: boolean;
    isDisconnected: boolean;
    isConnecting: boolean;
    isDisconnecting: boolean;
    isSchedulingResync: boolean;
    isLoading: boolean;
    isError: boolean;
    queryStatusComponent: ReactElement | null;
    title: string;
    subtitle?: string | null;
    privacyPolicyUrl?: string;
    learnMoreUrl: string;
    learnMoreViewedEvent: AnalyticsEvent;
    dataTestPrefix?: string;
    onConnectClick: () => void;
    onDisconnectClick: () => void;
    onResyncClick?: () => void;
    onViewDetailsClick?: () => void;
    icon: ReactElement;
  } & PropsWithChildren
) => {
  const {
    isActive,
    isDisconnected,
    isConnecting,
    isDisconnecting,
    isSchedulingResync,
    isLoading,
    isError,
    queryStatusComponent,
    title,
    subtitle,
    privacyPolicyUrl,
    learnMoreUrl,
    learnMoreViewedEvent,
    dataTestPrefix,
    onConnectClick,
    onDisconnectClick,
    onResyncClick,
    onViewDetailsClick,
    icon,
    children,
  } = props;

  const localDataTestPrefix = dataTestPrefix ?? 'integration-tile';

  return (
    <div
      className="personal-integration__container"
      data-test={`${localDataTestPrefix}__container`}
    >
      <>
        {icon}
        <div className="personal-integration__header-container">
          <span className="personal-integration__title">{title}</span>
          {isActive && <div className="growegy-badge-ok">Active</div>}
          {isDisconnected && <div className="growegy-badge-error">Disconnected</div>}
          <div className="flex-grow-1" />
          {privacyPolicyUrl && (
            <div className="personal-integration__header-container--privacy-policy">
              <NewTabLink href={privacyPolicyUrl}>Privacy policy</NewTabLink>
            </div>
          )}
        </div>
        {isLoading && queryStatusComponent}
        {isError && queryStatusComponent}
        <div className={clsx('personal-integration__body', { invisible: isLoading || isError })}>
          {subtitle && <div className="personal-integration__subtitle">{subtitle}</div>}
          <div className="personal-integration__description">{children}</div>
          {isActive ? (
            <div className="personal-integration__inactive-button-bar">
              <LearnMoreButton url={learnMoreUrl} analyticsEvent={learnMoreViewedEvent} />
              {onResyncClick && (
                <>
                  <DividerIcon />
                  <Button
                    variant="primary"
                    onClick={onResyncClick}
                    disabled={isConnecting || isDisconnecting || isSchedulingResync}
                    className="button-colored"
                  >
                    Resync
                  </Button>
                </>
              )}
              {onViewDetailsClick && (
                <>
                  <DividerIcon />
                  <ButtonColored
                    onClick={onViewDetailsClick}
                    text="View details"
                    dataTest={`${localDataTestPrefix}__view-details`}
                  />
                </>
              )}
              <DividerIcon />
              <Button
                variant="light"
                className="button-cancel"
                onClick={onDisconnectClick}
                style={{ width: 'fit-content' }}
                disabled={isConnecting || isDisconnecting}
                data-test={`${localDataTestPrefix}__disconnect`}
              >
                Disconnect
              </Button>
            </div>
          ) : (
            <div className="personal-integration__active-button-bar">
              <LearnMoreButton url={learnMoreUrl} analyticsEvent={learnMoreViewedEvent} />
              <Button
                variant="primary"
                className="button-apply"
                onClick={onConnectClick}
                style={{ width: 'fit-content' }}
                disabled={isConnecting || isDisconnecting}
                data-test={`${localDataTestPrefix}__connect`}
              >
                Connect
              </Button>
            </div>
          )}
        </div>
      </>
    </div>
  );
};
