import ReactDOM from 'react-dom';
import { useFormikContext } from 'formik';
import { ProgramEditFormikModel } from '../types';
import { useEffect, useRef } from 'react';
import { useAsyncDebounce } from 'react-table';
import { programToMathJsScope } from '../formulaUtil';
import _ from 'lodash';
import { getCustomFieldNamesSum } from '../util';

export const FormulaScopeUpdater = () => {
  const { values, setFieldValue } = useFormikContext<ProgramEditFormikModel>();

  const previousValuesRef = useRef<ProgramEditFormikModel>();

  const updateScope = useAsyncDebounce((v: ProgramEditFormikModel) => {
    const nextScope = programToMathJsScope(v);
    const previousScope = previousValuesRef.current
      ? programToMathJsScope(previousValuesRef.current)
      : null;

    const isScopeSame = _.isEqual(previousScope, nextScope);
    if (!isScopeSame) {
      ReactDOM.flushSync(async () => setFieldValue('formulaScope', nextScope.scope, true));
      ReactDOM.flushSync(async () => setFieldValue('mentionsData', nextScope.mentionsData, true));
    }

    const nextCustomFieldNameSums = getCustomFieldNamesSum(v.customFields.map(({ name }) => name));
    const prevCustomFieldNameSums = previousValuesRef.current
      ? getCustomFieldNamesSum(previousValuesRef.current.customFields.map(({ name }) => name))
      : null;

    const isCustomFieldsDataSame = _.isEqual(nextCustomFieldNameSums, prevCustomFieldNameSums);

    if (!isCustomFieldsDataSame) {
      ReactDOM.flushSync(async () =>
        setFieldValue('customFieldsNameSums', nextCustomFieldNameSums, true)
      );
    }

    if (!isScopeSame || !isCustomFieldsDataSame) {
      previousValuesRef.current = v;
    }
  }, 300);

  useEffect(() => {
    updateScope(values);
  }, [updateScope, values]);
  return null;
};
