import React from 'react';
import { PromptVariable, PromptVariableStringValue } from '../../types';
import { VariableListItem } from './VariableListItem';
import { PlusSmallIcon } from '../../../assets/icons';
import { DivButton } from '../../../commons/components/DivButton';

export const VariableOfListTypeItems = ({
  variable,
  onUpdate,
}: {
  variable: PromptVariable;
  onUpdate: (newValue: PromptVariable) => Promise<void>;
}) => {
  const onAddNewClick = async () => {
    if (variable.variableValue.type === 'list') {
      const updateValue: PromptVariable = {
        ...variable,
        variableValue: {
          ...variable.variableValue,
          values: [
            ...variable.variableValue.values,
            { type: 'string', value: '', description: '', name: '' },
          ],
        },
      };
      await onUpdate(updateValue);
    }
  };

  if (variable.variableValue.type === 'list') {
    return (
      <>
        {variable.variableValue.values.map((value, idx) => (
          <VariableListItem
            key={`variable-list-${value.value}-${value.description}-${idx}`}
            idxOfList={idx}
            variableName={variable.variableValue.name}
            origValue={value}
            onUpdate={async (newValue: PromptVariableStringValue) => {
              if (variable.variableValue.type === 'list') {
                const updateValue: PromptVariable = {
                  ...variable,
                  variableValue: {
                    ...variable.variableValue,
                    values: variable.variableValue.values.map((x, i) => (i === idx ? newValue : x)),
                  },
                };
                await onUpdate(updateValue);
              }
            }}
            onDelete={async () => {
              if (variable.variableValue.type === 'list') {
                const updateValue: PromptVariable = {
                  ...variable,
                  variableValue: {
                    ...variable.variableValue,
                    values: variable.variableValue.values.filter((x, i) => i !== idx),
                  },
                };
                await onUpdate(updateValue);
              }
            }}
          />
        ))}
        <div className="variables-table__row d-flex">
          <div className="variables-table__name-cell" style={{ maxWidth: '25%' }}></div>
          <div style={{ maxWidth: '95%' }}>
            <DivButton
              isGray={true}
              icon={<PlusSmallIcon />}
              text={`Add new ${variable.variableValue.name}`}
              onClick={async () => onAddNewClick()}
              className="common-table__add-new-btn"
              dataTest="variables-table__add-new-btn"
            />
          </div>
        </div>
      </>
    );
  }

  return null;
};
