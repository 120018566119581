import React from 'react';
import { IntegrationMetadataDto } from '../integrationApi';
import { useQuery } from '@tanstack/react-query';
import { IntegrationStateCacheKey } from '../../hooks/useQueryCacheManager';
import useApi from '../../api/backendApiContext';
import { useResolvedIntegration } from '../hooks/useResolvedIntegration';
import {
  INTEGRATION_STATE_ACTIVE,
  INTEGRATION_STATE_CONNECT,
  INTEGRATION_STATE_DISCONNECTED,
  IntegrationState,
  ResolvedIntegration,
} from '../types';
import { IntegrationTile } from '../../commons/components/IntegrationTile';
import { AnalyticsEvent } from '../../web-analytics/AnalyticsEvent';

export const isNew = (i: ResolvedIntegration): boolean =>
  i.attributes.reduce<number>((prev, curr) => prev + curr.value.length, 0) === 0;

const getIntegrationStatus = (i: ResolvedIntegration | null, state: string | undefined) => {
  let status: IntegrationState;
  if (i && isNew(i)) {
    status = INTEGRATION_STATE_CONNECT;
  } else if (state === 'working') {
    status = INTEGRATION_STATE_ACTIVE;
  } else {
    status = INTEGRATION_STATE_DISCONNECTED;
  }
  return status;
};

const TeamIntegration = (props: {
  integration: IntegrationMetadataDto;
  onDelete: (integration: ResolvedIntegration) => void;
  onSelect: () => void;
}) => {
  const { integration, onSelect, onDelete } = props;

  const { integrationApi } = useApi();

  const { data: dataState, status: statusState } = useQuery(IntegrationStateCacheKey, async () =>
    integrationApi.getIntegrationState(integration.id)
  );

  const { data, status } = useResolvedIntegration({ integrationId: integration.id });
  const integrationStatus = getIntegrationStatus(data, dataState?.status);

  const { icon, name } = integration;
  return (
    <IntegrationTile
      isActive={integrationStatus === INTEGRATION_STATE_ACTIVE}
      isDisconnected={!!data && !!dataState && integrationStatus === INTEGRATION_STATE_DISCONNECTED}
      isConnecting={false}
      isDisconnecting={false}
      isSchedulingResync={false}
      isLoading={statusState === 'loading' || status === 'loading'}
      isError={statusState === 'error' || status === 'error'}
      queryStatusComponent={null}
      title={name}
      learnMoreUrl="https://www.growegy.com/resources/tutorials/salesforce-integration/"
      learnMoreViewedEvent={AnalyticsEvent.INTEGRATION_HELP_VIEWED}
      dataTestPrefix={`integrations-table__${integration.id}`}
      onConnectClick={onSelect}
      onDisconnectClick={() => data && onDelete(data)}
      onViewDetailsClick={onSelect}
      icon={
        <img
          className="integration-row-icon"
          src={`data:image/svg+xml;base64,${icon}`}
          alt={name}
        />
      }
    >
      <div>
        Connecting with Salesforce allows you to import campaign data and tie your projected results
        to actual results and budget.
      </div>
    </IntegrationTile>
  );
};

export const IntegrationsTable = (props: {
  data: IntegrationMetadataDto[];
  onSelect: (integrations: IntegrationMetadataDto) => void;
  onDelete: (integration: ResolvedIntegration) => void;
}) => {
  const { data, onSelect, onDelete } = props;
  return (
    <>
      {data.map((integration) => (
        <TeamIntegration
          key={integration.id}
          integration={integration}
          onSelect={() => onSelect(integration)}
          onDelete={onDelete}
        />
      ))}
    </>
  );
};
