import {
  CartesianGrid,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  TooltipProps,
  XAxis,
  YAxis,
} from 'recharts';
import { useMemo } from 'react';
import moment from 'moment';
import { useFetchedDataSeries } from '../hooks/useFetchedDataSeries';
import { MOMENT_DATE_FORMAT, MONTH_DAY_YEAR_FORMAT } from '../formats';
import { ProgramMultiFiltersDto } from '../analyticsApi';

const percentFormatter = new Intl.NumberFormat('en-US', { style: 'percent' });
const decimalFormatter = new Intl.NumberFormat('en-US');

const CustomTooltip = (
  props: TooltipProps<number | string | Array<number | string>, number | string>
) => {
  const { active, payload, label } = props;
  if (active && payload && payload.length) {
    const { actualLeads, projectedLeads } = payload[0].payload;
    const { color } = payload[0];
    return (
      <div className="analytics__tooltip" data-test="analytics__tooltip">
        <div className="analytics__tooltip-title" data-test="analytics__tooltip-title">
          {moment.utc(label).format(MONTH_DAY_YEAR_FORMAT)}
        </div>
        <div className="analytics__tooltip-row">
          <div className="analytics__tooltip-name">Actual leads</div>
          <div
            className="analytics__tooltip-value"
            style={{ color }}
            data-test="analytics__tooltip-action-leads-value"
          >
            {`${decimalFormatter.format(actualLeads)} (${percentFormatter.format(
              projectedLeads ? actualLeads / projectedLeads : 0
            )})`}
          </div>
        </div>
        <div className="analytics__tooltip-row">
          <div className="analytics__tooltip-name">Projected leads</div>
          <div
            className="analytics__tooltip-value"
            data-test="analytics__tooltip-projected-leads-value"
          >
            {decimalFormatter.format(projectedLeads)}
          </div>
        </div>
      </div>
    );
  }
  return null;
};

const ActualVsProjectedLeadsLineChart = (props: {
  fromDate: number;
  toDate: number;
  filter: ProgramMultiFiltersDto | null;
}) => {
  const { fromDate, toDate, filter } = props;
  const ticks = useMemo(() => {
    const result = [] as number[];

    const daysBetween = moment.utc(props.toDate).diff(props.fromDate, 'days');
    const desiredIntervalsCount = 5;
    const daysStep = Math.ceil(daysBetween / desiredIntervalsCount);
    let tickDate = props.fromDate;
    while (tickDate < props.toDate) {
      result.push(tickDate);
      tickDate = moment.utc(tickDate).add(daysStep, 'days').valueOf();
    }
    result.push(props.toDate);
    return result;
  }, [props.fromDate, props.toDate]);

  const { isLoading, isSuccess, data } = useFetchedDataSeries(fromDate, toDate, filter);

  return (
    <div data-test="actual-projected-leads__chart" className="analytics__chart-container">
      {isLoading && (
        <div className="alert alert-secondary" role="alert">
          Loading chart...
        </div>
      )}
      <ResponsiveContainer width="100%" height={240}>
        <LineChart data={data?.points}>
          {isSuccess && (
            <>
              <Line
                dataKey="actualLeads"
                isAnimationActive={false}
                stroke="#00b5ce"
                strokeWidth={2}
                dot={false}
                role="actual-projected-leads__actual-line"
              />
              <Line
                dataKey="projectedLeads"
                isAnimationActive={false}
                stroke="#191c1d"
                strokeOpacity={0.4}
                strokeWidth={2}
                dot={false}
                role="actual-projected-leads__projected-line"
              />
            </>
          )}
          <CartesianGrid stroke="#191c1d14" vertical={false} />
          <XAxis
            dataKey="timestamp"
            type="number"
            domain={[props.fromDate, props.toDate]}
            axisLine={false}
            tickLine={false}
            ticks={ticks}
            tickFormatter={(x) => moment.utc(x).format(MOMENT_DATE_FORMAT)}
          />
          <YAxis axisLine={false} tickLine={false} />
          <Tooltip content={<CustomTooltip />} />
        </LineChart>
      </ResponsiveContainer>
    </div>
  );
};

export default ActualVsProjectedLeadsLineChart;
