import useApi from '../../api/backendApiContext';
import { useQuery } from '@tanstack/react-query';
import { PingCacheKey } from '../../hooks/useQueryCacheManager';

export const usePing = () => {
  const { metaApi } = useApi();
  return useQuery(PingCacheKey, async () => metaApi.getPing(), {
    refetchInterval: false,
    staleTime: Infinity,
    cacheTime: Infinity,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
  });
};
