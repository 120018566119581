import React from 'react';
import { DividerIcon } from '../../assets/icons';
import clsx from 'clsx';

export const FooterError = (props: {
  message: string;
  dataTest?: string;
  position?: 'left' | 'right';
}) => {
  const { message, dataTest, position } = props;
  return (
    <div
      className={clsx('footer-error__container', {
        'flex-row-reverse': position === 'left',
      })}
    >
      <div className="growegy-font14--error" data-test={dataTest}>
        {message}
      </div>
      <DividerIcon className="flex-shrink-0" />
    </div>
  );
};
