/* eslint-disable react-hooks/rules-of-hooks */
import { ColoredItem, ProgramRow, ProgramStatusesOptions, ProgramStatusOption } from '../../types';
import React, { useEffect, useRef, MouseEvent, useState, useCallback } from 'react';
import { CellProps } from './ProgramCells';
import { ColoredSelect } from '../ColoredSelect';
import { GrowegySelectMockup } from '../GrowegySelectMockup';
import { ProgramTableCellWidth } from '../../util';

const StatusCell = ({
  onProgramUpdate,
  row,
  readOnly,
}: CellProps<ProgramRow, ProgramStatusOption>) => {
  const [value, setValue] = useState<ProgramStatusOption>(row.original.status);
  const [editing, setEditing] = useState(false);
  const inputRef = useRef<HTMLSelectElement>(null);

  useEffect(() => {
    setValue(row.original.status);
  }, [row.original.status]);

  useEffect(() => {
    if (!inputRef.current) return;
    inputRef.current?.focus();
  }, [editing]);

  function onEditClick(e: MouseEvent<HTMLDivElement>) {
    if (row.isGrouped) return;
    e.stopPropagation();
    if (readOnly) return;
    if (editing) return;
    setEditing(true);
  }

  const cancel = useCallback(() => {
    setValue(row.original.status);
    setEditing(false);
  }, [row.original.status]);

  const handleStatusChange = (status: ColoredItem) => {
    if (status.id !== row.original.status.id) {
      onProgramUpdate({
        original: row.original,
        updates: [{ field: 'status', value: status }],
      });
    }
    cancel();
  };

  return (
    <div
      data-test="programs-table__status-cell"
      style={{ width: '100%' }}
      onClick={onEditClick}
      onKeyDown={(e) => {
        if (editing) e.stopPropagation();
      }}
      onBlur={cancel}
    >
      {!row.isGrouped &&
        (editing && !readOnly ? (
          <ColoredSelect
            dataTestPrefix="programs-table__status-cell"
            initialValue={value}
            coloredItems={[...ProgramStatusesOptions]}
            onValueChange={handleStatusChange}
            autoFocus={true}
            width={ProgramTableCellWidth}
          />
        ) : (
          <GrowegySelectMockup readOnly={readOnly}>
            <div style={{ color: value.color }}>{value.name}</div>
          </GrowegySelectMockup>
        ))}
    </div>
  );
};

export default StatusCell;
