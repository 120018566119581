import _ from 'lodash';
import { useFetchedEnterpriseChartsAmount } from '../hooks/useFetchedEnterpriseChartsAmount';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';
import Analytics from './Analytics';
import { EnterpriseAnalytics } from './EnterpriseAnalytics';
import { useFetchedEnterpriseAnalyticLayout } from '../hooks/useFetchedEnterpriseAnalyticLayout';
import {
  EnterpriseAnalyticLayoutRow,
  EnterpriseAnalyticLayoutRows,
  EnterpriseChartData,
} from '../types';
import { useFetchedEnterpriseCharts } from '../hooks/useFetchedEnterpriseCharts';

const filterChartsAndLayoutRowsByTabs = (
  tab: string,
  c: EnterpriseChartData[],
  r: EnterpriseAnalyticLayoutRow[]
): { charts: EnterpriseChartData[]; rows: EnterpriseAnalyticLayoutRow[] } => {
  if (!tab) {
    return { charts: c, rows: r };
  }
  const rows = r.filter((r) => r.tab === tab);
  const rowIds = new Set(rows.map((r) => r.id));
  const charts = c.filter((c) => rowIds.has(c.rowId));

  return { charts, rows };
};

const extractTabNames = (rows: EnterpriseAnalyticLayoutRow[]): string[] =>
  _([...rows])
    .sortBy((r) => r.idxWithinTab)
    .uniqBy((r) => r.tab)
    .map((r) => r.tab)
    .value();

const tabNameCollection = (data: EnterpriseAnalyticLayoutRows) => {
  const { layoutRows } = data;
  return layoutRows.length ? extractTabNames(layoutRows) : ['Enterprise'];
};
const tabPanelNameCollection = (data: EnterpriseAnalyticLayoutRows) => {
  const { layoutRows } = data;
  return layoutRows.length ? extractTabNames(layoutRows) : [''];
};

const AnalyticsTabs = () => {
  const chartsAmount = useFetchedEnterpriseChartsAmount();
  const enterpriseLayout = useFetchedEnterpriseAnalyticLayout();
  const showEnterprise = !!chartsAmount.data?.amount && !!enterpriseLayout.data;
  const { data: c } = useFetchedEnterpriseCharts();
  const charts = c?.charts ?? [];

  return (
    <Tabs>
      <TabList>
        <Tab>Main</Tab>
        {showEnterprise &&
          tabNameCollection(enterpriseLayout.data!).map((t) => <Tab key={t}>{t}</Tab>)}
      </TabList>

      <TabPanel className="analytics__tab-panel">
        <Analytics />
      </TabPanel>
      {showEnterprise &&
        tabPanelNameCollection(enterpriseLayout.data!).map((t) => {
          const { charts: filteredCharts, rows } = filterChartsAndLayoutRowsByTabs(
            t,
            charts,
            enterpriseLayout.data!.layoutRows
          );
          return (
            <TabPanel key={t} className="analytics__tab-panel">
              <EnterpriseAnalytics rows={rows} charts={filteredCharts} />
            </TabPanel>
          );
        })}
    </Tabs>
  );
};

export default AnalyticsTabs;
