import React from 'react';
import moment from 'moment';
import { analyticsTrack } from '../../web-analytics/webAnalytics';
import { AnalyticsEvent } from '../../web-analytics/AnalyticsEvent';
import useAnalytics from '../../web-analytics/webAnalyticsContext';
import { ChevronCircleIcon, ChevronLeftIcon, ChevronRightIcon } from '../../assets/icons';
import { PeriodType, PeriodPicker, StandardPeriodType, CustomPeriodType } from './PeriodPicker';
import { DivButton } from './DivButton';
import clsx from 'clsx';
import { CustomDateRangeSelector } from './CustomDateRangeSelector';

export type DateRange = { firstDay: Date; lastDay: Date };
export type StandardDateRange = { period: StandardPeriodType; currentDate: Date };
export type CustomDateRange = { period: CustomPeriodType; currentDate: Date; toDate: Date };
export type PeriodDateRange = StandardDateRange | CustomDateRange;

const toText = ({ firstDay, lastDay }: DateRange, period: PeriodType) => {
  switch (period) {
    case 'Day': {
      return `${moment(firstDay).format('MMMM D, YYYY')}`;
    }
    case 'Week': {
      const withinSameMonth = moment(firstDay)
        .startOf('month')
        .isSame(moment(lastDay).startOf('month'), 'day');
      return withinSameMonth
        ? `${moment(firstDay).format('MMM DD')} - ${moment(lastDay).format('DD')}`
        : `${moment(firstDay).format('MMM DD')} - ${moment(lastDay).format('MMM DD')}`;
    }
    case 'Month':
      return `${moment(firstDay).format('MMMM YYYY')}`;
    case 'Quarter':
      return `${moment(firstDay).format('MMM YYYY')} - ${moment(lastDay).format('MMM YYYY')}`;
    case 'Custom':
      return '';
    default:
      throw new Error(`unsupported period: ${period}`);
  }
};

const getQuarterRange = (initPoint: Date): DateRange => {
  const firstDay = moment(initPoint).startOf('month').toDate();
  const lastDay = moment(initPoint).startOf('month').add(2, 'month').endOf('month').toDate();

  return { firstDay, lastDay };
};

const getMonthRange = (initPoint: Date): DateRange => {
  const firstDay = moment(initPoint).startOf('month').toDate();
  const lastDay = moment(initPoint).endOf('month').toDate();

  return { firstDay, lastDay };
};

const getWeekRange = (initPoint: Date): DateRange => {
  const firstDay = moment(initPoint).startOf('week').toDate();
  const lastDay = moment(initPoint).endOf('week').toDate();

  return { firstDay, lastDay };
};

const getDayRange = (initPoint: Date): DateRange => {
  const firstDay = moment(initPoint).startOf('day').toDate();
  const lastDay = moment(initPoint).endOf('day').toDate();

  return { firstDay, lastDay };
};

export const getDateRange = (periodDateRange: PeriodDateRange): DateRange => {
  switch (periodDateRange.period) {
    case 'Day':
      return getDayRange(periodDateRange.currentDate);
    case 'Week':
      return getWeekRange(periodDateRange.currentDate);
    case 'Month':
      return getMonthRange(periodDateRange.currentDate);
    case 'Quarter':
      return getQuarterRange(periodDateRange.currentDate);
    case 'Custom':
      return { firstDay: periodDateRange.currentDate, lastDay: periodDateRange.toDate };
    default:
      throw new Error(`unsupported period`);
  }
};

const prepareDateForMove: (standardDateRange: StandardDateRange) => {
  date: Date;
  increment: 'week' | 'day' | 'month';
} = (standardDateRange: StandardDateRange) => {
  const { currentDate } = standardDateRange;
  switch (standardDateRange.period) {
    case 'Week':
      return { date: moment(currentDate).startOf('week').toDate(), increment: 'week' };
    case 'Day':
      return { date: moment(currentDate).startOf('day').toDate(), increment: 'day' };
    default:
      return { date: moment(currentDate).startOf('month').toDate(), increment: 'month' };
  }
};

export const DateRangeSelector = (props: {
  showDayPeriod: boolean;
  showCustomPeriod: boolean;
  periodDateRange: PeriodDateRange;
  onChanged: (newPeriodDateRange: PeriodDateRange, newDateRange: DateRange) => void;
}) => {
  const analytics = useAnalytics();
  const { showDayPeriod, showCustomPeriod, periodDateRange, onChanged } = props;

  const { period } = periodDateRange;
  const move = (direction: 'right' | 'left') => {
    if (periodDateRange.period === 'Custom') {
      return;
    }
    const { date, increment } = prepareDateForMove(periodDateRange);

    const next = moment(date)
      .add(direction === 'right' ? 1 : -1, increment)
      .toDate();

    const newPeriodDateRange = {
      ...periodDateRange,
      currentDate: next,
    };
    onChanged(newPeriodDateRange, getDateRange(newPeriodDateRange));
  };
  const onNext = () => move('right');
  const onPrev = () => move('left');
  const onNow = () => {
    const newPeriodDateRange = {
      ...periodDateRange,
      currentDate: new Date(),
    };
    onChanged(newPeriodDateRange, getDateRange(newPeriodDateRange));
  };

  const range = getDateRange(periodDateRange);
  const changePeriod = (newPeriod: PeriodType) => {
    if (periodDateRange.period === newPeriod) return;

    analyticsTrack(analytics, AnalyticsEvent.GENERIC_PERIOD_CHANGED, {
      calendarPeriod: newPeriod,
    });
    let newPeriodDateRange: PeriodDateRange;
    if (newPeriod !== 'Custom') {
      newPeriodDateRange = { period: newPeriod, currentDate: periodDateRange.currentDate };
    } else {
      newPeriodDateRange = {
        period: newPeriod,
        currentDate: range.firstDay,
        toDate: range.lastDay,
      };
    }
    onChanged(newPeriodDateRange, getDateRange(newPeriodDateRange));
  };

  return (
    <div className="dates-navigator__panel">
      <div className="dates-navigator__panel" data-test="period-picker__period-button">
        <PeriodPicker
          showDayPeriod={showDayPeriod}
          showCustomPeriod={showCustomPeriod}
          onSelect={(v) => changePeriod(v)}
          value={period}
        />
      </div>
      <div className="dates-navigator__panel">
        <span
          className={clsx('date-range-selector__title', {
            'date-range-selector__title--day': period === 'Day',
            'date-range-selector__title--week': period === 'Week',
            'date-range-selector__title--month': period === 'Month',
            'date-range-selector__title--quarter': period === 'Quarter',
            'date-range-selector__title--custom': period === 'Custom',
          })}
        >
          {toText(range, period)}
        </span>
      </div>
      {period !== 'Custom' && (
        <div className="dates-navigator__panel">
          <DivButton
            icon={<ChevronLeftIcon />}
            onClick={onPrev}
            small={true}
            dataTest="date-range-selector__prev"
          />
          <DivButton
            icon={<ChevronCircleIcon />}
            onClick={onNow}
            small={true}
            dataTest="date-range-selector__today"
          />
          <DivButton
            icon={<ChevronRightIcon />}
            onClick={onNext}
            small={true}
            dataTest="date-range-selector__next"
          />
        </div>
      )}
      {periodDateRange.period === 'Custom' && (
        <CustomDateRangeSelector
          fromDate={moment(periodDateRange.currentDate).startOf('day').toDate()}
          toDate={moment(periodDateRange.toDate).startOf('day').toDate()}
          onDatesChanged={(fromDate, toDate) => {
            const newPeriodDateRange = {
              period: 'Custom' as const,
              currentDate: fromDate,
              toDate,
            };
            return onChanged(newPeriodDateRange, getDateRange(newPeriodDateRange));
          }}
        />
      )}
    </div>
  );
};
