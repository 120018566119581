import { PencilIcon } from '../../assets/icons';
import React, { MouseEvent } from 'react';

export const PencilButton = (props: {
  onEdit: (e: MouseEvent<HTMLButtonElement>) => void;
  dataTest?: string;
}) => {
  const { onEdit, dataTest } = props;
  return (
    <button className="icon-button__wrapper" onClick={onEdit} type="button" data-test={dataTest}>
      <PencilIcon className="icon-button__icon" />
    </button>
  );
};
