import React, { useCallback, useState } from 'react';
import { Chat, Prompt } from '../types';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';
import { useChatContext } from '../chat/chatContext';
import { LibraryTab } from '../library/containers/LibraryTab';
import { growegyAIConfig } from '../../commons/config';
import useAnalyticsPage from '../../web-analytics/hooks/useAnalyticsPage';
import { Page } from '../../web-analytics/Page';
import { ChatTab } from '../chat/containers/ChatTab';
import { useCreateChatMutation } from '../chat/hooks/useCreateChatMutation';
import { getLibraryPromptWizardSteps } from '../util';
import _ from 'lodash';
import { VariablesTab } from '../variables/containers/VariablesTab';
import { CHAT_TYPE_GENERAL } from '../dto';
import { CHAT_REALM_GROWEGY_AI, ChatRealmContextProvider } from '../chat/chatRealmContext';
import { stringToComplexContent } from '../chat/components/TextMessageInput';

const CHAT_TAB_INDEX = 0;
const LIBRARY_TAB_INDEX = 1;
const VARIABLES_TAB_INDEX = 2;

export const GrowegyAI = () => (
  <ChatRealmContextProvider realm={CHAT_REALM_GROWEGY_AI}>
    <GrowegyAIInternal />
  </ChatRealmContextProvider>
);

const GrowegyAIInternal = () => {
  useAnalyticsPage(Page.GROWEGY_AI);
  const { getCurrentChat, setChatContext, setCurrentChat, setSelectedCategory, getCurrentModel } =
    useChatContext();
  const [selectedChat, setSelectedChat] = useState<Chat | null>(getCurrentChat());
  const { mutateAsync: createChatMutation } = useCreateChatMutation(
    () => {},
    () => {}
  );

  const onPromptClicked = async (prompt: Prompt) => {
    const chat = await createChatMutation({
      firstMessageContent: stringToComplexContent(prompt.title),
      model: getCurrentModel(),
      type: CHAT_TYPE_GENERAL,
    });
    setChatContext(chat.id, {
      rawContent: '',
      libraryPromptWizardState: {
        steps: getLibraryPromptWizardSteps(prompt),
        currentStepState: {
          index: 0,
          selectedValues: [],
          adhocValues: [],
          addNewMode: false,
          newValueDescription: '',
        },
        prompt: _.cloneDeep(prompt),
      },
    });
    setCurrentChat(chat);
    setSelectedChat(chat);
    setSelectedTabIndex(CHAT_TAB_INDEX);
  };
  const onOpenLibraryClick = useCallback(() => {
    setSelectedCategory(null);
    setSelectedTabIndex(LIBRARY_TAB_INDEX);
  }, [setSelectedCategory]);

  const onOpenVariablesClick = useCallback(() => {
    setSelectedCategory(null);
    setSelectedTabIndex(VARIABLES_TAB_INDEX);
  }, [setSelectedCategory]);

  const [selectedTabIndex, setSelectedTabIndex] = useState(CHAT_TAB_INDEX);

  const onChatSelected = useCallback(
    (chat: Chat) => {
      setSelectedChat(chat);
      setCurrentChat(chat);
    },
    [setCurrentChat]
  );

  return (
    <div className="growegy-ai__container">
      <Tabs selectedIndex={selectedTabIndex} onSelect={(i) => setSelectedTabIndex(i)}>
        <TabList>
          <Tab data-test="growegy-ai__chat-tab">Chat</Tab>
          {growegyAIConfig.libraryEnabled && <Tab data-test="growegy-ai__library-tab">Library</Tab>}
          {growegyAIConfig.variablesEnabled && (
            <Tab data-test="growegy-ai__variables-tab">Variables</Tab>
          )}
        </TabList>
        <TabPanel>
          <div>
            <ChatTab
              selectedChat={selectedChat}
              onChatSelected={onChatSelected}
              onOpenLibraryClick={onOpenLibraryClick}
              onOpenVariablesClick={onOpenVariablesClick}
            />
          </div>
        </TabPanel>
        {growegyAIConfig.variablesEnabled && (
          <TabPanel>
            <div>
              <LibraryTab onPromptClicked={onPromptClicked} />
            </div>
          </TabPanel>
        )}
        <TabPanel>
          <div>
            <VariablesTab />
          </div>
        </TabPanel>
      </Tabs>
    </div>
  );
};
