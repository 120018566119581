import {
  Chat,
  FALLBACK_CATEGORY_COLOR,
  GPT_MODEL_3_5,
  LibraryPromptWizardStep,
  NEW_CHAT_ID,
  PREDEFINED_PROMPT_CATEGORIES,
  Prompt,
  PromptCategory,
  PromptVariableStringValue,
  PromptVariableStringValueOrAdhoc,
} from './types';
import { v4 as uuidv4 } from 'uuid';
import _ from 'lodash';
import { ProgramType } from '../program/types';
import { ensureSharpAtHex } from '../program/util';
import { CHAT_TYPE_CREATE_PROGRAM, ChatType, PlaceholderMetadataDto } from './dto';
import { getBillingPlan } from '../billing/billingPlans';
import { Customer } from '../billing/types';

export const comparePromptCategories = (categoryA: string, categoryB: string) =>
  categoryA?.toLowerCase() === categoryB?.toLowerCase();

export const createNewChat = (chatType: ChatType): Chat => ({
  title: chatType === CHAT_TYPE_CREATE_PROGRAM ? 'New Program AI Creator chat' : '',
  id: NEW_CHAT_ID,
  model: GPT_MODEL_3_5,
  type: chatType,
});

export const getTempMessageId = () => `temp_${uuidv4()}`;
const substitutionsRegexGlobal = /(\[[^\]]+])|(\{[^}]+})/g;

export type LibraryPromptContentPart = {
  text: string;
  isPlaceholder: boolean;
};
export const breakDownLibraryPrompt = (
  libraryPromptContent: string
): LibraryPromptContentPart[] => {
  const result: LibraryPromptContentPart[] = [];
  const matches: { length: number; index: number }[] = Array.from(
    libraryPromptContent.matchAll(substitutionsRegexGlobal),
    (x: RegExpMatchArray) => ({
      index: x.index ?? 0,
      length: x[0].length,
    })
  );
  let current = 0;
  for (let i = 0; i < matches.length; i++) {
    const match = matches[i];
    if (current < match.index) {
      result.push({
        text: libraryPromptContent.substring(current, match.index),
        isPlaceholder: false,
      });
    }
    const placeholder = libraryPromptContent.substring(
      match.index + 1,
      match.index + match.length - 1
    );
    for (const part of breakDownPlaceholder(placeholder)) {
      result.push(part);
    }
    current = match.index + match.length;
  }
  if (current < libraryPromptContent.length) {
    result.push({
      text: libraryPromptContent.substring(current, libraryPromptContent.length),
      isPlaceholder: false,
    });
  }
  return result;
};

const breakDownPlaceholder = (placeholder: string): LibraryPromptContentPart[] => {
  placeholder.trimStart();

  const options = placeholder
    .split('|')
    .map((x) => x.trim())
    .filter((x) => x.length > 0);
  const result: LibraryPromptContentPart[] = [];
  for (let i = 0; i < options.length; i++) {
    if (i > 0) {
      result.push({
        text: i < options.length - 1 ? ', ' : options.length > 2 ? ', or ' : ' or ',
        isPlaceholder: false,
      });
    }
    result.push({ text: options[i], isPlaceholder: true });
  }
  return result;
};

const humanFriendlyJoin = (options: string[]) => {
  let result = '';
  for (let i = 0; i < options.length; i++) {
    result += i === 0 ? options[i] : (i < options.length - 1 ? ', ' : ' or ') + options[i];
  }
  return result;
};

export const PREV_ANSWER_REFERENCE = '%PREV%';

export const getPromptPlaceholders = (libraryPromptContent: string): string[] =>
  Array.from(libraryPromptContent.matchAll(substitutionsRegexGlobal), (x) => x[0]);

const placeholderQuestions: { [key: string]: string } = {
  company: 'Tell us more details about your company',
  product: 'What are the product that you would like to use?',
  service: 'What are the service that you would like to use?',
  'product or service': 'What are the products or services that you would like to use?',
  feature: 'Specify which features you would like to incorporate?',
  audience: 'Can you provide a detailed description of your audience?',
  n: 'Specify number',
  'social media': 'Which Social Media should we focus on?',
  topic: 'What is the main topic you would like to address?',
  keyword: 'Please share the keywords you would like to target.',
  industry: 'Tell us about the industry',
  value: 'Specify which values you would like to use',
};

export const NUMBER_PLACEHOLDER = 'n';
export const STRING_VARIABLES = ['company'];

const persistableVariables = _.keys(placeholderQuestions).filter((x) => x !== NUMBER_PLACEHOLDER);

export const getQuestionOrDefault = (placeholder: string, isFreeForm: boolean): string =>
  placeholderQuestions[placeholder.trim().toLowerCase()] ??
  (isFreeForm ? `Add ${placeholder}` : `Specify which ${placeholder} you would like to use?`);

const getWizardStepsByPlaceholder = (placeholderInfo: {
  rawPlaceholder: string;
  metadata: PlaceholderMetadataDto | null;
}): LibraryPromptWizardStep[] => {
  const { rawPlaceholder, metadata } = placeholderInfo;
  if (rawPlaceholder.length < 2) return [];
  const unwrappedPlaceholder = rawPlaceholder.substring(1, rawPlaceholder.length - 1);
  const isFreeForm = rawPlaceholder.startsWith('{') && rawPlaceholder.endsWith('}');

  if (unwrappedPlaceholder.indexOf('|') >= 0 && !isFreeForm) {
    const options = unwrappedPlaceholder
      .split('|')
      .map((x) => x.trim())
      .filter((x) => x.length > 0);
    if (options.length > 0) {
      const [first, ...rest] = options;
      const optionStep: LibraryPromptWizardStep = {
        rawPlaceholder,
        isFreeForm,
        question: humanFriendlyJoin([_.capitalize(first), ...rest]) + '?',
        variableName: null,
        valueOptions: options.map((x) => _.capitalize(x)),
        metadata,
      };
      const additionalStep: LibraryPromptWizardStep | null = options.some((x) => [
        persistableVariables.indexOf(x) >= 0,
      ])
        ? {
            rawPlaceholder,
            isFreeForm,
            question: PREV_ANSWER_REFERENCE,
            variableName: PREV_ANSWER_REFERENCE,
            valueOptions: null,
            metadata,
          }
        : null;
      return additionalStep ? [optionStep, additionalStep] : [optionStep];
    } else {
      return [];
    }
  }

  return [
    {
      rawPlaceholder,
      isFreeForm,
      question: getQuestionOrDefault(unwrappedPlaceholder, isFreeForm),
      variableName: unwrappedPlaceholder,
      valueOptions: null,
      metadata,
    },
  ];
};

export const getLibraryPromptWizardSteps = (prompt: Prompt): LibraryPromptWizardStep[] => {
  const placeholders = getPromptPlaceholders(prompt.content);
  return _(placeholders)
    .flatMap((x: string) =>
      getWizardStepsByPlaceholder({
        rawPlaceholder: x,
        metadata: prompt.metadata.placeholders.find((m) => m.name === x) ?? null,
      })
    )
    .value();
};

export const getFinalPrompt = (libraryPromptContent: string, steps: LibraryPromptWizardStep[]) => {
  let result = libraryPromptContent;
  const placeholdersWithValues = _(steps)
    .map((x, i) => ({ index: i, value: x }))
    .groupBy('value.rawPlaceholder')
    .map((value, key) => ({
      placeholder: key,
      value: _(value)
        .sortBy((x) => x.index)
        .map((x) => x.value.answer)
        .value()
        .join(', '),
    }))
    .map((x) => ({ ...x, value: x.value.indexOf('\n') >= 0 ? `\n${x.value}` : x.value }))
    .value();
  for (const placeholdersWithValue of placeholdersWithValues) {
    result = result.replace(placeholdersWithValue.placeholder, placeholdersWithValue.value);
  }
  return result;
};

export const categoryColorAsProgramType = (
  category: { name: string; color?: string },
  programTypes: ProgramType[],
  promptCategories?: PromptCategory[]
) => {
  const programType = programTypes.find((x) => comparePromptCategories(x.name, category.name));
  if (programType) {
    return ensureSharpAtHex(programType.color);
  } else {
    if (category.color) {
      return ensureSharpAtHex(category.color);
    }
    const categoryDto = promptCategories?.find((x) =>
      comparePromptCategories(x.name, category.name)
    );
    if (categoryDto) {
      return ensureSharpAtHex(categoryDto.color);
    }
    const predefined = PREDEFINED_PROMPT_CATEGORIES.find((x) =>
      comparePromptCategories(x.name, category.name)
    );
    return predefined ? predefined.color : FALLBACK_CATEGORY_COLOR;
  }
};

export const getPromptColors = (
  prompt: Prompt,
  programTypes: ProgramType[],
  promptCategories: PromptCategory[]
): { firstColor: string; secondColor?: string } => {
  const colors = prompt.categories
    .slice(0, 2)
    .map((x) => categoryColorAsProgramType({ name: x }, programTypes, promptCategories));
  if (colors.length === 0) {
    return { firstColor: FALLBACK_CATEGORY_COLOR };
  } else if (colors.length === 1) {
    return { firstColor: colors[0] };
  } else {
    return { firstColor: colors[0], secondColor: colors[1] };
  }
};

export const getStringValueOrAdhocDisplayValue = (x: PromptVariableStringValue) =>
  x.value ? x.value : x.description;

export const compareStringValueOrAdhoc = (
  selected: PromptVariableStringValueOrAdhoc,
  x: PromptVariableStringValueOrAdhoc
) =>
  selected.kind === x.kind &&
  selected.id === x.id &&
  (x.kind === 'adhoc' ||
    getStringValueOrAdhocDisplayValue(x) === getStringValueOrAdhocDisplayValue(selected));

export const canUseGpt4 = (customer: Customer): boolean =>
  !!(
    getBillingPlan(customer.planName)?.features.gpt4 ||
    (customer.pendingPlanChange && getBillingPlan(customer.pendingPlanChange)?.features.gpt4)
  );

export const cleanUpVariableValue = (s: string) =>
  s.replaceAll('\r', '').replaceAll('\n', ' ').trim();

export const variableValueToContent = (promptVariableStringValue: PromptVariableStringValue) => {
  const value = cleanUpVariableValue(promptVariableStringValue.value).trim();
  const description = cleanUpVariableValue(promptVariableStringValue.description).trim();
  if (value && description) return `${value} (${description})`;
  return value ? value : description;
};
