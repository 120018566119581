import _ from 'lodash';
import jwtDecode from 'jwt-decode';
import { parseRole, SystemRole } from '../auth/systemRole';
import { Permission } from '../auth/permissions';
import { hasValue } from '../util/utils';

export type IUserTokens = {
  id: string;
  access: string;
  refresh: string;
};

export interface ICurrentUser {
  userId: string;
  email: string;
  tokenExpiration: number;
  roles: Array<SystemRole>;
  has: (permission: Permission) => boolean;
  hasAtLeastOne: (...permissions: Permission[]) => boolean;
}

export const areUsersEqual = (left: ICurrentUser | null, right: ICurrentUser | null) => {
  if (left === null && right === null) return true;
  if (left === null || right === null) return false;
  const fieldsToIgnore = ['has', 'hasAtLeastOne'];
  return _.isEqual(_.omit(left, fieldsToIgnore), _.omit(right, fieldsToIgnore));
};

export const NonAuthTokens: IUserTokens = {
  id: '',
  access: '',
  refresh: '',
};

type AccessTokenPayload = {
  username: string;
  exp: number;
  'cognito:groups': string[];
};

type IdTokenPayload = {
  email: string;
};

const rolePattern = /^(tenant\.[^.]+\.)?role\.(?<role>[^.]+)$/;
const extractRoles = (groups: string[]): SystemRole[] =>
  _(groups)
    .map((r) => {
      const roleName = r.match(rolePattern)?.groups?.role;
      return roleName ? parseRole(roleName) : null;
    })
    .filter(hasValue)
    .uniqBy((x) => x.id)
    .value();

export const createUser = (tokens: IUserTokens | null): ICurrentUser | null => {
  if (!tokens || tokens === NonAuthTokens) return null;

  const { id: idToken, access: accessToken, refresh: refreshToken } = tokens;
  if (!idToken || !accessToken || !refreshToken) {
    return null;
  }

  try {
    const { exp, 'cognito:groups': groups, username }: AccessTokenPayload = jwtDecode(accessToken);
    const { email }: IdTokenPayload = jwtDecode(idToken);
    const roles = extractRoles(groups);
    const grantedPermissions = new Set<Permission>(roles.flatMap((r) => Array.from(r.permissions)));

    return {
      userId: username,
      email,
      tokenExpiration: exp,
      roles,
      has: (p) => grantedPermissions.has(p),
      hasAtLeastOne: (...permissions) =>
        permissions.find((x) => grantedPermissions.has(x)) !== undefined,
    };
  } catch (error) {
    logger.error(
      `failed to parse tokens: idToken ${idToken}, accessToken ${accessToken}, refreshToken ${refreshToken}`,
      error
    );
    return null;
  }
};
