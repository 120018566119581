import { useLocation } from 'react-router-dom';
import { useMemo } from 'react';

const useQuery = () => {
  const { search } = useLocation();

  return useMemo(() => new URLSearchParams(search), [search]);
};

export const usePrefix = () => {
  const query = useQuery();
  const prefix = query.get('prefix');

  return useMemo(() => (prefix == null ? '' : prefix.toLowerCase()), [prefix]);
};
