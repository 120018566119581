import React, { useCallback, useMemo, useState } from 'react';
import moment from 'moment';
import { useMutation } from '@tanstack/react-query';
import { FileRejection } from 'react-dropzone';
import useApi from '../../api/backendApiContext';
import useAuth from '../../auth/authContext';
import {
  ExistedCustomFields,
  Program,
  ProgramCustomField,
  ProgramPartialUpdateData,
  ProgramRow,
  ProgramSummariesFetchError,
  ProgramSummary,
  ProgramTask,
  ProgramTaskChange,
  ResolvedProgramSummary,
  TaskInFocus,
  TODO_PROGRAM_STATUS,
} from '../types';
import { errorToString, newRenderId } from '../../util/utils';
import ProgramDialog, { ModalState } from './ProgramDialog';

import {
  areCustomFieldsSame,
  CalendarLocalStorageKey,
  createProgramFromTask,
  extractConsecutiveMonths,
  extractExistedCustomFields,
  flatMapUnique,
  getProgramPatchMessage,
  isNullOrEmpty,
  unresolveProgramSummary,
} from '../util';
import { useQueryCacheManager } from '../../hooks/useQueryCacheManager';
import { useResolvedProgramSummaries } from '../hooks/useResolvedProgramSummaries';
import { ProgramsTableMemoised, ProgramsTabType } from '../components/ProgramsTable';
import { useNotification } from '../../notification/notificationsContext';
import { useFetchedUsers } from '../../user/hooks/useFetchedUsers';
import { useFetchedProgramTypes } from '../hooks/useFetchedProgramTypes';
import { MultiFilterRowsPersistable } from '../../multifilter/types';
import { useMemoCompare } from '../../hooks/useMemoCompare';
import _ from 'lodash';
import useAnalyticsPage from '../../web-analytics/hooks/useAnalyticsPage';
import { Page } from '../../web-analytics/Page';
import { analyticsTrack } from '../../web-analytics/webAnalytics';
import { AnalyticsEvent } from '../../web-analytics/AnalyticsEvent';
import useAnalytics from '../../web-analytics/webAnalyticsContext';
import { prepareFormulasToHuman } from '../formulaUtil';
import { useResolvedCampaigns } from '../hooks/useResolvedCampaigns';
import { PeriodType } from '../../commons/components/PeriodPicker';
import { ProgramsMultiFilterProps } from '../components/ProgramsMultiFilter';
import { filterResolvedPrograms, SelectedFilter } from '../../multifilter/programFiltering';
import { useLocalStorage } from '@rehooks/local-storage';
import { isUnscheduled, unscheduledProgramsMonths } from '../unscheduledProgramUtil';
import ReactDOM from 'react-dom';
import { useProgramsDatesNavigation } from '../hooks/useProgramsDatesNavigation';
import { getDateRange } from '../../commons/components/DateRangeSelector';
import { useAddProgramPermissionHelper } from '../programPermissionHelper';
import { useDownloadAllProgramsToCsvMutation } from '../hooks/useDownloadAllProgramsToCsvMutation';
import { growegyAIConfig } from '../../commons/config';
import { ProgramsChat } from '../components/ai/ProgramsChat';
import { Permission } from '../../auth/permissions';

const MonthsLength = 3;

type ProgramsTableDates = {
  monthsToLoad: Date[];
  visible: { from: Date; to: Date };
};

const getTableDates = (from: Date, to: Date) => {
  const monthsToLoad = extractConsecutiveMonths(from, to, MonthsLength);
  return { monthsToLoad, visible: { from, to } };
};

const Programs = () => {
  useAnalyticsPage(Page.PROGRAMS_TABLE);
  const analytics = useAnalytics();

  const {
    state: { user },
  } = useAuth();
  const programCache = useQueryCacheManager();
  const { notifySuccess, notifyError } = useNotification();

  const renderId = useCallback(() => newRenderId(), []);
  const [modalState, setModalState] = useState<ModalState>(null);
  const [downloadError, setDownloadAllProgramsError] = useState<string | null>(null);
  const [tabType, setTabType] = useState<ProgramsTabType>('SCHEDULED');
  const [taskInFocus, setTaskInFocus] = useState<TaskInFocus | null>(null);

  const changeTab = useCallback(
    (tab: ProgramsTabType) => setTabType((prev) => (prev === tab ? prev : tab)),
    []
  );

  const { programApi } = useApi();
  const { isLoading: deleteInProgress, mutateAsync: deleteAllProgramsMutation } = useMutation(
    async () => programApi.deleteAllPrograms(),
    {
      onSuccess: async () => programCache.deleteAll(),
    }
  );
  const {
    isLoading: replaceInProgress,
    error: replaceError,
    mutate: replaceFromCSVMutation,
  } = useMutation(async (csv: string) => programApi.replaceAllProgramsFromCSV(csv), {
    onSettled: async () => {
      await programCache.deleteAll();
    },
  });

  const actionProgramsFromCSV = useCallback(
    (acceptedFiles: File[], fileRejections: FileRejection[]) => {
      if (!isNullOrEmpty(fileRejections) && isNullOrEmpty(acceptedFiles)) {
        const errorMsg = `All the files were rejected for CSV upload: ${JSON.stringify(
          fileRejections
        )}`;
        notifyError({ notificationMsg: 'CSV uploading failed', logMsg: errorMsg });
      }

      if (!isNullOrEmpty(fileRejections)) {
        logger.warn(
          `The following files were rejected for CSV upload: ${JSON.stringify(fileRejections)}`
        );
      }

      if (acceptedFiles.length > 1) {
        logger.warn('More than two files were selected for CSV upload. Using only the first one.');
      }

      const file = acceptedFiles[0];

      const reader = new FileReader();
      reader.onload = async () => {
        const strCsv = reader.result;
        if (typeof strCsv === 'string') {
          analyticsTrack(analytics, AnalyticsEvent.PROGRAM_REPLACED_FROM_CSV);
          await replaceFromCSVMutation(strCsv);
        }
      };
      reader.onerror = () => {
        notifyError({ notificationMsg: `Failed to read file` });
      };
      reader.readAsText(file);
    },
    [notifyError, replaceFromCSVMutation, analytics]
  );

  const { mutate: downloadToCSV } = useDownloadAllProgramsToCsvMutation((err) => {
    notifyError({ err, notificationMsg: `Failed to download programs to CSV.` });
    setDownloadAllProgramsError(errorToString(err));
  });

  const showEditModal = useCallback((program: ProgramSummary) => {
    setModalState({
      action: 'UPDATE',
      program,
      scrollToTaskId: null,
      taskToDeleteOnCreate: null,
    });
  }, []);

  const addProgramPermissionHelper = useAddProgramPermissionHelper();
  const showProgramFromTaskModal = useCallback(
    (sourceProgram: ResolvedProgramSummary, sourceTask: ProgramTask) => {
      const version = sourceProgram.version;
      const taskId = sourceTask.id;
      if (taskId === null || version === undefined) return;

      const program = createProgramFromTask(
        unresolveProgramSummary(sourceProgram),
        sourceTask,
        user !== null ? user.userId : null,
        !!sourceProgram.campaign &&
          addProgramPermissionHelper.canCreateProgramInCampaign(sourceProgram.campaign)
      );
      setModalState({
        action: 'TASK_TO_PROGRAM',
        program,
        scrollToTaskId: null,
        taskToDeleteOnCreate: {
          programId: sourceProgram.id,
          version,
          taskId,
        },
      });
    },
    [addProgramPermissionHelper, user]
  );

  const createProgram = useCallback((program: ProgramSummary) => {
    setModalState({ action: 'CREATE', program, scrollToTaskId: null, taskToDeleteOnCreate: null });
  }, []);

  const { tablePeriodDateRange } = useProgramsDatesNavigation();
  const [tableDates, setTableDates] = useState<ProgramsTableDates>(() => {
    const dateRange = getDateRange(tablePeriodDateRange);
    return getTableDates(dateRange.firstDay, dateRange.lastDay);
  });

  const programMonthsToLoad = useMemoCompare<Date[]>(
    tabType === 'SCHEDULED' ? tableDates.monthsToLoad : unscheduledProgramsMonths,
    _.isEqual
  );

  const onDatesNavigatorChanged = useCallback(
    (period: PeriodType, from: Date, to: Date) => setTableDates(getTableDates(from, to)),
    []
  );

  const summaryQueryResults = useResolvedProgramSummaries({
    monthsStart: programMonthsToLoad,
  });

  const errors = useMemo(
    () =>
      summaryQueryResults.data
        .filter(({ status }) => status === 'error')
        .map(({ error }) => {
          if (error instanceof ProgramSummariesFetchError) {
            return (
              <div
                key={error.startMonth.toISOString()}
                className="alert alert-warning"
                role="alert"
                data-test={`programs__loading-error-${moment(error.startMonth).format('MMM-YYYY')}`}
              >
                Could not load programs for {moment(error.startMonth).format('MMM, YYYY')}:{' '}
                {errorToString(error)}
              </div>
            );
          }

          return (
            <div key={renderId()} className="alert alert-warning" role="alert">
              Could not load programs for: {errorToString(error)}
            </div>
          );
        }),
    [renderId, summaryQueryResults.data]
  );

  const programs = useMemoCompare(
    flatMapUnique(
      summaryQueryResults.data
        .filter((s) => s.data)
        .sort((a, b) => a.data!.startMonth.valueOf() - b.data!.startMonth.valueOf())
        .map((s) =>
          s.data!.summaries.filter((summary) => {
            return tabType === 'UNSCHEDULED'
              ? isUnscheduled(summary)
              : summary.startDateTime <= tableDates.visible.to &&
                  summary.endDateTime > tableDates.visible.from;
          })
        ),
      (s) => s.id
    ),
    (a, b) => _.isEqual(a, b)
  );

  // eslint-disable-next-line @typescript-eslint/no-unused-vars, no-unused-vars
  const [counter, setCounter] = useState(0);

  const existedCustomFields = useMemoCompare<ExistedCustomFields>(
    extractExistedCustomFields(programs),
    areCustomFieldsSame
  );

  const existedUsers = useFetchedUsers();
  const existedProgramTypes = useFetchedProgramTypes();
  const existedCampaigns = useResolvedCampaigns();

  const [filter, setFilter] = useLocalStorage<SelectedFilter | null>(
    `${CalendarLocalStorageKey}appliedFilter`,
    null
  );
  const [persistedFilters, setPersistedFilters] = useLocalStorage<
    MultiFilterRowsPersistable<ResolvedProgramSummary>[]
  >(`${CalendarLocalStorageKey}savedFilters`, []);

  const filteredPrograms: ProgramRow[] = useMemo(
    () => filterResolvedPrograms(programs, filter),
    [filter, programs]
  );

  const programRows = useMemoCompare(
    filteredPrograms,
    (a, b) => summaryQueryResults.status === 'loading' || _.isEqual(a, b)
  );

  const { mutate: updateMutation } = useMutation(
    async ({
      original,
      change,
      taskChanges,
    }: {
      original: ProgramSummary;
      change: ProgramSummary;
      taskChanges?: ProgramTaskChange[];
    }) => {
      const programParam = _.isEqual(
        _.omit(original, ['resolvedTasks', 'tasks']),
        _.omit(change, ['resolvedTasks', 'tasks'])
      )
        ? null
        : change;
      const taskChangesParam = taskChanges ? taskChanges : null;
      const response = await programApi.patchProgram(
        original.id,
        original.version!!,
        programParam,
        taskChangesParam
      );
      analyticsTrack(analytics, AnalyticsEvent.PROGRAM_UPDATED);
      return { response, programChanged: programParam !== null, taskChanges: taskChangesParam };
    },
    {
      onSuccess: (
        {
          response: { changedProgram, parentCampaign, oldParentCampaign },
          programChanged,
          taskChanges,
        },
        { change }
      ) => {
        if (parentCampaign) programCache.onCampaignUpdate(parentCampaign);
        if (oldParentCampaign) programCache.onCampaignUpdate(oldParentCampaign);
        programCache.updateOptimistically(change, changedProgram);
        notifySuccess({
          notificationMsg: getProgramPatchMessage(change.programKind, programChanged, taskChanges),
          logMsg: `Program ${change.name} is updated.`,
        });
      },
      onError: async (_error, { original }) => {
        notifyError({
          err: _error,
          logMsg: `Program dialog failed to update program ${JSON.stringify(original)}`,
          notificationMsg: `Failed to update program.`,
        });
        const invalidateSummaries =
          _error instanceof Error && _error.message.includes('status code 409');
        await programCache.onUpdateError(original.id, invalidateSummaries);
      },
    }
  );

  const handleDeleteAllPrograms = useCallback(
    async () => deleteAllProgramsMutation(),
    [deleteAllProgramsMutation]
  );

  const applyUpdateLocally = useCallback(
    (
      originalProgram: Program,
      changedProgram: Program,
      taskChanges: undefined | ProgramTaskChange[]
    ) => {
      programCache.updateOptimistically(originalProgram, changedProgram);
      if (taskChanges) {
        // HACK: Force re-render to prevent tasks flickering after drag.
        ReactDOM.flushSync(() => {
          setCounter((prevState) => prevState + 1);
        });
      }
    },
    [programCache]
  );

  const handleUpdateProgram = useCallback(
    async ({
      original,
      updates,
    }: {
      original: ProgramRow;
      updates: ProgramPartialUpdateData[];
    }) => {
      const change = { ...original, customFields: _.cloneDeep(original.customFields) };
      let taskChanges: ProgramTaskChange[] | undefined;
      for (const update of updates) {
        switch (update.field) {
          case 'name': {
            change.name = update.value ?? '';
            break;
          }
          case 'status': {
            change.status = update.value ?? TODO_PROGRAM_STATUS;
            break;
          }
          case 'owner': {
            change.owner = update.value;
            break;
          }
          case 'vendor': {
            change.vendor = update.value ?? '';
            break;
          }
          case 'salesforceCampaignId': {
            change.salesforceCampaignId = update.value ?? '';
            break;
          }
          case 'campaign': {
            change.campaign = update.value;
            break;
          }
          case 'startDateTime': {
            if (update.value) {
              change.startDateTime = update.value;
            }
            break;
          }
          case 'endDateTime': {
            if (update.value) {
              change.endDateTime = update.value;
            }
            break;
          }
          case 'leads': {
            change.leads = update.value ?? 0;
            break;
          }
          case 'actualLeads': {
            change.actualLeads = update.value;
            break;
          }
          case 'budget': {
            change.budget = update.value ?? 0;
            break;
          }
          case 'resolvedTasks': {
            change.resolvedTasks = update.value.tasksAfterChange;
            taskChanges = update.value.changes;
            break;
          }
          case 'customFields': {
            if (!update.customFieldData.value) {
              change.customFields = change.customFields.filter(
                (c) =>
                  !(c.name === update.customFieldName && c.type === update.customFieldData.type)
              );
            } else {
              const field = change.customFields.find(
                (c) => c.name === update.customFieldName && c.type === update.customFieldData.type
              );
              if (!field)
                change.customFields.push({
                  value: update.customFieldData.value,
                  name: update.customFieldName,
                  type: update.customFieldData.type,
                } as ProgramCustomField);
              else {
                field.value = update.customFieldData.value;
              }
            }
            break;
          }
        }
      }
      change.customFields = prepareFormulasToHuman(change);
      if (
        !!taskChanges &&
        taskChanges.length === 1 &&
        taskChanges[0].changeType === 'CREATE' &&
        taskChanges[0].orderId !== undefined &&
        taskChanges[0].shouldFocus
      ) {
        setTaskInFocus({ programId: change.id, taskOrderId: taskChanges[0].orderId });
      } else {
        setTaskInFocus(null);
      }
      const originalProgram = unresolveProgramSummary(original);
      const changedProgram = unresolveProgramSummary(change);

      applyUpdateLocally(originalProgram, changedProgram, taskChanges);
      await updateMutation({
        original: originalProgram,
        change: changedProgram,
        taskChanges: taskChanges,
      });
    },
    [updateMutation, applyUpdateLocally]
  );

  const persistFiltersClick = useCallback(
    (filters: MultiFilterRowsPersistable<ResolvedProgramSummary>[]) => setPersistedFilters(filters),
    [setPersistedFilters]
  );
  const filterApplyClick = useCallback(
    (f: SelectedFilter | null) => {
      analyticsTrack(analytics, AnalyticsEvent.PROGRAM_FILTER_APPLIED);
      return setFilter(f);
    },
    [analytics, setFilter]
  );

  const filterProps = useMemo<ProgramsMultiFilterProps>(
    () => ({
      persistedFilters,
      appliedFilter: filter,
      applyFilter: filterApplyClick,
      persistFilters: persistFiltersClick,
      existedUsers: existedUsers.data,
      existedTypes: existedProgramTypes.data?.types,
      existedCampaigns: existedCampaigns.data,
      existedCustomFields,
      noFormula: false,
    }),
    [
      existedCampaigns.data,
      existedCustomFields,
      existedProgramTypes.data,
      existedUsers.data,
      filter,
      filterApplyClick,
      persistFiltersClick,
      persistedFilters,
    ]
  );

  return (
    <div className="programs-container" data-test="programs">
      <>
        {replaceError && (
          <div
            className="alert alert-danger alert-dismissible fade show"
            data-test="programs__replace-error"
          >
            <strong>Replace from CSV failed!</strong> {errorToString(replaceError)}
          </div>
        )}
        {downloadError && (
          <div
            className="alert alert-danger alert-dismissible fade show"
            data-test="programs__download-error"
          >
            <strong>Download to CSV failed!</strong> {downloadError}
          </div>
        )}
        {errors}
        <ProgramsTableMemoised
          user={user}
          onCreate={createProgram}
          onProgramClick={showEditModal}
          onProgramUpdate={handleUpdateProgram}
          onConvertTaskToProgram={showProgramFromTaskModal}
          isLoading={deleteInProgress || replaceInProgress}
          onDeleteAllPrograms={handleDeleteAllPrograms}
          onDownloadToCSV={downloadToCSV}
          onActionProgramsFromCSV={actionProgramsFromCSV}
          data={programRows}
          onDatesChanged={onDatesNavigatorChanged}
          customFields={existedCustomFields}
          tabType={tabType}
          changeTab={changeTab}
          filterProps={filterProps}
          taskInFocus={taskInFocus}
        />
        {growegyAIConfig.programsChatEnabled && user && user.has(Permission.ADD_PROGRAM) && (
          <ProgramsChat />
        )}
        {modalState && (
          <ProgramDialog
            action={modalState.action}
            programSummary={modalState.program}
            scrollToTaskId={modalState.scrollToTaskId}
            onHide={() => setModalState(null)}
            taskToDeleteOnCreate={
              modalState.taskToDeleteOnCreate !== null ? modalState.taskToDeleteOnCreate : undefined
            }
          />
        )}
      </>
    </div>
  );
};

export default Programs;
