import { ChatList } from '../components/ChatList';
import { Messenger } from '../components/Messenger';
import React, { useCallback, useState } from 'react';
import { Chat } from '../../types';
import { createNewChat } from '../../util';
import { CHAT_TYPE_GENERAL } from '../../dto';

export const ChatTab = (props: {
  selectedChat: Chat | null;
  onChatSelected: (chat: Chat) => void;
  onOpenLibraryClick: () => void;
  onOpenVariablesClick: () => void;
}) => {
  const { selectedChat, onChatSelected, onOpenLibraryClick, onOpenVariablesClick } = props;

  const onNewChatClick = useCallback(() => {
    onChatSelected(createNewChat(CHAT_TYPE_GENERAL));
  }, [onChatSelected]);
  const [gptModelSelectDiv, setGptModelSelectDiv] = useState<HTMLDivElement | null>(null);

  return (
    <div className="growegy-ai__chat-tab-container">
      <ChatList
        chatType={CHAT_TYPE_GENERAL}
        selectedChatId={selectedChat !== null ? selectedChat.id : null}
        onChatSelected={onChatSelected}
      />
      <div className="messenger__placeholder">
        {selectedChat && (
          <>
            <div ref={(x) => setGptModelSelectDiv(x)} className="gpt-model-selector__wrapper" />
            <Messenger
              key={`chat-${selectedChat.id}`}
              chat={selectedChat}
              systemMessage={null}
              gptModelSelectDiv={gptModelSelectDiv}
              onChatCreated={onChatSelected}
              onOpenLibraryClick={onOpenLibraryClick}
              onNewChatClick={onNewChatClick}
              onManageVariablesClick={onOpenVariablesClick}
            />
          </>
        )}
      </div>
    </div>
  );
};
