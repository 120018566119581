import { useRef } from 'react';
import { Campaign, getProgramStatus, NoProgramType, QueryData, ResolvedCampaign } from '../types';
import { useQueryCacheManager } from '../../hooks/useQueryCacheManager';
import { useFetchedUsers } from '../../user/hooks/useFetchedUsers';
import { UnknownProgramOwner, User } from '../../user/types';
import { aggregateQueries, resolveProgramOwner } from '../util';
import { useFetchedCampaigns } from './useFetchedCampaigns';

const resolveCampaign = (campaign: Campaign, users: User[]): ResolvedCampaign => {
  const type = { ...NoProgramType };
  const owner = resolveProgramOwner(campaign, users);

  return {
    ...campaign,
    type,
    owner,
    resolvedTasks: [],
    campaign: null,
    status: getProgramStatus(campaign.status),
  };
};

const resolveCampaigns = (campaigns: Campaign[] | null, users: User[]) => {
  const resolvedCampaigns: ResolvedCampaign[] | null = campaigns
    ? campaigns.map((x) => resolveCampaign(x, users))
    : null;
  const hasUnresolvedOwners =
    !!resolvedCampaigns &&
    !!resolvedCampaigns.find((s) => s.owner && s.owner.id === UnknownProgramOwner.id);
  return { resolvedCampaigns, hasUnresolved: hasUnresolvedOwners };
};

export const useResolvedCampaigns = (): QueryData<ResolvedCampaign[] | null> => {
  const programCache = useQueryCacheManager();
  const campaignsQuery = useFetchedCampaigns();
  const usersQuery = useFetchedUsers();

  const ownerResolvingAttempt = useRef<number>(0);

  const { resolvedCampaigns, hasUnresolved } = resolveCampaigns(
    campaignsQuery.data ?? null,
    usersQuery.data ?? []
  );

  const aggregateQueryMetadata = aggregateQueries([campaignsQuery], hasUnresolved, [
    {
      metadata: usersQuery,
      initiateReload: async () => programCache.initUsersRefetch(),
      attemptCounter: ownerResolvingAttempt,
    },
  ]);

  return {
    data: resolvedCampaigns,
    ...aggregateQueryMetadata,
  };
};
