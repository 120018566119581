import {
  BillingStripeInvoiceDto,
  BillingStripeInvoiceLineDto,
  BillingStripeInvoicesListDto,
  BillingStripeProductDto,
  FutureBillingStripeInvoiceDto,
  StripeCustomerDto,
  StripeInvoiceStatus,
} from './dto';
import { StyleVariables } from '../commons/styleConstants';
import moment from 'moment';

export type Customer = Omit<StripeCustomerDto, 'activeSubscriptions'> & {
  activeSubscription: CustomerSubscription | null;
};

export type CustomerSubscription = {
  cancelAt: Date | null;
  futureCancellationDate: Date | null;
};

export type InvoiceLine = {
  id: string;
  amount: number;
  description: string;
  product: BillingStripeProductDto | null;
  start: Date | null;
  end: Date | null;
};

export const INVOICE_TYPE_ISSUED = 'issued';
export const INVOICE_TYPE_FUTURE = 'future';

export type IssuedInvoice = {
  type: typeof INVOICE_TYPE_ISSUED;
  id: string;
  hostedInvoiceUrl: string;
  invoicePdf: string;
  status: StripeInvoiceStatus;
  periodStart: Date;
  periodEnd: Date;
  dueDate: Date;
  createdDate: Date;
  total: number;
  amountDue: number;
  amountPaid: number;
  amountRemaining: number;
  description: string;
  lines: InvoiceLine[];
  billingPlanName: string | null;
};

export type FutureInvoice = {
  type: typeof INVOICE_TYPE_FUTURE;
  periodStart: Date;
  periodEnd: Date;
  dueDate: Date | null;
  createdDate: Date;
  amountDue: number;
  billingPlanName: string | null;
  description: string;
  optional: boolean;
};

export type Invoice = IssuedInvoice | FutureInvoice;

export const toCustomer = (dto: StripeCustomerDto): Customer => ({
  ...dto,
  activeSubscription:
    dto.activeSubscriptions && dto.activeSubscriptions.length > 0
      ? {
          cancelAt: dto.activeSubscriptions[0].cancelAt
            ? new Date(dto.activeSubscriptions[0].cancelAt)
            : null,
          futureCancellationDate: dto.activeSubscriptions[0].futureCancellationDate
            ? new Date(dto.activeSubscriptions[0].futureCancellationDate)
            : null,
        }
      : null,
});

const toInvoiceLine = (dto: BillingStripeInvoiceLineDto): InvoiceLine => ({
  ...dto,
  amount: dto.amount / 100,
  start: dto.start ? new Date(parseInt(dto.start) * 1000) : null,
  end: dto.end ? new Date(parseInt(dto.end) * 1000) : null,
});

export const toInvoices = (dto: BillingStripeInvoicesListDto) =>
  dto.invoices
    .map((x) => toIssuedInvoice(x) as Invoice)
    .concat(dto.futureInvoices?.map((x) => toFutureInvoice(x) as Invoice) ?? []);

const toIssuedInvoice = (dto: BillingStripeInvoiceDto): IssuedInvoice => ({
  ...dto,
  type: INVOICE_TYPE_ISSUED,
  total: dto.total / 100,
  amountPaid: dto.amountPaid / 100,
  amountDue: dto.amountDue / 100,
  amountRemaining: dto.amountRemaining / 100,
  periodStart: new Date(parseInt(dto.periodStart) * 1000),
  periodEnd: new Date(parseInt(dto.periodEnd) * 1000),
  dueDate: new Date(parseInt(dto.dueDate) * 1000),
  createdDate: new Date(parseInt(dto.createdDate) * 1000),
  description: dto.description,
  lines: dto.lines.map((x) => toInvoiceLine(x)),
});

const toFutureInvoice = (dto: FutureBillingStripeInvoiceDto): FutureInvoice => ({
  type: INVOICE_TYPE_FUTURE,
  periodStart: new Date(parseInt(dto.periodStart) * 1000),
  periodEnd: new Date(parseInt(dto.periodEnd) * 1000),
  dueDate: dto.dueDate ? new Date(parseInt(dto.dueDate) * 1000) : null,
  createdDate: new Date(parseInt(dto.periodStart) * 1000),
  amountDue: dto.amountDue / 100,
  billingPlanName: dto.billingPlanName,
  description: dto.description,
  optional: dto.optional,
});

export const invoiceStatusVisualData: Record<
  StripeInvoiceStatus,
  {
    textColor: string;
    lineColor: string;
    statusDisplayName: string;
  }
> = {
  OPEN: {
    textColor: StyleVariables.cyan500,
    lineColor: StyleVariables.cyan500,
    statusDisplayName: 'Open',
  },
  PAID: {
    textColor: StyleVariables.green500,
    lineColor: StyleVariables.green500,
    statusDisplayName: 'Paid',
  },
  UNCOLLECTIBLE: {
    textColor: StyleVariables.red500,
    lineColor: StyleVariables.red500,
    statusDisplayName: 'Uncollectible',
  },
  VOID: {
    textColor: StyleVariables.neutral500,
    lineColor: StyleVariables.neutral200,
    statusDisplayName: 'Void',
  },
  DRAFT: {
    textColor: StyleVariables.neutral500,
    lineColor: StyleVariables.neutral200,
    statusDisplayName: 'Draft',
  },
  DELETED: {
    textColor: StyleVariables.neutral500,
    lineColor: StyleVariables.neutral200,
    statusDisplayName: 'Deleted',
  },
};

export const pastDueVisualData = {
  textColor: StyleVariables.red500,
  lineColor: StyleVariables.red500,
  statusDisplayName: 'Past due',
};

export const futureInvoiceVisualData = (createdDate: Date) => ({
  textColor: StyleVariables.neutral500,
  lineColor: StyleVariables.neutral200,
  statusDisplayName: `Opens on ${moment(createdDate).format('MMM D')}`,
});

export type InvoiceAndBenefits = {
  invoice: Invoice | number | null;
  referenceInvoice: Invoice | null;
  regularBenefits: string[];
  specialBenefits: string[];
};

export type CancelationReason = { text: string; code: string };
