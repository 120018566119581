import CloudWatchSpaLoggerInit from './cloudwatch/cloudWatchSpaLoggerInit';
import FaroSpaLoggerInit from './faro/faroSpaLoggerInit';
import { ConsoleSpaLogger } from './consoleSpaLogger';

export const initializeLogger = () => {
  const loggerType = process.env.REACT_APP_LOGGER;
  try {
    if (loggerType === 'cloudwatch') {
      CloudWatchSpaLoggerInit.init()
        .then(() => {
          logger.info('CloudWatch logger has been successfully initialized.');
        })
        .catch((err) => {
          logger.error(
            'CloudWatch logger initialization has failed. Resort logging to console.',
            err
          );
          throw err;
        });
    } else if (loggerType === 'grafana') {
      FaroSpaLoggerInit.init();
      logger.info('Faro logger has been successfully initialized.');
    } else {
      global.logger = new ConsoleSpaLogger();
      logger.info('Console logger has been successfully initialized.');
    }
  } catch (err) {
    global.logger = new ConsoleSpaLogger();
    logger.error(
      `Logger '${loggerType}' initialization has failed. Resort logging to console.`,
      err
    );
  }
};
