import { useMutation } from '@tanstack/react-query';
import useApi from '../../../api/backendApiContext';
import useAuth from '../../../auth/authContext';
import { useQueryCacheManager } from '../../../hooks/useQueryCacheManager';

export const useCancelMessageMutation = (onSuccess: () => void, onError: () => void) => {
  const { growegyAIApi } = useApi();
  const {
    state: { user },
  } = useAuth();
  const queryCacheManager = useQueryCacheManager();
  return useMutation(
    async (variables: { chatId: string; clientMessageId: string }) => {
      const { chatId, clientMessageId } = variables;
      if (!user) return;
      await growegyAIApi.cancelMessage(user.userId, chatId, clientMessageId);
    },
    {
      onSuccess: (_, variables) => {
        queryCacheManager.onMessageCancelled(variables.chatId, variables.clientMessageId);
        onSuccess();
      },
      onError,
    }
  );
};
