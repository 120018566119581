export class Page {
  public static readonly SIGN_IN = new Page('Sign In');

  public static readonly SIGN_UP = new Page('Details', 'Sign Up');

  public static readonly CONFIRMATION_CODE = new Page('Confirmation Code', 'Sign Up');

  public static readonly FORGOT_PASSWORD_EMAIL = new Page('Enter Email', 'Forgot Password');

  public static readonly FORGOT_PASSWORD_NEW_PASSWORD = new Page('New Password', 'Forgot Password');

  public static readonly CHANGE_PASSWORD = new Page('Change Password');

  public static readonly PROGRAMS_CALENDAR = new Page('Programs Calendar');

  public static readonly PROGRAMS_TABLE = new Page('Programs Table');

  public static readonly PROGRAM_DEEP_LINK = new Page('Program Deep Link');

  public static readonly PROGRAM_CREATE = new Page('New Program');

  public static readonly PROGRAM_UPDATE = new Page('Program Details');

  public static readonly PROGRAM_COPY = new Page('Copy Program');

  public static readonly TASK_TO_PROGRAM = new Page('Task To Program');

  public static readonly ATTACHMENT_VIEW = new Page('Attachment View');

  public static readonly ANALYTICS = new Page('Analytics');

  public static readonly ANALYTICS_ENTERPRISE = new Page('Analytics for enterprise');

  public static readonly INTEGRATIONS = new Page('Integrations');

  public static readonly INTEGRATION_UPDATE = new Page('Integration Details');

  public static readonly USERS_MANAGEMENT = new Page('Users Management');

  public static readonly USER_CREATE = new Page('New User');

  public static readonly USER_UPDATE = new Page('User Details');

  public static readonly USER_DELETE = new Page('Delete User');

  public static readonly USER_PROFILE = new Page('User Profile');

  public static readonly PROGRAM_TYPES = new Page('Program Types');

  public static readonly PROGRAM_TYPE_CREATE = new Page('New Program Type');

  public static readonly PROGRAM_TYPE_UPDATE = new Page('Program Type Details');

  public static readonly PROGRAM_TYPE_DELETE = new Page('Delete Program Type');

  public static readonly RECOMMENDATIONS = new Page('Recommendations');

  public static readonly BILLING = new Page('Billing');

  public static readonly NOTIFICATION_SETTINGS = new Page('Notification Settings');

  public static readonly PERSONAL_INTEGRATIONS = new Page('Personal Integrations');

  public static readonly GROWEGY_AI = new Page('Growegy AI');

  private readonly pageName;

  private readonly pageCategory;

  constructor(name: string, category: string | undefined = undefined) {
    this.pageName = name;
    this.pageCategory = category;
  }

  get name(): string {
    return this.pageName;
  }

  get category(): string | undefined {
    return this.pageCategory;
  }
}
