import moment from 'moment';

export const utcDateAsLocal = (date: Date) =>
  new Date(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate());

const localDateAsUtc = (local: Date) =>
  new Date(Date.UTC(local.getFullYear(), local.getMonth(), local.getDate()));

export const adjustToLocal = (date: Date, isWholeDay: boolean) =>
  isWholeDay ? utcDateAsLocal(date) : date;
export const adjustToUtc = (date: Date, isWholeDay: boolean) =>
  isWholeDay ? localDateAsUtc(date) : date;

export const internalEndDateToDisplay = (date: Date, isWholeDay: boolean) =>
  isWholeDay ? moment(date).subtract(1, 'day').toDate() : date;

export const displayEndDateToInternal = (date: Date, isWholeDay: boolean) =>
  isWholeDay ? moment(date).add(1, 'day').toDate() : date;
export const formatDateTimeCell = (
  value: Date | null,
  formatDateTime: string = 'MMM DD'
): string => (value ? moment(value).format(formatDateTime) : '');
