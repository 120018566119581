import React, { PropsWithChildren, useEffect, useState } from 'react';
import {
  Cell,
  CellProps,
  Column,
  ColumnInstance,
  HeaderProps,
  Row,
  TableInstance,
  TableOptions,
  useAsyncDebounce,
  useGlobalFilter,
  useSortBy,
  useTable,
  useFlexLayout,
} from 'react-table';
import { Dropdown } from 'react-bootstrap';

import { User, UserRow, UserStatus } from '../types';
import { USER_ROLE } from '../../auth/systemRole';
import { stringToHslColor } from '../../util/utils';
import { extractUserInitials } from '../utils';
import { PlusButton } from '../../program/components/PlusButton';
import { DivButton } from '../../commons/components/DivButton';
import { BinIcon, EnvelopeIcon, PencilIcon, PlusSmallIcon } from '../../assets/icons';
import { DebounceMsConfig } from '../../config';
import { TextAndIcon } from '../../commons/components/TextAndIcon';
import { createPortal } from 'react-dom';

interface TableProperties extends TableOptions<UserRow> {
  name: string;
  onUpdate: (user: User) => void;
  onDelete: (user: User) => void;
  onCreate: (user: User) => void;
  onRefresh: (userId: string) => Promise<unknown>;
  hasAddPermission: boolean;
  hasDeletePermission: boolean;
  hasRefreshPermission: boolean;
  hasReplacePermission: boolean;
}

const hooks = [useFlexLayout, useGlobalFilter, useSortBy];

const getSortCss = (column: ColumnInstance<UserRow>) => {
  if (column.isSorted && column.isSortedDesc) return 'users-table__sort--desc';
  if (column.isSorted && !column.isSortedDesc) return 'users-table__sort--asc';
  return '';
};

const NameCell = ({ value, row }: CellProps<UserRow, string>) => (
  <div className="d-flex" data-test="users-table__name-cell">
    {row.original.avatar ? (
      <img
        src={row.original.avatar}
        alt={row.original.email}
        className="users-table__avatar"
        data-test="users-table__avatar"
      />
    ) : (
      <div
        className="users-table__avatar d-flex"
        style={{
          backgroundColor: stringToHslColor(row.original.email),
        }}
      >
        <span className="users-table__initials">
          {extractUserInitials(row.original, row.original.email)}
        </span>
      </div>
    )}
    <span className="text-capitalize text-break" data-test="users-table__name">
      {value}
    </span>
  </div>
);

const NameHeader = ({ column }: HeaderProps<UserRow>) => (
  <div
    {...column.getSortByToggleProps()}
    data-test="users-table__name-header"
    className="users-table__header"
  >
    <span className="text-capitalize users-table__header-title">Name</span>
    <div className={`users-table__sort ${getSortCss(column)}`} />
  </div>
);

const EmailCell = ({ value }: CellProps<UserRow, string>) => (
  <div data-test="users-table__email-cell">
    <span className="text-break">{value}</span>
  </div>
);

const EmailHeader = ({ column }: HeaderProps<UserRow>) => (
  <div
    {...column.getSortByToggleProps()}
    data-test="users-table__email-header"
    className="users-table__header"
  >
    <span className="text-capitalize users-table__header-title">Email</span>
    <div className={`users-table__sort ${getSortCss(column)}`} />
  </div>
);

const UserRoleCell = ({ value }: CellProps<UserRow, string>) => (
  <div data-test="users-table__role-cell">
    <span className="text-capitalize">{value}</span>
  </div>
);

const RoleHeader = () => (
  <div data-test="users-table__role-header">
    <span className="text-capitalize users-table__header-title">Role</span>
  </div>
);

type AnchorProps = React.HTMLProps<HTMLButtonElement>;
type ActionType = 'EDIT' | 'DELETE' | 'REFRESH_PASSWORD';
const CustomToggle = React.forwardRef<HTMLButtonElement, AnchorProps>(({ onClick }, ref) => (
  <button
    className="dropdown-menu__button"
    data-test="users-table__btn-action"
    type="button"
    ref={ref}
    onClick={(e) => {
      e.preventDefault();
      onClick && onClick(e);
    }}
    style={{ textDecoration: 'none' }}
  >
    <span>•••</span>
  </button>
));
type ActionCellProps = {
  onUpdate: (user: User) => void;
  onDelete: (user: User) => void;
  onRefresh: (userId: string) => Promise<unknown>;
  hasDeletePermission: boolean;
  hasRefreshPermission: boolean;
  hasReplacePermission: boolean;
} & CellProps<UserRow, UserStatus>;

const ActionCell = ({
  value,
  row,
  onUpdate,
  onDelete,
  onRefresh,
  hasDeletePermission,
  hasRefreshPermission,
  hasReplacePermission,
}: ActionCellProps) => {
  const { original } = row;

  const doRefresh = async () => onRefresh(original.id);
  const onSelect = async (eventKey: string | null) => {
    if (!eventKey) return;
    const action = eventKey as ActionType;
    switch (action) {
      case 'EDIT':
        onUpdate({ ...original });
        break;
      case 'DELETE':
        onDelete({ ...original });
        break;
      case 'REFRESH_PASSWORD':
        await doRefresh();
        break;
    }
  };

  return (
    <div className="col-1" data-test="users-table__actions-cell">
      <Dropdown onSelect={onSelect} drop="down" alignRight={false}>
        <Dropdown.Toggle as={CustomToggle} className="d-flex align-items-center" />
        <Dropdown.Menu title="">
          {hasReplacePermission && (
            <Dropdown.Item eventKey="EDIT" data-test="users-table__edit-action">
              <TextAndIcon text="Edit" icon={<PencilIcon />} />
            </Dropdown.Item>
          )}
          {hasDeletePermission && (
            <Dropdown.Item eventKey="DELETE" data-test="users-table__delete-action">
              <TextAndIcon text="Delete" icon={<BinIcon />} />
            </Dropdown.Item>
          )}
          {hasRefreshPermission && value === 'need-to-change-password' && (
            <Dropdown.Item
              eventKey="REFRESH_PASSWORD"
              data-test="users-table__refresh-password-action"
            >
              <TextAndIcon text="Resend invite" icon={<EnvelopeIcon />} />
            </Dropdown.Item>
          )}
        </Dropdown.Menu>
      </Dropdown>
    </div>
  );
};

const ActionHeader = () => <div />;

const GlobalFilter = (props: { instance: TableInstance<UserRow> }) => {
  const {
    setGlobalFilter,
    state: { globalFilter },
  } = props.instance;
  const [value, setValue] = useState(globalFilter || '');
  const onChange = useAsyncDebounce((v) => {
    setGlobalFilter(v || undefined);
  }, DebounceMsConfig.tableGlobalFilterDebounceMs);

  useEffect(() => {
    setValue(globalFilter || '');
  }, [globalFilter]);

  return (
    <div className="users-table__search-wrapper">
      <div className="users-table__search-icon-wrapper" />
      <input
        className="users-table__search-input"
        data-test="users-table__search"
        value={value || ''}
        onChange={(e) => {
          setValue(e.target.value);
          onChange(e.target.value);
        }}
        placeholder="Search"
      />
    </div>
  );
};

const tableColumns: Column<UserRow>[] = [
  {
    Header: NameHeader,
    id: 'username',
    accessor: (u: UserRow) => u.username,
    maxWidth: 280,
    Cell: NameCell,
  },
  {
    Header: EmailHeader,
    id: 'email',
    accessor: (u: UserRow) => u.email,
    maxWidth: 280,
    Cell: EmailCell,
  },
  {
    Header: RoleHeader,
    id: 'role',
    accessor: (u: UserRow) => u.role.name,
    maxWidth: 140,
    Cell: UserRoleCell,
  },
  {
    Header: ActionHeader,
    id: 'status',
    accessor: (u: UserRow) => u.status,
    // @ts-ignore
    Cell: ActionCell,
    disableGlobalFilter: true,
  },
];

const TableRow = (props: {
  row: Row<UserRow>;
  onUpdate: (user: User) => void;
  onDelete: (user: User) => void;
  onRefresh: (userId: string) => Promise<unknown>;
  hasDeleteAccess: boolean;
  hasRefreshAccess: boolean;
  hasReplaceAccess: boolean;
}) => {
  const {
    row,
    onUpdate,
    onDelete,
    onRefresh,
    hasDeleteAccess,
    hasRefreshAccess,
    hasReplaceAccess,
  } = props;

  return (
    <div {...row.getRowProps()} className="users-table__row" data-test="users-table__row">
      {row.cells.map((cell: Cell<UserRow>) => (
        <div {...cell.getCellProps()} key={cell.getCellProps().key}>
          {cell.render('Cell', {
            onUpdate,
            onDelete,
            onRefresh,
            key: cell.getCellProps().key,
            hasDeleteAccess,
            hasRefreshAccess,
            hasReplaceAccess,
          })}
        </div>
      ))}
    </div>
  );
};

const Table = (props: PropsWithChildren<TableProperties>) => {
  const {
    columns,
    onDelete,
    onUpdate,
    onRefresh,
    onCreate,
    hasAddPermission,
    hasDeletePermission,
    hasRefreshPermission,
    hasReplacePermission,
  } = props;

  const instance = useTable<UserRow>(
    {
      ...props,
      columns,
      autoResetGlobalFilter: true,
      autoResetSortBy: false,
    },
    ...hooks
  );

  const createNewUser = () =>
    onCreate({
      id: '',
      role: USER_ROLE,
      email: '',
      status: 'unknown',
      firstName: '',
      lastName: '',
      avatar: null,
    });
  const { getTableProps, getTableBodyProps, rows, prepareRow, headerGroups } = instance;

  return (
    <>
      {createPortal(
        <div className="users-table__header-actions">
          <GlobalFilter instance={instance} />
          {hasAddPermission && <PlusButton dataTest="user-edit__add-new" onClick={createNewUser} />}
        </div>,
        document.getElementById('settings-actions-portal-placeholder') ?? document.body
      )}
      <div {...getTableProps()} className="settings__table-content">
        <div>
          {headerGroups.map((headerGroup) => (
            <div
              {...headerGroup.getHeaderGroupProps()}
              key={headerGroup.getHeaderGroupProps().key}
              className="users-table__row users-table__row--header"
            >
              {headerGroup.headers.map((column) => (
                <div {...column.getHeaderProps()} key={column.getHeaderProps().key}>
                  {column.render('Header', { key: column.getHeaderProps().key })}
                </div>
              ))}
            </div>
          ))}
        </div>
        <div {...getTableBodyProps()} className="settings__table-body">
          {rows.map((row: Row<UserRow>) => {
            prepareRow(row);
            return (
              <TableRow
                key={row.getRowProps().key}
                onRefresh={onRefresh}
                onDelete={onDelete}
                onUpdate={onUpdate}
                row={row}
                hasDeleteAccess={hasDeletePermission}
                hasRefreshAccess={hasRefreshPermission}
                hasReplaceAccess={hasReplacePermission}
              />
            );
          })}
        </div>
      </div>
      {hasAddPermission && (
        <DivButton
          icon={<PlusSmallIcon />}
          onClick={createNewUser}
          text="Add new"
          dataTest="users-table__add-new"
          className="users-table__add-new-btn"
        />
      )}
    </>
  );
};

export const UsersTable = (props: {
  data: UserRow[];
  onUpdate: (user: User) => void;
  onDelete: (user: User) => void;
  onCreate: (user: User) => void;
  onRefresh: (userId: string) => Promise<unknown>;
  hasAddPermission: boolean;
  hasDeletePermission: boolean;
  hasRefreshPermission: boolean;
  hasReplacePermission: boolean;
}) => {
  return (
    <Table
      key="users-table"
      name="users-table"
      columns={tableColumns}
      data={props.data}
      onUpdate={props.onUpdate}
      onDelete={props.onDelete}
      onCreate={props.onCreate}
      onRefresh={props.onRefresh}
      hasAddPermission={props.hasAddPermission}
      hasDeletePermission={props.hasDeletePermission}
      hasRefreshPermission={props.hasRefreshPermission}
      hasReplacePermission={props.hasReplacePermission}
    />
  );
};
