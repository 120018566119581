import { PromptMark } from '../../types';
import React from 'react';
import { StarSolidIcon, StarIcon } from '../../../assets/icons';

export const PromptMarkButton = (props: {
  promptMark: PromptMark | null;
  onClick: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => Promise<void>;
  className?: string | undefined;
  iconClassName?: string | undefined;
}) => (
  <div className={props.className} onClick={props.onClick}>
    {props.promptMark?.mark ? (
      <StarSolidIcon className={props.iconClassName} data-test="prompt-mark--marked" />
    ) : (
      <StarIcon className={props.iconClassName} data-test="prompt-mark--unmarked" />
    )}
  </div>
);
