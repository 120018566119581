import React, { useMemo, useState } from 'react';
import { FieldArrayRenderProps, FormikErrors, FormikTouched } from 'formik';
import {
  CustomFieldType,
  CustomFieldTypeOptions,
  ProgramCustomField,
  ProgramEditFormikModel,
  SimpleCustomField,
} from '../types';
import { FormikTextInput } from '../../commons/components/FormikTextInput';
import { FormikNumberInput } from '../../commons/components/FormikNumberInput';
import { FormikDateTimeInput } from '../../commons/components/FormikDateTimeInput';
import { FormulaInput } from './FormulaInput';
import { DebounceMsConfig, isCustomFieldFormulaEnabled } from '../../config';
import { Form } from 'react-bootstrap';
import { ColoredSelect } from './ColoredSelect';
import { BinIcon } from '../../assets/icons';
import { encodeToMathJsId } from '../formulaUtil';
import _ from 'lodash';
import { ModalDropdownMenuZIndex } from '../util';
import clsx from 'clsx';
import { MoreMenu } from '../../commons/components/MoreMenu';

export type CustomFieldProps = {
  formikValues: ProgramEditFormikModel;
  initialCustomField: ProgramCustomField;
  index: number;
  disabled: boolean;
  readOnly: boolean;
  errors: FormikErrors<ProgramEditFormikModel>;
  touched: FormikTouched<ProgramEditFormikModel>;
  arrayHelpers: FieldArrayRenderProps;
  onDelete: ({
    arrayHelpers,
    index,
  }: {
    arrayHelpers: FieldArrayRenderProps;
    index: number;
  }) => void;
  handleBlur: (event: React.FocusEvent<HTMLInputElement>) => void;
  setFieldValue: (field: string, value: unknown, resetTouched?: boolean) => void;
};

const CustomField = ({
  formikValues,
  initialCustomField,
  index,
  arrayHelpers,
  disabled,
  readOnly,
  errors,
  touched,
  handleBlur,
  setFieldValue,
  onDelete,
}: CustomFieldProps) => {
  const [currentName, setCurrentName] = useState(initialCustomField.name);
  const [currentValue, setCurrentValue] = useState<SimpleCustomField>(initialCustomField);
  const hasError = (key: keyof ProgramCustomField): boolean => {
    const fieldErrors = errors.customFields?.[index];
    return !!(fieldErrors && typeof fieldErrors !== 'string' && fieldErrors[key]);
  };
  const wasTouched = (key: keyof ProgramCustomField): boolean => {
    const touchedFields = touched.customFields?.[index];
    return !!(touchedFields && touchedFields[key]);
  };

  const showNameError = hasError('name') && wasTouched('name');
  const wasValueTouched = wasTouched('value');
  const showValueError = hasError('value') && wasValueTouched;

  const Actions = ({
    index,
    arrayHelpers,
  }: {
    index: number;
    arrayHelpers: FieldArrayRenderProps;
  }) => (
    <MoreMenu
      menuItems={[
        {
          type: 'standard',
          text: 'Delete',
          icon: <BinIcon />,
          dataTest: `program-edit__customFields-delete-${index}`,
          onClick: () => onDelete({ index, arrayHelpers }),
        },
      ]}
      menuButtonDataTest={`program-edit__customFields-actions-${index}`}
    />
  );

  return (
    <div data-test={`program-edit__customFields-row-${index}`}>
      <Form.Group className="growegy-form__row">
        <FormikTextInput
          initialFieldValue={currentName}
          formikFieldName={`customFields.${index}.name`}
          disabled={disabled}
          readOnly={readOnly}
          placeholder="Name"
          isError={
            showNameError ||
            (!!formikValues.customFieldsNameSums[currentName.trim().toLowerCase()] &&
              formikValues.customFieldsNameSums[currentName.trim().toLowerCase()] > 1)
          }
          dataTest={`program-edit__customFields-name-${index}`}
          className="program-edit__custom-fields-name"
          errorClassName="invalid-feedback"
          setFieldValue={(v) => {
            setFieldValue(`customFields.${index}.name`, v);
            if (v !== undefined) {
              setCurrentName(v);
            }
          }}
          debounceMs={DebounceMsConfig.programEditInputDebounceMs}
          autoFocus={initialCustomField.isNew}
        />
        <div className="program-edit__custom-fields-value-container" style={{ minWidth: '150px' }}>
          {(() => {
            switch (currentValue.type) {
              case 'text':
                return (
                  <FormikTextInput
                    initialFieldValue={currentValue.value}
                    formikFieldName={`customFields.${index}.value`}
                    disabled={disabled}
                    readOnly={readOnly}
                    isError={showValueError}
                    dataTest={`program-edit__customFields-value-${index}`}
                    className={clsx('growegy-form__input program-edit__input-redesigned--sized', {
                      'growegy-form__input--readonly': readOnly || disabled,
                    })}
                    errorClassName="invalid-feedback"
                    setFieldValue={(v) => {
                      setFieldValue(`customFields.${index}.value`, v);
                      if (v !== undefined) {
                        setCurrentValue({ type: 'text', value: v });
                      }
                    }}
                    debounceMs={DebounceMsConfig.programEditInputDebounceMs}
                    urlHighlightOn={true}
                    placeholder="Value"
                  />
                );
              case 'number':
                return (
                  <FormikNumberInput
                    initialFieldValue={currentValue.value}
                    formikFieldName={`customFields.${index}.value`}
                    disabled={disabled}
                    readOnly={readOnly}
                    isError={showValueError}
                    dataTest={`program-edit__customFields-value-${index}`}
                    handleBlur={handleBlur}
                    setFieldValue={(v) => {
                      setFieldValue(`customFields.${index}.value`, v);
                      if (typeof v === 'number') {
                        setCurrentValue({ type: 'number', value: v });
                      }
                    }}
                    errorClassName="invalid-feedback"
                    className="growegy-form__input program-edit__input-redesigned--sized"
                    debounceMs={DebounceMsConfig.programEditInputDebounceMs}
                  />
                );
              case 'date-time':
                return (
                  <FormikDateTimeInput
                    initialFieldValue={currentValue.value}
                    formikFieldName={`customFields.${index}.value`}
                    disabled={disabled}
                    readOnly={readOnly}
                    isError={showValueError}
                    dataTest={`program-edit__customFields-value-${index}`}
                    handleBlur={handleBlur}
                    setFieldValue={(fieldName, v) => {
                      setFieldValue(fieldName, v);
                      setCurrentValue({ type: 'date-time', value: v });
                    }}
                    className="growegy-form__input program-edit__input-redesigned--sized"
                    errorClassName="invalid-feedback"
                  />
                );
              case 'formula':
                const mathJsId = encodeToMathJsId(currentName, initialCustomField.localId);
                const showFormulaCalculationError = !!(
                  wasValueTouched &&
                  errors.formulaScope &&
                  errors.formulaScope[mathJsId]
                );
                const formulaValueField = `customFields.${index}.value`;
                const formulaPrefixField = `customFields.${index}.prefix`;
                const formulaSuffixField = `customFields.${index}.suffix`;

                return (
                  <FormulaInput
                    formikValues={formikValues}
                    initialValue={{
                      formula: currentValue.value,
                      prefix: currentValue.prefix,
                      suffix: currentValue.suffix,
                    }}
                    setFieldValue={(v) => {
                      setFieldValue(formulaValueField, v.formula);
                      setFieldValue(formulaPrefixField, v.prefix);
                      setFieldValue(formulaSuffixField, v.suffix);
                      setCurrentValue({
                        type: 'formula',
                        value: v.formula,
                        result: 0,
                        prefix: v.prefix,
                        suffix: v.suffix,
                      });
                    }}
                    mathJsId={mathJsId}
                    disabled={disabled}
                    readOnly={readOnly}
                    dataTest={`program-edit__customFields-value-${index}`}
                    isError={showValueError || showFormulaCalculationError}
                  />
                );
              default:
                return <></>;
            }
          })()}
          <ColoredSelect
            initialValue={
              CustomFieldTypeOptions.find((o) => (o.id as CustomFieldType) === currentValue.type)!
            }
            dataTestPrefix={`program-edit__customFields-type-${index}`}
            coloredItems={useMemo(
              () =>
                [...CustomFieldTypeOptions].filter(({ id }) =>
                  isCustomFieldFormulaEnabled ? true : id !== 'formula'
                ),
              []
            )}
            disabled={disabled || readOnly}
            onValueChange={({ id }) => {
              let simpleCustomField: SimpleCustomField;
              if (currentValue.type === id) return;
              switch (id) {
                case 'text':
                  simpleCustomField = { type: id, value: '' };
                  break;
                case 'number':
                  simpleCustomField = { type: id, value: 0 };
                  break;
                case 'date-time':
                  simpleCustomField = { type: id, value: new Date() };
                  break;
                case 'formula':
                  simpleCustomField = {
                    type: id,
                    value: '',
                    result: 0,
                    prefix: null,
                    suffix: null,
                  };
                  break;
                default:
                  simpleCustomField = { type: 'text', value: '' };
                  break;
              }

              setFieldValue(`customFields.${index}.type`, id);
              setFieldValue(`customFields.${index}.value`, simpleCustomField.value, true);
              setCurrentValue(simpleCustomField);
            }}
            menuZIndex={ModalDropdownMenuZIndex}
          />
          {!disabled && !readOnly && <Actions index={index} arrayHelpers={arrayHelpers} />}
        </div>
      </Form.Group>
    </div>
  );
};

export const CustomFieldMemoised = React.memo(CustomField, (prev, next) => _.isEqual(prev, next));
