import { AxiosInstance, AxiosResponse } from 'axios';
import {
  DataSeriesName,
  EnterpriseAnalyticLayoutRow,
  EnterpriseAnalyticLayoutRows,
  EnterpriseChartData,
  EnterpriseCharts,
  EnterpriseChartsAmount,
  GroupByFieldName,
  GroupingDataItems,
  LineDataSeries,
} from './types';
import moment from 'moment';
import { FilteringFieldType, MultiFilterConjunction } from '../multifilter/types';
import { CustomFieldType } from '../program/types';

export const ProgramFilterDtoStaticFields = [
  'name',
  'budget',
  'leads',
  'actualLeads',
  'startDateTime',
  'endDateTime',
  'wholeDay',
  'notes',
  'vendor',
  'salesforceCampaignId',
  'owner',
  'status',
  'type',
  'campaign',
] as const;

export type ProgramFilteringFieldIdentity =
  | {
      type: 'StaticField';
      fieldName: string;
    }
  | {
      type: 'ArrayFieldItem';
      arrayField: 'customFields';
      arrayItemValue: { name: string; type: CustomFieldType };
    };

export type ProgramMultiFilterDto = {
  selectedFieldIdentity: ProgramFilteringFieldIdentity;
  selectedFieldType: FilteringFieldType;
  selectedFilterName: string;
  filterValue: unknown;
};

export type ProgramMultiFiltersDto = {
  conjunction: MultiFilterConjunction;
  filters: ProgramMultiFilterDto[];
};

type LineDataSeriesQueryDto = {
  from: string;
  to: string;
  lineNames: DataSeriesName[];
  multifilter: ProgramMultiFiltersDto | null;
};

export type LineDataSeriesDto = {
  linesByName: {
    projectedLeads: number[];
    actualLeads: number[];
  };
  timestamps: string[];
};

export type GroupingDataQueryDto = {
  from: string;
  to: string;
  groupBy: GroupByFieldName[];
  groupOf: DataSeriesName[];
  multifilter: ProgramMultiFiltersDto | null;
};

export type GroupingDataItemDto = {
  key: { type: string | null; month: string | null };
  value: {
    projectedLeads: number;
    actualLeads: number;
  };
};

export type GroupingDataDto = {
  data: GroupingDataItemDto[];
};

export type EnterpriseChartsAmountDto = {
  amount: number;
};

export type EnterpriseChartDto = {
  orderingId: string;
  title: string;
  html: string;
  width: number | null;
  height: number | null;
  rowId: number;
  columnIdx: number;
  filterName: string | null;
};

export type EnterpriseChartsDto = {
  charts: EnterpriseChartDto[];
};

export type EnterpriseAnalyticLayoutRowDto = {
  tab: string;
  title: string;
  id: number;
  idxWithinTab: number;
  showBorder: boolean;
  showChartTitles: boolean;
};

export type EnterpriseAnalyticLayoutRowsDto = {
  rows: EnterpriseAnalyticLayoutRowDto[];
};

export type AnalyticsApi = ReturnType<typeof getAnalyticsApi>;

export const getAnalyticsApi = (axiosInstance: AxiosInstance) => {
  const toLineDataSeries = (dto: LineDataSeriesDto): LineDataSeries => {
    const points = dto.timestamps.map((timestamp, i) => ({
      projectedLeads: dto.linesByName.projectedLeads[i],
      actualLeads: dto.linesByName.actualLeads[i],
      timestamp: new Date(timestamp),
    }));
    return {
      points,
    };
  };

  const toGroupingDataItems = (dto: GroupingDataDto) =>
    dto.data.map((x) => ({
      key: {
        typeId: x.key.type,
        month: x.key.month ? new Date(x.key.month) : null,
      },
      value: {
        ...x.value,
      },
    }));

  const getDataSeries = async (
    fromDate: number,
    toDate: number,
    lineNames: DataSeriesName[],
    filter: ProgramMultiFiltersDto | null
  ): Promise<LineDataSeries> => {
    const queryDto: LineDataSeriesQueryDto = {
      from: `${moment.utc(fromDate).toISOString().split('.')[0]}Z`,
      to: `${moment.utc(toDate).startOf('day').add(1, 'day').toISOString().split('.')[0]}Z`,
      lineNames,
      multifilter: filter,
    };
    return axiosInstance
      .post<LineDataSeriesQueryDto, AxiosResponse<LineDataSeriesDto>>(
        `/analytics/data-series`,
        queryDto
      )
      .then((r) => toLineDataSeries(r.data));
  };

  const getGrouping = async (
    fromDate: number,
    toDate: number,
    groupBy: GroupByFieldName[],
    groupOf: DataSeriesName[],
    filter: ProgramMultiFiltersDto | null
  ): Promise<GroupingDataItems> => {
    const queryDto: GroupingDataQueryDto = {
      from: `${moment.utc(fromDate).toISOString().split('.')[0]}Z`,
      to: `${moment.utc(toDate).startOf('day').add(1, 'day').toISOString().split('.')[0]}Z`,
      groupBy,
      groupOf,
      multifilter: filter,
    };
    return axiosInstance
      .post<GroupingDataQueryDto, AxiosResponse<GroupingDataDto>>(`/analytics/grouping`, queryDto)
      .then((r) => toGroupingDataItems(r.data));
  };

  // explanation is here: https://stackoverflow.com/a/30106551
  const b64DecodeUnicode = (str: string) =>
    decodeURIComponent(
      atob(str)
        .split('')
        .map(function (c) {
          return `%${`00${c.charCodeAt(0).toString(16)}`.slice(-2)}`;
        })
        .join('')
    );

  const toEnterpriseChart = (dto: EnterpriseChartDto): EnterpriseChartData => {
    const { orderingId, html, title, width, height, filterName, rowId, columnIdx } = dto;
    const htmlContent = b64DecodeUnicode(html);
    return {
      orderingId,
      html: htmlContent,
      title,
      width,
      height,
      filterName: filterName ?? '',
      rowId,
      columnIdx,
    };
  };

  const toEnterpriseLayoutRow = (
    dto: EnterpriseAnalyticLayoutRowDto
  ): EnterpriseAnalyticLayoutRow => {
    const { tab, title, id, idxWithinTab, showChartTitles, showBorder } = dto;
    return { tab, title, id, idxWithinTab, showChartTitles, showBorder };
  };

  const toEnterpriseCharts = (charts: EnterpriseChartsDto): EnterpriseCharts => {
    const { charts: dtos } = charts;
    return { charts: dtos.filter((c) => c.html).map(toEnterpriseChart) };
  };

  const toEnterpriseLayoutRows = (
    rows: EnterpriseAnalyticLayoutRowsDto
  ): EnterpriseAnalyticLayoutRows => {
    const { rows: dtos } = rows;
    return { layoutRows: dtos.map(toEnterpriseLayoutRow) };
  };

  const getEnterpriseChartsAmount = async () => {
    const { data } = await axiosInstance.get<EnterpriseChartsAmountDto>(
      `/analytics/enterprise-charts/amount`
    );
    const amount: EnterpriseChartsAmount = { amount: data.amount };
    return amount;
  };

  const getEnterpriseCharts = async () => {
    const { data } = await axiosInstance.get<EnterpriseChartsDto>(`/analytics/enterprise-charts`);
    return toEnterpriseCharts(data);
  };

  const getEnterpriseAnalyticLayout = async () => {
    const { data } = await axiosInstance.get<EnterpriseAnalyticLayoutRowsDto>(
      `/analytics/enterprise-charts/layout`
    );
    return toEnterpriseLayoutRows(data);
  };

  return {
    getDataSeries,
    getGrouping,
    getEnterpriseChartsAmount,
    getEnterpriseCharts,
    getEnterpriseAnalyticLayout,
  };
};
