import { useFetchedNotificationSettings } from '../hooks/useFetchedNotificationSettings';
import React, { useEffect, useState } from 'react';
import { useMutation } from '@tanstack/react-query';
import { useQueryCacheManager } from '../../hooks/useQueryCacheManager';
import useApi from '../../api/backendApiContext';
import { NotificationSettingsDto } from '../../user/userApi';
import useAnalyticsPage from '../../web-analytics/hooks/useAnalyticsPage';
import { Page } from '../../web-analytics/Page';
import useAnalytics from '../../web-analytics/webAnalyticsContext';
import { analyticsTrack } from '../../web-analytics/webAnalytics';
import { AnalyticsEvent } from '../../web-analytics/AnalyticsEvent';
import { useNotification } from '../notificationsContext';
import { extractBackendError } from '../../util/utils';
import useAuth from '../../auth/authContext';
import { PleaseRelogin } from '../../auth/PleaseRelogin';

export const NotificationSettings = () => {
  useAnalyticsPage(Page.NOTIFICATION_SETTINGS);
  const analytics = useAnalytics();

  const {
    state: { user },
  } = useAuth();
  const userId = user?.userId ?? '';

  const { isLoading, isFetching, isSuccess, isError, data } =
    useFetchedNotificationSettings(userId);
  const cacheManager = useQueryCacheManager();
  const { userApi } = useApi();
  const [emailEnabled, setEmailEnabled] = useState(data?.emailEnabled ?? false);
  useEffect(() => {
    if (!data || isFetching) return;
    setEmailEnabled(data.emailEnabled);
  }, [data, isFetching]);

  const { notifySuccess, notifyError } = useNotification();

  const { mutateAsync, isLoading: isMutating } = useMutation(
    async (value: NotificationSettingsDto) => {
      await userApi.updateNotificationSettings(userId, value);
    },
    {
      onSettled: async () => cacheManager.initNotificationSettingsRefetch(userId),
    }
  );

  const onEmailEnabledChanged = async (enabled: boolean) => {
    try {
      if (!data) return;
      if (isMutating) {
        logger.warn('Mutation is already in progress.');
        return;
      }
      if (isFetching) {
        logger.warn("Fetching data, won't start new mutation.");
        return;
      }

      setEmailEnabled(enabled);
      await mutateAsync({ ...data, emailEnabled: enabled });
      notifySuccess({
        notificationMsg: 'Notification settings updated',
        logMsg: 'Notification settings updated',
      });
      analyticsTrack(
        analytics,
        enabled
          ? AnalyticsEvent.EMAIL_NOTIFICATIONS_ENABLED
          : AnalyticsEvent.EMAIL_NOTIFICATIONS_DISABLED
      );
    } catch (e) {
      notifyError({
        notificationMsg: 'Failed to update notification settings',
        logMsg: `Failed to update notification settings: ${extractBackendError(e)}`,
      });
    }
  };

  if (!user) return <PleaseRelogin />;
  return (
    <div>
      <div className="notification-settings__container">
        {isLoading && (
          <div className="alert alert-secondary" role="alert">
            Loading notification settings...
          </div>
        )}
        {isError && (
          <div
            className="alert alert-secondary"
            role="alert"
            data-test="user-settings__loading-error"
          >
            Error loading notification settings
          </div>
        )}
        {isSuccess && data && (
          <>
            <div style={{ width: '448px' }}>
              <div className="switch-input-container w-100">
                <input
                  type="checkbox"
                  className="custom-control-input"
                  id="notification-settings__email-enabled"
                  onChange={async (e) => onEmailEnabledChanged(e.target.checked)}
                  checked={emailEnabled}
                />
                <label
                  className="custom-control-label calendar-display-settings__check-label"
                  htmlFor="notification-settings__email-enabled"
                >
                  By email
                </label>
              </div>
            </div>
            <div className="growegy-color-neutral-500">
              <span>You’ll receive emails when:</span>
              <ul className="text-bullet-list">
                <li>·&nbsp;&nbsp;A program owned by you starts tomorrow</li>
                <li>·&nbsp;&nbsp;A task assigned to you is due tomorrow</li>
                <li>·&nbsp;&nbsp;A task within a program owned by you is due tomorrow</li>
              </ul>
            </div>
          </>
        )}
      </div>
    </div>
  );
};
