import _ from 'lodash';
import * as math from 'mathjs';
import {
  DecimalFormatter,
  FormulaScope,
  FormulaScopeWithFieldMentions,
  ProgramCustomField,
  ProgramSummary,
} from './types';

export const MathJsIdentity = 'mathJsId';

export const formatFormulaValue = (
  v: number | undefined,
  prefix: string | null,
  suffix: string | null
) => {
  if (v === undefined) return 'Error';
  if (Number.isNaN(v)) return 'Error';
  if (!Number.isFinite(v)) return 'Infinity';
  return `${prefix ?? ''}${DecimalFormatter.format(v)}${suffix ?? ''}`;
};

const tempFieldPrefix = '_tmp-';

const adjustName = (name: string, fallback: string | undefined) =>
  name !== '' ? name : !!fallback ? `${tempFieldPrefix}${fallback}` : '';

export const encodeToMathJsId = (name: string, fallback?: string): string =>
  `_${Array.from(adjustName(name, fallback))
    .map((c) => c.charCodeAt(0))
    .join('_')}`;

export const encodeToReactMention = (view: string, mathJs: string): string =>
  `@[${view}](${MathJsIdentity}:${mathJs})`;

export const decodeFromMathJsId = (encoded: string): string => {
  const decoded = encoded
    .split('_')
    .slice(1)
    .map((u) => (u ? String.fromCodePoint(parseInt(u, 10)) : ''))
    .join('');
  return decoded.startsWith(tempFieldPrefix) ? decoded.substring(tempFieldPrefix.length) : decoded;
};

type ExtractedFieldForScope = {
  humanView: string;
  mathJs: string;
  value: string | number;
  isFormula: boolean;
  isMentionable: boolean;
};

export type ScopeableCustomField =
  | { type: 'number'; name: string; value: number; localId: string }
  | { type: 'formula'; name: string; value: string; localId: string };

const extractScopeableCustomFields = (fields: ProgramCustomField[]): ScopeableCustomField[] =>
  fields.flatMap((f) => {
    switch (f.type) {
      case 'number':
        return [{ type: 'number', name: f.name, value: f.value, localId: f.localId }];
      case 'formula':
        return [{ type: 'formula', name: f.name, value: f.value, localId: f.localId }];
      default:
        return [] as ScopeableCustomField[];
    }
  });

const extractScopeableNormalFields = (program: {
  budget: number;
  leads: number;
  actualLeads: number | null;
}): ExtractedFieldForScope[] => [
  {
    humanView: 'Budget',
    mathJs: encodeToMathJsId('Budget'),
    value: program.budget,
    isFormula: false,
    isMentionable: true,
  },
  {
    humanView: 'Projected Leads',
    mathJs: encodeToMathJsId('Projected Leads'),
    value: program.leads,
    isFormula: false,
    isMentionable: true,
  },
  {
    humanView: 'Actual Leads',
    mathJs: encodeToMathJsId('Actual Leads'),
    value: program.actualLeads || 0,
    isFormula: false,
    isMentionable: true,
  },
];

const extractFieldsForMathjsScope = (
  program: Omit<ProgramSummary, 'typeId' | 'tasks' | 'campaignId' | 'status'>
): ExtractedFieldForScope[] =>
  prepareFieldsForScopeCalculation(program, extractScopeableCustomFields(program.customFields));

const createScope = (fields: ExtractedFieldForScope[]): FormulaScopeWithFieldMentions => {
  const encodedReactMentions = _(fields)
    .map(({ humanView, mathJs }) => ({
      mathJs,
      reactMentionView: encodeToReactMention(humanView, mathJs),
    }))
    .sortBy((f) => f.reactMentionView.length)
    .reverse();

  const encodedHumanViews = _(fields)
    .map(({ humanView, mathJs }) => ({ humanView, mathJs }))
    .sortBy((f) => f.humanView.length)
    .reverse();

  const convertFormulaToMathJsExpression = (formula: string): string => {
    const decodedReactMentions = encodedReactMentions.reduce<string>(
      (prev, curr) => prev.replaceAll(curr.reactMentionView, curr.mathJs),
      formula.toString()
    );
    return encodedHumanViews.reduce<string>(
      (prev, curr) => prev.replaceAll(curr.humanView, curr.mathJs),
      decodedReactMentions.toString()
    );
  };

  const scope = fields.reduce<FormulaScope>((prevScope, field) => {
    const { mathJs, isFormula, value } = field;
    const expression = isFormula ? convertFormulaToMathJsExpression(value.toString()) : value;
    const nextScope = { ...prevScope };
    try {
      math.evaluate(`${mathJs} = ${expression}`, nextScope);
    } catch {
      nextScope[mathJs] = NaN;
    }
    return nextScope;
  }, {});

  return {
    scope,
    mentionsData: fields
      .filter((x) => x.isMentionable)
      .map(({ mathJs, humanView }) => ({ id: mathJs, display: humanView })),
  };
};

export const prepareFieldsForScopeCalculation = (
  program: { budget: number; leads: number; actualLeads: number | null },
  customFields: ScopeableCustomField[]
): ExtractedFieldForScope[] => [
  ...extractScopeableNormalFields(program),
  ...customFields.map(({ type, name, localId, value }) => ({
    humanView: adjustName(name, localId),
    mathJs: encodeToMathJsId(name, localId),
    value,
    isFormula: type === 'formula',
    isMentionable: !!name,
  })),
];

export const programToMathJsScope = (
  program: Omit<ProgramSummary, 'typeId' | 'tasks' | 'campaignId' | 'status'>
): FormulaScopeWithFieldMentions =>
  buildMathJsScope(program, extractScopeableCustomFields(program.customFields));

export const buildMathJsScope = (
  program: { budget: number; leads: number; actualLeads: number | null },
  customFields: ScopeableCustomField[]
): FormulaScopeWithFieldMentions => {
  const fields = prepareFieldsForScopeCalculation(program, customFields);
  return createScope(fields);
};

export const prepareFormulasToHuman = (
  program: Omit<ProgramSummary, 'typeId' | 'tasks' | 'campaignId' | 'status'>
): ProgramCustomField[] => {
  const scopedValues = extractFieldsForMathjsScope(program);
  const { scope } = createScope(scopedValues);
  const encodedReactMentions = _(scopedValues)
    .map(({ humanView, mathJs }) => ({
      humanView,
      reactMentionView: encodeToReactMention(humanView, mathJs),
    }))
    .sortBy((f) => f.reactMentionView.length)
    .reverse();

  const decodeMathJsViewToHuman = (formula: string): string =>
    encodedReactMentions.reduce<string>(
      (prev, curr) => prev.replaceAll(curr.reactMentionView, curr.humanView),
      formula.toString()
    );

  return program.customFields.map((field) => {
    switch (field.type) {
      case 'formula': {
        const scopeName = encodeToMathJsId(field.name, field.localId);
        const result = scope[scopeName] === undefined ? Number.NaN : scope[scopeName];
        return { ...field, value: decodeMathJsViewToHuman(`${field.value}`), result };
      }
      default:
        return field;
    }
  });
};

export const convertFormulasFromHumanToReactMentions = (
  program: Omit<ProgramSummary, 'typeId' | 'tasks' | 'campaignId' | 'status'>
): ProgramCustomField[] => {
  const encodedReactMentions = _(extractFieldsForMathjsScope(program))
    .map(({ humanView, mathJs }) => ({
      humanView,
      reactMentionView: encodeToReactMention(humanView, mathJs),
    }))
    .sortBy((f) => f.humanView.length)
    .reverse();

  const decodeHumanToMathJs = (formula: string): string =>
    encodedReactMentions.reduce<string>(
      (prev, curr) => prev.replaceAll(curr.humanView, curr.reactMentionView),
      formula.toString()
    );

  return program.customFields.map((field) =>
    field.type === 'formula' ? { ...field, value: decodeHumanToMathJs(`${field.value}`) } : field
  );
};
