import { useQuery } from '@tanstack/react-query';
import { useQueryCacheManager } from '../../hooks/useQueryCacheManager';
import useApi from '../../api/backendApiContext';
import { ReactQueryConstants } from '../../commons/constants';
import { QueryStatus } from '../../commons/components/QueryStatus';

export const useFetchedGoogleOauth = (userId: string) => {
  const { googleCalendarApi } = useApi();
  const cacheManager = useQueryCacheManager();
  const queryKey = cacheManager.getGoogleOauthCacheName(userId);
  const query = useQuery(queryKey, async () => googleCalendarApi.getGoogleOauth(userId), {
    refetchOnWindowFocus: false,
    staleTime: ReactQueryConstants.personalIntegrationSettingsStaleTime,
  });

  const queryStatusComponent = (
    <QueryStatus
      queryKey={queryKey}
      queryStatus={query.status}
      dataTestPrefix="chats"
      failedToLoadText="Something went wrong while loading Google Calendar integration settings."
    />
  );

  return {
    query,
    queryStatusComponent,
  };
};
