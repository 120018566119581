import { Button } from 'react-bootstrap';
import { BinIcon, CloseIcon, DividerIcon, DownloadIcon } from '../../../assets/icons';
import React, { useCallback } from 'react';
import prettyBytes from 'pretty-bytes';
import { ProgramAttachment } from '../../types';
import { useAttachmentContent } from '../../hooks/useAttachmentsContent';
import useAnalytics from '../../../web-analytics/webAnalyticsContext';
import { analyticsTrack } from '../../../web-analytics/webAnalytics';
import { AnalyticsEvent } from '../../../web-analytics/AnalyticsEvent';
import { downloadFile } from '../../../util/file-utils';

export const AttachmentCarouselHeader = (props: {
  programId: string;
  attachment: ProgramAttachment;
  onDelete: (attachment: ProgramAttachment) => void;
  onClose: () => void;
}) => {
  const { programId, attachment, onDelete, onClose } = props;
  const analytics = useAnalytics();

  const { data: attachmentWithContent } = useAttachmentContent(programId, attachment);
  const fileSizeString =
    attachmentWithContent && attachmentWithContent.file
      ? `(${prettyBytes(attachmentWithContent.file.size)})`
      : '';

  const download = useCallback(() => {
    if (!attachmentWithContent?.url || !attachmentWithContent?.name) return;
    analyticsTrack(analytics, AnalyticsEvent.PROGRAM_ATTACHMENT_DOWNLOADED, {
      button: 'header',
    });
    downloadFile(attachmentWithContent.url, attachmentWithContent.name);
  }, [attachmentWithContent?.url, attachmentWithContent?.name, analytics]);
  return (
    <div className="attachments-view__header-container">
      <div className="attachments-view__file-details">
        <div className="attachments-view__file-name" data-test="attachments-view__file-name">
          {attachment.name}
        </div>
        {!!fileSizeString && (
          <div className="attachments-view__file-size" data-test="attachments-view__file-size">
            &nbsp;{fileSizeString}
          </div>
        )}
      </div>
      {attachment.name && (
        <Button
          className="attachments-view__header-button"
          data-test="attachments-view__download-button"
          onClick={download}
          disabled={!attachmentWithContent?.url}
        >
          <DownloadIcon className="attachments-view__icon" />
        </Button>
      )}
      <Button
        className="attachments-view__header-button"
        data-test="attachments-view__delete-button"
        onClick={() => onDelete(attachment)}
      >
        <BinIcon className="attachments-view__icon" />
      </Button>
      <DividerIcon className="attachments-view__header-divider" />)
      <Button
        className="attachments-view__header-button"
        data-test="attachments-view__close-button"
        onClick={onClose}
      >
        <CloseIcon className="attachments-view__icon" />
      </Button>
    </div>
  );
};
