import {
  AiStarsIcon,
  ArrowRightUpIcon,
  BookIcon,
  BulbIcon,
  ChatBalloonIcon,
  EnvelopeIcon,
  GridIcon,
  HouseIcon,
  LinkIcon,
  PencilIcon,
  SearchIcon,
  ShoppingBagIcon,
  ShoppingCartIcon,
} from '../assets/icons';
import { PieChart } from 'recharts';

export const getIconByName = (name: string | null) => {
  switch (name) {
    case 'ai-stars':
      return AiStarsIcon;
    case 'arrow-up-right':
      return ArrowRightUpIcon;
    case 'book':
      return BookIcon;
    case 'bulb':
      return BulbIcon;
    case 'envelope':
      return EnvelopeIcon;
    case 'shopping-cart':
      return ShoppingCartIcon;
    case 'grid':
      return GridIcon;
    case 'link':
      return LinkIcon;
    case 'chat-balloon':
      return ChatBalloonIcon;
    case 'pencil':
      return PencilIcon;
    case 'pie-chart':
      return PieChart;
    case 'house':
      return HouseIcon;
    case 'search':
      return SearchIcon;
    case 'shopping-bag':
      return ShoppingBagIcon;
  }
  return null;
};
