import { useCallback, useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import useApi from '../../api/backendApiContext';
import { errorToString } from '../../util/utils';
import { ProgramTypesCacheKey } from '../../hooks/useQueryCacheManager';
import { ProgramEditModalState, ProgramTypeDialog } from './ProgramTypeDialog';
import { ProgramType } from '../../program/types';
import { ProgramTypesList } from '../components/ProgramTypesList';
import useAnalyticsPage from '../../web-analytics/hooks/useAnalyticsPage';
import { Page } from '../../web-analytics/Page';
import { ReactQueryConstants } from '../../commons/constants';

export const ProgramTypesManagement = () => {
  useAnalyticsPage(Page.PROGRAM_TYPES);

  const { programApi } = useApi();

  const { data, error, status } = useQuery(
    ProgramTypesCacheKey,
    async () => programApi.getProgramTypes(),
    {
      staleTime: ReactQueryConstants.programTypesStaleTime,
    }
  );

  const [modalState, setModalState] = useState<ProgramEditModalState | null>(null);

  const onUpdate = useCallback(
    (user: ProgramType) => {
      if (data) setModalState({ action: 'UPDATE', programType: { ...user }, programTypes: data! });
    },
    [data]
  );

  const onCreate = useCallback(
    (user: ProgramType) => {
      if (data) setModalState({ action: 'CREATE', programType: { ...user }, programTypes: data! });
    },
    [data]
  );

  if (status === 'loading') {
    return (
      <div className="alert alert-secondary" role="alert">
        Loading...
      </div>
    );
  }

  if (status === 'error') {
    return (
      <div className="alert alert-warning" role="alert" data-test="user-list__error">
        Could not load users: {errorToString(error)}
      </div>
    );
  }

  if (status === 'success' && data) {
    return (
      <div className="program-types__container">
        <ProgramTypesList onUpdate={onUpdate} onCreate={onCreate} data={data.types} />
        {modalState && (
          <ProgramTypeDialog
            action={modalState.action}
            programType={modalState.programType}
            programTypes={modalState.programTypes}
            onHide={() => setModalState(null)}
          />
        )}
      </div>
    );
  }

  return (
    <div>
      <h1>status {status}</h1>
      <p>
        <>data {data}</>
      </p>
    </div>
  );
};
