import useApi from '../../api/backendApiContext';
import { useQuery } from '@tanstack/react-query';
import { useQueryCacheManager } from '../../hooks/useQueryCacheManager';
import { DATA_SERIES_ACTUAL_LEADS, DATA_SERIES_PROJECTED_LEADS } from '../types';
import { ReactQueryConstants } from '../../commons/constants';
import { ProgramMultiFiltersDto } from '../analyticsApi';

export const useFetchedDataSeries = (
  fromDate: number,
  toDate: number,
  filter: ProgramMultiFiltersDto | null
) => {
  const { analyticsApi } = useApi();
  return useQuery(
    useQueryCacheManager().getDataSeriesCacheName(fromDate, toDate, filter),
    async () =>
      analyticsApi.getDataSeries(
        fromDate,
        toDate,
        [DATA_SERIES_PROJECTED_LEADS, DATA_SERIES_ACTUAL_LEADS],
        filter
      ),
    {
      staleTime: ReactQueryConstants.dataSeriesStaleTime,
      refetchOnWindowFocus: false,
    }
  );
};
