import { useQueries } from '@tanstack/react-query';
import { useQueryCacheManager } from '../../hooks/useQueryCacheManager';
import useApi from '../../api/backendApiContext';
import { ProgramAttachment } from '../types';
import { ProgramApi } from '../programApi';
import { getFileExtension } from '../../util/file-utils';

export type ProgramAttachmentWithUrl = ProgramAttachment & {
  url?: string;
};
const extensionToMimeType = (fileExtension: string): string => {
  switch (fileExtension) {
    case 'bmp':
      return 'image/bmp';
    case 'csv':
      return 'text/csv';
    case 'gif':
      return 'image/gif';
    case 'htm':
      return 'text/htm';
    case 'html':
      return 'text/html';
    case 'jpg':
    case 'jpeg':
      return 'image/jpeg';
    case 'pdf':
      return 'application/pdf';
    case 'png':
      return 'image/png';
    case 'tiff':
    case 'tif':
      return 'image/tiff';
    case 'txt':
      return 'text/plain';
    default:
      return 'application/octet-stream';
  }
};

const getOrDownloadFile = async (
  programApi: ProgramApi,
  programId: string,
  attachment: ProgramAttachment
) => {
  const filename = attachment.name;
  const fileExtension = getFileExtension(filename);
  return attachment.file
    ? Promise.resolve(attachment.file)
    : programApi.downloadProgramAttachment(programId, attachment.id).then((res) => {
        return new File([res], filename, {
          type: extensionToMimeType(fileExtension),
        });
      });
};

export const createAttachmentContentQueryParams = (
  programApi: ProgramApi,
  queryKey: string[],
  programId: string,
  attachment: ProgramAttachment,
  isEnabled: boolean
) => {
  return {
    enabled: isEnabled,
    queryKey: queryKey,
    queryFn: async (): Promise<ProgramAttachmentWithUrl> => {
      const file = await getOrDownloadFile(programApi, programId, attachment);
      const url = window.URL.createObjectURL(file);
      return { ...attachment, file, url };
    },
    staleTime: Infinity,
    refetchOnWindowFocus: false,
    refetchInterval: 0,
  };
};

export const useAttachmentsContent = (
  programId: string,
  attachments: ProgramAttachment[],
  currentIndex: number
) => {
  const cacheManager = useQueryCacheManager();
  const { programApi } = useApi();

  return useQueries({
    queries: attachments.map((attachment, index) =>
      createAttachmentContentQueryParams(
        programApi,
        cacheManager.getProgramAttachmentContentCacheName(programId, attachment.id),
        programId,
        attachment,
        index === currentIndex
      )
    ),
  });
};

export const useAttachmentContent = (programId: string, attachment: ProgramAttachment) =>
  useAttachmentsContent(programId, [attachment], 0)[0];
