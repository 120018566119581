import React, { useEffect, useState } from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import { useMutation } from '@tanstack/react-query';

import useApi from '../../api/backendApiContext';
import { ResolvedIntegration } from '../types';
import { useQueryCacheManager } from '../../hooks/useQueryCacheManager';
import { useNotification } from '../../notification/notificationsContext';
import { useResolvedIntegration } from '../hooks/useResolvedIntegration';
import { IntegrationEdit } from '../components/IntegrationEdit';
import { IntegrationMetadataDto } from '../integrationApi';
import useAnalyticsPage from '../../web-analytics/hooks/useAnalyticsPage';
import { Page } from '../../web-analytics/Page';
import useAnalytics from '../../web-analytics/webAnalyticsContext';
import { analyticsTrack } from '../../web-analytics/webAnalytics';
import { AnalyticsEvent } from '../../web-analytics/AnalyticsEvent';

type IntegrationDialogProps = {
  integrationMetadata: IntegrationMetadataDto;
  onHide: () => void;
  onDelete: (integration: ResolvedIntegration) => void;
};

const stubResolvedIntegration = ({
  id,
  name,
  description,
  icon,
  parameters,
}: IntegrationMetadataDto): ResolvedIntegration => ({
  id,
  name,
  description,
  icon,
  attributes: parameters.map(({ name: attrName, type: attrType }) => ({
    name: attrName,
    type: attrType === 'secure-string' ? 'secure-string' : 'string',
    value: '',
  })),
});

export const IntegrationDialog = ({
  integrationMetadata,
  onHide,
  onDelete,
}: IntegrationDialogProps) => {
  useAnalyticsPage(Page.INTEGRATION_UPDATE);
  const analytics = useAnalytics();

  const { integrationApi } = useApi();
  const queryCache = useQueryCacheManager();
  const { notifySuccess, notifyError } = useNotification();

  const getIntegrationState = async (integrationId: string) =>
    integrationApi.getIntegrationState(integrationId);

  const { error, data, status } = useResolvedIntegration({ integrationId: integrationMetadata.id });

  useEffect(() => {
    if (error) {
      notifyError({
        err: error,
        logMsg: `Failed to load integration ${integrationMetadata.id} from backend`,
        notificationMsg: `Failed to load integration`,
      });
    }
  }, [notifyError, error, integrationMetadata.id]);

  const [showModal, setShowModal] = useState(true);
  const [integrationStateError, setIntegrationStateError] = useState<string | null>(null);

  const {
    isLoading: updateInProgress,
    error: updateError,
    mutateAsync: updateMutation,
  } = useMutation(async (change: ResolvedIntegration) => integrationApi.updateIntegration(change), {
    onSettled: async () => queryCache.initTenantIntegrationsRefetch(),
  });

  const disabled: boolean = !!error || status !== 'success' || updateInProgress;

  const handleHideClick: () => void = () => {
    setShowModal(false);
    onHide();
  };

  const handleApplyClick = async (change: ResolvedIntegration): Promise<void> => {
    try {
      await updateMutation(change);
      analyticsTrack(analytics, AnalyticsEvent.INTEGRATION_UPDATED, { name: change.name });

      const integrationState = await getIntegrationState(integrationMetadata.id);

      if (integrationState.errorMessage) {
        setIntegrationStateError(integrationState.errorMessage);
      } else {
        notifySuccess({
          notificationMsg: `Integration is updated.`,
          logMsg: `Integration ${change.name} is updated.`,
        });
        setShowModal(false);
        onHide();
      }
    } catch (err) {
      notifyError({
        err,
        logMsg: `Integration dialog failed to update integration ${change.id}`,
        notificationMsg: `Failed to update integration.`,
      });
    }
  };

  const initialValues =
    data !== null
      ? { loaded: true, integration: data }
      : { loaded: false, integration: stubResolvedIntegration(integrationMetadata) };

  return (
    <>
      <IntegrationEdit
        showModal={showModal}
        disabled={disabled}
        initialValues={initialValues}
        updateError={updateError}
        integrationStateError={integrationStateError}
        handleHideClick={handleHideClick}
        handleApplyClick={handleApplyClick}
        handleDeleteClick={onDelete}
      />
    </>
  );
};
