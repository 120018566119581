import { DivButton } from './DivButton';
import { CloseIcon } from '../../assets/icons';
import React, { ReactElement } from 'react';

export const ModalHeader = (props: {
  title: string;
  subTitle?: string;
  titleIcon?: ReactElement;
  handleClose: () => void;
}) => {
  const { title, subTitle, titleIcon, handleClose } = props;
  return (
    <div>
      <div className="growegy-modal__close-button-container">
        <DivButton
          icon={<CloseIcon />}
          onClick={handleClose}
          dataTest="modal-header__close"
          className="growegy-modal__header-button"
        />
      </div>
      <div className="growegy-modal__title">
        {titleIcon && <div className="flex-shrink-0">{titleIcon}</div>}
        <div className="growegy-font-em" data-test="growegy-modal__title">
          {title}
        </div>
        <div className="growegy-title14--moderat" data-test="growegy-modal__subtitle">
          {subTitle}
        </div>
      </div>
    </div>
  );
};
