import React from 'react';
import { PromptVariable } from '../../types';
import { VariableRowCell } from './VariableRowCell';

export const VariableOfStringTypeFields = ({
  variable,
  onUpdate,
}: {
  variable: PromptVariable;
  onUpdate: (newValue: PromptVariable) => Promise<void>;
}) => {
  const onNameAdded = async (newValue: string) => {
    if (variable.variableValue.type === 'string' && newValue !== variable.variableValue.name) {
      const updateValue: PromptVariable = {
        ...variable,
        variableValue: {
          ...variable.variableValue,
          value: newValue,
        },
      };
      await onUpdate(updateValue);
    }
  };

  const onDescriptionAdded = async (newValue: string) => {
    if (
      variable.variableValue.type === 'string' &&
      newValue !== variable.variableValue.description
    ) {
      const updateValue: PromptVariable = {
        ...variable,
        variableValue: {
          ...variable.variableValue,
          description: newValue,
        },
      };
      await onUpdate(updateValue);
    }
  };

  if (variable.variableValue.type === 'string') {
    return (
      <>
        <div
          data-test="variables-table__string-name-row"
          className="variables-table__row variables-table__row--highlighted d-flex"
        >
          <div className="variables-table__string-variable-name-cell" style={{ maxWidth: '25%' }}>
            Name
          </div>
          <VariableRowCell
            origValue={variable.variableValue.value}
            placeholder="Add Name"
            onUpdate={async (v) => onNameAdded(v)}
            style={{ maxWidth: '95%' }}
            textMaxLength={40}
          />
        </div>
        <div
          data-test="variables-table__string-description-row"
          className="variables-table__row variables-table__row--highlighted d-flex"
        >
          <div className="variables-table__string-variable-name-cell" style={{ maxWidth: '25%' }}>
            Description
          </div>
          <VariableRowCell
            origValue={variable.variableValue.description}
            placeholder="Add Description"
            onUpdate={async (v) => onDescriptionAdded(v)}
            style={{ maxWidth: '95%' }}
            textMaxLength={300}
          />
        </div>
      </>
    );
  }

  return null;
};
