import React, { useCallback } from 'react';
import { AttachmentMessage } from './AttachmentMessage';
import { analyticsTrack } from '../../../web-analytics/webAnalytics';
import { AnalyticsEvent } from '../../../web-analytics/AnalyticsEvent';
import useAnalytics from '../../../web-analytics/webAnalyticsContext';
import { downloadFile } from '../../../util/file-utils';

export const NoPreviewAttachmentMessage = (props: { fileName: string; url: string }) => {
  const { fileName, url } = props;
  const analytics = useAnalytics();
  const download = useCallback(() => {
    if (!url) return;
    analyticsTrack(analytics, AnalyticsEvent.PROGRAM_ATTACHMENT_DOWNLOADED, {
      button: 'no-preview',
    });
    downloadFile(url, fileName);
  }, [fileName, url, analytics]);

  return (
    <AttachmentMessage
      fileName={fileName}
      buttonText="Download"
      message="We can’t preview this file"
      onButtonClick={download}
      dataTestPrefix="attachment-view__no-preview"
      isError={false}
    />
  );
};
