import _ from 'lodash';
import { useCallback, useEffect, useState } from 'react';
import { ColoredItem } from '../types';
import { useMemoCompare, useMemoCompareMerge } from '../../hooks/useMemoCompare';

const coloredItemsMerge = <T extends ColoredItem>(a: T[], b: T[]): T[] => {
  const customAdded = a.filter((t1) => b.findIndex((t2) => t2.id === t1.id) === -1);
  return [...b, ...customAdded];
};

export const useColoredItemState = <T extends ColoredItem>(props: {
  selected: T;
  options: T[];
  onValueChange: (value: T) => void;
  initDummyItem?: (id: string) => T;
}) => {
  const { selected, onValueChange, initDummyItem } = props;
  const [options, setOptions] = useState<T[]>([...props.options]);
  const [selectedOption, setSelected] = useState<T>({
    ...selected,
  });

  const mergedOptionsCache = useMemoCompareMerge<T[]>(props.options, _.isEqual, coloredItemsMerge);
  const selectedOptionCache = useMemoCompare<T>(selected, _.isEqual);

  useEffect(() => {
    setOptions([...mergedOptionsCache]);
  }, [mergedOptionsCache]);

  useEffect(() => {
    setSelected({ ...selectedOptionCache });
  }, [selectedOptionCache]);

  const setSelectedOption = useCallback(
    (option: T) => {
      setSelected(option);
      onValueChange(option);
    },
    [onValueChange]
  );

  const addIfNotExist = useCallback(
    (id: string) => {
      if (id) {
        const option = options.find((c) => c.id === id);
        if (option) {
          setSelectedOption(option);
          onValueChange(option);
        } else if (initDummyItem) {
          const newOption = initDummyItem(id);
          setOptions((prev) => [...prev, newOption]);
          setSelectedOption(newOption);
          onValueChange(newOption);
        }
      }
    },
    [onValueChange, options, setSelectedOption, initDummyItem]
  );

  const tryAdd = useCallback(
    (value: string | null): void => {
      if (value && value.trim().length) addIfNotExist(value.trim());
    },
    [addIfNotExist]
  );

  return { tryAdd, selectedOption, options, setSelectedOption };
};
