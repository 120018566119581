import { IssuedInvoice } from '../types';
import useAnalytics from '../../web-analytics/webAnalyticsContext';
import { getInvoiceClickSource, isInvoicePayable } from '../utils';
import { onInvoiceClick, PayNowButton } from './PayNowButton';
import { DownloadIcon } from '../../assets/icons';
import { StyleVariables } from '../../commons/styleConstants';
import React from 'react';

export const InvoiceActionButton = ({
  invoice,
  context,
}: {
  invoice: IssuedInvoice;
  context: string;
}) => {
  const analytics = useAnalytics();

  const source = getInvoiceClickSource(invoice, context);
  if (isInvoicePayable(invoice)) {
    return (
      <PayNowButton
        invoice={invoice}
        source={source}
        dataTest="billing-invoices__pay-button-cell"
      />
    );
  }

  return (
    <div
      className="prompt-mark-button"
      data-test="billing-invoices__download-button-cell"
      onClick={(e) => onInvoiceClick(e, invoice, analytics, source)}
    >
      <DownloadIcon fill={StyleVariables.neutral500} />
    </div>
  );
};
