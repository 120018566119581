import {
  ResolvedChatMessage,
  TOOL_CALL_USER_RESULT_OK,
  TOOL_CREATE_PROGRAMS,
} from '../../../growegy-ai/types';
import useAuth from '../../../auth/authContext';
import { ProgramCalendarEvent } from '../calendar/ProgramCalendarEvent';
import { UnscheduledViewType } from '../../containers/types';
import { ResolvedProgramDatesField, ResolvedProgramOwnerField } from '../../types';
import React, { useState } from 'react';
import ProgramDialog, { ModalState } from '../../containers/ProgramDialog';
import { unresolveProgramSummary } from '../../util';
import { v4 as uuidv4 } from 'uuid';

export const CreateProgramsResultMessageContent = (props: { message: ResolvedChatMessage }) => {
  const { message } = props;
  const {
    state: { user },
  } = useAuth();

  const [modalState, setModalState] = useState<ModalState>(null);

  if (!user) return null;

  if (
    message.resolvedToolCallResults &&
    message.resolvedToolCallResults.length > 0 &&
    message.resolvedToolCallResults.every((x) => x.content.user_result !== TOOL_CALL_USER_RESULT_OK)
  ) {
    return <span>No problem, just let me know what you want to modify.</span>;
  }
  const createdPrograms =
    message.resolvedToolCallResults
      ?.filter((x) => x.name === TOOL_CREATE_PROGRAMS)
      .flatMap((x) => x.content.created_programs) ?? [];
  return (
    <>
      <div className="message-list__programs-container">
        <div>
          {createdPrograms.length > 0
            ? 'Here is the list of created program(s):'
            : 'No programs were created.'}
        </div>
        {createdPrograms.map((x, index) => (
          <div className="program-calendar__program-underlayer" key={`create_program_${index}`}>
            <ProgramCalendarEvent
              program={x}
              user={user}
              onClick={() =>
                setModalState({
                  key: uuidv4(),
                  program: unresolveProgramSummary(x),
                  action: 'UPDATE',
                  scrollToTaskId: null,
                  taskToDeleteOnCreate: null,
                })
              }
              showCampaigns={false}
              applicableVisibleColumns={[ResolvedProgramDatesField, ResolvedProgramOwnerField]}
              viewType={UnscheduledViewType}
              shortcutFieldNamesOnly={false}
            />
          </div>
        ))}
      </div>
      {modalState && (
        <ProgramDialog
          key={modalState.key}
          action={modalState.action}
          programSummary={modalState.program}
          scrollToTaskId={modalState.scrollToTaskId}
          onHide={() => {}}
        />
      )}
    </>
  );
};
