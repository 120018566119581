import { ResolvedProgramTask } from '../../types';
import React from 'react';
import { CellProps } from './CellProps';
import { User } from '../../../user/types';
import { BinIcon, CalendarWithArrowIcon } from '../../../assets/icons';
import { useAddProgramPermissionHelper } from '../../programPermissionHelper';
import { MoreMenu } from '../../../commons/components/MoreMenu';

export const TaskMenuCell = ({
  programId,
  arrayHelpers,
  index,
  isReadonly,
  onConvertTaskToProgram,
  row,
}: CellProps<ResolvedProgramTask, User | null>) => {
  const addProgramPermissionHelper = useAddProgramPermissionHelper();
  return (
    !isReadonly && (
      <MoreMenu
        menuItems={[
          {
            type: 'standard',
            text: 'Delete',
            icon: <BinIcon />,
            dataTest: 'tasks-table__delete-menu-button',
            onClick: () => arrayHelpers.remove(index),
          },
          {
            type: 'standard',
            text: 'Convert to program',
            icon: <CalendarWithArrowIcon />,
            isHidden: !programId || !addProgramPermissionHelper.canCreateProgram(),
            dataTest: 'tasks-table__convert-to-program-menu-button',
            onClick: () => {
              if (onConvertTaskToProgram) onConvertTaskToProgram(row.original);
            },
          },
        ]}
        menuButtonDataTest="tasks-table__menu-button"
        menuDataTest="tasks-table__menu"
      />
    )
  );
};
