import _ from 'lodash';
import { AxiosInstance, AxiosResponse } from 'axios';
import {
  AI_MESSAGE_SENDER,
  Chat,
  ChatMessage,
  ChatPromptAndAnswer,
  CLIENT_MESSAGE_SENDER,
  GPT_MODEL_3_5,
  GPT_MODEL_4,
  Prompt,
  PromptMark,
  PromptRecentUsage,
  PromptVariable,
  PromptVariableStringValue,
  PromptVariableValue,
  TOOL_CREATE_PROGRAMS,
  ToolCall,
  ToolCallResult,
} from './types';
import { errorToString } from '../util/utils';
import {
  ChatDto,
  ChatListResponseDto,
  ChatType,
  CreateChatRequestDto,
  CreateProgramsArgumentDto,
  MessageDto,
  MessageListResponseDto,
  PromptDto,
  PromptListResponseDto,
  PromptMarkDto,
  PromptMarkPageDto,
  PromptRecentUsageDto,
  PromptRecentUsagesDto,
  PromptVariableDto,
  PromptVariableListResponseDto,
  PromptVariableListValueDto,
  PromptVariableStringValueDto,
  RenameChatRequestDto,
  SendMessageRequestDto,
  SendMessageResponseDto,
} from './dto';
import { breakDownLibraryPrompt } from './util';

const toChat = (dto: ChatDto): Chat => ({
  id: dto.chatId,
  model: dto.model === GPT_MODEL_4.id ? GPT_MODEL_4 : GPT_MODEL_3_5,
  title: dto.title,
  type: dto.type,
});
const toChatMessage = (dto: MessageDto): ChatMessage => ({
  id: dto.messageId,
  clientId: dto.clientMessageId ?? dto.messageId,
  content: dto.content,
  sender: dto.isSentByUser ? CLIENT_MESSAGE_SENDER : AI_MESSAGE_SENDER,
  timestamp: new Date(dto.createdDate),
  promptId: dto.promptId,
  toolCalls:
    dto.toolCalls
      ?.filter((x) => x.function.name === TOOL_CREATE_PROGRAMS)
      .map((x): ToolCall => {
        try {
          return {
            id: x.id,
            name: TOOL_CREATE_PROGRAMS,
            arguments: JSON.parse(x.function.arguments) as CreateProgramsArgumentDto,
          };
        } catch (e) {
          logger.error('Failed to parse tool call.', e);
          return { id: x.id, name: TOOL_CREATE_PROGRAMS, arguments: { new_programs: [] } };
        }
      }) ?? null,
  toolCallResults: dto.toolCallResults?.map((x) => ({
    id: x.id,
    name: TOOL_CREATE_PROGRAMS,
    content: JSON.parse(x.content),
  })),
});
const toPrompt = (dto: PromptDto): Prompt => {
  const parsedSpans = breakDownLibraryPrompt(dto.content);
  const parsedContent = parsedSpans.map((s) => s.text).join();
  return {
    id: dto.promptId,
    categories: dto.categories ?? [],
    title: dto.title,
    content: dto.content,
    metadata: dto.metadata ?? { placeholders: [] },
    parsedSpans,
    parsedContent,
  };
};

const toPromptMark = (dto: PromptMarkDto): PromptMark => ({
  ...dto,
  updatedDate: dto.updatedDate ? new Date(dto.updatedDate) : null,
});

const toPromptMarkDto = (promptMark: PromptMark): PromptMarkDto => ({
  ...promptMark,
  updatedDate: promptMark.updatedDate?.toISOString() ?? null,
});

const toPromptVariable = (dto: PromptVariableDto): PromptVariable => {
  return {
    id: dto.variableId,
    isPrivate: dto.isPrivate,
    variableValue: toPromptVariableValue(dto.variable),
  };
};

const toPromptVariableValue = (
  dto: PromptVariableStringValueDto | PromptVariableListValueDto
): PromptVariableValue => {
  if (dto.type === 'StringVariable') {
    return {
      type: 'string',
      name: dto.name,
      value: dto.value,
      description: dto.description,
    };
  } else {
    return {
      type: 'list',
      name: dto.name,
      values: dto.values.map((x) => toPromptVariableValue(x) as PromptVariableStringValue),
    };
  }
};

export const ANSWER_MESSAGE_STATUS_OK = 'OK';
export const ANSWER_MESSAGE_STATUS_CANCELLED = 'CANCELLED';
export const ANSWER_MESSAGE_STATUS_ERROR = 'ERROR';
export type AnswerMessageStatus =
  | typeof ANSWER_MESSAGE_STATUS_OK
  | typeof ANSWER_MESSAGE_STATUS_CANCELLED
  | typeof ANSWER_MESSAGE_STATUS_ERROR;

const toPromptVariableDto = (promptVariable: PromptVariable): PromptVariableDto => {
  return {
    variableId: promptVariable.id,
    isPrivate: promptVariable.isPrivate,
    variable: toPromptVariableValueDto(promptVariable.variableValue),
  };
};

const toPromptVariableValueDto = (
  value: PromptVariableValue
): PromptVariableStringValueDto | PromptVariableListValueDto => {
  if (value.type === 'string') {
    return {
      type: 'StringVariable',
      name: value.name,
      value: value.value,
      description: value.description,
    };
  } else {
    return {
      type: 'ListVariable',
      name: value.name,
      values: value.values.map((x) => toPromptVariableValueDto(x) as PromptVariableStringValueDto),
    };
  }
};

const toPromptRecentUsage = (dto: PromptRecentUsageDto): PromptRecentUsage => {
  return {
    usageId: dto.usageId,
    promptId: dto.promptId,
    messageId: dto.messageId,
    updatedDate: dto.updatedDate ? new Date(dto.updatedDate) : null,
  };
};

export type GrowegyAIApi = ReturnType<typeof getGrowegyAIApi>;

export const getGrowegyAIApi = (axiosInstance: AxiosInstance) => {
  const getChats = async (userId: string, chatType: ChatType): Promise<Chat[]> =>
    loadTillEnd(async (nextPageToken) => {
      const url = `/chatgpt/users/${userId}/chats?chatType=${chatType}${
        nextPageToken ? `&nextPageToken=${nextPageToken}` : ''
      }`;
      const response = await axiosInstance.get<ChatListResponseDto>(url);
      return { items: response.data.chats, nextPageToken: response.data.nextPageToken };
    }).then((chats) => chats.map(toChat));

  const createChat = async (
    userId: string,
    model: string,
    title: string,
    type: ChatType
  ): Promise<Chat> =>
    axiosInstance
      .post<CreateChatRequestDto, AxiosResponse<ChatDto>>(`/chatgpt/users/${userId}/chats`, {
        title,
        model,
        type,
      })
      .then((r) => toChat(r.data));

  const renameChat = async (userId: string, chatId: string, newTitle: string): Promise<Chat> =>
    axiosInstance
      .put<RenameChatRequestDto, AxiosResponse<ChatDto>>(
        `/chatgpt/users/${userId}/chats/${chatId}`,
        {
          chatId,
          title: newTitle,
        }
      )
      .then((r) => toChat(r.data));

  const deleteChat = async (userId: string, chatId: string): Promise<void> =>
    axiosInstance.delete(`/chatgpt/users/${userId}/chats/${chatId}`);

  const loadTillEnd = async <T>(
    getItems: (
      lastEvaluatedKey: string | null
    ) => Promise<{ items: T[]; nextPageToken: string | null }>
  ): Promise<T[]> => {
    const result: T[] = [];

    async function loadNext(lastEvaluatedKey: string | null) {
      const response = await getItems(lastEvaluatedKey);
      result.push(...response.items);
      if (response.nextPageToken) {
        await loadNext(response.nextPageToken);
      }
    }

    await loadNext(null);
    return result;
  };

  const getMessages = async (userId: string, chatId: string): Promise<ChatMessage[]> =>
    loadTillEnd(async (nextPageToken) => {
      const url = `/chatgpt/users/${userId}/chats/${chatId}/messages${
        nextPageToken ? `?nextPageToken=${nextPageToken}` : ''
      }`;
      const response = await axiosInstance.get<MessageListResponseDto>(url);
      return { items: response.data.messages, nextPageToken: response.data.nextPageToken };
    }).then((messages) => messages.reverse().map(toChatMessage));

  const createMessage = async (
    userId: string,
    chatId: string,
    clientMessageId: string,
    content: string | null,
    systemMessage: string | null,
    promptId: string | null,
    toolCallResults: ToolCallResult[] | null
  ): Promise<ChatPromptAndAnswer> => {
    try {
      const request: SendMessageRequestDto = {
        clientMessageId,
        content,
        promptId,
        messageMetadata: { systemMessage },
        toolCallResults: toolCallResults?.map((x) => ({
          id: x.id,
          name: x.name,
          content: JSON.stringify(x.content),
        })),
      };
      const response = await axiosInstance.post<
        SendMessageRequestDto,
        AxiosResponse<SendMessageResponseDto>
      >(`/chatgpt/users/${userId}/chats/${chatId}/messages`, request, {
        timeout: 60_000,
      });
      return {
        prompt: toChatMessage(response.data.prompt),
        answer: response.data.answer ? toChatMessage(response.data.answer) : null,
        answerStatus: response.data.answerStatus,
      };
    } catch (e) {
      throw new Error(errorToString(e));
    }
  };

  const cancelMessage = async (userId: string, chatId: string, clientMessageId: string) => {
    try {
      await axiosInstance.delete(
        `/chatgpt/users/${userId}/chats/${chatId}/messages/cancel?clientMessageId=${clientMessageId}`
      );
    } catch (e) {
      throw new Error(errorToString(e));
    }
  };

  const getPrompts = async (userId: string): Promise<Prompt[]> =>
    loadTillEnd(async (nextPageToken) => {
      const url = `/chatgpt/users/${userId}/prompts/growegy-predefined${
        nextPageToken ? `?nextPageToken=${nextPageToken}` : ''
      }`;
      const response = await axiosInstance.get<PromptListResponseDto>(url);
      return { items: response.data.prompts, nextPageToken: response.data.nextPageToken };
    }).then((x) => x.map(toPrompt));

  const getVariables = async (userId: string): Promise<PromptVariable[]> =>
    loadTillEnd(async (nextPageToken) => {
      const url = `/chatgpt/users/${userId}/variables${
        nextPageToken ? `?nextPageToken=${nextPageToken}` : ''
      }`;
      const response = await axiosInstance.get<PromptVariableListResponseDto>(url);
      return { items: response.data.variables, nextPageToken: response.data.nextPageToken };
    }).then((x) => {
      const allVariables = x.map(toPromptVariable);
      const uniqueVariables: PromptVariable[] = [];
      _(allVariables)
        .groupBy((x) => x.variableValue.name)
        .forEach((g) => uniqueVariables.push(g[0]));
      return uniqueVariables;
    });

  const createVariable = async (
    userId: string,
    variable: PromptVariable
  ): Promise<PromptVariable> => {
    try {
      const response = await axiosInstance.post<
        PromptVariableDto,
        AxiosResponse<PromptVariableDto>
      >(`/chatgpt/users/${userId}/variables`, toPromptVariableDto(variable));
      return toPromptVariable(response.data);
    } catch (e) {
      throw new Error(errorToString(e));
    }
  };

  const updateVariable = async (
    userId: string,
    variable: PromptVariable
  ): Promise<PromptVariable> => {
    try {
      const response = await axiosInstance.put<PromptVariableDto, AxiosResponse<PromptVariableDto>>(
        `/chatgpt/users/${userId}/variables/${variable.id}`,
        toPromptVariableDto(variable)
      );
      return toPromptVariable(response.data);
    } catch (e) {
      throw new Error(errorToString(e));
    }
  };

  const getPromptRecentUsages = async (userId: string): Promise<PromptRecentUsage[]> => {
    const url = `/chatgpt/users/${userId}/prompts/recent-usages?limit=50`;
    const response = await axiosInstance.get<PromptRecentUsagesDto>(url);
    return response.data.usages.map(toPromptRecentUsage);
  };

  const updatePromptMark = async (userId: string, promptMark: PromptMark): Promise<PromptMark> => {
    try {
      const response = await axiosInstance.put<PromptMarkDto, AxiosResponse<PromptMarkDto>>(
        `/chatgpt/users/${userId}/prompts/${promptMark.promptId}/mark`,
        toPromptMarkDto(promptMark)
      );
      return toPromptMark(response.data);
    } catch (e) {
      throw new Error(errorToString(e));
    }
  };

  const getPromptMarks = async (userId: string): Promise<PromptMark[]> =>
    loadTillEnd(async (nextPageToken) => {
      const url = `/chatgpt/users/${userId}/prompts/marks${
        nextPageToken ? `?nextPageToken=${nextPageToken}` : ''
      }`;
      const response = await axiosInstance.get<PromptMarkPageDto>(url);
      return { items: response.data.promptMarks, nextPageToken: response.data.nextPageToken };
    }).then((x) => x.map(toPromptMark));

  return {
    getChats,
    createChat,
    renameChat,
    deleteChat,
    getMessages,
    createMessage,
    cancelMessage,
    getPrompts,
    getVariables,
    createVariable,
    updateVariable,
    getPromptRecentUsages,
    getPromptMarks,
    updatePromptMark,
  };
};
