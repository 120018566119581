import React from 'react';

export const NoVariablesHelp = () => (
  <div
    className="variables-table__no-variables-help-container"
    data-test="variables-table__no-variables-help-container"
  >
    <p>
      This tab displays details about the variables that currently exist and provides the option to
      modify them. To add your first variable, go to the Library tab. Here's how to create a new
      variable:
    </p>
    <ol>
      <li>
        <span>Choose a prompt and click </span>
        <span className="growegy-font14--weight700">“Start new chat”</span>.
      </li>

      <li>
        <span>Look for the button labeled </span>
        <span className="growegy-font14--weight700">“Add new [variable name]”</span>
        <span>. For instance, if your variable is called </span>
        <span className="growegy-font14--weight700">“Product or service”</span>
        <span>, the button will say </span>
        <span className="growegy-font14--weight700">“Add new product or service”</span>.
      </li>

      <li>
        <span>Provide the necessary information for the variable, ensure that the </span>
        <span className="growegy-font14--weight700">“Save as variable”</span>
        <span> toggle is enabled, and click the </span>
        <span className="growegy-font14--weight700">“Add [variable name]”</span>
        <span> button.</span>
      </li>
    </ol>
  </div>
);
