import clsx from 'clsx';
import { DetailedHTMLProps, forwardRef, HTMLAttributes } from 'react';

export const BigDragHandle = forwardRef(
  ({
    className,
    dataTest,
    ...rest
  }: DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement> & { dataTest: string }) => (
    <div
      className="drag-handle__container"
      {...rest}
      onMouseDown={() => {
        const activeElement = document.activeElement;
        if (activeElement instanceof HTMLElement) {
          activeElement.blur();
        }
      }}
    >
      <div className={clsx('drag-handle', className)} data-test={dataTest} />
    </div>
  )
);
