import _ from 'lodash';
import React, { PropsWithChildren } from 'react';
import { StripePaymentMethodDto } from '../dto';
import { StyleVariables } from '../../commons/styleConstants';
import { BankCardIcon, PencilIcon, PlusIcon } from '../../assets/icons';
import { DivButton } from '../../commons/components/DivButton';
import useAnalytics from '../../web-analytics/webAnalyticsContext';
import useApi from '../../api/backendApiContext';
import { analyticsTrack } from '../../web-analytics/webAnalytics';
import { AnalyticsEvent } from '../../web-analytics/AnalyticsEvent';
import { useMutation } from '@tanstack/react-query';
import { MoreMenu } from '../../commons/components/MoreMenu';

import { openUrlInNewTab } from '../../util/utils';
import { Customer } from '../types';
import { useIsTabletOrLarger } from '../../responsive/hooks/hooks';

const IsDefaultBadge = ({ id, customer }: { id: string; customer: Customer }) => {
  const isDefault = id === customer.invoiceSettingsDto?.defaultPaymentMethod;
  if (isDefault)
    return (
      <span
        className="billing-table__badge"
        data-test="billing-cards__status-cell"
        style={{
          borderColor: StyleVariables.neutral500,
          color: StyleVariables.neutral500,
        }}
      >
        Default
      </span>
    );
  else return <span data-test="billing-cards__status-cell" />;
};

const PaymentMethodDescription = ({ children }: PropsWithChildren) => {
  return (
    <div className="d-flex align-items-center justify-content-start">
      <div className="billing__icon">
        <BankCardIcon className="d-block" />
      </div>
      <div className="growegy-label14--moderrat flex-grow-1" data-test="billing-cards__description">
        {children}
      </div>
    </div>
  );
};

const PaymentMethodExpiration = ({ paymentMethod }: { paymentMethod: StripePaymentMethodDto }) => {
  if (paymentMethod.card) {
    return (
      <>{`Expires ${paymentMethod.card.expMonth.toString().padStart(2, '0')}/${
        paymentMethod.card.expYear
      }`}</>
    );
  }
  return <></>;
};

const getPaymentMethodDescription = (paymentMethod: StripePaymentMethodDto) => {
  if (paymentMethod.card) {
    return `${_.capitalize(paymentMethod.card.brand)} •••• ${paymentMethod.card.last4}`;
  }
  if (paymentMethod.link) {
    return `Link ${paymentMethod.link.email}`;
  }
  if (paymentMethod.payPall) {
    return `PayPal ${paymentMethod.payPall.email}`;
  }
  if (paymentMethod.cashApp) {
    return `CashApp`;
  }
  if (paymentMethod.usBankAccount) {
    return `${paymentMethod.usBankAccount.bankName} •••• ${paymentMethod.usBankAccount.last4}`;
  }
  return `${paymentMethod.type ?? 'Unknown'}`;
};

const StripePaymentMethodRowCells = ({
  paymentMethod,
  customer,
}: {
  paymentMethod: StripePaymentMethodDto;
  customer: Customer;
}) => (
  <>
    <div
      className="billing-table__column billing-table__column--size8 text--truncated"
      data-test="billing-cards__description-cell"
    >
      <PaymentMethodDescription>
        {getPaymentMethodDescription(paymentMethod)}
      </PaymentMethodDescription>
    </div>
    <div className="billing-table__column billing-table__column--size4">
      <span className="growegy-label14--moderrat" data-test="billing-cards__expiration-cell">
        <PaymentMethodExpiration paymentMethod={paymentMethod} />
      </span>
    </div>
    <div className="billing-table__column--badge  billing-table__column--size3 d-flex">
      <IsDefaultBadge id={paymentMethod.id} customer={customer} />
    </div>
  </>
);

const StripePaymentMethodRowCellsNarrow = ({
  paymentMethod,
  customer,
}: {
  paymentMethod: StripePaymentMethodDto;
  customer: Customer;
}) => (
  <>
    <div
      className="billing-table__column text--truncated flex-grow-1"
      data-test="billing-cards__description-cell"
    >
      <PaymentMethodDescription>
        {getPaymentMethodDescription(paymentMethod)}
        <div className="billing-table__column--description-details">
          <span className="growegy-title14--moderat" data-test="billing-cards__expiration-cell">
            <PaymentMethodExpiration paymentMethod={paymentMethod} />
          </span>
          <IsDefaultBadge id={paymentMethod.id} customer={customer} />
        </div>
      </PaymentMethodDescription>
    </div>
  </>
);

const StripePaymentMethodRow = ({
  paymentMethod,
  customer,
  onManageClick,
}: {
  paymentMethod: StripePaymentMethodDto;
  customer: Customer;
  onManageClick: () => void;
}) => {
  const isTabletOrLarger = useIsTabletOrLarger();
  return (
    <div className="billing-flex-table__row" data-test="billing-cards__row">
      {isTabletOrLarger ? (
        <StripePaymentMethodRowCells paymentMethod={paymentMethod} customer={customer} />
      ) : (
        <StripePaymentMethodRowCellsNarrow paymentMethod={paymentMethod} customer={customer} />
      )}

      <div>
        <MoreMenu
          menuItems={[
            {
              type: 'standard',
              text: 'Manage',
              icon: <PencilIcon />,
              dataTest: 'billing-payment-methods__item-manage',
              onClick: () => onManageClick(),
            },
          ]}
          menuButtonDataTest="billing-payment-methods__item-menu-button"
          menuDataTest="billing-payment-methods__item-menu"
        />
      </div>
    </div>
  );
};

const extractPaymentMethodKeyString = (paymentMethod: StripePaymentMethodDto) => {
  if (paymentMethod.card) {
    return `${paymentMethod.type};${paymentMethod.card.brand};${paymentMethod.card.last4};${paymentMethod.card.expMonth};${paymentMethod.card.expYear}`;
  }
  if (paymentMethod.link) {
    return `${paymentMethod.type};${paymentMethod.link.email}`;
  }
  if (paymentMethod.payPall) {
    return `${paymentMethod.type};${paymentMethod.payPall.email}`;
  }
  if (paymentMethod.cashApp) {
    return `${paymentMethod.type};${paymentMethod.cashApp.buyerId};${paymentMethod.cashApp.cachTag}`;
  }
  if (paymentMethod.usBankAccount) {
    return `${paymentMethod.type};;${paymentMethod.usBankAccount.bankName};${paymentMethod.usBankAccount.last4};${paymentMethod.usBankAccount.routingNumber};${paymentMethod.usBankAccount.accountType}`;
  }
  return `unknown`;
};

const filterOutDuplicates = (paymentMethods: StripePaymentMethodDto[], customer: Customer) => {
  const defaultPaymentMethod = customer.invoiceSettingsDto?.defaultPaymentMethod;
  const keyToPaymentMethodMap = new Map<string, StripePaymentMethodDto>();

  paymentMethods.forEach((pm) => {
    const key = extractPaymentMethodKeyString(pm);
    const isDefault = pm.id === defaultPaymentMethod;
    if (isDefault || !keyToPaymentMethodMap.has(key)) {
      keyToPaymentMethodMap.set(key, pm);
    }
  });
  return Array.from(keyToPaymentMethodMap.values());
};

export const PaymentMethodsTable = ({
  paymentMethods,
  customer,
}: {
  paymentMethods: StripePaymentMethodDto[];
  customer: Customer;
}) => {
  const analytics = useAnalytics();
  const { billingApi } = useApi();

  const openBillingPortal = async (isAddNew: boolean) => {
    const billingPortalSession = await billingApi.createBillingPortalSession();
    logger.info('Redirecting to payment methods in billing portal');
    if (isAddNew) {
      openUrlInNewTab(
        billingPortalSession.url,
        {
          analytics,
          analyticsEvent: AnalyticsEvent.BILLING_CARD_ADD_NEW_CLICKED,
        },
        '/payment-methods'
      );
    } else {
      openUrlInNewTab(billingPortalSession.url, {
        analytics,
        analyticsEvent: AnalyticsEvent.BILLING_CARD_MANAGE,
      });
    }
  };

  const { isLoading, mutate: openBillingCardChangeMutation } = useMutation(openBillingPortal, {
    onError: () => {
      analyticsTrack(analytics, AnalyticsEvent.BILLING_PORTAL_FAILED);
    },
  });
  return (
    <div data-test="billing-cards">
      <div className="billing-section__header">Payment methods</div>
      <>
        {filterOutDuplicates(paymentMethods, customer).map((paymentMethod) => (
          <StripePaymentMethodRow
            paymentMethod={paymentMethod}
            customer={customer}
            key={paymentMethod.id}
            onManageClick={() => {
              if (!isLoading) openBillingCardChangeMutation(false);
            }}
          />
        ))}
      </>
      <div className="billing-flex-table__header">
        <DivButton
          isGray={true}
          icon={<PlusIcon />}
          text={`Add new`}
          onClick={() => {
            if (!isLoading) openBillingCardChangeMutation(true);
          }}
          className="common-table__add-new-btn"
          dataTest="billing-cards__add-new-btn"
        />
      </div>
    </div>
  );
};
