import * as yup from 'yup';
import { FieldArray, Formik } from 'formik';
import { Button, Form, Modal } from 'react-bootstrap';
import 'react-datepicker/dist/react-datepicker.css';
import { ResolvedIntegration } from '../types';
import { errorToString } from '../../util/utils';
import { IntegrationAttribute } from './IntegrationAttribute';
import { DivButton } from '../../commons/components/DivButton';
import { BinIcon, CloseIcon, DividerIcon } from '../../assets/icons';
import { isNew } from './IntegrationsTable';
import { NewTabLink } from '../../commons/components/NewTabLink';

const validationSchema = yup.object({
  attributes: yup.array().of(
    yup.object({
      value: yup.string().required('Field value is required!'),
    })
  ),
});

type IntegrationEditProps = {
  showModal: boolean;
  disabled: boolean;
  initialValues: { integration: ResolvedIntegration; loaded: boolean };
  updateError: unknown;
  integrationStateError: string | null;
  handleHideClick: () => void;
  handleApplyClick: (change: ResolvedIntegration) => Promise<void>;
  handleDeleteClick: (integration: ResolvedIntegration) => void;
};

export const IntegrationEdit = ({
  showModal,
  disabled,
  initialValues,
  updateError,
  integrationStateError,
  handleHideClick,
  handleApplyClick,
  handleDeleteClick,
}: IntegrationEditProps) => (
  <>
    <Modal
      show={showModal}
      onHide={handleHideClick}
      animation={false}
      dialogClassName="integration-modal"
      centered={true}
    >
      <Formik
        initialValues={initialValues.integration}
        validationSchema={validationSchema}
        onSubmit={async (p) => handleApplyClick(p)}
        enableReinitialize={true}
      >
        {({ touched, errors, isValid, dirty, handleSubmit, values }) => (
          <Form onSubmit={handleSubmit} aria-autocomplete="none">
            <>
              <Modal.Header className="integration-edit__header">
                <div className="dialog-header__menu">
                  {initialValues.loaded && !isNew(initialValues.integration) && (
                    <>
                      <DivButton
                        icon={<BinIcon />}
                        onClick={() => handleDeleteClick(initialValues.integration)}
                        className="dialog-header__button"
                        dataTest="integration-edit__delete"
                      />
                      <DividerIcon style={{ marginLeft: '16px', marginRight: '16px' }} />
                    </>
                  )}
                  <DivButton
                    icon={<CloseIcon />}
                    onClick={handleHideClick}
                    className="dialog-header__button"
                    data-test="integration-edit__close"
                  />
                </div>
              </Modal.Header>

              <Modal.Body className="integration-edit__body">
                <div>
                  <img
                    src={`data:image/svg+xml;base64,${initialValues.integration.icon}`}
                    alt={initialValues.integration.name}
                    data-test={`integration-edit__icon--${initialValues.integration.id}`}
                    className="integration__logo"
                  />
                  <div
                    className="integration__title"
                    data-test={`integration-edit__name--${initialValues.integration.id}`}
                  >
                    Integrate Growegy with {values.name}
                  </div>
                  <div
                    className="integration__description growegy-label14"
                    data-test={`integration-edit__description--${initialValues.integration.id}`}
                  >
                    <p>
                      Connecting with Salesforce allows you to import campaign data and tie your
                      projected results to&nbsp;actual results and budget. This provides results
                      like ROI, ROAS, and CAC. Moreover, you can quickly see your best performers so
                      you can repeat these activities.
                    </p>
                    <p>
                      For instructions, see our step-by-step{' '}
                      <NewTabLink href="https://www.growegy.com/salesforce-integration/">
                        Salesforce integration guide.
                      </NewTabLink>
                    </p>
                  </div>
                  <div>
                    <FieldArray name="attributes">
                      {() => (
                        <>
                          {values.attributes.map((attribute, index) => (
                            <IntegrationAttribute
                              key={attribute.name}
                              attribute={attribute}
                              index={index}
                              disabled={disabled}
                              errors={errors}
                              touched={touched}
                            />
                          ))}
                        </>
                      )}
                    </FieldArray>
                  </div>
                </div>
              </Modal.Body>
              {updateError && (
                <div
                  className="alert alert-warning"
                  role="alert"
                  data-test="integration-edit__edit-warning"
                >
                  Failed to update integration: {errorToString(updateError)}
                </div>
              )}
              {integrationStateError && (
                <div className="alert alert-warning" role="alert">
                  {integrationStateError}
                </div>
              )}
              <Modal.Footer className="integration-edit__footer">
                <div className="d-flex">
                  {initialValues.loaded && (
                    <>
                      <Button
                        variant="light"
                        className="button-cancel"
                        onClick={handleHideClick}
                        disabled={disabled}
                        data-test="integration-edit__cancel"
                      >
                        Cancel
                      </Button>
                      <Button
                        type="submit"
                        variant="primary"
                        className="button-apply"
                        disabled={!isValid || disabled || !dirty || !initialValues.loaded}
                        data-test="integration-edit__apply"
                      >
                        {isNew(initialValues.integration) ? 'Connect' : 'Update connection'}
                      </Button>
                    </>
                  )}
                </div>
              </Modal.Footer>
            </>
          </Form>
        )}
      </Formik>
    </Modal>
  </>
);
