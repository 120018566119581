import React, { ForwardedRef, forwardRef, PropsWithChildren } from 'react';
import { User } from '../../../user/types';
import clsx from 'clsx';
import { LargeUserAvatar } from '../../../commons/components/UserAvatar';
import { AiStarsSolid, CalendarWithSolidSpark } from '../../../assets/icons';
import { CHAT_TYPE_CREATE_PROGRAM, ChatType } from '../../dto';
import { IconWithGradientBackground } from '../../../commons/components/IconWithGradientBackground';
import { AI_MESSAGE_SENDER } from '../../types';

export const TemplateMessage = forwardRef(
  (
    props: PropsWithChildren<{
      sender: User | typeof AI_MESSAGE_SENDER | 'none';
      chatType?: ChatType;
    }>,
    ref: ForwardedRef<HTMLDivElement>
  ) => {
    const { sender, chatType, children } = props;
    return (
      <div
        className={clsx('message-list__message-panel', {
          'message-list__message-panel--ai': sender === AI_MESSAGE_SENDER,
          'message-list__message-panel--client': sender !== AI_MESSAGE_SENDER && sender !== 'none',
        })}
        data-test="message-list__message-panel"
        ref={ref}
      >
        <div className="message-list__message-container">
          <div className="message-list__avatar-container">
            {sender === AI_MESSAGE_SENDER ? (
              <IconWithGradientBackground
                icon={
                  chatType === CHAT_TYPE_CREATE_PROGRAM ? (
                    <CalendarWithSolidSpark className="growegy-ai__floating-button-icon" />
                  ) : (
                    <AiStarsSolid className="growegy-ai__floating-button-icon" />
                  )
                }
                dataTest="message-list__message-growegy-ai-avatar"
              />
            ) : sender === 'none' ? (
              <div className="message-list__message-avatar-placeholder" />
            ) : (
              <LargeUserAvatar user={sender} />
            )}
          </div>
          {children}
        </div>
      </div>
    );
  }
);
