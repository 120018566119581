/* eslint-disable react-hooks/rules-of-hooks */
import { ProgramRow, ResolvedCampaign } from '../../types';
import React, { MouseEvent, useEffect, useState } from 'react';
import { CellProps } from './ProgramCells';
import { CampaignPlaceholderText, CampaignSelect, CampaignSelectOption } from '../CampaignSelect';
import { useResolvedCampaigns } from '../../hooks/useResolvedCampaigns';
import { useProgramPermissionHelper } from '../../programPermissionHelper';
import { GrowegySelectMockup } from '../GrowegySelectMockup';
import { ProgramTableCellWidth } from '../../util';

const CampaignCell =
  (dataTest: string) =>
  ({
    onProgramUpdate,
    onRowEditChange,
    value: origValue,
    row,
    readOnly,
  }: CellProps<ProgramRow, ResolvedCampaign | null>) => {
    const [value, setValue] = useState<ResolvedCampaign | null>(origValue);
    const [editing, setEditing] = useState(false);
    const programPermissionHelper = useProgramPermissionHelper(row.original);
    const { data: campaigns } = useResolvedCampaigns();

    useEffect(() => {
      setValue(origValue);
    }, [origValue]);

    function onEditClick(e: MouseEvent<HTMLDivElement>) {
      if (row.isGrouped) return;
      e.stopPropagation();
      if (readOnly) return;
      if (editing) return;
      setEditing(true);
      onRowEditChange(true);
    }

    const cancel = () => {
      setValue(origValue);
      setEditing(false);
      onRowEditChange(false);
    };

    const handleCampaignChange = (campaign: ResolvedCampaign | null) => {
      if (campaign?.id !== row.original.campaign?.id) {
        onProgramUpdate({
          original: row.original,
          updates: [{ field: 'campaign', value: campaign }],
        });
      }
      cancel();
    };

    return (
      <div
        data-test={dataTest}
        onClick={onEditClick}
        onKeyDown={(e) => {
          if (editing) e.stopPropagation();
        }}
        className="d-flex align-items-center"
      >
        {editing ? (
          <CampaignSelect
            campaigns={programPermissionHelper.getCampaignOptions(campaigns)}
            initialValue={value}
            onBlur={cancel}
            onValueChange={handleCampaignChange}
            autoFocus={true}
            disabled={readOnly}
            isRequired={!programPermissionHelper.canMakeStandalone()}
            width={ProgramTableCellWidth}
          />
        ) : (
          <GrowegySelectMockup placeholderText={CampaignPlaceholderText} readOnly={readOnly}>
            {value ? <CampaignSelectOption campaign={value!} disabled={false} /> : undefined}
          </GrowegySelectMockup>
        )}
      </div>
    );
  };

export default CampaignCell;
