import { useQuery } from '@tanstack/react-query';
import { useQueryCacheManager } from '../../hooks/useQueryCacheManager';
import useApi from '../../api/backendApiContext';
import { ReactQueryConstants } from '../../commons/constants';

export const useFetchedNotificationSettings = (userId: string) => {
  const { userApi } = useApi();
  const cacheManager = useQueryCacheManager();
  return useQuery(
    cacheManager.getNotificationSettingsCacheName(userId),
    async () => userApi.getNotificationSettings(userId),
    {
      refetchOnWindowFocus: false,
      staleTime: ReactQueryConstants.notificationSettingsStaleTime,
    }
  );
};
