import { ICurrentUser } from '../currentuser/currentUser';
import { Analytics } from '@segment/analytics-next';
import { AnalyticsEvent } from './AnalyticsEvent';

export const analyticsIdentifyUser = (
  analytics: Analytics | undefined,
  currentUser: ICurrentUser
) => {
  try {
    analytics?.identify(currentUser.userId, {
      email: currentUser.email,
      username: currentUser.email,
      roles: currentUser.roles.map((x) => x.id).join(','),
    });
    analyticsTrack(analytics, AnalyticsEvent.USER_SIGNED_IN);
  } catch (err) {
    logger.error('Error while identifying user: ', err);
  }
};

export const analyticsTrack = (
  analytics: Analytics | undefined,
  analyticsEvent: AnalyticsEvent,
  properties: object = {}
) => {
  try {
    analytics?.track(`${analyticsEvent.entity} ${analyticsEvent.action}`, properties);
  } catch (err) {
    logger.error('Error while reporting analytics event: ', err);
  }
};
