import { FaroConfig } from '../config';
import { BrowserCacheLocation, Configuration, LogLevel } from '@azure/msal-browser';
import { BASE_URL } from './utils';

export const ENV_LOCAL = 'local';
export const ENV_E2E = 'e2e';

export const readCommonConfig = () => ({
  env: process.env.REACT_APP_ENV ?? ENV_LOCAL,
  version: process.env.REACT_APP_VERSION ?? '0000000000000000000000000000000000000000',
  backendApiUrl: process.env.REACT_APP_BACKEND_API,
  inAppSignup: process.env.REACT_APP_IN_APP_SIGNUP === 'true',
  externalSignupUrl: process.env.REACT_APP_EXTERNAL_SIGNUP_URL,
});

export const googleConfig = {
  enabled: process.env.REACT_APP_GOOGLE_ENABLED === 'true',
  restrictedDomainsOnly: process.env.REACT_APP_GOOGLE_RESTRICTED_DOMAINS_ONLY?.split(','),
  clientId: process.env.REACT_APP_GOOGLE_CLIENT_ID ?? '',
  calendarHelpUrl: process.env.REACT_APP_GOOGLE_CALENDAR_HELP_URL ?? 'https://growegy.com/',
};

export const outlookConfig = {
  enabled: process.env.REACT_APP_OUTLOOK_ENABLED === 'true',
  restrictedDomainsOnly: process.env.REACT_APP_OUTLOOK_RESTRICTED_DOMAINS_ONLY?.split(','),
  clientId: process.env.REACT_APP_OUTLOOK_CLIENT_ID ?? '',
  calendarHelpUrl: process.env.REACT_APP_OUTLOOK_HELP_URL ?? 'https://growegy.com/',
  redirectPath: '/ms-auth',
};

export const msalConfig: Configuration = {
  auth: {
    clientId: outlookConfig.clientId,
    authority: 'https://login.microsoftonline.com/common',
    redirectUri: `${BASE_URL}${outlookConfig.redirectPath}`,
  },
  cache: {
    cacheLocation: BrowserCacheLocation.MemoryStorage,
    temporaryCacheLocation: BrowserCacheLocation.MemoryStorage,
    storeAuthStateInCookie: false,
    secureCookies: true,
    cacheMigrationEnabled: false,
    claimsBasedCachingEnabled: false,
  },
  system: {
    loggerOptions: {
      loggerCallback: (level, message, containsPii) => {
        if (containsPii) {
          return;
        }
        switch (level) {
          case LogLevel.Error:
            logger.error(message);
            return;
          case LogLevel.Info:
            logger.info(message);
            return;
          case LogLevel.Verbose:
            logger.info(message);
            return;
          case LogLevel.Warning:
            logger.warn(message);
            return;
        }
      },
    },
  },
};

export const growegyAIConfig = {
  enabled: process.env.REACT_APP_GROWEGY_AI_ENABLED === 'true',
  libraryEnabled: process.env.REACT_APP_GROWEGY_AI_LIBRARY_ENABLED === 'true',
  variablesEnabled: process.env.REACT_APP_GROWEGY_AI_VARIABLES_ENABLED === 'true',
  recentlyUsedPromptsEnabled:
    process.env.REACT_APP_GROWEGY_AI_RECENTLY_USED_PROMPTS_ENABLED === 'true',
  maxMessagesPerChat: process.env.REACT_APP_GROWEGY_AI_MAX_MESSAGES_PER_CHAT
    ? +process.env.REACT_APP_GROWEGY_AI_MAX_MESSAGES_PER_CHAT
    : 25,
  programsChatEnabled: process.env.REACT_APP_PROGRAMS_CHAT_ENABLED === 'true',
};

export const programsConfig = {
  customFormulaFieldHelpUrl:
    process.env.REACT_APP_CUSTOM_FORMULA_FIELD_HELP_URL ?? 'https://www.growegy.com/formulas/',
};

export const readFaroConfig = () => ({
  url: FaroConfig.url,
  apiKey: FaroConfig.apiKey,
  environment: readCommonConfig().env,
  version: readCommonConfig().version,
});

export const billingPortalConfig = {
  enabled: process.env.REACT_APP_BILLING_PORTAL_ENABLED === 'true',
  loyaltyBenefitsConsultationUrl:
    process.env.REACT_APP_BILLING_PORTAL_LOYALTY_BENEFITS_CONSULTATION_URL ??
    'https://growegy.com/small-business/loyalty-program/',
};
