import { AnswerMessageStatus } from './growegyAIApi';
import { LibraryPromptContentPart } from './util';
import {
  ChatType,
  CreateProgramsArgumentDto,
  PlaceholderMetadataDto,
  PromptMetadataDto,
} from './dto';
import { StyleVariables } from '../commons/styleConstants';
import { ResolvedProgramSummary } from '../program/types';

export const MAX_CHAT_TITLE_LENGTH = 50;
export const MAX_CHAT_MESSAGE_LENGTH = 4000;
export const NEW_CHAT_ID = 'new-chat-id';

export type GptModel = {
  id: string;
  name: string;
  adjective: string;
  description: string;
};
export const GPT_MODEL_3_5: GptModel = {
  id: 'GPT_3_5',
  name: 'GPT-3.5',
  adjective: 'Basic',
  description: 'Included with Monthly plan',
};
export const GPT_MODEL_4: GptModel = {
  id: 'GPT_4',
  name: 'GPT-4',
  adjective: 'Advanced',
  description: 'The smartest and most capable model yet',
};

export type Chat = {
  id: string;
  model: GptModel;
  title: string;
  type: ChatType;
};

export const CLIENT_MESSAGE_SENDER = 'client' as const;
export const AI_MESSAGE_SENDER = 'ai' as const;
export type ChatMessageSender = typeof CLIENT_MESSAGE_SENDER | typeof AI_MESSAGE_SENDER;

export type ChatMessage = {
  id: string | null;
  clientId: string;
  content: string | null;
  timestamp: Date;
  sender: ChatMessageSender;
  isPlaceholder?: boolean;
  isWizardStep?: boolean;
  wizardStepNumber?: number;
  sendFailed?: boolean;
  errorMessage?: string;
  isCancelled?: boolean;
  promptId: string | null;
  toolCalls?: ToolCall[] | null;
  toolCallResults?: ToolCallResult[] | null;
  additionalContext?: string;
};

export const TOOL_CREATE_PROGRAMS = 'create_programs';

export type ToolCall = { id: string } & CreateProgramsToolCall;
export type CreateProgramsToolCall = {
  name: typeof TOOL_CREATE_PROGRAMS;
  arguments: CreateProgramsArgumentDto;
};

export type ResolvedToolCall = { id: string } & ResolvedCreateProgramsToolCall;
export type ResolvedCreateProgramsToolCall = {
  name: typeof TOOL_CREATE_PROGRAMS;
  arguments: {
    programs: ResolvedProgramSummary[];
  };
};

export type ToolCallResult = { id: string } & CreateProgramsToolCallResult;
export type CreateProgramToolCallResult = {
  id: string;
  name: string;
  program_type_id: string;
  all_day: boolean;
  start_date_time: string;
  end_date_time: string;
  owner_user_id: string;
};
export type CreateProgramsToolCallResult = {
  name: typeof TOOL_CREATE_PROGRAMS;
  content: {
    user_result: ToolCallUserResult;
    created_programs: CreateProgramToolCallResult[];
    message?: string;
  };
};

export type ResolvedToolCallResult = { id: string } & ResolvedCreateProgramsToolCallResult;
export type ResolvedCreateProgramsToolCallResult = {
  name: typeof TOOL_CREATE_PROGRAMS;
  content: {
    user_result: ToolCallUserResult;
    created_programs: ResolvedProgramSummary[];
  };
};

export type ResolvedChatMessage = ChatMessage & {
  aiMessageNumber?: number;
  resolvedToolCallResults?: ResolvedToolCallResult[] | null;
  resolvedToolCalls?: ResolvedToolCall[] | null;
};

export const TOOL_CALL_USER_RESULT_OK = 'OK';
export const TOOL_CALL_USER_RESULT_REJECT = 'REJECT';
export type ToolCallUserResult =
  | typeof TOOL_CALL_USER_RESULT_OK
  | typeof TOOL_CALL_USER_RESULT_REJECT;
export type PromptExtension = {
  resolvedToolCall: ResolvedToolCall;
  userResult: ToolCallUserResult;
};

export type ChatPromptAndAnswer = {
  prompt: ChatMessage;
  promptExtensions?: PromptExtension[];
  answer: ChatMessage | null;
  answerStatus: AnswerMessageStatus;
};

export type Prompt = {
  id: string;
  title: string;
  content: string;
  categories: string[];
  metadata: PromptMetadataDto;
  parsedSpans: LibraryPromptContentPart[];
  parsedContent: string;
};

export type PromptCategory = {
  name: string;
  color: string;
  icon: string | null;
};

export type PromptVariable = {
  id: string | null;
  isPrivate: boolean;
  variableValue: PromptVariableValue;
};

export type PromptVariableBaseValue = {
  name: string;
  type: 'string' | 'list';
};

export const MAX_PROMPT_VALUE_DESCRIPTION_LENGTH = 600;

export type PromptVariableStringValue = PromptVariableBaseValue & {
  type: 'string';
  value: string;
  description: string;
};

export type PromptVariableListValue = PromptVariableBaseValue & {
  type: 'list';
  values: PromptVariableStringValue[];
};

export type PromptVariableStringValueOrAdhoc = PromptVariableStringValue & {
  kind: 'saved' | 'adhoc';
  id: string | null;
};

export type PromptVariableValue = PromptVariableStringValue | PromptVariableListValue;

export type LibraryPromptWizardStep = {
  rawPlaceholder: string;
  isFreeForm: boolean;
  question: string;
  answer?: string;
  variableName: string | null;
  valueOptions: string[] | null;
  metadata: PlaceholderMetadataDto | null;
};

export type PromptRecentUsage = {
  usageId: string | null;
  promptId: string;
  messageId: string | null;
  updatedDate: Date | null;
};

export type PromptMark = {
  promptId: string;
  mark: number;
  updatedDate: Date | null;
};

export const FALLBACK_CATEGORY_COLOR = '#64748B';

export const PREDEFINED_PROMPT_CATEGORIES: PromptCategory[] = [
  {
    name: 'Advertising',
    color: StyleVariables.cyan500,
    icon: 'shopping-bag',
  },
  {
    name: 'Blogging prompts',
    color: StyleVariables.cyan500,
    icon: 'pencil',
  },
  {
    name: 'Book Publishing',
    color: '#84CC16',
    icon: 'book',
  },
  {
    name: 'Brainstorming',
    color: '#F59E0B',
    icon: 'bulb',
  },
  {
    name: 'Content',
    color: '#22C55e',
    icon: 'pencil',
  },
  {
    name: 'Customer pain points',
    color: '#F97316',
    icon: 'grid',
  },
  {
    name: 'Email',
    color: StyleVariables.red500,
    icon: 'envelope',
  },
  {
    name: 'E-Commerce',
    color: '#F97316',
    icon: 'shopping-cart',
  },
  {
    name: 'General',
    color: '#F1F5F9',
    icon: 'grid',
  },
  {
    name: 'Marketing',
    color: '#EC4899',
    icon: 'ai-stars',
  },
  {
    name: 'Real Estate',
    color: '#14B8A6',
    icon: 'house',
  },
  {
    name: 'Rewriting paragraphs',
    color: '#06B6D4',
    icon: 'pencil',
  },
  {
    name: 'Sales',
    color: '#6366F1',
    icon: 'pie-chart',
  },
  {
    name: 'SEO',
    color: '#6366F1',
    icon: 'search',
  },
  {
    name: 'Social media',
    color: '#D946EF',
    icon: 'chat-balloon',
  },
  {
    name: 'Strategy',
    color: '#F59E0B',
    icon: 'arrow-up-right',
  },
  {
    name: 'Trends and Research',
    color: '#10B981',
    icon: 'pie-chart',
  },
  {
    name: 'Website',
    color: '#A855F7',
    icon: 'link',
  },
];
