const PROGRAM_TYPE_ENTITY = 'Program Type';
const PROGRAM_ENTITY = 'Program';
const PROGRAM_ATTACHMENT_ENTITY = 'Program Attachment';
const PROGRAM_FILTER_ENTITY = 'Program Filter';
const INTEGRATION_ENTITY = 'Integration';
const USER_ENTITY = 'User';
const GENERIC_ENTITY = 'Generic';
const BILLING_PORTAL = 'Billing Portal';
const BILLING_PLAN = 'Billing Plan';
const NOTIFICATION_SETTINGS = 'Notification Settings';
const GOOGLE_CALENDAR = 'Google Calendar';
const OUTLOOK = 'Outlook';
const CHAT = 'Chat';
const CHAT_MESSAGE = 'Chat Message';
const PROMPT = 'Prompt';
const SANITY_CHECK = 'Sanity Check';

const ADDED_ACTION = 'Added';
const CREATED_ACTION = 'Created';
const UPDATED_ACTION = 'Updated';
const DELETED_ACTION = 'Deleted';
const APPLIED_ACTION = 'Applied';
const OPENED_ACTION = 'Opened';
const FAILED_ACTION = 'Failed';
const ENABLED_ACTION = 'Enabled';
const DISABLED_ACTION = 'Disabled';
const CONNECTED_ACTION = 'Connected';
const DISCONNECTED_ACTION = 'Disconnected';
const DOWNLOADED_ACTION = 'Downloaded';

export class AnalyticsEvent {
  public static readonly PROGRAM_CREATED = new AnalyticsEvent(PROGRAM_ENTITY, CREATED_ACTION);

  public static readonly PROGRAM_UPDATED = new AnalyticsEvent(PROGRAM_ENTITY, UPDATED_ACTION);

  public static readonly PROGRAM_DELETED = new AnalyticsEvent(PROGRAM_ENTITY, DELETED_ACTION);

  public static readonly PROGRAM_REPLACED_FROM_CSV = new AnalyticsEvent(
    PROGRAM_ENTITY,
    'Replaced from CSV'
  );

  public static readonly PROGRAM_DOWNLOADED_AS_CSV = new AnalyticsEvent(
    PROGRAM_ENTITY,
    'Downloaded as CSV'
  );

  public static readonly PROGRAM_CLICKED_NEW = new AnalyticsEvent(PROGRAM_ENTITY, 'Clicked New');

  public static readonly PROGRAM_ATTACHMENT_ADDED = new AnalyticsEvent(
    PROGRAM_ATTACHMENT_ENTITY,
    ADDED_ACTION
  );

  public static readonly PROGRAM_ATTACHMENT_DELETED = new AnalyticsEvent(
    PROGRAM_ATTACHMENT_ENTITY,
    DELETED_ACTION
  );

  public static readonly PROGRAM_ATTACHMENT_DOWNLOADED = new AnalyticsEvent(
    PROGRAM_ATTACHMENT_ENTITY,
    DOWNLOADED_ACTION
  );

  public static readonly PROGRAM_FILTER_OPENED = new AnalyticsEvent(
    PROGRAM_FILTER_ENTITY,
    OPENED_ACTION
  );

  public static readonly PROGRAM_FILTER_ADDED = new AnalyticsEvent(
    PROGRAM_FILTER_ENTITY,
    ADDED_ACTION
  );

  public static readonly PROGRAM_FILTER_APPLIED = new AnalyticsEvent(
    PROGRAM_FILTER_ENTITY,
    APPLIED_ACTION
  );

  public static readonly GENERIC_PERIOD_CHANGED = new AnalyticsEvent(
    GENERIC_ENTITY,
    'Period Changed'
  );

  public static readonly INTEGRATION_UPDATED = new AnalyticsEvent(
    INTEGRATION_ENTITY,
    UPDATED_ACTION
  );

  public static readonly INTEGRATION_HELP_VIEWED = new AnalyticsEvent(
    INTEGRATION_ENTITY,
    'Help Viewed'
  );

  public static readonly PROGRAM_TYPE_CREATED = new AnalyticsEvent(
    PROGRAM_TYPE_ENTITY,
    CREATED_ACTION
  );

  public static readonly PROGRAM_TYPE_UPDATED = new AnalyticsEvent(
    PROGRAM_TYPE_ENTITY,
    UPDATED_ACTION
  );

  public static readonly PROGRAM_TYPE_DELETED = new AnalyticsEvent(
    PROGRAM_TYPE_ENTITY,
    DELETED_ACTION
  );

  public static readonly USER_SIGN_UP_DETAILS_SUBMITTED = new AnalyticsEvent(
    USER_ENTITY,
    'Sign Up Details Submitted'
  );

  public static readonly USER_SIGN_UP_DETAILS_ACCEPTED = new AnalyticsEvent(
    USER_ENTITY,
    'Sign Up Details Accepted'
  );

  public static readonly USER_SIGN_UP_DETAILS_REJECTED = new AnalyticsEvent(
    USER_ENTITY,
    'Sign Up Details Rejected'
  );

  public static readonly USER_SIGN_UP_EMAIL_VERIFICATION_SUBMITTED = new AnalyticsEvent(
    USER_ENTITY,
    'Sign Up Email Verification Submitted'
  );

  public static readonly USER_SIGN_UP_EMAIL_VERIFIED = new AnalyticsEvent(
    USER_ENTITY,
    'Sign Up Email Verified'
  );

  public static readonly USER_SIGN_UP_EMAIL_VERIFICATION_FAILED = new AnalyticsEvent(
    USER_ENTITY,
    'Sign Up Email Verification Failed'
  );

  public static readonly USER_SIGN_UP_EMAIL_VERIFICATION_RESENT = new AnalyticsEvent(
    USER_ENTITY,
    'Sign Up Email Verification Resent'
  );

  public static readonly USER_SIGNED_UP = new AnalyticsEvent(USER_ENTITY, 'Signed Up');

  public static readonly USER_SIGNED_IN = new AnalyticsEvent(USER_ENTITY, 'Signed In');

  public static readonly USER_CREATED = new AnalyticsEvent(USER_ENTITY, CREATED_ACTION);

  public static readonly USER_UPDATED = new AnalyticsEvent(USER_ENTITY, UPDATED_ACTION);

  public static readonly USER_DELETED = new AnalyticsEvent(USER_ENTITY, DELETED_ACTION);

  public static readonly USER_PASSWORD_CHANGED = new AnalyticsEvent(
    USER_ENTITY,
    'Password Changed'
  );

  public static readonly USER_SIGNED_OUT = new AnalyticsEvent(USER_ENTITY, 'Signed Out');

  public static readonly BILLING_PORTAL_OPENED = new AnalyticsEvent(BILLING_PORTAL, OPENED_ACTION);

  public static readonly BILLING_PORTAL_FAILED = new AnalyticsEvent(BILLING_PORTAL, FAILED_ACTION);

  public static readonly EMAIL_NOTIFICATIONS_ENABLED = new AnalyticsEvent(
    NOTIFICATION_SETTINGS,
    ENABLED_ACTION
  );

  public static readonly EMAIL_NOTIFICATIONS_DISABLED = new AnalyticsEvent(
    NOTIFICATION_SETTINGS,
    DISABLED_ACTION
  );

  public static readonly GOOGLE_CALENDAR_CONNECTED = new AnalyticsEvent(
    GOOGLE_CALENDAR,
    CONNECTED_ACTION
  );

  public static readonly GOOGLE_CALENDAR_DISCONNECTED = new AnalyticsEvent(
    GOOGLE_CALENDAR,
    DISCONNECTED_ACTION
  );

  public static readonly GOOGLE_CALENDAR_MISSING_PERMISSIONS = new AnalyticsEvent(
    GOOGLE_CALENDAR,
    'Missing Permissions'
  );

  public static readonly GOOGLE_CALENDAR_HELP_VIEWED = new AnalyticsEvent(
    GOOGLE_CALENDAR,
    'Help Viewed'
  );

  public static readonly GOOGLE_CALENDAR_RESYNC_SCHEDULED = new AnalyticsEvent(
    GOOGLE_CALENDAR,
    'Resync Scheduled'
  );

  public static readonly OUTLOOK_CONNECTED = new AnalyticsEvent(OUTLOOK, CONNECTED_ACTION);

  public static readonly OUTLOOK_DISCONNECTED = new AnalyticsEvent(OUTLOOK, DISCONNECTED_ACTION);

  public static readonly OUTLOOK_FAILED_TO_CONSENT = new AnalyticsEvent(
    OUTLOOK,
    'Failed to consent'
  );

  public static readonly OUTLOOK_CONNECTION_FAILED = new AnalyticsEvent(
    OUTLOOK,
    'Connection failed'
  );

  public static readonly OUTLOOK_HELP_VIEWED = new AnalyticsEvent(OUTLOOK, 'Help Viewed');

  public static readonly OUTLOOK_RESYNC_SCHEDULED = new AnalyticsEvent(OUTLOOK, 'Resync Scheduled');

  public static readonly CHAT_CREATED = new AnalyticsEvent(CHAT, CREATED_ACTION);

  public static readonly CHAT_RENAMED = new AnalyticsEvent(CHAT, 'Renamed');

  public static readonly CHAT_DELETED = new AnalyticsEvent(CHAT, DELETED_ACTION);

  public static readonly CHAT_MESSAGE_SENT = new AnalyticsEvent(CHAT_MESSAGE, 'Sent');

  public static readonly CHAT_MESSAGE_CANCELLED = new AnalyticsEvent(CHAT_MESSAGE, 'Cancelled');

  public static readonly PROMPT_SELECTED = new AnalyticsEvent(PROMPT, 'Selected');

  public static readonly BILLING_INVOICE_OPEN = new AnalyticsEvent(BILLING_PORTAL, 'Invoice Open');

  public static readonly BILLING_INVOICE_LIST_OPENED = new AnalyticsEvent(
    BILLING_PORTAL,
    'Invoice List Opened'
  );

  public static readonly BILLING_BILLING_INFORMATION_CHANGE_OPEN = new AnalyticsEvent(
    BILLING_PORTAL,
    'Change billing information open'
  );

  public static readonly BILLING_CARD_ADD_NEW_CLICKED = new AnalyticsEvent(
    BILLING_PORTAL,
    'Add new card open'
  );

  public static readonly BILLING_CARD_MANAGE = new AnalyticsEvent(
    BILLING_PORTAL,
    'Manage card open'
  );

  public static readonly BILLING_PLAN_CANCELED = new AnalyticsEvent(BILLING_PLAN, 'Canceled');

  public static readonly BILLING_PLAN_ANNUAL_PLAN_VIEWED = new AnalyticsEvent(
    BILLING_PLAN,
    'Annual plan viewed'
  );

  public static readonly BILLING_PLAN_UPGRADE_CONFIRMATION_VIEWED = new AnalyticsEvent(
    BILLING_PLAN,
    'Upgrade confirmation viewed'
  );

  public static readonly BILLING_PLAN_UPGRADE_CONFIRMATION_CANCELLED = new AnalyticsEvent(
    BILLING_PLAN,
    'Upgrade confirmation cancelled'
  );

  public static readonly BILLING_PLAN_UPGRADED = new AnalyticsEvent(BILLING_PLAN, 'Upgraded');

  public static readonly BILLING_PLAN_UPGRADE_FAILED = new AnalyticsEvent(
    BILLING_PLAN,
    'Upgrade failed'
  );

  public static readonly SANITY_CHECK_FAILED = new AnalyticsEvent(SANITY_CHECK, FAILED_ACTION);

  private readonly eventEntity: string;

  private readonly eventAction: string;

  constructor(entity: string, action: string) {
    this.eventEntity = entity;
    this.eventAction = action;
  }

  get entity(): string {
    return this.eventEntity;
  }

  get action(): string {
    return this.eventAction;
  }
}
