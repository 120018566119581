import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import _, { compact } from 'lodash';
import { useMutation } from '@tanstack/react-query';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin, { EventReceiveArg } from '@fullcalendar/interaction';
import listPlugin from '@fullcalendar/list';
import { DayHeaderContentArg, EventAddArg, EventApi, EventClickArg } from '@fullcalendar/core';
import useApi from '../../api/backendApiContext';
import {
  ExistedCustomFields,
  NoCampaign,
  ProgramKind,
  ProgramRow,
  ProgramSummary,
  ProgramTask,
  ProgramTaskChange,
  ResolvedProgramFields,
  ResolvedProgramNameField,
  ResolvedProgramSummary,
  ResolvedProgramTask,
} from '../types';
import { errorToString, newRenderId } from '../../util/utils';
import { useQueryCacheManager } from '../../hooks/useQueryCacheManager';
import { useResolvedProgramSummaries } from '../hooks/useResolvedProgramSummaries';
import ProgramDialog, { ModalState } from './ProgramDialog';
import {
  areCustomFieldsSame,
  CalendarLocalStorageKey,
  extractConsecutiveMonths,
  extractExistedCustomFields,
  flatMapUnique,
  getEmptyProgramSummary,
  getProgramPatchMessage,
} from '../util';
import useAuth from '../../auth/authContext';
import { useNotification } from '../../notification/notificationsContext';
import { MultiFilterRowsPersistable } from '../../multifilter/types';
import { ProgramsMultiFilter } from '../components/ProgramsMultiFilter';
import { useMemoCompare } from '../../hooks/useMemoCompare';
import CalendarDisplaySettings from '../components/calendar/CalendarDisplaySettings';
import { useLocalStorage } from '@rehooks/local-storage';
import { CollapseRight, DividerIcon, ExpandRight } from '../../assets/icons';
import { SummaryErrors, UnscheduledPrograms } from '../components/calendar/UnscheduledPrograms';
import useAnalyticsPage from '../../web-analytics/hooks/useAnalyticsPage';
import { Page } from '../../web-analytics/Page';
import useAnalytics from '../../web-analytics/webAnalyticsContext';
import { analyticsTrack } from '../../web-analytics/webAnalytics';
import { AnalyticsEvent } from '../../web-analytics/AnalyticsEvent';
import {
  getNewDatesAfterDrag,
  quarterDateToNormalDate,
  startDateToQuarterDate,
} from '../components/calendar/quarterTimeTransformations';
import {
  CalendarEventExtendedProps,
  CalendarEventType,
  CalendarViewType,
  ColumnsConfig,
  EventCompare,
} from './types';
import {
  CampaignDataMarker,
  ProgramCalendarEventContent,
} from '../components/calendar/ProgramCalendarEventContent';
import { TaskCalendarEvent } from '../components/calendar/TaskCalendarEvent';
import moment from 'moment';
import { useResolvedCampaigns } from '../hooks/useResolvedCampaigns';
import { AddNewProgram } from '../components/programs-table/AddNewProgram';
import { programsToEvents } from '../components/calendar/calendarEventFactory';
import { compareCalendarEvents } from '../components/calendar/calendarEventComparison';
import {
  DateRange,
  DateRangeSelector,
  getDateRange,
} from '../../commons/components/DateRangeSelector';
import { GroupingType, ProgramsGroupingSelector } from '../components/ProgramsGroupingSelector';
import {
  CampaignCalendarEvent,
  CampaignToggleDataMarker,
} from '../components/calendar/CampaignCalendarEvent';
import { StyleVariables } from '../../commons/styleConstants';
import { filterResolvedPrograms, SelectedFilter } from '../../multifilter/programFiltering';
import { useProgramsDatesNavigation } from '../hooks/useProgramsDatesNavigation';
import {
  unscheduledEnd,
  unscheduledProgramsMonths,
  unscheduledStart,
} from '../unscheduledProgramUtil';
import { useFetchedProgramTypes } from '../hooks/useFetchedProgramTypes';
import { PleaseRelogin } from '../../auth/PleaseRelogin';
import clsx from 'clsx';
import { useAddProgramPermissionHelper } from '../programPermissionHelper';
import { VerboseFormattingArg } from '@fullcalendar/core/internal';
import { PeriodType } from '../../commons/components/PeriodPicker';
import { recalculateDueDates, recalculateFormula } from '../components/tasks/smartDueDateUtils';
import { formatDateTimeCell, utcDateAsLocal } from '../../util/date-utils';
import { ProgramsChat } from '../components/ai/ProgramsChat';
import { growegyAIConfig } from '../../commons/config';
import { Permission } from '../../auth/permissions';
import { useIsTabletOrLarger } from '../../responsive/hooks/hooks';

const TotalMonths = 5;

type DisplayConfig = {
  showCampaigns: boolean | null;
  showTasks: boolean;
};

const getProgramTaskFields: () => {
  title: string;
  id: keyof ResolvedProgramTask;
  view: CalendarViewType | 'all';
}[] = () => [
  { title: 'Due date', id: 'dueDateTime', view: 'monthGridQuarter' },
  { title: 'Assignee', id: 'owner', view: 'all' },
];

const joinTaskColumnsTitles = (columns: ColumnsConfig, viewType: CalendarViewType) => {
  const programTaskFields = getProgramTaskFields().filter(
    ({ view }) => view === 'all' || view === viewType
  );
  return columns.flatMap(({ id, visible }) => {
    const field = programTaskFields.find((t) => id === t.id);
    return field ? [{ id: field.id, visible, title: field.title }] : [];
  });
};

const formatQuarterViewTitle = (arg: VerboseFormattingArg) => {
  const start = moment(arg.start);
  const end = moment(start).add(2, 'months');
  return start.year() !== end.year()
    ? `${start.format('MMM YY')} - ${end.format('MMM YY')}`
    : `${start.format('MMM')} - ${end.format('MMM YY')}`;
};

const formatQuarterViewHeader = (date: Date) => {
  const monthDate = moment(date)
    .add(date.getDate() - 1, 'months')
    .startOf('month')
    .toDate();
  return moment(monthDate).format('MMMM');
};

const GrowegyCalendarViews = [
  'dayGridMonth',
  'dayGridWeek',
  'dayGridDay',
  'timeGridWeek',
  'timeGridDay',
  'monthGridQuarter',
] as const;

type GrowegyCalendarView = (typeof GrowegyCalendarViews)[number];

const getDayHeaderContent = (arg: DayHeaderContentArg) => {
  const type = arg.view.type as CalendarViewType;
  if (type === 'monthGridQuarter') {
    return <div className="growegy-title12--em">{formatQuarterViewHeader(arg.date)}</div>;
  }
  if (type === 'dayGridMonth') {
    return (
      <div className="growegy-title12--em">
        {formatDateTimeCell(utcDateAsLocal(arg.date), 'ddd')}
      </div>
    );
  }
  if (type === 'dayGridWeek' || type === 'timeGridWeek') {
    return (
      <div className="d-flex align-items-center">
        <div className="growegy-title12--em">{formatDateTimeCell(arg.date, 'ddd')}</div>
        <div className="calendar__column-header-day-value growegy-title12--em">
          {formatDateTimeCell(arg.date, 'D')}
        </div>
      </div>
    );
  }

  if (type === 'timeGridDay' || type === 'dayGridDay') {
    return (
      <div className="d-flex align-items-center">
        <div className="growegy-title12--em">{formatDateTimeCell(arg.date, 'dddd')}</div>
        <div className="calendar__column-header-day-value growegy-title12--em">
          {formatDateTimeCell(arg.date, 'D')}
        </div>
      </div>
    );
  }

  return null;
};

const nonGroupingViews: Record<GrowegyCalendarView, never> = {
  dayGridMonth: { buttonText: 'Month' },
  timeGridWeek: { buttonText: 'Week' },
  timeGridDay: { buttonText: 'Day' },
  monthGridQuarter: {
    type: 'dayGrid',
    buttonText: 'Quarter',
    duration: { days: 3 },
    titleFormat: formatQuarterViewTitle,
    dateIncrement: { months: 1 },
    dateAlignment: 'month',
  },
} as Record<GrowegyCalendarView, never>;

const groupingViews: Record<GrowegyCalendarView, never> = {
  dayGridMonth: { buttonText: 'Month' },
  dayGridWeek: { buttonText: 'Week' },
  dayGridDay: { buttonText: 'Day' },
  monthGridQuarter: {
    type: 'dayGrid',
    buttonText: 'Quarter',
    duration: { days: 3 },
    titleFormat: formatQuarterViewTitle,
    dateIncrement: { months: 1 },
    dateAlignment: 'month',
  },
} as Record<GrowegyCalendarView, never>;

function recursiveBubbleAttributeExploring(
  attributeName: string,
  node: (Node & ParentNode) | null | undefined
): string | null {
  if (!node) return null;
  const attribute = (node as HTMLElement)?.attributes?.getNamedItem(attributeName);
  if (attribute) {
    return attribute.value;
  }
  return recursiveBubbleAttributeExploring(attributeName, node.parentNode);
}

const findClickedDaySlot = (jsEvent: MouseEvent): string | null => {
  const parentNode: (Node & ParentNode) | null | undefined = (jsEvent.target as HTMLElement)
    ?.parentNode;
  return recursiveBubbleAttributeExploring('data-date', parentNode);
};

const findClickedCampaignDiv = ({
  campaignId,
  dataDate,
}: {
  campaignId: string;
  dataDate: string | null;
}) => {
  if (dataDate === null) {
    return document.querySelector(`[data-campaign="${campaignId}"]`);
  }
  const daySlots = document.querySelectorAll(`[data-date="${dataDate}"]`);
  const calendarSlotDiv = Array.from(daySlots.values()).find((daySlot) =>
    daySlot.querySelector(`[data-campaign="${campaignId}"]`)
  );

  if (calendarSlotDiv) {
    return calendarSlotDiv.querySelector(`[data-campaign="${campaignId}"]`);
  }
  return calendarSlotDiv;
};

const periodTypeToViewType = (period: PeriodType) => {
  switch (period) {
    case 'Quarter':
      return 'monthGridQuarter';
    case 'Month':
      return 'dayGridMonth';
    case 'Week':
      return 'timeGridWeek';
    case 'Day':
      return 'timeGridDay';
    default:
      return 'dayGridMonth';
  }
};

const applyNewDates = (
  calendarEventType: CalendarEventType,
  program: ProgramSummary,
  task: ProgramTask | null,
  newStart: Date,
  newEnd: Date,
  wholeDay: boolean
): {
  updatedProgram: ProgramSummary;
  taskChanges: ProgramTaskChange[] | null;
} | null => {
  let updatedProgram: ProgramSummary;
  let taskChanges: ProgramTaskChange[] | null;

  switch (calendarEventType) {
    case CalendarEventType.program:
      const newTaskSnapshot = recalculateDueDates(newStart, newEnd, wholeDay, program.tasks, false);
      updatedProgram = {
        ...program,
        startDateTime: newStart,
        endDateTime: newEnd,
        wholeDay,
        tasks: newTaskSnapshot ?? program.tasks,
      };

      const changedTasks = recalculateDueDates(newStart, newEnd, wholeDay, program.tasks, true);
      taskChanges =
        changedTasks !== null
          ? changedTasks.map((t) => ({ changeType: 'UPDATE' as const, task: t }))
          : null;
      break;
    case CalendarEventType.task:
      updatedProgram = {
        ...program,
        tasks: program.tasks.map((t) => ({
          ...t,
          dueDateTime: t.id === task?.id ? newStart : t.dueDateTime,
        })),
      };

      const loadedTask = program.tasks.find((t) => t.id === task?.id);
      taskChanges = !!loadedTask
        ? [
            {
              changeType: 'UPDATE',
              task: {
                ...loadedTask,
                dueDateTime: newStart,
                dueDateFormula: loadedTask.dueDateFormula
                  ? recalculateFormula(
                      newStart,
                      program.startDateTime,
                      program.endDateTime,
                      program.wholeDay,
                      loadedTask.dueDateFormula,
                      loadedTask.status
                    )
                  : null,
              },
            },
          ]
        : null;
      break;
    default:
      return null;
  }
  return { updatedProgram, taskChanges };
};

const ProgramCalendar = () => {
  useAnalyticsPage(Page.PROGRAMS_CALENDAR);
  const analytics = useAnalytics();

  const { programApi } = useApi();
  const {
    state: { user },
  } = useAuth();
  const isTabletOrLarger = useIsTabletOrLarger();
  const addProgramPermissionHelper = useAddProgramPermissionHelper();
  const programCache = useQueryCacheManager();
  const { notifySuccess, notifyError, notifyWarn } = useNotification();

  const renderId = useCallback(() => newRenderId(), []);
  const [filter, setFilter] = useLocalStorage<SelectedFilter | null>(
    `${CalendarLocalStorageKey}appliedFilter`,
    null
  );
  const [persistedFilters, setPersistedFilters] = useLocalStorage<
    MultiFilterRowsPersistable<ResolvedProgramSummary>[]
  >(`${CalendarLocalStorageKey}savedFilters`, []);
  const existedTypes = useFetchedProgramTypes();
  const existedCampaigns = useResolvedCampaigns();

  const defaultViewType = 'dayGridMonth';
  const [viewType, setViewType] = useState<CalendarViewType>(defaultViewType);
  const [grouping, setGrouping] = useLocalStorage<GroupingType>(
    `${CalendarLocalStorageKey}campaignGrouping`,
    'No grouping'
  );

  const setGroupingAndRemoveEvents = (newGrouping: GroupingType | null) => {
    if (grouping === newGrouping) return;
    if (calendarRef.current) {
      const api = calendarRef.current.getApi();
      api.removeAllEvents();
    }
    setGrouping(newGrouping);
  };

  const [expandedCampaigns, setExpandedCampaigns] = useState<Set<string>>(new Set());
  const [clickedCampaign, setClickedCampaign] = useState<{
    campaignId: string;
    dataDate: string | null;
  } | null>(null);

  useEffect(() => {
    if (clickedCampaign) {
      const closureCopy = _.cloneDeep(clickedCampaign);
      setClickedCampaign(null);
      setTimeout(() => {
        if (calendarRef.current) {
          const campaignDiv = findClickedCampaignDiv(closureCopy);
          if (campaignDiv) {
            campaignDiv.scrollIntoView({ behavior: 'auto', block: 'center' });
            campaignDiv.animate({ backgroundColor: StyleVariables.grayAlpha16 }, 5000);
          } else {
            console.warn(`Campaign div to be scrolled to is not found: `, closureCopy);
          }
        }
      }, 100);
    }
  }, [clickedCampaign]);

  const onGroupingChanged = (v: GroupingType) => {
    if (grouping !== v) {
      setExpandedCampaigns(new Set());
    }
    if (v === 'By campaign')
      setDisplayConfig({
        ...displayConfig,
        showCampaigns: false,
      });
    else
      setDisplayConfig({
        ...displayConfig,
        showCampaigns: true,
      });

    setGroupingAndRemoveEvents(v);
  };

  const views = useMemo(
    () => (grouping === 'No grouping' ? nonGroupingViews : groupingViews),
    [grouping]
  );

  const onCampaignToggle = (
    campaignId: string,
    event: React.MouseEvent<HTMLDivElement, MouseEvent>
  ) => {
    if (grouping === 'No grouping') return;
    setExpandedCampaigns((expanded) => {
      if (expanded.has(campaignId)) expanded.delete(campaignId);
      else expanded.add(campaignId);
      return new Set<string>(expanded.values());
    });
    const clickedDate = findClickedDaySlot(event.nativeEvent);
    if (clickedDate) setClickedCampaign({ dataDate: clickedDate, campaignId });
    else if (viewType === 'timeGridDay' || viewType === 'dayGridDay') {
      setClickedCampaign({ dataDate: null, campaignId });
    }
  };

  const { calendarPeriodDateRange: periodDateRange, setPeriodDateRange } =
    useProgramsDatesNavigation();

  const [monthsStart, setMonthsStart] = useState<{ quarterBasis: Date; months: Date[] }>(() => {
    const dateRange = getDateRange(periodDateRange);
    return {
      quarterBasis: moment(periodDateRange.currentDate).startOf('month').toDate(),
      months: extractConsecutiveMonths(dateRange.firstDay, dateRange.lastDay, TotalMonths),
    };
  });

  const {
    data: summaryQueryResults,
    error: summaryQueryError,
    status,
  } = useResolvedProgramSummaries({
    monthsStart: monthsStart.months.concat(unscheduledProgramsMonths),
  });

  const nonFilteredPrograms = useMemo(
    () =>
      status === 'loading'
        ? []
        : flatMapUnique(
            summaryQueryResults
              .filter((s) => s.data)
              .sort((a, b) => a.data!.startMonth.valueOf() - b.data!.startMonth.valueOf())
              .map((s) => s.data!.summaries),
            (s) => s.id
          ),
    [status, summaryQueryResults]
  );

  const existedCustomFields = useMemoCompare<ExistedCustomFields>(
    extractExistedCustomFields(nonFilteredPrograms),
    areCustomFieldsSame
  );

  const programs: ProgramRow[] = useMemo(
    () => filterResolvedPrograms(nonFilteredPrograms, filter),
    [filter, nonFilteredPrograms]
  );

  const wipeOutNoCampaignId = (campaignId: string | null) => {
    if (campaignId === NoCampaign.id) return null;
    return campaignId;
  };

  const { mutate: updateMutation } = useMutation(
    async (variables: {
      calendarEventType: CalendarEventType;
      program: ProgramSummary;
      task?: ProgramTask;
      newStart: Date;
      newEnd: Date;
      wholeDay: boolean;
    }) => {
      const { calendarEventType, program, task, newStart, newEnd, wholeDay } = variables;
      if (
        calendarEventType !== CalendarEventType.program &&
        calendarEventType !== CalendarEventType.task
      )
        return null;

      const loadedProgram = await programApi.getProgram(program.id);
      const result = applyNewDates(
        calendarEventType,
        loadedProgram,
        task ?? null,
        newStart,
        newEnd,
        wholeDay
      );
      if (!result) return null;
      const { updatedProgram, taskChanges } = result;

      const programParam = calendarEventType === CalendarEventType.program ? updatedProgram : null;
      return {
        response: await programApi.patchProgram(
          updatedProgram.id,
          updatedProgram.version!,
          programParam,
          taskChanges
        ),
        programChanged: programParam !== null,
        taskChanges,
      };
    },
    {
      onMutate: (variables) => {
        const { calendarEventType, program, task, newStart, newEnd, wholeDay } = variables;

        const result = applyNewDates(
          calendarEventType,
          program,
          task ?? null,
          newStart,
          newEnd,
          wholeDay
        );
        const version = program.version === undefined ? undefined : program.version + 1;
        if (!result) return null;
        const { updatedProgram } = result;

        return {
          cacheBeforeUpdate: programCache.updateOptimistically(
            {
              ...program,
              campaignId: wipeOutNoCampaignId(program.campaignId),
            },
            {
              ...updatedProgram,
              version,
              campaignId: wipeOutNoCampaignId(updatedProgram.campaignId),
            }
          ),
        };
      },
      onError: (err, variables, context) => {
        const { newStart, newEnd, program } = variables;
        if (context) programCache.rollback(context.cacheBeforeUpdate);
        notifyError({
          err,
          notificationMsg: `Failed to update program`,
          logMsg: `Program calendar failed to update program ${program.id} ${
            program.name
          } from ${newStart.toISOString()} to ${newEnd.toISOString()}`,
        });
      },
      onSuccess: (result, { program }) => {
        if (!result) return;
        const {
          programChanged,
          response: { changedProgram, parentCampaign, oldParentCampaign },
          taskChanges,
        } = result;
        programCache.onUpdateSuccess(changedProgram);
        if (parentCampaign) programCache.onCampaignUpdate(parentCampaign);
        if (oldParentCampaign) programCache.onCampaignUpdate(oldParentCampaign);
        notifySuccess({
          notificationMsg: getProgramPatchMessage(program.programKind, programChanged, taskChanges),
          logMsg: `${program.programKind === 'CAMPAIGN' ? 'Campaign' : 'Program'} ${
            program.name
          } is updated from calendar.`,
        });
      },
    }
  );

  const onEditChange = async (newEvent: EventApi, oldEvent: EventApi, revert: () => void) => {
    if (newEvent.start && oldEvent.start && newEvent.end && oldEvent.end) {
      const oldEventExtendedProps = oldEvent.extendedProps;
      const eventProps = oldEventExtendedProps as CalendarEventExtendedProps;
      if (
        eventProps.calendarEventType !== CalendarEventType.program &&
        eventProps.calendarEventType !== CalendarEventType.task
      )
        return;
      const {
        type: { id: typeId },
        resolvedTasks,
        ...rest
      } = eventProps.origResolvedSummary;
      const task =
        eventProps.calendarEventType === CalendarEventType.task ? eventProps.origTask : undefined;

      let newStart: Date;
      let newEnd: Date;
      let newWholeDay: boolean;
      if (viewType === 'monthGridQuarter') {
        ({ newStart, newEnd } = getNewDatesAfterDrag(
          {
            start: rest.startDateTime,
            end: rest.endDateTime,
            wholeDay: rest.wholeDay,
          },
          {
            quarterBasis: monthsStart.quarterBasis,
            newStart: newEvent.start!,
            newEnd: newEvent.end!,
          }
        ));
        newWholeDay = rest.wholeDay;
      } else {
        newStart = newEvent.start;
        newEnd = newEvent.end;
        newWholeDay = newEvent.allDay;
      }

      await updateMutation({
        calendarEventType: eventProps.calendarEventType,
        program: {
          ...rest,
          typeId,
          status: rest.status.id,
          tasks: [...resolvedTasks],
          campaignId: rest.campaign !== null ? rest.campaign.id : null,
        },
        task,
        newStart,
        newEnd,
        wholeDay: newWholeDay,
      });
    } else {
      notifyWarn({
        logMsg: `ProgramCalendar onEditChange reverted: oldEvent id ${oldEvent.id}, oldEvent title ${oldEvent.title}, oldEvent range [${oldEvent.start} - ${oldEvent.end}], newEvent range [${newEvent.start} - ${newEvent.end}]`,
      });
      revert();
    }
  };

  const onEventAdd = (arg: EventAddArg) => {
    if (arg.event.start === null) return;

    const extendedProps = arg.event.extendedProps as CalendarEventExtendedProps;
    if (
      extendedProps.calendarEventType !== CalendarEventType.program &&
      extendedProps.calendarEventType !== CalendarEventType.task
    )
      return;
    const startDatesByEventId = _(
      extendedProps.calendarEventType === CalendarEventType.program
        ? programs.map((p) => ({ id: p.id, startDateTime: p.startDateTime }))
        : programs
            .flatMap((p) => p.resolvedTasks)
            .map((t) => ({ id: t.id, startDateTime: t.dueDateTime }))
    )
      .groupBy((p) => p.id)
      .value();
    const sameIdStartDates = startDatesByEventId[arg.event.id];
    const found =
      Array.isArray(sameIdStartDates) &&
      !!sameIdStartDates.find(
        (p) => p.startDateTime && p.startDateTime.valueOf() === arg.event.start!.valueOf()
      );
    if (!found) {
      arg.event.remove();
    }
  };

  const [modalState, setModalState] = useState<ModalState>(null);

  const onCreateProgram = (program: ProgramSummary) => {
    setModalState({
      action: 'CREATE',
      program,
      scrollToTaskId: null,
      taskToDeleteOnCreate: null,
    });
  };

  const createProgram = (arg: {
    start: Date | null;
    end: Date | null;
    wholeDay: boolean;
    isScheduled?: boolean;
    typeId?: string;
    campaignId?: string | null;
    programKind?: ProgramKind;
  }) => {
    const { start, end, wholeDay, isScheduled, typeId, campaignId, programKind } = arg;
    setModalState({
      action: 'CREATE',
      program: getEmptyProgramSummary(
        typeId ?? '',
        wholeDay,
        start,
        end,
        user ? user.userId : null,
        isScheduled ?? true,
        programKind ?? 'PROGRAM',
        campaignId ?? null
      ),
      scrollToTaskId: null,
      taskToDeleteOnCreate: null,
    });
  };

  const editProgram = (
    program: ResolvedProgramSummary,
    task: ResolvedProgramTask | null = null
  ) => {
    if (program.programKind === 'CAMPAIGN' && program.id === NoCampaign.id) return;
    setModalState({
      action: 'UPDATE',
      program: {
        ...program,
        typeId: program.type.id,
        campaignId: program.campaign !== null ? program.campaign.id : null,
        status: program.status.id,
        tasks: [...program.resolvedTasks.map((t) => ({ ...t }))],
      },
      scrollToTaskId: task !== null ? task.id : null,
      taskToDeleteOnCreate: null,
    });
  };

  const allColumns = useMemo(
    () => [
      ...ResolvedProgramFields.filter((c) => c !== ResolvedProgramNameField),
      ...Array.from(existedCustomFields.strings).map((fieldName) => `${fieldName}-text`),
      ...Array.from(existedCustomFields.numbers).map((fieldName) => `${fieldName}-number`),
      ...Array.from(existedCustomFields.dates).map((fieldName) => `${fieldName}-date-time`),
      ...Array.from(existedCustomFields.formulas).map((fieldName) => `${fieldName}-formula`),
    ],
    [existedCustomFields]
  );

  const [displayConfig, setDisplayConfig] = useLocalStorage<DisplayConfig>(
    `${CalendarLocalStorageKey}display`,
    {
      showCampaigns: true,
      showTasks: true,
    }
  );

  const refreshedEvents = useMemo(
    () =>
      programsToEvents(
        programs,
        grouping === 'By campaign' ? existedCampaigns.data ?? [] : [],
        expandedCampaigns,
        existedTypes.data?.types ?? [],
        displayConfig.showTasks,
        viewType,
        monthsStart.quarterBasis,
        grouping === 'By campaign',
        user!!
      ),
    [
      displayConfig,
      viewType,
      monthsStart,
      existedTypes,
      programs,
      grouping,
      existedCampaigns,
      expandedCampaigns,
      user,
    ]
  );
  const events = useMemoCompare(
    refreshedEvents,
    (a, b) =>
      status === 'loading' ||
      (grouping === 'By campaign' && existedCampaigns.status === 'loading') ||
      _.isEqual(a, b)
  );

  const onShowHideCampaigns = (visible: boolean) => {
    setDisplayConfig({
      ...displayConfig,
      showCampaigns: visible,
    });
  };

  const onShowHideTasks = (visible: boolean) => {
    setDisplayConfig({
      ...displayConfig,
      showTasks: visible,
    });
  };

  const [columnsConfig, setColumnsConfig] = useLocalStorage<ColumnsConfig>(
    `${CalendarLocalStorageKey}columns`,
    allColumns
      .filter((c) => c !== ResolvedProgramNameField)
      .map((c) => ({
        id: c as string,
        visible: false,
      }))
  );

  const columnsConfigToShow = useMemo(
    () => columnsConfig.filter((c) => allColumns.find((column) => column === c.id)),
    [columnsConfig, allColumns]
  );

  const [taskColumnsConfig, setTaskColumnsConfig] = useLocalStorage<ColumnsConfig>(
    `${CalendarLocalStorageKey}task-columns`,
    getProgramTaskFields().map((c) => ({
      id: c.id,
      visible: false,
    }))
  );

  useEffect(() => {
    const newColumns = allColumns.filter(
      (col) => !columnsConfig.find((colConfig) => colConfig.id === col)
    );

    if (newColumns.length || columnsConfig.find((c) => c.id === ResolvedProgramNameField))
      setColumnsConfig([
        ...columnsConfig.filter((c) => c.id !== ResolvedProgramNameField),
        ...newColumns.map((col) => ({
          id: col as string,
          visible: false,
        })),
      ]);
  }, [allColumns, columnsConfig, setColumnsConfig]);

  const visibleColumns = compact(
    columnsConfig.map(
      (colConfig) => colConfig.visible && allColumns.find((column) => column === colConfig.id)
    )
  );

  const visibleTaskColumns: {
    title: string;
    id: keyof ResolvedProgramTask;
    view: CalendarViewType | 'all';
  }[] = taskColumnsConfig.flatMap((colConfig) => {
    const field = getProgramTaskFields().find(({ id }) => id === colConfig.id);
    return colConfig.visible && field && (field.view === 'all' || field.view === viewType)
      ? [field]
      : [];
  });

  const onShowHideColumn = (key: string | null, visible: boolean) => {
    setColumnsConfig(columnsConfig.map((col) => (col.id === key ? { ...col, visible } : col)));
  };

  const onShowHideTaskColumn = (key: keyof ResolvedProgramTask, visible: boolean) => {
    const configColumn = taskColumnsConfig.find(({ id }) => id === key);
    const updatedConfig = configColumn
      ? taskColumnsConfig.map((col) => (col.id === key ? { ...col, visible } : col))
      : [...taskColumnsConfig, { id: key, visible }];
    setTaskColumnsConfig(updatedConfig);
  };

  const [displayUnscheduled, setDisplayUnscheduled] = useState(false);

  const handleEventReceive = async ({ event }: EventReceiveArg) => {
    const extendedProps = event.extendedProps as CalendarEventExtendedProps;
    if (
      extendedProps.calendarEventType !== CalendarEventType.program &&
      extendedProps.calendarEventType !== CalendarEventType.task
    )
      return;
    const programSummary = extendedProps.origResolvedSummary;
    event.remove();
    if (!event.start || !event.end) return;

    let newStart: Date;
    let newEnd: Date;
    if (viewType === 'monthGridQuarter') {
      const monthStart = quarterDateToNormalDate(monthsStart.quarterBasis, event.start!);
      newStart = monthStart;
      newEnd = moment(monthStart).add(1, 'day').toDate();
    } else {
      newStart = event.start;
      newEnd = event.end;
    }

    const task =
      extendedProps.calendarEventType === CalendarEventType.task
        ? extendedProps.origTask
        : undefined;
    await updateMutation({
      calendarEventType: extendedProps.calendarEventType,
      program: {
        ...programSummary,
        typeId: programSummary.type.id,
        campaignId: programSummary.campaign !== null ? programSummary.campaign.id : null,
        status: programSummary.status.id,
        tasks: [...programSummary.resolvedTasks],
      },
      task,
      newStart,
      newEnd,
      wholeDay: event.allDay,
    });
  };

  const makeUnscheduled = async (event: EventApi) => {
    const extendedProps = event.extendedProps as CalendarEventExtendedProps;
    if (extendedProps.calendarEventType !== CalendarEventType.program) return;
    const { origResolvedSummary } = extendedProps;
    const {
      type: { id: typeId },
      resolvedTasks,
      ...rest
    } = origResolvedSummary;
    const start = unscheduledStart(origResolvedSummary.id);
    const end = unscheduledEnd(origResolvedSummary.id);
    await updateMutation({
      calendarEventType: extendedProps.calendarEventType,
      program: {
        ...rest,
        typeId,
        campaignId: rest.campaign !== null ? rest.campaign.id : null,
        status: rest.status.id,
        tasks: [...resolvedTasks],
      },
      newStart: start,
      newEnd: end,
      wholeDay: true,
    });
  };

  const calendarRef = useRef<FullCalendar>(null);

  const onDatesChanged = (period: PeriodType, firstDay: Date, lastDay: Date) => {
    if (!calendarRef.current) return;

    let viewType = periodTypeToViewType(period);
    const api = calendarRef.current.getApi();
    const previousViewType = api.view.type as CalendarViewType;
    if (previousViewType === viewType) {
      api.gotoDate(firstDay);
    } else {
      if (previousViewType === 'monthGridQuarter' || viewType === 'monthGridQuarter') {
        api.removeAllEvents();
      }
      api.changeView(viewType, { start: firstDay, end: lastDay });
      api.gotoDate(firstDay);
    }

    const months = extractConsecutiveMonths(firstDay, lastDay, TotalMonths);
    const quarterBasis = moment(firstDay).startOf('month').toDate();
    if (
      quarterBasis.valueOf() === monthsStart.quarterBasis.valueOf() &&
      _.isEqual(months, monthsStart.months)
    )
      return;
    setMonthsStart({ quarterBasis, months });
  };
  const handleProgramClick = (arg: EventClickArg) => {
    try {
      if (
        [CampaignDataMarker, CampaignToggleDataMarker].find(
          (marker) =>
            (arg.jsEvent.target as HTMLElement)?.attributes?.getNamedItem('data-marker')?.value ===
            marker
        )
      ) {
        return;
      }

      const extendedProps = arg.event.extendedProps as CalendarEventExtendedProps;
      if (
        extendedProps.calendarEventType !== CalendarEventType.program &&
        extendedProps.calendarEventType !== CalendarEventType.task
      )
        return;

      if (extendedProps.calendarEventType === CalendarEventType.task) {
        editProgram(extendedProps.origResolvedSummary, extendedProps.origTask);
      } else {
        editProgram(extendedProps.origResolvedSummary, null);
      }
    } catch (e) {
      console.error(e);
    }
  };

  const onProgramDialogHide = useCallback(() => {
    setModalState(null);
  }, []);

  const [initialState] = useState({
    view: periodTypeToViewType(periodDateRange.period),
    date:
      periodDateRange.period !== 'Quarter'
        ? periodDateRange.currentDate
        : startDateToQuarterDate(monthsStart.quarterBasis, periodDateRange.currentDate),
  });

  if (!user) return <PleaseRelogin />;

  return (
    <>
      <SummaryErrors queryResults={summaryQueryResults} />
      {summaryQueryError && (
        <div
          data-test="calendar__general-loading-error"
          key={`calendar-error-${renderId()}`}
          className="alert alert-warning"
          role="alert"
        >
          Could not load programs: {errorToString(summaryQueryError)}
        </div>
      )}
      <div className="calendar__container">
        <div className="calendar__sub-container">
          <div className="d-flex justify-content-between">
            <div className="calendar-toolbar__date-navigator-container">
              <DateRangeSelector
                showDayPeriod={true}
                showCustomPeriod={false}
                periodDateRange={periodDateRange}
                onChanged={(periodDateRange, newDateRange: DateRange) => {
                  setPeriodDateRange(periodDateRange);
                  onDatesChanged(
                    periodDateRange.period,
                    newDateRange.firstDay,
                    newDateRange.lastDay
                  );
                }}
              />
            </div>

            <div className="d-flex">
              <div className="calendar-toolbar__filter-container">
                <ProgramsGroupingSelector onSelect={onGroupingChanged} value={grouping} />
              </div>
              <div className="calendar-toolbar__filter-container">
                <ProgramsMultiFilter
                  appliedFilter={filter}
                  persistedFilters={persistedFilters}
                  applyFilter={(f) => {
                    analyticsTrack(analytics, AnalyticsEvent.PROGRAM_FILTER_APPLIED);
                    setFilter(f);
                  }}
                  persistFilters={setPersistedFilters}
                  noFormula={false}
                />
              </div>
              {(addProgramPermissionHelper.canCreateProgram() ||
                addProgramPermissionHelper.canCreateCampaign()) && (
                <div className="calendar-toolbar__add-new-container">
                  <AddNewProgram
                    isScheduledMode={true}
                    user={user ?? null}
                    onCreate={(program: ProgramSummary) => {
                      analyticsTrack(analytics, AnalyticsEvent.PROGRAM_CLICKED_NEW, {
                        button: 'Calendar Toolbar',
                      });
                      onCreateProgram(program);
                    }}
                  />
                </div>
              )}
              {!displayUnscheduled && (
                <div className="calendar-toolbar__unscheduled-open-container">
                  <DividerIcon className="calendar-toolbar__unscheduled-divider" />
                  <div
                    className="calendar__toolbar__unscheduled-btn"
                    data-test="calendar__toolbar__unscheduled-btn"
                    onClick={() => setDisplayUnscheduled(true)}
                  >
                    <img src={ExpandRight} alt="" />
                    Unscheduled
                  </div>
                </div>
              )}
            </div>
          </div>

          <div className="calendar__box">
            <div className="program-calendar__display-settings-holder">
              <div className="program-calendar__display-settings-button">
                <CalendarDisplaySettings
                  onShowHideColumn={onShowHideColumn}
                  columns={columnsConfigToShow}
                  showCampaigns={displayConfig.showCampaigns ?? true}
                  onShowHideCampaigns={onShowHideCampaigns}
                  showTasks={displayConfig.showTasks}
                  onShowHideTasks={onShowHideTasks}
                  taskFields={joinTaskColumnsTitles(taskColumnsConfig, viewType)}
                  onShowTaskField={onShowHideTaskColumn}
                  isDisplayShowCampaigns={grouping !== 'By campaign'}
                />
              </div>
            </div>

            <FullCalendar
              ref={calendarRef}
              height="100%"
              allDayText="All-day"
              allDayClassNames="calendar__all-day-text"
              firstDay={0}
              dayHeaderClassNames="program-calendar__day-header"
              dayCellClassNames={clsx('program-calendar__day-cell', {
                'program-calendar__day-cell--readonly':
                  !addProgramPermissionHelper.canCreateProgram(),
              })}
              droppable={true}
              editable={true}
              selectable={true}
              eventAllow={() => true}
              dragRevertDuration={0}
              forceEventDuration
              eventDragStop={(e) => {
                const els = document.getElementsByClassName('calendar__unscheduled-programs');
                if (!els.length) return;
                const unscheduledEl = els[0];
                const boundingRect = unscheduledEl.getBoundingClientRect();

                if (
                  e.jsEvent.clientY >= boundingRect.top &&
                  e.jsEvent.clientY <= boundingRect.bottom &&
                  e.jsEvent.clientX >= boundingRect.left &&
                  e.jsEvent.clientX <= boundingRect.right
                ) {
                  makeUnscheduled(e.event);
                }
              }}
              themeSystem="bootstrap"
              eventReceive={handleEventReceive}
              eventAdd={(arg) => onEventAdd(arg)}
              eventChange={async (arg) => {
                await onEditChange(arg.event, arg.oldEvent, arg.revert);
              }}
              eventClick={handleProgramClick}
              selectAllow={() => addProgramPermissionHelper.canCreateProgram()}
              select={(arg) => {
                analyticsTrack(analytics, AnalyticsEvent.PROGRAM_CLICKED_NEW, {
                  button: 'Calendar Date',
                });
                if (viewType === 'monthGridQuarter') {
                  const translatedStart = quarterDateToNormalDate(
                    monthsStart.quarterBasis,
                    arg.start
                  );
                  const translatedEnd = quarterDateToNormalDate(monthsStart.quarterBasis, arg.end);
                  const isOneMonthProgram = moment(translatedStart)
                    .add(1, 'month')
                    .isSame(moment(translatedEnd));

                  createProgram({
                    start: translatedStart,
                    end: isOneMonthProgram
                      ? moment(translatedStart).add(1, 'day').toDate()
                      : translatedEnd,
                    wholeDay: true,
                    programKind: grouping === 'No grouping' ? 'PROGRAM' : 'CAMPAIGN',
                  });
                } else {
                  createProgram({
                    start: arg.start,
                    end: arg.end,
                    wholeDay: arg.allDay,
                    programKind: grouping === 'No grouping' ? 'PROGRAM' : 'CAMPAIGN',
                  });
                }
              }}
              viewClassNames={(hookProps) => {
                if (viewType !== hookProps.view.type) {
                  setViewType(hookProps.view.type as CalendarViewType);
                }
                return [];
              }}
              views={views}
              plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin, listPlugin]}
              headerToolbar={{
                left: '',
                center: '',
                right: '',
              }}
              initialView={initialState.view}
              initialDate={initialState.date}
              events={events}
              eventOrder={(a, b) =>
                compareCalendarEvents(
                  viewType,
                  a as EventCompare,
                  b as EventCompare,
                  grouping === 'By campaign'
                )
              }
              eventOrderStrict={true}
              eventContent={(arg) => {
                const extendedProps = arg.event.extendedProps as CalendarEventExtendedProps;
                switch (extendedProps.calendarEventType) {
                  case CalendarEventType.program:
                    return (
                      <ProgramCalendarEventContent
                        programSummary={extendedProps.origResolvedSummary}
                        showCampaigns={displayConfig.showCampaigns ?? true}
                        visibleColumns={visibleColumns}
                        openCampaignDialog={editProgram}
                        viewType={viewType}
                        isCampaignGrouping={grouping === 'By campaign'}
                        shortcutFieldNamesOnly={true}
                      />
                    );
                  case CalendarEventType.campaign:
                    return (
                      <CampaignCalendarEvent
                        programSummary={extendedProps.origResolvedSummary}
                        visibleColumns={visibleColumns}
                        onToggleCampaign={(c, event) => onCampaignToggle(c.id, event)}
                        onOpen={editProgram}
                        viewType={viewType}
                        isExpanded={extendedProps.expanded}
                        isUnscheduledList={false}
                      />
                    );
                  case CalendarEventType.task:
                    return (
                      <TaskCalendarEvent
                        task={extendedProps.origTask}
                        programSummary={extendedProps.origResolvedSummary}
                        visibleColumns={visibleTaskColumns.map(({ id }) => id)}
                      />
                    );
                  default:
                    return <div />;
                }
              }}
              eventTimeFormat={{
                hour: 'numeric',
                meridiem: 'short',
              }}
              eventMinHeight={25}
              slotEventOverlap={false}
              nowIndicator={true}
              dayHeaderContent={getDayHeaderContent}
            />
          </div>
        </div>
        {displayUnscheduled && (
          <div className="calendar__unscheduled-programs-container">
            <div className="calendar__unscheduled-programs-header-container">
              <div className="calendar__unscheduled-programs-header">Unscheduled</div>
              <div
                onClick={() => setDisplayUnscheduled(false)}
                className="calendar__unscheduled-programs-collapse"
              >
                <img src={CollapseRight} alt="" />
              </div>
            </div>
            <UnscheduledPrograms
              onCreate={(
                arg?: { type: 'typeId'; value?: string } | { type: 'campaignId'; value?: string }
              ) =>
                createProgram({
                  start: null,
                  end: null,
                  wholeDay: true,
                  isScheduled: false,
                  typeId: arg?.type === 'typeId' ? arg.value : '',
                  campaignId:
                    arg?.type === 'campaignId' && arg.value !== NoCampaign.id ? arg.value : null,
                })
              }
              onClick={(program) => editProgram(program)}
              showCampaigns={displayConfig.showCampaigns ?? true}
              visibleColumns={visibleColumns}
              isCampaignGrouping={grouping === 'By campaign'}
              allCampaigns={existedCampaigns.data ?? []}
              selectedFilter={filter}
            />
          </div>
        )}
      </div>
      {growegyAIConfig.programsChatEnabled &&
        isTabletOrLarger &&
        user &&
        user.has(Permission.ADD_PROGRAM) && <ProgramsChat />}
      {modalState && (
        <ProgramDialog
          action={modalState.action}
          programSummary={modalState.program}
          scrollToTaskId={modalState.scrollToTaskId}
          onHide={onProgramDialogHide}
        />
      )}
    </>
  );
};

export default ProgramCalendar;
