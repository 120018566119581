import { useLocalStorage } from '@rehooks/local-storage';
import { StandardPeriodType } from '../../commons/components/PeriodPicker';
import { CalendarLocalStorageKey, ProgramsTableLocalStorageKey } from '../util';
import { useCallback } from 'react';
import { PeriodDateRange } from '../../commons/components/DateRangeSelector';
import moment from 'moment';

export const useProgramsDatesNavigation = () => {
  const [calendarPeriod] = useLocalStorage<StandardPeriodType>(
    `${CalendarLocalStorageKey}period`,
    'Month'
  );
  const [programsTablePeriod] = useLocalStorage<StandardPeriodType>(
    `${ProgramsTableLocalStorageKey}period`,
    'Quarter'
  );

  const defaultDate = moment(new Date()).startOf('day').toDate();
  const [tablePeriodDateRange, setTablePeriodDateRange] = useLocalStorage<PeriodDateRange>(
    `${ProgramsTableLocalStorageKey}PeriodDateRange`,
    {
      period: programsTablePeriod,
      currentDate: defaultDate,
    }
  );

  const [calendarPeriodDateRange, setCalendarPeriodDateRange] = useLocalStorage<PeriodDateRange>(
    `${CalendarLocalStorageKey}PeriodDateRange`,
    {
      period: calendarPeriod,
      currentDate: defaultDate,
    }
  );

  const setPeriodDateRange = useCallback(
    (periodDateRange: PeriodDateRange) => {
      setCalendarPeriodDateRange(periodDateRange);
      const { period } = periodDateRange;
      const programsPeriod = period !== 'Day' && period !== 'Custom' ? period : 'Week';
      setTablePeriodDateRange({
        ...periodDateRange,
        period: programsPeriod,
      });
    },
    [setCalendarPeriodDateRange, setTablePeriodDateRange]
  );

  return {
    calendarPeriodDateRange,
    tablePeriodDateRange,
    setPeriodDateRange,
  };
};
