import useApi from '../../../api/backendApiContext';
import { useQuery } from '@tanstack/react-query';
import { useQueryCacheManager } from '../../../hooks/useQueryCacheManager';
import useAuth from '../../../auth/authContext';
import { ReactQueryConstants } from '../../../commons/constants';
import { QueryStatus } from '../../../commons/components/QueryStatus';
import { NEW_CHAT_ID } from '../../types';

export const useFetchedChatMessages = (chatId: string, canBeStale: boolean) => {
  const { growegyAIApi } = useApi();
  const {
    state: { user },
  } = useAuth();
  const queryKey = useQueryCacheManager().getChatMessagesCacheKey(chatId);
  const query = useQuery(
    queryKey,
    async () =>
      user && chatId !== NEW_CHAT_ID ? growegyAIApi.getMessages(user?.userId, chatId) : [],
    {
      staleTime: canBeStale ? Infinity : ReactQueryConstants.chatsMessagesStaleTime,
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
    }
  );
  const queryStatusComponent = (
    <QueryStatus
      queryKey={queryKey}
      queryStatus={query.status}
      dataTestPrefix="messages"
      failedToLoadText="Something went wrong while loading the messages."
    />
  );

  return { query, queryStatusComponent };
};
