import useApi from '../../api/backendApiContext';
import useAuth from '../../auth/authContext';
import { useQueryCacheManager } from '../../hooks/useQueryCacheManager';
import { useQuery } from '@tanstack/react-query';
import { ReactQueryConstants } from '../../commons/constants';
import React from 'react';
import { QueryStatus } from '../../commons/components/QueryStatus';
import { INVOICE_TYPE_ISSUED, IssuedInvoice } from '../types';
import { isInvoiceNonPastDue, isInvoicePastDue } from '../utils';
import { Permission } from '../../auth/permissions';
import { useQueryErrorAnalyticsPublisher } from '../../log/hooks/useQueryErrorAnalyticPublisher';
import { AnalyticsEvent } from '../../web-analytics/AnalyticsEvent';

export const useFetchedBillingInvoices = (props?: {
  withTracking?: boolean;
  refetchOnWindowFocus?: boolean | 'always';
}) => {
  const { billingApi } = useApi();
  const {
    state: { user },
  } = useAuth();
  const queryCacheManager = useQueryCacheManager();
  const queryKey = queryCacheManager.getBillingInvoicesCacheKey();
  const query = useQuery(
    queryKey,
    async () => {
      if (!user || !user.has(Permission.CHANGE_BILLING)) {
        return [] as IssuedInvoice[];
      }
      return billingApi.finalizeInvoices();
    },
    {
      staleTime: ReactQueryConstants.billingStaleTime,
      refetchInterval: ReactQueryConstants.billingRefetchTime,
      refetchOnWindowFocus: props?.refetchOnWindowFocus,
    }
  );
  useQueryErrorAnalyticsPublisher(
    props?.withTracking ? query.error : null,
    'getBillingInvoices',
    AnalyticsEvent.BILLING_PORTAL_FAILED
  );
  const queryStatusComponent = (
    <QueryStatus
      queryKey={queryKey}
      queryStatus={query.status}
      dataTestPrefix="billing-invoices"
      failedToLoadText="Something went wrong. Try reloading the page multiple times. If the issue persists, contact support@growegy.com"
    />
  );

  return { query, queryStatusComponent };
};

export const useHasFetchedPastDueInvoices = (options?: {
  refetchOnWindowFocus?: boolean | 'always';
}) => {
  const {
    query: { data: invoices },
  } = useFetchedBillingInvoices({ ...options, withTracking: false });

  return (invoices?.filter((inv) => isInvoicePastDue(inv))?.length ?? 0) > 0;
};

export const useHasFetchedNonPastDueIssuedInvoices = (options?: {
  refetchOnWindowFocus?: boolean | 'always';
}) => {
  const {
    query: { data: invoices },
  } = useFetchedBillingInvoices({ ...options, withTracking: false });

  return (
    (invoices?.filter((inv) => inv.type === INVOICE_TYPE_ISSUED && isInvoiceNonPastDue(inv))
      ?.length ?? 0) > 0
  );
};
