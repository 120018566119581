import _, { compact } from 'lodash';
import React, { PropsWithChildren, useCallback, useEffect, useMemo, useState } from 'react';
import { DragDropContext, DropResult, ResponderProvided } from '@hello-pangea/dnd';
import {
  Column,
  HeaderGroup,
  Row,
  TableBodyProps,
  TableOptions,
  TableProps,
  TableState,
  useExpanded,
  useFlexLayout,
  useGlobalFilter,
  useGroupBy,
  useSortBy,
  useTable,
} from 'react-table';

import {
  ExistedCustomFields,
  ProgramPartialUpdateData,
  ProgramRow,
  ProgramSummary,
  ProgramTask,
  ProgramType,
  ResolvedProgramSummary,
  ResolvedProgramTask,
  TaskInFocus,
} from '../types';
import { DateRangeSelector } from '../../commons/components/DateRangeSelector';
import { ProgramsMultiFilter, ProgramsMultiFilterProps } from './ProgramsMultiFilter';
import { useLocalStorage } from '@rehooks/local-storage';
import { FileRejection } from 'react-dropzone';
import { ICurrentUser } from '../../currentuser/currentUser';
import { formatFormulaValue } from '../formulaUtil';
import { ConfirmDialogProps, ConfirmModal } from '../../commons/components/ConfirmModal';
import TableRow, { buildTaskArrayHelper } from './programs-table/ProgramsTableRow';
import { SimpleStringCell } from './programs-table/ProgramCells';
import HeaderRow from './programs-table/HeaderRow';
import SortableHeader from './programs-table/SortableHeader';
import NameCell from './programs-table/NameCell';
import DateTimeCell from './programs-table/DateTimeCell';
import OwnerCell from './programs-table/OwnerCell';
import NumberCell from './programs-table/NumberCell';
import StringCell from './programs-table/StringCell';
import MoneyCell from './programs-table/MoneyCell';
import StatusCell from './programs-table/StatusCell';
import { AddNewProgram } from './programs-table/AddNewProgram';
import CampaignCell from './programs-table/CampaignCell';
import { ColumnsConfig } from '../containers/types';
import { onProgramColumnDragEnd } from './programs-table/utils';
import { GlobalFilter } from './GlobalFilter';
import { DivTab } from './DivTab';
import ColumnsSelector from './ColumnsSelector';
import { PeriodType } from '../../commons/components/PeriodPicker';
import { ProgramTableCellWidth, ProgramTableNameCellWidth, SelectCellWidth } from '../util';
import { useMemoCompare } from '../../hooks/useMemoCompare';
import { useProgramsDatesNavigation } from '../hooks/useProgramsDatesNavigation';
import { Tasks } from './tasks/Tasks';
import { Permission } from '../../auth/permissions';
import { useAddProgramPermissionHelper } from '../programPermissionHelper';
import { ProgramDragDropContext } from './ProgramDragDropContext';
import { TaskArrayHelpers } from './tasks/CellProps';
import { MoreMenu } from '../../commons/components/MoreMenu';

const LocalStorageKey = 'ProgramsTable/';

export type TableColumn = Column<ProgramRow> & { id: string; scheduledOnly?: boolean };
export type ProgramsTabType = 'SCHEDULED' | 'UNSCHEDULED';

interface TableProperties extends TableOptions<ProgramRow> {
  name: string;
  user: ICurrentUser | null;
  onCreate: (p: ProgramSummary) => void;
  onProgramClick: (p: ProgramSummary) => void;
  onConvertTaskToProgram: (sourceProgram: ResolvedProgramSummary, sourceTask: ProgramTask) => void;
  onProgramUpdate: (data: { original: ProgramRow; updates: ProgramPartialUpdateData[] }) => void;
  isLoading: boolean;
  onDeleteAllPrograms: () => Promise<void>;
  onDownloadToCSV: () => void;
  onActionProgramsFromCSV: (acceptedFiles: File[], fileRejections: FileRejection[]) => void;
  onDatesChanged: (period: PeriodType, firstDay: Date, lastDay: Date) => void;
  filterProps: ProgramsMultiFilterProps;
  columnsConfig: ColumnsConfig;
  onColumnsReorder: (result: DropResult) => void;
  onShowHideColumn: (key: string | null, visible: boolean) => void;
  changeTab: (tab: ProgramsTabType) => void;
  tabType: ProgramsTabType;
  taskInFocus: TaskInFocus | null;
  onHeaderDragEnd: (result: DropResult, provided: ResponderProvided) => void;
}

const useControlledState: (state: TableState<ProgramRow>) => TableState<ProgramRow> = (
  state: TableState<ProgramRow>
) => {
  const { sortBy: programsSortings, groupBy } = state;

  if (programsSortings.length && groupBy.length && programsSortings[0].id === groupBy[0])
    return state;
  if (groupBy.length) {
    return {
      ...state,
      sortBy: [{ id: groupBy[0] }, ...programsSortings],
    };
  }
  return state;
};

const commonTableColumns: TableColumn[] = [
  {
    Header: SortableHeader,
    id: 'type',
    accessor: (u: ProgramRow) => u.type.name,
    // @ts-ignore
    Cell: SimpleStringCell,
    Aggregated: () => null,
    width: 0,
  },
  {
    Header: SortableHeader,
    id: 'name',
    accessor: (u: ProgramRow) => u.name,
    // @ts-ignore
    Cell: NameCell,
    Aggregated: () => null,
    width: ProgramTableNameCellWidth,
  },
  {
    Header: SortableHeader,
    id: 'startDateTime',
    accessor: (u: ProgramRow) => u.startDateTime,
    // @ts-ignore
    Cell: DateTimeCell('programs-table__start-date', {
      type: 'StaticField',
      fieldName: 'startDateTime',
    }),
    sortType: 'datetime',
    scheduledOnly: true,
    Aggregated: () => null,
    width: ProgramTableCellWidth,
  },
  {
    Header: SortableHeader,
    id: 'endDateTime',
    accessor: (u: ProgramRow) => u.endDateTime,
    // @ts-ignore
    Cell: DateTimeCell('programs-table__end-date', {
      type: 'StaticField',
      fieldName: 'endDateTime',
    }),
    sortType: 'datetime',
    Aggregated: () => null,
    scheduledOnly: true,
    width: ProgramTableCellWidth,
  },
  {
    Header: SortableHeader,
    id: 'owner',
    accessor: (u: ProgramRow) => u.owner,
    // @ts-ignore
    Cell: OwnerCell({ dataTest: 'programs-table__owner-cell' }),
    Aggregated: () => null,
    width: SelectCellWidth,
  },
  {
    Header: SortableHeader,
    id: 'vendor',
    accessor: (u: ProgramRow) => u.vendor,
    // @ts-ignore
    Cell: StringCell('programs-table__vendor-cell', { type: 'StaticField', fieldName: 'vendor' }),
    Aggregated: () => null,
    width: ProgramTableCellWidth,
  },
  {
    Header: SortableHeader,
    id: 'salesforceCampaignId',
    accessor: (u: ProgramRow) => u.salesforceCampaignId,
    // @ts-ignore
    Cell: StringCell('programs-table__salesforceCampaignId-cell', {
      type: 'StaticField',
      fieldName: 'salesforceCampaignId',
    }),
    Aggregated: () => null,
    width: ProgramTableCellWidth,
  },

  {
    Header: SortableHeader,
    id: 'Campaign',
    accessor: (u: ProgramRow) => u.campaign,
    // @ts-ignore
    Cell: CampaignCell('programs-table__campaign-cell'),
    Aggregated: () => null,
    width: SelectCellWidth,
  },
  {
    Header: SortableHeader,
    id: 'leads',
    accessor: (u: ProgramRow) => u.leads,
    // @ts-ignore
    Cell: NumberCell('programs-table__leads-cell', { type: 'StaticField', fieldName: 'leads' }),
    aggregate: 'sum',
    width: ProgramTableCellWidth,
  },
  {
    Header: SortableHeader,
    id: 'actualLeads',
    accessor: (u: ProgramRow) => u.actualLeads,
    // @ts-ignore
    Cell: NumberCell('programs-table__actual-leads-cell', {
      type: 'StaticField',
      fieldName: 'actualLeads',
    }),
    aggregate: 'sum',
    width: ProgramTableCellWidth,
  },
  {
    Header: SortableHeader,
    id: 'budget',
    accessor: (u: ProgramRow) => u.budget,
    // @ts-ignore
    Cell: MoneyCell('programs-table__budget-cell', { type: 'StaticField', fieldName: 'budget' }),
    aggregate: 'sum',
    width: ProgramTableCellWidth,
  },
  {
    Header: SortableHeader,
    id: 'status',
    accessor: (u: ProgramRow) => u.status.name,
    // @ts-ignore
    Cell: StatusCell,
    Aggregated: () => null,
    width: SelectCellWidth,
  },
];

const getRowId = (r: ResolvedProgramSummary) => r.id;

const TableContent = (props: {
  getTableProps: TableProps;
  headerGroups: Array<HeaderGroup<ProgramRow>>;
  onHeaderDragEnd: (result: DropResult, provided: ResponderProvided) => void;
  getTableBodyProps: TableBodyProps;
  rows: Array<Row<ProgramRow>>;
  user: ICurrentUser;
  prepareRow: (row: Row<ProgramRow>) => void;
  programTypes: Map<string, ProgramType>;
  onCreate: (p: ProgramSummary) => void;
  onProgramClick: (p: ProgramSummary) => void;
  tabType: 'SCHEDULED' | 'UNSCHEDULED';
  isLoading: boolean;
  onProgramUpdate: (data: { original: ProgramRow; updates: ProgramPartialUpdateData[] }) => void;
  taskInFocus: TaskInFocus | null;
  onConvertTaskToProgram: (sourceProgram: ResolvedProgramSummary, sourceTask: ProgramTask) => void;
}) => {
  const {
    getTableProps,
    headerGroups,
    onHeaderDragEnd,
    getTableBodyProps,
    rows,
    user,
    prepareRow,
    programTypes,
    onCreate,
    onProgramClick,
    tabType,
    isLoading,
    onProgramUpdate,
    taskInFocus,
    onConvertTaskToProgram,
  } = props;
  const programSummaries = useMemoCompare(
    rows.flatMap((x) => x.subRows).map((x) => x.original),
    (a, b) => _.isEqual(a, b)
  );
  const taskArrayHelpersFactory = useCallback(
    (programSummary: ResolvedProgramSummary) =>
      buildTaskArrayHelper(programSummary, onProgramUpdate),
    [onProgramUpdate]
  );

  const tasksTableFactory = useCallback(
    (props: {
      program: ResolvedProgramSummary;
      values: ResolvedProgramTask[];
      arrayHelpers: TaskArrayHelpers;
      onPopoverOpen: () => void;
      onPopoverClose: () => void;
      onConvertTaskToProgram: (program: ResolvedProgramSummary, task: ProgramTask) => void;
    }) => {
      const {
        program,
        values,
        arrayHelpers,
        onPopoverOpen,
        onPopoverClose,
        onConvertTaskToProgram,
      } = props;

      return (
        <Tasks
          program={program}
          values={values}
          arrayHelpers={arrayHelpers}
          isReadonly={false}
          taskInFocusOrderId={
            taskInFocus?.programId === program.id ? taskInFocus?.taskOrderId : null
          }
          onPopoverOpen={onPopoverOpen}
          onPopoverClose={onPopoverClose}
          onConvertTaskToProgram={(task: ProgramTask) => onConvertTaskToProgram(program, task)}
        />
      );
    },
    [taskInFocus]
  );

  return (
    <div className="programs-table">
      {isLoading && (
        <div className="programs-table__loading">
          <div className="spinner-center">
            <div className="spinner-border spinner-color" role="status" />
          </div>
        </div>
      )}
      <div {...getTableProps} className="programs-table__content">
        <div className="programs-table__header--row--wrapper">
          <DragDropContext onDragEnd={onHeaderDragEnd}>
            {headerGroups.map((h) => (
              <HeaderRow headerGroup={h} key={h.getHeaderGroupProps().key} />
            ))}
          </DragDropContext>
        </div>
        <div {...getTableBodyProps} className="programs-table__rows--wrapper">
          <ProgramDragDropContext
            programSummaries={programSummaries}
            taskArrayHelpersFactory={taskArrayHelpersFactory}
          >
            {rows.map((row: Row<ProgramRow>) => {
              prepareRow(row);
              return (
                <TableRow
                  key={row.getRowProps().key}
                  programTypes={programTypes}
                  prepareRow={prepareRow}
                  onCreate={onCreate}
                  onProgramClick={onProgramClick}
                  onConvertTaskToProgram={onConvertTaskToProgram}
                  isScheduledMode={tabType === 'SCHEDULED'}
                  onProgramUpdate={onProgramUpdate}
                  row={row}
                  user={user}
                  TasksTable={tasksTableFactory}
                />
              );
            })}
          </ProgramDragDropContext>
        </div>
      </div>
    </div>
  );
};

const Table = (props: PropsWithChildren<TableProperties>) => {
  const {
    columns,
    user,
    onCreate,
    isLoading,
    onDeleteAllPrograms,
    onDownloadToCSV,
    onActionProgramsFromCSV,
    data,
    onDatesChanged,
    filterProps,
    onProgramUpdate,
    changeTab,
    tabType,
    taskInFocus,
    onHeaderDragEnd,
    onConvertTaskToProgram,
  } = props;
  const programTypes = useMemo(() => new Map(data.map((i) => [i.type.name, i.type])), [data]);
  const [rowEditing, setRowEditing] = useState(false);

  const { tablePeriodDateRange, setPeriodDateRange } = useProgramsDatesNavigation();

  const onProgramClick = useCallback(
    (p: ProgramSummary) => {
      if (rowEditing) return;
      props.onProgramClick(p);
    },
    [props, rowEditing]
  );

  const tableOptions = useMemo(
    () => ({
      ...props,
      ...{
        onProgramClick,
        onRowEditChange: setRowEditing,
      },
      useControlledState,
      columns,
      autoResetGlobalFilter: false,
      autoResetSortBy: false,
      autoResetExpanded: false,
      expandSubRows: false,
      getRowId,
      initialState: {
        sortBy: [{ id: 'type' }],
        groupBy: ['type'],
      },
    }),
    [columns, onProgramClick, props]
  );

  const [confirmModal, setConfirmModal] = useState(false);

  const handleDeleteAll = useCallback(async () => {
    setConfirmModal(false);
    await onDeleteAllPrograms();
  }, [onDeleteAllPrograms]);

  const confirmDeleteAll: ConfirmDialogProps = useMemo<ConfirmDialogProps>(
    () => ({
      title: 'Delete all programs?',
      message: 'Are you sure want to delete all programs?',
      footer: {
        type: 'show',
        applyButton: {
          label: 'Yes, Delete All!',
          variant: 'danger',
          onClick: handleDeleteAll,
        },
        cancelButton: {
          label: 'Cancel',
          onClick: () => setConfirmModal(false),
        },
      },
    }),
    [handleDeleteAll]
  );

  const MenuProgramsAction = (props: {
    hasDownloadAccess: boolean;
    hasUploadAccess: boolean;
    hasDeleteAllAccess: boolean;
  }) => {
    const { hasDownloadAccess, hasUploadAccess, hasDeleteAllAccess } = props;

    if (!hasDownloadAccess && !hasUploadAccess && !hasDeleteAllAccess) return <></>;
    return (
      <MoreMenu
        menuItems={[
          {
            type: 'upload',
            text: 'Upload CSV',
            dataTest: 'programs-actions',
            isHidden: !hasUploadAccess,
            accept: '.csv,text/csv,application/vnd.ms-excel,',
            onClick: (acceptedFiles, rejectedFiles) =>
              onActionProgramsFromCSV(acceptedFiles, rejectedFiles),
          },
          {
            type: 'standard',
            text: 'Download programs to CSV',
            dataTest: 'programs__download',
            isHidden: !hasDownloadAccess,
            onClick: onDownloadToCSV,
          },
          {
            type: 'divider',
            isHidden: !hasDownloadAccess && !hasUploadAccess,
          },
          {
            type: 'standard',
            text: 'Delete All',
            dataTest: 'programs__delete-all-program',
            isHidden: !hasDeleteAllAccess,
            onClick: () => setConfirmModal(true),
          },
          {
            type: 'divider',
            isHidden: !hasDeleteAllAccess,
          },
          {
            type: 'standard',
            text: 'Help',
            isHidden: !hasDownloadAccess && !hasUploadAccess,
            onClick: () => window.open('https://www.growegy.com/csv/', '_blank'),
          },
        ]}
        menuButtonDataTest="programs-actions"
      />
    );
  };

  const instance = useTable<ProgramRow>(
    tableOptions,
    useFlexLayout,
    useGlobalFilter,
    useGroupBy,
    useSortBy,
    useExpanded
  );

  const { getTableProps, getTableBodyProps, rows, prepareRow, headerGroups } = instance;

  const addProgramPermissionHelper = useAddProgramPermissionHelper();
  const tableProps = useMemoCompare<TableProps>(getTableProps(), (a, b) => _.isEqual(a, b));
  const tableBodyProps = useMemoCompare<TableBodyProps>(getTableBodyProps(), (a, b) =>
    _.isEqual(a, b)
  );
  const headerGroupsProps = useMemoCompare<HeaderGroup<ResolvedProgramSummary>[]>(
    headerGroups,
    (a, b) => _.isEqual(a, b)
  );
  const rowsProps = useMemoCompare<Row<ResolvedProgramSummary>[]>(rows, (a, b) => _.isEqual(a, b));
  return (
    <>
      <div className="d-flex justify-content-between align-items-center">
        <div className="d-flex">
          <div className="m-0 p-0">
            <DivTab
              text="Scheduled"
              dataTest="programs-table__tab-scheduled"
              onClick={() => changeTab('SCHEDULED')}
              isSelected={tabType === 'SCHEDULED'}
            />
          </div>
          <div className="p-0" style={{ margin: '0 0 0 16px' }}>
            <DivTab
              dataTest="programs-table__tab-unscheduled"
              text="Unscheduled"
              onClick={() => changeTab('UNSCHEDULED')}
              isSelected={tabType === 'UNSCHEDULED'}
            />
          </div>
        </div>
        <div>
          {tabType === 'SCHEDULED' && (
            <DateRangeSelector
              showDayPeriod={false}
              showCustomPeriod={false}
              periodDateRange={tablePeriodDateRange}
              onChanged={(newPeriodDateRange, newDateRange) => {
                setPeriodDateRange(newPeriodDateRange);
                onDatesChanged(
                  newPeriodDateRange.period,
                  newDateRange.firstDay,
                  newDateRange.lastDay
                );
              }}
            />
          )}
        </div>
        <div className="programs-table__header--right">
          <GlobalFilter
            filterValue={instance.state.globalFilter}
            setFilterValue={instance.setGlobalFilter}
          />
          <ProgramsMultiFilter {...filterProps} />
          <MenuProgramsAction
            hasDownloadAccess={
              user?.hasAtLeastOne(
                Permission.GET_ALL_PROGRAMS,
                Permission.GET_ALL_ASSIGNED_PROGRAMS
              ) ?? false
            }
            hasUploadAccess={user?.has(Permission.REPLACE_PROGRAMS_FROM_CSV) ?? false}
            hasDeleteAllAccess={user?.has(Permission.DELETE_ALL_PROGRAM) ?? false}
          />
          <div className="d-flex align-items-center" style={{ width: '32px', height: '32px' }}>
            {addProgramPermissionHelper.canCreateProgram() && (
              <AddNewProgram
                isScheduledMode={tabType === 'SCHEDULED'}
                user={user}
                onCreate={onCreate}
              />
            )}
            <div className="div-button--overlay">
              <ColumnsSelector
                columnsConfig={props.columnsConfig}
                onShowHideColumn={props.onShowHideColumn}
                onColumnsReorder={props.onColumnsReorder}
              />
            </div>
          </div>
        </div>
      </div>

      <TableContent
        getTableProps={tableProps}
        getTableBodyProps={tableBodyProps}
        tabType={tabType}
        onCreate={onCreate}
        programTypes={programTypes}
        onProgramUpdate={onProgramUpdate}
        onProgramClick={onProgramClick}
        headerGroups={headerGroupsProps}
        onHeaderDragEnd={onHeaderDragEnd}
        prepareRow={prepareRow}
        rows={rowsProps}
        user={user!}
        isLoading={isLoading}
        taskInFocus={taskInFocus}
        onConvertTaskToProgram={onConvertTaskToProgram}
      />

      {confirmModal && <ConfirmModal {...confirmDeleteAll} />}
    </>
  );
};

const ProgramsTable = (props: {
  user: ICurrentUser | null;
  onCreate: (p: ProgramSummary) => void;
  onProgramClick: (p: ProgramSummary) => void;
  onProgramUpdate: (data: { original: ProgramRow; updates: ProgramPartialUpdateData[] }) => void;
  onConvertTaskToProgram: (sourceProgram: ResolvedProgramSummary, sourceTask: ProgramTask) => void;
  isLoading: boolean;
  onDeleteAllPrograms: () => Promise<void>;
  onDownloadToCSV: () => void;
  onActionProgramsFromCSV: (acceptedFiles: File[], fileRejections: FileRejection[]) => void;
  data: ProgramRow[];
  onDatesChanged: (period: PeriodType, firstDay: Date, lastDay: Date) => void;
  customFields: ExistedCustomFields;
  filterProps: ProgramsMultiFilterProps;
  changeTab: (tab: ProgramsTabType) => void;
  tabType: ProgramsTabType;
  taskInFocus: TaskInFocus | null;
}) => {
  const { data, filterProps, changeTab, tabType, isLoading, taskInFocus } = props;

  const tableColumns: TableColumn[] = useMemo(
    () =>
      commonTableColumns.concat(
        // @ts-ignore
        Array.from(props.customFields.strings).map((fieldName) => ({
          Header: SortableHeader,
          id: `${fieldName}-text`,
          accessor: (u: ProgramRow) =>
            u.customFields.find((f) => f.name === fieldName && f.type === 'text')?.value,
          Cell: StringCell(`programs-table__custom-cell-${fieldName}-text`, {
            type: 'ArrayFieldItem',
            arrayField: 'customFields',
            arrayItemValue: { type: 'text', name: fieldName },
          }),
          width: ProgramTableCellWidth,
        })),
        Array.from(props.customFields.numbers).map((fieldName) => ({
          Header: SortableHeader,
          title: fieldName,
          id: `${fieldName}-number`,
          accessor: (u: ProgramRow) =>
            u.customFields.find((f) => f.name === fieldName && f.type === 'number')?.value ?? null,
          Cell: NumberCell(`programs-table__custom-cell-${fieldName}-number`, {
            type: 'ArrayFieldItem',
            arrayField: 'customFields',
            arrayItemValue: { type: 'number', name: fieldName },
          }),
          width: ProgramTableCellWidth,
        })),
        Array.from(props.customFields.dates).map((fieldName) => ({
          Header: SortableHeader,
          title: fieldName,
          id: `${fieldName}-date-time`,
          accessor: (u: ProgramRow) =>
            u.customFields.find((f) => f.name === fieldName && f.type === 'date-time')?.value ??
            null,
          Cell: DateTimeCell(`programs-table__custom-cell-${fieldName}-date-time`, {
            type: 'ArrayFieldItem',
            arrayField: 'customFields',
            arrayItemValue: { type: 'date-time', name: fieldName },
          }),
          width: ProgramTableCellWidth,
        })),
        Array.from(props.customFields.formulas).map((fieldName) => ({
          Header: SortableHeader,
          title: fieldName,
          id: `${fieldName}-formula`,
          accessor: (u: ProgramRow) => {
            const found = u.customFields.find((f) => f.name === fieldName && f.type === 'formula');
            if (!found || found.type !== 'formula') return null;
            return formatFormulaValue(found.result, found.prefix, found.suffix);
          },
          Cell: SimpleStringCell({
            dataTest: `programs-table__custom-cell-${fieldName}-number`,
            className: 'text-break',
          }),
          width: ProgramTableCellWidth,
        }))
      ),
    [props.customFields]
  );

  const [columnsConfigStorage, setColumnsConfigStorage] = useLocalStorage<ColumnsConfig>(
    `${LocalStorageKey}columns`,
    tableColumns.map((c) => ({
      id: c.id as string,
      visible: true,
    }))
  );

  useEffect(() => {
    const newColumns = tableColumns.filter(
      (col) => !columnsConfigStorage.find((colConfig) => colConfig.id === col.id)
    );

    if (newColumns.length)
      setColumnsConfigStorage([
        ...columnsConfigStorage,
        ...newColumns.map((col) => ({
          id: col.id as string,
          visible: true,
        })),
      ]);
  }, [tableColumns, columnsConfigStorage, setColumnsConfigStorage]);

  const visibleColumns = useMemo(
    () =>
      compact(
        columnsConfigStorage.map(
          (colConfig) =>
            colConfig.visible &&
            tableColumns.find(
              (column) =>
                column.id === colConfig.id && (tabType === 'SCHEDULED' || !column.scheduledOnly)
            )
        )
      ),
    [columnsConfigStorage, tabType, tableColumns]
  );

  const columnsConfig = useMemo(
    () =>
      columnsConfigStorage.filter((c) =>
        tableColumns.find(
          (column) => column.id === c.id && (tabType === 'SCHEDULED' || !column.scheduledOnly)
        )
      ),
    [columnsConfigStorage, tabType, tableColumns]
  );

  const onHeaderDragEnd = useCallback(
    (result: DropResult) =>
      onProgramColumnDragEnd(
        result,
        2,
        visibleColumns,
        columnsConfigStorage,
        setColumnsConfigStorage
      ),
    [columnsConfigStorage, setColumnsConfigStorage, visibleColumns]
  );
  const onColumnListDragEnd = useCallback(
    (result: DropResult) =>
      onProgramColumnDragEnd(
        result,
        2,
        columnsConfig,
        columnsConfigStorage,
        setColumnsConfigStorage
      ),
    [columnsConfigStorage, setColumnsConfigStorage, columnsConfig]
  );

  const onShowHideColumn = useCallback(
    (key: string | null, visible: boolean) => {
      if (key === null)
        setColumnsConfigStorage(
          columnsConfigStorage.map((col, idx) => (idx >= 2 ? { ...col, visible } : col))
        );
      else
        setColumnsConfigStorage(
          columnsConfigStorage.map((col) => (col.id === key ? { ...col, visible } : col))
        );
    },
    [columnsConfigStorage, setColumnsConfigStorage]
  );

  return (
    <Table
      key="programs-table"
      name="programs-table"
      columns={visibleColumns}
      onColumnsReorder={onColumnListDragEnd}
      data={data}
      user={props.user}
      onCreate={props.onCreate}
      onProgramClick={props.onProgramClick}
      onProgramUpdate={props.onProgramUpdate}
      onConvertTaskToProgram={props.onConvertTaskToProgram}
      isLoading={isLoading}
      onDeleteAllPrograms={props.onDeleteAllPrograms}
      onDownloadToCSV={props.onDownloadToCSV}
      onActionProgramsFromCSV={props.onActionProgramsFromCSV}
      onDatesChanged={props.onDatesChanged}
      filterProps={filterProps}
      columnsConfig={columnsConfig}
      onShowHideColumn={onShowHideColumn}
      tabType={tabType}
      changeTab={changeTab}
      taskInFocus={taskInFocus}
      onHeaderDragEnd={onHeaderDragEnd}
    />
  );
};

export const ProgramsTableMemoised = React.memo(ProgramsTable, (prev, next) =>
  _.isEqual(prev, next)
);
