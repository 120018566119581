import {
  ResolvedProgramTask,
  ProgramType,
  ResolvedProgramSummary,
  ResolvedCampaign,
} from '../types';
import { EventInput } from '@fullcalendar/core';
import { Dictionary } from '@fullcalendar/core/internal';

export enum CalendarEventType {
  program,
  task,
  typeMarker,
  campaign,
  campaignMarker,
}

export type CalendarViewType =
  | 'timeGridDay'
  | 'timeGridWeek'
  | 'dayGridMonth'
  | 'monthGridQuarter'
  | 'dayGridWeek'
  | 'dayGridDay';

export const UnscheduledViewType = 'unscheduled';

export type CalendarProgramEventInput = {
  calendarEventType: CalendarEventType.program;
  origResolvedSummary: ResolvedProgramSummary;
};
export type CalendarTaskEventInput = {
  calendarEventType: CalendarEventType.task;
  origResolvedSummary: ResolvedProgramSummary;
  origTask: ResolvedProgramTask;
};
export type CalendarProgramTypeMarkerEventInput = {
  calendarEventType: CalendarEventType.typeMarker;
  origCampaign?: ResolvedCampaign;
  origProgramType: ProgramType;
};
export type CalendarCampaignEventInput = {
  calendarEventType: CalendarEventType.campaign;
  origResolvedSummary: ResolvedCampaign;
  expanded: boolean;
};
export type CalendarCampaignMarkerEventInput = {
  calendarEventType: CalendarEventType.campaignMarker;
  origCampaign: ResolvedCampaign;
};

export type CalendarEventExtendedProps =
  | CalendarProgramEventInput
  | CalendarTaskEventInput
  | CalendarProgramTypeMarkerEventInput
  | CalendarCampaignEventInput
  | CalendarCampaignMarkerEventInput;

export type CalendarEventInput = CalendarEventExtendedProps & EventInput;

export type EventCompare = {
  id: string;
  start: number;
  end: number;
  duration: number;
  allDay: number;
  defId: string;
  sourceId: string;
  publicId: string;
  groupId: string;
  hasEnd: boolean;
  recurringDef: {
    typeId: number;
    duration: Duration;
  };
  title: string;
  url: string;
  interactive?: boolean;
  extendedProps: Dictionary;
};

export type ColumnsConfig = {
  id: string;
  visible: boolean;
}[];
