import { useQuery } from '@tanstack/react-query';
import useApi from '../../api/backendApiContext';
import { useQueryCacheManager } from '../../hooks/useQueryCacheManager';
import { ReactQueryConstants } from '../../commons/constants';
import { NoProgramOwner, UnknownProgramOwner } from '../../user/types';

const nullAvatar192x192 =
  'data:application/octet-stream;base64,iVBORw0KGgoAAAANSUhEUgAAAMAAAADACAYAAABS3GwHAAAApklEQVR4Xu3BMQEAAADCoPVPbQlPoAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAPgZA3gABs6TKEQAAAABJRU5ErkJggg==';
const blobToBase64 = async (blob: Blob): Promise<string | null> => {
  const reader = new FileReader();
  reader.readAsDataURL(blob);
  return new Promise((resolve) => {
    reader.onloadend = () => {
      const base64 = reader.result;
      if (typeof base64 === 'string') resolve(base64);
      else resolve(null);
    };
  });
};

export const useFetchedUserAvatar = (userId: string) => {
  const { userApi } = useApi();
  const programCache = useQueryCacheManager();

  return useQuery(
    programCache.getUserAvatarCacheName(userId),
    async () => {
      if (userId === UnknownProgramOwner.id || userId === NoProgramOwner.id) return null;
      const blob = await userApi.getAvatar(userId);
      const base64 = await blobToBase64(blob);
      return base64 === nullAvatar192x192 ? null : URL.createObjectURL(blob);
    },
    {
      refetchOnWindowFocus: false,
      staleTime: ReactQueryConstants.userAvatarStaleTime,
    }
  );
};
