import {
  DATA_SERIES_ACTUAL_LEADS,
  DATA_SERIES_PROJECTED_LEADS,
  ResolvedGroupingDataItem,
} from '../types';
import { Cell, Pie, PieChart } from 'recharts';
import { useState } from 'react';
import clsx from 'clsx';

const decimalFormatter = new Intl.NumberFormat('en-US', { maximumFractionDigits: 0 });

const ActualVsProjectedLeadsPieChart = (props: { item: ResolvedGroupingDataItem }) => {
  const { projectedLeads, actualLeads } = props.item.value;
  const { type } = props.item.key;
  const leadsPercentage =
    projectedLeads !== 0
      ? `${decimalFormatter.format((actualLeads / projectedLeads) * 100)}%`
      : '-';

  const innerRadius = 54;
  const backgroundCircleThickness = 6;
  const valueCircleThickness = 6;
  const outerRadius = innerRadius + valueCircleThickness;
  const chartWidth = outerRadius * 2 + 10;

  const [isHovering, setIsHovering] = useState(false);
  const handleMouseOver = () => {
    setIsHovering(true);
  };

  const handleMouseOut = () => {
    setIsHovering(false);
  };

  return (
    <div
      className="analytics__pie-chart-container"
      data-test="analytics__pie-chart"
      onMouseOver={handleMouseOver}
      onMouseOut={handleMouseOut}
      onFocus={handleMouseOver}
      onBlur={handleMouseOut}
    >
      <div
        className={clsx('analytics__pie-chart-tooltip-container', {
          invisible: !isHovering || projectedLeads === 0,
        })}
      >
        <div className="analytics__pie-chart-tooltip-label" style={{ color: type?.color }}>
          {leadsPercentage}
        </div>
      </div>
      <PieChart
        key={`pie-chart-${type?.id}`}
        width={chartWidth}
        height={chartWidth}
        defaultShowTooltip={false}
      >
        <Pie
          data={[{ key: DATA_SERIES_PROJECTED_LEADS, value: 1 }]}
          dataKey="value"
          nameKey="key"
          isAnimationActive={false}
          cx={outerRadius}
          strokeWidth={0}
          innerRadius={innerRadius}
          tooltipType="none"
          outerRadius={innerRadius + backgroundCircleThickness}
        >
          {[<Cell key="cell-projected-back" fill="#191c1d14" />]}
        </Pie>
        <Pie
          key={`pie-${type?.id}`}
          data={[
            {
              key: DATA_SERIES_PROJECTED_LEADS,
              value: actualLeads !== 0 ? Math.max(0, projectedLeads - actualLeads) : 1,
            },
            { key: DATA_SERIES_ACTUAL_LEADS, value: actualLeads },
          ]}
          dataKey="value"
          nameKey="key"
          isAnimationActive={false}
          cx={outerRadius}
          strokeWidth={0}
          tooltipType="none"
          innerRadius={innerRadius}
          outerRadius={outerRadius}
          startAngle={90}
          endAngle={450}
          label={(labelProps) => {
            const { cx, cy, payload } = labelProps;
            return payload.key === DATA_SERIES_PROJECTED_LEADS ? (
              <g />
            ) : (
              <g>
                <text x={cx} y={cy} dy={-4} textAnchor="middle" fill="#8c8c8c">
                  <tspan fill={type?.color} className="analytics__pie-chart-quantity">
                    {decimalFormatter.format(payload.value)}
                  </tspan>
                  <tspan x={cx} dy="1.5em" className="analytics__pie-chart-label">
                    of {}
                    {decimalFormatter.format(projectedLeads)}
                  </tspan>
                </text>
              </g>
            );
          }}
        >
          {[
            <Cell key="cell-projected" fill="#e0e0e0" fillOpacity="0" />,
            <Cell key="cell-actual" fill={type?.color} />,
          ]}
        </Pie>
      </PieChart>
      <div
        style={{ width: chartWidth, color: type?.color ?? '00b5ce' }}
        className="analytics__pie-chart-label"
        data-test="analytics__pie-chart-type-name"
      >
        {type?.name}
      </div>
    </div>
  );
};

export default ActualVsProjectedLeadsPieChart;
