import { Button } from 'react-bootstrap';
import { ChevronLeftIcon, ChevronRightIcon } from '../../../assets/icons';
import { FailedToDownloadAttachmentMessage } from './FailedToDownloadAttachmentMessage';
import { BoxSpinner } from '../../../commons/components/Spinner';
import DocViewer from '@cyntler/react-doc-viewer';
import BMPRenderer from '@cyntler/react-doc-viewer/dist/cjs/renderers/bmp';
import CSVRenderer from '@cyntler/react-doc-viewer/dist/cjs/renderers/csv';
import GIFRenderer from '@cyntler/react-doc-viewer/dist/cjs/renderers/gif';
import HTMLRenderer from '@cyntler/react-doc-viewer/dist/cjs/renderers/html';
import ImageProxyRenderer from '@cyntler/react-doc-viewer/dist/cjs/renderers/image';
import JPGRenderer from '@cyntler/react-doc-viewer/dist/cjs/renderers/jpg';
import PDFRenderer from '@cyntler/react-doc-viewer/dist/cjs/renderers/pdf';
import PNGRenderer from '@cyntler/react-doc-viewer/dist/cjs/renderers/png';
import TIFFRenderer from '@cyntler/react-doc-viewer/dist/cjs/renderers/tiff';
import TXTRenderer from '@cyntler/react-doc-viewer/dist/cjs/renderers/txt';
import React, { useCallback, useMemo } from 'react';
import { ProgramAttachment } from '../../types';
import { IDocument } from '@cyntler/react-doc-viewer/dist/esm/models';
import { NoPreviewAttachmentMessage } from './NoPreviewAttachmentMessage';
import { useAttachmentsContent } from '../../hooks/useAttachmentsContent';
import { useMemoCompare } from '../../../hooks/useMemoCompare';
import _ from 'lodash';

export type IndexedProgramAttachment = {
  attachment: ProgramAttachment;
  index: number;
};

const pluginRenderers = [
  BMPRenderer,
  CSVRenderer,
  GIFRenderer,
  HTMLRenderer,
  ImageProxyRenderer,
  JPGRenderer,
  PDFRenderer,
  PNGRenderer,
  TIFFRenderer,
  TXTRenderer,
];

export const AttachmentCarouselBody = (props: {
  programId: string;
  attachments: ProgramAttachment[];
  currentAttachment: IndexedProgramAttachment;
  onPrevClick: () => void;
  onNextClick: () => void;
}) => {
  const { programId, attachments, currentAttachment, onPrevClick, onNextClick } = props;

  const spinner = useMemo(() => <BoxSpinner />, []);
  const createSpinner = useCallback(() => spinner, [spinner]);

  const createNoAttachmentPreview = useCallback(
    (p: { document: IDocument | undefined; fileName: string }) => {
      return <NoPreviewAttachmentMessage fileName={p.fileName} url={p.document?.uri ?? ''} />;
    },
    []
  );

  const docViewerConfig = useMemo(
    () => ({
      header: { disableHeader: true },
      pdfVerticalScrollByDefault: true,
      loadingRenderer: { overrideComponent: createSpinner, showLoadingTimeout: 0 },
      noRenderer: {
        overrideComponent: createNoAttachmentPreview,
      },
    }),
    [createSpinner, createNoAttachmentPreview]
  );

  const queries = useAttachmentsContent(programId, attachments, currentAttachment.index);
  const documents = useMemoCompare(
    queries.map((x) => ({
      uri: x.data?.url ?? '',
      fileName: x.data?.name ?? '',
    })),
    (a, b) => _.isEqual(a, b)
  );
  const query = queries[currentAttachment.index];

  if (!query || !currentAttachment) return <></>;

  return (
    <div className="attachments-view__main-container">
      <Button
        className="attachments-view__navigation-button"
        data-test="attachments-view__prev-button"
        onClick={onPrevClick}
        disabled={!currentAttachment || currentAttachment.index <= 0}
      >
        <ChevronLeftIcon className="attachments-view__icon" />
      </Button>
      <div
        className="attachments-view__doc-viewer-container"
        data-test="attachments-view__doc-viewer-container"
      >
        {query.status === 'error' || query.isPaused ? (
          <FailedToDownloadAttachmentMessage
            programId={programId}
            attachmentId={currentAttachment.attachment.id}
            fileName={currentAttachment.attachment.name}
          />
        ) : query.status === 'loading' ? (
          <BoxSpinner />
        ) : (
          <DocViewer
            config={docViewerConfig}
            documents={documents}
            activeDocument={documents[currentAttachment.index]}
            pluginRenderers={pluginRenderers}
            className="attachments-view__doc-viewer"
          />
        )}
      </div>
      <Button
        className="attachments-view__navigation-button"
        data-test="attachments-view__next-button"
        onClick={onNextClick}
        disabled={!currentAttachment || currentAttachment.index >= attachments.length - 1}
      >
        <ChevronRightIcon className="attachments-view__icon" />
      </Button>
    </div>
  );
};
