import React, { useRef, useState } from 'react';
import clsx from 'clsx';

export const IFrameChart = (props: {
  src: string;
  title: string;
  width: number | null;
  height: number | null;
  showChartTitles: boolean;
  showBorder: boolean;
}) => {
  const iframeRef = useRef<HTMLIFrameElement>(null);
  const [height, setHeight] = useState(props.height);
  const [width, setWidth] = useState(props.width);
  const onLoad = () => {
    setTimeout(() => {
      if (iframeRef.current?.contentWindow && height === null) {
        setHeight(iframeRef.current?.contentWindow?.document.body.scrollHeight);
      }
      if (iframeRef.current?.contentWindow && width === null) {
        setWidth(iframeRef.current?.contentWindow?.document.body.scrollWidth);
      }
    }, 500);
  };
  return (
    <div
      style={{
        minHeight: `${height || 0}px`,
        minWidth: width ? `${width}px` : '100%',
      }}
      className="d-flex flex-column"
    >
      <div className="analytics__enterprise-chart-header">
        <span>{props.showChartTitles ? props.title : ''}</span>
      </div>
      <div
        className={clsx('analytics__iframe_container', {
          'container-border': props.showBorder,
        })}
      >
        <iframe
          sandbox="allow-same-origin allow-scripts"
          ref={iframeRef}
          title={props.title}
          onLoad={onLoad}
          id={props.title}
          height={`${height || 0}px`}
          width={width ? `${width}px` : '100%'}
          seamless
          frameBorder="0"
          scrolling="yes"
          srcDoc={props.src}
        />
      </div>
    </div>
  );
};
