import { GPT_MODEL_3_5, GPT_MODEL_4, GptModel } from '../../types';
import { ConfirmModal } from '../../../commons/components/ConfirmModal';
import { useCallback, useEffect, useState } from 'react';
import { useFetchedBillingCustomer } from '../../../billing/hooks/useFetchedBillingCustomer';
import { canUseGpt4 } from '../../util';
import { BILLING_PLAN_YEAR } from '../../../billing/billingPlans';
import { AnnualPlanBenefitsWithTimeline } from '../../../billing/components/AnnualPlanBenefitsWithTimeline';
import { useUpgradeToAnnualPlanMutation } from '../../../billing/hooks/useUpgradeToAnnualPlanMutation';
import { GrowegySelect } from '../../../program/components/GrowegySelect';
import { AiStarsFilledIcon, AiStarsIcon } from '../../../assets/icons';
import { StyleVariables } from '../../../commons/styleConstants';
import { Button } from 'react-bootstrap';
import useAnalytics from '../../../web-analytics/webAnalyticsContext';
import { analyticsTrack } from '../../../web-analytics/webAnalytics';
import { AnalyticsEvent } from '../../../web-analytics/AnalyticsEvent';

const modelLine = (model: GptModel, slim?: boolean) =>
  slim ? model.name : `${model.adjective} · ${model.name}`;

const GptModelValueOption = (props: { model: GptModel; slim?: boolean }) => {
  const { model, slim } = props;
  return <div className="growegy-font-em">{modelLine(model, slim)}</div>;
};

const GptModelMenuOption = (props: {
  model: GptModel;
  showAdditionalDetails: boolean;
  slim?: boolean;
}) => {
  const { model, showAdditionalDetails, slim } = props;
  return (
    <div
      className="gpt-model-selector__option-container"
      data-test={`growegy-model-menu-option__${model.id}-wrapper`}
    >
      {model.id === GPT_MODEL_4.id ? (
        <AiStarsFilledIcon className="flex-shrink-0" />
      ) : (
        <AiStarsIcon className="flex-shrink-0" fill={StyleVariables.neutral500} />
      )}
      <div className="gpt-model-selector__option-right-part-container">
        <div
          className="gpt-model-selector__option-name"
          data-test="growegy-model-menu-option__name"
        >
          {modelLine(model, slim)}
        </div>
        {showAdditionalDetails && (
          <>
            <div
              className="gpt-model-selector__option-description"
              data-test="growegy-model-menu-option__description"
            >
              {model.description}
            </div>
            {model.id === GPT_MODEL_4.id && (
              <Button
                variant="primary"
                className="gpt-model-selector__see-annual-plan-button"
                style={{ width: 'fit-content' }}
                data-test="growegy-model-menu-option__see-annual-button"
              >{`See ${BILLING_PLAN_YEAR.displayName} plan`}</Button>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export const GptModelSelect = (props: {
  gptModel: GptModel;
  disabled: boolean;
  slim?: boolean;
  onGptModelChanged: (model: GptModel) => void;
}) => {
  const { gptModel, disabled, slim, onGptModelChanged } = props;

  const analytics = useAnalytics();
  const [localGptModel, setLocalGptModel] = useState(gptModel);
  useEffect(() => {
    setLocalGptModel(gptModel);
  }, [gptModel]);

  const [showDialog, setShowDialog] = useState(false);

  const source = 'chat';
  const { mutate: upgradePlanMutation } = useUpgradeToAnnualPlanMutation({
    source,
    onSuccess: () => {
      setLocalGptModel(GPT_MODEL_4);
      onGptModelChanged(GPT_MODEL_4);
    },
    onError: () => {
      toggle(GPT_MODEL_3_5);
    },
  });

  const {
    query: { data: customer },
  } = useFetchedBillingCustomer();
  const toggle = useCallback(
    (model: GptModel) => {
      setLocalGptModel(model);
      if (model.id === GPT_MODEL_4.id) {
        if (!customer) return;
        if (!canUseGpt4(customer)) {
          setShowDialog(true);
          analyticsTrack(analytics, AnalyticsEvent.BILLING_PLAN_ANNUAL_PLAN_VIEWED, {
            source,
          });
        } else {
          onGptModelChanged(model);
        }
      } else {
        onGptModelChanged(model);
      }
    },
    [customer, onGptModelChanged, analytics]
  );

  return (
    <>
      <GrowegySelect
        options={[GPT_MODEL_3_5, GPT_MODEL_4]}
        selectedOption={localGptModel}
        width={slim ? 100 : 180}
        borderWidth="2px"
        dataTestPrefix={'gpt-model-select'}
        isSearchable={false}
        formatOptionLabel={(item, metadata) => {
          return metadata.context === 'menu' ? (
            <GptModelMenuOption
              model={item}
              showAdditionalDetails={!!customer && !canUseGpt4(customer)}
              slim={slim}
            />
          ) : (
            <GptModelValueOption model={item} slim={slim} />
          );
        }}
        disabled={disabled}
        menuAlignment={slim ? 'right' : 'left'}
        onChange={(option) => {
          if (!option) return;
          toggle(option);
        }}
      />
      {showDialog && (
        <ConfirmModal
          className="gpt-model-selector__upgrade-plan-modal"
          title={'Get our Advanced AI (Chat GPT-4)'}
          ariaHideApp={false}
          shouldReturnFocusAfterClose={false}
          footer={{
            type: 'show',
            applyButton: {
              label: `Upgrade to ${BILLING_PLAN_YEAR.displayName} plan`,
              variant: 'primary',
              onClick: () => upgradePlanMutation(),
            },
            cancelButton: {
              label: 'Cancel',
              onClick: () => {
                setShowDialog(false);
                if (!customer || !canUseGpt4(customer)) {
                  toggle(GPT_MODEL_3_5);
                }
                analyticsTrack(
                  analytics,
                  AnalyticsEvent.BILLING_PLAN_UPGRADE_CONFIRMATION_CANCELLED,
                  { source }
                );
              },
            },
          }}
        >
          <div className="gpt-model-selector__confirm-modal-container">
            <div className="growegy-title14--moderat">
              <div>
                Supercharge your business with smarter, more creative answers to your prompts.
              </div>
              <div>
                Spend less time preparing and more time selling, serving customers, and streamlining
                your operations.
              </div>
            </div>
            <AnnualPlanBenefitsWithTimeline outlineHeader={true} />
          </div>
        </ConfirmModal>
      )}
    </>
  );
};
