import React from 'react';
import clsx from 'clsx';
import { PromptVariableStringValueOrAdhoc } from '../../types';
import { compareStringValueOrAdhoc, getStringValueOrAdhocDisplayValue } from '../../util';

export const ValueList = (props: {
  values: PromptVariableStringValueOrAdhoc[];
  selectedValues: PromptVariableStringValueOrAdhoc[];
  onSelectionChanged: (value: PromptVariableStringValueOrAdhoc, isSelected: boolean) => void;
}) => {
  const { values, selectedValues, onSelectionChanged } = props;
  if (values.length === 0) return <></>;
  return (
    <div className="variable-message__values-container">
      {values
        .filter((x) => x.value || x.description)
        .map((x, i) => {
          const isSelected = selectedValues.some((selected) =>
            compareStringValueOrAdhoc(selected, x)
          );
          return (
            <button
              key={`value-${i}`}
              data-test="variable-message__value-pill"
              className={clsx('variable-message__value-pill', {
                'variable-message__value-pill--selected': isSelected,
              })}
              onClick={() => onSelectionChanged(x, !isSelected)}
            >
              {getStringValueOrAdhocDisplayValue(x)}
            </button>
          );
        })}
    </div>
  );
};
