import { ChatMessage, ResolvedChatMessage } from '../../types';
import { TextMessage } from './TextMessage';
import { useCurrentUser } from '../../../user/hooks/useCurrentUser';
import { ForwardedRef, forwardRef } from 'react';
import { IntroMessage } from './IntroMessage';
import { MaxMessagesLimitMessage } from './MaxMessagesLimitMessage';
import { ChatType } from '../../dto';

export const MessageList = forwardRef(
  (
    props: {
      chatType: ChatType;
      messages: ResolvedChatMessage[];
      maxAiResponses: number;
      hideIntro?: boolean;
      onIntroMessageSend: (content: string) => void;
      onSendAgain: (message: ChatMessage) => void;
      onOpenLibraryClick: () => void;
      onNewChatClick: () => void;
    },
    ref: ForwardedRef<HTMLDivElement>
  ) => {
    const {
      chatType,
      messages,
      maxAiResponses,
      hideIntro,
      onIntroMessageSend,
      onSendAgain,
      onOpenLibraryClick,
      onNewChatClick,
    } = props;

    const user = useCurrentUser();
    return (
      <>
        {messages.length > 0 ? (
          <>
            {messages.map((x, i) => (
              <TextMessage
                chatType={chatType}
                key={`${x.sender}-${x.clientId}`}
                message={x}
                user={user}
                onSendAgain={onSendAgain}
                ref={i === messages.length - 1 ? ref : undefined}
                maxAiResponses={maxAiResponses}
              />
            ))}
            {messages.some(
              (x) => x.aiMessageNumber !== undefined && x.aiMessageNumber >= maxAiResponses
            ) && (
              <MaxMessagesLimitMessage
                maxAiResponses={maxAiResponses}
                onNewChatClick={onNewChatClick}
              />
            )}
          </>
        ) : (
          !hideIntro && (
            <IntroMessage
              chatType={chatType}
              onSend={onIntroMessageSend}
              onOpenLibraryClick={onOpenLibraryClick}
            />
          )
        )}
      </>
    );
  }
);
