// noinspection JSUnusedGlobalSymbols

import React, { ReactElement, useMemo } from 'react';
import { DivButton } from './DivButton';
import { MoreIcon } from '../../assets/icons';
import { Dropdown } from 'react-bootstrap';
import { TextAndIcon } from './TextAndIcon';
import Dropzone, { FileRejection } from 'react-dropzone';

const MoreIconToggle = (dataTest: string, customButton?: () => ReactElement) =>
  React.forwardRef<HTMLDivElement, React.HTMLProps<HTMLDivElement>>(({ onClick }, ref) => (
    <div
      data-test={dataTest}
      // className="more-menu__menu-button"
      role="button"
      ref={ref}
      tabIndex={0}
      onClick={(e) => {
        e.preventDefault();
        e.stopPropagation();
        onClick && onClick(e);
      }}
      style={{ height: 'inherit' }}
    >
      {!!customButton ? customButton() : <DivButton icon={<MoreIcon />} />}
    </div>
  ));

type BaseMoreMenuItem = {
  type: 'standard' | 'upload' | 'divider';
  isHidden?: boolean;
};

export type StandardMoreMenuItem = BaseMoreMenuItem & {
  text: string;
  icon?: ReactElement;
  dataTest?: string;
};

export type ActionMoreMenuItem = StandardMoreMenuItem & {
  type: 'standard';
  onClick: () => void;
};

export type UploadMoreMenuItem = StandardMoreMenuItem & {
  type: 'upload';
  accept: string;
  onClick: <T extends File>(acceptedFiles: T[], fileRejections: FileRejection[]) => void;
};

export type DividerMoreMenuItem = BaseMoreMenuItem & {
  type: 'divider';
};

export const MoreMenu = (props: {
  menuItems: (ActionMoreMenuItem | UploadMoreMenuItem | DividerMoreMenuItem)[];
  menuButtonDataTest: string;
  menuDataTest?: string;
  customToggle?: () => ReactElement;
}) => {
  const { menuItems, menuButtonDataTest, menuDataTest, customToggle } = props;
  const toggle = useMemo(
    () => MoreIconToggle(menuButtonDataTest, customToggle),
    [customToggle, menuButtonDataTest]
  );
  return (
    <Dropdown drop="down" alignRight={false}>
      <Dropdown.Toggle as={toggle} className="d-flex align-items-center" />
      <Dropdown.Menu title="" data-test={menuDataTest}>
        {menuItems
          .filter((x) => !x.isHidden)
          .map((x) => {
            switch (x.type) {
              case 'standard':
                return (
                  <Dropdown.Item
                    key={x.dataTest ?? x.text}
                    onClick={(e) => {
                      e.stopPropagation();
                      x.onClick();
                    }}
                    data-test={x.dataTest}
                  >
                    <TextAndIcon text={x.text} icon={x.icon} />
                  </Dropdown.Item>
                );
              case 'upload':
                return (
                  <Dropzone
                    key={x.dataTest ?? x.text}
                    onDrop={(acceptedFiles, rejectedFiles) =>
                      x.onClick(acceptedFiles, rejectedFiles)
                    }
                    accept={x.accept}
                    multiple={false}
                    noClick={true}
                    noKeyboard={true}
                  >
                    {({ getRootProps, getInputProps, open }) => (
                      <div {...getRootProps({ className: 'dropzone' })}>
                        <input {...getInputProps()} />
                        <Dropdown.Item onClick={open} data-test={x.dataTest}>
                          {x.text}
                        </Dropdown.Item>
                      </div>
                    )}
                  </Dropzone>
                );
              case 'divider': {
                return <Dropdown.Divider />;
              }
              default: {
                return <></>;
              }
            }
          })}
      </Dropdown.Menu>
    </Dropdown>
  );
};
