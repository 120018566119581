import { QueryOptions } from '@tanstack/react-query';

export const userPoolId = process.env.REACT_APP_COGNITO_USER_POOL_ID!;
export const clientId = process.env.REACT_APP_COGNITO_CLIENT_ID!;

export const awsCognito = {
  UserPoolId: userPoolId,
  ClientId: clientId,
};

export const reactQueryConfig: QueryOptions = {
  retry: process.env.REACT_APP_REACT_QUERY_RETRY ? +process.env.REACT_APP_REACT_QUERY_RETRY : 3,
  retryDelay: process.env.REACT_APP_REACT_QUERY_RETRY_INTERVAL
    ? +process.env.REACT_APP_REACT_QUERY_RETRY_INTERVAL
    : (attemptIndex) => Math.min(1000 * 2 ** attemptIndex, 30000),
};

export const programSummariesLimit: number = process.env.REACT_APP_PROGRAM_SUMMARIES_LIMIT
  ? +process.env.REACT_APP_PROGRAM_SUMMARIES_LIMIT
  : 30;

export const notificationAutoCloseMillis: number = process.env.REACT_APP_NOTIFICATION_AUTO_CLOSE
  ? +process.env.REACT_APP_NOTIFICATION_AUTO_CLOSE
  : 2000;

export const notificationToastsLimit: number = process.env.REACT_APP_NOTIFICATION_TOASTS_LIMIT
  ? +process.env.REACT_APP_NOTIFICATION_TOASTS_LIMIT
  : 4;

function parseBooleanConfig(configValue: string | undefined) {
  return configValue == null ? false : configValue.toLowerCase() === 'true';
}

export const isCustomFieldFormulaEnabled: boolean = parseBooleanConfig(
  process.env.REACT_APP_PROGRAM_CUSTOM_FIELD_FORMULA_ENABLED
);

export const segmentEnabled: boolean = parseBooleanConfig(process.env.REACT_APP_SEGMENT_ENABLED);

export const segmentWriteKey = process.env.REACT_APP_SEGMENT_WRITE_KEY ?? '';

export const DebounceMsConfig = {
  programMultiFilterInputDebounceMs: process.env.REACT_APP_PROGRAM_MULTI_FILTER_INPUT_DEBOUNCE_MS
    ? +process.env.REACT_APP_PROGRAM_MULTI_FILTER_INPUT_DEBOUNCE_MS
    : 200,
  programEditInputDebounceMs: process.env.REACT_APP_PROGRAM_EDIT_INPUT_DEBOUNCE_MS
    ? +process.env.REACT_APP_PROGRAM_EDIT_INPUT_DEBOUNCE_MS
    : 300,
  tableGlobalFilterDebounceMs: process.env.REACT_APP_TABLE_GLOBAL_FILTER_DEBOUNCE_MS
    ? +process.env.REACT_APP_TABLE_GLOBAL_FILTER_DEBOUNCE_MS
    : 300,
};

export const FaroConfig = {
  url: process.env.REACT_APP_FARO_URL ? process.env.REACT_APP_FARO_URL : '',
  apiKey: process.env.REACT_APP_FARO_API_KEY ? process.env.REACT_APP_FARO_API_KEY : '',
};
