import { useMutation } from '@tanstack/react-query';
import { Chat, GptModel, MAX_CHAT_TITLE_LENGTH } from '../../types';
import useApi from '../../../api/backendApiContext';
import useAuth from '../../../auth/authContext';
import { useQueryCacheManager } from '../../../hooks/useQueryCacheManager';
import useAnalytics from '../../../web-analytics/webAnalyticsContext';
import { analyticsTrack } from '../../../web-analytics/webAnalytics';
import { AnalyticsEvent } from '../../../web-analytics/AnalyticsEvent';
import { ChatType } from '../../dto';
import { ComplexContent } from '../components/TextMessageInput';

const ELLIPSIS = '…';
const getTitle = (content: string) => {
  const title =
    content.length > MAX_CHAT_TITLE_LENGTH
      ? content
          .trim()
          .substring(0, MAX_CHAT_TITLE_LENGTH - ELLIPSIS.length)
          .trim() + ELLIPSIS
      : content.trim();
  return title ? title : 'New chat';
};

export const useCreateChatMutation = (
  onSuccess: (chat: Chat) => void,
  onError: (e: unknown, variables: { firstMessageContent: ComplexContent; model: GptModel }) => void
) => {
  const { growegyAIApi } = useApi();
  const {
    state: { user },
  } = useAuth();
  const queryCacheManager = useQueryCacheManager();
  const analytics = useAnalytics();
  return useMutation(
    async (props: { firstMessageContent: ComplexContent; model: GptModel; type: ChatType }) => {
      const { firstMessageContent, model, type } = props;
      const title = getTitle(firstMessageContent.plainTextContent);
      return growegyAIApi.createChat(user!.userId, model.id, title.replaceAll('\n', ' '), type);
    },
    {
      onSuccess: (chat) => {
        if (!chat) return;
        queryCacheManager.onChatCreated(chat);
        analyticsTrack(analytics, AnalyticsEvent.CHAT_CREATED, {
          type: chat.type,
          model: chat.model.id,
        });
        onSuccess(chat);
      },
      onError,
    }
  );
};
