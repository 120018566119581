import { ErrorMessage, Field } from 'formik';

type AttributeTextInputProps = {
  type: string;
  formikFieldName: string;
  fieldValue: string;
  dataTest: string;
  disabled: boolean;
  isError: boolean;
};

export const AttributeTextInput = ({
  type,
  formikFieldName,
  fieldValue,
  dataTest,
  disabled,
  isError,
}: AttributeTextInputProps) => (
  <>
    <Field
      type={type}
      name={formikFieldName}
      value={fieldValue}
      disabled={disabled}
      placeholder="Field value"
      autocomplete="nope"
      className={`integration-edit__input ${isError ? 'is-invalid' : ''}`}
      data-test={dataTest}
    />
    <ErrorMessage
      component="div"
      name={formikFieldName}
      className="invalid-feedback"
      data-test={`${dataTest}-validation-error`}
    />
  </>
);
