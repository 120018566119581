import { AxiosError } from 'axios';
import { usdFormatter } from '../program/util';
import { Analytics } from '@segment/analytics-next';
import { AnalyticsEvent } from '../web-analytics/AnalyticsEvent';
import { analyticsTrack } from '../web-analytics/webAnalytics';

export const nonBreakableSpace = '\u00a0';

export const enumFromValue = <T extends Record<string, string>>(
  val: string,
  _enum: T
): null | T[keyof T] => {
  const enumName = (Object.keys(_enum) as Array<keyof T>).find((k) => _enum[k] === val);
  if (!enumName) return null;
  return _enum[enumName];
};

type BackendError = {
  msg: string;
  status: null;
  userMsg: string | undefined;
};

export const isAxiosError = (err: unknown): err is AxiosError => {
  if (!err) return false;
  if (typeof err === 'object') {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const asErr = err as any;
    return asErr.isAxiosError === true;
  }
  return false;
};

const isBackendError = (err: unknown): err is BackendError => {
  if (typeof err === 'object') {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const asErr = err as any;
    return asErr.msg && asErr.status;
  }
  return false;
};

export const getHttpErrorCode = (error: unknown): number | null =>
  isAxiosError(error) ? error.response?.status ?? null : null;

export const errorToString = (error: unknown, defaultMessage?: string): string => {
  if (typeof error === 'string') return error;
  if (isAxiosError(error)) {
    const data = error.response?.data;
    if (data && isBackendError(data) && data.userMsg) {
      return data.userMsg;
    }
  }
  if (isBackendError(error) && error.userMsg) return error.userMsg;
  return defaultMessage ?? 'Something went wrong. Please try again later.';
};

export const errorToLoggingString = (error: unknown, defaultMessage?: string): string => {
  if (typeof error === 'string') return error;
  if (isAxiosError(error)) {
    const data = error.response?.data;
    if (data && isBackendError(data)) {
      return JSON.stringify(data);
    }
  }
  if (isBackendError(error)) return JSON.stringify(error);
  try {
    return JSON.stringify(error);
  } catch {
    return defaultMessage ?? 'Something went wrong. Please try again later.';
  }
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const extractBackendError = (error: any): string => {
  let msgErr: string = '';
  if (error) {
    if (error.response) {
      msgErr = error.response?.data?.msg;
    } else if (error.request) {
      msgErr = error.request?.data?.msg;
    } else {
      msgErr = error.message;
    }
  }
  return msgErr;
};

export const newRenderId = (() => {
  let id = 1;
  return () => {
    id += 1;
    return `${id}-${new Date().valueOf()}`;
  };
})();

export const stringToHslColor = (input: string): string => {
  const saturation = 58;
  const lightness = 55;
  let hash = 0;
  for (let i = 0; i < input.length; i++) {
    // eslint-disable-next-line no-bitwise
    hash = input.charCodeAt(i) + ((hash << 5) - hash);
  }

  const h = hash % 360;
  return `hsl(${h}, ${saturation}%, ${lightness}%)`;
};

export const hasValue = <TValue>(value: TValue | null | undefined): value is TValue =>
  value !== null && value !== undefined;
export const formatMoney = (value: string): string =>
  value !== null ? usdFormatter.format(isNaN(parseFloat(value)) ? 0 : parseFloat(value)) : '';
export const openUrlInNewTab = (
  baseUrl: string,
  analyticData: {
    analytics: Analytics | undefined;
    analyticsEvent: AnalyticsEvent;
    properties?: object;
  },
  path?: string
) => {
  analyticsTrack(
    analyticData.analytics,
    analyticData.analyticsEvent,
    analyticData.properties ?? {}
  );
  if (path) {
    const urlWithPath = baseUrl + (path.startsWith('/') ? path : `/${path}`);
    window.open(urlWithPath, '_blank', 'noopener,noreferrer');
  } else {
    window.open(baseUrl, '_blank', 'noopener,noreferrer');
  }
};
