import React, { useRef, useState } from 'react';
import { Overlay, Popover } from 'react-bootstrap';
import {
  DragDropContext,
  Draggable,
  DraggableProvided,
  DraggableStateSnapshot,
  Droppable,
  DropResult,
} from '@hello-pangea/dnd';
import clsx from 'clsx';
import { DragHandle } from '../../commons/components/DragHandle';
import { GearIcon } from '../../assets/icons';
import { ColumnsConfig } from '../containers/types';
import { DivButton } from '../../commons/components/DivButton';
import { getColumnName } from '../programColumns';

interface Props {
  columnsConfig: ColumnsConfig;
  onColumnsReorder: (result: DropResult) => void;
  onShowHideColumn: (key: string | null, shown: boolean) => void;
}

const renderColumnRow = (
  provided: DraggableProvided,
  snapshot: DraggableStateSnapshot,
  index: number,
  columnsConfig: ColumnsConfig,
  onShowHideColumn: (key: string | null, visible: boolean) => void
) => {
  const column = columnsConfig[index];
  return (
    <div
      ref={provided.innerRef}
      data-test={`programs-table-columns-selector--column-${index}`}
      className="programs-table-columns-selector--column"
      {...provided.draggableProps}
    >
      <div className="d-flex align-items-center">
        <DragHandle
          data-test={`programs-table-columns-selector--drag-handle-${index}`}
          {...provided.dragHandleProps}
        />
      </div>
      <div className="d-flex align-items-center w-100">
        <div className="switch-input-container w-100">
          <input
            type="checkbox"
            className="custom-control-input"
            id={`programs-table-columns-selector--column-check-${index}`}
            data-test={`programs-table-columns-selector--column-check-${index}`}
            onChange={() => onShowHideColumn(column.id, !column.visible)}
            checked={column.visible}
          />
          <label
            className="custom-control-label calendar-display-settings__check-label text-capitalize"
            htmlFor={`programs-table-columns-selector--column-check-${index}`}
          >
            {getColumnName(column.id)}
          </label>
        </div>
      </div>
    </div>
  );
};

const ColumnsSelector = ({ columnsConfig, onColumnsReorder, onShowHideColumn }: Props) => {
  const [show, setShow] = useState(false);
  const target = useRef(null);
  const configurableColumns = columnsConfig.slice(2); // we dont change type and name columns
  const allColumnsVisible = configurableColumns.every((col) => col.visible);
  return (
    <>
      <div
        className="m-0 p-0"
        data-test="programs-table-columns-selector__plus"
        ref={target}
        aria-label="Show columns"
        role="button"
        tabIndex={0}
        onClick={() => setShow(true)}
        onKeyDown={(event) => {
          if (event.key === 'Enter') setShow(true);
        }}
      >
        <DivButton icon={<GearIcon />} text="View" dataTest="programs-table-filter__button-title" />
      </div>
      <Overlay
        show={show}
        target={target.current}
        placement="bottom-start"
        rootClose
        onHide={() => setShow(false)}
      >
        <Popover id="popover-contained" className="programs-filter">
          <Popover.Content
            className="programs-table-columns-selector--content"
            data-test="programs-table-columns-selector__content"
          >
            <DragDropContext onDragEnd={onColumnsReorder}>
              <div
                data-test="programs-table-columns-selector--select-all"
                className="programs-table-columns-selector--select-all"
              >
                <div className="d-flex align-items-center w-100">
                  <div className="switch-input-container w-100">
                    <input
                      className="custom-control-input"
                      id="table-display-settings__show-column-select-all"
                      type="checkbox"
                      onChange={() => onShowHideColumn(null, !allColumnsVisible)}
                      checked={allColumnsVisible}
                    />
                    <label
                      className="custom-control-label calendar-display-settings__check-label"
                      htmlFor="table-display-settings__show-column-select-all"
                    >
                      Select all
                    </label>
                  </div>
                </div>
              </div>

              <Droppable
                droppableId="columns-list"
                direction="vertical"
                renderClone={(provided, snapshot, rubric) =>
                  renderColumnRow(
                    provided,
                    snapshot,
                    rubric.source.index,
                    configurableColumns,
                    onShowHideColumn
                  )
                }
              >
                {(provided, snapshot) => (
                  <div
                    className={clsx('row m-0 p-0 programs-table-columns-selector__drag--area', {
                      'programs-table-columns-selector__drag--area_dragging':
                        snapshot.isDraggingOver,
                    })}
                    ref={provided.innerRef}
                    {...provided.droppableProps}
                  >
                    {configurableColumns.map((column, index) => (
                      <Draggable draggableId={column.id} index={index} key={column.id}>
                        {(provided, snapshot) =>
                          renderColumnRow(
                            provided,
                            snapshot,
                            index,
                            configurableColumns,
                            onShowHideColumn
                          )
                        }
                      </Draggable>
                    ))}
                  </div>
                )}
              </Droppable>
            </DragDropContext>
          </Popover.Content>
        </Popover>
      </Overlay>
    </>
  );
};

export default ColumnsSelector;
