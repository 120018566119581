import React, { ChangeEvent, CSSProperties } from 'react';
import ReactDOM from 'react-dom';
import { useTextAreaWithAutoHeightStateRef } from '../../../hooks/useTextAreaWithAutoHeightRef';

const BorderHeight = 2;

export const VariableRowCell = ({
  origValue,
  onUpdate,
  placeholder,
  style,
  textMaxLength,
  forbidEmpty,
}: {
  origValue: string;
  placeholder: string;
  onUpdate: (newValue: string) => void;
  style?: CSSProperties | undefined;
  textMaxLength?: number | undefined;
  forbidEmpty?: boolean | undefined;
}) => {
  const { value, setValue, textAreaRef } = useTextAreaWithAutoHeightStateRef({
    borderHeight: BorderHeight,
    origValue,
  });

  const handleChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    const { value } = e.target;
    setValue(value);
  };

  const delayedBlur = () =>
    setTimeout(() => {
      if (textAreaRef.current) {
        textAreaRef.current.blur();
      }
    }, 50);

  const confirm = () => {
    if (value !== origValue) {
      if (!value.trim() && forbidEmpty) {
        ReactDOM.flushSync(() => setValue(origValue));
        return;
      }
      onUpdate(value);
      delayedBlur();
    }
  };

  const handleBlur = () => {
    confirm();
  };

  const cancel = () => {
    ReactDOM.flushSync(() => setValue(origValue));
    delayedBlur();
  };

  return (
    <div className="variables-table__name-cell" style={style}>
      <textarea
        className="variables-table__value-textarea text-wrap"
        data-test="variables-table__value-cell"
        placeholder={placeholder}
        value={value}
        maxLength={textMaxLength}
        onBlur={handleBlur}
        onChange={handleChange}
        style={{ textIndent: '0px' }}
        onKeyDown={(e) => {
          if (e.key === 'Escape') {
            e.preventDefault();
            cancel();
          }
          if (e.key === 'Enter') {
            e.preventDefault();
            confirm();
          }
        }}
        ref={textAreaRef}
        readOnly={false}
      />
    </div>
  );
};
