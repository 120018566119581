import { string, object } from 'yup';
import { Formik, Form } from 'formik';
import AuthInput from './AuthInput';
import PassInput from './PassInput';
import useAnalyticsPage from '../../web-analytics/hooks/useAnalyticsPage';
import { Page } from '../../web-analytics/Page';
import { Button } from 'react-bootstrap';
import { printAuthErrorMessage } from '../utils';

const validationSchema = object({
  email: string().email('Must contain a valid email address').required('Enter an email address'),
  password: string().required('Enter your password'),
});

type SigninPayload = {
  email: string;
  password: string;
};

const SignIn = (props: {
  onSignIn: (email: string, password: string) => Promise<void>;
  toForgotPassword: () => void;
}) => {
  useAnalyticsPage(Page.SIGN_IN);

  const { onSignIn, toForgotPassword } = props;
  const initialValues: SigninPayload = {
    email: '',
    password: '',
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={async (values, formikHelpers) => {
        formikHelpers.setStatus(null);
        await onSignIn(values.email, values.password).catch((err) =>
          formikHelpers.setStatus({ error: err })
        );
      }}
    >
      {({ touched, errors, isSubmitting, status }) => (
        <>
          <Form>
            <div className="auth-dialog">
              <div className="auth-dialog__header">
                <div className="auth-dialog__title">Sign in to your Growegy account</div>
              </div>
              <div className="auth-dialog__fields">
                <div className="auth-dialog__field">
                  <AuthInput
                    type="email"
                    name="email"
                    autoComplete="email"
                    placeholder="Enter email"
                    dataTest="sign-in__email"
                    invalid={!!touched.email && !!errors.email}
                  />
                </div>
                <div className="auth-dialog__field">
                  <PassInput
                    name="password"
                    autoComplete="current-password"
                    placeholder="Enter password"
                    dataTest="sign-in__password"
                    invalid={!!touched.password && !!errors.password}
                  />
                </div>
              </div>
              <div className="auth-dialog__info">
                <button
                  type="button"
                  className="btn btn-link p-0"
                  onClick={() => toForgotPassword()}
                  data-test="sign-in__forgot-password"
                >
                  Forgot your password?
                </button>

                {status && status.error && (
                  <div className="alert-danger fade show">
                    {printAuthErrorMessage(status.error)}
                  </div>
                )}
              </div>
              <div className="auth-dialog__footer">
                <Button
                  type="submit"
                  className="button-apply"
                  disabled={isSubmitting}
                  data-test="sign-in__submit"
                >
                  Sign in to account
                </Button>
              </div>
            </div>
          </Form>
        </>
      )}
    </Formik>
  );
};

export default SignIn;
