import { useQuery } from '@tanstack/react-query';
import useApi from '../../api/backendApiContext';
import { UserSettings } from '../../user/types';
import { UserApi } from '../../user/userApi';
import { useQueryCacheManager } from '../../hooks/useQueryCacheManager';
import { ReactQueryConstants } from '../../commons/constants';

const getEmptyUserSettings = (): UserSettings => ({
  firstName: '',
  lastName: '',
  title: '',
  location: '',
});

const getUserSettings = async (userId: string, userApi: UserApi): Promise<UserSettings> => {
  const serverSettings = await userApi.getUserSettings(userId);
  return serverSettings ?? getEmptyUserSettings();
};

export const useFetchedUserSettings = (userId: string) => {
  const { userApi } = useApi();
  const cacheManager = useQueryCacheManager();

  return useQuery(
    cacheManager.getUserSettingsCacheName(userId),
    async () => getUserSettings(userId, userApi),
    {
      refetchOnWindowFocus: false,
      staleTime: ReactQueryConstants.userSettingsStaleTime,
    }
  );
};
