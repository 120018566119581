import { useQuery } from '@tanstack/react-query';
import { IntegrationMetadatasCacheKey } from '../../hooks/useQueryCacheManager';
import useApi from '../../api/backendApiContext';
import { ReactQueryConstants } from '../../commons/constants';

export const useFetchedIntegrationMetadatas = () => {
  const { integrationApi } = useApi();
  return useQuery(IntegrationMetadatasCacheKey, async () => integrationApi.getMetadata(), {
    staleTime: ReactQueryConstants.integrationMetadatasStaleTime,
    refetchOnWindowFocus: false,
  });
};
