import { SpaLogger } from './spaLogger';
import { ICurrentUser } from '../currentuser/currentUser';

/* eslint-disable  class-methods-use-this */
export class ConsoleSpaLogger implements SpaLogger {
  info(message: string, ...optionalParams: any[]): void {
    console.info(message, optionalParams);
  }

  warn(message: string, ...optionalParams: any[]): void {
    console.warn(message, optionalParams);
  }

  error(message: string, ...optionalParams: any[]): void {
    console.error(message, optionalParams);
  }

  public pushEvent(
    name: string,
    attributes?: Record<string, string>,
    domain?: string,
    options?: {
      skipDedupe?: boolean;
    }
  ) {
    console.info('Event: ', name, attributes, domain, options);
  }

  public pushAlertEvent(
    name: string,
    attributes?: Record<string, string>,
    domain?: string,
    options?: {
      skipDedupe?: boolean;
    }
  ) {
    this.pushEvent(name, attributes, domain, options);
  }

  public setUser(user: ICurrentUser | null) {
    console.info('User: ', user);
  }

  public async wrapWithTelemetry<T extends unknown>(promise: Promise<T>): Promise<T> {
    return promise;
  }
}

/* eslint-enable  class-methods-use-this */
