import UserProfile from '../../currentuser/UserProfile';
import { UsersManagement } from '../../user/containers/UsersManagement';
import useAuth from '../../auth/authContext';
import { ProgramTypesManagement } from '../../program-type/containers/ProgamTypesManagement';
import { IntegrationsManagement } from '../../integrations/containers/IntegrationsManagement';
import { BillingManagement } from '../../billing/containers/BillingManagement';
import { NotificationSettings } from '../../notification/containers/NotificationSettings';
import { Permission } from '../../auth/permissions';
import { PleaseRelogin } from '../../auth/PleaseRelogin';
import { useFetchedUsers } from '../../user/hooks/useFetchedUsers';
import { nonBreakableSpace } from '../../util/utils';
import { isGoogleCalendarAvailable, isOutlookAvailable } from '../util';
import { PersonalIntegrations } from './PersonalIntegrations';
import { BillingPortal } from '../../billing/containers/BillingPortal';
import { billingPortalConfig } from '../../commons/config';
import {
  useHasFetchedNonPastDueIssuedInvoices,
  useHasFetchedPastDueInvoices,
} from '../../billing/hooks/useFetchedBillingInvoices';
import React from 'react';
import { pastDueVisualData, invoiceStatusVisualData } from '../../billing/types';
import { MultilevelTabPanel } from '../../commons/components/MultilevelTabPanel';

const BillingTabHeader = () => {
  const hasPastDueInvoices = useHasFetchedPastDueInvoices({ refetchOnWindowFocus: 'always' });
  const hasNonPastDueInvoices = useHasFetchedNonPastDueIssuedInvoices({
    refetchOnWindowFocus: 'always',
  });

  const badge = hasPastDueInvoices
    ? pastDueVisualData
    : hasNonPastDueInvoices
    ? { ...invoiceStatusVisualData.OPEN, statusDisplayName: 'Due' }
    : undefined;

  return (
    <div className="settings__tab-with-badge">
      <span className="growegy-font-em">Billing</span>
      {badge && (
        <span
          className="badge-container badge-container--small"
          data-test="settings__billing-badge"
          style={{
            borderColor: badge.textColor,
            color: badge.textColor,
          }}
        >
          {badge.statusDisplayName}
        </span>
      )}
    </div>
  );
};

const UsersHeader = () => {
  const { data: users } = useFetchedUsers();
  return (
    <div style={{ minWidth: '80px' }} className="growegy-font-em">
      {'Members · ' + (users ? `${users.length}` : '').padEnd(3, nonBreakableSpace)}
    </div>
  );
};

const Settings = () => {
  const {
    state: { user },
  } = useAuth();

  if (!user) return <PleaseRelogin />;

  return (
    <MultilevelTabPanel
      tabs={[
        {
          title: 'Team',
          dataTest: 'team-settings-tab',
          subTabs: [
            {
              id: 'team__billing',
              hasAccess: user.has(Permission.CHANGE_BILLING),
              dataTest: 'settings__billing',
              title: <BillingTabHeader />,
              body: billingPortalConfig.enabled ? <BillingPortal /> : <BillingManagement />,
            },
            {
              id: 'team__members',
              hasAccess: user.hasAtLeastOne(
                Permission.ADD_USER,
                Permission.UPDATE_USER,
                Permission.DELETE_USER
              ),
              dataTest: 'settings__users',
              title: <UsersHeader />,
              body: <UsersManagement />,
            },
            {
              id: 'team__program-types',
              hasAccess: user.has(Permission.REPLACE_PROGRAM_TYPES),
              dataTest: 'settings__program-types',
              title: 'Program types',
              body: <ProgramTypesManagement />,
            },
            {
              id: 'team__integrations',
              hasAccess: user.has(Permission.REPLACE_INTEGRATIONS),
              dataTest: 'settings__integrations',
              title: 'Integrations',
              body: <IntegrationsManagement />,
            },
          ],
        },
        {
          title: 'Personal',
          dataTest: 'personal-settings-tab',
          subTabs: [
            {
              id: 'personal__profile',
              hasAccess: true,
              dataTest: 'settings__profile',
              title: 'Profile',
              body: <UserProfile />,
            },
            {
              id: 'personal__integrations',
              hasAccess: isGoogleCalendarAvailable(user) || isOutlookAvailable(user),
              dataTest: 'settings__personal-integrations',
              title: 'Integrations',
              body: <PersonalIntegrations />,
            },
            {
              id: 'personal__notifications',
              hasAccess: true,
              dataTest: 'settings__notifications',
              title: 'Notifications',
              body: <NotificationSettings />,
            },
          ],
        },
      ]}
    />
  );
};

export default Settings;
