import _ from 'lodash';
import Fuse from 'fuse.js';
import { useFetchedPrompts } from '../hooks/useFetchedPrompts';
import { PromptPanel } from '../components/PromptPanel';
import { PromptCategoryPanel } from '../components/PromptCategoryPanel';
import React, { useEffect, useMemo, useState } from 'react';
import { PromptCategoryBreadcrumb } from '../components/PromptCategoryBreadcrumb';
import { Prompt, PromptCategory, PromptRecentUsage } from '../../types';
import { categoryColorAsProgramType, comparePromptCategories } from '../../util';
import { useChatContext } from '../../chat/chatContext';
import { useFetchedProgramTypes } from '../../../program/hooks/useFetchedProgramTypes';
import { PromptPreview } from '../../chat/components/PromptPreviewForm';
import { useFetchedPromptRecentUsages } from '../hooks/useFetchedPromptRecentUsages';
import { GlobalFilter } from '../../../program/components/GlobalFilter';
import { useFetchedPromptMarks } from '../hooks/useFetchedPromptMarks';
import { useUpdatePromptMarkMutation } from '../hooks/useUpdatePromptMarkMutation';
import { useNotification } from '../../../notification/notificationsContext';
import { AiStarsIcon, ClockIcon, StarIcon } from '../../../assets/icons';

export const LibraryTab = (props: { onPromptClicked: (prompt: Prompt) => void }) => {
  const { onPromptClicked } = props;
  const { notifyError } = useNotification();
  const existedTypes = useFetchedProgramTypes();
  const {
    query: { data: promptRecentUsagesFetchData },
  } = useFetchedPromptRecentUsages();
  const {
    query: { data: promptMarksFetchData },
  } = useFetchedPromptMarks();
  const {
    query: { data: promptsFetchData, isError: isPromptsFetchError },
    queryStatusComponent: promptQueryStatusComponent,
  } = useFetchedPrompts();
  const { getSelectedCategory, setSelectedCategory } = useChatContext();
  const { mutateAsync: updatePromptMarkMutation, error: promptMarkUpdateError } =
    useUpdatePromptMarkMutation();

  useEffect(() => {
    if (promptMarkUpdateError) {
      notifyError({
        err: promptMarkUpdateError,
        logMsg: `Failed to update prompt mark`,
        notificationMsg: 'Update failed',
      });
    }
  }, [promptMarkUpdateError, notifyError]);

  const [currentCategory, setCurrentCategory] = useState<PromptCategory | null>(() => {
    const currentCategory = getSelectedCategory();
    if (!currentCategory || !promptsFetchData) return null;
    return promptsFetchData.categories.find((x) => x.name === currentCategory) ?? null;
  });
  const [modalState, setModalState] = useState<Prompt | null>(null);
  const [textFilterValue, setTextFilterValue] = useState<string>('');
  const onTextFilterChange = (value?: string) => {
    setTextFilterValue(value ?? '');
  };
  const onModalConfirmClicked = (promptToSubmit: Prompt) => {
    setModalState(null);
    onPromptClicked(promptToSubmit);
  };

  const onPromptMarkUpdate = async (promptId: string, mark: number) => {
    await updatePromptMarkMutation({ promptId, mark, updatedDate: new Date() });
  };

  const categoryFilteredPrompts = useMemo(
    () =>
      promptsFetchData
        ? promptsFetchData.prompts.filter(
            (x) =>
              currentCategory === null ||
              x.categories.some((c) => comparePromptCategories(c, currentCategory.name))
          )
        : null,
    [promptsFetchData, currentCategory]
  );

  const promptMarksMap = useMemo(
    () => (promptMarksFetchData ? new Map(promptMarksFetchData.map((x) => [x.promptId, x])) : null),
    [promptMarksFetchData]
  );

  const hasPromptNoMark = (promptId: string) => promptMarksMap?.get(promptId)?.mark !== 1;

  const promptsFuse = useMemo(
    () =>
      new Fuse<Prompt>(categoryFilteredPrompts ?? [], {
        keys: ['title', 'content', 'parsedContent'],
        threshold: 0.3,
        useExtendedSearch: true,
      }),
    [categoryFilteredPrompts]
  );

  const mainCollectionPrompts = useMemo(() => {
    if (!textFilterValue || !categoryFilteredPrompts) return categoryFilteredPrompts;
    const search = `"${textFilterValue}"`;
    const exact = new Set<string>(promptsFuse.search("'" + search).map((res) => res.item.id));
    const fuzzy = new Set<string>(promptsFuse.search(search).map((res) => res.item.id));

    return categoryFilteredPrompts.filter((x) => exact.has(x.id) || fuzzy.has(x.id));
  }, [promptsFuse, textFilterValue, categoryFilteredPrompts]);

  const recentlyUsedPromptsMap: Map<string, PromptRecentUsage> = useMemo(() => {
    const mainCollectionPromptIdSet = new Set<string>(
      (mainCollectionPrompts ?? []).map((x) => x.id)
    );
    const result = new Map<string, PromptRecentUsage>();
    _(promptRecentUsagesFetchData ?? [])
      .filter((x) => mainCollectionPromptIdSet.has(x.promptId))
      .groupBy((x) => x.promptId)
      .map((usages, promptId) => {
        const promptRecentUsage = _(usages).maxBy((x) => x.updatedDate?.valueOf() ?? 0)!;
        return { promptId, promptRecentUsage };
      })
      .forEach((x) => result.set(x.promptId, x.promptRecentUsage));
    return result;
  }, [promptRecentUsagesFetchData, mainCollectionPrompts]);

  const recentlyUsedFilteredPrompts = useMemo(
    () =>
      categoryFilteredPrompts
        ? categoryFilteredPrompts
            .map((x) => ({
              prompt: x,
              recentUsage: recentlyUsedPromptsMap.get(x.id),
            }))
            .filter((x) => x.recentUsage)
            .sort(
              (x, y) =>
                (y.recentUsage?.updatedDate?.valueOf() ?? 0) -
                (x.recentUsage?.updatedDate?.valueOf() ?? 0)
            )
            .map((x) => x.prompt)
            .slice(0, 6)
        : null,
    [categoryFilteredPrompts, recentlyUsedPromptsMap]
  );

  const favourites = useMemo(() => {
    const mainCollectionIdToPromptMap = new Map<string, Prompt>(
      (mainCollectionPrompts ?? []).map((x) => [x.id, x])
    );
    return (promptMarksFetchData ?? [])
      .filter((x) => x.mark === 1)
      .sort((x, y) => (y.updatedDate?.valueOf() ?? 0) - (x.updatedDate?.valueOf() ?? 0))
      .flatMap((x) => {
        const prompt = mainCollectionIdToPromptMap.get(x.promptId);
        if (!prompt) return [];
        return [prompt];
      });
  }, [mainCollectionPrompts, promptMarksFetchData]);

  if (isPromptsFetchError) {
    return promptQueryStatusComponent;
  }

  return categoryFilteredPrompts && promptsFetchData && existedTypes.data ? (
    <div className="overflow-auto">
      <div className="d-flex justify-content-center">
        <div className="library-table__search-wrapper" data-test="library-table__search-wrapper">
          <GlobalFilter
            filterValue={textFilterValue}
            setFilterValue={onTextFilterChange}
            alwaysExpanded={true}
            placeholder="Search for prompts"
            dataTest="prompt-library__search-input"
          />
        </div>
      </div>
      {currentCategory ? (
        <PromptCategoryBreadcrumb
          category={{
            ...currentCategory,
            color: categoryColorAsProgramType(currentCategory, existedTypes.data?.types ?? []),
          }}
          onClickBack={() => {
            setCurrentCategory(null);
            setSelectedCategory(null);
          }}
        />
      ) : (
        <div data-test="prompt-library__categories" className="prompt-library__categories">
          {promptsFetchData.categories.map((x) => (
            <PromptCategoryPanel
              key={x.name}
              category={{
                ...x,
                color: categoryColorAsProgramType(x, existedTypes.data?.types ?? []),
              }}
              onClick={(c) => {
                setCurrentCategory(c);
                setSelectedCategory(c.name);
              }}
            />
          ))}
        </div>
      )}

      {currentCategory === null && (
        <div
          data-test="prompt-library__recently-used"
          className="prompt-library__prompts-group prompt-library__prompts-group--margin-top"
        >
          <div
            className="prompt-library__prompts-group--header"
            data-test="prompt-library__group--header"
          >
            <ClockIcon />
            <span className="prompt-library__header-text">Recently Used</span>
          </div>
          {!!recentlyUsedFilteredPrompts?.length && (
            <div
              className="prompt-library__prompts"
              data-test="prompt-library__recently-used--prompts"
            >
              {recentlyUsedFilteredPrompts.map((x) => (
                <PromptPanel
                  key={x.id}
                  prompt={x}
                  onPromptClicked={setModalState}
                  promptMark={promptMarksMap?.get(x.id) ?? null}
                  onPromptMark={async (mark) => onPromptMarkUpdate(x.id, mark)}
                  asteriskMarkIsFlippable={hasPromptNoMark(x.id)}
                  existedCategories={promptsFetchData.categories ?? []}
                  existedTypes={existedTypes.data?.types ?? []}
                />
              ))}
            </div>
          )}
          {!recentlyUsedFilteredPrompts?.length && (
            <div className="prompt-library__prompts--no-prompts-fallback">
              <span>
                No recently used prompts so far. Explore prompts categories above and recently used
                prompts will be displayed here.
              </span>
            </div>
          )}
        </div>
      )}

      {currentCategory === null && (
        <div
          data-test="prompt-library__favourite-prompts"
          className="prompt-library__prompts-group"
        >
          <div
            className="prompt-library__prompts-group--header"
            data-test="prompt-library__group--header"
          >
            <StarIcon />
            <span className="prompt-library__header-text"> Favorites</span>
          </div>
          {!!favourites?.length && (
            <div
              className="prompt-library__prompts"
              data-test="prompt-library__favourite-prompts--prompts"
            >
              {favourites.map((x) => (
                <PromptPanel
                  key={x.id}
                  prompt={x}
                  onPromptClicked={setModalState}
                  promptMark={promptMarksMap?.get(x.id) ?? null}
                  onPromptMark={async (mark) => onPromptMarkUpdate(x.id, mark)}
                  asteriskMarkIsFlippable={true}
                  existedCategories={promptsFetchData.categories ?? []}
                  existedTypes={existedTypes.data?.types ?? []}
                />
              ))}
            </div>
          )}
          {!favourites?.length && (
            <div className="prompt-library__prompts--no-prompts-fallback">
              <span>
                No favourite prompts so far. Explore prompts categories above and add favorite
                prompts to your collection.
              </span>
            </div>
          )}
        </div>
      )}

      <div data-test="prompt-library__main-prompts" className="prompt-library__prompts-group">
        <div
          className="prompt-library__prompts-group--header"
          data-test="prompt-library__group--header"
        >
          <AiStarsIcon />
          <span className="prompt-library__header-text">All Prompts</span>
        </div>
        <div data-test="prompt-library__prompts" className="prompt-library__prompts">
          {(mainCollectionPrompts ?? []).map((x) => (
            <PromptPanel
              key={x.id}
              prompt={x}
              onPromptClicked={setModalState}
              promptMark={promptMarksMap?.get(x.id) ?? null}
              onPromptMark={async (mark) => onPromptMarkUpdate(x.id, mark)}
              asteriskMarkIsFlippable={hasPromptNoMark(x.id)}
              existedCategories={promptsFetchData.categories ?? []}
              existedTypes={existedTypes.data?.types ?? []}
            />
          ))}
        </div>
      </div>

      {modalState && (
        <PromptPreview
          showModal={!!modalState}
          prompt={modalState}
          promptMark={promptMarksMap?.get(modalState.id) ?? null}
          handleHideClick={() => setModalState(null)}
          handleApplyClick={onModalConfirmClicked}
          onPromptMark={async (mark) => onPromptMarkUpdate(modalState.id, mark)}
          existedTypes={existedTypes.data?.types ?? []}
          existedCategories={promptsFetchData.categories ?? []}
        />
      )}
    </div>
  ) : (
    promptQueryStatusComponent
  );
};
