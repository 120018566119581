/* eslint-disable react-hooks/rules-of-hooks */
import { ProgramPartialUpdateField, ProgramRow } from '../../types';
import { ChangeEvent, MouseEvent, useEffect, useRef, useState } from 'react';
import clsx from 'clsx';
import { CellProps } from './ProgramCells';

import { formatMoney } from '../../../util/utils';

const MoneyCell =
  (dataTest: string, field: ProgramPartialUpdateField) =>
  ({
    onProgramUpdate,
    onRowEditChange,
    value: origValue,
    row,
    readOnly,
  }: CellProps<ProgramRow, number>) => {
    const [value, setValue] = useState<string>(`${origValue ?? ''}`);
    const [editing, setEditing] = useState(false);
    const inputRef = useRef<HTMLInputElement>(null);

    useEffect(() => {
      setValue(`${origValue ?? ''}`);
    }, [origValue]);

    useEffect(() => {
      if (!inputRef.current) return;
      inputRef.current?.focus();
    }, [editing]);

    function handleEditClick(e: MouseEvent<HTMLDivElement>) {
      if (row.isGrouped) return;
      e.stopPropagation();
      if (readOnly) return;
      if (editing) return;
      setEditing(true);
      onRowEditChange(true);
    }

    function handleChange(e: ChangeEvent<HTMLInputElement>) {
      const { value } = e.target;
      setValue(value);
    }

    function confirm() {
      const v = value === '' ? null : parseFloat(value);
      if (v !== null && isNaN(v)) {
        cancel();
        return;
      }

      const originalValue =
        field.type === 'StaticField'
          ? row.original[field.fieldName]
          : row.original.customFields.find(
              ({ name, type }) =>
                field.arrayItemValue.name === name && field.arrayItemValue.type === type
            );

      if (
        (originalValue === null && v !== null) ||
        (typeof originalValue === 'number' && v !== originalValue)
      ) {
        if (field.type === 'StaticField' && field.fieldName === 'budget') {
          onProgramUpdate({
            original: row.original,
            updates: [{ field: field.fieldName, value: v }],
          });
        }
      }
      setEditing(false);
      onRowEditChange(false);
    }

    function cancel() {
      setValue(`${origValue ?? ''}`);
      setEditing(false);
      onRowEditChange(false);
    }

    return (
      <div
        data-test={dataTest}
        onClick={handleEditClick}
        onKeyDown={(e) => {
          if (editing) e.stopPropagation();
        }}
      >
        {editing ? (
          <input
            ref={inputRef}
            className="programs-table__cell-input"
            value={value ?? ''}
            onBlur={confirm}
            onChange={handleChange}
            onKeyDown={(e) => {
              if (e.key === 'Escape') cancel();
              if (e.key === 'Enter') confirm();
            }}
            readOnly={readOnly}
          />
        ) : (
          <div
            className={clsx('programs-table__editable-cell', {
              'programs-table__cell-edit-disabled': row.isGrouped || readOnly,
            })}
          >
            {formatMoney(value)}
          </div>
        )}
      </div>
    );
  };

export default MoneyCell;
