import Select from 'react-select';
import React from 'react';

type FormikMultiSelectProps<T> = {
  defaultValues: T[];
  availableValues: T[];
  onChange: (value: T[]) => void;
  toOption: (u: T) => { label: string; value: string };
  className: string;
  classNamePrefix: string;
  isSearchable?: boolean;
};

export const FormikMultiSelect = <T extends unknown>({
  defaultValues,
  availableValues,
  onChange,
  toOption,
  className,
  classNamePrefix,
  isSearchable,
}: FormikMultiSelectProps<T>) => (
  <>
    <div className="d-flex flex-column">
      <Select
        isMulti
        value={defaultValues.map((u) => ({ ...toOption(u), original: u }))}
        defaultValue={defaultValues.map((u) => ({ ...toOption(u), original: u }))}
        isClearable={false}
        components={{ IndicatorSeparator: () => null }}
        isSearchable={isSearchable ?? false}
        options={availableValues.map((u) => ({ ...toOption(u), original: u }))}
        onChange={(values) => onChange(values.map((u) => u.original))}
        className={className}
        classNamePrefix={classNamePrefix}
        styles={{
          menu: (provided) => ({
            ...provided,
            borderRadius: '8px',
          }),
          control: (provided) => ({
            ...provided,
            borderRadius: '8px',
          }),
        }}
      />
    </div>
  </>
);
