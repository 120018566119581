import _ from 'lodash';
import { MultiFilterFn, MultiFilterInstance } from './types';

const contains =
  <T>(propGetter: (s: T) => unknown): MultiFilterFn<T> =>
  (value: unknown) =>
  (entity: T) => {
    const propertyValue = propGetter(entity);
    return typeof propertyValue === 'string' && typeof value === 'string'
      ? propertyValue.toLowerCase().includes(value.toLowerCase())
      : false;
  };

const doesNotContain =
  <T>(propGetter: (s: T) => unknown): MultiFilterFn<T> =>
  (value: unknown) =>
  (entity: T) => {
    const propertyValue = propGetter(entity);
    return typeof propertyValue === 'string' && typeof value === 'string'
      ? !propertyValue.toLowerCase().includes(value.toLowerCase())
      : false;
  };

const isEqual =
  <T>(propGetter: (s: T) => unknown): MultiFilterFn<T> =>
  (value: unknown) =>
  (entity: T) => {
    const propertyValue = propGetter(entity);
    return typeof propertyValue === 'string' && typeof value === 'string'
      ? propertyValue.toLowerCase() === value.toLowerCase()
      : propertyValue === value;
  };

const isNotEqual =
  <T>(propGetter: (s: T) => unknown): MultiFilterFn<T> =>
  (value: unknown) =>
  (entity: T) => {
    const propertyValue = propGetter(entity);
    return typeof propertyValue === 'string' && typeof value === 'string'
      ? propertyValue.toLowerCase() !== value.toLowerCase()
      : propertyValue !== value;
  };

const isEmpty =
  <T>(propGetter: (s: T) => unknown): MultiFilterFn<T> =>
  () =>
  (entity: T) => {
    const propertyValue = propGetter(entity);
    return typeof propertyValue === 'string' ? propertyValue.length === 0 : false;
  };

const isNotEmpty =
  <T>(propGetter: (s: T) => unknown): MultiFilterFn<T> =>
  () =>
  (entity: T) => {
    const propertyValue = propGetter(entity);
    return typeof propertyValue === 'string' ? propertyValue.length > 0 : false;
  };

const isGreater =
  <T>(propGetter: (s: T) => unknown): MultiFilterFn<T> =>
  (value: unknown) =>
  (entity: T) => {
    const propertyValue = propGetter(entity);
    return typeof propertyValue === 'number' && typeof value === 'number'
      ? propertyValue > value
      : false;
  };

const isGreaterOrEqual =
  <T>(propGetter: (s: T) => unknown): MultiFilterFn<T> =>
  (value: unknown) =>
  (entity: T) => {
    const propertyValue = propGetter(entity);
    return typeof propertyValue === 'number' && typeof value === 'number'
      ? propertyValue >= value
      : false;
  };

const isLess =
  <T>(propGetter: (s: T) => unknown): MultiFilterFn<T> =>
  (value: unknown) =>
  (entity: T) => {
    const propertyValue = propGetter(entity);
    return typeof propertyValue === 'number' && typeof value === 'number'
      ? propertyValue < value
      : false;
  };

const isLessOrEqual =
  <T>(propGetter: (s: T) => unknown): MultiFilterFn<T> =>
  (value: unknown) =>
  (entity: T) => {
    const propertyValue = propGetter(entity);
    return typeof propertyValue === 'number' && typeof value === 'number'
      ? propertyValue <= value
      : false;
  };

const isOnDate =
  <T>(propGetter: (s: T) => unknown): MultiFilterFn<T> =>
  (value: unknown) =>
  (entity: T) => {
    const propertyValue = propGetter(entity);
    return propertyValue instanceof Date && value instanceof Date
      ? propertyValue.valueOf() === value.valueOf()
      : false;
  };

const isNotOnDate =
  <T>(propGetter: (s: T) => unknown): MultiFilterFn<T> =>
  (value: unknown) =>
  (entity: T) => {
    const propertyValue = propGetter(entity);
    return propertyValue instanceof Date && value instanceof Date
      ? propertyValue.valueOf() !== value.valueOf()
      : false;
  };

const isOnDateOrAfter =
  <T>(propGetter: (s: T) => unknown): MultiFilterFn<T> =>
  (value: unknown) =>
  (entity: T) => {
    const propertyValue = propGetter(entity);
    return propertyValue instanceof Date && value instanceof Date
      ? propertyValue.valueOf() >= value.valueOf()
      : false;
  };

const isAfterDate =
  <T>(propGetter: (s: T) => unknown): MultiFilterFn<T> =>
  (value: unknown) =>
  (entity: T) => {
    const propertyValue = propGetter(entity);
    return propertyValue instanceof Date && value instanceof Date
      ? propertyValue.valueOf() > value.valueOf()
      : false;
  };

const isOnDateOrBefore =
  <T>(propGetter: (s: T) => unknown): MultiFilterFn<T> =>
  (value: unknown) =>
  (entity: T) => {
    const propertyValue = propGetter(entity);
    return propertyValue instanceof Date && value instanceof Date
      ? propertyValue.valueOf() <= value.valueOf()
      : false;
  };

const isBeforeDate =
  <T>(propGetter: (s: T) => unknown): MultiFilterFn<T> =>
  (value: unknown) =>
  (entity: T) => {
    const propertyValue = propGetter(entity);
    return propertyValue instanceof Date && value instanceof Date
      ? propertyValue.valueOf() < value.valueOf()
      : false;
  };

const isOneOf =
  <T>(
    entityPropGetter: (s: T) => unknown,
    arrayItemPropertyGetter: (s: unknown) => unknown
  ): MultiFilterFn<T> =>
  (value: unknown) =>
  (entity: T) => {
    const propertyValue = entityPropGetter(entity);
    if (value instanceof Array) {
      if (value.length === 0) return true;
      return value.some((v) => _.isEqual(arrayItemPropertyGetter(v), propertyValue));
    }

    return false;
  };

const isNotOneOf =
  <T>(
    entityPropGetter: (s: T) => unknown,
    arrayItemPropertyGetter: (s: unknown) => unknown
  ): MultiFilterFn<T> =>
  (value: unknown) =>
  (entity: T) => {
    const propertyValue = entityPropGetter(entity);
    if (value instanceof Array) {
      if (value.length === 0) return true;
      return value.every((v) => !_.isEqual(arrayItemPropertyGetter(v), propertyValue));
    }

    return false;
  };

export const BooleanFilters = <T>(propGetter: (s: T) => unknown): MultiFilterInstance<T>[] => [
  {
    name: `is`,
    paramsCount: 1,
    fn: isEqual(propGetter),
  },
];

export const StringFilters = <T>(propGetter: (s: T) => unknown): MultiFilterInstance<T>[] => [
  {
    name: 'contains',
    paramsCount: 1,
    fn: contains(propGetter),
  },
  {
    name: `doesn't contain`,
    paramsCount: 1,
    fn: doesNotContain(propGetter),
  },
  {
    name: `is`,
    paramsCount: 1,
    fn: isEqual(propGetter),
  },
  {
    name: `is not`,
    paramsCount: 1,
    fn: isNotEqual(propGetter),
  },
  {
    name: `is empty`,
    paramsCount: 0,
    fn: isEmpty(propGetter),
  },
  {
    name: `is not empty`,
    paramsCount: 0,
    fn: isNotEmpty(propGetter),
  },
];

export const NumberFilters = <T>(propGetter: (s: T) => unknown): MultiFilterInstance<T>[] => [
  {
    name: `=`,
    paramsCount: 1,
    fn: isEqual(propGetter),
  },
  {
    name: `≠`,
    paramsCount: 1,
    fn: isNotEqual(propGetter),
  },
  {
    name: `>`,
    paramsCount: 1,
    fn: isGreater(propGetter),
  },
  {
    name: `>=`,
    paramsCount: 1,
    fn: isGreaterOrEqual(propGetter),
  },
  {
    name: `<`,
    paramsCount: 1,
    fn: isLess(propGetter),
  },
  {
    name: `<=`,
    paramsCount: 1,
    fn: isLessOrEqual(propGetter),
  },
];

export const DateFilters = <T>(propGetter: (s: T) => unknown): MultiFilterInstance<T>[] => [
  {
    name: `is`,
    paramsCount: 1,
    fn: isOnDate(propGetter),
  },
  {
    name: `is not`,
    paramsCount: 1,
    fn: isNotOnDate(propGetter),
  },
  {
    name: `is on or after`,
    paramsCount: 1,
    fn: isOnDateOrAfter(propGetter),
  },
  {
    name: `is after`,
    paramsCount: 1,
    fn: isAfterDate(propGetter),
  },
  {
    name: `is on or before`,
    paramsCount: 1,
    fn: isOnDateOrBefore(propGetter),
  },
  {
    name: `is before`,
    paramsCount: 1,
    fn: isBeforeDate(propGetter),
  },
];

export const MultiSelectFilters = <T>(
  entityPropGetter: (s: T) => unknown,
  arrayItemPropertyGetter: (s: unknown) => unknown
): MultiFilterInstance<T>[] => [
  {
    name: `is one of`,
    paramsCount: 1,
    fn: isOneOf(entityPropGetter, arrayItemPropertyGetter),
  },
  {
    name: `is not one of`,
    paramsCount: 1,
    fn: isNotOneOf(entityPropGetter, arrayItemPropertyGetter),
  },
];
