import React, { ReactElement } from 'react';
import { NavLink, useMatch } from 'react-router-dom';
import useAuth from '../auth/authContext';
import { AppRoute, AppRoutes } from '../router/AppRoutes';
import VisibleResource from './VisibleResource';
import {
  AiStarsIcon,
  AnalyticsIcon,
  BulbIcon,
  CalendarIcon,
  LogoGrowegy,
  PowerIcon,
  SettingsIcon,
  TableIcon,
} from '../assets/icons';
import clsx from 'clsx';
import {
  useIsNewVersionPersonalRecommendations,
  usePersonalRecommendations,
} from '../recommendations/hooks/usePersonalRecommendations';
import { ICurrentUser } from '../currentuser/currentUser';
import { Permission } from '../auth/permissions';
import { growegyAIConfig } from '../commons/config';
import { StyleVariables } from '../commons/styleConstants';
import { SanityChecker } from '../meta/components/SanityChecker';
import { useHasFetchedPastDueInvoices } from '../billing/hooks/useFetchedBillingInvoices';
import { useIsTabletOrLarger } from '../responsive/hooks/hooks';

const SideBarLink = (props: {
  to: AppRoute;
  alternativeTo?: AppRoute;
  secondAlternativeTo?: AppRoute;
  header: string;
  icon: (props: { fill: string }) => ReactElement;
  subheader?: string;
  badge?: string | null;
}) => {
  const { to, alternativeTo, secondAlternativeTo, header, icon, subheader, badge } = props;
  const isPrimaryActive = useMatch(to)!!;
  const isAlternativeActive = useMatch(alternativeTo ?? 'not-matching-path');
  const isSecondAlternativeToActive = useMatch(secondAlternativeTo ?? 'not-matching-path');
  const isActive =
    isPrimaryActive ||
    (alternativeTo && isAlternativeActive) ||
    (secondAlternativeTo && isSecondAlternativeToActive);
  const color = isActive ? StyleVariables.neutral900 : StyleVariables.neutral500;
  return (
    <NavLink className="app-sidebar__item" data-test="side-bar__link" to={to}>
      <div
        className={clsx('app-sidebar__item-icon', { active: isActive })}
        data-test="app-sidebar__item-icon"
      >
        {icon({ fill: color })}
        {badge && (
          <div className="app-sidebar__item-indicator-container">
            <div className="app-sidebar__item-indicator">
              <span
                className="app-sidebar__item-indicator-number"
                data-test="app-sidebar__badge-value"
              >
                {badge}
              </span>
            </div>
          </div>
        )}
      </div>
      <div className="app-sidebar__item-header" data-test="side-bar__link-header" style={{ color }}>
        {header}
      </div>
      {subheader ? <div className="app-sidebar__item-badge">{subheader}</div> : null}
    </NavLink>
  );
};

const SideBarLogo = () => (
  <div className="app-sidebar__logo" data-test="app-sidebar__logo">
    <img src={LogoGrowegy} alt="" />
  </div>
);

const SideBarCalendar = (props: { asHome?: boolean }) => {
  const { asHome } = props;
  return (
    <VisibleResource
      to={asHome ? AppRoutes.Home : AppRoute.calendar}
      render={(to) => (
        <SideBarLink
          to={to}
          alternativeTo={AppRoute.calendar}
          header="Calendar"
          icon={(props: { fill: string }) => <CalendarIcon fill={props.fill} />}
        />
      )}
    />
  );
};

const SideBarPrograms = () => (
  <VisibleResource
    to={AppRoutes.Program.programs}
    render={(to) => (
      <SideBarLink
        to={to}
        header="Table"
        icon={(props: { fill: string }) => <TableIcon fill={props.fill} />}
      />
    )}
  />
);

const SideBarGrowegyAI = (props: { user: ICurrentUser }) => {
  if (!growegyAIConfig.enabled || !props.user.has(Permission.USE_CHAT_GPT)) return null;

  return (
    <VisibleResource
      to={AppRoutes.GrowegyAI}
      render={(to) => (
        <SideBarLink
          to={to}
          header="Growegy AI"
          icon={(props: { fill: string }) => <AiStarsIcon fill={props.fill} />}
        />
      )}
    />
  );
};

const SideBarAnalytics = (props: { user: ICurrentUser }) => {
  const { user } = props;
  if (!user.has(Permission.GET_DATA_SERIES) && !user.has(Permission.GET_GROUPED_DATA)) return null;

  return (
    <VisibleResource
      to={AppRoutes.Analytics}
      render={(to) => (
        <SideBarLink
          to={to}
          header="Analytics"
          icon={(props: { fill: string }) => <AnalyticsIcon fill={props.fill} />}
          subheader="Beta"
        />
      )}
    />
  );
};

function SideBarRecommendations(props: { user: ICurrentUser }) {
  const { user } = props;
  const hasNewRecommendations = useIsNewVersionPersonalRecommendations();
  const { data: recommendations } = usePersonalRecommendations('');

  if (
    !user.has(Permission.GET_TENANT_RECOMMENDATIONS) ||
    !recommendations ||
    recommendations.recommendations.length === 0
  )
    return null;

  return (
    <VisibleResource
      to={AppRoutes.Recommendations}
      render={(to) => (
        <SideBarLink
          to={to}
          header="Insights"
          icon={(props: { fill: string }) => <BulbIcon fill={props.fill} />}
          badge={hasNewRecommendations ? '1' : null}
        />
      )}
    />
  );
}

const SideBarSettings = (props: { asHome?: boolean }) => {
  const hasPastDueInvoices = useHasFetchedPastDueInvoices();
  const { asHome } = props;
  return (
    <VisibleResource
      to={asHome ? AppRoute.home : AppRoutes.Settings}
      render={(to) => (
        <SideBarLink
          to={to}
          secondAlternativeTo={AppRoute.settings}
          alternativeTo={AppRoute.billing}
          header="Settings"
          icon={(props: { fill: string }) => <SettingsIcon fill={props.fill} />}
          badge={hasPastDueInvoices ? '1' : null}
        />
      )}
    />
  );
};

const SideBarNavigation = (props: { user: ICurrentUser }) => {
  const { user } = props;
  const isTabletOrLarger = useIsTabletOrLarger();

  return (
    <nav className="app-sidebar__navigation">
      <div className="app-sidebar__navigation-items">
        {isTabletOrLarger ? (
          <>
            <SideBarCalendar asHome={true} />
            <SideBarPrograms />
            <SideBarGrowegyAI user={user} />
            <SideBarAnalytics user={user} />
            <SideBarRecommendations user={user} />
            <SideBarSettings />
          </>
        ) : (
          <>
            <SideBarSettings asHome={true} />
            <SideBarCalendar />
            <SideBarGrowegyAI user={user} />
            <SideBarSignOut />
          </>
        )}
      </div>
    </nav>
  );
};

const SideBarSignOut = () => (
  <VisibleResource
    to={AppRoutes.Auth.signOut}
    render={(to) => (
      <SideBarLink
        to={to}
        header="Sign out"
        icon={(props: { fill: string }) => <PowerIcon fill={props.fill} />}
      />
    )}
  />
);
export const SideBar = () => {
  const {
    state: { user },
  } = useAuth();
  const isTabletOrLarger = useIsTabletOrLarger();

  return (
    user && (
      <div className="app-sidebar" data-test="app-sidebar">
        <SideBarLogo />
        <SideBarNavigation user={user} />
        {isTabletOrLarger && (
          <div className="app-sidebar__navigation-items" style={{ flex: '0 0 auto' }}>
            <SideBarSignOut />
          </div>
        )}
        <SanityChecker />
      </div>
    )
  );
};
