import _, { compact } from 'lodash';
import { useCallback, useEffect, useRef } from 'react';
import { useQuery } from '@tanstack/react-query';
import useApi from '../../api/backendApiContext';
import {
  NoCampaign,
  NoProgramType,
  Program,
  ProgramAttachment,
  ProgramForEditResult,
  ProgramSummary,
  ProgramType,
  QueryData,
  ResolvedCampaign,
  UnknownCampaign,
  UnknownProgramType,
} from '../types';
import { useQueryCacheManager } from '../../hooks/useQueryCacheManager';
import { useFetchedProgramTypes } from './useFetchedProgramTypes';
import { useFetchedUsers } from '../../user/hooks/useFetchedUsers';
import { User } from '../../user/types';
import { useResolvedCampaigns } from './useResolvedCampaigns';
import { ReactQueryConstants } from '../../commons/constants';

export const useResolvedProgram = (props: {
  summary: ProgramSummary;
  enabled: boolean;
}): ProgramForEditResult => {
  const { summary, enabled } = props;
  const { programApi } = useApi();
  const programCache = useQueryCacheManager();
  const typeResolvingAttempt = useRef<number>(0);
  const ownerResolvingAttempt = useRef<number>(0);
  const campaignResolvingAttempt = useRef<number>(0);

  const fullRefetch = useCallback(async () => {
    await programCache.initTypesRefetch();
    await programCache.initUsersRefetch();
    await programCache.initCampaignsRefetch();
    await programCache.initProgramsRefetch(summary.id);
    await programCache.initAttachmentsRefetch(summary.id);
  }, [summary.id, programCache]);

  const programTypeRefetch = useCallback(async () => {
    await programCache.initTypesRefetch();
    await programCache.initProgramsRefetch(summary.id);
    await programCache.initAttachmentsRefetch(summary.id);
  }, [summary.id, programCache]);

  const programOwnerRefetch = useCallback(async () => {
    await programCache.initUsersRefetch();
    await programCache.initProgramsRefetch(summary.id);
    await programCache.initAttachmentsRefetch(summary.id);
  }, [summary.id, programCache]);

  const campaignsRefetch = useCallback(async () => {
    await programCache.initCampaignsRefetch();
    await programCache.initProgramsRefetch(summary.id);
    await programCache.initAttachmentsRefetch(summary.id);
  }, [summary.id, programCache]);

  const forceRefetch = useCallback(async () => {
    typeResolvingAttempt.current = 0;
    ownerResolvingAttempt.current = 0;
    campaignResolvingAttempt.current = 0;
    await fullRefetch();
  }, [fullRefetch]);

  useEffect(() => {
    typeResolvingAttempt.current = 0;
    ownerResolvingAttempt.current = 0;
    campaignResolvingAttempt.current = 0;
  }, [summary.id, enabled]);

  const {
    data: programTypesData,
    error: typesError,
    status: typesStatus,
    fetchStatus: typesFetchStatus,
    isStale: areTypesStale,
  } = useFetchedProgramTypes();

  const {
    data: usersData,
    error: usersError,
    status: usersStatus,
    fetchStatus: usersFetchStatus,
    isStale: areUsersStale,
  } = useFetchedUsers();

  const {
    data: campaignsData,
    error: campaignsError,
    status: campaignsStatus,
    fetchStatus: campaignsFetchStatus,
    isStale: areCampaignsStale,
  } = useResolvedCampaigns();

  const {
    data: attachmentsData,
    error: attachmentsError,
    status: attachmentsStatus,
    fetchStatus: attachmentsFetchStatus,
    isStale: isAttachmentsStale,
  } = useQuery(
    programCache.getProgramAttachmentsCacheName(summary.id),
    async () => programApi.getProgramAttachments(summary.id),
    {
      staleTime: ReactQueryConstants.programAttachmentsStaleTime,
      refetchOnWindowFocus: false,
      enabled,
    }
  );

  const {
    data: programData,
    error: programError,
    status: programStatus,
    fetchStatus: programFetchStatus,
    isStale: isProgramStale,
  } = useQuery(
    programCache.getProgramCacheName(summary.id),
    async () => programApi.getProgram(summary.id),
    { staleTime: ReactQueryConstants.fullProgramStaleTime, refetchOnWindowFocus: false, enabled }
  );

  const loadedProgram: QueryData<Program> = {
    data: programData ?? _.cloneDeep(summary),
    status: programStatus,
    fetchStatus: programFetchStatus,
    error: programError,
    isStale: isProgramStale,
  };

  const loadedAttachments: QueryData<ProgramAttachment[]> = {
    data: attachmentsData ?? [],
    status: attachmentsStatus,
    fetchStatus: attachmentsFetchStatus,
    error: attachmentsError,
    isStale: isAttachmentsStale,
  };

  let loadedType: QueryData<ProgramType> = {
    data: NoProgramType,
    error: typesError,
    status: 'loading',
    fetchStatus: 'fetching',
    isStale: areTypesStale,
  };

  let loadedUsers: QueryData<User[]> = {
    data: usersData ?? [],
    error: usersError,
    status: 'loading',
    fetchStatus: 'fetching',
    isStale: areUsersStale,
  };

  let loadedCampaign: QueryData<ResolvedCampaign | null> = {
    data: summary.campaignId === null ? null : UnknownCampaign,
    error: campaignsError,
    status: 'loading',
    fetchStatus: 'fetching',
    isStale: areCampaignsStale,
  };

  if (programData && programTypesData) {
    const programType = programTypesData.types.find((t) => t.id === programData.typeId);
    if (programType) {
      loadedType = {
        data: programType,
        error: typesError,
        status: typesStatus,
        fetchStatus: typesFetchStatus,
        isStale: areTypesStale,
      };
      typeResolvingAttempt.current = 0;
    }
    // @ts-ignore
    else if (programStatus !== 'loading' && typesStatus !== 'loading') {
      if (typeResolvingAttempt.current < 2) {
        typeResolvingAttempt.current += 1;
        programTypeRefetch();
      } else {
        loadedType = {
          data: _.cloneDeep(UnknownProgramType),
          error: null,
          status: typesStatus,
          fetchStatus: typesFetchStatus,
          isStale: areTypesStale,
        };
      }
    }
  }

  if (programData && usersData) {
    const userIdsToBeResolved = Array.from(
      new Set(compact([programData.owningUserId, ...programData.tasks.map((t) => t.owningUserId)]))
    );
    if (userIdsToBeResolved.length > 0) {
      const areAllResolved =
        userIdsToBeResolved.find((uid) => usersData.find((u) => u.id === uid) === undefined) ===
        undefined;
      if (areAllResolved) {
        loadedUsers = {
          data: usersData,
          error: usersError,
          status: usersStatus,
          fetchStatus: usersFetchStatus,
          isStale: areUsersStale,
        };
        ownerResolvingAttempt.current = 0;
      } else if (
        // @ts-ignore
        programStatus !== 'loading' &&
        // @ts-ignore
        usersStatus !== 'loading'
      ) {
        if (ownerResolvingAttempt.current < 2) {
          ownerResolvingAttempt.current += 1;
          programOwnerRefetch();
        } else {
          loadedUsers = {
            data: usersData,
            error: null,
            status: usersStatus,
            fetchStatus: usersFetchStatus,
            isStale: areUsersStale,
          };
        }
      }
    } else {
      loadedUsers = {
        data: usersData,
        error: usersError,
        status: usersStatus,
        fetchStatus: usersFetchStatus,
        isStale: areUsersStale,
      };
    }
  }

  if (programData || !enabled) {
    const campaignIdToResolve = enabled
      ? programData?.campaignId
      : summary.campaignId ?? programData?.campaignId;
    if (!campaignIdToResolve || campaignIdToResolve === NoCampaign.id) {
      loadedCampaign = {
        data: null,
        error: null,
        status: 'success',
        fetchStatus: campaignsFetchStatus,
        isStale: false,
      };
    } else if (campaignsData) {
      const campaign = campaignsData.find((c) => c.id === campaignIdToResolve);
      if (campaign) {
        loadedCampaign = {
          data: campaign,
          error: campaignsError,
          status: campaignsStatus,
          fetchStatus: campaignsFetchStatus,
          isStale: areCampaignsStale,
        };
        campaignResolvingAttempt.current = 0;
      } else if (programStatus !== 'loading' && campaignsStatus !== 'loading') {
        if (campaignResolvingAttempt.current < 2) {
          campaignResolvingAttempt.current += 1;
          campaignsRefetch();
        } else {
          loadedCampaign = {
            data: _.cloneDeep(UnknownCampaign),
            error: null,
            status: campaignsStatus,
            fetchStatus: campaignsFetchStatus,
            isStale: areCampaignsStale,
          };
        }
      }
    }
  }

  return {
    program: loadedProgram,
    programType: loadedType,
    campaign: loadedCampaign,
    attachments: loadedAttachments,
    programTypes: programTypesData,
    programUsers: loadedUsers,
    refetch: forceRefetch,
  };
};
