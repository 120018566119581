import React from 'react';
import { getColumnName } from '../../programColumns';

interface Props {
  onShowHideColumn: (id: string | null, visible: boolean) => void;
  columns: { id: string; visible: boolean }[];
}

const truncateLength = 25;

const truncatedColumnName = (id: string) => {
  const name = getColumnName(id, true);
  return name.length > truncateLength ? `${name.slice(0, truncateLength - 3)}...` : name;
};

const CalendarColumnSelector = ({ columns, onShowHideColumn }: Props) => {
  const selectedCount = columns.filter((col) => col.visible).length;
  return (
    <>
      <div>
        <span className="calendar-display-settings__title">Show program fields</span>
        <span className="calendar-display-settings__sub-title"> (up to 3 fields)</span>
      </div>
      {columns
        .sort((a, b) => a.id.localeCompare(b.id))
        .map((column, index) => (
          <div key={column.id} className="calendar-display-settings__draggable-container">
            <div className="d-flex align-items-center">
              <div className="drag-handle drag-handle--small" />
            </div>
            <div className="d-flex align-items-center w-100">
              <div className="switch-input-container w-100">
                <input
                  type="checkbox"
                  className="custom-control-input"
                  id={`calendar-display-settings__column-check-${index}`}
                  data-test={`calendar-display-settings__column-check-${column.id}`}
                  onChange={() => onShowHideColumn(column.id, !column.visible)}
                  checked={column.visible}
                  disabled={!column.visible && selectedCount >= 3}
                />
                <label
                  className="custom-control-label text-capitalize calendar-display-settings__check-label"
                  htmlFor={`calendar-display-settings__column-check-${index}`}
                >
                  {truncatedColumnName(column.id)}
                </label>
              </div>
            </div>
          </div>
        ))}
    </>
  );
};

export default CalendarColumnSelector;
