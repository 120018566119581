import React, { useEffect, useState } from 'react';
import { ValueList } from './ValueList';
import { useMemoCompare } from '../../../hooks/useMemoCompare';
import _ from 'lodash';
import { PromptVariable, PromptVariableStringValueOrAdhoc } from '../../types';
import { STRING_VARIABLES } from '../../util';

export const VariableValueList = (props: {
  variableName: string;
  promptVariable: PromptVariable | null;
  selectedValues: PromptVariableStringValueOrAdhoc[];
  adhocValues: PromptVariableStringValueOrAdhoc[];
  onAddNewClick: () => void;
  onSelectionChanged: (value: PromptVariableStringValueOrAdhoc, isSelected: boolean) => void;
}) => {
  const {
    variableName,
    promptVariable,
    selectedValues,
    adhocValues,
    onAddNewClick,
    onSelectionChanged,
  } = props;

  const memoizedValues = useMemoCompare<PromptVariableStringValueOrAdhoc[]>(
    (() => {
      if (!promptVariable) return [];
      if (promptVariable.variableValue.type === 'list')
        return promptVariable.variableValue.values.map((x) => ({ ...x, kind: 'saved', id: null }));
      if (promptVariable.variableValue.type === 'string') {
        if (!promptVariable.variableValue.value && !promptVariable.variableValue.description)
          return [];
        else return [{ ...promptVariable.variableValue, kind: 'saved', id: null }];
      }
      return [];
    })(),

    (a, b) => _.isEqual(a, b)
  );

  const [allValues, setAllValues] = useState([...(memoizedValues ?? []), ...adhocValues]);
  useEffect(() => {
    setAllValues([...(memoizedValues ?? []), ...adhocValues]);
  }, [memoizedValues, adhocValues]);

  return (
    <div className="variable-message__values-and-new">
      <ValueList
        values={allValues}
        selectedValues={selectedValues}
        onSelectionChanged={onSelectionChanged}
      />
      {(STRING_VARIABLES.indexOf(variableName) < 0 || memoizedValues.length === 0) && (
        <button
          className="variable-message__add-new-button"
          data-test="variable-message__add-new-button"
          onClick={onAddNewClick}
        >
          {`Add new ${variableName}`}
        </button>
      )}
    </div>
  );
};
