import { string, object, ref } from 'yup';
import { Formik, Form } from 'formik';
import { passwordRequirements } from '../validationConstants';
import AuthInput from './AuthInput';
import PassInput from './PassInput';
import useAnalyticsPage from '../../web-analytics/hooks/useAnalyticsPage';
import { Page } from '../../web-analytics/Page';
import useAnalytics from '../../web-analytics/webAnalyticsContext';
import { analyticsTrack } from '../../web-analytics/webAnalytics';
import { AnalyticsEvent } from '../../web-analytics/AnalyticsEvent';
import { Button } from 'react-bootstrap';
import { NewTabLink } from '../../commons/components/NewTabLink';
import { printAuthErrorMessage } from '../utils';

const validationSchema = object({
  email: string().email('Must contain a valid email address').required('Enter an email address'),
  password: string()
    .required('Please Enter your password')
    .matches(passwordRequirements.pattern, passwordRequirements.explainMessage)
    .required('Enter your password'),
  confirmPassword: string()
    .required('Confirm your password')
    .oneOf([ref('password')], 'Password does not match'),
});

type SignupPayload = {
  email: string;
  password: string;
  confirmPassword: string;
};

const SignUp = (props: { onSignUp: (email: string, password: string) => Promise<void> }) => {
  useAnalyticsPage(Page.SIGN_UP);
  const analytics = useAnalytics();

  const { onSignUp } = props;
  const initialValues: SignupPayload = {
    email: '',
    password: '',
    confirmPassword: '',
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={async (values, formikHelpers) => {
        formikHelpers.setStatus(null);
        await onSignUp(values.email.toLowerCase(), values.password).catch((err) => {
          formikHelpers.setStatus({ error: err });
          analyticsTrack(analytics, AnalyticsEvent.USER_SIGN_UP_DETAILS_REJECTED);
        });
      }}
    >
      {({ touched, errors, isSubmitting, status }) => (
        <Form>
          <div className="auth-dialog">
            <div className="auth-dialog__header">
              <div className="auth-dialog__title">Create your Growegy account</div>
              <div className="auth-dialog__subtitle">
                Explore for free with a 7-day trial, no credit card required.
              </div>
            </div>
            <div className="auth-dialog__fields">
              <div className="auth-dialog__field">
                <AuthInput
                  type="email"
                  name="email"
                  autoComplete="email"
                  placeholder="Enter work email"
                  dataTest="sign-up__email"
                  invalid={!!touched.email && !!errors.email}
                />
              </div>
              <div className="auth-dialog__field">
                <PassInput
                  name="password"
                  autoComplete="new-password"
                  placeholder="Enter password"
                  dataTest="sign-up__password"
                  invalid={!!touched.password && !!errors.password}
                />
              </div>
              <div className="auth-dialog__field">
                <PassInput
                  name="confirmPassword"
                  autoComplete="new-password"
                  placeholder="Confirm password"
                  dataTest="sign-up__confirm-password"
                  invalid={!!touched.confirmPassword && !!errors.confirmPassword}
                />
              </div>
            </div>
            <div className="auth-dialog__info growegy-title12">
              <span>
                By creating account, you agree to our{' '}
                <a href="https://www.growegy.com/terms/" target="_blank" rel="noopener noreferrer">
                  Terms of Service
                </a>{' '}
                and{' '}
                <NewTabLink href="https://www.growegy.com/privacy-policy/">
                  Privacy Policy
                </NewTabLink>{' '}
              </span>
              {status && status.error && (
                <div className="alert-danger fade show">{printAuthErrorMessage(status.error)}</div>
              )}
            </div>

            <div className="auth-dialog__footer">
              <Button
                type="submit"
                className="button-apply"
                disabled={isSubmitting}
                data-test="sign-up__submit"
              >
                Create your trial account
              </Button>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default SignUp;
