import useAnalyticsPage from '../../web-analytics/hooks/useAnalyticsPage';
import { Page } from '../../web-analytics/Page';
import { usePersistedPersonalRecommendations } from '../hooks/usePersonalRecommendations';
import { usePrefix } from '../hooks/usePrefix';

export const Recommendations = () => {
  useAnalyticsPage(Page.RECOMMENDATIONS);
  const prefix = usePrefix();

  const { data } = usePersistedPersonalRecommendations(prefix);

  return (
    <div className="recommendations__container">
      {!!data && !data.recommendations.length && (
        <div className="recommendations__header">
          Personalized insights and recommendations are on the way. Check later!
        </div>
      )}
      {data && data.recommendations.length > 0 && (
        <>
          <div className="row pr-3" style={{ height: '50vh' }}>
            {(!prefix || prefix.includes('single')) && (
              <div
                className="col-12 p-0"
                // eslint-disable-next-line react/no-danger
                dangerouslySetInnerHTML={{ __html: data.recommendations[0].htmlContent }}
              />
            )}
            {prefix && prefix.includes('multiple') && (
              <>
                <div
                  className="col-md-4 p-0"
                  // eslint-disable-next-line react/no-danger
                  dangerouslySetInnerHTML={
                    data.recommendations.length > 0
                      ? { __html: data.recommendations[0].htmlContent }
                      : undefined
                  }
                />
                <div
                  className="col-md-4 p-0"
                  // eslint-disable-next-line react/no-danger
                  dangerouslySetInnerHTML={
                    data.recommendations.length > 1
                      ? { __html: data.recommendations[1].htmlContent }
                      : undefined
                  }
                />
                <div
                  className="col-md-4 p-0"
                  // eslint-disable-next-line react/no-danger
                  dangerouslySetInnerHTML={
                    data.recommendations.length > 2
                      ? { __html: data.recommendations[2].htmlContent }
                      : undefined
                  }
                />
              </>
            )}
          </div>
        </>
      )}
    </div>
  );
};
