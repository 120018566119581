import { PropsWithChildren } from 'react';
import { GrowegySelectDropdownIndicator } from './GrowegySelect';
import clsx from 'clsx';

export const GrowegySelectMockup = (
  props: PropsWithChildren<{ placeholderText?: string; readOnly?: boolean }>
) => {
  const { children, placeholderText, readOnly } = props;
  return (
    <div
      className={clsx('growegy-select-mockup__container', {
        'growegy-select-mockup__container--readonly': readOnly,
      })}
      data-test="growegy-select-mockup__container"
    >
      <div className="flex-grow-1" style={{ minWidth: 0 }}>
        {children ? (
          children
        ) : (
          <div className="growegy-form__placeholder growegy-select-mockup__visible-on-parent-hover">
            {placeholderText}
          </div>
        )}
      </div>
      <div className="growegy-select-mockup__visible-on-parent-hover">
        <GrowegySelectDropdownIndicator />
      </div>
    </div>
  );
};
