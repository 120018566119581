import React, { useEffect, useMemo, useRef } from 'react';
import { Cell, Column, Row, useFlexLayout, useTable } from 'react-table';
import { ResolvedProgramSummary, ResolvedProgramTask } from '../../types';
import { TaskDueDateCell } from './TaskDueDateCell';
import { TaskOwnerInputCell } from './TaskOwnerInputCell';
import { TaskStatusCell } from './TaskStatusCell';
import { TaskArrayHelpers } from './CellProps';
import { createNewTask, getTaskDraggableId, getTaskTableDroppableId } from './utils';
import {
  TaskTableDueDateCellWidth,
  TaskTableNameCellWidth,
  TaskTableOwnerCellWidth,
  TaskTableStatusCellWidth,
} from '../../util';
import clsx from 'clsx';
import { DivButton } from '../../../commons/components/DivButton';
import { PlusSmallIcon } from '../../../assets/icons';
import { TaskMenuCell } from './TaskMenuCell';
import { TaskNameCell } from './TaskNameCell';
import { Draggable, Droppable } from '@hello-pangea/dnd';
import { BigDragHandle } from '../../../commons/components/BigDragHandle';
import { useProgramPermissionHelper } from '../../programPermissionHelper';
import { useFetchedUsers } from '../../../user/hooks/useFetchedUsers';
import useAuth from '../../../auth/authContext';

export const buildProgramTaskReactKey = (
  program: { id: string; version?: number },
  row: Row<ResolvedProgramTask>
): string =>
  `${row.getRowProps().key}-${row.original.id}-${row.original.name}-${program.id}-${
    program.version
  }`;

// @ts-ignore
export const getTaskColumns = (
  emphasizeFirstColumn: boolean,
  readOnly: boolean
): Column<ResolvedProgramTask>[] => {
  const menuColumn: Column<ResolvedProgramTask> = {
    Header: () => <div />,
    accessor: () => (t: ResolvedProgramTask) => t,
    id: 'menu',
    maxWidth: 50,
    // @ts-ignore
    Cell: TaskMenuCell,
  };
  return [
    {
      Header: () => (
        <div
          className={clsx(
            emphasizeFirstColumn ? 'growegy-label14' : 'growegy-label12',
            'growegy-font-em'
          )}
        >
          Tasks
        </div>
      ),
      accessor: 'status',
      width: TaskTableStatusCellWidth,
      maxWidth: TaskTableStatusCellWidth,
      // @ts-ignore
      Cell: TaskStatusCell,
    },
    {
      Header: () => <div />,
      accessor: 'name',
      width: TaskTableNameCellWidth,
      maxWidth: TaskTableNameCellWidth,
      // @ts-ignore
      Cell: TaskNameCell,
    },
    {
      Header: () => <div className="tasks-table__header">Due date</div>,
      accessor: 'dueDateTime',
      width: TaskTableDueDateCellWidth,
      // @ts-ignore
      Cell: TaskDueDateCell,
    },
    {
      Header: () => <div className="tasks-table__header">Assignee</div>,
      accessor: 'owner',
      width: TaskTableOwnerCellWidth,
      // @ts-ignore
      Cell: TaskOwnerInputCell,
    },
    ...(readOnly ? [] : [menuColumn]),
  ];
};

export const Tasks = (props: {
  program: ResolvedProgramSummary;
  values: ResolvedProgramTask[];
  arrayHelpers: TaskArrayHelpers;
  emphasizeFirstColumn?: boolean;
  scrollToTaskId?: string | null;
  isReadonly: boolean;
  taskInFocusOrderId?: number | null;
  onPopoverOpen?: () => void;
  onPopoverClose?: () => void;
  onConvertTaskToProgram?: (task: ResolvedProgramTask) => void;
}) => {
  const {
    program,
    values,
    arrayHelpers,
    scrollToTaskId,
    emphasizeFirstColumn,
    isReadonly,
    taskInFocusOrderId,
    onPopoverOpen,
    onPopoverClose,
    onConvertTaskToProgram,
  } = props;

  const {
    state: { user },
  } = useAuth();
  const programPermissionsHelper = useProgramPermissionHelper(program);
  const { data: users } = useFetchedUsers();
  const dragDisabled = isReadonly || !programPermissionsHelper.canReorderTasks();
  const canEdit = (columnId: string, task: ResolvedProgramTask): boolean => {
    switch (columnId) {
      case 'owner':
        return programPermissionsHelper.canReassignTask();
      case 'menu':
        return programPermissionsHelper.canDeleteTask(task);
      default:
        return programPermissionsHelper.canEditRegularTaskField(task);
    }
  };

  const columns = useMemo(
    () => getTaskColumns(emphasizeFirstColumn ?? false, isReadonly),
    [emphasizeFirstColumn, isReadonly]
  );

  const tableInstance = useTable<ResolvedProgramTask>({ columns, data: values }, useFlexLayout);
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = tableInstance;

  const selectedTaskRef = useRef<HTMLDivElement | null>(null);
  const scrolledToTask = useRef(false);
  useEffect(() => {
    if (!!selectedTaskRef.current && !scrolledToTask.current) {
      scrolledToTask.current = true;
      selectedTaskRef.current?.scrollIntoView({
        behavior: 'auto',
        block: 'center',
      });
    }
  });

  const droppableId = useMemo(() => getTaskTableDroppableId(program.id), [program.id]);

  return (
    <>
      <div {...getTableProps()}>
        <div>
          {headerGroups.map((headerGroup) => (
            <div
              {...headerGroup.getHeaderGroupProps()}
              key={headerGroup.getHeaderGroupProps().key}
              className="tasks-table__headers"
            >
              {headerGroup.headers.map((column) => (
                <div {...column.getHeaderProps()} key={column.getHeaderProps().key}>
                  {column.render('Header', { key: column.getHeaderProps().key })}
                </div>
              ))}
            </div>
          ))}
        </div>
        <Droppable droppableId={droppableId} isDropDisabled={dragDisabled}>
          {(provided, droppableSnapshot) => (
            <div
              ref={provided.innerRef}
              {...provided.droppableProps}
              {...getTableBodyProps()}
              style={droppableSnapshot.isDraggingOver ? { pointerEvents: 'none' } : {}}
              className={clsx('tasks-table__body', {
                'tasks-table__body--dragging-over': droppableSnapshot.isDraggingOver,
              })}
            >
              {rows.map((row: Row<ResolvedProgramTask>, index: number) => {
                prepareRow(row);
                const key = buildProgramTaskReactKey(program, row);
                return (
                  <Draggable
                    draggableId={getTaskDraggableId(
                      program.id,
                      row.original.id ?? '',
                      row.getRowProps().key.toString()
                    )}
                    index={index}
                    isDragDisabled={dragDisabled}
                    key={key}
                  >
                    {(provided, draggingSnapshot) => (
                      <div
                        ref={
                          scrollToTaskId && row.original.id === scrollToTaskId
                            ? selectedTaskRef
                            : null
                        }
                      >
                        <div
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...row.getRowProps()}
                          className={clsx(
                            'tasks-table__row',
                            { 'tasks-table__row--draggable': !dragDisabled },
                            { 'tasks-table__row--dragging': draggingSnapshot.isDragging }
                          )}
                          style={{
                            ...provided.draggableProps.style,
                            ...row.getRowProps().style,
                          }}
                          data-test="programs-table__tasks-row"
                        >
                          {!dragDisabled && (
                            <BigDragHandle
                              dataTest="tasks-table__task-drag-handle"
                              {...provided.dragHandleProps}
                            />
                          )}
                          {row.cells.map((cell: Cell<ResolvedProgramTask>) => (
                            <div
                              {...cell.getCellProps()}
                              key={cell.getCellProps().key}
                              className={clsx('tasks-table__cell')}
                            >
                              {cell.render('Cell', {
                                arrayHelpers,
                                isReadonly:
                                  isReadonly || !canEdit(cell.column.id, cell.row.original),
                                key: cell.getCellProps().key,
                                index,
                                focus: row.original.orderId === taskInFocusOrderId,
                                onPopoverOpen,
                                onPopoverClose,
                                onConvertTaskToProgram,
                                programId: program.id,
                                programStart: program.startDateTime,
                                programEnd: program.endDateTime,
                                wholeDay: program.wholeDay,
                                programTypeColor: program.type.color,
                              })}
                            </div>
                          ))}
                        </div>
                      </div>
                    )}
                  </Draggable>
                );
              })}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </div>
      {programPermissionsHelper.canCreateTask() && (
        <div className="tasks-table__add-new-wrapper">
          <DivButton
            isGray={true}
            className="common-table__add-new-btn"
            icon={<PlusSmallIcon />}
            text="Add new"
            dataTest="program-edit__add-task"
            onClick={() => {
              const currentUser = programPermissionsHelper.canCreateTaskForAnyUser()
                ? null
                : users?.find((u) => !!user && u.id === user.userId) ?? null;
              arrayHelpers.push(createNewTask(values.length, currentUser));
            }}
          />
        </div>
      )}
    </>
  );
};
