import { AxiosInstance } from 'axios';
import { PersonalRecommendation, PersonalRecommendations } from './types';

type PersonalRecommendationDto = {
  lastModified: string;
  name: string;
  content: string;
};

type PersonalRecommendationsListDto = {
  recommendations: PersonalRecommendationDto[];
};

export type PersonalRecommendationsApi = ReturnType<typeof getPersonalRecommendationsApi>;

const toPersonalRecommendation = (dto: PersonalRecommendationDto): PersonalRecommendation => {
  const { name, lastModified, content } = dto;
  const htmlContent = atob(content);
  return {
    name,
    version: lastModified,
    htmlContent,
  };
};

const toPersonalRecommendations = (
  recommendationsDto: PersonalRecommendationsListDto
): PersonalRecommendations => {
  const { recommendations: dtos } = recommendationsDto;
  return { recommendations: dtos.filter((c) => c.content).map(toPersonalRecommendation) };
};

export const getPersonalRecommendationsApi = (axiosInstance: AxiosInstance) => {
  const getRecommendations: (prefix?: string | null) => Promise<PersonalRecommendations> = async (
    prefix: string | null = null
  ) => {
    const url = prefix ? `/recommendations?prefix=${prefix}` : `/recommendations`;
    const { data } = await axiosInstance.get<PersonalRecommendationsListDto>(url);
    return toPersonalRecommendations(data);
  };

  return { getRecommendations };
};
