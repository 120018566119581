import React, { useCallback, useMemo, useState } from 'react';
import { outlookConfig } from '../../commons/config';
import useAnalytics from '../../web-analytics/webAnalyticsContext';
import { analyticsTrack } from '../../web-analytics/webAnalytics';
import { AnalyticsEvent } from '../../web-analytics/AnalyticsEvent';
import { useQueryCacheManager } from '../../hooks/useQueryCacheManager';
import { useMutation } from '@tanstack/react-query';
import useApi from '../../api/backendApiContext';
import { ConfirmDialogProps, ConfirmModal } from '../../commons/components/ConfirmModal';
import { useNotification } from '../../notification/notificationsContext';
import { errorToString, getHttpErrorCode } from '../../util/utils';
import { notConnectedOutlookOauth } from '../outlookApi';
import { useMsal } from '@azure/msal-react';
import { useFetchedOutlookOauth } from '../hooks/useFetchedOutlookOauth';
import { BrowserAuthError } from '@azure/msal-browser';
import outlookImage from '../../assets/images/outlook.png';
import { IntegrationTile } from '../../commons/components/IntegrationTile';

export const OutlookSettings = (props: { userId: string }) => {
  const analytics = useAnalytics();

  const { userId } = props;
  const {
    query: { isLoading, isError, data },
    queryStatusComponent,
  } = useFetchedOutlookOauth(userId);
  const { outlookApi } = useApi();
  const { notifySuccess, notifyError } = useNotification();

  const isActive = !!data && data.isConfigured && !data.isDisconnected;
  const isDisconnected = !!data && data.isConfigured && data.isDisconnected;

  const queryCacheManager = useQueryCacheManager();

  const { instance: msalInstance } = useMsal();

  const { isLoading: isConnecting, mutate: connect } = useMutation(
    async (accessToken: string) => {
      return outlookApi.userConsent(userId, accessToken);
    },
    {
      onSuccess: async (oauth) => {
        await queryCacheManager.setOutlookOauth(userId, oauth);
        const message = 'Synchronization to Outlook Calendar has been successfully setup';
        notifySuccess({
          notificationMsg: message,
          logMsg: message,
        });
        analyticsTrack(analytics, AnalyticsEvent.OUTLOOK_CONNECTED);
      },
      onError: (e) => {
        analyticsTrack(analytics, AnalyticsEvent.OUTLOOK_CONNECTION_FAILED);
        const message = errorToString(
          e,
          'Something when wrong while setting up synchronization to Outlook. Please try again.'
        );
        notifyError({
          notificationMsg: message,
          logMsg: message,
        });
      },
    }
  );

  const onConnectClick = useCallback(async () => {
    try {
      const authResult = await msalInstance.loginPopup({
        scopes: [`api://${outlookConfig.clientId}/access_as_user`],
        prompt: 'select_account',
        extraScopesToConsent: ['Calendars.ReadWrite', 'MailboxSettings.Read', 'offline_access'],
        storeInCache: {
          idToken: false,
          accessToken: false,
          refreshToken: false,
        },
      });
      connect(authResult.accessToken);
    } catch (error) {
      analyticsTrack(analytics, AnalyticsEvent.OUTLOOK_FAILED_TO_CONSENT);
      if (error instanceof BrowserAuthError) {
        logger.error(`Failed to connect to Outlook: ${error.errorCode} - ${error.message}`);
      } else {
        logger.error('Failed to connect to Outlook');
      }
      const notificationMsg =
        error instanceof BrowserAuthError && error.errorCode === 'interaction_in_progress'
          ? 'Outlook login dialog or tab is already open, return to it to continue. ' +
            'Alternatively, refresh this page and try again.'
          : 'Login to the Outlook account was not complete, please try again.';
      notifyError({
        err: error,
        notificationMsg: notificationMsg,
      });
    }
  }, [msalInstance, connect, notifyError, analytics]);

  const { isLoading: isDisconnecting, mutate: disconnect } = useMutation(
    async () => outlookApi.deleteOauth(userId),
    {
      onSuccess: async () => {
        await queryCacheManager.setOutlookOauth(userId, notConnectedOutlookOauth);
        const message = 'Synchronization to Outlook Calendar has been successfully stopped.';
        notifySuccess({
          notificationMsg: message,
          logMsg: message,
        });
        analyticsTrack(analytics, AnalyticsEvent.OUTLOOK_DISCONNECTED);
      },
      onError: (e) => {
        const message = errorToString(
          e,
          'Something when wrong while disconnecting from Outlook Calendar. Please try again.'
        );
        notifyError({
          notificationMsg: message,
          logMsg: message,
        });
      },
    }
  );

  const [confirmDisconnectModal, setConfirmDisconnectModal] = useState(false);

  const onDisconnectConfirmed = useCallback(async () => {
    setConfirmDisconnectModal(false);
    await disconnect();
  }, [disconnect]);

  const disconnectConfirmDialogProps: ConfirmDialogProps = useMemo<ConfirmDialogProps>(
    () => ({
      title: 'Disconnect Growegy and Outlook Calendar?',
      message: 'You’ll be able to reconnect at any time.',
      footer: {
        type: 'show',
        applyButton: {
          label: 'Disconnect',
          variant: 'danger',
          onClick: onDisconnectConfirmed,
        },
        cancelButton: {
          label: 'Cancel',
          onClick: () => setConfirmDisconnectModal(false),
        },
      },
    }),
    [onDisconnectConfirmed]
  );

  const { isLoading: isSchedulingResync, mutate: scheduleResync } = useMutation(
    async () => outlookApi.scheduleResync(userId),
    {
      onSuccess: () => {
        const message =
          'Re-synchronization with Outlook Calendar has been successfully scheduled. It may take a couple of minutes.';
        notifySuccess({
          notificationMsg: message,
          logMsg: message,
        });
        analyticsTrack(analytics, AnalyticsEvent.OUTLOOK_RESYNC_SCHEDULED);
      },
      onError: async (e) => {
        if (getHttpErrorCode(e) === 404) {
          await queryCacheManager.setOutlookOauth(userId, notConnectedOutlookOauth);
        }
        const message = errorToString(
          e,
          'Something when wrong while scheduling re-synchronization with Outlook Calendar. Please try again.'
        );
        notifyError({
          notificationMsg: message,
          logMsg: message,
        });
      },
    }
  );

  return (
    <>
      <IntegrationTile
        isActive={isActive}
        isDisconnected={isDisconnected}
        isConnecting={isConnecting}
        isDisconnecting={isDisconnecting}
        isSchedulingResync={isSchedulingResync}
        isLoading={isLoading}
        isError={isError}
        queryStatusComponent={queryStatusComponent}
        title="Microsoft Outlook Calendar"
        subtitle={data && data.isConfigured ? data.email : null}
        privacyPolicyUrl="https://www.growegy.com/resources/about/privacy-policy/"
        learnMoreUrl={outlookConfig.calendarHelpUrl}
        learnMoreViewedEvent={AnalyticsEvent.OUTLOOK_HELP_VIEWED}
        onConnectClick={onConnectClick}
        onDisconnectClick={() => setConfirmDisconnectModal(true)}
        onResyncClick={scheduleResync}
        icon={<img className="integration-row-icon" src={outlookImage} alt="Outlook" />}
      >
        <div>Enable real-time, two-way synchronization between Outlook Calendar and Growegy.</div>
        <div>
          This integration ensures that both the programs you own and their associated tasks, as
          well as tasks assigned to you in other programs, are seamlessly synced.
        </div>
      </IntegrationTile>
      {confirmDisconnectModal && <ConfirmModal {...disconnectConfirmDialogProps} />}
    </>
  );
};
