import React, { ReactElement } from 'react';
import clsx from 'clsx';

export const TextInputUnderlined = ({
  children,
  value,
  setValue,
  editing,
  setEditing,
}: {
  children: UnderlinedInputChild;
  value: string;
  setValue: (v: string | null) => void;
  editing: boolean;
  setEditing: (v: boolean) => void;
}) => {
  const onBlur = () => setEditing(false);
  const onFocus = () => setEditing(true);

  return (
    <div
      className={clsx('text-input__wrapper', {
        'text-input__wrapper--editing': editing,
      })}
    >
      {children({ onBlur, onFocus, setValue, value })}
      <div
        className={clsx('text-input__underline', {
          'text-input__underline--editing': editing,
        })}
      />
    </div>
  );
};

export type UnderlinedInputChild = ({
  onBlur,
  onFocus,
  setValue,
  value,
}: {
  onBlur: () => void;
  onFocus: () => void;
  setValue: (v: string | null) => void;
  value: string;
}) => ReactElement;
