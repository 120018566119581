import { CheckIcon } from '../../../assets/icons';
import { StyleVariables } from '../../../commons/styleConstants';
import React from 'react';
import { ConfirmModal } from '../../../commons/components/ConfirmModal';
import moment from 'moment';
import {
  isInvoiceObligatory,
  getScheduledCancellationDate,
  isPlanAlreadyTerminated,
} from '../../utils';
import { useFetchedBillingCustomer } from '../../hooks/useFetchedBillingCustomer';
import { useNavigate } from 'react-router-dom';
import { AppRoutes } from '../../../router/AppRoutes';
import { useFetchedBillingInvoices } from '../../hooks/useFetchedBillingInvoices';

export const FinalStep = () => {
  const {
    query: { data: customer },
  } = useFetchedBillingCustomer();
  const {
    query: { data: invoices },
  } = useFetchedBillingInvoices();
  const navigate = useNavigate();
  if (!customer || !invoices) return null;
  const isAlreadyTerminated = isPlanAlreadyTerminated(customer.activeSubscription);
  const scheduledCancellationDate = getScheduledCancellationDate(customer.activeSubscription);
  const hasDueInvoices = invoices.filter(isInvoiceObligatory).length > 0;

  return (
    <ConfirmModal
      title={
        isAlreadyTerminated
          ? 'Your plan has been canceled & your account has been closed'
          : 'Your plan has been canceled'
      }
      ariaHideApp={false}
      shouldCloseOnOverlayClick={false}
      shouldCloseOnEsc={true}
      titleIcon={
        <div className="cancellation-final__check-icon-container">
          <CheckIcon fill={StyleVariables.cyan500} className="d-block" />
        </div>
      }
      onClose={() => {
        if (isAlreadyTerminated) {
          navigate(AppRoutes.Auth.signOut);
        }
      }}
      footer={{
        type: 'show',
        applyButton: {
          label: 'Done',
          variant: 'primary',
          onClick: () => {},
        },
      }}
    >
      {!isAlreadyTerminated && (
        <div className="cancellation-intro__description">
          {scheduledCancellationDate && (
            <span>
              {!hasDueInvoices ? 'Your account is paid in full. ' : ''}It’s still available until
              the end of your billing period on{' '}
              {moment(scheduledCancellationDate).format('MMMM D, YYYY')}.
            </span>
          )}
          {hasDueInvoices && (
            <span>
              Please ensure all open and past due invoices are paid promptly to avoid any negative
              impact on your credit score.
            </span>
          )}
        </div>
      )}
    </ConfirmModal>
  );
};
