import { PromptCategory } from '../../types';
import { ArrowLeftIcon } from '../../../assets/icons';
import { Button } from 'react-bootstrap';
import React from 'react';
import { getIconByName } from '../../../commons/icon-utils';

export const PromptCategoryBreadcrumb = (props: {
  category: PromptCategory;
  onClickBack?: () => void;
}) => {
  const { category, onClickBack } = props;
  const IconComponent = getIconByName(category.icon);

  return (
    <div className="prompt-category-breadcrumb__container">
      {onClickBack && (
        <Button
          data-test="prompt-category-breadcrumb__back"
          onClick={onClickBack}
          className="button-default"
        >
          <ArrowLeftIcon />
        </Button>
      )}
      <div className="prompt-category-breadcrumb__icon-and-name">
        {IconComponent ? <IconComponent style={{ fill: category.color }} /> : <></>}
        <div style={{ color: category.color }}>{category.name}</div>
      </div>
    </div>
  );
};

export const PromptCategories = (props: { categories: PromptCategory[] }) => {
  const { categories } = props;
  const iconComponent = categories.map((c) => getIconByName(c.icon));

  return (
    <div data-test="prompt-preview__categories" className="prompt-preview__header-categories">
      {categories.map((category, i) => {
        const IconComponent = iconComponent[i];
        return (
          <div
            key={`prompt-category-icon__${category.name}`}
            data-test={`prompt-preview__category-${category.name}`}
            className="prompt-category-breadcrumb__icon-and-name prompt-category-breadcrumb__icon-and-name--item"
          >
            {IconComponent ? (
              <div style={{ marginRight: '8px' }}>
                <IconComponent style={{ fill: category.color }} />{' '}
              </div>
            ) : (
              <></>
            )}
            <div
              data-test={`prompt-category__name-${category.name}`}
              style={{ color: category.color }}
            >
              {category.name}
            </div>
          </div>
        );
      })}
    </div>
  );
};
