import { renderProgramSummaryValue } from '../../util';
import { ResolvedProgramSummary } from '../../types';

const CalendarExtraField = (props: {
  programSummary: ResolvedProgramSummary;
  visibleColumns: string[];
  shortcutFieldNamesOnly: boolean;
}) => {
  const { programSummary, visibleColumns, shortcutFieldNamesOnly } = props;
  return (
    <div className="calendar__program-fields-wrapper">
      {visibleColumns.map((col) => (
        <div
          key={col}
          className="calendar__program-event-column text--truncated"
          data-test={`calendar__program-event-column-${col}`}
        >
          {renderProgramSummaryValue(programSummary, col, shortcutFieldNamesOnly)}
        </div>
      ))}
    </div>
  );
};

export default CalendarExtraField;
