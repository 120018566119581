import { ResolvedChatMessage, TOOL_CREATE_PROGRAMS } from '../../../growegy-ai/types';
import useAuth from '../../../auth/authContext';
import { ProgramCalendarEvent } from '../calendar/ProgramCalendarEvent';
import { UnscheduledViewType } from '../../containers/types';
import { ResolvedProgramDatesField, ResolvedProgramOwnerField } from '../../types';

export const CreateProgramsMessageContent = (props: { message: ResolvedChatMessage }) => {
  const { message } = props;
  const {
    state: { user },
  } = useAuth();
  if (!user) return null;
  return (
    <div className="message-list__programs-container">
      {message.resolvedToolCalls
        ?.filter((x) => x.name === TOOL_CREATE_PROGRAMS)
        .flatMap((x) => x.arguments.programs)
        .map((x, index) => {
          return (
            <div className="program-calendar__program-underlayer" key={`create_program_${index}`}>
              <ProgramCalendarEvent
                program={x}
                user={user}
                onClick={null}
                showCampaigns={false}
                applicableVisibleColumns={[ResolvedProgramDatesField, ResolvedProgramOwnerField]}
                viewType={UnscheduledViewType}
                shortcutFieldNamesOnly={false}
              />
            </div>
          );
        })}
      <div>Would you like to create the programs?</div>
    </div>
  );
};
