import useApi from '../../api/backendApiContext';
import { useQuery } from '@tanstack/react-query';
import { CampaignsCacheKey } from '../../hooks/useQueryCacheManager';
import { ReactQueryConstants } from '../../commons/constants';

export const useFetchedCampaigns = () => {
  const { programApi } = useApi();

  return useQuery(CampaignsCacheKey, async () => programApi.getCampaigns(), {
    refetchOnWindowFocus: false,
    staleTime: ReactQueryConstants.campaignsStaleTime,
    refetchInterval: ReactQueryConstants.campaignsRefetchInterval,
  });
};
