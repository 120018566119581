import { ErrorMessage, Field } from 'formik';
import { EyeIcon, EyeCrossedIcon } from '../../assets/icons';
import React, { useState } from 'react';
import { DivButton } from '../../commons/components/DivButton';
const PassInput = (props: {
  name: string;
  autoComplete: string;
  id?: string;
  placeholder: string;
  dataTest: string;
  invalid: boolean;
}) => {
  const { name, autoComplete, id, placeholder, dataTest, invalid } = props;

  const [passwordShow, setPasswordShow] = useState(false);

  return (
    <>
      <div className={`pass__field ${invalid ? 'is-invalid' : ''}`}>
        <Field
          type={passwordShow ? 'text' : 'password'}
          name={name}
          id={id}
          autoComplete={autoComplete}
          placeholder={placeholder}
          className={'auth__input growegy-label14'}
          data-test={dataTest}
        />
        <DivButton
          icon={passwordShow ? <EyeCrossedIcon /> : <EyeIcon />}
          onClick={() => setPasswordShow((prevState) => !prevState)}
          dataTest="pass__show-password"
          className="pass__show-password"
        />
      </div>
      <ErrorMessage
        component="div"
        name={name}
        className="invalid-feedback"
        data-test={`${dataTest}-error`}
      />
    </>
  );
};

export default PassInput;
