import React, { useEffect, useRef } from 'react';
import { mapValues } from 'lodash';
import moment from 'moment';
import * as yup from 'yup';
import { DateSchema } from 'yup';
import { Formik } from 'formik';
import Modal from 'react-modal';
import 'react-datepicker/dist/react-datepicker.css';
import {
  FormulaScope,
  NoCampaign,
  NoProgramType,
  ProgramDialogAction,
  ProgramEditFormikModel,
  ProgramKinds,
  ProgramTask,
  ProgramType,
  ResolvedCampaign,
  ResolvedProgram,
  UnknownCampaign,
  UnknownProgramType,
} from '../types';
import { getCustomFieldNamesSum } from '../util';
import { convertFormulasFromHumanToReactMentions, programToMathJsScope } from '../formulaUtil';
// eslint-disable-next-line import/no-cycle
import { ProgramEditFormik } from './ProgramEditFormik';
import clsx from 'clsx';
import {
  useAddProgramPermissionHelper,
  useProgramPermissionHelper,
} from '../programPermissionHelper';
import { UnknownProgramOwner } from '../../user/types';

const validationSchema = yup.object({
  name: yup.string().required('Name is required!'),
  programKind: yup.string().oneOf(ProgramKinds),
  type: yup.object().when('programKind', {
    is: 'PROGRAM',
    then: yup.object({
      id: yup
        .string()
        .required('Type is required!')
        .notOneOf([NoProgramType.id, UnknownProgramType.id]),
    }),
  }),
  campaign: yup
    .object()
    .when('programKind', {
      is: 'PROGRAM',
      then: yup.object().when('isCampaignRequired', {
        is: true,
        then: yup
          .object({
            id: yup
              .string()
              .required('Required!')
              .notOneOf([NoCampaign.id, UnknownCampaign.id], 'Required!'),
          })
          .required('Required!'),
      }),
    })
    .nullable(true),
  owner: yup
    .object({
      id: yup.string().required('Owner is required!').notOneOf([UnknownProgramOwner.id]),
    })
    .nullable(true),
  leads: yup.number().strict(true).required('Required!').integer('Must be integer!'),
  budget: yup.number().strict(true).required('Required!'),
  startDateTime: yup.date().required('Start value is required!'),
  endDateTime: yup
    .date()
    .required('End value is required!')
    .when('wholeDay', {
      is: true,
      then: yup.date().min(yup.ref('startDateTime'), 'End must be not less then start!'),
      otherwise: yup
        .date()
        .when('startDateTime', (startDate: Date, field: DateSchema) =>
          field.min(moment(startDate).add(1, 'minute').toDate(), 'End must be greater then start!')
        ),
    }),
  customFields: yup.array().of(
    yup.object({
      name: yup
        .string()
        .min(2, 'At least two symbols')
        .matches(/^(?=.*[A-Za-z])/, 'At least one letter')
        .matches(/^(?!.*([[\]~])).*/, 'Square brackets are not allowed')
        .required('Required!'),
      value: yup
        .mixed()
        .when('type', {
          is: 'text',
          then: yup.string().required('Required!'),
        })
        .when('type', {
          is: 'number',
          then: yup.number().strict(true).required('Number required!'),
        })
        .when('type', {
          is: 'formula',
          then: yup.string().required('Required!'),
        }),
    })
  ),
  formulaScope: yup.lazy((scope) =>
    yup.object(
      mapValues(scope as FormulaScope, () =>
        yup
          .number()
          .typeError('Amount must be a number')
          .notOneOf([Number.NEGATIVE_INFINITY, Number.POSITIVE_INFINITY])
          .required()
          .strict(true)
      )
    )
  ),
  customFieldsNameSums: yup.lazy((scope) =>
    yup.object(
      mapValues(scope as FormulaScope, () =>
        yup.number().oneOf([0, 1, undefined]).required().strict(true)
      )
    )
  ),
});

export type ProgramEditProps = {
  disabled: boolean;
  initialProgram: ResolvedProgram;
  programTypes: ProgramType[];
  createError: unknown;
  updateError: unknown;
  deleteError: unknown;
  handleHideClick: () => void;
  handleApplyClick: (change: ResolvedProgram) => Promise<void>;
  handleRefetchClick: () => Promise<void>;
  handleDeleteClick: () => Promise<void>;
  handleCopyClick: (copyCampaign: boolean) => void;
  handleConvertTaskToProgramClick: (task: ProgramTask, copyCampaign: boolean) => void;
  handleShowCampaignClick: (c: ResolvedCampaign) => void;
  action: ProgramDialogAction;
  scrollToTaskId: string | null;
};

export type ProgramEditModalProps = ProgramEditProps & { showModal: boolean };

const toProgramEditModel = (
  p: ResolvedProgram,
  isCampaignRequired: boolean
): ProgramEditFormikModel => {
  const { scope, mentionsData } = programToMathJsScope(p);
  const customFieldsNameSums = getCustomFieldNamesSum(p.customFields.map(({ name }) => name));
  const customFields = convertFormulasFromHumanToReactMentions(p);
  return {
    ...p,
    formulaScope: scope,
    mentionsData,
    customFieldsNameSums,
    customFields,
    isSalesforceInputFocused: false,
    isChangedCampaignPrograms: false,
    isCampaignRequired,
  };
};

export const ProgramEditModal = ({
  showModal,
  disabled,
  initialProgram,
  programTypes,
  createError,
  deleteError,
  updateError,
  handleHideClick,
  handleApplyClick,
  handleRefetchClick,
  handleDeleteClick,
  handleCopyClick,
  handleConvertTaskToProgramClick,
  handleShowCampaignClick,
  action,
  scrollToTaskId,
}: ProgramEditModalProps) => {
  const inputNameRef = useRef<HTMLInputElement>();
  useEffect(() => {
    if (!disabled && scrollToTaskId === null) inputNameRef.current?.focus();
  }, [action, disabled, scrollToTaskId]);
  const programPermissionHelper = useProgramPermissionHelper(initialProgram);
  const addProgramPermissionHelper = useAddProgramPermissionHelper();
  const isCampaignRequired =
    action === 'UPDATE'
      ? !programPermissionHelper.canMakeStandalone()
      : !addProgramPermissionHelper.canCreateStandaloneProgram();

  const copyCampaign =
    !!initialProgram.campaign &&
    addProgramPermissionHelper.canCreateProgramInCampaign(initialProgram.campaign);
  return (
    <>
      <Modal
        isOpen={showModal}
        onAfterClose={handleHideClick}
        className={clsx('program-modal', { 'program-modal__second-layer': false })}
        overlayClassName={clsx('growegy-modal__overlay', {
          'program-modal-backdrop__second-layer': false,
        })}
        shouldCloseOnEsc={true}
        shouldCloseOnOverlayClick={false}
        onRequestClose={handleHideClick}
        testId="program-edit__dialog"
        ariaHideApp={false}
      >
        <Formik
          initialValues={toProgramEditModel(initialProgram, isCampaignRequired)}
          validationSchema={validationSchema}
          onSubmit={async (p) => handleApplyClick(p)}
          enableReinitialize={true}
        >
          {({
            touched,
            errors,
            isValid,
            handleSubmit,
            handleChange,
            handleBlur,
            values,
            setFieldValue,
            setFieldTouched,
            initialValues,
          }) => (
            <ProgramEditFormik
              touched={touched}
              errors={errors}
              isValid={isValid}
              handleSubmit={handleSubmit}
              handleChange={handleChange}
              handleBlur={handleBlur}
              values={values}
              setFieldValue={setFieldValue}
              setFieldTouched={setFieldTouched}
              initialValues={initialValues}
              disabled={disabled}
              programTypes={programTypes}
              handleShowCampaignClick={handleShowCampaignClick}
              handleCopyClick={() => handleCopyClick(copyCampaign)}
              handleConvertTaskToProgramClick={(task: ProgramTask) =>
                handleConvertTaskToProgramClick(task, copyCampaign)
              }
              handleHideClick={handleHideClick}
              handleDeleteClick={handleDeleteClick}
              action={action}
              createError={createError}
              handleRefetchClick={handleRefetchClick}
              scrollToTaskId={scrollToTaskId}
              deleteError={deleteError}
              updateError={updateError}
              programPermissionHelper={programPermissionHelper}
              addProgramPermissionHelper={addProgramPermissionHelper}
            />
          )}
        </Formik>
      </Modal>
    </>
  );
};

export const ProgramEditDeepLink = ({
  disabled,
  initialProgram,
  programTypes,
  createError,
  deleteError,
  updateError,
  handleHideClick,
  handleApplyClick,
  handleRefetchClick,
  handleDeleteClick,
  handleCopyClick,
  handleConvertTaskToProgramClick,
  handleShowCampaignClick,
  action,
  scrollToTaskId,
}: ProgramEditProps) => {
  const inputNameRef = useRef<HTMLInputElement>();
  useEffect(() => {
    if (!disabled && scrollToTaskId === null) inputNameRef.current?.focus();
  }, [action, disabled, scrollToTaskId]);
  const programPermissionHelper = useProgramPermissionHelper(initialProgram);
  const addProgramPermissionHelper = useAddProgramPermissionHelper();
  const isCampaignRequired =
    action === 'UPDATE'
      ? !programPermissionHelper.canMakeStandalone()
      : !addProgramPermissionHelper.canCreateStandaloneProgram();

  const copyCampaign =
    !!initialProgram.campaign &&
    addProgramPermissionHelper.canCreateProgramInCampaign(initialProgram.campaign);
  return (
    <div>
      <Formik
        initialValues={toProgramEditModel(initialProgram, isCampaignRequired)}
        validationSchema={validationSchema}
        onSubmit={async (p) => handleApplyClick(p)}
        enableReinitialize={true}
      >
        {({
          touched,
          errors,
          isValid,
          handleSubmit,
          handleChange,
          handleBlur,
          values,
          setFieldValue,
          setFieldTouched,
          initialValues,
        }) => (
          <ProgramEditFormik
            touched={touched}
            errors={errors}
            isValid={isValid}
            handleSubmit={handleSubmit}
            handleChange={handleChange}
            handleBlur={handleBlur}
            values={values}
            setFieldValue={setFieldValue}
            setFieldTouched={setFieldTouched}
            initialValues={initialValues}
            disabled={disabled}
            programTypes={programTypes}
            handleShowCampaignClick={handleShowCampaignClick}
            handleCopyClick={() => handleCopyClick(copyCampaign)}
            handleConvertTaskToProgramClick={(task: ProgramTask) =>
              handleConvertTaskToProgramClick(task, copyCampaign)
            }
            handleHideClick={handleHideClick}
            handleDeleteClick={handleDeleteClick}
            action={action}
            createError={createError}
            handleRefetchClick={handleRefetchClick}
            scrollToTaskId={scrollToTaskId}
            deleteError={deleteError}
            updateError={updateError}
            programPermissionHelper={programPermissionHelper}
            addProgramPermissionHelper={addProgramPermissionHelper}
          />
        )}
      </Formik>
    </div>
  );
};
