import moment from 'moment';
import { useMemo } from 'react';
import _ from 'lodash';
import {
  FetchQueryOptions,
  Query,
  QueryClient,
  QueryKey,
  useQueryClient,
} from '@tanstack/react-query';
import { OneMonthSummaries, Program, ProgramSummary, ProgramTypes } from '../program/types';
import {
  areCustomFieldsNotEmpty,
  areCustomFieldsSame,
  extractExistedCustomFields,
  extractMonths,
} from '../program/util';
import { DataSeriesName, GroupByFieldName } from '../analytics/types';
import { GoogleOauth } from '../google-calendar/types';
import { ProgramMultiFiltersDto } from '../analytics/analyticsApi';
import { ProgramAttachmentWithUrl } from '../program/hooks/useAttachmentsContent';
import {
  Chat,
  ChatMessage,
  PromptRecentUsage,
  PromptMark,
  PromptVariable,
  AI_MESSAGE_SENDER,
  CLIENT_MESSAGE_SENDER,
} from '../growegy-ai/types';
import { OutlookOauth } from '../outlook/types';
import { Customer, Invoice } from '../billing/types';
import { ChatType } from '../growegy-ai/dto';

type CachedSummaries = { data: OneMonthSummaries | undefined; cache: string[] };
type CachedProgram = { data: Program | undefined; cache: string[] };

export type CacheBeforeUpdate = {
  program: CachedProgram;
  summaries: CachedSummaries[];
};

export const PingCacheKey = ['ping'];

const ProgramsMonthlyCacheName = 'program-summaries-monthly';
const ProgramCache = 'program';
const ProgramAttachments = 'program-attachments';
const ProgramAttachmentContent = 'program-attachment-content';

export const CampaignsCacheKey = ['all-campaigns'];
export const CampaignCacheName = 'campaign';

const UserSettingsCacheName = 'user-settings';
const NotificationSettingsCacheName = 'notification-settings';
const GoogleOauthCacheName = 'google-calendar-settings';
const OutlookOauthCacheName = 'outlook-settings';
const UserAvatarCacheName = 'user-avatar';
export const ProgramTypesCacheKey = ['program-types'];
export const AllCustomFieldsCacheKey = ['all-custom-fields'];
export const UsersCacheKey = ['users'];

export const IntegrationStateCacheKey = ['integration-state'];
export const IntegrationMetadatasCacheKey = ['integration-metadata'];
const TenantAllIntegrationsCacheName = 'tenant-all-integrations';
const TenantIntegrationCacheName = 'tenant-integrations';

const DataSeriesCacheName = 'data-series';
const GroupingCacheName = 'grouping';
const EnterpriseChartsAmount = 'enterpriseChartsAmount';
const EnterpriseCharts = 'enterpriseCharts';
const EnterpriseAnalyticLayout = 'enterpriseAnalyticLayout';

const ChatsCacheName = 'chats';
const PromptsCacheKey = ['prompts'];
const PromptMarksCacheKey = ['prompts-marks'];
const PromptVariablesCacheKey = ['prompt-variables'];
const PromptRecentUsagesCacheKey = ['prompt-recen-usages'];
const ChatMessagesCacheName = 'chat-messages';

const BillingInvoicesCacheKey = ['billing-invoices'];
const BillingCustomerCacheKey = ['billing-customer'];
const BillingPaymentMethodsCacheKey = ['billing-payment-methods'];

export const PersonalRecommendationsCacheName = 'personal-recommendations';

const summaryCacheKey = (date: Date): string[] => [
  ProgramsMonthlyCacheName,
  moment(date).startOf('month').toISOString(),
];

const getProgramCacheName = (id: string) => [ProgramCache, id];
const getUserSettingsCacheName = (id: string) => [UserSettingsCacheName, id];
const getNotificationSettingsCacheName = (id: string) => [NotificationSettingsCacheName, id];
const getGoogleOauthCacheName = (id: string) => [GoogleOauthCacheName, id];
const getOutlookOauthCacheName = (id: string) => [OutlookOauthCacheName, id];
const getUserAvatarCacheName = (id: string) => [UserAvatarCacheName, id];
const getProgramAttachmentsCacheName = (id: string) => [ProgramAttachments, id];
const getProgramAttachmentContentCacheName = (programId: string, attachmentId?: string) => [
  ProgramAttachmentContent,
  programId,
  ...(attachmentId ? [attachmentId] : []),
];
const getDataSeriesCacheName = (
  fromDate: number,
  toDate: number,
  filter: ProgramMultiFiltersDto | null
) => [DataSeriesCacheName, fromDate, toDate, JSON.stringify(filter)];
const getGroupingCacheName = (
  fromDate: number,
  toDate: number,
  groupBy: GroupByFieldName[],
  groupOf: DataSeriesName[],
  filter: ProgramMultiFiltersDto | null
) => [
  GroupingCacheName,
  fromDate,
  toDate,
  groupBy.sort().join(','),
  groupOf.sort().join(','),
  JSON.stringify(filter),
];

const getEnterpriseChartsAmountCacheKey = () => [EnterpriseChartsAmount];
const getEnterpriseChartsCacheKey = () => [EnterpriseCharts];
const getEnterpriseAnalyticLayoutCacheKey = () => [EnterpriseAnalyticLayout];

const getTenantAllIntegrationsCacheName = () => [TenantAllIntegrationsCacheName];
const getTenantIntegrationCacheName = (integrationId: string) => [
  TenantIntegrationCacheName,
  integrationId,
];

const getCampaignProgramsCacheName = (campaignId: string) => [CampaignCacheName, campaignId];

const getChatsCacheKey = (chatType: ChatType) => [ChatsCacheName, chatType];
const getChatMessagesCacheKey = (chatId: string) => [ChatMessagesCacheName, chatId];
const getPromptsCacheKey = () => PromptsCacheKey;
const getPromptMarksCacheKey = () => PromptMarksCacheKey;
const getPromptVariablesCacheKey = () => PromptVariablesCacheKey;
const getPromptRecentUsagesCacheKey = () => PromptRecentUsagesCacheKey;

const getBillingInvoicesCacheKey = () => BillingInvoicesCacheKey;
const getBillingCustomerCacheKey = () => BillingCustomerCacheKey;
const getBillingPaymentMethodsCacheKey = () => BillingPaymentMethodsCacheKey;

const createCacheManager = (queryClient: QueryClient) => {
  const appendSummary = (
    cacheName: string[],
    program: ProgramSummary
  ): { previous: OneMonthSummaries; updated: OneMonthSummaries } | null => {
    const currentCache = queryClient.getQueryData<OneMonthSummaries>(cacheName);

    if (currentCache && Array.isArray(currentCache.summaries)) {
      const updatedSummaries = [...currentCache.summaries, _.cloneDeep(program)];
      const updatedCache = {
        startMonth: currentCache.startMonth,
        summaries: updatedSummaries,
      };
      queryClient.setQueryData<OneMonthSummaries>(cacheName, updatedCache);

      return { previous: currentCache, updated: updatedCache };
    }
    return null;
  };

  const deleteSummary = (
    cacheName: string[],
    programId: string
  ): { previous: OneMonthSummaries; updated: OneMonthSummaries } | null => {
    const currentCache = queryClient.getQueryData<OneMonthSummaries>(cacheName);

    if (currentCache && Array.isArray(currentCache.summaries)) {
      const updatedSummaries = [...currentCache.summaries];
      const idx = updatedSummaries.findIndex((p) => p.id === programId);
      if (idx >= 0) {
        updatedSummaries.splice(idx, 1);
      }
      const updatedCache = {
        startMonth: currentCache.startMonth,
        summaries: updatedSummaries,
      };
      queryClient.setQueryData<OneMonthSummaries>(cacheName, updatedCache);

      return { previous: currentCache, updated: updatedCache };
    }
    return null;
  };

  const replaceSummary = (
    cacheName: string[],
    program: ProgramSummary
  ): { previous: OneMonthSummaries; updated: OneMonthSummaries } | null => {
    const currentCache = queryClient.getQueryData<OneMonthSummaries>(cacheName);
    if (currentCache && Array.isArray(currentCache.summaries)) {
      const updatedSummaries = [...currentCache.summaries];
      const idx = updatedSummaries.findIndex((p) => p.id === program.id);
      if (idx >= 0) {
        updatedSummaries.splice(idx, 1, _.cloneDeep(program));
      }
      const updatedCache = {
        startMonth: currentCache.startMonth,
        summaries: updatedSummaries,
      };
      queryClient.setQueryData<OneMonthSummaries>(cacheName, updatedCache);

      return { previous: currentCache, updated: updatedCache };
    }
    return null;
  };

  const onDeleteSuccess = async (p: ProgramSummary) => {
    const result = extractMonths(p)
      .map(summaryCacheKey)
      .map((cache) => ({ cache, data: deleteSummary(cache, p.id)?.previous }));

    queryClient.removeQueries(getProgramCacheName(p.id));
    await queryClient.invalidateQueries(getProgramCacheName(p.id));
    await queryClient.invalidateQueries([DataSeriesCacheName]);
    await queryClient.invalidateQueries([GroupingCacheName]);
    if (p.campaignId) {
      await queryClient.invalidateQueries(getCampaignProgramsCacheName(p.campaignId));
    }

    return result;
  };

  const getProgramCache = (program: ProgramSummary) => {
    const programCacheName = getProgramCacheName(program.id);
    const previousValue = queryClient.getQueryData<Program>(programCacheName);
    return { cache: programCacheName, data: previousValue };
  };

  const putProgram = (change: ProgramSummary): CachedProgram => {
    const programCacheName = getProgramCacheName(change.id);
    cancelQueriesNoWait(programCacheName);
    const changeCustomFields = extractExistedCustomFields([change]);
    const previousValue = queryClient.getQueryData<Program>(programCacheName);
    if (previousValue) {
      const updatedValue: Program = { ...previousValue, ...change };
      queryClient.setQueryData<Program>(programCacheName, updatedValue);
      const origCustomFields = extractExistedCustomFields([previousValue]);
      const customFieldsSame = areCustomFieldsSame(origCustomFields, changeCustomFields);
      if (!customFieldsSame) {
        invalidateQueriesNoWait(AllCustomFieldsCacheKey);
      }
    } else {
      queryClient.setQueryData<Program>(programCacheName, { ...change });
      if (areCustomFieldsNotEmpty(changeCustomFields)) {
        invalidateQueriesNoWait(AllCustomFieldsCacheKey);
      }
    }

    invalidateQueriesNoWait(programCacheName);
    invalidateQueriesNoWait(getProgramAttachmentsCacheName(change.id));
    invalidateQueriesNoWait([DataSeriesCacheName]);
    invalidateQueriesNoWait([GroupingCacheName]);
    return { cache: programCacheName, data: previousValue };
  };

  const cancelQueriesNoWait = (key: QueryKey) => {
    // noinspection JSIgnoredPromiseFromCall
    queryClient.cancelQueries(key);
  };

  const invalidateQueriesNoWait = (key: QueryKey) => {
    // noinspection JSIgnoredPromiseFromCall
    queryClient.invalidateQueries(key);
  };

  const onUpdateSuccess = (program: ProgramSummary) => {
    const { data: previousProgram } = putProgram(program);
    updateOptimistically(previousProgram ? previousProgram : program, program);
  };

  const updateOptimistically = (
    original: ProgramSummary,
    change: ProgramSummary
  ): CacheBeforeUpdate => {
    const cachedProgram: CachedProgram = putProgram(change);

    const originalMonths = extractMonths(original);
    const updatedMonths = extractMonths(change);

    const intersection = _.intersectionBy(originalMonths, updatedMonths, (p) => p.valueOf());
    const toDelete = _.differenceBy(originalMonths, intersection, (p) => p.valueOf());
    const toAdd = _.differenceBy(updatedMonths, intersection, (p) => p.valueOf());

    const beforeUpdate: CachedSummaries[] = intersection.map(summaryCacheKey).map((cache) => ({
      cache,
      data: replaceSummary(cache, change)?.previous,
    }));
    const beforeDelete: CachedSummaries[] = toDelete.map(summaryCacheKey).map((cache) => ({
      cache,
      data: deleteSummary(cache, change.id)?.previous,
    }));
    const beforeAdd: CachedSummaries[] = toAdd.map(summaryCacheKey).map((cache) => ({
      cache,
      data: appendSummary(cache, change)?.previous,
    }));

    if (original.campaignId)
      invalidateQueriesNoWait(getCampaignProgramsCacheName(original.campaignId));

    if (change.campaignId && change.campaignId !== original.campaignId)
      invalidateQueriesNoWait(getCampaignProgramsCacheName(change.campaignId));

    if (original.programKind === 'CAMPAIGN') {
      invalidateQueriesNoWait(CampaignsCacheKey);
    }

    return {
      program: cachedProgram,
      summaries: [...beforeAdd, ...beforeDelete, ...beforeUpdate],
    };
  };

  const rollback = (prevValue: CacheBeforeUpdate): void => {
    const { program, summaries } = prevValue;
    if (program.data) queryClient.setQueryData<Program>(program.cache, program.data);
    else queryClient.removeQueries(program.cache);

    summaries.forEach(({ cache, data }) => {
      if (data) queryClient.setQueryData<OneMonthSummaries>(cache, data);
      else queryClient.removeQueries(cache);
    });
  };

  const deleteAll = async (): Promise<void> => {
    await queryClient.invalidateQueries({
      queryKey: [ProgramsMonthlyCacheName],
      refetchType: 'active',
    });
    await queryClient.invalidateQueries({ queryKey: [ProgramCache], refetchType: 'active' });
    await queryClient.invalidateQueries([DataSeriesCacheName]);
    await queryClient.invalidateQueries([GroupingCacheName]);
  };

  const onCampaignCreate = (campaign: Program) => {
    const currentCache = queryClient.getQueryData<Program[]>(CampaignsCacheKey) || [];
    queryClient.setQueryData<Program[]>(CampaignsCacheKey, [...currentCache, campaign]);
  };
  const onCampaignUpdate = (campaign: Program) => {
    const currentCache = queryClient.getQueryData<Program[]>(CampaignsCacheKey) || [];
    const idx = currentCache.findIndex((p) => p.id === campaign.id);
    if (idx >= 0) {
      currentCache.splice(idx, 1, campaign);
      queryClient.setQueryData<Program[]>(CampaignsCacheKey, [...currentCache]);
    }
  };

  const onCreate = async (id: string, newProgram: Program) => {
    queryClient.setQueryData<Program>(getProgramCacheName(id), {
      ...newProgram,
      id,
    });
    await queryClient.invalidateQueries(getProgramCacheName(id));
    await queryClient.invalidateQueries([DataSeriesCacheName]);
    await queryClient.invalidateQueries([GroupingCacheName]);
    const customFields = extractExistedCustomFields([newProgram]);
    if (areCustomFieldsNotEmpty(customFields)) {
      await queryClient.invalidateQueries(AllCustomFieldsCacheKey);
    }
    if (newProgram.campaignId) {
      await queryClient.invalidateQueries(getCampaignProgramsCacheName(newProgram.campaignId));
    }
    const append = { ...newProgram, id };
    return extractMonths(newProgram)
      .map(summaryCacheKey)
      .map((cache) => ({ cache, data: appendSummary(cache, append)?.previous }));
  };

  const onUpdateError = async (id: string, invalidateSummaries?: boolean): Promise<void> => {
    await queryClient.invalidateQueries(getProgramCacheName(id));
    if (invalidateSummaries)
      await queryClient.invalidateQueries({ queryKey: [ProgramsMonthlyCacheName] });
    await queryClient.invalidateQueries(getProgramAttachmentsCacheName(id));
    await queryClient.invalidateQueries([DataSeriesCacheName]);
    await queryClient.invalidateQueries([GroupingCacheName]);
  };

  const onDeleteError = async (id: string): Promise<void> => {
    await queryClient.invalidateQueries(getProgramCacheName(id));
    await queryClient.invalidateQueries(getProgramAttachmentsCacheName(id));
    await queryClient.invalidateQueries([DataSeriesCacheName]);
    await queryClient.invalidateQueries([GroupingCacheName]);
  };

  const initProgramsRefetch = async (id: string): Promise<void> => {
    await queryClient.invalidateQueries(getCampaignProgramsCacheName(id));
    await queryClient.invalidateQueries(getProgramCacheName(id));
    await queryClient.invalidateQueries([DataSeriesCacheName]);
    await queryClient.invalidateQueries([GroupingCacheName]);
  };

  const initAttachmentsRefetch = async (id: string): Promise<void> => {
    await queryClient.invalidateQueries(getProgramAttachmentsCacheName(id));
  };

  const initAttachmentContentRefetch = async (
    programId: string,
    attachmentId: string
  ): Promise<void> => {
    await queryClient.invalidateQueries(
      getProgramAttachmentContentCacheName(programId, attachmentId)
    );
  };

  const initTypesRefetch = async (): Promise<void> => {
    await queryClient.invalidateQueries({ queryKey: ProgramTypesCacheKey });
  };

  const initUsersRefetch = async (): Promise<void> => {
    await queryClient.invalidateQueries(UsersCacheKey);
  };

  const initUserSettingsRefetch = async (id: string): Promise<void> => {
    await queryClient.invalidateQueries(getUserSettingsCacheName(id));
  };

  const initNotificationSettingsRefetch = async (id: string): Promise<void> => {
    await queryClient.invalidateQueries(getNotificationSettingsCacheName(id));
  };

  const setGoogleOauth = async (userId: string, googleOauth: GoogleOauth): Promise<void> => {
    await queryClient.setQueryData(getGoogleOauthCacheName(userId), googleOauth);
  };

  const setOutlookOauth = async (userId: string, outlookOauth: OutlookOauth): Promise<void> => {
    await queryClient.setQueryData(getOutlookOauthCacheName(userId), outlookOauth);
  };

  const initCampaignsRefetch = async (): Promise<void> => {
    await queryClient.invalidateQueries(CampaignsCacheKey);
  };

  const updateProgramTypes = async (change: ProgramTypes): Promise<void> => {
    await queryClient.cancelQueries({ queryKey: [ProgramTypesCacheKey] });
    queryClient.setQueryData<ProgramTypes>(ProgramTypesCacheKey, change);
  };

  const initTenantIntegrationsRefetch = async (): Promise<void> => {
    await queryClient.invalidateQueries([TenantIntegrationCacheName]);
    await queryClient.invalidateQueries(getTenantAllIntegrationsCacheName());
    await queryClient.invalidateQueries(IntegrationStateCacheKey);
  };

  const getPersonalRecommendationsCacheKey = () => [PersonalRecommendationsCacheName];

  const fetchQuery = async <T>(query: FetchQueryOptions<T>) => queryClient.fetchQuery(query);
  const invalidateQuery = (queryKey: string[]): void => {
    // noinspection JSIgnoredPromiseFromCall
    queryClient.invalidateQueries(queryKey);
  };

  const onChatCreated = (chat: Chat) => {
    const key = getChatsCacheKey(chat.type);
    const oldChats = queryClient.getQueryData<Chat[]>(key);
    queryClient.setQueryData(key, [chat, ...(oldChats ?? [])]);
    if (!oldChats) queryClient.invalidateQueries(key);
  };

  const onChatUpdated = (chat: Chat) => {
    const key = getChatsCacheKey(chat.type);
    const oldChats = queryClient.getQueryData<Chat[]>(key) ?? [];
    const newChats = oldChats.map((x) => (x.id === chat.id ? chat : x));
    queryClient.setQueryData(key, newChats);
  };

  const onChatDeleted = (chat: Chat) => {
    const chatsKey = getChatsCacheKey(chat.type);
    const oldChats = queryClient.getQueryData<Chat[]>(chatsKey) ?? [];
    const newChats = oldChats.filter((x) => x.id !== chat.id);
    queryClient.setQueryData(chatsKey, newChats);

    const messagesKey = getChatMessagesCacheKey(chat.id);
    queryClient.removeQueries(messagesKey);
  };

  const getMessages = (chatId: string) => {
    const key = getChatMessagesCacheKey(chatId);
    return queryClient.getQueryData<ChatMessage[]>(key) ?? [];
  };

  const onMessagesAdded = (chatId: string, prompt: ChatMessage, answer: ChatMessage | null) => {
    const key = getChatMessagesCacheKey(chatId);
    const oldMessages = queryClient.getQueryData<ChatMessage[]>(key) ?? [];
    const newMessages: ChatMessage[] = [...oldMessages, prompt, ...(answer ? [answer] : [])];
    queryClient.setQueryData(key, newMessages);
  };

  const isSameMessage = (a: ChatMessage, b: ChatMessage) =>
    (!!a.id && a.id === b.id) ||
    (!!a.clientId && !!a.sender && a.sender === b.sender && a.clientId === b.clientId);

  const onMessagesUpdated = (chatId: string, prompt: ChatMessage, answer: ChatMessage | null) => {
    const key = getChatMessagesCacheKey(chatId);
    const oldMessages = queryClient.getQueryData<ChatMessage[]>(key) ?? [];
    const newMessages: ChatMessage[] = oldMessages
      .map((x) =>
        isSameMessage(x, prompt) ? prompt : answer && isSameMessage(x, answer) ? answer : x
      )
      .filter(
        (x) => answer !== null || x.sender !== AI_MESSAGE_SENDER || x.clientId !== prompt.clientId
      );
    queryClient.setQueryData(key, newMessages);
  };

  const onMessageFailed = (
    chatId: string,
    promptClientId: string,
    answerClientId: string | null,
    errorMessage: string | null
  ) => {
    const key = getChatMessagesCacheKey(chatId);
    const oldMessages = queryClient.getQueryData<ChatMessage[]>(key);
    if (!oldMessages) return;
    const newMessages: ChatMessage[] = oldMessages
      .map((x) => {
        if (x.clientId === promptClientId && x.sender === CLIENT_MESSAGE_SENDER)
          return {
            ...x,
            sendFailed: true,
            errorMessage,
          };
        if (x.clientId === answerClientId && x.sender === AI_MESSAGE_SENDER) return null;
        return x;
      })
      .filter((x) => x !== null) as ChatMessage[];
    queryClient.setQueryData(key, newMessages);
  };

  const onMessageDeleted = (chatId: string, message: ChatMessage) => {
    const key = getChatMessagesCacheKey(chatId);
    const oldMessages = queryClient.getQueryData<ChatMessage[]>(key);
    const newMessages = oldMessages?.filter((x) => !isSameMessage(x, message));
    queryClient.setQueryData(key, newMessages);
  };

  const onMessageCancelled = (chatId: string, clientMessageId: string) => {
    const key = getChatMessagesCacheKey(chatId);
    const oldMessages = queryClient.getQueryData<ChatMessage[]>(key);
    if (!oldMessages) return;
    const newMessages = oldMessages.map((x) =>
      x.sender === AI_MESSAGE_SENDER && x.clientId === clientMessageId
        ? { ...x, isCancelled: true }
        : x
    );
    queryClient.setQueryData(key, newMessages);
  };

  const invalidateVariables = async () => {
    await queryClient.invalidateQueries(getPromptVariablesCacheKey());
  };

  const updatePromptVariable = (promptVariable: PromptVariable) => {
    const key = getPromptVariablesCacheKey();
    const promptVariables = queryClient.getQueryData<PromptVariable[]>(key) ?? [];
    const newPromptVariables = promptVariables.some((x) => x.id === promptVariable.id)
      ? promptVariables.map((x) => (x.id === promptVariable.id ? promptVariable : x))
      : [...promptVariables, promptVariable];
    queryClient.setQueryData(key, newPromptVariables);
  };

  const addRecentlyUsedPrompt = (promptId: string) => {
    const key = getPromptRecentUsagesCacheKey();
    const promptUsages = queryClient.getQueryData<PromptRecentUsage[]>(key) ?? [];
    const usage: PromptRecentUsage = {
      promptId,
      updatedDate: new Date(),
      messageId: null,
      usageId: null,
    };

    const updatedPromptUsages = [usage, ...promptUsages];
    queryClient.setQueryData(key, updatedPromptUsages);
  };

  const updatePromptMark = (promptMark: PromptMark) => {
    const key = getPromptMarksCacheKey();
    const promptMarks = queryClient.getQueryData<PromptMark[]>(key) ?? [];
    const newPromptMarks = promptMarks.some((x) => x.promptId === promptMark.promptId)
      ? promptMarks.map((x) => (x.promptId === promptMark.promptId ? promptMark : x))
      : [...promptMarks, promptMark];
    queryClient.setQueryData(key, newPromptMarks);
  };

  const invalidatePromptMarks = async () => {
    await queryClient.invalidateQueries(getPromptMarksCacheKey());
  };

  const invalidateBillingCustomer = async () => {
    await queryClient.invalidateQueries(getBillingCustomerCacheKey());
  };

  const updateBillingCustomer = async (customerUpdate: Partial<Customer>) => {
    const key = getBillingCustomerCacheKey();
    const cachedCustomer = queryClient.getQueryData<Customer>(key);
    if (cachedCustomer) {
      await queryClient.setQueryData(key, { ...cachedCustomer, ...customerUpdate });
    }
  };

  const invalidateInvoices = async () => {
    await queryClient.invalidateQueries(getBillingInvoicesCacheKey());
  };

  const updateInvoices = async (invoices: Invoice[]) => {
    await queryClient.setQueryData(getBillingInvoicesCacheKey(), invoices);
  };

  return {
    getMonthly: summaryCacheKey,
    getProgramCacheName,
    getProgramAttachmentsCacheName,
    getProgramAttachmentContentCacheName,
    onDeleteSuccess,
    updateOptimistically,
    getProgramCache,
    rollback,
    deleteAll,
    onCampaignCreate,
    onCampaignUpdate,
    onCreate,
    onUpdateSuccess,
    onUpdateError,
    onDeleteError,
    initProgramsRefetch,
    initTypesRefetch,
    initUsersRefetch,
    initAttachmentsRefetch,
    initAttachmentContentRefetch,
    initCampaignsRefetch,
    updateProgramTypes,
    getUserSettingsCacheName,
    getUserAvatarCacheName,
    initUserSettingsRefetch,
    getTenantAllIntegrationsCacheName,
    getTenantIntegrationCacheName,
    initTenantIntegrationsRefetch,
    getDataSeriesCacheName,
    getGroupingCacheName,
    getCampaignProgramsCacheName,
    getPersonalRecommendationsCacheName: getPersonalRecommendationsCacheKey,
    getEnterpriseChartsAmountCacheName: getEnterpriseChartsAmountCacheKey,
    getEnterpriseChartsCacheName: getEnterpriseChartsCacheKey,
    getEnterpriseAnalyticLayoutCacheName: getEnterpriseAnalyticLayoutCacheKey,
    getNotificationSettingsCacheName,
    initNotificationSettingsRefetch,
    getGoogleOauthCacheName,
    setGoogleOauth,
    getOutlookOauthCacheName,
    setOutlookOauth,
    fetchQuery,
    invalidateQuery,
    getChatsCacheKey,
    getChatMessagesCacheKey,
    onChatCreated,
    onChatUpdated,
    onChatDeleted,
    getMessages,
    onMessagesAdded,
    onMessagesUpdated,
    onMessageFailed,
    onMessageDeleted,
    onMessageCancelled,
    getPromptsCacheKey,
    getPromptVariablesCacheKey,
    updatePromptVariable,
    invalidateVariables,
    getPromptRecentUsagesCacheKey,
    addRecentlyUsedPrompt,
    getPromptMarksCacheKey,
    updatePromptMark,
    invalidatePromptMarks,
    getBillingInvoicesCacheKey,
    getBillingCustomerCacheKey,
    getBillingPaymentMethodsCacheKey,
    invalidateBillingCustomer,
    updateBillingCustomer,
    invalidateInvoices,
    updateInvoices,
  };
};

export const useQueryCacheManager = () => {
  const queryClient = useQueryClient();
  return useMemo(() => createCacheManager(queryClient), [queryClient]);
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const onQueryRemoved = (query: Query<any, any, any, any>) => {
  if ((query.queryKey as string[])[0] === ProgramAttachmentContent) {
    if (!query.state.data) return;
    const attachmentContent: ProgramAttachmentWithUrl = query.state.data;
    if (attachmentContent && attachmentContent.url) {
      try {
        window.URL.revokeObjectURL(attachmentContent.url);
      } catch (e) {
        logger.error('Failed to revoke object URL', e);
      }
    }
  }
};
