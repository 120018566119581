import { useNotification } from '../../notification/notificationsContext';
import { useResolvedProgramSummaries } from './useResolvedProgramSummaries';
import { QueryData, ResolvedProgram, ResolvedProgramSummary } from '../types';
import { useEffect } from 'react';

export default function useCampaignPrograms(campaign: ResolvedProgram) {
  const { notifyError } = useNotification();

  const isCampaign = campaign.programKind === 'CAMPAIGN';

  const {
    data: campaignProgramsData,
    error: campaignProgramsError,
    status: campaignProgramsStatus,
    fetchStatus: campaignProgramsFetchStatus,
    isStale: campaignProgramsStale,
  } = useResolvedProgramSummaries({
    campaignId: campaign.id,
    disabled: !isCampaign,
  });

  const loadedCampaignPrograms: QueryData<ResolvedProgramSummary[] | null> = {
    data:
      isCampaign && campaignProgramsData.length
        ? campaignProgramsData[0].data?.summaries ?? null
        : null,
    error: isCampaign ? campaignProgramsError : null,
    status: isCampaign ? campaignProgramsStatus : 'success',
    fetchStatus: isCampaign ? campaignProgramsFetchStatus : 'idle',
    isStale: isCampaign ? campaignProgramsStale : false,
  };

  useEffect(() => {
    if (campaignProgramsError && campaignProgramsStatus === 'error') {
      notifyError({
        err: campaignProgramsError,
        logMsg: `Failed to load campaign programs for ${JSON.stringify(campaign)} from backend`,
        notificationMsg: `Failed to load campaign's programs`,
      });
    }
  }, [notifyError, campaign, campaignProgramsError, campaignProgramsStatus]);
  return {
    programs: loadedCampaignPrograms.data || [],
    status: loadedCampaignPrograms.status,
  };
}
