import {
  ProgramType,
  ResolvedCampaign,
  ResolvedProgramSummary,
  ResolvedProgramTask,
} from '../../types';
import {
  CalendarEventExtendedProps,
  CalendarEventType,
  CalendarViewType,
  EventCompare,
} from '../../containers/types';
import moment from 'moment';

const compareTypes = (
  a:
    | { type: 'ProgramType'; value: ProgramType }
    | { type: 'CampaignGroupingMarkerArtificialProgramType' },
  b:
    | { type: 'ProgramType'; value: ProgramType }
    | { type: 'CampaignGroupingMarkerArtificialProgramType' }
) => {
  if (a.type === 'ProgramType' && b.type === 'ProgramType')
    return a.value.name.localeCompare(b.value.name);

  if (a.type === 'CampaignGroupingMarkerArtificialProgramType') return 1;
  if (b.type === 'CampaignGroupingMarkerArtificialProgramType') return -1;
  return 0;
};

const compareProgramsByMeta = (
  viewType: CalendarViewType,
  a:
    | { type: 'Program'; value: ResolvedProgramSummary }
    | { type: 'ProgramTypeMarkerArtificialProgram' },
  b:
    | { type: 'Program'; value: ResolvedProgramSummary }
    | { type: 'ProgramTypeMarkerArtificialProgram' }
) => {
  if (a.type === 'Program' && b.type === 'Program')
    return comparePrograms(viewType, a.value, b.value);

  if (a.type === 'ProgramTypeMarkerArtificialProgram') return 1;
  if (b.type === 'ProgramTypeMarkerArtificialProgram') return -1;
  return 0;
};

const comparePrograms = (
  viewType: CalendarViewType,
  programA: ResolvedProgramSummary,
  programB: ResolvedProgramSummary
) => {
  if (programA.id.length && programA.id === programB.id) return 0;
  if (viewType === 'monthGridQuarter') {
    if (getMonthsCount(programA) > getMonthsCount(programB)) return -1;
    if (getMonthsCount(programA) < getMonthsCount(programB)) return 1;
    const leftStart =
      programA.programKind === 'PROGRAM' ? programA.startDateTime : programA.startRangeDateTime;
    const rightStart =
      programB.programKind === 'PROGRAM' ? programB.startDateTime : programB.startRangeDateTime;
    if (sameMonth(leftStart, rightStart)) {
      if (leftStart < rightStart) return -1;
      if (leftStart > rightStart) return 1;
    }
  } else {
    if (getDurationMs(programA) > getDurationMs(programB)) return -1;
    if (getDurationMs(programA) < getDurationMs(programB)) return 1;
    if (programA.wholeDay !== programB.wholeDay) {
      if (programA.wholeDay) return -1;
      if (programB.wholeDay) return 1;
    }
  }
  if (programA.name < programB.name) return -1;
  if (programA.name > programB.name) return 1;
  if (programA.id < programB.id) return -1;
  if (programA.id > programB.id) return 1;
  return 0;
};

const compareCampaigns = (viewType: CalendarViewType, a: ResolvedCampaign, b: ResolvedCampaign) => {
  if (viewType === 'monthGridQuarter') {
    const nameComparison = a.name.localeCompare(b.name);
    if (nameComparison !== 0) return nameComparison;
  }
  return comparePrograms(viewType, a, b);
};

const compareTasks = (taskA: ResolvedProgramTask, taskB: ResolvedProgramTask) => {
  if (taskA.id === taskB.id) return 0;
  if (!taskA.dueDateTime || !taskB.dueDateTime) return 0;
  if (taskA.dueDateTime < taskB.dueDateTime) return -1;
  if (taskA.dueDateTime > taskB.dueDateTime) return 1;
  if (taskA.orderId < taskB.orderId) return -1;
  if (taskA.orderId > taskB.orderId) return 1;
  return 0;
};

type CampaignCompareMetadata = { type: 'Campaign'; value: ResolvedCampaign };
type ProgramTypeCompareMetadata = {
  type: 'ProgramType';
  value:
    | { type: 'ProgramType'; value: ProgramType }
    | { type: 'CampaignGroupingMarkerArtificialProgramType' };
};
type ProgramCompareMetadata = {
  type: 'Program';
  value:
    | { type: 'Program'; value: ResolvedProgramSummary }
    | { type: 'ProgramTypeMarkerArtificialProgram' };
};
type TaskCompareMetadata = { type: 'Task'; value: ResolvedProgramTask };

type CompareMetadata =
  | CampaignCompareMetadata
  | ProgramTypeCompareMetadata
  | ProgramCompareMetadata
  | TaskCompareMetadata;

const compareEventsByMetadata = (
  viewType: CalendarViewType,
  a: CompareMetadata[],
  b: CompareMetadata[]
): number => {
  if (a.length === 0 && b.length === 0) return 0;
  if (a.length === 0) return -1;
  if (b.length === 0) return 1;

  const left = a[0];
  const right = b[0];
  if (!left.value || !right.value) return 0;
  let compareResult = 0;
  if (left.type === 'Campaign' && right.type === 'Campaign') {
    compareResult = compareCampaigns(viewType, left.value, right.value);
  } else if (left.type === 'ProgramType' && right.type === 'ProgramType') {
    compareResult = compareTypes(left.value, right.value);
  } else if (left.type === 'Program' && right.type === 'Program') {
    compareResult = compareProgramsByMeta(viewType, left.value, right.value);
  } else if (left.type === 'Task' && right.type === 'Task') {
    compareResult = compareTasks(left.value, right.value);
  } else
    throw new Error(
      `Unexpected calendar event comparing types ${left.type} and ${
        right.type
      } from left metadata ${JSON.stringify(a)} and rigth metadata ${JSON.stringify(b)}`
    );
  if (compareResult !== 0) return compareResult;

  return compareEventsByMetadata(viewType, a.slice(1), b.slice(1));
};

const buildMetadata = (
  event: CalendarEventExtendedProps,
  viewType: CalendarViewType,
  isCampaignGroupingEnabled: boolean
): CompareMetadata[] => {
  switch (event.calendarEventType) {
    case CalendarEventType.campaign:
      return isCampaignGroupingEnabled
        ? [{ type: 'Campaign', value: event.origResolvedSummary }]
        : [];
    case CalendarEventType.campaignMarker: {
      const campaignMeta: CompareMetadata[] = isCampaignGroupingEnabled
        ? [{ type: 'Campaign', value: event.origCampaign }]
        : [];
      const fakeType: CompareMetadata[] =
        isCampaignGroupingEnabled && viewType === 'monthGridQuarter'
          ? [
              {
                type: 'ProgramType',
                value: { type: 'CampaignGroupingMarkerArtificialProgramType' },
              },
            ]
          : [];
      return [...campaignMeta, ...fakeType];
    }
    case CalendarEventType.program: {
      const campaignMeta: CompareMetadata[] = isCampaignGroupingEnabled
        ? [{ type: 'Campaign', value: event.origResolvedSummary.campaign! }]
        : [];
      const programMeta: CompareMetadata = {
        type: 'Program',
        value: { type: 'Program', value: event.origResolvedSummary },
      };
      const programTypeMeta: CompareMetadata[] =
        viewType === 'monthGridQuarter'
          ? [
              {
                type: 'ProgramType',
                value: { type: 'ProgramType', value: event.origResolvedSummary.type },
              },
            ]
          : [];
      return [...campaignMeta, ...programTypeMeta, programMeta];
    }
    case CalendarEventType.typeMarker: {
      const campaignMeta: CompareMetadata[] = isCampaignGroupingEnabled
        ? [{ type: 'Campaign', value: event.origCampaign! }]
        : [];
      const programTypeMeta: CompareMetadata[] =
        viewType === 'monthGridQuarter'
          ? [{ type: 'ProgramType', value: { type: 'ProgramType', value: event.origProgramType } }]
          : [];
      const fakeProgram: CompareMetadata = {
        type: 'Program',
        value: { type: 'ProgramTypeMarkerArtificialProgram' },
      };
      return [...campaignMeta, ...programTypeMeta, fakeProgram];
    }
    case CalendarEventType.task: {
      const campaignMeta: CompareMetadata[] = isCampaignGroupingEnabled
        ? [{ type: 'Campaign', value: event.origResolvedSummary.campaign! }]
        : [];
      const programMeta: CompareMetadata = {
        type: 'Program',
        value: { type: 'Program', value: event.origResolvedSummary },
      };
      const programTypeMeta: CompareMetadata[] =
        viewType === 'monthGridQuarter'
          ? [
              {
                type: 'ProgramType',
                value: { type: 'ProgramType', value: event.origResolvedSummary.type },
              },
            ]
          : [];
      const taskMeta: CompareMetadata = {
        type: 'Task',
        value: event.origTask,
      };
      return [...campaignMeta, ...programTypeMeta, programMeta, taskMeta];
    }
    default:
      throw new Error(`Unexpected calendarEventType ${JSON.stringify(event)}`);
  }
};

export const compareCalendarEvents = (
  viewType: CalendarViewType,
  eventA: EventCompare,
  eventB: EventCompare,
  isCampaignGroupingEnabled: boolean
) => {
  const propsA = eventA.extendedProps as CalendarEventExtendedProps;
  const propsB = eventB.extendedProps as CalendarEventExtendedProps;

  const compareMetaA = buildMetadata(propsA, viewType, isCampaignGroupingEnabled);
  const compareMetaB = buildMetadata(propsB, viewType, isCampaignGroupingEnabled);
  return compareEventsByMetadata(viewType, compareMetaA, compareMetaB);
};

const getDurationMs = (program: ResolvedProgramSummary) =>
  program.programKind === 'PROGRAM'
    ? program.endDateTime.getTime() - program.startDateTime.getTime()
    : program.endRangeDateTime.getTime() - program.startRangeDateTime.getTime();

const sameMonth = (a: Date, b: Date) =>
  moment(a).startOf('month').isSame(moment(b).startOf('month'));

const getMonthsCount = (program: ResolvedProgramSummary) =>
  program.programKind === 'PROGRAM'
    ? calculateMonthsCount(program.endDateTime, program.startDateTime)
    : calculateMonthsCount(program.endRangeDateTime, program.startRangeDateTime);

const calculateMonthsCount = (endDateTime: Date, startDateTime: Date) => {
  const adjustedDateTime =
    moment(endDateTime).valueOf() === moment(endDateTime).startOf('month').valueOf()
      ? moment(endDateTime).subtract(1, 'day')
      : moment(endDateTime);
  return moment(adjustedDateTime)
    .startOf('month')
    .diff(moment(startDateTime).startOf('month').add(1, 'month'), 'months');
};
