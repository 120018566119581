import moment from 'moment';
import _ from 'lodash';
import {
  CustomFieldType,
  CustomFormulaField,
  DONE_PROGRAM_STATUS,
  ExistedCustomFields,
  FormulaScope,
  NoCampaign,
  NoProgramType,
  ProgramKind,
  ProgramStatusOption,
  ProgramSummary,
  ProgramTask,
  ProgramTaskChange,
  ProgramType,
  ResolvedCampaign,
  ResolvedProgramBudgetField,
  ResolvedProgramDatesField,
  ResolvedProgramEndDateField,
  ResolvedProgramFields,
  ResolvedProgramOwnerField,
  ResolvedProgramStartDateField,
  ResolvedProgramStatusField,
  ResolvedProgramSummary,
  ResolvedProgramTask,
  ResolvedProgramTypeField,
  ResolvedTaskAssigneeField,
  ResolvedTaskFields,
  TODO_PROGRAM_STATUS,
  UnknownCampaign,
  UnknownProgramType,
} from './types';
import { NoProgramOwner, UnknownProgramOwner, User } from '../user/types';
import { GroupingDataItem } from '../analytics/types';
import { FetchStatus, QueryStatus } from '@tanstack/react-query';
import { MutableRefObject } from 'react';
import { extractUserName } from '../user/utils';
import { isUnscheduled, unscheduledEnd, unscheduledStart } from './unscheduledProgramUtil';
import { getColumnName, getColumnShortcut } from './programColumns';
import { internalEndDateToDisplay } from '../util/date-utils';
import { nonBreakableSpace } from '../util/utils';

export const calculateStartDate = (wholeDay: boolean) =>
  moment(new Date())
    .startOf(wholeDay ? 'day' : 'minute')
    .toDate();

export const calculateEndDate: (start: Date, wholeDay: boolean | null) => Date = (
  start,
  wholeDay
) => {
  const nextDay = moment(start).startOf('day').add(1, 'day').toDate();

  if (!wholeDay) {
    const endOfWorkingDay = moment(start).startOf('day').add(19, 'hours').toDate();
    if (start.valueOf() < endOfWorkingDay.valueOf()) return endOfWorkingDay;
  }
  return nextDay;
};

export const flatMapUnique = <T>(arrayOfArrays: Array<T[]>, id: (t: T) => string): T[] => {
  const flatten = arrayOfArrays.flatMap((arr) => [...arr]);
  const existed = new Set<string>();
  const result: T[] = new Array<T>();
  flatten.forEach((item) => {
    if (!existed.has(id(item))) {
      existed.add(id(item));
      result.push(item);
    }
  });
  return result;
};

export const extractMonths = (p: ProgramSummary): Date[] => {
  // explicitly calculate both ranges to cover unscheduled program cases
  const programOwnMonths = getMonthsBetween(p.startDateTime, p.endDateTime, p.name);
  const programRangeMonths = getMonthsBetween(p.startRangeDateTime, p.endRangeDateTime, p.name);
  return flatMapUnique([programOwnMonths, programRangeMonths], (d) => d.toISOString()).sort(
    (a, b) => a.valueOf() - b.valueOf()
  );
};

const getMonthsBetween = (start: Date, end: Date, name: string): Date[] => {
  const startDate = moment(start);
  const endDate = moment(end);

  if (endDate.isBefore(startDate)) {
    throw new Error(
      `End date must be greater than start date: name ${name}, start ${start}, end ${end}`
    );
  }
  const startMonth = startDate.startOf('month');
  const result: Date[] = [];
  do {
    result.push(startMonth.toDate());
    startMonth.add(1, 'month');
  } while (startMonth.isBefore(endDate));

  return result;
};

export const ensureSharpAtHex = (hex: string): string => (hex.startsWith('#') ? hex : `#${hex}`);
export const hexToRGB = (hex: string, alpha: number | null): string => {
  if (hex.startsWith('#')) {
    const r = parseInt(hex.slice(1, 3), 16);
    const g = parseInt(hex.slice(3, 5), 16);
    const b = parseInt(hex.slice(5, 7), 16);

    return alpha ? `rgba(${r}, ${g}, ${b}, ${alpha})` : `rgb(${r}, ${g}, ${b})`;
  }
  return hexToRGB(`#${hex}`, alpha);
};

const RGBAtoRGB = (
  r: number,
  g: number,
  b: number,
  a: number,
  bgR: number,
  bgG: number,
  bgB: number
): string => {
  const targetR = Math.round((1 - a) * bgR + a * r);
  const targetG = Math.round((1 - a) * bgG + a * g);
  const targetB = Math.round((1 - a) * bgB + a * b);
  return `rgb(${targetR}, ${targetG}, ${targetB})`;
};

export const hexAToRGB = (hex: string, alpha: number): string => {
  if (hex.startsWith('#')) {
    const r = parseInt(hex.slice(1, 3), 16);
    const g = parseInt(hex.slice(3, 5), 16);
    const b = parseInt(hex.slice(5, 7), 16);
    return RGBAtoRGB(r, g, b, alpha, 255, 255, 255);
  }
  return hexAToRGB(`#${hex}`, alpha);
};

export const extractConsecutiveMonths = (start: Date, end: Date, totalMonths: number): Date[] => {
  const m: Date[] = [];
  for (
    let currentDay = moment(start).startOf('month');
    currentDay <= moment(end).startOf('month');
    currentDay = currentDay.add(1, 'month')
  ) {
    m.push(currentDay.toDate());
  }
  let months: Date[] = _(m)
    .uniqBy((d) => d.getTime())
    .value();

  if (months.length > totalMonths)
    throw new Error(
      `unexpected length of months ${
        months.length
      } exceeded ${totalMonths}: start ${start}, end ${end}, months ${JSON.stringify(months)}`
    );

  if (!months.length)
    throw new Error(`unexpected zero length of months: start ${start}, end ${end}`);

  let remaining: number = totalMonths - months.length;

  while (remaining > 0) {
    if (remaining >= 2) {
      const nextMonth = moment(months[months.length - 1])
        .add(1, 'month')
        .toDate();
      const prevMonth = moment(months[0]).subtract(1, 'month').toDate();
      months = [prevMonth, ...months, nextMonth];
      remaining -= 2;
    } else {
      const prevMonth = moment(months[0]).subtract(1, 'month').toDate();
      months = [prevMonth, ...months];
      remaining -= 1;
    }
  }

  return months;
};

export const getEmptyProgramSummary = (
  typeId: string,
  wholeDay: boolean,
  start: Date | null,
  end: Date | null,
  ownerId: string | null,
  isScheduled: boolean,
  programKind: ProgramKind = 'PROGRAM',
  campaignId?: string | null
): ProgramSummary => {
  const startDateTime = start ?? (isScheduled ? calculateStartDate(wholeDay) : unscheduledStart());
  const endDateTime =
    end ?? (isScheduled ? calculateEndDate(startDateTime, wholeDay) : unscheduledEnd());
  return {
    id: '',
    programKind,
    name: '',
    startDateTime,
    endDateTime,
    startRangeDateTime: startDateTime,
    endRangeDateTime: endDateTime,
    wholeDay,
    budget: 0,
    leads: 0,
    status: TODO_PROGRAM_STATUS.id,
    typeId,
    owningUserId: ownerId,
    customFields: [],
    tasks: [],
    notes: '',
    salesforceCampaignId: null,
    vendor: '',
    actualLeads: null,
    endDateInClientRepresentation: false,
    campaignId: programKind === 'CAMPAIGN' || !campaignId ? null : campaignId,
    campaignColor: programKind === 'CAMPAIGN' ? 'EF242B' : null,
  };
};

export const createProgramCopy = (
  sourceProgram: ProgramSummary,
  copyCampaign: boolean
): ProgramSummary => {
  const id = '';
  const unscheduled = isUnscheduled(sourceProgram);
  const startDateTime = unscheduled ? unscheduledStart(id) : sourceProgram.startDateTime;
  const endDateTime = unscheduled ? unscheduledEnd(id) : sourceProgram.endDateTime;
  const campaignId =
    sourceProgram.campaignId === UnknownCampaign.id ||
    sourceProgram.campaignId === NoCampaign.id ||
    copyCampaign
      ? sourceProgram.campaignId
      : null;

  return {
    ..._.cloneDeep(sourceProgram),
    id,
    name: `(Copy) ${sourceProgram.name}`,
    startDateTime,
    endDateTime,
    campaignId,
    version: undefined,
    tasks: sourceProgram.tasks.map((t) => ({ ...t, id: null })),
  };
};

export const createProgramFromTask = (
  sourceProgram: ProgramSummary,
  sourceTask: ProgramTask,
  currentUserId: string | null,
  copyCampaign: boolean
): ProgramSummary => {
  const id = '';
  const isUnscheduled = !sourceTask.dueDateTime;
  const startDateTime = isUnscheduled ? unscheduledStart(id) : sourceTask.dueDateTime;
  const endDateTime = isUnscheduled
    ? unscheduledEnd(id)
    : moment(sourceTask.dueDateTime).add(1, 'day').toDate();
  const campaignId =
    sourceProgram.campaignId === UnknownCampaign.id ||
    sourceProgram.campaignId === NoCampaign.id ||
    copyCampaign
      ? sourceProgram.campaignId
      : null;

  const emptyProgramSummary = getEmptyProgramSummary(
    sourceProgram.typeId,
    true,
    startDateTime,
    endDateTime,
    sourceTask.owningUserId ?? currentUserId,
    !isUnscheduled,
    'PROGRAM',
    campaignId
  );
  return {
    ...emptyProgramSummary,
    name: sourceTask.name,
    status: sourceTask.status === 'done' ? DONE_PROGRAM_STATUS.id : TODO_PROGRAM_STATUS.id,
  };
};

export const resolveGroupingItemProgramType = (
  groupingItem: GroupingDataItem,
  types: ProgramType[]
): ProgramType => resolveProgramType(groupingItem.key.typeId!, true, types);

export const resolveProgramSummaryProgramType = (
  s: ProgramSummary,
  types: ProgramType[]
): ProgramType => resolveProgramType(s.typeId, s.id.length > 0, types);

export const resolveProgramType = (
  typeId: string,
  existingEntity: boolean,
  types: ProgramType[]
): ProgramType => {
  if (typeId.length) {
    const foundType = types.find((t) => t.id === typeId);
    if (foundType) return { ...foundType };
    return existingEntity ? { ...UnknownProgramType } : { ...NoProgramType };
  }
  return { ...NoProgramType };
};

export const resolveProgramOwner = (
  s: { id: string | null; owningUserId: string | null },
  users: User[]
): User | null => {
  if (s.owningUserId) {
    const foundOwner = users.find((u) => u.id === s.owningUserId);
    if (foundOwner) return { ...foundOwner };
    return s.id ? _.cloneDeep(UnknownProgramOwner) : null;
  }
  return null;
};

export const resolveCampaign = (
  s: { id: string | null; campaignId: string | null },
  campaigns: ResolvedCampaign[]
): ResolvedCampaign | null => {
  if (s.campaignId) {
    const foundCampaign = campaigns.find((c) => c.id === s.campaignId);
    if (foundCampaign) return { ...foundCampaign };
    return _.cloneDeep(UnknownCampaign);
  }
  return null;
};

export const getCommonQueryStatus = (statuses: QueryStatus[]): QueryStatus => {
  if (statuses.find((x) => x === 'error')) return 'error';
  if (statuses.every((x) => x === 'success')) return 'success';
  return 'loading';
};

export const getCommonFetchStatus = (statuses: FetchStatus[]): FetchStatus => {
  if (statuses.find((s) => s === 'fetching')) return 'fetching';
  if (_(statuses).every((s) => s === 'paused')) return 'paused';

  return 'idle';
};

export interface QueryMetadata {
  status: 'loading' | 'success' | 'error';
  fetchStatus: 'fetching' | 'paused' | 'idle';
  error: unknown | null;
  isStale: boolean;
}

export const aggregateQueries = (
  mainQueries: QueryMetadata[],
  hasMissingDependencies: boolean,
  dependencyQueries: {
    metadata: QueryMetadata;
    initiateReload: () => {};
    attemptCounter: MutableRefObject<number>;
  }[]
): QueryMetadata => {
  const commonStatus = getCommonQueryStatus([
    ...mainQueries.map((x) => x.status),
    ...dependencyQueries.map((x) => x.metadata.status),
  ]);
  const commonFetchStatus = getCommonFetchStatus([
    ...mainQueries.map((x) => x.fetchStatus),
    ...dependencyQueries.map((x) => x.metadata.fetchStatus),
  ]);
  const isStale = !!mainQueries.find((x) => x.isStale);
  const result = { status: commonStatus, fetchStatus: commonFetchStatus, error: null, isStale };

  const dependencyWithError = dependencyQueries.find((x) => x.metadata.status === 'error');
  if (dependencyWithError) {
    return { ...result, error: dependencyWithError.metadata.error };
  }

  if (!hasMissingDependencies) {
    for (const dependencyQuery of dependencyQueries) {
      dependencyQuery.attemptCounter.current = 0;
    }
    return result;
  }

  let stillLoading = false;
  for (const dependencyQuery of dependencyQueries) {
    if (dependencyQuery.attemptCounter.current < 2) {
      dependencyQuery.attemptCounter.current += 1;
      dependencyQuery.initiateReload();
      stillLoading = true;
    }
  }
  if (stillLoading) {
    return { ...result, status: 'loading', fetchStatus: 'fetching' };
  }
  return result;
};

export const extractExistedCustomFields = (
  summaries: { customFields: { type: CustomFieldType; name: string }[] }[]
): ExistedCustomFields => {
  const allFields = summaries.flatMap((s) => s.customFields);
  const strings = allFields.filter((f) => f.type === 'text').map(({ name }) => name);
  const numbers = allFields.filter((f) => f.type === 'number').map(({ name }) => name);
  const dates = allFields.filter((f) => f.type === 'date-time').map(({ name }) => name);
  const formulas = allFields.filter((f) => f.type === 'formula').map(({ name }) => name);

  return {
    strings: new Set<string>(strings),
    numbers: new Set<string>(numbers),
    dates: new Set<string>(dates),
    formulas: new Set<string>(formulas),
  };
};

export const areCustomFieldsSame = (a: ExistedCustomFields, b: ExistedCustomFields) =>
  _.isEqual(a.strings, b.strings) &&
  _.isEqual(a.numbers, b.numbers) &&
  _.isEqual(a.dates, b.dates) &&
  _.isEqual(a.formulas, b.formulas);

export const areCustomFieldsNotEmpty = ({
  strings,
  numbers,
  dates,
  formulas,
}: ExistedCustomFields) => !![strings, numbers, dates, formulas].find(({ size }) => size > 0);

export function isNullOrEmpty<T>(array: T[]): boolean {
  return array === null || array.length === 0;
}

const BackendReservedProgramFields: readonly string[] = [
  'objectType',
  'parentId',
  'campaignId',
  'programId',
  'taskId',
  'name',
  'type',
  'campaignColor',
  'startDateTime',
  'endDateTime',
  'wholeDay',
  'budget',
  'projectedLeads',
  'vendor',
  'notes',
  'status',
  'ownerUsername',
  'salesforceCampaignId',
  'actualLeads',
];

export const getCustomFieldNamesSum = (names: string[]): FormulaScope =>
  [
    'Actual Leads',
    'Projected Leads',
    ...BackendReservedProgramFields,
    ...names,
  ].reduce<FormulaScope>((prev, curr) => {
    const fieldName = curr.trim().toLowerCase();
    const next = { ...prev };
    next[fieldName] = prev[fieldName] === undefined ? 1 : prev[fieldName] + 1;
    return next;
  }, {});

export const getCampaignName = (u: ResolvedCampaign, truncateLength?: number): string => {
  const campaignName = u.name;
  if (truncateLength) {
    return campaignName.length > truncateLength
      ? `${campaignName.slice(0, truncateLength - 3)}...`
      : campaignName;
  }
  return campaignName;
};

export const usdFormatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
  maximumFractionDigits: 0,
  minimumFractionDigits: 0,
});

export const getProgramNameWithTasksCount = (
  programName: string,
  resolvedTasks: ResolvedProgramTask[]
): string => {
  if (resolvedTasks.length) {
    const total = resolvedTasks.length;
    const done = resolvedTasks.filter((t) => t.status === 'done').length;
    return `${programName} · ${done}/${total}`;
  }
  return programName;
};

type CommonFields = (typeof ResolvedProgramFields)[number];
type TaskFields = (typeof ResolvedTaskFields)[number];

const getProgramSummaryValue = (programSummary: ResolvedProgramSummary, col: string) => {
  const customFieldTypeExcludingFormula = col.match(/-(text|number|date-time)$/)?.[1];
  if (customFieldTypeExcludingFormula)
    return programSummary.customFields.find(
      (field) =>
        field.type === customFieldTypeExcludingFormula &&
        `${field.name}-${customFieldTypeExcludingFormula}` === col
    )?.value;

  const customFieldTypeFormula = col.match(/-(formula)$/)?.[1];
  if (customFieldTypeFormula && customFieldTypeFormula === 'formula') {
    return (
      programSummary.customFields.find(
        (field) =>
          field.type === customFieldTypeFormula && `${field.name}-${customFieldTypeFormula}` === col
      ) as CustomFormulaField | undefined
    )?.result;
  }

  return programSummary[col as CommonFields];
};

const getProgramTaskValue = (task: ResolvedProgramTask, field: string) => task[field as TaskFields];

const formatDateValue = (value: Date, wholeDay: boolean) =>
  moment(value).format(wholeDay ? 'MMM DD' : 'MMM DD, h:mm a');
const formatTimeValue = (value: Date, wholeDay: boolean) =>
  moment(value).format(wholeDay ? '' : 'h:mm a');

const formatValue = (programSummary: ResolvedProgramSummary, col: string) => {
  if (col === ResolvedProgramDatesField) {
    if (isUnscheduled(programSummary)) return 'Unscheduled';
    const start = getProgramSummaryValue(programSummary, ResolvedProgramStartDateField) as Date;
    const startText = formatDateValue(start, programSummary.wholeDay);
    const end = internalEndDateToDisplay(
      getProgramSummaryValue(programSummary, ResolvedProgramEndDateField) as Date,
      programSummary.wholeDay
    );
    return start.valueOf() !== end.valueOf()
      ? moment(start).startOf('day').valueOf() !== moment(end).startOf('day').valueOf()
        ? `${startText}–${formatDateValue(end, programSummary.wholeDay)}`
        : `${startText}–${formatTimeValue(end, programSummary.wholeDay)}`
      : startText;
  }
  const value = getProgramSummaryValue(programSummary, col);
  if (col === ResolvedProgramTypeField) return (value as ProgramType).name;
  if (col === ResolvedProgramOwnerField) return (value as User | null)?.email;
  if (col === ResolvedProgramBudgetField) return usdFormatter.format(value as number);
  if (col === ResolvedProgramStatusField) return (value as ProgramStatusOption).name;
  if (col === ResolvedProgramEndDateField)
    return formatDateValue(
      internalEndDateToDisplay(value as Date, programSummary.wholeDay),
      programSummary.wholeDay
    );
  if (value instanceof Date) {
    return formatDateValue(value, programSummary.wholeDay);
  }
  return value?.toString() ?? nonBreakableSpace;
};

export const renderProgramSummaryValue = (
  programSummary: ResolvedProgramSummary,
  col: string,
  shortcutOnly: boolean
) => {
  const stringValue = formatValue(programSummary, col);
  const shortcut = getColumnShortcut(col);
  const prefix = shortcutOnly ? (shortcut ? `${shortcut}: ` : '') : `${getColumnName(col)}: `;
  return prefix ? `${prefix}${nonBreakableSpace}${stringValue}` : stringValue;
};

export const renderTaskValue = (task: ResolvedProgramTask, col: string) => {
  const value = getProgramTaskValue(task, col);
  if (col === ResolvedTaskAssigneeField) {
    const user = value as User | null;
    if (!user) return null;
    return extractUserName(user);
  }
  if (value instanceof Date) return moment(value).format('MMM DD');
  return value?.toString();
};

export const ProgramTableCellWidth = 150;
export const SelectCellWidth = 160;
export const ProgramTableNameCellWidth = 284;
export const TaskTableStatusCellWidth = 32;
export const TaskTableNameCellWidth =
  ProgramTableNameCellWidth - TaskTableStatusCellWidth + ProgramTableCellWidth;
export const MaxTaskTableNameCellWidth = 500;
export const TaskTableDueDateCellWidth = 242;
export const TaskTableOwnerCellWidth = 160;

export const ProgramsTableLocalStorageKey = 'ProgramsTable/';
export const CalendarLocalStorageKey = 'ProgramCalendar/';
export const AnalyticsLocalStorageKey = 'Analytics/';
export const AiVariablesLocalStorageKey = 'AiVariables/';

export const unresolveProgramSummary = (summary: ResolvedProgramSummary): ProgramSummary => {
  const ownerId = summary.owner ? summary.owner.id : null;
  return {
    ...summary,
    typeId: summary.type.id,
    owningUserId: ownerId === NoProgramOwner.id ? null : ownerId,
    campaignId:
      summary.campaign && summary.campaign.id !== NoCampaign.id ? summary.campaign.id : null,
    status: summary.status.id,
    tasks: [...summary.resolvedTasks],
  };
};

export const ModalDropdownMenuZIndex = 3;

export const getProgramPatchMessage = (
  programKind: ProgramKind,
  programChanged: boolean,
  taskChanges: ProgramTaskChange[] | null
) => {
  let message: string;
  switch (programKind) {
    case 'CAMPAIGN':
      message = 'Campaign is updated.';
      break;
    case 'PROGRAM':
      if (taskChanges && taskChanges.length > 0) {
        if (programChanged) {
          message = 'Program and its tasks are updated.';
        } else if (taskChanges.length === 1) {
          switch (taskChanges[0].changeType) {
            case 'DELETE':
              message = 'Task is deleted.';
              break;
            case 'CREATE':
              message = 'Task is created.';
              break;
            case 'UPDATE':
              message = 'Task is updated.';
              break;
          }
        } else {
          message = 'Program is updated.';
        }
      } else {
        message = 'Program is updated.';
      }
      break;
  }
  return message;
};
