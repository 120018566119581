import { BillingHeaderSections } from '../BillingHeader';
import { ConfirmModal } from '../../../commons/components/ConfirmModal';
import React, { useEffect, useState } from 'react';
import { getBillingPlan } from '../../billingPlans';
import { useFetchedBillingCustomer } from '../../hooks/useFetchedBillingCustomer';
import { useFetchedBillingInvoices } from '../../hooks/useFetchedBillingInvoices';
import moment from 'moment';
import { InvoiceTable } from '../InvoiceTable';
import { useNotification } from '../../../notification/notificationsContext';
import { useDownloadAllProgramsToCsvMutation } from '../../../program/hooks/useDownloadAllProgramsToCsvMutation';
import { useMemoCompare } from '../../../hooks/useMemoCompare';
import _ from 'lodash';
import { INVOICE_TYPE_ISSUED } from '../../types';
import { GrowegyCheckbox } from '../../../program/components/GrowegyCheckbox';
import { isInvoiceObligatory, isInvoicePaid } from '../../utils';

export const InitialStep = (props: { onNext: () => void; onCancel: () => void }) => {
  const { onNext, onCancel } = props;

  const { notifySuccess, notifyError } = useNotification();

  const {
    query: { data: customer },
  } = useFetchedBillingCustomer({ refetchOnWindowFocus: 'always' });
  const {
    query: { data: invoices, status: invoicesStatus },
    queryStatusComponent: invoicesStatusComponent,
  } = useFetchedBillingInvoices({ refetchOnWindowFocus: 'always' });

  const { mutate: downloadToCSV } = useDownloadAllProgramsToCsvMutation(
    (err) => notifyError({ err, notificationMsg: 'Failed to download programs to CSV.' }),
    () => notifySuccess({ notificationMsg: 'Programs has been successfully downloaded to CSV.' })
  );

  const billingPlan = customer?.planName ? getBillingPlan(customer.planName) : null;

  const [dueInvoices, setDueInvoices] = useState(
    invoicesStatus === 'success' ? invoices?.filter(isInvoiceObligatory) ?? null : null
  );
  const [acknowledgement, setAcknowledgement] = useState(false);
  const memoizedInvoices = useMemoCompare(invoices, (a, b) => _.isEqual(a, b));

  useEffect(() => {
    if (!memoizedInvoices) return;
    const newDueInvoices = memoizedInvoices.filter(
      (x) =>
        isInvoiceObligatory(x) ||
        (x.type === INVOICE_TYPE_ISSUED &&
          !!dueInvoices &&
          dueInvoices.some((y) => y.type === INVOICE_TYPE_ISSUED && y.id === x.id))
    );
    if (_.isEqual(newDueInvoices, dueInvoices)) return;
    setDueInvoices(newDueInvoices);
  }, [memoizedInvoices, invoicesStatus, dueInvoices]);

  if (
    !customer ||
    !memoizedInvoices ||
    !customer.activeSubscription ||
    !customer.activeSubscription.futureCancellationDate
  )
    return null;

  const immediateTermination = customer.activeSubscription.futureCancellationDate <= new Date();
  const terminationDateString = moment(customer.activeSubscription.futureCancellationDate).format(
    'MMMM D, YYYY'
  );
  const hasDueInvoices = !!dueInvoices && dueInvoices.length > 0;
  return (
    <ConfirmModal
      title="Cancel your plan?"
      ariaHideApp={false}
      shouldCloseOnOverlayClick={false}
      shouldCloseOnEsc={true}
      footer={{
        type: 'show',
        applyButton: {
          label: 'Continue cancellation',
          variant: 'primary',
          disabled:
            !dueInvoices ||
            (!immediateTermination &&
              dueInvoices.some((x) => !isInvoicePaid(x)) &&
              !acknowledgement),
          onClick: onNext,
        },
        cancelButton: {
          label: 'Dismiss',
          onClick: onCancel,
        },
      }}
    >
      <div className="align-self-start">
        <BillingHeaderSections
          sections={[
            {
              column1: { top: 'Current plan', bottom: billingPlan?.displayName ?? 'unknown' },
              column2: { top: 'Price', bottom: billingPlan?.scheduleDescription ?? 'unknown' },
            },
            {
              column1: {
                top: 'Service termination',
                bottom: immediateTermination ? 'Immediate' : terminationDateString,
                isBottomRed: immediateTermination,
              },
            },
          ]}
        />
      </div>
      <div className="cancellation-intro__description">
        {immediateTermination ? (
          <>
            <span data-test="cancellation-intro__description">
              If you proceed with cancellation, your account will be closed immediately and all your
              programs will be permanently deleted. Your current invoice will be voided.
            </span>
            <span data-test="cancellation-intro__description">
              <span>To export your current programs, </span>
              <a
                href={window.location.href}
                data-test="cancellation-intro__download-programs"
                onClick={(e) => {
                  e.preventDefault();
                  downloadToCSV();
                }}
              >
                download them to CSV now.
              </a>
            </span>
          </>
        ) : (
          <>
            <span data-test="cancellation-intro__description">
              {!hasDueInvoices ? 'Your account is paid in full. ' : ''}If you proceed with
              cancellation, your account will still be available until the end of your billing
              period on {terminationDateString}.
            </span>
            <span data-test="cancellation-intro__description">
              To export your current programs,{' '}
              <a
                href={window.location.href}
                data-test="cancellation-intro__download-programs"
                onClick={(e) => {
                  e.preventDefault();
                  downloadToCSV();
                }}
              >
                download them now
              </a>{' '}
              or go to Table -&gt; More options (···) -&gt; Download programs to CSV.
            </span>
            {hasDueInvoices && (
              <span data-test="cancellation-intro__description">
                Please ensure all outstanding invoices are paid promptly to avoid any negative
                impact on your credit score.
              </span>
            )}
            {dueInvoices
              ? hasDueInvoices && (
                  <>
                    <InvoiceTable
                      invoices={dueInvoices}
                      header="Outstanding invoices"
                      context="cancellation-invoices"
                      reverseOrder={true}
                    />
                    {dueInvoices.some((x) => !isInvoicePaid(x)) && (
                      <div className="cancellation-intro__acknowledgement-container">
                        <GrowegyCheckbox
                          defaultChecked={acknowledgement}
                          text="I understand that cancelling my account does not cancel these outstanding invoices, these amounts are still due."
                          checkedClassName={
                            acknowledgement
                              ? 'cancellation-intro__acknowledgement--checked'
                              : undefined
                          }
                          dataTestPrefix="cancellation-intro__acknowledgement-checkbox"
                          onChange={(e) => setAcknowledgement(e.target.checked)}
                        />
                      </div>
                    )}
                  </>
                )
              : invoicesStatusComponent}
          </>
        )}
      </div>
    </ConfirmModal>
  );
};
