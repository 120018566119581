import { AxiosInstance } from 'axios';

export type MetadataDto = {
  status: string;
};

export type MetaApi = ReturnType<typeof getMetaApi>;

export const getMetaApi = (axiosInstance: AxiosInstance) => {
  const getPing = async () => {
    const response = await axiosInstance.get<MetadataDto>('ping');
    return response.data;
  };

  return {
    getPing,
  };
};
