import { Invoice, INVOICE_TYPE_FUTURE, INVOICE_TYPE_ISSUED, InvoiceAndBenefits } from '../types';
import { PayNowButton } from './PayNowButton';
import { getInvoiceRenderInfo, isInvoicePaid, isInvoicePastDue, isInvoicePayable } from '../utils';
import { StyleVariables } from '../../commons/styleConstants';
import { usdFormatter } from '../../program/util';
import clsx from 'clsx';
import { ForwardedRef, forwardRef, Fragment, useEffect, useRef, useState } from 'react';
import moment from 'moment';

const TopSegment = (props: {
  title: string;
  invoice: Invoice | null;
  price: number | null;
  referenceInvoice: Invoice | null;
}) => {
  const { title, invoice, price, referenceInvoice } = props;
  const effectiveInvoice = invoice ?? referenceInvoice;
  return (
    <div className="timeline-top__segment-container" data-test="timeline-top__segment-container">
      <div
        className={clsx('timeline-top__title', {
          'timeline-top__title--past': effectiveInvoice && isInvoicePaid(effectiveInvoice),
        })}
        data-test="timeline-top__title"
      >
        {title}
      </div>
      {invoice &&
        invoice.type === INVOICE_TYPE_ISSUED &&
        (invoice.status === 'OPEN' || invoice?.status === 'UNCOLLECTIBLE') &&
        (isInvoicePastDue(invoice) ? (
          <div className="timeline-top__past-due-amount" data-test="timeline-top__amount">
            {usdFormatter.format(invoice.amountDue)} past due
          </div>
        ) : (
          <div className="timeline-top__due-amount" data-test="timeline-top__amount">
            {usdFormatter.format(invoice.amountDue)} due
          </div>
        ))}
      {invoice && invoice.type === INVOICE_TYPE_ISSUED && invoice.status === 'PAID' && (
        <div className="timeline-top__paid-amount" data-test="timeline-top__amount">
          {usdFormatter.format(invoice.amountDue)} paid
        </div>
      )}
      {(!invoice || invoice.type === INVOICE_TYPE_FUTURE) && (
        <div
          className={clsx({
            'timeline-top__settled-amount':
              price === null &&
              referenceInvoice?.type === INVOICE_TYPE_ISSUED &&
              referenceInvoice.status === 'PAID',
          })}
          data-test="timeline-top__amount"
        >
          {price
            ? usdFormatter.format(price)
            : invoice
            ? usdFormatter.format(invoice.amountDue)
            : 'Included'}
        </div>
      )}
      {invoice && invoice.type === INVOICE_TYPE_ISSUED && isInvoicePayable(invoice) && (
        <PayNowButton
          invoice={invoice}
          smallSize={true}
          source="timeline"
          dataTest="timeline-top__pay-now"
        />
      )}
      {invoice && invoice.type === INVOICE_TYPE_FUTURE && (
        <div
          className="timeline-top__future-invoice-date"
          data-test="timeline-top__future-invoice-date"
        >
          Opens on {moment(invoice.periodStart).format('MMM D')}
        </div>
      )}
    </div>
  );
};

const TopLine = (props: { segmentsPerYear: number; invoicesAndBenefits: InvoiceAndBenefits[] }) => {
  const { segmentsPerYear, invoicesAndBenefits } = props;
  return (
    <div className="timeline-top__container">
      {invoicesAndBenefits.map(({ invoice, referenceInvoice }, index) => {
        const year = Math.floor(index / segmentsPerYear) + 1;
        const monthNumber = (index % segmentsPerYear) + 1;
        const title =
          year === 1
            ? monthNumber !== segmentsPerYear || segmentsPerYear >= 12
              ? `Month ${monthNumber}`
              : `Months ${monthNumber}–12`
            : monthNumber !== segmentsPerYear || segmentsPerYear >= 12
            ? `Year ${year} month ${monthNumber}`
            : `Year ${year} months ${monthNumber}–12`;
        return (
          <TopSegment
            key={index}
            title={title}
            invoice={typeof invoice === 'object' ? invoice : null}
            price={typeof invoice === 'number' ? invoice : null}
            referenceInvoice={referenceInvoice}
          />
        );
      })}
    </div>
  );
};

const MiddleLineSegmentTick = (props: { color: string }) => {
  const { color } = props;
  return (
    <div
      className="timeline-middle-line__segment-tick"
      data-test="timeline-middle-line__segment-tick"
      style={{ backgroundColor: color }}
    />
  );
};

const MiddleLineSegmentBody = (props: { color: string; dashed: boolean }) => {
  const { color, dashed } = props;
  return (
    <div
      className="timeline-middle-line__segment-body"
      data-test="timeline-middle-line__segment-body"
      style={{
        background: dashed
          ? `repeating-linear-gradient(to right, transparent 0 8px, ${color} 0 16px)`
          : color,
      }}
    />
  );
};

const MiddleLineSegment = forwardRef(
  (
    props: {
      isFirst: boolean;
      isLastDashed: boolean;
      invoice: Invoice | number | null;
      referenceInvoice: Invoice | null;
      hasPastDueInvoices: boolean;
    },
    ref: ForwardedRef<HTMLDivElement>
  ) => {
    const { isFirst, isLastDashed, invoice, referenceInvoice, hasPastDueInvoices } = props;
    const color =
      isLastDashed ||
      typeof invoice === 'number' ||
      invoice?.type === INVOICE_TYPE_FUTURE ||
      referenceInvoice?.type === INVOICE_TYPE_FUTURE ||
      (!invoice && !referenceInvoice)
        ? StyleVariables.neutral200
        : hasPastDueInvoices
        ? StyleVariables.red500
        : invoice !== null
        ? getInvoiceRenderInfo(invoice).lineColor
        : referenceInvoice !== null
        ? getInvoiceRenderInfo(referenceInvoice).lineColor
        : StyleVariables.neutral200;
    return (
      <div
        ref={ref}
        className={clsx('timeline-middle-line__segment', { 'align-self-start': isLastDashed })}
        data-test="timeline-middle-line__segment"
      >
        {isFirst && <MiddleLineSegmentTick color={color} />}
        <MiddleLineSegmentBody color={color} dashed={isLastDashed} />
        {!isLastDashed && <MiddleLineSegmentTick color={color} />}
      </div>
    );
  }
);

const MiddleLine = (props: {
  invoicesAndBenefits: InvoiceAndBenefits[];
  historicInvoices: Invoice[];
}) => {
  const { invoicesAndBenefits, historicInvoices } = props;
  const scrollToDivRef = useRef<HTMLDivElement | null>(null);
  const [scrollToIndex] = useState(
    invoicesAndBenefits
      .map((x) => x.invoice)
      .findIndex(
        (x, i) =>
          i === invoicesAndBenefits.length - 1 ||
          typeof x === 'number' ||
          (x?.type === INVOICE_TYPE_ISSUED && (x.status === 'OPEN' || x.status === 'UNCOLLECTIBLE'))
      )
  );
  useEffect(() => {
    scrollToDivRef.current?.scrollIntoView({
      behavior: 'auto',
      block: 'nearest',
      inline: 'center',
    });
  }, []);

  let hasPastDueInvoices = historicInvoices.some((x) => isInvoicePastDue(x));
  return (
    <div className="timeline-middle__container">
      {invoicesAndBenefits.map(({ invoice, referenceInvoice }, index) => {
        if (
          !hasPastDueInvoices &&
          invoice &&
          typeof invoice === 'object' &&
          isInvoicePastDue(invoice)
        )
          hasPastDueInvoices = true;
        return (
          <MiddleLineSegment
            key={index}
            isFirst={index === 0}
            isLastDashed={false}
            invoice={invoice}
            referenceInvoice={referenceInvoice}
            ref={index === scrollToIndex ? scrollToDivRef : undefined}
            hasPastDueInvoices={hasPastDueInvoices}
          />
        );
      })}

      <MiddleLineSegment
        isFirst={false}
        isLastDashed={true}
        invoice={null}
        referenceInvoice={null}
        hasPastDueInvoices={hasPastDueInvoices}
      />
    </div>
  );
};

const BottomSegment = (props: {
  invoice: Invoice | null;
  referenceInvoice: Invoice | null;
  regularBenefits: string[];
  specialBenefits: string[];
  hasDueInvoices: boolean;
}) => {
  const { invoice, referenceInvoice, regularBenefits, specialBenefits, hasDueInvoices } = props;
  const effectiveInvoice = invoice ?? referenceInvoice;
  const invoicePaid =
    effectiveInvoice?.type === INVOICE_TYPE_ISSUED &&
    effectiveInvoice.status === 'PAID' &&
    !hasDueInvoices;
  return (
    <div
      className="timeline-bottom__segment-container"
      data-test="timeline-bottom__segment-container"
    >
      <div
        className={clsx('timeline-bottom__regular-benefits-container', {
          'timeline-bottom__regular-benefits-container--past': invoicePaid,
        })}
      >
        {regularBenefits.map((x, i) => (
          <Fragment key={`reg-benefit-container-${i}`}>
            <div key={`reg-benefit-${i}`} data-test="timeline-bottom__regular-benefit">
              {x}
            </div>
            {i < regularBenefits.length - 1 || specialBenefits.length > 0 ? (
              <div
                key={`reg-benefit-plus-${i}`}
                className={clsx('timeline-bottom__regular-benefit-plus', {
                  'timeline-bottom__regular-benefit-plus--past': invoicePaid,
                })}
              >
                +
              </div>
            ) : null}
          </Fragment>
        ))}
      </div>
      <div className="timeline-bottom__special-benefits-container">
        {specialBenefits.map((x, i) => (
          <div
            key={i}
            className={clsx('timeline-bottom__special-benefit-container', {
              'timeline-bottom__special-benefit-container--available':
                invoicePaid && !hasDueInvoices,
            })}
            data-test="timeline-bottom__special-benefit-container"
          >
            {x}
          </div>
        ))}
      </div>
    </div>
  );
};

const BottomLine = (props: {
  invoicesAndBenefits: InvoiceAndBenefits[];
  historicInvoices: Invoice[];
}) => {
  const { invoicesAndBenefits, historicInvoices } = props;
  let hasDueInvoices = historicInvoices.some(isInvoicePayable);
  return (
    <div className="timeline-bottom__container">
      {invoicesAndBenefits.map(
        ({ invoice, referenceInvoice, regularBenefits, specialBenefits }, index) => {
          if (typeof invoice === 'object' && invoice && isInvoicePayable(invoice))
            hasDueInvoices = true;
          return (
            <BottomSegment
              key={index}
              invoice={
                typeof invoice === 'object' && invoice?.type === INVOICE_TYPE_ISSUED
                  ? invoice
                  : null
              }
              referenceInvoice={referenceInvoice}
              regularBenefits={regularBenefits}
              specialBenefits={specialBenefits}
              hasDueInvoices={hasDueInvoices}
            />
          );
        }
      )}
    </div>
  );
};

export const Timeline = (props: {
  segmentsPerYear: number;
  invoicesAndBenefits: InvoiceAndBenefits[];
  historicInvoices: Invoice[];
}) => {
  const { segmentsPerYear, invoicesAndBenefits, historicInvoices } = props;
  return (
    <div className="timeline__container">
      <TopLine segmentsPerYear={segmentsPerYear} invoicesAndBenefits={invoicesAndBenefits} />
      <MiddleLine invoicesAndBenefits={invoicesAndBenefits} historicInvoices={historicInvoices} />
      <BottomLine invoicesAndBenefits={invoicesAndBenefits} historicInvoices={historicInvoices} />
    </div>
  );
};
