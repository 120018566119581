import { useState } from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import { useMutation, useQueryClient } from '@tanstack/react-query';

import useApi from '../../api/backendApiContext';
import { ProgramType, ProgramTypes } from '../../program/types';
import { ProgramTypeEdit } from '../components/ProgramTypeEdit';
import { ProgramTypesCacheKey } from '../../hooks/useQueryCacheManager';
import { useNotification } from '../../notification/notificationsContext';
import useAnalyticsPage from '../../web-analytics/hooks/useAnalyticsPage';
import { Page } from '../../web-analytics/Page';
import useAnalytics from '../../web-analytics/webAnalyticsContext';
import { analyticsTrack } from '../../web-analytics/webAnalytics';
import { AnalyticsEvent } from '../../web-analytics/AnalyticsEvent';

export type ProgramEditModalState = {
  programType: ProgramType;
  programTypes: ProgramTypes;
  action: 'UPDATE' | 'CREATE' | 'DELETE';
};

const NewItemId = 'new-program-type-id';
type ProgramTypeDialogProps = ProgramEditModalState & { onHide: () => void };

const getPage = (action: 'UPDATE' | 'CREATE' | 'DELETE'): Page => {
  switch (action) {
    case 'CREATE':
      return Page.PROGRAM_TYPE_CREATE;
    case 'DELETE':
      return Page.PROGRAM_TYPE_DELETE;
    case 'UPDATE':
    default:
      return Page.PROGRAM_TYPE_UPDATE;
  }
};

export const ProgramTypeDialog = (props: ProgramTypeDialogProps) => {
  const { programApi } = useApi();
  const queryClient = useQueryClient();
  const { notifySuccess, notifyError } = useNotification();

  const { action, programType, programTypes, onHide } = props;
  useAnalyticsPage(getPage(action));
  const analytics = useAnalytics();

  const [showModal, setShowModal] = useState(true);

  const {
    mutateAsync: createMutation,
    isLoading: createInProgress,
    error: createError,
  } = useMutation(
    async (change: ProgramType) => {
      if (!change.id || change.id === NewItemId) {
        const update = {
          version: programTypes.version,
          types: [...programTypes.types, { name: change.name, color: change.color }],
        };
        await programApi.putProgramTypes(update);
      }
    },
    {
      onSettled: async () => queryClient.invalidateQueries(ProgramTypesCacheKey),
    }
  );

  const {
    mutateAsync: updateMutation,
    isLoading: updateInProgress,
    error: updateError,
  } = useMutation(
    async (change: ProgramType) => {
      if (change.id && change.id !== NewItemId) {
        const update = {
          version: programTypes.version,
          types: [...programTypes.types.filter((t) => t.id !== change.id), change],
        };
        await programApi.putProgramTypes(update);
      }
    },
    {
      onSettled: async () => queryClient.invalidateQueries(ProgramTypesCacheKey),
    }
  );

  const {
    mutateAsync: deleteMutation,
    isLoading: deleteInProgress,
    error: deleteError,
  } = useMutation(
    async (change: ProgramType) => {
      if (change.id && change.id !== NewItemId) {
        const update = {
          version: programTypes.version,
          types: [...programTypes.types.filter((t) => t.id !== change.id)],
        };
        await programApi.putProgramTypes(update);
      }
    },
    {
      onSuccess: async () => queryClient.invalidateQueries(ProgramTypesCacheKey),
    }
  );

  const disabled = updateInProgress || deleteInProgress || createInProgress;

  const handleHideClick = () => {
    setShowModal(false);
    onHide();
  };

  const applyPromise = async (change: ProgramType): Promise<void> => {
    switch (action) {
      case 'UPDATE': {
        await updateMutation(change);
        analyticsTrack(analytics, AnalyticsEvent.PROGRAM_TYPE_UPDATED);
        return;
      }
      case 'CREATE': {
        await createMutation(change);
        analyticsTrack(analytics, AnalyticsEvent.PROGRAM_TYPE_CREATED);
      }
    }
  };

  const handleApplyClick: (change: ProgramType) => Promise<void> = async (change: ProgramType) => {
    try {
      await applyPromise(change);
      setShowModal(false);
      onHide();
      notifySuccess({
        notificationMsg: `Program type ${action.toLowerCase()}d`,
        logMsg: `Program type ${change.name} ${action.toLowerCase()}d`,
      });
    } catch (err) {
      notifyError({
        notificationMsg: `Failed to ${action.toLowerCase()} program type`,
        logMsg: `Failed to ${action.toLowerCase()} program type ${change.name}`,
        err,
      });
    }
  };

  const handleDeleteClick: () => Promise<void> = async () => {
    try {
      await deleteMutation(programType);
      analyticsTrack(analytics, AnalyticsEvent.PROGRAM_TYPE_DELETED);

      notifySuccess({
        notificationMsg: `Program type ${programType.name} is deleted`,
        logMsg: `Program type ${programType.name} is deleted`,
      });
      setShowModal(false);
      onHide();
    } catch (err) {
      notifyError({
        err,
        notificationMsg: 'Failed to delete program type',
        logMsg: '',
      });
    }
  };

  return (
    <ProgramTypeEdit
      showModal={showModal}
      disabled={disabled}
      existedTypes={programTypes}
      initialValues={{ ...programType }}
      createError={createError}
      updateError={updateError}
      deleteError={deleteError}
      handleHideClick={handleHideClick}
      handleApplyClick={handleApplyClick}
      handleDeleteClick={handleDeleteClick}
      action={action}
    />
  );
};
