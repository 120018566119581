import useApi from '../../api/backendApiContext';
import { useQueryCacheManager } from '../../hooks/useQueryCacheManager';
import { GroupByFieldName, DataSeriesName } from '../types';
import { useQuery } from '@tanstack/react-query';
import { ReactQueryConstants } from '../../commons/constants';
import { ProgramMultiFiltersDto } from '../analyticsApi';

export const useFetchedGroupingItems = (props: {
  fromDate: number;
  toDate: number;
  groupBy: GroupByFieldName[];
  groupOf: DataSeriesName[];
  filter: ProgramMultiFiltersDto | null;
}) => {
  const { fromDate, toDate, groupBy, groupOf, filter } = props;
  const { analyticsApi } = useApi();
  return useQuery(
    useQueryCacheManager().getGroupingCacheName(fromDate, toDate, groupBy, groupOf, filter),
    async () => analyticsApi.getGrouping(fromDate, toDate, groupBy, groupOf, filter),
    {
      staleTime: ReactQueryConstants.analyticsGroupingStaleTime,
      refetchOnWindowFocus: false,
    }
  );
};
