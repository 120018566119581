import React from 'react';
import { toast, ToastContainer } from 'react-toastify';
import { errorToString } from '../util/utils';
import { notificationAutoCloseMillis, notificationToastsLimit } from '../config';

type NotificationContextProps = {
  notifyError: (param: { err?: unknown; logMsg?: string; notificationMsg?: string }) => void;
  notifySuccess: (param: { logMsg?: string; notificationMsg?: string }) => void;
  notifyWarn: (param: { err?: unknown; logMsg?: string; notificationMsg?: string }) => void;
  notifyInfo: (param: { logMsg?: string; notificationMsg?: string }) => void;
};

const ToastContent = (props: { value: string; header: string }) => (
  <div>
    <div className="notification__toast-header" data-test="notification__toast-header">
      {props.header}
    </div>
    <div className="notification__toast-msg" data-test="notification__toast-msg">
      {props.value}
    </div>
  </div>
);

const notify = (
  param: { err?: unknown; logMsg?: string; notificationMsg?: string },
  logFunc: (msg: string) => void,
  toastHeader: string,
  toastCssClass: string
) => {
  const { err, logMsg, notificationMsg } = param;
  if (logMsg || notificationMsg) {
    const errMsg = err ? `: ${errorToString(err)}` : '';
    logFunc(`NotificationContext. ${logMsg ?? notificationMsg}${errMsg}`);
  }
  if (notificationMsg) {
    toast(<ToastContent value={notificationMsg} header={toastHeader} />, {
      className: toastCssClass,
    });
  }
};

const notifyError = (param: { err?: unknown; logMsg?: string; notificationMsg?: string }) =>
  notify(param, (s) => logger.error(s), 'Error', 'notification__toast--error');

const notifySuccess = (param: { logMsg?: string; notificationMsg?: string }) =>
  notify(param, (s) => logger.info(s), 'Success', 'notification__toast--success');

const notifyWarn = (param: { logMsg?: string; notificationMsg?: string }) =>
  notify(param, (s) => logger.warn(s), 'Alert', 'notification__toast--warn');

const notifyInfo = (param: { logMsg?: string; notificationMsg?: string }) =>
  notify(param, (s) => logger.info(s), 'News', 'notification__toast--info');

const NotificationContext = React.createContext<NotificationContextProps>({
  notifyError: () => {},
  notifySuccess: () => {},
  notifyWarn: () => {},
  notifyInfo: () => {},
});

export const NotificationProvider = (props: React.PropsWithChildren<{}>) => (
  <>
    <ToastContainer
      pauseOnFocusLoss={true}
      limit={notificationToastsLimit}
      autoClose={notificationAutoCloseMillis}
      position="bottom-left"
    />
    <NotificationContext.Provider
      value={{ notifyError, notifySuccess, notifyWarn, notifyInfo }}
      {...props}
    />
  </>
);

export const useNotification = (): NotificationContextProps => {
  const context = React.useContext(NotificationContext);
  if (!context) {
    throw new Error(`useNotification must be used within an NotificationContext`);
  }
  return context;
};
