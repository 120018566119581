import { useEffect, useRef, useState } from 'react';

export const useTextAreaWithAutoHeightStateRef = ({
  borderHeight,
  origValue,
}: {
  borderHeight: number;
  origValue: string;
}) => {
  const [value, setValue] = useState<string>(origValue);
  const textAreaRef = useRef<HTMLTextAreaElement>(null);

  useEffect(() => {
    if (textAreaRef.current) {
      textAreaRef.current.style.height = '0px';
      const { scrollHeight } = textAreaRef.current;
      textAreaRef.current.style.height = `${scrollHeight + borderHeight}px`;
    }
  }, [textAreaRef, value, borderHeight]);

  useEffect(() => {
    setValue(origValue);
  }, [origValue]);

  return { value, setValue, textAreaRef };
};
