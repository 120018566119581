import React, { useMemo } from 'react';
import { Column, Row, useFlexLayout, useTable } from 'react-table';
import { ProgramAttachment } from '../../types';
import { AttachmentMenuCell } from './AttachmentMenuCell';
import { AttachmentArrayHelpers, CellProps } from './CellProps';
import { FileTypeIcon } from './FileTypeIcon';
import { StyleVariables } from '../../../commons/styleConstants';
import {
  TaskTableDueDateCellWidth,
  TaskTableNameCellWidth,
  TaskTableOwnerCellWidth,
} from '../../util';
import { getFileExtension } from '../../../util/file-utils';

const AttachmentIconCell = ({ value }: CellProps<ProgramAttachment, string>) => (
  <div className="attachments-table__cell--icon">
    <FileTypeIcon extension={getFileExtension(value)} fill={StyleVariables.neutral500} />
  </div>
);

const AttachmentNameCell = ({ value, row }: CellProps<ProgramAttachment, string>) => (
  <div
    className="attachments-table__cell--name"
    data-test={`attachments-table__attachment-name-${row.index}`}
  >
    <span className="text-break">{value}</span>
  </div>
);

export const columns: Column<ProgramAttachment>[] = [
  {
    id: 'icon',
    accessor: 'name',
    width: 32,
    maxWidth: 32,
    // @ts-ignore
    Cell: AttachmentIconCell,
  },
  {
    Header: () => <div />,
    width: TaskTableNameCellWidth + TaskTableDueDateCellWidth + TaskTableOwnerCellWidth,
    maxWidth: TaskTableNameCellWidth + TaskTableDueDateCellWidth + TaskTableOwnerCellWidth,
    accessor: 'name',
    // @ts-ignore
    Cell: AttachmentNameCell,
  },
  {
    Header: () => <div />,
    accessor: (t: ProgramAttachment) => t,
    id: 'menu',
    maxWidth: 50,
    // @ts-ignore
    Cell: AttachmentMenuCell,
  },
];

export const AttachmentsTable = (props: {
  values: ProgramAttachment[];
  arrayHelpers: AttachmentArrayHelpers;
  id: string;
  hasDeleteAccess: boolean;
}) => {
  const { values, arrayHelpers, hasDeleteAccess } = props;

  const data = useMemo<ProgramAttachment[]>(() => values, [values]);

  const tableInstance = useTable<ProgramAttachment>({ columns, data }, useFlexLayout);
  const { getTableProps, getTableBodyProps, rows, prepareRow } = tableInstance;

  return (
    <div {...getTableProps()}>
      <div {...getTableBodyProps()}>
        {rows.map((row: Row<ProgramAttachment>, index: number) => {
          prepareRow(row);
          return (
            <div
              {...row.getRowProps()}
              className="attachments-table__row"
              data-test={`attachments-table__row-${index}`}
              key={`${row.getRowProps().key}-${row.original.id}-${row.original.name}`}
              onClick={() => arrayHelpers.preview(row.original)}
            >
              {row.cells.map((cell) => (
                <div
                  {...cell.getCellProps()}
                  key={cell.getCellProps().key}
                  className="attachments-table__cell"
                >
                  {cell.render('Cell', {
                    arrayHelpers,
                    key: cell.getCellProps().key,
                    index,
                    hasDeleteAccess,
                  })}
                </div>
              ))}
            </div>
          );
        })}
      </div>
    </div>
  );
};
