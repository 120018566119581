import React, { useMemo } from 'react';
import { StyleVariables } from '../../../commons/styleConstants';
import { Button } from 'react-bootstrap';
import { FileTypeIcon } from './FileTypeIcon';
import { getFileExtension } from '../../../util/file-utils';

export const AttachmentMessage = (props: {
  fileName: string;
  buttonText: string;
  message: string;
  onButtonClick: () => void;
  dataTestPrefix: string;
  isError: boolean;
}) => {
  const { fileName, buttonText, message, onButtonClick, dataTestPrefix, isError } = props;

  const extension = useMemo(() => getFileExtension(fileName).toUpperCase(), [fileName]);

  return (
    <div className="attachments-view__message-container" data-test={`${dataTestPrefix}-container`}>
      <div className="attachments-view__message-wrapper">
        <FileTypeIcon extension={extension} large={true} fill={StyleVariables.neutral50} />
        <div
          className="attachments-view__message-file-name"
          data-test={`${dataTestPrefix}-file-name`}
        >
          {fileName}
        </div>
        <div
          className="attachments-view__message-text"
          data-test={`${dataTestPrefix}-message-text`}
          style={isError ? { color: StyleVariables.red } : {}}
        >
          {message}
        </div>
        <div className="attachments-view__message-button-wrapper">
          <Button
            variant="primary"
            className="button-apply"
            data-test={`${dataTestPrefix}-button`}
            onClick={onButtonClick}
            style={{ width: 'fit-content' }}
          >
            {buttonText}
          </Button>
        </div>
      </div>
    </div>
  );
};
