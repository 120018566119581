import React from 'react';
import { ProgramAttachment } from '../../types';
import { CellProps } from './CellProps';
import { BinIcon, DownloadIcon } from '../../../assets/icons';
import { StyleVariables } from '../../../commons/styleConstants';
import { MoreMenu } from '../../../commons/components/MoreMenu';

export const AttachmentMenuCell = ({
  value,
  arrayHelpers,
  row,
  hasDeleteAccess,
}: CellProps<ProgramAttachment, ProgramAttachment>) => {
  return (
    <MoreMenu
      menuItems={[
        {
          type: 'standard',
          text: 'Delete',
          icon: <BinIcon fill={StyleVariables.black2} />,
          isHidden:
            row.original.status !== 'NEW' &&
            (row.original.status !== 'EXISTED' || !hasDeleteAccess),
          dataTest: `attachments-table__delete-action-${row.index}`,
          onClick: () => arrayHelpers.delete(value),
        },
        {
          type: 'standard',
          text: 'Download',
          icon: <DownloadIcon fill={StyleVariables.black2} />,
          isHidden: row.original.status !== 'EXISTED',
          dataTest: `attachments-table__download-action-${row.index}`,
          onClick: async () => arrayHelpers.download(value),
        },
      ]}
      menuButtonDataTest={`attachments-table__actions-${row.index}`}
    />
  );
};
