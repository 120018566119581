import { HeaderProps } from 'react-table';
import { ProgramRow } from '../../types';
import clsx from 'clsx';
import { ChevronDownIcon } from '../../../assets/icons';
import { getColumnName } from '../../programColumns';

const SortableHeader = ({ column }: HeaderProps<ProgramRow>) => (
  <div
    className="d-flex"
    {...column.getSortByToggleProps()}
    data-test={`programs-table__sortable-header--${getColumnName(column.id).toLowerCase()}`}
  >
    <span className="programs-table__header-content growegy-font-em">
      {getColumnName(column.id)}
    </span>
    <div className="programs-table__sort">
      {column.isSorted && (
        <ChevronDownIcon
          className={clsx({ rotate180: !column.isSortedDesc })}
          data-test={clsx({
            'programs-table__sort--asc': !column.isSortedDesc,
            'programs-table__sort--desc': column.isSortedDesc,
          })}
        />
      )}
    </div>
  </div>
);

export default SortableHeader;
