import React, { ReactElement } from 'react';
import { CalendarCustomIcon, CloseIcon, DividerLongIcon } from '../../assets/icons';
import { Customer, CustomerSubscription, IssuedInvoice } from '../types';
import { ActionMoreMenuItem, MoreMenu } from '../../commons/components/MoreMenu';
import clsx from 'clsx';
import { formatDateTimeCell } from '../../util/date-utils';
import { formatMoney } from '../../util/utils';
import { ButtonColored } from '../../program/components/ButtonColored';
import { BILLING_PLAN_YEAR, getBillingPlan } from '../billingPlans';
import useAnalytics from '../../web-analytics/webAnalyticsContext';
import { AnalyticsEvent } from '../../web-analytics/AnalyticsEvent';
import { analyticsTrack } from '../../web-analytics/webAnalytics';
import { getInvoiceMaxEnd, isInvoiceNonPastDue, isInvoicePastDue } from '../utils';
import { DivButton } from '../../commons/components/DivButton';
import { Button } from 'react-bootstrap';
import { CanceledBanner } from './cancellation/CanceledBanner';

type BillingHeaderColumnProps = { top: string; bottom: string; isBottomRed?: boolean };
type BillingHeaderSectionProps = {
  column1: BillingHeaderColumnProps;
  column2?: BillingHeaderColumnProps;
  action?: ReactElement;
};

const BillingHeaderColumn = ({ top, bottom, isBottomRed }: BillingHeaderColumnProps) => {
  return (
    <>
      <div data-test="billing__header-column-top" className="billing__header-column-top">
        {top}
      </div>
      <div
        data-test="billing__header-column-value"
        className={clsx('billing__header-column-bottom', {
          'billing__header-column-bottom--red': isBottomRed,
        })}
      >
        {bottom}
      </div>
    </>
  );
};

const HeaderSectionColumns = ({ column1, column2, action }: BillingHeaderSectionProps) => (
  <div className="billing__header-columns">
    <div>
      <BillingHeaderColumn {...column1} />
    </div>
    {column2 && (
      <div
        className={clsx({
          'billing__header-middle-column-margin': column2.isBottomRed,
        })}
      >
        <BillingHeaderColumn {...column2} />
      </div>
    )}
    {action && <div className="flex-shrink-0">{action}</div>}
  </div>
);

export const BillingHeaderSections = ({ sections }: { sections: BillingHeaderSectionProps[] }) => {
  return (
    <div className="billing__header">
      {sections.map((section, index) => (
        <React.Fragment key={index}>
          <div className="billing__header-section" data-test="billing__header-section">
            <HeaderSectionColumns {...section} />
          </div>
          {index < sections.length - 1 && (
            <>
              <DividerLongIcon className="billing__header-vertical-divider" />
              <div className="billing__header-horizontal-divider" />
            </>
          )}
        </React.Fragment>
      ))}
    </div>
  );
};

const isAnnualPlanFullyPaid = (currentPlan: string, invoices: IssuedInvoice[]) => {
  const plan = getBillingPlan(currentPlan);
  if (plan?.name !== BILLING_PLAN_YEAR.name) {
    return false;
  }

  if (invoices.filter((i) => i.status !== 'PAID').length > 0) {
    return false;
  }

  const annualInvoices = invoices.filter((i) => i.billingPlanName === plan.name);
  return annualInvoices.length > 0 && annualInvoices.length % 3 === 0;
};

const getCurrentPlanSectionProps = ({
  onCancelClick,
  currentPlan,
  onSeeAnnualClick,
  changePlanOptions,
  customerSubscription,
  invoices,
}: {
  onCancelClick: () => void;
  onSeeAnnualClick: () => void;
  currentPlan: string;
  changePlanOptions: string[];
  customerSubscription: CustomerSubscription | null;
  invoices: IssuedInvoice[];
}) => {
  const menuItems: ActionMoreMenuItem[] = [];
  if (changePlanOptions.includes(BILLING_PLAN_YEAR.name)) {
    menuItems.push({
      type: 'standard',
      text: 'See Annual Plan',
      icon: <CalendarCustomIcon />,
      dataTest: 'billing__see-annual-plan-menu-item',
      onClick: () => onSeeAnnualClick(),
    });
  }
  if (customerSubscription && !customerSubscription.cancelAt) {
    menuItems.push({
      type: 'standard',
      text: isAnnualPlanFullyPaid(currentPlan, invoices) ? 'Cancel renewal' : 'Cancel plan',
      icon: <CloseIcon />,
      dataTest: 'billing__cancel-plan-menu-item',
      onClick: () => onCancelClick(),
    });
  }

  const billingPlan = getBillingPlan(currentPlan);
  return {
    column1: { top: 'Current plan', bottom: billingPlan?.displayName ?? 'unknown' },
    column2: { top: 'Price', bottom: billingPlan?.scheduleDescription ?? 'unknown' },
    action: (
      <>
        {customerSubscription && menuItems.length > 0 ? (
          <MoreMenu
            menuItems={menuItems}
            menuButtonDataTest="billing__manage-button"
            menuDataTest="billing__manage-menu"
            customToggle={() => (
              <ButtonColored text={!!customerSubscription?.cancelAt ? 'Canceled' : 'Manage'} />
            )}
          />
        ) : (
          <DivButton
            className="billing__header-cancelled"
            dataTest="billing__plan-cancelled"
            text="Canceled"
          />
        )}
      </>
    ),
  };
};

const getInvoicesSection = (
  invoices: IssuedInvoice[],
  column1Header: string,
  column2Header: string,
  isPastDue: boolean,
  onPayNowClick: () => void
): BillingHeaderSectionProps | null => {
  if (invoices.length > 0) {
    const totalAmountDue = invoices.reduce((acc, curr) => acc + curr.amountDue, 0);

    if (totalAmountDue > 0) {
      const earliestDueDate = invoices.reduce((acc, curr) =>
        acc.dueDate.valueOf() < curr.dueDate.valueOf() ? acc : curr
      ).dueDate;
      return {
        column1: {
          top: column1Header,
          bottom: formatDateTimeCell(earliestDueDate, 'MMM DD, YYYY'),
          isBottomRed: isPastDue,
        },
        column2: {
          top: column2Header,
          bottom: formatMoney(`${totalAmountDue}`),
          isBottomRed: isPastDue,
        },
        action: (
          <Button
            variant="primary"
            className="button-apply"
            data-test={isPastDue ? 'billing-header__pay-now-past-due' : 'billing-header__pay-now'}
            onClick={onPayNowClick}
          >
            Pay now
          </Button>
        ),
      };
    }
  }
  return null;
};

const getNextPaymentDate = (
  invoices: IssuedInvoice[],
  cancelAt: Date | null | undefined
): Date | null => {
  if (cancelAt) {
    return cancelAt;
  }
  if (invoices.length > 0) {
    return invoices
      .map((i) => getInvoiceMaxEnd(i))
      .reduce((acc, curr) => (acc.valueOf() > curr.valueOf() ? acc : curr));
  }
  return null;
};

const getNextPaymentSection = (
  currentPlan: string,
  invoices: IssuedInvoice[],
  customerSubscription: CustomerSubscription | null
) => {
  if (invoices.length > 0) {
    const lastEndDate = getNextPaymentDate(invoices, customerSubscription?.cancelAt);
    return {
      column1: {
        top: !!customerSubscription?.cancelAt
          ? 'Active until'
          : isAnnualPlanFullyPaid(currentPlan, invoices)
          ? 'To be renewed'
          : 'Next invoice',
        bottom: formatDateTimeCell(lastEndDate, 'MMM DD, YYYY'),
        isBottomRed: false,
      },
    };
  }
  return null;
};

export const BillingHeader = ({
  customer,
  invoices,
  onPayNowClick,
  onCancelClick,
  onSeeAnnualPlan,
}: {
  customer: Customer;
  invoices: IssuedInvoice[];
  onPayNowClick: (invoices: IssuedInvoice[], isPastDue: boolean) => void;
  onCancelClick: () => void;
  onSeeAnnualPlan: () => void;
}) => {
  const analytics = useAnalytics();
  const now = new Date();
  const openInvoices = invoices.filter((invoice) => invoice.status === 'OPEN');
  const pastDueInvoices = openInvoices.filter((inv) => isInvoicePastDue(inv, now));
  const uncollectibleInvoices = invoices.filter((invoice) => invoice.status === 'UNCOLLECTIBLE');
  const pastDueAndUncollectible = [...pastDueInvoices, ...uncollectibleInvoices];

  const nonPastDueInvoices = openInvoices.filter((value) => isInvoiceNonPastDue(value, now));

  const sections: (BillingHeaderSectionProps | null)[] = [
    getCurrentPlanSectionProps({
      currentPlan: customer.planName,
      customerSubscription: customer.activeSubscription,
      invoices,
      changePlanOptions: customer.changePlanOptions,
      onCancelClick: () => onCancelClick(),
      onSeeAnnualClick: () => {
        onSeeAnnualPlan();
        analyticsTrack(analytics, AnalyticsEvent.BILLING_PLAN_ANNUAL_PLAN_VIEWED, {
          source: 'header',
        });
      },
    }),
    getInvoicesSection(pastDueAndUncollectible, 'Payment past due', 'Amount past due', true, () =>
      onPayNowClick(pastDueAndUncollectible, true)
    ),
    getInvoicesSection(nonPastDueInvoices, 'Payment due', 'Amount due', false, () =>
      onPayNowClick(nonPastDueInvoices, false)
    ),
  ];

  if (sections[1] === null && sections[2] === null && customer.activeSubscription) {
    sections.push(getNextPaymentSection(customer.planName, invoices, customer.activeSubscription));
  }

  return (
    <div className="billing__header-container">
      <BillingHeaderSections
        sections={sections.filter((section) => section !== null) as BillingHeaderSectionProps[]}
      />
      <CanceledBanner customer={customer} />
    </div>
  );
};
