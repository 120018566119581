import { ResolvedProgramSummary } from '../../types';
import { ICurrentUser } from '../../../currentuser/currentUser';
import { CalendarViewType, UnscheduledViewType } from '../../containers/types';
import clsx from 'clsx';
import { getProgramPermissionHelper } from '../../programPermissionHelper';
import { hexToRGB } from '../../util';
import { ProgramCalendarEventContent } from './ProgramCalendarEventContent';
import React from 'react';

export const ProgramCalendarEvent = (props: {
  program: ResolvedProgramSummary;
  user: ICurrentUser;
  onClick: ((program: ResolvedProgramSummary) => void) | null;
  showCampaigns: boolean;
  applicableVisibleColumns: string[];
  viewType: CalendarViewType | typeof UnscheduledViewType;
  shortcutFieldNamesOnly: boolean;
}) => {
  const {
    program,
    user,
    onClick,
    showCampaigns,
    applicableVisibleColumns,
    viewType,
    shortcutFieldNamesOnly,
  } = props;
  return (
    <div
      data-programid={program.id}
      className={clsx('program-calendar__unscheduled-program', {
        'program-calendar__unscheduled-program--draggable': getProgramPermissionHelper(
          user,
          program
        ).canEditRegularField(),
        'cursor-default': !onClick,
      })}
      style={{
        backgroundColor: hexToRGB(program.type.color, 0.1),
        borderColor: hexToRGB(program.type.color, null),
      }}
      onClick={() => onClick && onClick(program)}
    >
      <div
        className="program-calendar__unscheduled-program-container"
        style={{ color: hexToRGB(program.type.color, null) }}
      >
        <ProgramCalendarEventContent
          programSummary={program}
          showCampaigns={showCampaigns}
          visibleColumns={applicableVisibleColumns}
          openCampaignDialog={onClick}
          viewType={viewType}
          isCampaignGrouping={false}
          shortcutFieldNamesOnly={shortcutFieldNamesOnly}
        />
      </div>
    </div>
  );
};
