import { NewTabLink } from '../../commons/components/NewTabLink';
import { programsConfig } from '../../commons/config';
import React from 'react';

export const FormulaInputHelp = (props: { dataTest: string }) => {
  const { dataTest } = props;
  return (
    <div className="formula-input__example-wrapper" tabIndex={0}>
      <div>
        <div className="text-nowrap" data-test={`${dataTest}-formula-description`}>
          Calculate a custom numeric value based on other numeric fields in the program ·{' '}
          <NewTabLink href={programsConfig.customFormulaFieldHelpUrl}>Learn more</NewTabLink>
        </div>
      </div>
      <div className="formula-input__example-examples-block">
        <span data-test={`${dataTest}-formula-examples-title`}>Examples: </span>
        <div className="formula-input__example-examples-rows">
          <div className="text-nowrap">
            <span className="formula-input__example-highlight">Budget</span>
            <span className="growegy-color-neutral-900"> * 0.1</span>
          </div>
          <div className="text-nowrap">
            <span className="formula-input__example-highlight">Actual Leads</span>
            <span className="growegy-color-neutral-900"> / </span>
            <span className="formula-input__example-highlight">Projected Leads</span>
            <span className="growegy-color-neutral-900"> * 100</span>
          </div>
        </div>
      </div>
    </div>
  );
};
