import { ChangeEvent } from 'react';
import { Formik } from 'formik';
import { useMutation } from '@tanstack/react-query';
import { Button, Form } from 'react-bootstrap';
import { UserSettings } from '../user/types';
import useApi from '../api/backendApiContext';
import { useQueryCacheManager } from '../hooks/useQueryCacheManager';
import { useFetchedUserSettings } from './hooks/useFetchedUserSettings';
import { SystemRole } from '../auth/systemRole';
import UserAvatar from './UserAvatar';
import { DivButton } from '../commons/components/DivButton';
import { useNotification } from '../notification/notificationsContext';

const UserSettingsEdit = (props: { userId: string; email: string; roles: SystemRole[] }) => {
  const { userId, email, roles } = props;
  const { userApi } = useApi();
  const cacheManager = useQueryCacheManager();
  const { notifySuccess } = useNotification();

  const { isLoading, isSuccess, isError, data } = useFetchedUserSettings(userId);

  const { mutateAsync: updateMutation, error: updateError } = useMutation(
    async (value: UserSettings) => userApi.updateUserSettings(userId, value),
    {
      onSettled: async () => {
        await cacheManager.initUserSettingsRefetch(userId);
        await cacheManager.initUsersRefetch();
      },
      onSuccess: () => {
        const message = `User settings updated`;
        notifySuccess({
          notificationMsg: message,
          logMsg: message,
        });
      },
    }
  );

  const handleSaveClick = async (values: UserSettings) => {
    await updateMutation(values);
  };

  return (
    <>
      {isLoading && (
        <div className="alert alert-secondary" role="alert">
          Loading user settings...
        </div>
      )}
      {isError && (
        <div
          className="alert alert-secondary"
          role="alert"
          data-test="user-settings__loading-error"
        >
          Error loading user settings
        </div>
      )}
      {updateError && (
        <div className="alert alert-secondary" role="alert" data-test="user-settings__update-error">
          Error update user settings
        </div>
      )}
      {isSuccess && data && (
        <>
          <Formik
            initialValues={data}
            onSubmit={async (values: UserSettings) => handleSaveClick(values)}
            enableReinitialize={true}
          >
            {({ handleSubmit, handleChange, values, dirty, resetForm }) => (
              <Form onSubmit={handleSubmit}>
                <Form.Group controlId="avatar" className="growegy-form__row">
                  <label className="growegy-form__label d-flex align-items-center">
                    Profile picture
                  </label>
                  <div data-test="user-profile__avatar">
                    <UserAvatar userId={userId} size={40} editMode={true} />
                  </div>
                </Form.Group>
                <Control
                  name="firstName"
                  label="First name"
                  value={values.firstName}
                  onChange={handleChange}
                  dataTest="user-settings__input-firstName"
                />
                <Control
                  name="lastName"
                  label="Last name"
                  value={values.lastName}
                  onChange={handleChange}
                  dataTest="user-settings__input-lastName"
                />
                <Control
                  name="title"
                  label="Job title"
                  value={values.title}
                  onChange={handleChange}
                  placeholder="Add job title"
                  dataTest="user-settings__input-title"
                />
                <Control
                  name="location"
                  label="Location"
                  value={values.location}
                  onChange={handleChange}
                  placeholder="Add country, city, etc."
                  dataTest="user-settings__input-location"
                />

                <div className="growegy-form__row user-settings__form-group">
                  <label className="growegy-form__label">Email</label>
                  <div
                    className="growegy-form__label user-settings__input"
                    data-test="user-profile__email"
                  >
                    {email}
                  </div>
                </div>
                <div className="growegy-form__row user-settings__form-group">
                  <label className="growegy-form__label">Role</label>
                  <div
                    className="growegy-form__label user-settings__input text-capitalize"
                    data-test="user-profile__role"
                  >
                    {roles.map((x) => x.name).join(', ')}
                  </div>
                </div>
                <div className="growegy-form__row user-settings__buttons-group">
                  {dirty && (
                    <DivButton
                      text={'Cancel'}
                      className="div-button--cancel"
                      onClick={() => resetForm()}
                      isGray={true}
                      dataTest="user-settings__cancel"
                    />
                  )}

                  <Button
                    type="submit"
                    variant="primary"
                    className="button-apply"
                    disabled={!dirty}
                    data-test="user-settings__apply"
                  >
                    Update profile
                  </Button>
                </div>
              </Form>
            )}
          </Formik>
        </>
      )}
    </>
  );
};

const Control = (props: {
  name: string;
  label: string;
  value: string;
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
  dataTest: string;
  placeholder?: string;
}) => {
  const { name, value, label, onChange, dataTest, placeholder } = props;
  return (
    <Form.Group controlId={name} className="growegy-form__row">
      <label className="growegy-form__label">{label}</label>
      <input
        type="text"
        name={name}
        value={value}
        onChange={onChange}
        className="growegy-form__input user-settings__form-group growegy-label14"
        data-test={dataTest}
        placeholder={placeholder}
      />
    </Form.Group>
  );
};

export default UserSettingsEdit;
