import useApi from '../../../api/backendApiContext';
import { useQuery } from '@tanstack/react-query';
import { useQueryCacheManager } from '../../../hooks/useQueryCacheManager';
import useAuth from '../../../auth/authContext';
import { ReactQueryConstants } from '../../../commons/constants';
import { QueryStatus } from '../../../commons/components/QueryStatus';
import { PromptMark } from '../../types';

export const useFetchedPromptMarks = () => {
  const { growegyAIApi } = useApi();
  const {
    state: { user },
  } = useAuth();
  const queryCacheManager = useQueryCacheManager();
  const queryKey = queryCacheManager.getPromptMarksCacheKey();
  const query = useQuery(
    queryKey,
    async () => {
      if (!user) {
        return [] as PromptMark[];
      }
      return growegyAIApi.getPromptMarks(user.userId);
    },
    {
      staleTime: ReactQueryConstants.promptsStaleTime,
      refetchInterval: ReactQueryConstants.promptsRefetchTime,
      refetchOnWindowFocus: true,
    }
  );
  const queryStatusComponent = (
    <QueryStatus
      queryKey={queryKey}
      queryStatus={query.status}
      dataTestPrefix="chats"
      failedToLoadText="Something went wrong while loading the favorites."
    />
  );

  return { query, queryStatusComponent };
};
