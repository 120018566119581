import { HeaderGroup } from 'react-table';
import { ProgramRow } from '../../types';
import { Draggable, Droppable } from '@hello-pangea/dnd';
import clsx from 'clsx';
import { DragHandle } from '../../../commons/components/DragHandle';

const HeaderRow = (props: { headerGroup: HeaderGroup<ProgramRow>; showFirst?: boolean }) => {
  const { headerGroup, showFirst } = props;
  const [first, ...tail] = headerGroup.headers.filter((h) => !h.isGrouped);
  return (
    <Droppable droppableId="columns-headers" direction="horizontal">
      {(provided, snapshot) => (
        <div
          className={clsx('programs-table__header--row', 'programs-table__row', {
            'programs-table__header--row_dragging': snapshot.isDraggingOver,
          })}
          ref={provided.innerRef}
          {...provided.droppableProps}
          {...headerGroup.getHeaderGroupProps()}
        >
          {showFirst ? (
            <div
              ref={provided.innerRef}
              {...first.getHeaderProps()}
              className="programs-table__sticky programs-table__sticky--header programs-table__header--cell programs-table__row--header"
              style={{
                ...first.getHeaderProps().style,
              }}
            >
              {first.render('Header', { key: first.getHeaderProps().key })}
            </div>
          ) : (
            <div
              ref={provided.innerRef}
              {...first.getHeaderProps()}
              className="programs-table__sticky programs-table__sticky--header"
            />
          )}
          {tail.map((h, index) => (
            <Draggable draggableId={h.id} index={index} key={h.id}>
              {(provided, snapshot) => (
                <div
                  ref={provided.innerRef}
                  data-test={`programs-table__header--cell-${index}`}
                  className={clsx('programs-table__header--cell programs-table__row--header', {
                    'programs-table__header--cell_dragging': snapshot.isDragging,
                  })}
                  {...provided.draggableProps}
                  {...h.getHeaderProps()}
                  style={{
                    ...provided.draggableProps.style,
                    ...h.getHeaderProps().style,
                  }}
                >
                  <DragHandle
                    {...provided.dragHandleProps}
                    data-test="programs-table__header--cell--drag--handle"
                  />
                  {h.render('Header', { key: h.getHeaderProps().key })}
                </div>
              )}
            </Draggable>
          ))}
          {provided.placeholder}
        </div>
      )}
    </Droppable>
  );
};

export default HeaderRow;
