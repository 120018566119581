import { SpaLogger } from '../spaLogger';
import FaroSpaLoggerConfig from './faroSpaLoggerConfig';
import { TracingInstrumentation } from '@grafana/faro-web-tracing';
import {
  createRoutesFromChildren,
  matchRoutes,
  Routes,
  useLocation,
  useNavigationType,
} from 'react-router-dom';

import type { Faro } from '@grafana/faro-react';
import {
  getWebInstrumentations,
  initializeFaro as coreInit,
  ReactIntegration,
  ReactRouterVersion,
} from '@grafana/faro-react';
import { ICurrentUser } from '../../currentuser/currentUser';
import { readCommonConfig } from '../../commons/config';

export default class FaroSpaLogger implements SpaLogger {
  private readonly faro: Faro;

  public constructor(config: FaroSpaLoggerConfig) {
    this.faro = coreInit({
      url: config.url,
      apiKey: config.apiKey,
      instrumentations: [
        ...getWebInstrumentations({
          captureConsole: true,
        }),
        new TracingInstrumentation({
          instrumentationOptions: {
            propagateTraceHeaderCorsUrls: [new RegExp(`${readCommonConfig().backendApiUrl}/*`)],
          },
        }),
        new ReactIntegration({
          router: {
            version: ReactRouterVersion.V6,
            dependencies: {
              createRoutesFromChildren,
              matchRoutes,
              Routes,
              useLocation,
              useNavigationType,
            },
          },
        }),
      ],
      app: {
        name: 'spa',
        version: config.version,
        environment: config.environment,
      },
    });

    this.faro.api.pushLog(['Faro was initialized']);
  }

  public info(message: string, ...optionalParams: any[]) {
    console.info(message, optionalParams);
  }

  public warn(message: string, ...optionalParams: any[]) {
    console.warn(message, optionalParams);
  }

  public error(message: string, ...optionalParams: any[]) {
    console.error(message, optionalParams);
  }

  public pushEvent(
    name: string,
    attributes?: Record<string, string>,
    domain?: string,
    options?: {
      skipDedupe?: boolean;
    }
  ) {
    this.faro.api.pushEvent(name, attributes, domain, options);
  }

  public pushAlertEvent(
    name: string,
    attributes?: Record<string, string>,
    domain?: string,
    options?: {
      skipDedupe?: boolean;
    }
  ) {
    const text = 'GROWEGY_ALARM ' + name;
    this.faro.api.pushEvent(text, attributes, domain, options);
    this.faro.api.pushLog([text, JSON.stringify(attributes)]);
  }

  public setUser(user: ICurrentUser | null) {
    if (user) {
      this.faro.api.setUser({
        id: user.userId,
        email: user.email,
      });
    } else {
      this.faro.api.resetUser();
    }
  }

  public async wrapWithTelemetry<T extends unknown>(
    promise: Promise<T>,
    name?: string
  ): Promise<T> {
    const otel = this.faro.api.getOTEL();
    if (otel) {
      const nameOrDefault = name ?? 'http-request';
      const tracer = otel.trace.getTracer('default');
      let span = otel.trace.getActiveSpan() ?? tracer.startSpan(nameOrDefault);
      return otel.context.with(otel.trace.setSpan(otel.context.active(), span), async () => {
        try {
          this.pushEvent(`Start tracing ${nameOrDefault}`);
          const response = await promise;
          span.setStatus({ code: 1 });
          return response;
        } catch (e) {
          span.setStatus({ code: 2 });
          throw e;
        } finally {
          span.end();
        }
      });
    } else {
      this.warn(`OTEL is not initialized, skipping tracing ${name}`);
      return promise;
    }
  }
}
