import moment from 'moment';

const unscheduledYear = 1000;

export const isUnscheduled = (p: { startDateTime: Date }): boolean =>
  p.startDateTime.getUTCFullYear() === unscheduledYear;

export const isScheduled = (p: { startDateTime: Date }): boolean => !isUnscheduled(p);

const hashCode = (s: string): number => {
  let h = 0;
  for (let i = 0; i < s.length; i++)
    h =
      // eslint-disable-next-line no-bitwise
      (Math.imul(31, h) + s.charCodeAt(i)) | 0;
  return h;
};

const getUnscheduledMonth = (programId?: string) => Math.abs(hashCode(programId ?? '') % 12) + 1;
const getUnscheduledDate = (programId: string | undefined, date: number) =>
  new Date(unscheduledYear, getUnscheduledMonth(programId) - 1, date, 0, 0, 0, 0);
export const unscheduledStart = (programId?: string): Date => getUnscheduledDate(programId, 15);
export const unscheduledEnd = (programId?: string): Date => getUnscheduledDate(programId, 16);

export const unscheduledProgramsMonths = Array.from(Array(12)).map((_, idx) =>
  moment(`${unscheduledYear}-${idx + 1 < 10 ? '0' : ''}${idx + 1}-15T00:00:00Z`)
    .startOf('month')
    .toDate()
);
