import { AnalyticsEvent } from '../../web-analytics/AnalyticsEvent';
import useAnalytics from '../../web-analytics/webAnalyticsContext';
import { ButtonColored } from '../../program/components/ButtonColored';
import React from 'react';

import { openUrlInNewTab } from '../../util/utils';

export const LearnMoreButton = (props: { url: string; analyticsEvent: AnalyticsEvent }) => {
  const { url, analyticsEvent } = props;
  const analytics = useAnalytics();
  return (
    <ButtonColored
      onClick={() => {
        openUrlInNewTab(url, {
          analytics,
          analyticsEvent,
        });
      }}
      text="Learn more"
    />
  );
};
