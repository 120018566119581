import moment from 'moment';

export const startDateToQuarterDate = (quarterBasis: Date, date: Date) => {
  const monthsDelta = moment(date).startOf('month').diff(moment(quarterBasis), 'months');
  return moment(quarterBasis).add(monthsDelta, 'days').startOf('day').toDate();
};

export const endDateToQuarterDate = (quarterBasis: Date, date: Date) => {
  const monthsDelta = moment(date)
    .add(-1, 'day')
    .add(1, 'month')
    .diff(moment(quarterBasis), 'months');
  return moment(quarterBasis).add(monthsDelta, 'days').startOf('day').toDate();
};

export const quarterDateToNormalDate = (quarterBasis: Date, quarterDate: Date) => {
  const monthsDelta = moment(quarterDate).diff(moment(quarterBasis), 'days');
  return moment(quarterBasis).add(monthsDelta, 'months').startOf('day').toDate();
};

export const getNewDatesAfterDrag = (
  oldProgram: {
    start: Date;
    end: Date;
    wholeDay: boolean;
  },
  dragTo: {
    quarterBasis: Date;
    newStart: Date;
    newEnd: Date;
  }
): { newStart: Date; newEnd: Date } => {
  const newStartMonth = quarterDateToNormalDate(dragTo.quarterBasis, dragTo.newStart);
  const newEndMonth = quarterDateToNormalDate(
    dragTo.quarterBasis,
    moment(dragTo.newEnd).subtract(1, 'day').toDate()
  );

  const startMonthDiff = moment(newStartMonth).diff(
    moment(oldProgram.start).startOf('month'),
    'months'
  );
  const oldEndMonth = moment(oldProgram.end).startOf('month').isSame(moment(oldProgram.end))
    ? moment(oldProgram.end).startOf('month').subtract(1, 'month')
    : moment(oldProgram.end).startOf('month');
  const endMonthDiff = moment(newEndMonth).diff(oldEndMonth, 'months');

  let newStart = moment(oldProgram.start).add(startMonthDiff, 'months').toDate();
  let newEnd = moment(oldProgram.end).add(endMonthDiff, 'months').toDate();
  if (newStart >= newEnd) {
    if (startMonthDiff === 0) {
      newEnd = oldProgram.wholeDay
        ? moment(newStart).startOf('day').add(1, 'day').toDate()
        : moment(newStart).add(1, 'minute').toDate();
    } else {
      newStart = oldProgram.wholeDay
        ? moment(newEnd).startOf('day').subtract(1, 'day').toDate()
        : moment(newEnd).subtract(1, 'minute').toDate();
    }
  }

  return {
    newStart,
    newEnd,
  };
};
