import useAuth from '../../auth/authContext';
import { useFetchedUsers } from './useFetchedUsers';
import { UnknownProgramOwner, User } from '../types';

export const useCurrentUser = (): User => {
  const {
    state: { user },
  } = useAuth();
  const { data: users } = useFetchedUsers();
  if (!user) return UnknownProgramOwner;
  if (!users) return UnknownProgramOwner;
  return users.find((x) => x.id === user.userId) ?? UnknownProgramOwner;
};
