import _ from 'lodash';
import React, { useCallback, useEffect, useState } from 'react';
import { NoProgramOwner, UnknownProgramOwner } from '../../user/types';
import { User } from '../../user/types';
import { extractUserName } from '../../user/utils';
import { useFetchedUsers } from '../../user/hooks/useFetchedUsers';
import { FilterOptionOption } from 'react-select/dist/declarations/src/filters';
import { GrowegySelect } from './GrowegySelect';
import { UserAvatar } from '../../commons/components/UserAvatar';

const getUserName = (u: User): string => {
  if (u.id === UnknownProgramOwner.id) return 'Unknown';
  if (u.id === NoProgramOwner.id) return 'No owner';
  return extractUserName(u);
};

const filterOption: <Option extends unknown>(
  option: FilterOptionOption<Option>,
  input: string
) => boolean = <Option extends unknown>(
  option: FilterOptionOption<Option>,
  input: string
): boolean => {
  const user = option.data as User;
  if (user.id === UnknownProgramOwner.id) return false;

  const searchString = input.toLowerCase().trim();
  if (user.email.toLowerCase().includes(searchString)) return true;

  const { firstName, lastName } = user;
  if (firstName.toLowerCase().includes(searchString)) return true;
  if (lastName.toLowerCase().includes(searchString)) return true;
  return `${firstName.toLowerCase()} ${lastName.toLowerCase()}`.includes(searchString);
};

export const UserWithAvatar = ({
  user,
  className,
  dataTestPrefix,
}: {
  user: User;
  className?: string;
  userName?: string;
  dataTestPrefix?: string;
}) => (
  <div
    className={`program-owner-select__option-wrapper ${className ?? ''}`}
    data-test={`${dataTestPrefix ?? 'program-owner-select'}__option-row--${user.email}`}
  >
    <UserAvatar user={user} />
    <div className="program-owner-select__label-wrapper">
      <span
        className="program-owner-select__label"
        data-test={`program-owner-select__option-label--${user.email}`}
      >
        {getUserName(user)}
      </span>
    </div>
  </div>
);

export const ProgramOwnerSelect = (props: {
  initialValue: User | null;
  onBlur?: () => void;
  onMenuOpen?: () => void;
  onMenuClose?: () => void;
  onValueChange: (value: User | null) => void;
  autoFocus?: boolean;
  isClearable?: boolean;
  disabled?: boolean;
  placeholder?: string;
  optionClassName?: string;
  dataTestPrefix?: string;
  menuZIndex?: number;
  width?: number;
}) => {
  const {
    initialValue,
    onValueChange,
    onBlur,
    optionClassName,
    onMenuOpen,
    onMenuClose,
    autoFocus,
    isClearable,
    disabled,
    placeholder,
    dataTestPrefix,
    menuZIndex,
    width,
  } = props;
  const { data: options } = useFetchedUsers();

  const calculateState = useCallback(
    () => (initialValue === null && !isClearable ? _.cloneDeep(NoProgramOwner) : initialValue),
    [initialValue, isClearable]
  );

  const [selectedOption, setSelected] = useState<User | null>(calculateState());

  useEffect(() => {
    setSelected(calculateState());
  }, [calculateState, initialValue]);

  const onChange: (c: User | null) => void = useCallback(
    (c: User | null) => {
      setSelected(c);
      onValueChange(c);
    },
    [onValueChange]
  );
  const formatOptionLabelCallback = useCallback(
    (user: User) => (
      <UserWithAvatar user={user} className={optionClassName} dataTestPrefix={dataTestPrefix} />
    ),
    [optionClassName, dataTestPrefix]
  );

  return (
    <GrowegySelect
      disabled={disabled}
      placeholder={placeholder}
      autoFocus={autoFocus}
      options={options}
      filterOption={filterOption}
      selectedOption={selectedOption}
      formatOptionLabel={formatOptionLabelCallback}
      onChange={onChange}
      onBlur={onBlur}
      onMenuOpen={onMenuOpen}
      onMenuClose={onMenuClose}
      isClearable={isClearable}
      backspaceRemovesValue={true}
      dataTestPrefix={dataTestPrefix ?? 'program-owner-select'}
      menuZIndex={menuZIndex}
      width={width}
    />
  );
};
