import React, {
  BaseSyntheticEvent,
  ReactElement,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { Row } from 'react-table';
import {
  NoProgramType,
  ProgramRow,
  ProgramSummary,
  TaskChangeAndSnapshot,
  ProgramType,
  ResolvedProgramSummary,
  ResolvedProgramTask,
  UnknownProgramType,
  ProgramPartialUpdateData,
  ProgramTask,
} from '../../types';
import useAuth from '../../../auth/authContext';
import { getEmptyProgramSummary } from '../../util';
import clsx from 'clsx';
import { TaskArrayHelpers } from '../tasks/CellProps';
import { DivButton } from '../../../commons/components/DivButton';
import { ICurrentUser } from '../../../currentuser/currentUser';
import {
  getProgramPermissionHelper,
  useAddProgramPermissionHelper,
  useProgramPermissionHelper,
} from '../../programPermissionHelper';
import { useResolvedCampaigns } from '../../hooks/useResolvedCampaigns';
import { cloneTasks } from '../tasks/utils';
import { Droppable } from '@hello-pangea/dnd';
import { getProgramDroppableId } from '../tasks/utils';
import { PlusSmallIcon } from '../../../assets/icons';

export const GroupedRow = (props: {
  row: Row<ProgramRow>;
  programTypes: Map<string, ProgramType> | null;
  onCreate: (p: ProgramSummary) => void;
  isScheduledMode: boolean;
}) => {
  const {
    state: { user },
  } = useAuth();
  const addProgramPermissionHelper = useAddProgramPermissionHelper();
  const { row, onCreate, programTypes, isScheduledMode } = props;

  const [first, ...tail] = row.cells.filter((c) => !c.column.isGrouped);

  let typeId = '';
  let campaignId = '';
  switch (row.groupByID) {
    case 'type':
      typeId = programTypes?.get(row.groupByVal)?.id ?? '';
      break;
    case 'campaign':
      campaignId = row.groupByVal;
      break;
  }
  const onCreateClick = (e: BaseSyntheticEvent) => {
    e.stopPropagation();
    onCreate(
      getEmptyProgramSummary(
        typeId === UnknownProgramType.id || typeId === NoProgramType.id ? '' : typeId,
        true,
        null,
        null,
        user ? user.userId : null,
        isScheduledMode,
        'PROGRAM',
        campaignId
      )
    );
  };
  const { data: campaigns } = useResolvedCampaigns();
  let canCreate = true;
  if (row.groupByID === 'campaign') {
    if (campaignId && campaigns) {
      const campaign = campaigns.find((c) => c.id === campaignId);
      canCreate = !!campaign && addProgramPermissionHelper.canCreateProgramInCampaign(campaign);
    } else {
      canCreate = false;
    }
  }

  return (
    <div
      className="programs-table__row"
      data-test={`programs-table__grouped-row--${row.groupByVal}`}
      {...row.getRowProps()}
    >
      <div
        {...first.getCellProps()}
        className="programs-table__sticky programs-table__sticky-footer"
      >
        <div className="d-flex" style={{ width: '111px' }}>
          {canCreate && (
            <DivButton
              isGray={true}
              icon={<PlusSmallIcon />}
              text="Add new"
              onClick={onCreateClick}
              dataTest={`programs-table__create-new--${row.groupByVal}`}
              className="common-table__add-new-btn"
            />
          )}
        </div>
      </div>

      {tail.map((cell) => (
        <div
          {...cell.getCellProps()}
          key={cell.getCellProps().key}
          className="programs-table__group-row-cell"
        >
          {cell.render('Aggregated')}
        </div>
      ))}
    </div>
  );
};

export const buildTaskArrayHelper = (
  programSummary: ResolvedProgramSummary,
  onProgramUpdate: (data: { original: ProgramRow; updates: ProgramPartialUpdateData[] }) => void
): TaskArrayHelpers => {
  const originalTasks = programSummary.resolvedTasks;
  const tasksAfterChange = cloneTasks(originalTasks);
  const insert = (index: number, task: ResolvedProgramTask) => {
    tasksAfterChange.splice(index, 0, task);
    tasksAfterChange.forEach((x, i) => {
      tasksAfterChange[i] = { ...x, orderId: i };
    });
  };
  return {
    push: (task) => {
      tasksAfterChange.push(task);
      const value: TaskChangeAndSnapshot = {
        changes: [
          {
            changeType: 'CREATE',
            orderId: originalTasks.length,
            task,
            shouldFocus: task.shouldFocus,
          },
        ],
        tasksAfterChange,
      };
      onProgramUpdate({ original: programSummary, updates: [{ field: 'resolvedTasks', value }] });
    },
    insert: (index, task) => {
      if (index < 0 || index > originalTasks.length) return;
      insert(index, task);
      const value: TaskChangeAndSnapshot = {
        changes: [
          {
            changeType: 'CREATE',
            orderId: index,
            task,
            shouldFocus: task.shouldFocus,
          },
        ],
        tasksAfterChange,
      };
      onProgramUpdate({ original: programSummary, updates: [{ field: 'resolvedTasks', value }] });
    },
    remove: (index) => {
      if (index < 0 || index >= originalTasks.length) return;
      const taskId = originalTasks[index].id;
      if (taskId === null) return;
      tasksAfterChange.splice(index, 1);
      const value: TaskChangeAndSnapshot = {
        changes: [
          {
            changeType: 'DELETE',
            taskId: taskId,
          },
        ],
        tasksAfterChange,
      };
      onProgramUpdate({ original: programSummary, updates: [{ field: 'resolvedTasks', value }] });
    },
    replace: (index, task) => {
      if (index < 0 || index >= originalTasks.length) return;
      tasksAfterChange.splice(index, 1, task);
      const value: TaskChangeAndSnapshot = {
        changes: [
          {
            changeType: 'UPDATE',
            task,
          },
        ],
        tasksAfterChange,
      };
      onProgramUpdate({ original: programSummary, updates: [{ field: 'resolvedTasks', value }] });
    },
    move: (from: number, to: number) => {
      if (from < 0 || from >= originalTasks.length || to < 0 || to >= originalTasks.length) return;
      const movedTask = tasksAfterChange[from];
      tasksAfterChange.splice(from, 1);
      insert(to, movedTask);
      const value: TaskChangeAndSnapshot = {
        changes: [
          {
            changeType: 'UPDATE',
            orderId: to,
            task: originalTasks[from],
          },
        ],
        tasksAfterChange,
      };
      onProgramUpdate({ original: programSummary, updates: [{ field: 'resolvedTasks', value }] });
    },
  };
};

export const NotGroupedRow = (props: {
  row: Row<ProgramRow>;
  user: ICurrentUser;
  onProgramClick: (p: ProgramSummary) => void;
  onProgramUpdate: (data: { original: ProgramRow; updates: ProgramPartialUpdateData[] }) => void;
  onConvertTaskToProgram: (program: ResolvedProgramSummary, task: ProgramTask) => void;
  TasksTable: (props: {
    program: ResolvedProgramSummary;
    values: ResolvedProgramTask[];
    arrayHelpers: TaskArrayHelpers;
    onPopoverOpen: () => void;
    onPopoverClose: () => void;
    onConvertTaskToProgram: (program: ResolvedProgramSummary, task: ProgramTask) => void;
  }) => ReactElement;
  className?: string;
}) => {
  const {
    row,
    user,
    onProgramClick,
    onProgramUpdate,
    onConvertTaskToProgram,
    TasksTable,
    className,
  } = props;

  const onRowClick = () => {
    const {
      type: { id: typeId },
      resolvedTasks,
      ...rest
    } = row.original;
    onProgramClick({
      typeId,
      tasks: [...resolvedTasks],
      campaignId: rest.campaign ? rest.campaign.id : null,
      ...rest,
      status: rest.status.id,
    });
  };

  const arrayHelpers = buildTaskArrayHelper(row.original, onProgramUpdate);

  const onRowKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.key === 'Enter') {
      event.stopPropagation();
      onRowClick();
    }
  };

  const canEdit = useCallback(
    (columnId: string): boolean => {
      const programPermissionHelper = getProgramPermissionHelper(user, row.original);
      return columnId === 'owner'
        ? programPermissionHelper.canChangeProgramOwner()
        : columnId === 'campaign'
        ? programPermissionHelper.canChangeCampaign()
        : programPermissionHelper.canEditRegularField();
    },
    [row.original, user]
  );

  const cells = useMemo(
    () =>
      row.cells
        .filter((x) => !x.column.isGrouped)
        .map((cell, idx) => (
          <div
            data-test={`programs-table__data-cell-${idx}`}
            {...cell.getCellProps()}
            key={cell.getCellProps().key}
            className={clsx('programs-table__cell', `${className ?? ''}`, {
              'programs-table__sticky': idx === 0,
            })}
          >
            {cell.render('Cell', { readOnly: !canEdit(cell.column.id) })}
          </div>
        )),
    [row.cells, canEdit, className]
  );

  const [isPopoverOpen, setIsPopoverOpen] = useState(false);
  useEffect(() => setIsPopoverOpen(false), [row.original.resolvedTasks]);

  const programPermissionHelper = useProgramPermissionHelper(row.original);

  return (
    <>
      <Droppable
        droppableId={getProgramDroppableId(row.original.id)}
        isDropDisabled={
          !programPermissionHelper.canCreateTask() &&
          !programPermissionHelper.canCreateTaskForAnyUser()
        }
      >
        {(provided, droppableSnapshot) => {
          return (
            <div
              className={clsx('programs-table__row', 'programs-table__row--selectable', {
                'programs-table__row--drag-over': droppableSnapshot.isDraggingOver,
              })}
              data-test={`programs-table__row--${row.original.name.trim().replace(/ /g, '-')}`}
              role="button"
              onClick={onRowClick}
              tabIndex={0}
              onKeyDown={onRowKeyDown}
              {...provided.droppableProps}
              {...row.getRowProps()}
              ref={provided.innerRef}
            >
              {cells}
              <div className="invisible">{provided.placeholder}</div>
            </div>
          );
        }}
      </Droppable>
      {row.isExpanded && (
        <div className="programs-table__tasks-border">
          <div
            className={clsx('programs-table__tasks', {
              'programs-table__tasks--frontmost': isPopoverOpen,
            })}
          >
            <TasksTable
              program={row.original}
              values={row.original.resolvedTasks}
              arrayHelpers={arrayHelpers}
              onPopoverOpen={() => setIsPopoverOpen(true)}
              onPopoverClose={() => setIsPopoverOpen(false)}
              onConvertTaskToProgram={onConvertTaskToProgram}
            />
          </div>
        </div>
      )}
    </>
  );
};

const TableRow = (props: {
  isScheduledMode: boolean;
  row: Row<ProgramRow>;
  user: ICurrentUser;
  programTypes: Map<string, ProgramType> | null;
  prepareRow: (row: Row<ProgramRow>) => void;
  onProgramClick: (p: ProgramSummary) => void;
  onCreate: (p: ProgramSummary) => void;
  onProgramUpdate: (data: { original: ProgramRow; updates: ProgramPartialUpdateData[] }) => void;
  onConvertTaskToProgram: (program: ResolvedProgramSummary, task: ProgramTask) => void;
  TasksTable: (props: {
    program: ResolvedProgramSummary;
    values: ResolvedProgramTask[];
    arrayHelpers: TaskArrayHelpers;
    onPopoverOpen: () => void;
    onPopoverClose: () => void;
    onConvertTaskToProgram: (program: ResolvedProgramSummary, task: ProgramTask) => void;
  }) => ReactElement;
}) => {
  const {
    row,
    user,
    onProgramClick,
    onCreate,
    prepareRow,
    programTypes,
    isScheduledMode,
    onProgramUpdate,
    onConvertTaskToProgram,
    TasksTable,
  } = props;

  useEffect(() => {
    if (row.isGrouped && !row.isExpanded) {
      row.toggleRowExpanded(true);
    }
  }, [row]);

  const isGroupedByType = row.groupByID === 'type';

  if (row.isGrouped) {
    const groupedType = programTypes?.get(row.groupByVal);
    return (
      <div
        className={isGroupedByType ? 'mb-5' : ''}
        data-test={`programs-table__grouping--${row.groupByVal}`}
      >
        {isGroupedByType && (
          <div className="mb-2">
            <div
              className="col-6 programs-table__sticky"
              data-test={`programs-table__grouping_header--${row.groupByVal}`}
            >
              {groupedType && (
                <span className="program-row__group-label" style={{ color: groupedType.color }}>
                  {groupedType.name}
                </span>
              )}
            </div>
          </div>
        )}
        <div
          className={clsx('program-rows-group', {
            'program-rows-group--distinctive': isGroupedByType,
          })}
          data-test="program-rows-group"
        >
          {row.subRows.map((s) => {
            prepareRow(s);
            return (
              <TableRow
                key={s.getRowProps().key}
                programTypes={programTypes}
                row={s}
                user={user}
                prepareRow={prepareRow}
                onProgramClick={onProgramClick}
                onCreate={onCreate}
                isScheduledMode={isScheduledMode}
                onProgramUpdate={onProgramUpdate}
                onConvertTaskToProgram={onConvertTaskToProgram}
                TasksTable={TasksTable}
              />
            );
          })}
        </div>
        <GroupedRow
          key={row.getRowProps().key}
          programTypes={programTypes}
          row={row}
          onCreate={onCreate}
          isScheduledMode={isScheduledMode}
        />
      </div>
    );
  }
  return (
    <NotGroupedRow
      key={row.getRowProps().key}
      row={row}
      user={user}
      onProgramClick={onProgramClick}
      onProgramUpdate={onProgramUpdate}
      onConvertTaskToProgram={onConvertTaskToProgram}
      TasksTable={TasksTable}
    />
  );
};

export default TableRow;
