import { DropResult } from '@hello-pangea/dnd';
import { ColumnsConfig } from '../../containers/types';

export const onProgramColumnDragEnd = (
  result: DropResult,
  leftFixedColumns: number,
  visibleColumns: { id: string }[],
  columnsConfig: ColumnsConfig,
  setColumnsConfig: (c: ColumnsConfig) => void
): void => {
  const { destination, source } = result;
  if (!destination) return;
  if (destination.droppableId !== 'columns-headers' && destination.droppableId !== 'columns-list')
    return;
  if (destination.droppableId !== source.droppableId) return;
  if (destination.index === source.index) return;

  let sourceIndex = source.index + leftFixedColumns;
  let destinationIndex = destination.index + leftFixedColumns;
  const sourceId = visibleColumns[sourceIndex].id;
  sourceIndex = columnsConfig.findIndex((col) => col.id === sourceId);
  if (destinationIndex !== 0) {
    const prevId = visibleColumns[destinationIndex].id;
    destinationIndex = columnsConfig.findIndex((col) => col.id === prevId);
  }

  const newCols = columnsConfig.slice();
  const column = newCols.splice(sourceIndex, 1);
  newCols.splice(destinationIndex, 0, column[0]);
  setColumnsConfig(newCols);
};
