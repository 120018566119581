import React, { useState } from 'react';
import { Button } from 'react-bootstrap';
import { BILLING_PLAN_YEAR } from '../billingPlans';
import { ConfirmModal } from '../../commons/components/ConfirmModal';
import { analyticsTrack } from '../../web-analytics/webAnalytics';
import useAnalytics from '../../web-analytics/webAnalyticsContext';
import { AnalyticsEvent } from '../../web-analytics/AnalyticsEvent';
import { AnnualPlanBenefitsWithTimeline } from './AnnualPlanBenefitsWithTimeline';
import { useUpgradeToAnnualPlanMutation } from '../hooks/useUpgradeToAnnualPlanMutation';

export const UpgradeToAnnualPlan = (props: {
  expanded: boolean;
  onExpandedChanged: (expanded: boolean) => void;
  hasPendingPlanChange: boolean;
  changePlanOptions: string[];
}) => {
  const { expanded, onExpandedChanged, hasPendingPlanChange, changePlanOptions } = props;
  const analytics = useAnalytics();
  const [showModal, setShowModal] = useState(false);
  const source = 'billing';
  const { mutate: upgradePlanMutation } = useUpgradeToAnnualPlanMutation({ source });

  return (
    <>
      {hasPendingPlanChange ? (
        <div
          className="upgrade-to-annual__container--collapsed"
          data-test="upgrade-to-annual__reviewing-upgrade-message"
        >
          Your account manager is reviewing and upgrading your plan…
        </div>
      ) : changePlanOptions.findIndex((x) => x === BILLING_PLAN_YEAR.name) < 0 ? null : expanded ? (
        <div
          className="upgrade-to-annual__container--expanded"
          data-test="upgrade-to-annual__container--expanded"
        >
          <AnnualPlanBenefitsWithTimeline />
          <div className="upgrade-to-annual__footer">
            <Button
              variant="primary"
              className="upgrade-to-annual__apply-button"
              onClick={() => {
                setShowModal(true);
                analyticsTrack(analytics, AnalyticsEvent.BILLING_PLAN_UPGRADE_CONFIRMATION_VIEWED, {
                  source,
                });
              }}
              data-test="upgrade-to-annual__show-upgrade-confirmation-button"
            >{`Upgrade to ${BILLING_PLAN_YEAR.displayName} plan`}</Button>
            <Button
              variant="light"
              className="upgrade-to-annual__hide-button"
              onClick={() => onExpandedChanged(false)}
              data-test="upgrade-to-annual__hide-annual-plan-button"
            >
              Hide
            </Button>
          </div>
        </div>
      ) : (
        <div
          className="upgrade-to-annual__container--collapsed"
          data-test="upgrade-to-annual__upgrade-invitation-message"
        >
          <div>
            Get all your loyalty benefits faster (ads, seo, giveaways), save 10%, and increase your
            credit extended with the credit bureaus.
          </div>
          <Button
            variant="primary"
            className="upgrade-to-annual__apply-button"
            onClick={() => {
              onExpandedChanged(true);
              analyticsTrack(analytics, AnalyticsEvent.BILLING_PLAN_ANNUAL_PLAN_VIEWED, {
                source: 'banner',
              });
            }}
            data-test="upgrade-to-annual__see-annual-plan-button"
          >
            {`See ${BILLING_PLAN_YEAR.displayName} plan`}
          </Button>
        </div>
      )}
      {showModal && (
        <ConfirmModal
          title={`You are upgrading to ${BILLING_PLAN_YEAR.displayName} plan`}
          message="We’ll let you know when your account manager reviews and upgrades your plan."
          shouldCloseOnOverlayClick={false}
          shouldCloseOnEsc={true}
          ariaHideApp={false}
          footer={{
            type: 'show',
            applyButton: {
              label: 'Upgrade plan',
              onClick: () => upgradePlanMutation(),
            },
            cancelButton: {
              label: 'Cancel',
              onClick: () => {
                setShowModal(false);
                analyticsTrack(
                  analytics,
                  AnalyticsEvent.BILLING_PLAN_UPGRADE_CONFIRMATION_CANCELLED
                );
              },
            },
          }}
        />
      )}
    </>
  );
};
