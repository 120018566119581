import { CreateProgramArgumentDto } from '../../../growegy-ai/dto';
import {
  NoProgramType,
  ProgramType,
  ResolvedProgramSummary,
  TODO_PROGRAM_STATUS,
} from '../../types';
import { UnknownProgramOwner, User } from '../../../user/types';
import moment from 'moment';
import { resolveProgramType } from '../../util';
import _ from 'lodash';
import { unscheduledEnd, unscheduledStart } from '../../unscheduledProgramUtil';
import { unitOfTime } from 'moment/moment';
import { CreateProgramToolCallResult } from '../../../growegy-ai/types';
import { adjustToLocal } from '../../../util/date-utils';

const isRoundedTo = (date: Date, unitOfTime: unitOfTime.StartOf) =>
  moment(date).valueOf() === moment(date).startOf(unitOfTime).valueOf();
const isMidnight = (date: Date) => isRoundedTo(date, 'day');

const detectWholeDay = (
  startDateText: string | null | undefined,
  endDateText: string | null | undefined
) => {
  if (!startDateText && !endDateText) return true;
  const isMidnightStart =
    !startDateText || startDateText.indexOf('T') < 0 || startDateText.indexOf('T00:00') >= 0;
  const isMidnightEnd =
    !endDateText || endDateText.indexOf('T') < 0 || endDateText.indexOf('T00:00') >= 0;
  return isMidnightStart && isMidnightEnd;
};

const parseDateString = (dateString: string | undefined | null, wholeDay: boolean) =>
  !!dateString && !isNaN(Date.parse(dateString))
    ? dateString.indexOf('Z') >= 0
      ? adjustToLocal(new Date(dateString), wholeDay)
      : new Date(dateString)
    : null;

const createProgram = (
  id: string,
  name: string,
  startDateTime: Date,
  endDateTime: Date,
  wholeDay: boolean,
  type: ProgramType,
  owningUser: User
): ResolvedProgramSummary => ({
  name,
  programKind: 'PROGRAM',
  startDateTime,
  endDateTime,
  startRangeDateTime: startDateTime,
  endRangeDateTime: endDateTime,
  wholeDay,
  type,
  owner: owningUser,
  owningUserId: owningUser?.id ?? null,
  id,
  budget: 0,
  leads: 0,
  status: TODO_PROGRAM_STATUS,
  vendor: '',
  notes: '',
  version: undefined,
  customFields: [],
  resolvedTasks: [],
  actualLeads: null,
  salesforceCampaignId: null,
  campaign: null,
  campaignColor: null,
  endDateInClientRepresentation: false,
});

export const normalizeCreateProgramArgument = (
  arg: CreateProgramArgumentDto,
  programTypes: ProgramType[],
  users: User[]
): ResolvedProgramSummary => {
  const now = new Date();
  const name =
    arg.name && arg.name.trim()
      ? _.truncate(arg.name.trim(), { length: 255, omission: '…' })
      : 'New program';

  const isUnscheduled = !arg.start_date_time && !arg.end_date_time;
  const wholeDay = isUnscheduled
    ? true
    : arg.all_day === true || arg.all_day === false
    ? arg.all_day
    : detectWholeDay(arg.start_date_time, arg.end_date_time);

  const rawStartDate = parseDateString(arg.start_date_time, wholeDay);
  const rawEndDate = parseDateString(arg.end_date_time, wholeDay);
  let startDateTime = isUnscheduled ? unscheduledStart() : rawStartDate ?? now;
  let endDateTime = isUnscheduled
    ? unscheduledEnd()
    : rawEndDate ?? moment(now).add(1, 'day').toDate();
  if (wholeDay) {
    if (!isMidnight(startDateTime)) {
      startDateTime = moment(startDateTime).startOf('day').toDate();
    }
    if (!isMidnight(endDateTime)) {
      endDateTime = moment(endDateTime).startOf('day').toDate();
    }
  } else {
    if (!isRoundedTo(startDateTime, 'minute')) {
      startDateTime = moment(startDateTime).startOf('minute').toDate();
    }
    if (!isRoundedTo(endDateTime, 'minute')) {
      endDateTime = moment(endDateTime).startOf('minute').add(1, 'minute').toDate();
    }
  }
  if (endDateTime.valueOf() <= startDateTime.valueOf()) {
    if (wholeDay) endDateTime = moment(startDateTime).add(1, 'day').toDate();
    else endDateTime = moment(startDateTime).add(1, 'hour').toDate();
  }

  const owningUser =
    users.find((x) => x.id === arg.owner_user_id) ??
    (users.length > 0 ? users[0] : UnknownProgramOwner);
  const type = !!arg.program_type_id
    ? resolveProgramType(arg.program_type_id, false, programTypes)
    : programTypes.length > 0
    ? programTypes[0]
    : NoProgramType;

  return createProgram('', name, startDateTime, endDateTime, wholeDay, type, owningUser);
};

export const resolveCreateProgramToolCallResult = (
  result: CreateProgramToolCallResult,
  programTypes: ProgramType[],
  users: User[]
): ResolvedProgramSummary => {
  const owningUser =
    users.find((x) => x.id === result.owner_user_id) ??
    (users.length > 0 ? users[0] : UnknownProgramOwner);
  const type = !!result.program_type_id
    ? resolveProgramType(result.program_type_id, false, programTypes)
    : programTypes.length > 0
    ? programTypes[0]
    : NoProgramType;

  return createProgram(
    result.id,
    result.name,
    new Date(result.start_date_time),
    new Date(result.end_date_time),
    result.all_day,
    type,
    owningUser
  );
};
