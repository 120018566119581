import { useMemo } from 'react';
import { breakDownLibraryPrompt, LibraryPromptContentPart } from '../../util';
import clsx from 'clsx';
import { PlaceholderMetadataDto, PromptMetadataDto } from '../../dto';

const promptSpanMarginLeft = (part: LibraryPromptContentPart, prev?: LibraryPromptContentPart) =>
  part.isPlaceholder &&
  prev &&
  prev.text.length > 0 &&
  prev.text.substring(prev.text.length - 1) !== ' '
    ? '4px'
    : undefined;

const promptSpanMarginRight = (part: LibraryPromptContentPart, next?: LibraryPromptContentPart) =>
  part.isPlaceholder && next && next.text.length > 0 && next.text[0] !== ' ' && next.text[0] !== '.'
    ? '4px'
    : undefined;

const PromptSpan = ({
  identifier,
  className,
  part,
  prev,
  next,
}: {
  identifier: string;
  part: LibraryPromptContentPart;
  prev?: LibraryPromptContentPart;
  next?: LibraryPromptContentPart;
  className?: string | undefined;
}) => {
  const rows = part.text.split('\n');
  if (rows.length === 1) {
    return (
      <>
        <span
          key={identifier}
          data-test={`prompt-span__${identifier}`}
          className={className}
          style={{
            marginLeft: promptSpanMarginLeft(part, prev),
            marginRight: promptSpanMarginRight(part, next),
          }}
        >
          {part.text}
        </span>
      </>
    );
  }

  return (
    <>
      {rows.map((line, index) => (
        <div
          style={{ display: 'inline' }}
          key={`prompt-span-${identifier}--new-line-wrapper-to-avoid-key-prop-react-issue-${index}`}
        >
          {line && (
            <span
              key={`prompt-span__${identifier}--line-${index}`}
              data-test={`prompt-span__${identifier}--line-${index}`}
              className={className}
              style={{
                marginLeft: promptSpanMarginLeft(part, prev),
                marginRight: promptSpanMarginRight(part, next),
              }}
            >
              {line}
            </span>
          )}
          {index < rows.length - 1 && (
            <br
              data-test={`prompt-span__${identifier}--new-line-${index}`}
              key={`prompt-span__${identifier}--new-line-${index}`}
            />
          )}
        </div>
      ))}
    </>
  );
};

export const PromptWithPlaceholders = (props: {
  libraryPromptContent: string;
  className?: string;
}) => {
  const { libraryPromptContent, className } = props;
  const parts = useMemo(() => breakDownLibraryPrompt(libraryPromptContent), [libraryPromptContent]);
  return (
    <>
      {parts.map((x, i) => {
        return (
          <PromptSpan
            key={`part-${i}`}
            identifier={`part-${i}`}
            className={clsx('growegy-font14', className, {
              'prompt-with-placeholders__placeholder': x.isPlaceholder,
            })}
            part={x}
            prev={parts[i - 1]}
            next={parts[i + 1]}
          />
        );
      })}
    </>
  );
};

export const PromptExample = (props: {
  libraryPromptContent: string;
  metadata?: PromptMetadataDto;
}) => {
  const { libraryPromptContent, metadata } = props;
  const parts = useMemo(() => breakDownLibraryPrompt(libraryPromptContent), [libraryPromptContent]);
  return (
    <>
      {parts.map((x, i) => {
        if (!x.isPlaceholder || !metadata || !metadata.placeholders) {
          return (
            <PromptSpan
              identifier={`part-${i}`}
              key={`part-${i}`}
              className={clsx('growegy-font14 prompt-with-placeholders__text--light500', {
                'prompt-with-placeholders__placeholder': x.isPlaceholder,
              })}
              part={x}
              prev={parts[i - 1]}
              next={parts[i + 1]}
            />
          );
        }

        const meta = metadata.placeholders.find((p) => {
          const promptName = breakDownLibraryPrompt(p.name);
          return promptName.length > 0 && promptName[0].text === x.text;
        });

        if (!meta) {
          return (
            <PromptSpan
              identifier={`part-${i}`}
              key={`part-${i}`}
              className="growegy-font14 prompt-with-placeholders__text--light500 prompt-with-placeholders__placeholder"
              part={x}
              prev={parts[i - 1]}
              next={parts[i + 1]}
            />
          );
        }

        const examplesList: string[] = [];
        flattenAllNonListReqursive([meta], examplesList);
        if (examplesList.length === 1) {
          return (
            <PromptSpan
              identifier={`part-${i}__example-value-${0}`}
              key={`part-${i}__example-value-${0}`}
              className="growegy-font14 prompt-with-placeholders__text--light500 prompt-with-placeholders__placeholder"
              part={{
                text: examplesList[0],
                isPlaceholder: true,
              }}
            />
          );
        }

        return examplesList.map((exampleValue, j) => {
          return (
            <>
              <br data-test={`prompt-span__part-${i}--new-line-${j}`} />
              <PromptSpan
                identifier={`part-${i}--example-dot-${j}`}
                className="growegy-font14 prompt-with-placeholders__text--light500"
                part={{
                  text: ` • `,
                  isPlaceholder: false,
                }}
              />
              <PromptSpan
                identifier={`part-${i}--example-value_${j}`}
                className="growegy-font14 prompt-with-placeholders__text--light500 prompt-with-placeholders__placeholder"
                part={{
                  text: exampleValue,
                  isPlaceholder: true,
                }}
              />
            </>
          );
        });
      })}
    </>
  );
};

const flattenAllNonListReqursive = (metadatas: PlaceholderMetadataDto[], result: string[]) => {
  metadatas.forEach((metadata) => {
    if (metadata.type === 'ListMetadata') {
      flattenAllNonListReqursive(metadata.values, result);
    } else {
      if (metadata.exampleValue !== undefined) {
        result.push(`${metadata.exampleValue}`);
      }
    }
  });
};
