import { CellProps } from './CellProps';
import { ProgramTaskStatus, ResolvedProgramTask } from '../../types';
import { ChangeEvent } from 'react';
import { GrowegyCheckbox } from '../GrowegyCheckbox';
import clsx from 'clsx';
import { recalculateDueDate } from './smartDueDateUtils';

export const TaskStatusCell = ({
  value: origValue,
  cell,
  row,
  arrayHelpers,
  isReadonly,
  programStart,
  programEnd,
  wholeDay,
}: CellProps<ResolvedProgramTask>) => {
  const changeStatus = (e: ChangeEvent<HTMLInputElement>) => {
    const field = cell.column.id as keyof typeof row.original;
    const newStatus: ProgramTaskStatus = e.target.checked ? 'done' : 'to-do';

    const newDueDate = !row.original.dueDateFormula
      ? row.original.dueDateTime
      : recalculateDueDate(
          programStart,
          programEnd,
          wholeDay,
          row.original.dueDateFormula,
          newStatus,
          row.original.dueDateTime
        );
    arrayHelpers.replace(Number(row.id), {
      ...row.original,
      [field]: newStatus,
      dueDateTime: newDueDate,
    });
  };

  return (
    <div className="m-auto d-flex align-items-center justify-content-start">
      <div
        className={clsx('programs-table__editable-cell--squared programs-table__editable-cell p-0')}
        data-test="tasks-table__task-status"
      >
        <GrowegyCheckbox
          defaultChecked={origValue === 'done'}
          dataTestPrefix="task-status-checkbox"
          disabled={isReadonly}
          onChange={changeStatus}
        />
      </div>
    </div>
  );
};
