import { DATA_SERIES_ACTUAL_LEADS, DATA_SERIES_PROJECTED_LEADS, GROUP_BY_TYPE } from '../types';
import { useResolvedGroupingItems } from '../hooks/useResolvedGroupingItems';
import _ from 'lodash';
import { TotalProgramType } from '../../program/types';
import ActualVsProjectedLeadsPieChart from './ActualVsProjectedLeadsPieChart';
import { ProgramMultiFiltersDto } from '../analyticsApi';

const ActualVsProjectedLeadsPieChartList = (props: {
  fromDate: number;
  toDate: number;
  filter: ProgramMultiFiltersDto | null;
}) => {
  const { status, data } = useResolvedGroupingItems({
    fromDate: props.fromDate,
    toDate: props.toDate,
    groupBy: [GROUP_BY_TYPE],
    groupOf: [DATA_SERIES_ACTUAL_LEADS, DATA_SERIES_PROJECTED_LEADS],
    filter: props.filter,
  });
  return (
    <div data-test="actual-projected-leads__pie-chart">
      {status === 'loading' && (
        <div className="alert alert-secondary" role="alert">
          Loading chart...
        </div>
      )}
      {status === 'success' && data && (
        <div className="d-flex flex-wrap analytics__pie-chart-list-container">
          {_.concat(
            [
              {
                key: { type: TotalProgramType, month: null },
                value: {
                  actualLeads: _.sumBy(data, (item) => item.value.actualLeads),
                  projectedLeads: _.sumBy(data, (item) => item.value.projectedLeads),
                },
              },
            ],
            data.sort((item1, item2) => item2.value.projectedLeads - item1.value.projectedLeads)
          ).map((item) => (
            <ActualVsProjectedLeadsPieChart item={item} key={`pie-back-${item.key.type?.id}`} />
          ))}
        </div>
      )}
    </div>
  );
};

export default ActualVsProjectedLeadsPieChartList;
