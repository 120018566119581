import { startCase } from 'lodash';

type ProgramColumnDetails = { displayName: string; shortcut?: string };
const ColumnNamesMap = {
  startDateTime: { displayName: 'Start date', shortcut: 'SD' },
  endDateTime: { displayName: 'End date', shortcut: 'ED' },
  actualLeads: { displayName: 'Actual leads', shortcut: 'AL' },
  leads: { displayName: 'Projected leads', shortcut: 'PL' },
} as { [id: string]: ProgramColumnDetails };

export const getColumnName = (id: string, includeShortcut?: boolean) => {
  const columnDetails = ColumnNamesMap[id];
  if (columnDetails) {
    return includeShortcut && columnDetails.shortcut
      ? `${columnDetails.displayName} (${columnDetails.shortcut})`
      : columnDetails.displayName;
  }
  return startCase(id.replace(/(-text$)|(-number$)|(-date-time$)|(-formula$)/, ''));
};

export const getColumnShortcut = (id: string) => ColumnNamesMap[id]?.shortcut;

export const getColumnId = (id: string) =>
  ColumnNamesMap[id] ? id : id.replace(/(-text$)|(-number$)|(-date-time$)|(-formula$)/, '');

export const getCustomColumnType = (id: string) => {
  const match = id.match(/-((text$)|(number$)|(date-time$)|(formula$))/);
  return match?.[1] as 'text' | 'number' | 'date-time' | 'formula';
};
