import { ArrayHelpers, FieldArray, FieldArrayRenderProps } from 'formik';
import Dropzone, { ErrorCode, FileRejection } from 'react-dropzone';
import { ProgramAttachment } from '../../types';
import { newRenderId } from '../../../util/utils';
import { AttachmentsTable } from './AttachmentsTable';
import { DivButton } from '../../../commons/components/DivButton';
import { DividerIcon, PaperclipIcon } from '../../../assets/icons';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import useAuth from '../../../auth/authContext';
import { PleaseRelogin } from '../../../auth/PleaseRelogin';
import { AttachmentCarousel } from './AttachmentCarousel';
import Modal from 'react-modal';
import { useAttachmentDownloadMutation } from '../../hooks/useAttachmentDownloadMutation';
import useAnalytics from '../../../web-analytics/webAnalyticsContext';
import { analyticsTrack } from '../../../web-analytics/webAnalytics';
import { AnalyticsEvent } from '../../../web-analytics/AnalyticsEvent';
import { useNotification } from '../../../notification/notificationsContext';
import { Info } from '../../../commons/components/Info';
import { hasErrorOfType } from '../../../util/file-utils';

type AttachmentProps = {
  values: ProgramAttachment[];
  id: string;
  hasAddAccess: boolean;
  hasDeleteAccess: boolean;
};

const MAX_FILES = 10;
const MAX_SIZE_MB = 5;
const MAX_SIZE = MAX_SIZE_MB * 1024 * 1024;

export const Attachments = ({ values, id, hasAddAccess, hasDeleteAccess }: AttachmentProps) => {
  const analytics = useAnalytics();
  const [attachError, setAttachError] = useState<'TOO LARGE' | 'TOO MANY' | null>(null);
  const nonDeletedAttachments = useMemo(
    () => values.filter((attachment) => attachment.status !== 'DELETED'),
    [values]
  );

  const nonDeletedAttachmentsAmount = useMemo(
    () => nonDeletedAttachments.length,
    [nonDeletedAttachments]
  );

  useEffect(() => {
    if (attachError === 'TOO MANY' && nonDeletedAttachmentsAmount < MAX_FILES) {
      setAttachError(null);
    }
  }, [nonDeletedAttachmentsAmount, attachError]);

  const handleAddProgramAttachment = (arrayHelper: FieldArrayRenderProps, files: File[]) => {
    const nonDeletedCount = values.filter((x) => x.status !== 'DELETED').length;
    const filesToAdd = files.slice(0, MAX_FILES - nonDeletedCount);
    if (filesToAdd.length < files.length) {
      setAttachError('TOO MANY');
    } else {
      setAttachError(null);
    }
    filesToAdd.forEach((file) => {
      arrayHelper.push({
        id: newRenderId(),
        name: file.name,
        status: 'NEW',
        file,
      });
    });
  };

  const deleteAttachment = useCallback(
    (arrayHelper: ArrayHelpers, attachment: ProgramAttachment) => {
      const idx = values.findIndex((x) => x.id === attachment.id);
      if (idx < 0) return;
      if (attachment.status === 'EXISTED') {
        arrayHelper.replace(idx, {
          ...attachment,
          status: 'DELETED',
        });
      } else {
        arrayHelper.remove(idx);
      }
    },
    [values]
  );

  const { mutate: download } = useAttachmentDownloadMutation();

  const [selectedIndex, setSelectedIndex] = useState<number | null>(null);
  useEffect(() => {
    if (selectedIndex !== null) {
      if (nonDeletedAttachments.length === 0) setSelectedIndex(null);
      else setSelectedIndex(Math.min(nonDeletedAttachments.length - 1, selectedIndex));
    }
  }, [nonDeletedAttachments, selectedIndex]);

  const preview = (attachment: ProgramAttachment) => {
    let index: number | null = nonDeletedAttachments.findIndex((x) => x.id === attachment.id);
    if (index < 0) index = null;
    setSelectedIndex(index);
  };
  const closeAttachmentView = useCallback(() => {
    setSelectedIndex(null);
  }, []);

  const { notifyError } = useNotification();
  const onAttachmentFailed = useCallback(
    (fileRejections: FileRejection[]) => {
      if (hasErrorOfType(fileRejections, ErrorCode.FileTooLarge)) {
        setAttachError('TOO LARGE');
        notifyError({
          notificationMsg: `The file that you tried to attach is too big. The maximum file size is ${MAX_SIZE_MB} MB.`,
        });
      } else if (hasErrorOfType(fileRejections, ErrorCode.TooManyFiles)) {
        setAttachError('TOO MANY');
        notifyError({
          notificationMsg: `You tried to attach too many files. The maximum number of attachments in a program is 10.`,
        });
      }
    },
    [notifyError]
  );

  const {
    state: { user },
  } = useAuth();
  if (!user) return <PleaseRelogin />;

  return (
    <div style={{ marginLeft: '-12px', marginRight: '-12px' }}>
      <FieldArray name="attachments">
        {(arrayHelpers) => (
          <>
            <AttachmentsTable
              values={nonDeletedAttachments}
              arrayHelpers={{
                download: async (a) => {
                  analyticsTrack(analytics, AnalyticsEvent.PROGRAM_ATTACHMENT_DOWNLOADED, {
                    button: 'menu',
                  });
                  await download({ programId: id, attachment: a });
                },
                preview: (a) => preview(a),
                delete: (a) => deleteAttachment(arrayHelpers, a),
              }}
              id={id}
              hasDeleteAccess={hasDeleteAccess}
            />

            {hasAddAccess && (
              <Dropzone
                onDrop={(acceptedFiles) => handleAddProgramAttachment(arrayHelpers, acceptedFiles)}
                multiple={true}
                noClick={true}
                noKeyboard={true}
                maxFiles={MAX_FILES}
                maxSize={MAX_SIZE}
                onDropRejected={onAttachmentFailed}
              >
                {({ getRootProps, getInputProps, open }) => (
                  <div {...getRootProps({ className: 'dropzone' })}>
                    <input {...getInputProps()} data-test="program-edit__input-file-attachment" />
                    <div className="attachments-table__add-btn-wrapper">
                      <DivButton
                        className="attachments-table__add-btn"
                        icon={<PaperclipIcon />}
                        text="Attach new"
                        dataTest="program-edit__attachment-add"
                        onClick={open}
                      />
                      {attachError === null && (
                        <div data-test="program-edit__attachment-info">
                          <Info
                            tooltipText={`Allowed to upload up to ${MAX_FILES} attachments and up to ${MAX_SIZE_MB} MB`}
                            tooltipPlace="right"
                            tooltipWidth={136}
                          />
                        </div>
                      )}
                      {attachError !== null && (
                        <>
                          <DividerIcon style={{ marginLeft: '16px', marginRight: '16px' }} />
                          <span
                            data-test={
                              attachError === 'TOO MANY'
                                ? 'program-edit__error-text--too-many'
                                : 'program-edit__error-text--too-large'
                            }
                            className="growegy-label14--red-500"
                          >
                            {attachError === 'TOO MANY'
                              ? `Not allowed to upload more than ${MAX_FILES} attachments`
                              : `Not allowed to upload files bigger than ${MAX_SIZE_MB} MB`}
                          </span>
                        </>
                      )}
                    </div>
                  </div>
                )}
              </Dropzone>
            )}

            <Modal
              isOpen={nonDeletedAttachments.length > 0 && selectedIndex !== null}
              overlayClassName="attachments-view__overlay"
              className="attachments-view__content"
              onRequestClose={closeAttachmentView}
              testId="attachments-view__dialog"
              ariaHideApp={false}
            >
              {selectedIndex !== null && (
                <AttachmentCarousel
                  programId={id}
                  attachments={nonDeletedAttachments}
                  selectedIndex={Math.min(selectedIndex, nonDeletedAttachments.length - 1)}
                  onDelete={(attachment) => deleteAttachment(arrayHelpers, attachment)}
                  onClose={closeAttachmentView}
                />
              )}
            </Modal>
          </>
        )}
      </FieldArray>
    </div>
  );
};
