import { Dropdown } from 'react-bootstrap';
import React, { useMemo } from 'react';
import { GridIcon } from '../../assets/icons';
import { DivButton } from '../../commons/components/DivButton';
import clsx from 'clsx';

type AnchorProps = React.HTMLProps<HTMLDivElement>;
export type GroupingType = 'No grouping' | 'By campaign';

const getLabel = (period: GroupingType): string => {
  switch (period) {
    case 'No grouping':
      return 'No grouping';
    case 'By campaign':
      return 'By campaign';
    default:
      return '';
  }
};

const CustomToggle = (period: GroupingType) =>
  React.forwardRef<HTMLDivElement, AnchorProps>(({ onClick }, ref) => (
    <div
      className={clsx('programs-grouping__selected', {
        'programs-grouping__selected--highlighted': period === 'By campaign',
      })}
      data-test="programs-grouping"
      ref={ref}
      onClick={(e) => {
        e.preventDefault();
        onClick && onClick(e);
      }}
      style={{ textDecoration: 'none' }}
    >
      <GridIcon className="programs-grouping__selected-icon" />
      <span>{getLabel(period)}</span>
    </div>
  ));

const GroupingItem = ({
  period,
  dataTest,
  value,
  className,
}: {
  period: GroupingType;
  value: GroupingType;
  dataTest: string;
  className: string;
}) => (
  <Dropdown.Item eventKey={period} bsPrefix="programs-grouping__dropdown-item">
    <div data-test={dataTest}>
      <DivButton text={getLabel(period)} isSelected={value === period} className={className} />
    </div>
  </Dropdown.Item>
);

export const ProgramsGroupingSelector = (props: {
  onSelect: (v: GroupingType) => void;
  value: GroupingType;
}) => {
  const { onSelect, value } = props;

  const toggleElement = useMemo(() => CustomToggle(value), [value]);

  return (
    <Dropdown
      onSelect={(eventKey) => {
        if (!eventKey) return;
        onSelect(eventKey as GroupingType);
      }}
      drop="down"
      alignRight={false}
    >
      <Dropdown.Toggle as={toggleElement} />
      <Dropdown.Menu title="">
        <GroupingItem
          period="By campaign"
          value={value}
          dataTest="programs-grouping__item--by-campaign"
          className="programs-grouping__item programs-grouping__item--first"
        />
        <GroupingItem
          period="No grouping"
          value={value}
          dataTest="programs-grouping__item--no-grouping"
          className="programs-grouping__item"
        />
      </Dropdown.Menu>
    </Dropdown>
  );
};
