import useAnalyticsPage from '../../web-analytics/hooks/useAnalyticsPage';
import { Page } from '../../web-analytics/Page';
import { Button } from 'react-bootstrap';
import React from 'react';
import useApi from '../../api/backendApiContext';
import { analyticsTrack } from '../../web-analytics/webAnalytics';
import { AnalyticsEvent } from '../../web-analytics/AnalyticsEvent';
import useAnalytics from '../../web-analytics/webAnalyticsContext';
import { useMutation } from '@tanstack/react-query';

import { openUrlInNewTab } from '../../util/utils';

export const BillingManagement = () => {
  useAnalyticsPage(Page.BILLING);
  const analytics = useAnalytics();
  const { billingApi } = useApi();

  const openBillingPortal = async () => {
    const billingPortalSession = await billingApi.createBillingPortalSession();
    logger.info('Redirecting to billing portal');

    openUrlInNewTab(billingPortalSession.url, {
      analytics,
      analyticsEvent: AnalyticsEvent.BILLING_PORTAL_OPENED,
    });
  };

  const {
    isLoading,
    error,
    mutate: openBillingPortalMutation,
  } = useMutation(openBillingPortal, {
    onError: () => {
      analyticsTrack(analytics, AnalyticsEvent.BILLING_PORTAL_FAILED);
    },
  });

  return (
    <div>
      <>
        {error && (
          <div
            data-test="calendar__general-loading-error"
            className="alert alert-warning"
            role="alert"
          >
            Billing portal cannot be opened at this time, please contact support at{' '}
            <a href="mailto:support@growegy.com">support@growegy.com</a>
          </div>
        )}
        <div>
          <Button
            type="submit"
            variant="primary"
            className="button-apply"
            data-test="program-type-edit__apply"
            onClick={() => openBillingPortalMutation()}
            disabled={isLoading}
          >
            Check invoices and make payments
          </Button>
        </div>
      </>
    </div>
  );
};
