/* eslint-disable react-hooks/rules-of-hooks */
import { ProgramPartialUpdateField, ProgramRow } from '../../types';
import { ChangeEvent, MouseEvent, useEffect, useRef, useState } from 'react';
import clsx from 'clsx';
import { CellProps } from './ProgramCells';
import Linkify from 'react-linkify';

const StringCell =
  (dataTest: string, field: ProgramPartialUpdateField) =>
  ({
    onProgramUpdate,
    onRowEditChange,
    value: origValue,
    row,
    readOnly,
  }: CellProps<ProgramRow, string>) => {
    const [value, setValue] = useState<string>(origValue ?? '');
    const [editing, setEditing] = useState(false);
    const inputRef = useRef<HTMLInputElement>(null);

    useEffect(() => {
      setValue(origValue);
    }, [origValue]);

    useEffect(() => {
      if (!inputRef.current) return;
      inputRef.current?.focus();
    }, [editing]);

    function handleEditClick(e: MouseEvent<HTMLDivElement>) {
      if (row.isGrouped) return;
      e.stopPropagation();
      if (readOnly) return;
      if (editing) return;
      setEditing(true);
      onRowEditChange(true);
    }

    function handleChange(e: ChangeEvent<HTMLInputElement>) {
      const { value } = e.target;
      setValue(value);
    }

    function confirm() {
      if (value === origValue) {
        cancel();
        return;
      }

      if (
        field.type === 'StaticField' &&
        (field.fieldName === 'name' ||
          field.fieldName === 'vendor' ||
          field.fieldName === 'salesforceCampaignId')
      ) {
        onProgramUpdate({
          original: row.original,
          updates: [{ field: field.fieldName, value }],
        });
      } else if (field.type === 'ArrayFieldItem' && field.arrayField === 'customFields') {
        onProgramUpdate({
          original: row.original,
          updates: [
            {
              field: 'customFields',
              customFieldName: field.arrayItemValue.name,
              customFieldData: {
                type: 'text',
                value: value,
              },
            },
          ],
        });
      }
      setEditing(false);
      onRowEditChange(false);
    }

    function cancel() {
      setValue(origValue);
      setEditing(false);
      onRowEditChange(false);
    }

    return (
      <div
        data-test={dataTest}
        onClick={handleEditClick}
        onKeyDown={(e) => {
          if (editing) e.stopPropagation();
        }}
      >
        {editing ? (
          <input
            ref={inputRef}
            className="programs-table__cell-input"
            value={value}
            onBlur={confirm}
            onChange={handleChange}
            onKeyDown={(e) => {
              if (e.key === 'Escape') cancel();
              if (e.key === 'Enter') confirm();
            }}
            readOnly={readOnly}
          />
        ) : (
          <div
            className={clsx(
              'text-truncate programs-table__editable-cell programs-table__editable-cell--with-whitespace',
              {
                'programs-table__cell-edit-disabled': row.isGrouped || readOnly,
              }
            )}
          >
            <Linkify
              componentDecorator={(decoratedHref, decoratedText, key) => (
                <a target="blank" href={decoratedHref} key={key}>
                  {decoratedText}
                </a>
              )}
            >
              {value}
            </Linkify>
          </div>
        )}
      </div>
    );
  };

export default StringCell;
