import { useRef, useState } from 'react';
import { Overlay, Popover } from 'react-bootstrap';
import { DisplaySettingsIcon } from '../../../assets/icons';
import CalendarEventSelector from './CalendarEventSelector';
import { ResolvedProgramTask } from '../../types';
import CalendarColumnSelector from './CalendarColumnSelector';

interface Props {
  onShowHideColumn: (id: string | null, visible: boolean) => void;
  columns: { id: string; visible: boolean }[];
  showCampaigns: boolean;
  onShowHideCampaigns: (visible: boolean) => void;
  showTasks: boolean;
  onShowHideTasks: (visible: boolean) => void;
  taskFields: { id: keyof ResolvedProgramTask; title: string; visible: boolean }[];
  onShowTaskField: (id: keyof ResolvedProgramTask, visible: boolean) => void;
  isDisplayShowCampaigns: boolean;
}

const CalendarDisplaySettings = ({
  columns,
  onShowHideColumn,
  showCampaigns,
  onShowHideCampaigns,
  showTasks,
  onShowHideTasks,
  taskFields,
  onShowTaskField,
  isDisplayShowCampaigns,
}: Props) => {
  const [show, setShow] = useState(false);
  const target = useRef(null);

  return (
    <>
      <div
        className="calendar-display-settings__plus"
        data-test="calendar-display-settings__plus"
        ref={target}
        role="button"
        aria-label="Show columns"
        tabIndex={0}
        onClick={() => setShow(true)}
        onKeyDown={(event) => {
          if (event.key === 'Enter') setShow(true);
        }}
      >
        <DisplaySettingsIcon className="calendar-display-settings__icon" />
        <div>View</div>
      </div>
      <Overlay
        show={show}
        target={target.current}
        placement="bottom-start"
        rootClose
        onHide={() => setShow(false)}
      >
        <Popover id="popover-contained" className="calendar-display-settings__popover">
          <Popover.Content
            className="calendar-display-settings__content"
            data-test="calendar-display-settings__content"
          >
            <CalendarEventSelector
              showCampaigns={showCampaigns}
              onShowHideCampaigns={onShowHideCampaigns}
              showTasks={showTasks}
              onShowHideTasks={onShowHideTasks}
              taskFields={taskFields}
              onShowTaskField={onShowTaskField}
              isDisplayCampaigns={isDisplayShowCampaigns}
            />
            <CalendarColumnSelector onShowHideColumn={onShowHideColumn} columns={columns} />
          </Popover.Content>
        </Popover>
      </Overlay>
    </>
  );
};

export default CalendarDisplaySettings;
