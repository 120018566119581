import ActualVsProjectedLeadsLineChart from '../components/ActualVsProjectedLeadsLineChart';
import React, { useMemo, useState } from 'react';
import moment from 'moment';
import ActualVsProjectedLeadsPieChartList from '../components/ActualVsProjectedLeadsPieChartList';
import ActualLeadsByMonthAndTypeBar from '../components/ActualLeadsByMonthAndTypeBar';
import useAnalyticsPage from '../../web-analytics/hooks/useAnalyticsPage';
import { Page } from '../../web-analytics/Page';
import {
  DateRange,
  DateRangeSelector,
  getDateRange,
  PeriodDateRange,
} from '../../commons/components/DateRangeSelector';
import { useLocalStorage } from '@rehooks/local-storage';
import { AnalyticsLocalStorageKey, CalendarLocalStorageKey } from '../../program/util';
import { ProgramsMultiFilter } from '../../program/components/ProgramsMultiFilter';
import { analyticsTrack } from '../../web-analytics/webAnalytics';
import { AnalyticsEvent } from '../../web-analytics/AnalyticsEvent';
import { SelectedFilter } from '../../multifilter/programFiltering';
import { MultiFilterRowsPersistable } from '../../multifilter/types';
import { ResolvedProgramSummary } from '../../program/types';
import useAnalytics from '../../web-analytics/webAnalyticsContext';
import {
  ProgramFilterDtoStaticFields,
  ProgramMultiFilterDto,
  ProgramMultiFiltersDto,
} from '../analyticsApi';
import useAuth from '../../auth/authContext';
import { PleaseRelogin } from '../../auth/PleaseRelogin';
import { Permission } from '../../auth/permissions';

const filterToDto = (filter: SelectedFilter | null): ProgramMultiFiltersDto | null => {
  if (!filter) return null;
  const { conjunction } = filter.filter;
  const filters: ProgramMultiFilterDto[] = filter.filter.filters.flatMap((row) => {
    const { filterValue, selectedFilterName, selectedFieldType, selectedFieldIdentity } = row;
    if (selectedFieldIdentity.type === 'ArrayFieldItem') {
      return selectedFieldIdentity.arrayField === 'customFields'
        ? [
            {
              selectedFieldIdentity: {
                type: 'ArrayFieldItem',
                arrayField: 'customFields',
                arrayItemValue: selectedFieldIdentity.arrayItemValue,
              },
              selectedFieldType,
              selectedFilterName,
              filterValue,
            } as ProgramMultiFilterDto,
          ]
        : [];
    }
    const fieldName = ProgramFilterDtoStaticFields.find(
      (f) => f === selectedFieldIdentity.fieldName
    );
    return fieldName
      ? [
          {
            selectedFieldIdentity: { type: 'StaticField', fieldName },
            selectedFieldType,
            selectedFilterName,
            filterValue,
          } as ProgramMultiFilterDto,
        ]
      : [];
  });
  return { conjunction, filters };
};

const AnalyticsHeader = (props: { text: string }) => (
  <div className="analytics__chart-header">
    {props.text} <span className="analytics__header-sub-title">(actual vs projected leads)</span>
  </div>
);

const Analytics = () => {
  useAnalyticsPage(Page.ANALYTICS);
  const analytics = useAnalytics();

  const {
    state: { user },
  } = useAuth();

  const [periodDateRange, setPeriodDateRange] = useLocalStorage<PeriodDateRange>(
    `${AnalyticsLocalStorageKey}periodDateRange`,
    {
      period: 'Quarter',
      currentDate: moment(new Date()).startOf('month').subtract(2, 'months').toDate(),
    }
  );

  const localAsUtc = (dateValue: number) => {
    const date = new Date(dateValue);
    return Date.UTC(date.getFullYear(), date.getMonth(), date.getDate());
  };
  const [dateRange, setDateRange] = useState(() => {
    const defaultDateRange = getDateRange(periodDateRange);
    return {
      from: defaultDateRange.firstDay.valueOf(),
      to: defaultDateRange.lastDay.valueOf(),
    };
  });
  const [filter, setFilter] = useLocalStorage<SelectedFilter | null>(
    `${CalendarLocalStorageKey}appliedFilter`,
    null
  );
  const filterDto = useMemo<ProgramMultiFiltersDto | null>(() => filterToDto(filter), [filter]);

  const [persistedFilters, setPersistedFilters] = useLocalStorage<
    MultiFilterRowsPersistable<ResolvedProgramSummary>[]
  >(`${CalendarLocalStorageKey}savedFilters`, []);
  const fromUtcDate = localAsUtc(dateRange.from);
  const toUtcDate = localAsUtc(dateRange.to);

  if (!user) return <PleaseRelogin />;

  const onChanged = (newPeriodDateRange: PeriodDateRange, newDateRange: DateRange) => {
    setPeriodDateRange(newPeriodDateRange);
    if (
      newDateRange.firstDay.valueOf() !== dateRange.from ||
      newDateRange.lastDay.valueOf() !== dateRange.to
    ) {
      setDateRange({
        from: newDateRange.firstDay.valueOf(),
        to: newDateRange.lastDay.valueOf(),
      });
    }
  };

  return (
    <div className="analytics__container">
      <div className="analytics__header-container">
        <div className="analytics__date-range-selector">
          <DateRangeSelector
            showDayPeriod={false}
            showCustomPeriod={true}
            periodDateRange={periodDateRange}
            onChanged={onChanged}
          />
        </div>
        <div className="calendar-toolbar__filter-container">
          <ProgramsMultiFilter
            appliedFilter={filter}
            persistedFilters={persistedFilters}
            applyFilter={(f) => {
              analyticsTrack(analytics, AnalyticsEvent.PROGRAM_FILTER_APPLIED);
              setFilter(f);
            }}
            persistFilters={setPersistedFilters}
            noFormula={true}
          />
        </div>
      </div>
      <div className="analytics__body-container">
        <div className="analytics__body-offset-container">
          {user.has(Permission.GET_DATA_SERIES) && (
            <div>
              <AnalyticsHeader text="Total · Trend" />
              <ActualVsProjectedLeadsLineChart
                fromDate={fromUtcDate}
                toDate={toUtcDate}
                filter={filterDto}
              />
            </div>
          )}
          {user.has(Permission.GET_GROUPED_DATA) && (
            <>
              <div className="analytics__pie-charts-container">
                <AnalyticsHeader text="Total · By program type" />
                <ActualVsProjectedLeadsPieChartList
                  fromDate={fromUtcDate}
                  toDate={toUtcDate}
                  filter={filterDto}
                />
              </div>
              <div className="analytics__bar-chart-container">
                <AnalyticsHeader text="Monthly · By program type" />
                <ActualLeadsByMonthAndTypeBar
                  fromDate={fromUtcDate}
                  toDate={toUtcDate}
                  filter={filterDto}
                />
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default Analytics;
