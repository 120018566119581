import { Navigate, Route, Routes } from 'react-router-dom';
import { RequireAuth } from './CheckedAuthRoute';
import { AppRoutes } from './AppRoutes';
import ProgramCalendar from '../program/containers/ProgramCalendar';
import Settings from '../settings/containers/Settings';
import Programs from '../program/containers/Programs';
import AuthContainer from '../auth/containters/authContainer';
import ProgramDeepLink from '../program/containers/ProgramDeepLink';
import { Recommendations } from '../recommendations/containers/Recommendations';
import AnalyticsTabs from '../analytics/containers/AnalyticsTabs';
import NotFound from './NotFound';
import { GrowegyAI } from '../growegy-ai/containers/GrowegyAI';
import { readCommonConfig } from '../commons/config';
import { RedirectToUrl } from '../commons/components/RedirectToUrl';
import { useIsTabletOrLarger } from '../responsive/hooks/hooks';

export default function AppRouter() {
  const commonConfig = readCommonConfig();
  const isTabletOrLarger = useIsTabletOrLarger();
  return (
    <div className="app-main">
      <Routes>
        <Route
          path={AppRoutes.Home}
          element={
            <RequireAuth path={AppRoutes.Home}>
              {isTabletOrLarger ? <ProgramCalendar /> : <Settings />}
            </RequireAuth>
          }
        />
        <Route
          path={AppRoutes.Calendar}
          element={
            <RequireAuth path={AppRoutes.Calendar}>
              <ProgramCalendar />
            </RequireAuth>
          }
        />
        <Route
          path={AppRoutes.Program.programs}
          element={
            <RequireAuth path={AppRoutes.Program.programs}>
              <Programs />
            </RequireAuth>
          }
        />
        <Route
          path={AppRoutes.Program.deeplink}
          element={
            <RequireAuth path={AppRoutes.Program.deeplink}>
              <ProgramDeepLink />
            </RequireAuth>
          }
        />
        <Route
          path={AppRoutes.Analytics}
          element={
            <RequireAuth path={AppRoutes.Analytics}>
              <AnalyticsTabs />
            </RequireAuth>
          }
        />
        <Route
          path={AppRoutes.GrowegyAI}
          element={
            <RequireAuth path={AppRoutes.GrowegyAI}>
              <GrowegyAI />
            </RequireAuth>
          }
        />

        <Route
          path={AppRoutes.Recommendations}
          element={
            <RequireAuth path={AppRoutes.Recommendations}>
              <Recommendations />
            </RequireAuth>
          }
        />
        <Route
          path={AppRoutes.Settings}
          element={
            <RequireAuth path={AppRoutes.Settings}>
              <Settings />
            </RequireAuth>
          }
        />
        <Route
          path={AppRoutes.Billing}
          element={
            <RequireAuth path={AppRoutes.Billing}>
              <Settings />
            </RequireAuth>
          }
        />
        <Route
          path={AppRoutes.Auth.signIn}
          element={
            <RequireAuth path={AppRoutes.Auth.signIn}>
              <AuthContainer tab="SIGN_IN" />
            </RequireAuth>
          }
        />
        <Route
          path={AppRoutes.Auth.signUp}
          element={
            <RequireAuth path={AppRoutes.Auth.signUp}>
              {commonConfig.inAppSignup ? (
                <AuthContainer tab="SIGN_UP" />
              ) : commonConfig.externalSignupUrl ? (
                <RedirectToUrl url={commonConfig.externalSignupUrl} />
              ) : (
                <></>
              )}
            </RequireAuth>
          }
        />
        <Route
          path={AppRoutes.Auth.signOut}
          element={
            <RequireAuth path={AppRoutes.Auth.signOut}>
              <Navigate
                to={{
                  pathname: AppRoutes.Home,
                }}
              />
            </RequireAuth>
          }
        />
        <Route
          path={AppRoutes.NotFound}
          element={
            <RequireAuth path={AppRoutes.NotFound}>
              <NotFound />
            </RequireAuth>
          }
        />
        <Route
          path="*"
          element={
            <Navigate
              to={{
                pathname: AppRoutes.NotFound,
              }}
              state={{}}
            />
          }
        />
      </Routes>
    </div>
  );
}
