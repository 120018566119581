import React, { MouseEvent, ReactElement } from 'react';
import clsx from 'clsx';
import { TextAndIcon } from './TextAndIcon';

export const DivButton = ({
  icon,
  text,
  isSelected,
  onClick,
  textDataTest,
  dataTest,
  small,
  className,
  isGray,
  menu,
  ref,
}: {
  text?: string;
  icon?: ReactElement;
  isSelected?: boolean;
  onClick?: (e: MouseEvent<HTMLDivElement>) => void;
  textDataTest?: string;
  dataTest?: string;
  small?: boolean;
  className?: string;
  isGray?: boolean;
  menu?: ReactElement;
  ref?: React.ForwardedRef<HTMLDivElement>;
}) => (
  <div
    onClick={onClick}
    data-test={dataTest}
    className={clsx(`div-button ${className ?? ''}`, {
      'div-button--selected': isSelected,
      'div-button--small': small,
      'div-button--gray': isGray,
    })}
    ref={ref}
  >
    <TextAndIcon
      text={text}
      icon={icon}
      textDataTest={textDataTest}
      small={small}
      hasMenu={!!menu}
    />
    {!!menu && <div className={clsx('div-button__menu')}>{menu}</div>}
  </div>
);
