import { AnalyticsEvent } from '../../web-analytics/AnalyticsEvent';
import useAnalytics from '../../web-analytics/webAnalyticsContext';
import { useEffect, useRef } from 'react';
import { errorToLoggingString } from '../../util/utils';
import { analyticsTrack } from '../../web-analytics/webAnalytics';

export const useQueryErrorAnalyticsPublisher = (
  queryError: unknown,
  request: string,
  analyticsEvent: AnalyticsEvent
) => {
  const analytics = useAnalytics();
  const errorRef = useRef<string>('');

  useEffect(() => {
    if (queryError) {
      const errString = errorToLoggingString(queryError);
      if (errorRef.current !== errString) {
        errorRef.current = errString;
        analyticsTrack(analytics, analyticsEvent, {
          request,
          error: errString,
        });
        const title = `${analyticsEvent.entity} ${analyticsEvent.action}`;
        const attributes = { request, error: errString };
        logger.error(title, JSON.stringify(attributes));

        setTimeout(() => {
          logger.pushAlertEvent(title, attributes);
          logger.error(title, JSON.stringify(attributes));
        }, 5000);
      }
    }
  }, [analytics, analyticsEvent, queryError, request]);
};
