import useApi from '../../../api/backendApiContext';
import { useQuery } from '@tanstack/react-query';
import { useQueryCacheManager } from '../../../hooks/useQueryCacheManager';
import useAuth from '../../../auth/authContext';
import { ReactQueryConstants } from '../../../commons/constants';
import { QueryStatus } from '../../../commons/components/QueryStatus';
import { ChatType } from '../../dto';

export const useFetchedChats = (chatType: ChatType) => {
  const { growegyAIApi } = useApi();
  const {
    state: { user },
  } = useAuth();
  const queryCacheManager = useQueryCacheManager();
  const queryKey = queryCacheManager.getChatsCacheKey(chatType);
  const query = useQuery(
    queryKey,
    async () => (user ? growegyAIApi.getChats(user?.userId, chatType) : []),
    {
      staleTime: ReactQueryConstants.chatsStaleTime,
      refetchInterval: ReactQueryConstants.chatsRefetchInterval,
      refetchOnWindowFocus: true,
    }
  );
  const queryStatusComponent = (
    <QueryStatus
      queryKey={queryKey}
      queryStatus={query.status}
      dataTestPrefix="chats"
      failedToLoadText="Something went wrong while loading the chats."
    />
  );

  return { query, queryStatusComponent };
};
