import { Analytics, AnalyticsBrowser } from '@segment/analytics-next';
import React, { useEffect, useState } from 'react';
import { segmentEnabled, segmentWriteKey } from '../config';

const sleep = async (ms: number) => new Promise((resolve) => setTimeout(resolve, ms));

type WebAnalyticsContextProps = {
  instance?: Analytics;
};

const WebAnalyticsContext = React.createContext<WebAnalyticsContextProps>({
  instance: undefined,
});

export const WebAnalyticsProvider = (props: React.PropsWithChildren<{}>) => {
  const [analytics, setAnalytics] = useState<Analytics | undefined>(undefined);

  useEffect(() => {
    if (segmentEnabled) {
      logger.info('Loading analytics');
      const loadAnalyticsImpl = async () => {
        const [loadedAnalytics] = await AnalyticsBrowser.load({
          writeKey: segmentWriteKey,
        });
        logger.info('Analytics loaded');
        setAnalytics(loadedAnalytics);
      };

      const loadAnalytics = async () => {
        await loadAnalyticsImpl().catch(async (err) => {
          logger.error('Error while loading analytics, will try again: ', err);
          await sleep(1000);
          await loadAnalyticsImpl().catch((err) =>
            logger.error('Error while loading analytics for the second time: ', err)
          );
        });
      };
      loadAnalytics();
    } else {
      logger.info('Analytics disabled, skipping initialization.');
    }
  }, []);
  const value = {
    instance: analytics,
  };
  logger.info(`Analytics initialized: ${!!analytics}`);
  return <WebAnalyticsContext.Provider value={value} {...props} />;
};

const useAnalytics = () => {
  const context = React.useContext(WebAnalyticsContext);
  if (!context) {
    throw new Error(`useAnalytics must be used within an WebAnalyticsContext`);
  }
  return context.instance;
};

export default useAnalytics;
