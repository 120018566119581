import { Button, Form, Modal } from 'react-bootstrap';
import React from 'react';
import { Prompt, PromptCategory, PromptMark } from '../../types';
import { hexToRGB } from '../../../program/util';
import { PROGRAM_TYPE_BACKGROUND_ALPHA } from '../../../program-type/util';
import { DivButton } from '../../../commons/components/DivButton';
import { CloseIcon, DividerIcon } from '../../../assets/icons';
import { ProgramType } from '../../../program/types';
import { categoryColorAsProgramType, getPromptColors } from '../../util';
import { Field, Formik } from 'formik';
import {
  PromptWithPlaceholders,
  PromptExample,
} from '../../library/components/PromptWithPlaceholders';
import { PromptCategories } from '../../library/components/PromptCategoryBreadcrumb';
import { PromptMarkButton } from '../../library/components/PromptMarkButton';

type PromptPreviewProps = {
  showModal: boolean;
  prompt: Prompt;
  promptMark: PromptMark | null;
  handleHideClick: () => void;
  handleApplyClick: (prompt: Prompt) => void;
  onPromptMark: (mark: number) => Promise<void>;
  existedTypes: ProgramType[];
  existedCategories: PromptCategory[];
};

export const PromptPreview = ({
  showModal,
  prompt,
  promptMark,
  handleHideClick,
  handleApplyClick,
  onPromptMark,
  existedTypes,
  existedCategories,
}: PromptPreviewProps) => {
  const { firstColor, secondColor } = getPromptColors(prompt, existedTypes, existedCategories);
  const firstRgba = hexToRGB(firstColor, PROGRAM_TYPE_BACKGROUND_ALPHA);
  const secondRgba = secondColor ? hexToRGB(secondColor, PROGRAM_TYPE_BACKGROUND_ALPHA) : null;

  const background = secondRgba
    ? `linear-gradient(90deg, ${firstRgba} 0%, ${secondRgba} 100%)`
    : firstRgba;

  const promptCategories: PromptCategory[] = prompt.categories.map((c) => {
    const color = categoryColorAsProgramType({ name: c }, existedTypes, existedCategories);
    const icon =
      existedCategories.find((x) => x.name.toLowerCase() === c.toLowerCase())?.icon ?? null;
    return { color, icon, name: c };
  });

  return (
    <Modal
      show={showModal}
      onHide={handleHideClick}
      animation={false}
      dialogClassName="program-type-modal"
      centered={true}
    >
      <Formik
        initialValues={prompt}
        onSubmit={(t) => handleApplyClick({ ...t })}
        enableReinitialize={true}
      >
        {() => (
          <Form>
            <Modal.Header
              data-test="prompt-preview__header"
              className="prompt-preview__header"
              style={{ background }}
            >
              <div className="dialog-header__menu justify-content-between">
                <PromptCategories categories={promptCategories} />
                <div className="d-flex align-items-center">
                  <PromptMarkButton
                    className="prompt-mark-button"
                    promptMark={promptMark}
                    onClick={async (e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      const newVal = ((promptMark?.mark ?? 0) + 1) % 2;
                      await onPromptMark(newVal);
                    }}
                  />
                  <div style={{ margin: '0 12px' }}>
                    <DividerIcon />
                  </div>
                  <DivButton
                    icon={<CloseIcon />}
                    onClick={handleHideClick}
                    className="dialog-header__button"
                    dataTest="prompt-preview__close"
                  />
                </div>
              </div>
              <Form.Group controlId="name" className="m-0 w-100">
                <Field
                  type="text"
                  name="title"
                  value={prompt.title}
                  disabled={true}
                  data-test="prompt-preview__title"
                  className="prompt-preview__input-title"
                />
              </Form.Group>
            </Modal.Header>

            <Modal.Body className="prompt-preview__body">
              <div>
                <div className="prompt-preview__title">Prompt</div>
                <div
                  className="prompt-preview__body-paragraph"
                  data-test="prompt-preview__body-paragraph--content"
                >
                  <PromptWithPlaceholders
                    libraryPromptContent={prompt.content}
                    className="prompt-with-placeholders__text--light900"
                  />
                </div>
              </div>
              <div>
                <div className="prompt-preview__title">Example</div>
                <div
                  className="prompt-preview__body-paragraph"
                  data-test="prompt-preview__body-paragraph--example"
                >
                  <PromptExample libraryPromptContent={prompt.content} metadata={prompt.metadata} />
                </div>
              </div>
            </Modal.Body>
            <Modal.Footer className="prompt-preview__footer">
              <>
                <Button
                  variant="light"
                  className="button-cancel"
                  onClick={handleHideClick}
                  data-test="prompt-preview__cancel"
                >
                  Cancel
                </Button>
                <Button
                  variant="primary"
                  className="button-apply"
                  onClick={() => handleApplyClick(prompt)}
                  data-test="prompt-preview__apply"
                >
                  Start new chat
                </Button>
              </>
            </Modal.Footer>
          </Form>
        )}
      </Formik>
    </Modal>
  );
};
