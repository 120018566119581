import { useState } from 'react';
import { object, string } from 'yup';
import { Form, Formik } from 'formik';
import { confirmCodeRequirements } from '../validationConstants';
import AuthInput from './AuthInput';
import useAnalyticsPage from '../../web-analytics/hooks/useAnalyticsPage';
import { Page } from '../../web-analytics/Page';
import useAnalytics from '../../web-analytics/webAnalyticsContext';
import { analyticsTrack } from '../../web-analytics/webAnalytics';
import { AnalyticsEvent } from '../../web-analytics/AnalyticsEvent';
import { Button } from 'react-bootstrap';
import { printAuthErrorMessage } from '../utils';

const validationSchema = object({
  code: string()
    .required('Please enter a confirmation code sent to your email')
    .matches(confirmCodeRequirements.pattern, confirmCodeRequirements.explainMessage),
});

const ConfirmCode = (props: {
  onConfirm: (code: string) => Promise<void>;
  resend: () => Promise<void>;
}) => {
  useAnalyticsPage(Page.CONFIRMATION_CODE);
  const analytics = useAnalytics();

  const { onConfirm, resend } = props;

  const [resendError, setResendError] = useState<string | null>(null);

  const [isResendInProgress, setResendInProgress] = useState<boolean>(false);

  const onResend = async (): Promise<void> => {
    try {
      setResendInProgress(true);
      await resend();
      analyticsTrack(analytics, AnalyticsEvent.USER_SIGN_UP_EMAIL_VERIFICATION_RESENT);
    } catch (err) {
      setResendError(
        err && err.toString ? err.toString() : 'Something went wrong, please try again later.'
      );
    } finally {
      setResendInProgress(false);
    }
  };

  return (
    <Formik
      initialValues={{ code: '' }}
      validationSchema={validationSchema}
      onSubmit={async (values, formikHelpers) => {
        formikHelpers.setStatus(null);
        await onConfirm(values.code).catch((err) => {
          formikHelpers.setStatus({ error: err });
          analyticsTrack(analytics, AnalyticsEvent.USER_SIGN_UP_EMAIL_VERIFICATION_FAILED);
        });
      }}
    >
      {({ touched, errors, isSubmitting, status }) => (
        <Form>
          <div className="auth-dialog">
            <div className="auth-dialog__header">
              <div className="auth-dialog__title">Enter your verification code</div>
              <div className="auth-dialog__subtitle">We sent it to your email.</div>
            </div>
            <div className="auth-dialog__fields">
              <div className="auth-dialog__field">
                <AuthInput
                  type="text"
                  name="code"
                  autoComplete="one-time-code"
                  placeholder="Enter code"
                  dataTest="confirm-code__code"
                  invalid={!!touched.code && !!errors.code}
                />
              </div>
            </div>
            <div className="auth-dialog__info growegy-title14">
              {status && status.error && (
                <div className="alert-danger fade show">{printAuthErrorMessage(status.error)}</div>
              )}
              {resendError && <div className="alert-danger fade show">{resendError}</div>}
            </div>
            <div className="auth-dialog__footer justify-content-between">
              <Button
                type="button"
                className="button-apply"
                disabled={isResendInProgress}
                onClick={async () => onResend()}
                data-test="confirm-code__resend"
              >
                Resend code
              </Button>
              <Button
                type="submit"
                className="button-apply"
                disabled={isSubmitting}
                data-test="confirm-code__submit"
              >
                Confirm
              </Button>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default ConfirmCode;
