import _ from 'lodash';
import { useRef } from 'react';
import { ProgramForEditResult, ProgramSummary } from '../types';
import { useMemoCompare } from '../../hooks/useMemoCompare';
import { useResolvedProgram } from './useResolvedProgram';
import { resolveProgramSummaryProgramType } from '../util';

const useProgramMemoCompare = (s: ProgramSummary): ProgramSummary =>
  useMemoCompare<ProgramSummary>(s, (a, b) => a.id === b.id && a.id.length > 0);

const isExist = (s: ProgramSummary) => s.id.length > 0;

export const useResolvedProgramOnceForDialog = (props: {
  summary: ProgramSummary;
}): ProgramForEditResult => {
  const { summary } = props;
  const freshSummary = useProgramMemoCompare(summary);
  const memoized = useRef<ProgramForEditResult | null>(null);

  const anotherProgramHasCome = memoized.current?.program.data?.id !== summary.id;

  const resolvedProgramQuery = useResolvedProgram({
    summary,
    enabled: isExist(freshSummary) && anotherProgramHasCome,
  });

  const { program, attachments, programType, programTypes, programUsers, campaign, refetch } =
    resolvedProgramQuery;

  if (!isExist(summary)) {
    return {
      program: {
        data: _.cloneDeep(summary),
        status: 'success',
        fetchStatus: 'idle',
        error: null,
        isStale: false,
      },
      attachments: {
        data: [],
        status: 'success',
        fetchStatus: 'idle',
        error: null,
        isStale: false,
      },
      programType: {
        data: resolveProgramSummaryProgramType(summary, programTypes?.types ?? []),
        status: 'success',
        fetchStatus: 'idle',
        error: null,
        isStale: false,
      },
      programTypes,
      programUsers: {
        data: programUsers.data,
        status: 'success',
        fetchStatus: 'idle',
        error: null,
        isStale: false,
      },
      campaign: _.cloneDeep(campaign),
      refetch,
    };
  }

  if (
    program.status !== 'loading' &&
    attachments.status !== 'loading' &&
    programType.status !== 'loading' &&
    programUsers.status !== 'loading' &&
    campaign?.status !== 'loading' &&
    !program.isStale &&
    !attachments.isStale &&
    !programType.isStale &&
    !programUsers.isStale &&
    !campaign?.isStale &&
    anotherProgramHasCome
  ) {
    memoized.current = resolvedProgramQuery;
  }

  const cleanUpAndRefetch = async () => {
    memoized.current = null;
    return refetch();
  };

  const result =
    anotherProgramHasCome || memoized.current == null ? resolvedProgramQuery : memoized.current;

  return { ...result, refetch: cleanUpAndRefetch };
};
