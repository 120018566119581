import { useMutation } from '@tanstack/react-query';
import { PromptVariable } from '../../types';
import useAuth from '../../../auth/authContext';
import useApi from '../../../api/backendApiContext';
import { useQueryCacheManager } from '../../../hooks/useQueryCacheManager';

export const useCreateVariableMutation = () => {
  const {
    state: { user },
  } = useAuth();
  const { growegyAIApi } = useApi();
  const queryCacheManager = useQueryCacheManager();

  return useMutation(
    async (variable: PromptVariable) => {
      if (!user || variable.id) return null;
      return growegyAIApi.createVariable(user.userId, variable);
    },
    {
      onSuccess: (promptVariable) => {
        if (!promptVariable) return;
        queryCacheManager.updatePromptVariable(promptVariable);
      },
      onError: async () => {
        await queryCacheManager.invalidateVariables();
      },
    }
  );
};
