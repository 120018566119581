import { Page } from '../Page';
import { useEffect } from 'react';
import useAnalytics from '../webAnalyticsContext';

const useAnalyticsPage = (page: Page) => {
  const webAnalytics = useAnalytics();
  useEffect(() => {
    webAnalytics?.page(page.category, page.name);
  }, [page, webAnalytics]);
};

export default useAnalyticsPage;
