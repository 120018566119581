import { Button } from 'react-bootstrap';
import React, { UIEvent } from 'react';
import { IssuedInvoice } from '../types';
import clsx from 'clsx';
import useAnalytics from '../../web-analytics/webAnalyticsContext';
import { Analytics } from '@segment/analytics-next';
import { AnalyticsEvent } from '../../web-analytics/AnalyticsEvent';

import { openUrlInNewTab } from '../../util/utils';

export const onInvoiceClick = (
  event: UIEvent | null,
  invoice: IssuedInvoice,
  analytics: Analytics | undefined,
  source: string
) => {
  event?.stopPropagation();
  openUrlInNewTab(invoice.hostedInvoiceUrl, {
    analytics,
    analyticsEvent: AnalyticsEvent.BILLING_INVOICE_OPEN,
    properties: { source, invoice_status: invoice.status },
  });
};

export type PayNowButtonProps = {
  invoice: IssuedInvoice;
  smallSize?: boolean;
  source: string;
  dataTest: string;
};

export const PayNowButton = (props: PayNowButtonProps) => {
  const { invoice, smallSize, source, dataTest } = props;
  const analytics = useAnalytics();
  return (
    <Button
      variant="primary"
      className={clsx('button-apply', {
        'growegy-button--small': smallSize,
        'growegy-font12': smallSize,
      })}
      data-test={dataTest}
      onClick={(e) => onInvoiceClick(e, invoice, analytics, source)}
    >
      Pay now
    </Button>
  );
};
