import React, { PropsWithChildren, useCallback, useContext, useEffect, useRef } from 'react';
import {
  Chat,
  GPT_MODEL_3_5,
  GPT_MODEL_4,
  GptModel,
  LibraryPromptWizardStep,
  Prompt,
  PromptVariableStringValueOrAdhoc,
} from '../types';
import { canUseGpt4, createNewChat } from '../util';
import { useFetchedBillingCustomer } from '../../billing/hooks/useFetchedBillingCustomer';
import {
  CHAT_REALM_GROWEGY_AI,
  CHAT_REALM_PROGRAMS,
  ChatRealm,
  useChatRealmContext,
} from './chatRealmContext';
import { CHAT_TYPE_CREATE_PROGRAM, CHAT_TYPE_GENERAL, ChatType } from '../dto';

export type LibraryPromptWizardStepState = {
  index: number;
  adhocValues: PromptVariableStringValueOrAdhoc[];
  selectedValues: PromptVariableStringValueOrAdhoc[];
  addNewMode: boolean;
  newValueDescription: string;
};

export type LibraryPromptWizardState = {
  steps: LibraryPromptWizardStep[];
  currentStepState: LibraryPromptWizardStepState;
  prompt: Prompt;
};

export type ChatLocalData = {
  rawContent: string;
  plainTextContent: string;
  libraryPromptWizardState: LibraryPromptWizardState | null;
};

type ChatContextProps = {
  getChatContext: (chatId: string) => ChatLocalData;
  setChatContext: (chatId: string, data: Partial<ChatLocalData>) => void;
  getCurrentChat: () => Chat;
  setCurrentChat: (chat: Chat) => void;
  getSelectedCategory: () => string | null;
  setSelectedCategory: (category: string | null) => void;
  getCurrentModel: () => GptModel;
  setCurrentModel: (model: GptModel) => void;
};

type ChatContextsProps = Record<ChatRealm, ChatContextProps>;

const createEmptyChatContext = (chatType: ChatType): ChatContextProps => ({
  getChatContext: () => ({
    rawContent: '',
    plainTextContent: '',
    libraryPromptWizardState: null,
  }),
  setChatContext: () => {},
  getCurrentChat: () => createNewChat(chatType),
  setCurrentChat: () => {},
  getSelectedCategory: () => null,
  setSelectedCategory: () => {},
  getCurrentModel: () => GPT_MODEL_3_5,
  setCurrentModel: () => {},
});

const ChatContexts = React.createContext<ChatContextsProps>({
  [CHAT_REALM_GROWEGY_AI]: createEmptyChatContext(CHAT_TYPE_GENERAL),
  [CHAT_REALM_PROGRAMS]: createEmptyChatContext(CHAT_TYPE_CREATE_PROGRAM),
});

export const useChatContext = (): ChatContextProps => {
  const chatRealmContext = useChatRealmContext();
  const chatContextsProps = useContext(ChatContexts);
  if (!chatRealmContext.realm) throw Error('No chat realm here.');
  return chatContextsProps[chatRealmContext.realm];
};

const useSingleRealmChatContext = (
  chatType: ChatType
): Omit<ChatContextProps, 'getCurrentModel' | 'setCurrentModel'> => {
  const chats = useRef(new Map<string, ChatLocalData>());
  const getChatContext = (chatId: string) =>
    chats.current.get(chatId) ?? {
      rawContent: '',
      plainTextContent: '',
      libraryPromptWizardState: null,
    };
  const setChatContext = (chatId: string, data: Partial<ChatLocalData>) =>
    chats.current.set(chatId, { ...getChatContext(chatId), ...data });

  const currentChat = useRef(createNewChat(chatType));
  const getCurrentChat = useCallback(() => currentChat.current, []);
  const setCurrentChat = useCallback((chat: Chat) => (currentChat.current = chat), [currentChat]);

  const selectedCategory = useRef<string | null>(null);
  const getSelectedCategory = useCallback(() => selectedCategory.current, []);
  const setSelectedCategory = useCallback((categoryName: string | null) => {
    selectedCategory.current = categoryName;
  }, []);

  return {
    getChatContext,
    setChatContext,
    getCurrentChat,
    setCurrentChat,
    getSelectedCategory,
    setSelectedCategory,
  };
};

export const ChatContextsProvider = (props: PropsWithChildren) => {
  const { children } = props;

  const growegyAIRealmContext = useSingleRealmChatContext(CHAT_TYPE_GENERAL);
  const programsRealmContext = useSingleRealmChatContext(CHAT_TYPE_CREATE_PROGRAM);

  const currentModel = useRef<GptModel>(GPT_MODEL_3_5);
  const getCurrentModel = useCallback(() => currentModel.current, []);
  const setCurrentModel = useCallback((model: GptModel) => {
    currentModel.current = model;
  }, []);

  const {
    query: { data: customer },
  } = useFetchedBillingCustomer();
  const firstCustomerLoad = useRef(true);
  useEffect(() => {
    if (customer && firstCustomerLoad) {
      if (canUseGpt4(customer)) {
        setCurrentModel(GPT_MODEL_4);
      }
      firstCustomerLoad.current = false;
    }
  }, [customer, firstCustomerLoad, setCurrentModel]);

  return (
    <ChatContexts.Provider
      value={{
        [CHAT_REALM_GROWEGY_AI]: {
          ...growegyAIRealmContext,
          getCurrentModel,
          setCurrentModel,
        },
        [CHAT_REALM_PROGRAMS]: {
          ...programsRealmContext,
          getCurrentModel,
          setCurrentModel,
        },
      }}
    >
      {children}
    </ChatContexts.Provider>
  );
};
