import { useFetchedBillingCustomer } from '../hooks/useFetchedBillingCustomer';
import { useFetchedBillingInvoices } from '../hooks/useFetchedBillingInvoices';
import { getBillingPlan } from '../billingPlans';
import { generateInvoicesAndBenefits, isInvoicePaid } from '../utils';
import { Timeline } from './Timeline';
import React from 'react';
import { LoyaltyBenefitConsultation } from './LoyaltyBenefitConsultation';
import _ from 'lodash';

export const PlanBenefits = () => {
  const {
    queryStatusComponent: customerStatusComponent,
    query: { data: customer, isLoading: isCustomerLoading, isError: isCustomerError },
  } = useFetchedBillingCustomer({ withTracking: false, refetchOnWindowFocus: 'always' });

  const {
    queryStatusComponent: invoiceListStatusComponent,
    query: { data: invoices, isLoading: isInvoiceListLoading, isError: isInvoiceListError },
  } = useFetchedBillingInvoices({ withTracking: false, refetchOnWindowFocus: 'always' });

  if (isCustomerError) return customerStatusComponent;
  if (isInvoiceListError) return invoiceListStatusComponent;
  if (isCustomerLoading || !customer) return customerStatusComponent;
  if (isInvoiceListLoading || !invoices) return invoiceListStatusComponent;

  const billingPlan = getBillingPlan(customer.planName);
  if (!billingPlan) {
    return <div>Billing plan benefits are coming soon...</div>;
  }
  const { invoicesAndBenefits, historicInvoices } = generateInvoicesAndBenefits(
    billingPlan,
    invoices,
    new Date()
  );
  const specialBenefitsEligibility =
    historicInvoices.every(isInvoicePaid) &&
    _(invoicesAndBenefits)
      .takeWhile((x) => typeof x.invoice === 'object' && x.invoice && isInvoicePaid(x.invoice))
      .some((x) => x.specialBenefits.length > 0);

  return (
    <div className="billing-section w-100" data-test="plan-benefits">
      <div className="billing-section__header">Plan benefits</div>
      <div className="billing__timeline-container">
        <Timeline
          segmentsPerYear={billingPlan.months.length}
          invoicesAndBenefits={invoicesAndBenefits}
          historicInvoices={historicInvoices}
        />
        {specialBenefitsEligibility && <LoyaltyBenefitConsultation />}
      </div>
    </div>
  );
};
