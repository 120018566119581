import { PropsWithChildren } from 'react';

export const NewTabLink = (props: PropsWithChildren<{ href: string }>) => {
  const { href, children } = props;
  return (
    <a href={href} target="_blank" rel="noopener noreferrer">
      {children}
    </a>
  );
};
