import { ResolvedCampaign } from '../../types';
import { hexToRGB } from '../../util';
import { CalendarViewType } from '../../containers/types';
import clsx from 'clsx';
import {
  TriangleDownFilledHoveredIcon,
  TriangleDownFilledUnhoveredIcon,
  TriangleRightFilledHoveredIcon,
  TriangleRightFilledUnhoveredIcon,
} from '../../../assets/icons';
import { StyleVariables } from '../../../commons/styleConstants';
import { CSSProperties } from 'react';
import CalendarExtraFields from './CalendarExtraFields';

export const CampaignToggleDataMarker = 'calendar__campaign-toggle';

export const CampaignCalendarEvent = (props: {
  programSummary: ResolvedCampaign;
  visibleColumns: string[];
  onToggleCampaign: (
    campaign: ResolvedCampaign,
    event: React.MouseEvent<HTMLDivElement, MouseEvent>
  ) => void;
  onOpen: (campaign: ResolvedCampaign) => void;
  viewType?: CalendarViewType;
  isExpanded: boolean;
  isUnscheduledList: boolean;
}) => {
  const {
    programSummary,
    visibleColumns,
    onToggleCampaign,
    onOpen,
    viewType,
    isExpanded,
    isUnscheduledList,
  } = props;
  const isMonthOrQuarterView = viewType === 'dayGridMonth' || viewType === 'monthGridQuarter';
  const color = programSummary.campaignColor
    ? `#${programSummary.campaignColor}`
    : StyleVariables.black2;

  const cssHover: CSSProperties = {
    // @ts-ignore
    '--hover-color': hexToRGB(color, 1),
    '--hover-opacity': 0.9,
    border: `2px solid ${hexToRGB(color, 1)}`,
    color: hexToRGB(color, 1),
  };
  return (
    <div className="calendar__campaign-event-sausage" data-campaign={programSummary.id}>
      {!isUnscheduledList && (
        <div className="calendar__campaign-event-left-line">
          <div
            style={{ background: hexToRGB(color, 1) }}
            className="calendar__campaign-event-line"
          />
        </div>
      )}
      <div
        className={clsx('calendar__campaign-event-pill', {
          'text-break': isMonthOrQuarterView && !isUnscheduledList,
          'calendar__campaign-event-pill--unscheduled': isUnscheduledList,
        })}
        role="button"
        tabIndex={0}
        style={cssHover}
        data-test="calendar__campaign-event-name"
        onClick={(event) => {
          event.stopPropagation();
          onOpen(programSummary);
        }}
      >
        <div className="calendar__campaign-event-pill-main">
          <div
            role="button"
            tabIndex={0}
            className="calendar__campaign-event-toggle"
            data-marker={CampaignToggleDataMarker}
            onClick={(event) => {
              event.stopPropagation();
              onToggleCampaign(programSummary, event);
            }}
          >
            {isExpanded ? (
              <>
                <TriangleDownFilledHoveredIcon
                  data-marker={CampaignToggleDataMarker}
                  className="m-auto calendar__campaign-event-icon--hovered"
                  fill="#ffffff"
                />
                <TriangleDownFilledUnhoveredIcon
                  data-marker={CampaignToggleDataMarker}
                  className="m-auto calendar__campaign-event-icon--unhovered"
                  fill={color}
                />
              </>
            ) : (
              <>
                <TriangleRightFilledHoveredIcon
                  data-marker={CampaignToggleDataMarker}
                  className="m-auto calendar__campaign-event-icon--hovered"
                  fill="#ffffff"
                />
                <TriangleRightFilledUnhoveredIcon
                  data-marker={CampaignToggleDataMarker}
                  className="m-auto calendar__campaign-event-icon--unhovered"
                  fill={color}
                />
              </>
            )}
          </div>

          <div className="calendar__campaign-event-pill-program-name"> {programSummary.name}</div>
        </div>
        <div className="calendar__campaign-event-pill-fields">
          <CalendarExtraFields
            programSummary={programSummary}
            visibleColumns={visibleColumns}
            shortcutFieldNamesOnly={true}
          />
        </div>
      </div>
      <div className="calendar__campaign-event-right-line">
        <div style={{ background: hexToRGB(color, 1) }} className="calendar__campaign-event-line" />
      </div>
    </div>
  );
};
