import { useState } from 'react';
import Modal from 'react-modal';
import 'react-datepicker/dist/react-datepicker.css';
import { useMutation, useQueryClient } from '@tanstack/react-query';

import useApi from '../../api/backendApiContext';
import { User } from '../types';
import { SystemRole } from '../../auth/systemRole';
import { UserEdit } from '../components/UserEdit';
import { UsersCacheKey } from '../../hooks/useQueryCacheManager';
import { useNotification } from '../../notification/notificationsContext';
import { Page } from '../../web-analytics/Page';
import useAnalyticsPage from '../../web-analytics/hooks/useAnalyticsPage';
import { analyticsTrack } from '../../web-analytics/webAnalytics';
import { AnalyticsEvent } from '../../web-analytics/AnalyticsEvent';
import useAnalytics from '../../web-analytics/webAnalyticsContext';

export type UserModalState = {
  user: User;
  action: 'UPDATE' | 'CREATE' | 'DELETE';
};

type UserDialogProps = UserModalState & { onHide: () => void };

const getPage = (action: 'UPDATE' | 'CREATE' | 'DELETE') => {
  switch (action) {
    case 'CREATE':
      return Page.USER_CREATE;
    case 'DELETE':
      return Page.USER_DELETE;
    case 'UPDATE':
    default:
      return Page.USER_UPDATE;
  }
};

export const UserDialog = ({ action, user, onHide }: UserDialogProps) => {
  useAnalyticsPage(getPage(action));
  const analytics = useAnalytics();

  const { userApi } = useApi();
  const queryClient = useQueryClient();
  const { notifySuccess, notifyError } = useNotification();

  const [showModal, setShowModal] = useState(true);

  const {
    mutateAsync: createMutation,
    isLoading: createInProgress,
    error: createError,
  } = useMutation(
    async (user: { email: string; role: SystemRole }) =>
      userApi.createUser({ username: user.email, role: user.role.id }),
    {
      onSuccess: async () => queryClient.invalidateQueries(UsersCacheKey),
    }
  );

  const {
    mutateAsync: updateMutation,
    isLoading: updateInProgress,
    error: updateError,
  } = useMutation(
    async (role: SystemRole) =>
      userApi.updateUser({
        id: user.id,
        role: role.id,
      }),
    {
      // eslint-disable-next-line no-unused-vars,@typescript-eslint/no-unused-vars
      onSuccess: async () => queryClient.invalidateQueries(UsersCacheKey),
    }
  );

  const {
    mutateAsync: deleteUserMutation,
    isLoading: deleteInProgress,
    error: deleteError,
  } = useMutation(async () => userApi.deleteUser(user.id), {
    onSuccess: async () => queryClient.invalidateQueries(UsersCacheKey),
  });

  const disabled = updateInProgress || deleteInProgress || createInProgress;

  const handleHideClick = () => {
    setShowModal(false);
    onHide();
  };

  const applyPromise = async (change: User): Promise<void> => {
    switch (action) {
      case 'UPDATE': {
        await updateMutation(change.role);
        analyticsTrack(analytics, AnalyticsEvent.USER_UPDATED);
        notifySuccess({
          notificationMsg: `User is updated.`,
          logMsg: `User ${change.email} is updated.`,
        });
        return;
      }
      case 'CREATE': {
        await createMutation({ email: change.email, role: change.role });
        analyticsTrack(analytics, AnalyticsEvent.USER_CREATED);
        notifySuccess({
          notificationMsg: `User ${change.email} is created.`,
          logMsg: `User is created.`,
        });
        return;
      }
      case 'DELETE': {
        await deleteUserMutation();
        analyticsTrack(analytics, AnalyticsEvent.USER_DELETED);
        notifySuccess({
          notificationMsg: `User is deleted.`,
          logMsg: `User ${change.email} is deleted.`,
        });
      }
    }
  };

  const handleApplyClick: (change: User) => Promise<void> = async (change: User) => {
    try {
      await applyPromise(change);
      setShowModal(false);
      onHide();
    } catch (err) {
      notifyError({
        err,
        notificationMsg: `Failed to ${(action === 'CREATE'
          ? 'invite'
          : action
        ).toLowerCase()} member`,
      });
    }
  };

  return (
    <Modal
      isOpen={showModal}
      onAfterClose={handleHideClick}
      className={action !== 'DELETE' ? 'user-modal--create-update' : 'user-modal--delete'}
      overlayClassName="growegy-modal__overlay"
      shouldCloseOnEsc={true}
      shouldCloseOnOverlayClick={true}
      onRequestClose={handleHideClick}
      ariaHideApp={false}
    >
      <UserEdit
        disabled={disabled}
        initialValues={{ ...user }}
        createError={createError}
        updateError={updateError}
        deleteError={deleteError}
        handleHideClick={handleHideClick}
        handleApplyClick={handleApplyClick}
        action={action}
      />
    </Modal>
  );
};
