import { CirclePicker, ColorResult } from 'react-color';
import * as yup from 'yup';
import _ from 'lodash';
import { string } from 'yup';
import { ErrorMessage, Field, Formik } from 'formik';
import { Button, Form, Modal } from 'react-bootstrap';
import { errorToString } from '../../util/utils';
import { ProgramType, ProgramTypes } from '../../program/types';
import { DivButton } from '../../commons/components/DivButton';
import { BinIcon, CloseIcon, DividerIcon } from '../../assets/icons';
import React from 'react';
import { hexToRGB } from '../../program/util';
import { defaultColors } from '../../commons/constants';
import { PROGRAM_TYPE_BACKGROUND_ALPHA } from '../util';

const validationSchema = (
  existedTypes: ProgramTypes,
  action: 'UPDATE' | 'CREATE' | 'DELETE',
  initialValues: ProgramType
) => {
  const existedTypesCapitalized = existedTypes.types.map((t) => _.capitalize(t.name));
  const forbidden =
    action === 'UPDATE'
      ? existedTypesCapitalized.filter(
          (t) => t.trim().toLowerCase() !== initialValues.name.trim().toLowerCase()
        )
      : existedTypesCapitalized;
  const existedMsg = `Program type should not be one of: ${forbidden.join(',  ')}`;
  if (action !== 'DELETE') {
    const nameRequiredMsg = 'Name is required!';
    return yup.object({
      name: string()
        .required(nameRequiredMsg)
        .test('trimmedLength', nameRequiredMsg, (item) => (item ? item.trim().length > 0 : false))
        .test('existedCheck', existedMsg, (item) =>
          item
            ? !forbidden.find((t) => t.trim().toLowerCase() === item.trim().toLowerCase())
            : false
        ),
    });
  }
  return null;
};

type ProgramTypeEditProps = {
  showModal: boolean;
  disabled: boolean;
  existedTypes: ProgramTypes;
  initialValues: ProgramType;
  createError: unknown;
  updateError: unknown;
  deleteError: unknown;
  handleHideClick: () => void;
  handleApplyClick: (change: ProgramType) => Promise<void>;
  handleDeleteClick: () => Promise<void>;
  action: 'UPDATE' | 'CREATE' | 'DELETE';
};

const applyButtonTitle = (action: 'UPDATE' | 'CREATE' | 'DELETE') => {
  switch (action) {
    case 'UPDATE':
      return 'Save program type';
    case 'CREATE':
      return 'Add program type';
    case 'DELETE':
      return 'Delete';
    default:
      throw new Error(`Unexpected user edit action: ${action}`);
  }
};

export const ProgramTypeEdit = ({
  showModal,
  disabled,
  initialValues,
  existedTypes,
  createError,
  deleteError,
  updateError,
  handleHideClick,
  handleApplyClick,
  handleDeleteClick,
  action,
}: ProgramTypeEditProps) => (
  <>
    <Modal
      show={showModal}
      onHide={handleHideClick}
      animation={false}
      dialogClassName="program-type-modal"
      centered={true}
    >
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema(existedTypes, action, initialValues)}
        onSubmit={async (t) => handleApplyClick({ ...t, name: t.name.trim() })}
        enableReinitialize={true}
      >
        {({
          touched,
          errors,
          isValid,
          dirty,
          handleSubmit,
          values,
          setFieldValue,
          setFieldTouched,
        }) => (
          <Form onSubmit={handleSubmit}>
            <Modal.Header
              className="program-type-edit__header"
              style={{ background: hexToRGB(values.color, PROGRAM_TYPE_BACKGROUND_ALPHA) }}
            >
              <div className="dialog-header__menu">
                <DivButton
                  icon={<BinIcon />}
                  onClick={handleDeleteClick}
                  className="dialog-header__button"
                  dataTest="program-type-edit__delete"
                />
                <DividerIcon style={{ marginLeft: '16px', marginRight: '16px' }} />
                <DivButton
                  icon={<CloseIcon />}
                  onClick={handleHideClick}
                  className="dialog-header__button"
                  data-test="program-type-edit__close"
                />
              </div>
              <Form.Group controlId="name" className="m-0">
                <Field
                  type="text"
                  name="name"
                  value={values.name}
                  placeholder="Add type name"
                  disabled={disabled || action === 'DELETE'}
                  data-test="program-type-edit__name"
                  className={`program-type-edit__input-name ${
                    touched.name && errors.name ? 'is-invalid' : ''
                  }`}
                />
                <ErrorMessage
                  component="div"
                  name="name"
                  className="invalid-feedback text-white opacity-8"
                  data-test="program-type-edit__name-validation-error"
                />
              </Form.Group>
            </Modal.Header>

            <Modal.Body className="program-type-edit__body p-0">
              <Form.Group controlId="color" className="program-type-edit__color d-flex">
                <label className="program-type-edit__label" htmlFor="color">
                  Color
                </label>
                <div className="program-type-edit__color-select">
                  <CirclePicker
                    width="400px"
                    circleSize={24}
                    circleSpacing={16}
                    colors={defaultColors}
                    color={values.color}
                    onChangeComplete={(color: ColorResult) => {
                      setFieldTouched('color');
                      setFieldValue('color', color.hex, true);
                    }}
                  />
                </div>
              </Form.Group>

              {action === 'DELETE' && (
                <div className="program-type-edit__row">
                  <span className="program-type-edit__delete-ask">
                    Are you sure you want to delete this program type?
                  </span>
                </div>
              )}
            </Modal.Body>
            <Modal.Footer className="program-type-edit__footer">
              <>
                {createError && (
                  <div
                    className="alert alert-warning text-break"
                    role="alert"
                    data-test="program-type-edit__error-create"
                  >
                    Failed to create program type: {errorToString(createError)}
                  </div>
                )}
                {updateError && (
                  <div
                    className="alert alert-warning text-break"
                    role="alert"
                    data-test="program-type-edit__error-update"
                  >
                    Failed to update program type: {errorToString(updateError)}
                  </div>
                )}
                {deleteError && (
                  <div
                    className="alert alert-warning text-break"
                    role="alert"
                    data-test="program-type-edit__error-delete"
                  >
                    Failed to delete program type: {errorToString(deleteError)}
                  </div>
                )}
                <Button
                  variant="light"
                  className="button-cancel"
                  onClick={handleHideClick}
                  disabled={disabled}
                  data-test="program-type-edit__cancel"
                >
                  Cancel
                </Button>
                <Button
                  type="submit"
                  variant={action === 'DELETE' ? 'danger' : 'primary'}
                  className="button-apply"
                  disabled={!isValid || disabled || (action !== 'DELETE' && !dirty)}
                  data-test="program-type-edit__apply"
                >
                  {applyButtonTitle(action)}
                </Button>
              </>
            </Modal.Footer>
          </Form>
        )}
      </Formik>
    </Modal>
  </>
);
