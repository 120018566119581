export enum Permission {
  ADD_USER,
  FIND_USER,
  RESEND_TEMP_PASSWORD,
  DELETE_USER,
  UPDATE_USER,
  GET_ALL_USERS,

  SAVE_REPLACE_AVATAR,
  GET_AVATAR,
  DELETE_AVATAR,

  SAVE_REPLACE_USER_SETTINGS,
  GET_USER_SETTINGS,
  DELETE_USER_SETTINGS,

  ADD_PROGRAM,
  ADD_ASSIGNED_PROGRAM,
  ADD_CAMPAIGN,
  ADD_PROGRAM_ATTACHMENT,
  ADD_ASSIGNED_PROGRAM_ATTACHMENT,
  GET_PROGRAM_ATTACHMENT,
  GET_ASSIGNED_PROGRAM_ATTACHMENT,
  GET_PROGRAM_ATTACHMENT_METADATA,
  GET_ASSIGNED_PROGRAM_ATTACHMENT_METADATA,
  DELETE_PROGRAM_ATTACHMENT,
  DELETE_ASSIGNED_PROGRAM_ATTACHMENT,
  REPLACE_PROGRAMS_FROM_CSV,
  GET_PROGRAM_PAGE,
  GET_ASSIGNED_PROGRAM_PAGE,
  GET_ALL_PROGRAMS,
  GET_ALL_ASSIGNED_PROGRAMS,
  GET_PROGRAM,
  GET_ASSIGNED_PROGRAM,
  DELETE_PROGRAM,
  DELETE_ALL_PROGRAM,
  REPLACE_PROGRAM,
  REPLACE_ASSIGNED_PROGRAM,

  GET_TENANT_RECOMMENDATIONS,
  GET_TENANT_ENTERPRISE_ANALYTICS,

  GET_CAMPAIGNS,
  GET_ASSIGNED_CAMPAIGNS,
  GET_CAMPAIGN_PROGRAMS,
  GET_ASSIGNED_CAMPAIGN_PROGRAMS,
  GET_CAMPAIGN_PROGRAMS_METADATA,
  GET_ASSIGNED_CAMPAIGN_PROGRAMS_METADATA,

  REPLACE_PROGRAM_TYPES,
  GET_PROGRAM_TYPES,

  REPLACE_INTEGRATIONS,
  GET_INTEGRATIONS,
  GET_INTEGRATION_STATE,

  BATCH_INTEGRATIONS,

  GET_INTEGRATION_METADATA,

  GET_DATA_SERIES,
  GET_GROUPED_DATA,

  CHANGE_BILLING,

  SAVE_REPLACE_NOTIFICATION_SETTINGS,
  GET_NOTIFICATION_SETTINGS,

  EXCHANGE_GOOGLE_AUTH_TOKEN,
  GET_GOOGLE_OAUTH,
  DELETE_GOOGLE_OAUTH,
  RESYNC_GOOGLE_CALENDAR,

  USE_CHAT_GPT,
  USE_PROMPT_LIBRARY_GPT,
  CRUD_PROMPT_LIBRARY_GPT,
  CRUD_VARIABLES_GPT,
}
