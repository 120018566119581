import clsx from 'clsx';
import React, { ReactElement } from 'react';

export const TextAndIcon = (props: {
  text?: string;
  icon?: ReactElement;
  textDataTest?: string;
  small?: boolean;
  hasMenu?: boolean;
  iconPosition?: 'left' | 'right';
  textAlign?: 'left' | 'center';
  textClassName?: string;
}) => {
  const { text, icon, textDataTest, small, hasMenu, iconPosition, textAlign, textClassName } =
    props;

  return (
    <div
      className="div-button__container"
      style={{
        flexDirection: iconPosition === 'right' ? 'row-reverse' : 'row',
      }}
    >
      {!!icon && (
        <div
          className={clsx('div-button__icon', {
            'div-button__icon--alone': !text,
            'div-button__icon--small': small,
          })}
        >
          {icon}
        </div>
      )}
      {!!text && (
        <span
          className={clsx('div-button__title', textClassName, {
            'flex-grow-1': hasMenu,
          })}
          style={{ textAlign }}
          data-test={textDataTest ?? ''}
        >
          {text}
        </span>
      )}
    </div>
  );
};
