import { useState } from 'react';
import { string, object, ref } from 'yup';
import { Formik, Form } from 'formik';
import { confirmCodeRequirements, passwordRequirements } from '../validationConstants';
import AuthInput from './AuthInput';
import PassInput from './PassInput';
import useAnalyticsPage from '../../web-analytics/hooks/useAnalyticsPage';
import { Page } from '../../web-analytics/Page';
import { Button } from 'react-bootstrap';
import { printAuthErrorMessage } from '../utils';

const validationSchema = object({
  code: string()
    .required('Please enter a confirmation code sent to your email')
    .matches(confirmCodeRequirements.pattern, confirmCodeRequirements.explainMessage),
  'new-password': string()
    .required('Please Enter your password')
    .matches(passwordRequirements.pattern, passwordRequirements.explainMessage)
    .required('Enter your password'),
  confirmPassword: string()
    .required('Confirm your password')
    .oneOf([ref('new-password')], 'Password does not match'),
});

type Payload = {
  code: string;
  'new-password': string;
  confirmPassword: string;
};

const ResetPassword = (props: {
  onReset: (code: string, password: string) => Promise<void>;
  resend: () => Promise<void>;
}) => {
  useAnalyticsPage(Page.FORGOT_PASSWORD_NEW_PASSWORD);

  const { onReset, resend } = props;
  const initialValues: Payload = {
    code: '',
    'new-password': '',
    confirmPassword: '',
  };

  const [resendError, setResendError] = useState<string | null>(null);
  const [isResendInProgress, setResendInProgress] = useState<boolean>(false);

  const onResend = async (): Promise<void> => {
    try {
      setResendInProgress(true);
      await resend();
    } catch (err) {
      setResendError(
        err && err.toString ? err.toString() : 'Something went wrong, please try again later.'
      );
    } finally {
      setResendInProgress(false);
    }
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={async (values, formikHelpers) => {
        formikHelpers.setStatus(null);
        await onReset(values.code, values['new-password']).catch((err) =>
          formikHelpers.setStatus({ error: err })
        );
      }}
    >
      {({ touched, errors, isSubmitting, status }) => (
        <Form>
          <div className="auth-dialog">
            <div className="auth-dialog__header">
              <div className="auth-dialog__title">
                Enter your code <br />
                and set a new password
              </div>
              <div className="auth-dialog__subtitle">
                We sent the verification code to your email.{' '}
              </div>
            </div>
            <div className="auth-dialog__fields">
              <div className="auth-dialog__field">
                <AuthInput
                  type="text"
                  name="code"
                  autoComplete="one-time-code"
                  placeholder="Enter code from email"
                  dataTest="reset-password__code"
                  invalid={!!touched.code && !!errors.code}
                />
              </div>
              <div className="auth-dialog__field">
                <PassInput
                  name="new-password"
                  id="new-password"
                  autoComplete="new-password"
                  placeholder="Enter new password"
                  dataTest="reset-password__new-password"
                  invalid={!!touched['new-password'] && !!errors['new-password']}
                />
              </div>
              <div className="auth-dialog__field">
                <PassInput
                  name="confirmPassword"
                  autoComplete="new-password"
                  placeholder="Confirm password"
                  dataTest="reset-password__confirm-password"
                  invalid={!!touched.confirmPassword && !!errors.confirmPassword}
                />
              </div>
            </div>
            <div className="auth-dialog__info">
              {status && status.error && (
                <div className="alert-danger fade show">{printAuthErrorMessage(status.error)}</div>
              )}
              {resendError && <div className="alert-danger fade show">{resendError}</div>}
            </div>
            <div className="auth-dialog__footer justify-content-between">
              <Button
                type="button"
                className="button-apply"
                disabled={isResendInProgress}
                onClick={async () => onResend()}
                data-test="reset-password__resend"
              >
                Resend code
              </Button>
              <Button
                type="submit"
                className="button-apply"
                disabled={isSubmitting}
                data-test="reset-password__submit"
              >
                Set new password
              </Button>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default ResetPassword;
