import useApi from '../../api/backendApiContext';
import { useQueryCacheManager } from '../../hooks/useQueryCacheManager';
import { useQuery } from '@tanstack/react-query';
import { ReactQueryConstants } from '../../commons/constants';

export const useFetchedEnterpriseAnalyticLayout = () => {
  const { analyticsApi } = useApi();
  return useQuery(
    useQueryCacheManager().getEnterpriseAnalyticLayoutCacheName(),
    async () => analyticsApi.getEnterpriseAnalyticLayout(),
    {
      staleTime: ReactQueryConstants.enterpriseAnalyticLayoutStaleTime,
      refetchOnWindowFocus: false,
    }
  );
};
