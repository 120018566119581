import { ReactElement } from 'react';
import clsx from 'clsx';

export const IconWithGradientBackground = (props: {
  icon: ReactElement;
  isButton?: boolean;
  dataTest?: string;
  onClick?: () => void;
}) => {
  const { icon, isButton, dataTest, onClick } = props;

  return (
    <div
      className={clsx('icon-with-background__container', {
        'icon-with-background__container--button': isButton,
      })}
      data-test={dataTest}
      onClick={onClick}
    >
      {icon}
    </div>
  );
};
