import { object, ref, string } from 'yup';
import { Form, Formik } from 'formik';
import { passwordRequirements } from '../validationConstants';
import PassInput from './PassInput';
import useAnalyticsPage from '../../web-analytics/hooks/useAnalyticsPage';
import { Page } from '../../web-analytics/Page';
import { Button } from 'react-bootstrap';
import { printAuthErrorMessage } from '../utils';

const validationSchema = object({
  'new-password': string()
    .required('Please Enter your password')
    .matches(passwordRequirements.pattern, passwordRequirements.explainMessage)
    .required('Enter your password'),
  confirmPassword: string()
    .required('Confirm your password')
    .oneOf([ref('new-password')], 'Password does not match'),
});

type NewPasswordPayload = {
  'new-password': string;
  confirmPassword: string;
};

const NewPassword = (props: { onSubmit: (password: string) => Promise<void> }) => {
  useAnalyticsPage(Page.CHANGE_PASSWORD);

  const { onSubmit } = props;
  const initialValues: NewPasswordPayload = {
    'new-password': '',
    confirmPassword: '',
  };

  return (
    <>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={async (values, formikHelpers) => {
          formikHelpers.setStatus(null);
          await onSubmit(values['new-password']).catch((err) =>
            formikHelpers.setStatus({ error: err })
          );
        }}
      >
        {({ touched, errors, isSubmitting, status }) => (
          <Form>
            <div className="auth-dialog">
              <div className="auth-dialog__header">
                <div className="auth-dialog__title">Set your new password</div>
              </div>
              <div className="auth-dialog__fields">
                <div className="auth-dialog__field">
                  <PassInput
                    name="new-password"
                    id="new-password"
                    autoComplete="new-password"
                    placeholder="Enter new password"
                    dataTest="new-password__password"
                    invalid={!!touched['new-password'] && !!errors['new-password']}
                  />
                </div>
                <div className="auth-dialog__field">
                  <PassInput
                    name="confirmPassword"
                    autoComplete="new-password"
                    placeholder="Confirm new password"
                    dataTest="new-password__confirm-password"
                    invalid={!!touched.confirmPassword && !!errors.confirmPassword}
                  />
                </div>
              </div>
              <div className="auth-dialog__info">
                {status && status.error && (
                  <div className="alert-danger fade show">
                    {printAuthErrorMessage(status.error)}
                  </div>
                )}
              </div>
              <div className="auth-dialog__footer">
                <Button
                  type="submit"
                  className="button-apply"
                  disabled={isSubmitting}
                  data-test="new-password__submit"
                >
                  Set new password
                </Button>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default NewPassword;
