import { BarChart, Cell, CartesianGrid, XAxis, YAxis, Tooltip, Bar, TooltipProps } from 'recharts';
import {
  DATA_SERIES_ACTUAL_LEADS,
  DATA_SERIES_PROJECTED_LEADS,
  GROUP_BY_MONTH,
  GROUP_BY_TYPE,
} from '../types';
import { useResolvedGroupingItems } from '../hooks/useResolvedGroupingItems';
import moment from 'moment';
import _ from 'lodash';
import { MONTH_YEAR_FORMAT, SHORT_MONTH_YEAR_FORMAT } from '../formats';
import clsx from 'clsx';
import AutoSizer from 'react-virtualized-auto-sizer';
import { ProgramMultiFiltersDto } from '../analyticsApi';

const BAR_SIZE = 8;
const MIN_MONTH_GAP = 64;
const Y_AXIS_WIDTH = 60;
const percentFormatter = new Intl.NumberFormat('en-US', { style: 'percent' });
const decimalFormatter = new Intl.NumberFormat('en-US');
const tickFormatter = (x: number) => moment.utc(x).format(MONTH_YEAR_FORMAT);
const shortTickFormatter = (x: number) => moment.utc(x).format(SHORT_MONTH_YEAR_FORMAT);
const shortcutNumberFormatter = (x: number) =>
  x > 1000 ? `${decimalFormatter.format(x / 1000)}K` : decimalFormatter.format(x);

const CustomTooltip = (
  props: TooltipProps<number | string | Array<number | string>, number | string>
) => {
  const { active, payload, label } = props;
  if (active && payload && payload.length) {
    const { color, actualLeads, projectedLeads, gapType, leadsRatio } = payload[0].payload;
    if (gapType) {
      return <div className="invisible" />;
    }

    return (
      <div className="analytics__tooltip" data-test="analytics__tooltip">
        <div
          className="analytics__tooltip-title"
          style={{ color }}
          data-test="analytics__tooltip-title"
        >
          {label}
        </div>
        <div className="analytics__tooltip-row">
          <div className="analytics__tooltip-name">Actual leads</div>
          <div
            className="analytics__tooltip-value"
            style={{ color }}
            data-test="analytics__tooltip-action-leads-value"
          >
            {`${decimalFormatter.format(actualLeads)} (${
              leadsRatio ? percentFormatter.format(leadsRatio) : '-'
            })`}
          </div>
        </div>
        <div className="analytics__tooltip-row">
          <div className="analytics__tooltip-name">Projected leads</div>
          <div
            className="analytics__tooltip-value"
            data-test="analytics__tooltip-projected-leads-value"
          >
            {decimalFormatter.format(projectedLeads)}
          </div>
        </div>
      </div>
    );
  }
  return null;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const CustomTick = (props: any) => {
  const { x, y, payload } = props;
  return (
    <g transform={`translate(${x},${y})`}>
      <text x={0} y={0} dy={16} textAnchor="middle">
        {payload.value}
      </text>
    </g>
  );
};

const compareItems = (a: BarData, b: BarData) => {
  if (a.month === b.month) {
    if (a.typeName === b.typeName) return 0;
    if (a.gapType === 'left') return -1;
    if (b.gapType === 'left') return 1;
    if (a.gapType === 'right') return 1;
    if (b.gapType === 'right') return -1;
    return a.typeName.localeCompare(b.typeName);
  }
  return a.month - b.month;
};

type BarData = {
  typeName: string;
  color: string;
  month: number;
  actualLeads: number;
  projectedLeads: number;
  leadsDifference: number;
  leadsRatio: number | null;
  gapType?: 'left' | 'right';
  tickText?: string;
};

const getActualLeadsPath = (x: number, y: number, width: number, height: number) => `
  M${x},${y + height}
  V ${y + BAR_SIZE / 2}
  A ${BAR_SIZE / 2} ${BAR_SIZE / 2} 0 0 1 ${x + width} ${y + BAR_SIZE / 2}
  V ${y + height}
  Z`;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const SmoothenedActualLeadsBar = (props: any) => {
  const { fill, x, y, width, height, gapType } = props;
  if (!x || !y || !width || !height) return <path />;
  return (
    <path
      d={getActualLeadsPath(x, y, width, height)}
      stroke="none"
      fill={fill}
      className={clsx({ 'actual-leads-bar-marker': !gapType })}
    />
  );
};

const getProjectedLeadsPath = (
  x: number,
  y: number,
  width: number,
  height: number,
  heightAdjustment: number
) => `
  M${x},${y + height + BAR_SIZE / 2}
  V ${y + BAR_SIZE / 2}
  A ${BAR_SIZE / 2} ${BAR_SIZE / 2} 0 0 1 ${x + width} ${y + BAR_SIZE / 2}
  V ${y + height + BAR_SIZE / 2 - heightAdjustment}
  A ${BAR_SIZE / 2} ${BAR_SIZE / 2} 0 0 0 ${x} ${y + height + BAR_SIZE / 2 - heightAdjustment}`;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const SmoothenedProjectedLeadsBar = (props: any) => {
  const { fill, x, y, width, height, actualLeads, leadsDifference } = props;
  if (!x || !y || !width || !height || !actualLeads || !leadsDifference || leadsDifference === 0)
    return <path />;
  const leadHeight = height / leadsDifference;
  const actualLeadsHeight = actualLeads * leadHeight;
  const heightAdjustment = Math.max(BAR_SIZE / 2 - Math.round(actualLeadsHeight), 0);
  return (
    <path
      d={getProjectedLeadsPath(x, y, width, height, heightAdjustment)}
      stroke="none"
      fill={fill}
    />
  );
};

const ChartPiece = (props: { dataSet: BarData[]; isYAxis: boolean; width: number }) => {
  const { dataSet, isYAxis, width } = props;
  return (
    <BarChart
      width={width}
      height={282}
      data={dataSet}
      className={clsx({ 'analytics__y-axis-bar-chart': isYAxis })}
      barSize={isYAxis ? 0 : BAR_SIZE}
      margin={{ top: 5, right: 0, bottom: 20, left: 0 }}
    >
      <CartesianGrid display={isYAxis ? 'none' : 'visible'} vertical={false} stroke="#191c1d14" />
      <XAxis dataKey="typeName" hide={true} visibility="hidden" />
      <XAxis
        visibility={isYAxis ? 'hidden' : 'visible'}
        dataKey="tickText"
        xAxisId="month"
        type="category"
        tickFormatter={tickFormatter}
        tickLine={false}
        axisLine={false}
        interval={0}
        tick={isYAxis ? <g /> : <CustomTick />}
      />
      {isYAxis && (
        <YAxis axisLine={false} tickLine={false} tickFormatter={shortcutNumberFormatter} />
      )}
      {!isYAxis && <Tooltip content={<CustomTooltip />} cursor={false} />}

      <Bar
        dataKey="actualLeads"
        stackId="a"
        shape={<SmoothenedActualLeadsBar />}
        minPointSize={BAR_SIZE / 2}
      >
        {dataSet.map((x) => (
          <Cell key={`cell-actual-${x.typeName}`} fill={x.color} />
        ))}
      </Bar>
      <Bar
        dataKey="leadsDifference"
        stackId="a"
        shape={<SmoothenedProjectedLeadsBar />}
        minPointSize={BAR_SIZE / 2}
      >
        {dataSet.map((x) => (
          <Cell
            key={`cell-projected-${x.typeName}`}
            fill={x.gapType ? x.color : '#d4d4d4'}
            className="real-bar-marker"
          />
        ))}
      </Bar>
    </BarChart>
  );
};

const getChartWidth = (barsCount: number) => barsCount * BAR_SIZE * 2;

function applyGaps(availableWidth: number, filteredData: BarData[]) {
  const months = _.chain(filteredData)
    .map((x) => x.month)
    .uniq()
    .value();

  const minGapBarsCount = MIN_MONTH_GAP / (2 * BAR_SIZE);
  const ensureEven = 2;
  const gapBarsCount = Math.max(
    Math.floor(
      (availableWidth - getChartWidth(filteredData.length)) /
        (months.length * 2 * BAR_SIZE * ensureEven)
    ) * ensureEven,
    minGapBarsCount
  );

  const gaps = _.chain(months)
    .map((x) =>
      _.range(0, gapBarsCount).map(
        (i) =>
          ({
            typeName: 'gap',
            color: '#ffffff00',
            month: x.valueOf(),
            actualLeads: 0,
            projectedLeads: 0,
            leadsDifference: 0,
            gapType: i < gapBarsCount / 2 ? 'left' : 'right',
          }) as BarData
      )
    )
    .flatten()
    .value();

  const dataSet = filteredData.concat(gaps).sort(compareItems);

  _.chain(dataSet)
    .filter((x) => !x.gapType)
    .groupBy((x) => x.month)
    .forEach((g) => {
      if (g.length === 0) return;
      const index = Math.floor(g.length / 2);
      const monthCenterBar = g[index];
      monthCenterBar.tickText = (g.length >= 3 ? tickFormatter : shortTickFormatter)(
        monthCenterBar.month
      );
    })
    .value();
  return dataSet;
}

const ActualLeadsByMonthAndTypeBar = (props: {
  fromDate: number;
  toDate: number;
  filter: ProgramMultiFiltersDto | null;
}) => {
  const { status, data } = useResolvedGroupingItems({
    fromDate: props.fromDate,
    toDate: props.toDate,
    groupBy: [GROUP_BY_TYPE, GROUP_BY_MONTH],
    groupOf: [DATA_SERIES_ACTUAL_LEADS, DATA_SERIES_PROJECTED_LEADS],
    filter: props.filter,
  });

  const filteredData = data
    ?.filter((x) => x.value.actualLeads !== 0)
    .map(
      (x) =>
        ({
          typeName: x.key.type!.name,
          color: x.key.type!.color,
          month: x.key.month!.valueOf(),
          actualLeads: x.value.actualLeads,
          projectedLeads: x.value.projectedLeads,
          leadsDifference: Math.max(x.value.projectedLeads - x.value.actualLeads, 0),
          leadsRatio: x.value.projectedLeads ? x.value.actualLeads / x.value.projectedLeads : null,
        }) as BarData
    );
  if (!filteredData || filteredData.length === 0)
    return <div className="analytics__histogram-by-month-by-type-no-data">No data</div>;

  return (
    <div data-test="actual-leads-by-month-by-type-bar" className="analytics__chart-container">
      {status === 'loading' && (
        <div className="alert alert-secondary" role="alert">
          Loading chart...
        </div>
      )}
      {status === 'success' && filteredData && (
        <div className="analytics__bar-chart-scrollable-container">
          <ChartPiece dataSet={filteredData} isYAxis={true} width={Y_AXIS_WIDTH} />
          <AutoSizer>
            {
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              (p: any) => {
                if (!p || !p.width || typeof p.width !== 'number') return;
                const availableWidth = p.width - Y_AXIS_WIDTH;
                const dataSet = applyGaps(availableWidth, filteredData);
                return (
                  <ChartPiece
                    dataSet={dataSet}
                    isYAxis={false}
                    width={getChartWidth(dataSet.length)}
                  />
                );
              }
            }
          </AutoSizer>
        </div>
      )}
    </div>
  );
};

export default ActualLeadsByMonthAndTypeBar;
