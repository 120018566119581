import React from 'react';
import useAuth from '../auth/authContext';
import { AppRoute, isRouteVisible } from '../router/AppRoutes';

type AuthorizedLinkProps = {
  render: (to: AppRoute) => React.ReactNode;
  to: AppRoute;
};

export default function VisibleResource({ render, to }: AuthorizedLinkProps) {
  const {
    state: { user },
  } = useAuth();

  const isAuthorized = isRouteVisible(user, to);

  return <>{isAuthorized && render(to)}</>;
}
