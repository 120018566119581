import React, { useEffect, useState } from 'react';
import { PromptVariable } from '../../types';
import {
  TriangleDownFilledUnhoveredIcon,
  TriangleRightFilledUnhoveredIcon,
} from '../../../assets/icons';
import { VariableOfStringTypeFields } from './VariableOfStringTypeFields';
import { VariableOfListTypeItems } from './VariableOfListTypeItems';

export const VariableWithCollapsingHeader = ({
  variable,
  isCollapsed,
  onUpdate,
  onCollapse,
}: {
  variable: PromptVariable;
  isCollapsed: boolean;
  onUpdate: (newValue: PromptVariable) => Promise<void>;
  onCollapse: (isCollapsed: boolean) => void;
}) => {
  const [collapsedState, setCollapsedState] = useState(isCollapsed);

  useEffect(() => {
    setCollapsedState(isCollapsed);
  }, [isCollapsed]);

  if (variable.variableValue.type === 'list' && variable.variableValue.values.length === 0) {
    return null;
  }
  return (
    <div
      className="variables-table__variable-container"
      data-test="variables-table__variable-container"
    >
      <div className="variables-table__name-header" data-test="variables-table__name-header">
        <div
          className="programs-table__editable-cell--squared2"
          data-test={`variables-table__name-header-expander--${
            collapsedState ? 'collapsed' : 'expanded'
          }`}
          onClick={(e) => {
            const newVal = !collapsedState;
            setCollapsedState(newVal);
            onCollapse(newVal);
            e.stopPropagation();
          }}
        >
          {!collapsedState ? (
            <TriangleDownFilledUnhoveredIcon className="variables-table__collapse-icon" />
          ) : (
            <TriangleRightFilledUnhoveredIcon className="variables-table__collapse-icon" />
          )}
        </div>
        <div>
          <span
            data-test="variables-table__name-header-text"
            className="text-capitalize growegy-font14--weight500 growegy-font--neutral900 growegy-font-em"
          >
            {variable.variableValue.name}
          </span>
        </div>
      </div>
      {variable.variableValue.type === 'string' && !collapsedState && (
        <VariableOfStringTypeFields variable={variable} onUpdate={onUpdate} />
      )}
      {variable.variableValue.type === 'list' && !collapsedState && (
        <VariableOfListTypeItems variable={variable} onUpdate={onUpdate} />
      )}
    </div>
  );
};
