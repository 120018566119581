import { PromptVariable, PromptVariableValue } from '../../types';
import useApi from '../../../api/backendApiContext';
import { useUpdateVariableMutation } from './useUpdateVariableMutation';
import { useMutation } from '@tanstack/react-query';
import useAuth from '../../../auth/authContext';
import { STRING_VARIABLES } from '../../util';
import { useCreateVariableMutation } from './useCreateVariableMutation';

const getDefaultVariableValue = (variableName: string): PromptVariableValue =>
  STRING_VARIABLES.indexOf(variableName) >= 0
    ? {
        name: variableName,
        type: 'string',
        value: '',
        description: '',
      }
    : {
        name: variableName,
        type: 'list',
        values: [],
      };

export const useAddValueToVariableMutation = () => {
  const { growegyAIApi } = useApi();
  const { mutate: updateVariableMutation } = useUpdateVariableMutation();
  const { mutate: createVariableMutation } = useCreateVariableMutation();
  const {
    state: { user },
  } = useAuth();

  return useMutation(
    async (variables: {
      valueDescription: string;
      promptVariable: PromptVariable | null;
      variableName: string;
    }) => {
      const { valueDescription, promptVariable, variableName } = variables;
      if (!user) return null;

      const promptVariableToSend: PromptVariable = (promptVariable !== null &&
      promptVariable.id !== null
        ? (await growegyAIApi.getVariables(user.userId)).find(
            (x) => x.variableValue.name === variableName
          )
        : null) ?? {
        id: null,
        isPrivate: false,
        variableValue: getDefaultVariableValue(variableName),
      };
      if (promptVariableToSend.variableValue.type === 'list') {
        if (
          !promptVariableToSend.variableValue.values.find((x) => x.description === valueDescription)
        ) {
          promptVariableToSend.variableValue.values.push({
            name: '',
            value: '',
            description: valueDescription,
            type: 'string',
          });
        }
      } else {
        promptVariableToSend.variableValue.description = valueDescription;
      }

      if (promptVariableToSend.id) {
        return updateVariableMutation(promptVariableToSend);
      } else {
        return createVariableMutation(promptVariableToSend);
      }
    },
    {}
  );
};
