import { AnswerMessageStatus } from './growegyAIApi';

export type CreateChatRequestDto = {
  title: string;
  model: string;
  type: ChatType;
};

export type RenameChatRequestDto = {
  chatId: string;
  title: string;
};

export const CHAT_TYPE_GENERAL = 'GENERAL';
export const CHAT_TYPE_CREATE_PROGRAM = 'CREATE_PROGRAM';
export type ChatType = typeof CHAT_TYPE_GENERAL | typeof CHAT_TYPE_CREATE_PROGRAM;

export type ChatDto = {
  chatId: string;
  model: string;
  title: string;
  type: ChatType;
};

export type ChatListResponseDto = {
  chats: ChatDto[];
  nextPageToken: string | null;
};

export type MessageDto = {
  messageId: string;
  clientMessageId: string | null;
  content: string | null;
  isSentByUser: boolean;
  createdDate: string;
  promptId: string | null;
  toolCalls: ToolCallDto[] | null;
  toolCallResults: ToolCallResultDto[] | null;
};

export type ToolCallDto = {
  id: string;
  function: FunctionCallDto;
};

export type FunctionCallDto = {
  name: string;
  arguments: string;
};

export type CreateProgramArgumentDto = {
  name?: string | null;
  program_type_id?: string | null;
  all_day?: boolean | null;
  start_date_time?: string | null;
  end_date_time?: string | null;
  owner_user_id?: string | null;
};

export type CreateProgramsArgumentDto = {
  new_programs: CreateProgramArgumentDto[];
};

export type ToolCallResultDto = {
  id: string;
  content: string;
};

export type MessageListResponseDto = {
  messages: MessageDto[];
  nextPageToken: string | null;
};

export type SendMessageRequestDto = {
  clientMessageId: string;
  content: string | null;
  promptId: string | null;
  messageMetadata?: { systemMessage?: string | null };
  toolCallResults?: ToolCallResultDto[] | null;
};

export type SendMessageResponseDto = {
  prompt: MessageDto;
  answer: MessageDto | null;
  answerStatus: AnswerMessageStatus;
};

export type NumberMetadataDto = {
  name: string;
  type: 'NumberMetadata';
  exampleValue?: number;
  min?: number;
  max?: number;
};

export type StringMetadataDto = {
  name: string;
  type: 'StringMetadata';
  exampleValue?: string;
};

export type ListMetadataDto = {
  name: string;
  type: 'ListMetadata';
  values: PlaceholderMetadataDto[];
  minSizeLimit?: number;
  maxSizeLimit?: number;
};

export type PlaceholderMetadataDto = NumberMetadataDto | StringMetadataDto | ListMetadataDto;

export type PromptMetadataDto = {
  placeholders: PlaceholderMetadataDto[];
};

export type PromptDto = {
  promptId: string;
  title: string;
  content: string;
  categories?: string[];
  metadata?: PromptMetadataDto;
};

export type PromptListResponseDto = {
  prompts: PromptDto[];
  nextPageToken: string | null;
};

export type PromptVariableBaseValueDto = {
  name: string;
  type: 'StringVariable' | 'ListVariable';
};

export type PromptVariableStringValueDto = PromptVariableBaseValueDto & {
  type: 'StringVariable';
  value: string;
  description: string;
};

export type PromptVariableListValueDto = PromptVariableBaseValueDto & {
  type: 'ListVariable';
  values: PromptVariableStringValueDto[];
};

export type PromptVariableDto = {
  variableId: string | null;
  isPrivate: boolean;
  variable: PromptVariableStringValueDto | PromptVariableListValueDto;
};

export type PromptVariableListResponseDto = {
  variables: PromptVariableDto[];
  nextPageToken: string | null;
};

export type PromptRecentUsageDto = {
  usageId: string | null;
  promptId: string;
  messageId: string | null;
  updatedDate: string | null;
};

export type PromptRecentUsagesDto = {
  usages: PromptRecentUsageDto[];
};

export type PromptMarkDto = {
  promptId: string;
  mark: number;
  updatedDate: string | null;
};

export type PromptMarkPageDto = {
  promptMarks: PromptMarkDto[];
  nextPageToken: string | null;
};
