import useApi from '../../api/backendApiContext';
import { useQueryCacheManager } from '../../hooks/useQueryCacheManager';
import { useQuery } from '@tanstack/react-query';
import { ReactQueryConstants } from '../../commons/constants';
import React from 'react';
import { QueryStatus } from '../../commons/components/QueryStatus';
import { useQueryErrorAnalyticsPublisher } from '../../log/hooks/useQueryErrorAnalyticPublisher';
import { AnalyticsEvent } from '../../web-analytics/AnalyticsEvent';

export const useFetchedBillingPaymentMethods = (props: { withTracking: boolean }) => {
  const { billingApi } = useApi();
  const queryCacheManager = useQueryCacheManager();
  const queryKey = queryCacheManager.getBillingPaymentMethodsCacheKey();
  const query = useQuery(queryKey, async () => billingApi.getPaymentMethods(), {
    staleTime: ReactQueryConstants.billingStaleTime,
    refetchInterval: ReactQueryConstants.billingRefetchTime,
    refetchOnWindowFocus: 'always',
  });
  useQueryErrorAnalyticsPublisher(
    props.withTracking ? query.error : null,
    'getBillingPaymentMethods',
    AnalyticsEvent.BILLING_PORTAL_FAILED
  );
  const queryStatusComponent = (
    <QueryStatus
      queryKey={queryKey}
      queryStatus={query.status}
      dataTestPrefix="payment-methods"
      failedToLoadText="Something went wrong. Try reloading the page multiple times. If the issue persists, contact support@growegy.com"
    />
  );

  return { query, queryStatusComponent };
};
