import { compact } from 'lodash';
import React, {
  BaseSyntheticEvent,
  PropsWithChildren,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { DragDropContext, DropResult } from '@hello-pangea/dnd';
import {
  Column,
  TableOptions,
  useExpanded,
  useFlexLayout,
  useGroupBy,
  useSortBy,
  useTable,
} from 'react-table';

import {
  ExistedCustomFields,
  ProgramPartialUpdateData,
  ProgramRow,
  ProgramSummary,
  ResolvedProgram,
  ResolvedProgramSummary,
} from '../types';
import { formatFormulaValue } from '../formulaUtil';
import TableRow from './programs-table/ProgramsTableRow';
import {
  SimpleDateCell,
  SimpleMoneyCell,
  SimpleNumericCell,
  SimpleProgramTypeCell,
  SimpleStringCell,
} from './programs-table/ProgramCells';
import HeaderRow from './programs-table/HeaderRow';
import SortableHeader from './programs-table/SortableHeader';
import { areCustomFieldsSame, extractExistedCustomFields, getEmptyProgramSummary } from '../util';
import NameReadonlyCell from './programs-table/NameReadnonlyCell';
import { ColumnsConfig } from '../containers/types';
import { onProgramColumnDragEnd } from './programs-table/utils';
import { SortableHeaderFn } from './programs-table/SortableHeaderFn';
import OwnerCell from './programs-table/OwnerCell';
// eslint-disable-next-line import/no-cycle,import/no-named-as-default,import/no-named-as-default-member
import ProgramDialog, { ModalState } from '../containers/ProgramDialog';
import useCampaignPrograms from '../hooks/useCampaignPrograms';
import { useMemoCompare } from '../../hooks/useMemoCompare';
import { Tasks } from './tasks/Tasks';
import { DivButton } from '../../commons/components/DivButton';
import { ICurrentUser } from '../../currentuser/currentUser';
import useAuth from '../../auth/authContext';
import { PlusSmallIcon } from '../../assets/icons';

interface TableProperties extends TableOptions<ProgramRow> {
  name: string;
  onCreate: (p: ProgramSummary) => void;
  onProgramClick: (p: ProgramSummary) => void;
  onProgramUpdate: (data: { original: ProgramRow; updates: ProgramPartialUpdateData[] }) => void;
  onColumnsReorder: (result: DropResult) => void;
  columnsConfig: ColumnsConfig;
  campaign: ResolvedProgram;
  user: ICurrentUser;
}

export type TableColumn = Column<ProgramRow> & { id: string };

const commonTableColumns: TableColumn[] = [
  {
    Header: SortableHeaderFn({ className: 'growegy-label14', title: 'Programs' }),
    id: 'name',
    width: 284,
    maxWidth: 284,
    accessor: (u: ProgramRow) => u.name,
    // @ts-ignore
    Cell: NameReadonlyCell,
  },
  {
    Header: SortableHeader,
    id: 'campaign',
    accessor: (u: ProgramRow) => u.campaign?.id,
    // @ts-ignore
    Cell: SimpleStringCell({
      dataTest: 'campaign-programs-table__leads-cell',
      className: 'text-break campaign__programs-table--cell',
    }),
  },
  {
    Header: SortableHeader,
    id: 'type',
    width: 112,
    maxWidth: 112,
    accessor: (u: ProgramRow) => u.type,
    // @ts-ignore
    Cell: SimpleProgramTypeCell({
      dataTest: 'campaign-programs-table__type-cell',
      className: 'text-break growegy-title14 campaign__programs-table--cell',
    }),
  },
  {
    Header: SortableHeader,
    id: 'startDateTime',
    width: 112,
    maxWidth: 112,
    accessor: (u: ProgramRow) => u.startDateTime,
    // @ts-ignore
    Cell: SimpleDateCell({
      dataTest: `campaign-programs-table__start-date-cell`,
      className: 'text-break campaign__programs-table--cell',
    }),
    sortType: 'datetime',
  },
  {
    Header: SortableHeader,
    width: 130,
    maxWidth: 130,
    id: 'owner',
    accessor: (u: ProgramRow) => u.owner,
    // @ts-ignore
    Cell: OwnerCell({ dataTest: 'campaign-programs-table__owner-cell', isReadonly: true }),
  },
  {
    Header: SortableHeader,
    width: 140,
    maxWidth: 140,
    id: 'leads',
    accessor: (u: ProgramRow) => u.leads,
    // @ts-ignore
    Cell: SimpleNumericCell({
      dataTest: 'campaign-programs-table__leads-cell',
      className: 'text-break campaign__programs-table--cell',
    }),
    aggregate: 'sum',
  },
  {
    Header: SortableHeader,
    width: 140,
    maxWidth: 140,
    id: 'actualLeads',
    accessor: (u: ProgramRow) => u.actualLeads,
    // @ts-ignore
    Cell: SimpleNumericCell({
      dataTest: 'campaign-programs-table__actual-leads-cell',
      className: 'text-break campaign__programs-table--cell',
    }),
    aggregate: 'sum',
  },
  {
    Header: SortableHeader,
    width: 112,
    maxWidth: 112,
    id: 'budget',
    accessor: (u: ProgramRow) => u.budget,
    // @ts-ignore
    Cell: SimpleMoneyCell({
      dataTest: 'campaign-programs-table__budget-cell',
      className: 'text-break campaign__programs-table--cell',
    }),
    aggregate: 'sum',
  },
  {
    Header: SortableHeader,
    id: 'endDateTime',
    accessor: (u: ProgramRow) => u.endDateTime,
    // @ts-ignore
    Cell: SimpleDateCell({
      dataTest: `campaign-programs-table__end-date-cell`,
      className: 'text-break campaign__programs-table--cell',
    }),
    sortType: 'datetime',
    Aggregated: () => null,
  },
  {
    Header: SortableHeader,
    id: 'status',
    accessor: (u: ProgramRow) => u.status.name,
    // @ts-ignore
    Cell: SimpleStringCell({
      dataTest: 'campaign-programs-table__status-cell',
      className: 'text-break campaign__programs-table--cell',
    }),
  },
];

const getRowId = (r: ResolvedProgramSummary) => r.id;

const Table = (props: PropsWithChildren<TableProperties>) => {
  const { columns, onProgramUpdate, onProgramClick, onCreate, campaign, user } = props;

  const instance = useTable<ProgramRow>(
    {
      ...props,
      ...{
        onProgramClick,
      },
      columns,
      autoResetSortBy: false,
      autoResetExpanded: false,
      expandSubRows: false,
      getRowId,
      initialState: {
        sortBy: [{ id: 'name' }],
        groupBy: ['campaign'],
      },
    },
    useFlexLayout,
    useGroupBy,
    useSortBy,
    useExpanded
  );

  const { getTableProps, getTableBodyProps, rows, prepareRow, headerGroups } = instance;

  const onCreateClick = (e: BaseSyntheticEvent) => {
    e.stopPropagation();
    onCreate(
      getEmptyProgramSummary(
        '',
        true,
        campaign.startDateTime,
        null,
        campaign.owningUserId,
        true,
        'PROGRAM',
        campaign.id
      )
    );
  };

  return (
    <>
      <div className="programs-table">
        <div {...getTableProps()} className="programs-table__content">
          <div className="programs-table__header--row--wrapper">
            {headerGroups.map((h) => (
              <HeaderRow headerGroup={h} key={h.getHeaderGroupProps().key} showFirst={true} />
            ))}
          </div>
          <div {...getTableBodyProps()} className="programs-table__rows--wrapper">
            {campaign.id && rows.length === 0 ? (
              <div className="d-flex" style={{ width: '111px' }}>
                <DivButton
                  isGray={true}
                  icon={<PlusSmallIcon />}
                  text="Add new"
                  onClick={onCreateClick}
                  dataTest="programs-table__create-new--"
                  className="common-table__add-new-btn"
                />
              </div>
            ) : (
              rows.map((row) => {
                prepareRow(row);
                return (
                  <TableRow
                    key={row.getRowProps().key}
                    programTypes={null}
                    prepareRow={prepareRow}
                    onCreate={onCreate}
                    onProgramClick={onProgramClick}
                    isScheduledMode={true}
                    onProgramUpdate={onProgramUpdate}
                    onConvertTaskToProgram={() => {}}
                    row={row}
                    user={user}
                    TasksTable={({ program, values, arrayHelpers }) => (
                      <div className="p-0">
                        <Tasks
                          program={program}
                          values={values}
                          arrayHelpers={arrayHelpers}
                          emphasizeFirstColumn={true}
                          isReadonly={true}
                        />
                      </div>
                    )}
                  />
                );
              })
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export const CampaignProgramsTable = (props: {
  campaign: ResolvedProgram;
  onChanged: () => void;
}) => {
  const {
    state: { user },
  } = useAuth();

  const { campaign, onChanged } = props;
  const { programs: loadedCampaignPrograms, status: programsLoadStatus } =
    useCampaignPrograms(campaign);

  const [campaignPrograms, setCampaignPrograms] = useState(loadedCampaignPrograms);

  const programsLoaded = useRef(false);

  useEffect(() => {
    if (programsLoadStatus === 'success' && !programsLoaded.current) {
      setCampaignPrograms(loadedCampaignPrograms);
      programsLoaded.current = true;
    }
  }, [loadedCampaignPrograms, programsLoadStatus]);

  const customFields = useMemoCompare<ExistedCustomFields>(
    extractExistedCustomFields(campaignPrograms),
    areCustomFieldsSame
  );

  const onProgramCreated = (program: ResolvedProgram) => {
    setCampaignPrograms((prev) => [...prev, program]);
    onChanged();
  };

  const onProgramUpdated = (program: ResolvedProgram) => {
    setCampaignPrograms((prev) => prev.map((p) => (p.id === program.id ? program : p)));
    onChanged();
  };

  const onProgramDeleted = (programId: string) => {
    setCampaignPrograms((prev) => prev.filter((p) => p.id !== programId));
    onChanged();
  };

  const tableColumns: TableColumn[] = useMemo(
    () =>
      commonTableColumns.concat(
        // @ts-ignore
        Array.from(customFields.strings).map((fieldName) => ({
          Header: SortableHeader,
          id: `${fieldName}-text`,
          accessor: (u: ProgramRow) =>
            u.customFields.find((f) => f.name === fieldName && f.type === 'text')?.value,
          Cell: SimpleStringCell({
            dataTest: `campaign-programs-table__custom-cell-${fieldName}-text`,
            className: 'text-break campaign__programs-table--cell',
          }),
        })),
        Array.from(customFields.numbers).map((fieldName) => ({
          Header: SortableHeader,
          title: fieldName,
          id: `${fieldName}-number`,
          accessor: (u: ProgramRow) =>
            u.customFields.find((f) => f.name === fieldName && f.type === 'number')?.value ?? null,
          Cell: SimpleStringCell({
            dataTest: `campaign-programs-table__custom-cell-${fieldName}-number`,
            className: 'text-break campaign__programs-table--cell',
          }),
        })),
        Array.from(customFields.dates).map((fieldName) => ({
          Header: SortableHeader,
          title: fieldName,
          id: `${fieldName}-date-time`,
          accessor: (u: ProgramRow) =>
            u.customFields.find((f) => f.name === fieldName && f.type === 'date-time')?.value ??
            null,
          Cell: SimpleDateCell({
            dataTest: `campaign-programs-table__custom-cell-${fieldName}-date-time`,
            className: 'text-break campaign__programs-table--cell',
          }),
        })),
        Array.from(customFields.formulas).map((fieldName) => ({
          Header: SortableHeader,
          title: fieldName,
          id: `${fieldName}-formula`,
          accessor: (u: ProgramRow) => {
            const found = u.customFields.find((f) => f.name === fieldName && f.type === 'formula');
            if (!found || found.type !== 'formula') return null;
            return formatFormulaValue(found.result, found.prefix, found.suffix);
          },
          Cell: SimpleStringCell({
            dataTest: `campaign-programs-table__custom-cell-${fieldName}-formula`,
            className: 'text-break campaign__programs-table--cell',
          }),
        }))
      ),
    [customFields]
  );

  const [columnsConfig, setColumnsConfig] = useState<ColumnsConfig>(
    tableColumns.map((c) => ({
      id: c.id as string,
      visible: true,
    }))
  );

  useEffect(() => {
    const newColumns = tableColumns.filter(
      (col) => !columnsConfig.find((colConfig) => colConfig.id === col.id)
    );

    if (newColumns.length)
      setColumnsConfig([
        ...columnsConfig,
        ...newColumns.map((col) => ({
          id: col.id as string,
          visible: true,
        })),
      ]);
  }, [tableColumns, columnsConfig, setColumnsConfig]);

  const visibleColumns = compact(
    columnsConfig.map((colConfig) => tableColumns.find((column) => column.id === colConfig.id))
  );

  const onDragEnd = (result: DropResult) =>
    onProgramColumnDragEnd(result, 2, visibleColumns, columnsConfig, setColumnsConfig);

  const [modalState, setModalState] = useState<ModalState>(null);

  const showNewProgramDialog = (program: ProgramSummary) => {
    if (!programsLoaded.current) return;
    setModalState({
      action: 'CREATE',
      program,
      scrollToTaskId: null,
      taskToDeleteOnCreate: null,
    });
  };

  const showEditProgramDialog = (program: ProgramSummary) => {
    setModalState({
      action: 'UPDATE',
      program,
      scrollToTaskId: null,
      taskToDeleteOnCreate: null,
    });
  };

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Table
        key="programs-table"
        name="programs-table"
        columns={visibleColumns}
        onColumnsReorder={onDragEnd}
        data={campaignPrograms}
        onCreate={showNewProgramDialog}
        onProgramClick={showEditProgramDialog}
        onProgramUpdate={() => {}}
        columnsConfig={columnsConfig.filter((c) =>
          tableColumns.find((column) => column.id === c.id)
        )}
        campaign={campaign}
        user={user!}
      />
      {modalState && (
        <ProgramDialog
          onProgramUpdated={onProgramUpdated}
          onProgramCreated={onProgramCreated}
          onProgramDeleted={onProgramDeleted}
          action={modalState.action}
          programSummary={modalState.program}
          scrollToTaskId={modalState.scrollToTaskId}
          onHide={() => setModalState(null)}
        />
      )}
    </DragDropContext>
  );
};
