import axios, { AxiosInstance, InternalAxiosRequestConfig } from 'axios';
import { useMemo } from 'react';
import { RefreshTokenApi } from '../auth/authApi';
import { createUser, IUserTokens } from '../currentuser/currentUser';
import { errorToLoggingString } from '../util/utils';

const buildAxiosInstance = (
  url: string,
  storageTokens: IUserTokens | null,
  refreshToken: (email: string, token: string) => Promise<string>
): AxiosInstance => {
  logger.info('Building new axios instance.');

  const axiosInstance = axios.create({ baseURL: `${url}` });
  axiosInstance.defaults.headers.common = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  };

  axiosInstance.interceptors.request.use(async (config: InternalAxiosRequestConfig) => {
    if (!storageTokens) return Promise.reject(new Error(`No authenticated user`));

    const { url, method, baseURL, headers } = config;
    // eslint-disable-next-line no-param-reassign
    config.headers.Authorization = `Bearer ${storageTokens.access}`;
    logger.pushEvent('Sending request', {
      url: `${url}`,
      method: `${method}`,
      baseURL: `${baseURL}`,
      headers: `${JSON.stringify(headers)}`,
    });
    return config;
  });

  axiosInstance.interceptors.response.use(
    (response) => {
      logger.pushEvent('Request completed', {
        url: `${response.config.url}`,
        method: `${response.config.method}`,
        baseURL: `${response.config.baseURL}`,
      });
      return response;
    },
    async (error) => {
      const originalRequest = error.config;

      // eslint-disable-next-line no-underscore-dangle
      if (error.response && error.response.status === 401) {
        const user = createUser(storageTokens);
        // eslint-disable-next-line no-underscore-dangle
        if (!originalRequest._retry && user && storageTokens?.refresh) {
          // eslint-disable-next-line no-underscore-dangle
          originalRequest._retry = true;
          logger.pushEvent('Refreshing cognito token', {
            original_request_url: `${originalRequest.url}`,
            original_request_method: `${originalRequest.method}`,
          });
          const newToken = await refreshToken(user.email, storageTokens?.refresh);
          originalRequest.headers.Authorization = `Bearer ${newToken}`;
          return axios(originalRequest);
        }
      }
      logger.error(`Request failed`, error);
      logger.pushEvent(`Request failed`, { error: errorToLoggingString(error) });
      return Promise.reject(error);
    }
  );
  logger.info('New axios instance built.');
  return axiosInstance;
};

export function useAxios(
  url: string,
  refreshTokenApi: RefreshTokenApi,
  tokens: IUserTokens | null
): AxiosInstance {
  return useMemo<AxiosInstance>(
    () => buildAxiosInstance(url, tokens, refreshTokenApi),

    [refreshTokenApi, tokens, url]
  );
}
