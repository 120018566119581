import { AxiosInstance, AxiosResponse } from 'axios';
import {
  BillingStripeInvoicesListDto,
  StripeCustomerDto,
  StripePaymentMethodDto,
  StripePaymentMethodsListDto,
} from './dto';
import { Customer, toCustomer, Invoice, toInvoices } from './types';

type BillingPortalSessionDto = {
  url: string;
};

type ChangeBillingPlanDto = {
  newBillingPlanName: string;
};

type CancelBillingPlanDto = {
  reasons: string[];
  comment: string;
};

export type BillingApi = ReturnType<typeof getBillingApi>;

export const getBillingApi = (axiosInstance: AxiosInstance) => {
  const createBillingPortalSession = async (): Promise<BillingPortalSessionDto> =>
    axiosInstance.post<BillingPortalSessionDto>('/billing/create-session').then((r) => r.data);

  const finalizeInvoices = async (): Promise<Invoice[]> =>
    axiosInstance
      .post<BillingStripeInvoicesListDto>('/billing/invoices/finalize')
      .then((r) => toInvoices(r.data));

  const getPaymentMethods = async (): Promise<StripePaymentMethodDto[]> =>
    axiosInstance
      .get<StripePaymentMethodsListDto>('/billing/payment-methods')
      .then((r) => r.data.paymentMethods);

  const getBillingCustomer = async (): Promise<Customer> =>
    axiosInstance.get<StripeCustomerDto>('/billing/customer').then((r) => toCustomer(r.data));

  const changeBillingPlan = async (newBillingPlanName: string) => {
    const body: ChangeBillingPlanDto = {
      newBillingPlanName,
    };

    await axiosInstance.post('/billing/plan/change', body);
  };

  const cancelBillingPlan = async (request: CancelBillingPlanDto): Promise<Customer> =>
    axiosInstance
      .post<CancelBillingPlanDto, AxiosResponse<StripeCustomerDto>>('/billing/plan/cancel', request)
      .then((r) => toCustomer(r.data));

  return {
    createBillingPortalSession,
    getBillingCustomer,
    finalizeInvoices,
    getPaymentMethods,
    changeBillingPlan,
    cancelBillingPlan,
  };
};
