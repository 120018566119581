import _ from 'lodash';
import { UnknownProgramOwner, User } from '../user/types';
import { SuggestionDataItem } from 'react-mentions';
import { StyleVariables } from '../commons/styleConstants';

export const DecimalFormatter = new Intl.NumberFormat('en-US');

export type ProgramAttachment = {
  status: 'NEW' | 'EXISTED' | 'DELETED';
  id: string;
  name: string;
  file?: File;
};

export interface QueryData<T> {
  status: 'loading' | 'success' | 'error';
  fetchStatus: 'fetching' | 'paused' | 'idle';
  data: T;
  error: unknown | null;
  dataUpdatedAt?: number;
  errorUpdatedAt?: number;
  isStale: boolean;
}

export type ColoredItem = {
  id: string;
  name: string;
  color: string;
  description?: string;
};

export type ProgramType = ColoredItem;

export type ProgramTypes = {
  types: Array<ProgramType>;
  version: number;
};

export const MAX_ABSOLUTE_OFFSET = 9999;
export type TaskDueDateFormula = {
  anchor: DueDateFormulaAnchor;
  offset: number;
  offsetUnit: DueDateFormulaOffsetUnit;
};

export type ProgramTask = {
  id: string | null;
  name: string;
  description: string | null;
  dueDateTime: Date | null;
  dueDateFormula: TaskDueDateFormula | null;
  owningUserId: string | null;
  status: ProgramTaskStatus;
};

export type CreateProgramTaskChange = {
  changeType: 'CREATE';
  orderId?: number;
  task: ProgramTask;
  shouldFocus?: boolean;
};

export type UpdateProgramTaskChange = {
  changeType: 'UPDATE';
  orderId?: number;
  task: ProgramTask;
};

export type DeleteProgramTaskChange = {
  changeType: 'DELETE';
  taskId: string;
};

export type ProgramTaskChange =
  | CreateProgramTaskChange
  | UpdateProgramTaskChange
  | DeleteProgramTaskChange;

export type TaskChangeAndSnapshot = {
  changes: ProgramTaskChange[];
  tasksAfterChange: ResolvedProgramTask[];
};

export type ProgramSummary = {
  id: string;
  programKind: ProgramKind;
  name: string;
  startDateTime: Date;
  endDateTime: Date;
  startRangeDateTime: Date;
  endRangeDateTime: Date;
  wholeDay: boolean;
  typeId: string;
  budget: number;
  leads: number;
  status: string;
  owningUserId: string | null;
  vendor: string;
  notes: string;
  version?: number;
  customFields: ProgramCustomField[];
  tasks: ProgramTask[];
  actualLeads: number | null;
  salesforceCampaignId: string | null;
  campaignId: string | null;
  campaignColor: string | null;
  endDateInClientRepresentation: boolean;
};

export type Program = ProgramSummary;
export type Campaign = ProgramSummary;

export type ResolvedProgramTask = ProgramTask & {
  owner: User | null;
  orderId: number;
  shouldFocus?: boolean;
};

export type ResolvedProgramSummary = Omit<
  ProgramSummary,
  'typeId' | 'tasks' | 'campaignId' | 'status'
> & {
  type: ProgramType;
  owner: User | null;
  resolvedTasks: ResolvedProgramTask[];
  campaign: ResolvedCampaign | null;
  status: ProgramStatusOption;
};

export type ResolvedCampaign = ResolvedProgramSummary;

export const ResolvedProgramTypeField = 'type';
export const ResolvedProgramNameField = 'name';
export const ResolvedProgramOwnerField = 'owner';
export const ResolvedProgramBudgetField = 'budget';
export const ResolvedProgramStatusField = 'status';
export const ResolvedProgramDatesField = 'dates';
export const ResolvedProgramStartDateField = 'startDateTime';
export const ResolvedProgramEndDateField = 'endDateTime';

export const ResolvedTaskAssigneeField = 'owner';
export const ResolvedTaskDueDateField = 'dueDateTime';

export const ResolvedProgramFields = [
  ResolvedProgramTypeField,
  ResolvedProgramNameField,
  ResolvedProgramOwnerField,
  'vendor',
  'salesforceCampaignId',
  'leads',
  'actualLeads',
  ResolvedProgramBudgetField,
  ResolvedProgramStartDateField,
  ResolvedProgramEndDateField,
  ResolvedProgramStatusField,
] as const;

export const ResolvedTaskFields = [ResolvedTaskAssigneeField, ResolvedTaskDueDateField] as const;

export type FormulaScope = { [programNumberField: string]: number };

export type FormulaScopeWithFieldMentions = {
  scope: FormulaScope;
  mentionsData: SuggestionDataItem[];
};

export type ResolvedProgram = ResolvedProgramSummary & {
  attachments: ProgramAttachment[];
};

export type ProgramEditFormikModel = ResolvedProgram & {
  formulaScope: FormulaScope;
  mentionsData: SuggestionDataItem[];
  customFieldsNameSums: FormulaScope;
  isSalesforceInputFocused: boolean;
  isChangedCampaignPrograms: boolean;
  isCampaignRequired: boolean;
};

export const TODO_PROGRAM_STATUS: ColoredItem = {
  id: 'to-do',
  name: 'To-do',
  color: StyleVariables.black,
};
export const IN_PROGRESS_PROGRAM_STATUS: ColoredItem = {
  id: 'in-progress',
  name: 'In progress',
  color: '#f5a623',
};
export const DONE_PROGRAM_STATUS: ColoredItem = { id: 'done', name: 'Done', color: '#417505' };
export const CANCELLED_PROGRAM_STATUS: ColoredItem = {
  id: 'cancelled',
  name: 'Cancelled',
  color: '#d0021b',
};
export const EXPIRED_PROGRAM_STATUS: ColoredItem = {
  id: 'expired',
  name: 'Expired',
  color: '#4e010d',
};
export const ProgramStatusesOptions = [
  TODO_PROGRAM_STATUS,
  IN_PROGRESS_PROGRAM_STATUS,
  DONE_PROGRAM_STATUS,
  CANCELLED_PROGRAM_STATUS,
  EXPIRED_PROGRAM_STATUS,
] as const;
export type ProgramStatusOption = (typeof ProgramStatusesOptions)[number];
export const ProgramStatuses = [...ProgramStatusesOptions.map((s) => s.id)] as const;
export type ProgramStatus = (typeof ProgramStatuses)[number];
export const getProgramStatus = (id: string) => {
  switch (id) {
    case TODO_PROGRAM_STATUS.id:
      return TODO_PROGRAM_STATUS;
    case IN_PROGRESS_PROGRAM_STATUS.id:
      return IN_PROGRESS_PROGRAM_STATUS;
    case DONE_PROGRAM_STATUS.id:
      return DONE_PROGRAM_STATUS;
    case CANCELLED_PROGRAM_STATUS.id:
      return CANCELLED_PROGRAM_STATUS;
    case EXPIRED_PROGRAM_STATUS.id:
      return EXPIRED_PROGRAM_STATUS;
    default:
      return TODO_PROGRAM_STATUS;
  }
};

export const ProgramTaskStatuses = ['to-do', 'done'] as const;
export type ProgramTaskStatus = (typeof ProgramTaskStatuses)[number];

export const ProgramKinds = ['PROGRAM', 'CAMPAIGN'] as const;
export type ProgramKind = (typeof ProgramKinds)[number];

export const DueDateFormulaAnchors = ['PROGRAM_START_DATE', 'PROGRAM_END_DATE'] as const;
export type DueDateFormulaAnchor = (typeof DueDateFormulaAnchors)[number];

export const DueDateFormulaOffsetUnits = ['DAY'] as const;
export type DueDateFormulaOffsetUnit = (typeof DueDateFormulaOffsetUnits)[number];

export const ProgramScheduledTypeOptions = [
  { id: 'Scheduled', name: 'Scheduled', color: StyleVariables.black },
  { id: 'Unscheduled', name: 'Unscheduled', color: StyleVariables.black },
] as const;
export const ProgramScheduledTypes = [...ProgramScheduledTypeOptions.map((s) => s.name)] as const;
export type ProgramScheduledType = (typeof ProgramScheduledTypes)[number];

export const CustomFieldTypeOptions = [
  { id: 'text', name: 'Text', color: StyleVariables.black },
  { id: 'number', name: 'Number', color: StyleVariables.black },
  { id: 'date-time', name: 'Date & Time', color: StyleVariables.black },
  { id: 'formula', name: 'Formula', color: StyleVariables.black },
] as const;
export const CustomParamTypes = [...CustomFieldTypeOptions.map((s) => s.id)] as const;
export type CustomFieldType = (typeof CustomParamTypes)[number];

export const ProgramNumberFields = ['leads', 'actualLeads', 'budget'] as const;
export type ProgramNumberField = (typeof ProgramNumberFields)[number];

export const ProgramDateTimeFields = ['startDateTime', 'endDateTime'] as const;
export type ProgramDateTimeField = (typeof ProgramDateTimeFields)[number];

export type CustomTextField = {
  type: 'text';
  value: string;
};

export type CustomNumberField = {
  type: 'number';
  value: number;
};

export type CustomDateTimeField = {
  type: 'date-time';
  value: Date;
};

export type CustomFormulaField = {
  type: 'formula';
  value: string;
  result: number;
  prefix: string | null;
  suffix: string | null;
};

export type SimpleCustomField =
  | CustomTextField
  | CustomNumberField
  | CustomDateTimeField
  | CustomFormulaField;

export type ProgramCustomField = {
  localId: string;
  name: string;
  isNew?: boolean;
} & SimpleCustomField;

export type ProgramSummaries = {
  from: Date;
  to: Date;
  limit: number;
  lastEvaluatedKey: string | null;
  summaries: Array<ProgramSummary>;
};

export interface MonthlySummaries<T> {
  summaries: T[];
  startMonth: Date;
}

export type OneMonthSummaries = MonthlySummaries<ProgramSummary>;
export type OneMonthResolvedSummaries = MonthlySummaries<ResolvedProgramSummary>;

export type ResolvedProgramSummaries = QueryData<QueryData<OneMonthResolvedSummaries | null>[]>;

export class ProgramSummariesFetchError extends Error {
  get startMonth(): Date {
    return this.start;
  }

  private start: Date;

  constructor(startMonth: Date, message?: string) {
    super(message);
    this.start = startMonth;

    Object.setPrototypeOf(this, ProgramSummariesFetchError.prototype);
  }
}

export type ProgramForEditResult = {
  program: QueryData<Program>;
  attachments: QueryData<ProgramAttachment[]>;
  programType: QueryData<ProgramType>;
  programTypes: ProgramTypes | undefined;
  programUsers: QueryData<User[]>;
  campaign: QueryData<ResolvedCampaign | null>;
  refetch: () => Promise<void>;
};

export const NoProgramType: ProgramType = {
  id: 'no-program-type-id',
  name: 'Add program type',
  color: StyleVariables.neutral500,
};
export const UnknownProgramType: ProgramType = {
  id: 'unknown-program-type-id',
  name: 'Unknown',
  color: '#a8b2b9',
};
export const TotalProgramType: ProgramType = {
  id: 'total-program-type-id',
  name: 'Total',
  color: '#00b5ce',
};

export const UnknownCampaign: ResolvedCampaign = {
  id: 'unknown-campaign-id',
  programKind: 'CAMPAIGN',
  name: 'Unknown',
  owner: UnknownProgramOwner,
  startDateTime: new Date(),
  endDateTime: new Date(),
  startRangeDateTime: new Date(),
  endRangeDateTime: new Date(),
  wholeDay: false,
  budget: 0,
  leads: 0,
  status: TODO_PROGRAM_STATUS,
  vendor: '',
  notes: '',
  version: undefined,
  customFields: [],
  resolvedTasks: [],
  actualLeads: null,
  type: UnknownProgramType,
  campaign: null,
  owningUserId: null,
  salesforceCampaignId: null,
  endDateInClientRepresentation: false,
  campaignColor: 'EF242B',
};

export const NoCampaign: ResolvedCampaign = {
  ..._.cloneDeep(UnknownCampaign),
  id: 'no-campaign-id',
  name: 'Add campaign',
};

export type ProgramRow = ResolvedProgramSummary;

export type ExistedCustomFields = {
  strings: Set<string>;
  numbers: Set<string>;
  dates: Set<string>;
  formulas: Set<string>;
};

export type ProgramCustomFieldMeta = {
  name: string;
  type: CustomFieldType;
};

export type TaskInFocus = {
  programId: string;
  taskOrderId: number;
};

export type ProgramPartialUpdateFieldArrayItem = { name: string; type: CustomFieldType };

export type ProgramPartialUpdateField =
  | {
      type: 'StaticField';
      fieldName: keyof ProgramRow;
    }
  | {
      type: 'ArrayFieldItem';
      arrayField: keyof ProgramRow;
      arrayItemValue: ProgramPartialUpdateFieldArrayItem;
    };

export type ProgramPartialUpdateData =
  | {
      field: 'name';
      value: string | null;
    }
  | {
      field: 'owner';
      value: User | null;
    }
  | {
      field: 'vendor';
      value: string | null;
    }
  | {
      field: 'salesforceCampaignId';
      value: string | null;
    }
  | {
      field: 'campaign';
      value: ResolvedProgramSummary | null;
    }
  | {
      field: ProgramDateTimeField;
      value: Date | null;
    }
  | {
      field: ProgramNumberField;
      value: number | null;
    }
  | {
      field: 'status';
      value: ProgramStatusOption;
    }
  | {
      field: 'resolvedTasks';
      value: TaskChangeAndSnapshot;
    }
  | {
      field: 'customFields';
      customFieldName: string;
      customFieldData:
        | {
            type: 'text';
            value: string | null;
          }
        | {
            type: 'number';
            value: number | null;
          }
        | {
            type: 'date-time';
            value: Date | null;
          };
    };

export type ProgramDialogAction = 'UPDATE' | 'CREATE' | 'COPY' | 'TASK_TO_PROGRAM';
