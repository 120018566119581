import React, { MouseEvent, useCallback, useEffect, useRef, useState } from 'react';
import moment from 'moment';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import {
  ErrorMessage,
  FieldArray,
  FieldArrayRenderProps,
  FormikErrors,
  FormikTouched,
} from 'formik';
import { Button, Form } from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import TextareaAutosize from 'react-textarea-autosize';
import 'react-datepicker/dist/react-datepicker.css';
import {
  getProgramStatus,
  NoCampaign,
  ProgramCustomField,
  ProgramDialogAction,
  ProgramEditFormikModel,
  ProgramScheduledType,
  ProgramScheduledTypeOptions,
  ProgramStatusesOptions,
  ProgramStatusOption,
  ProgramTask,
  ProgramType,
  ResolvedCampaign,
  ResolvedProgramSummary,
  UnknownCampaign,
} from '../types';
import { errorToString } from '../../util/utils';
import { CustomFieldMemoised } from './CustomField';
import { Tasks } from './tasks/Tasks';
import { calculateEndDate, calculateStartDate, hexToRGB, ModalDropdownMenuZIndex } from '../util';
import { FormulaScopeUpdater } from './FormulaScopeUpdater';
import clsx from 'clsx';
// eslint-disable-next-line import/no-cycle
import { CampaignProgramsTable } from './CampaignProgramsTable';
import { CirclePicker } from 'react-color';
import { defaultColors } from '../../commons/constants';
import { ColoredSelect } from './ColoredSelect';
import { DivButton } from '../../commons/components/DivButton';
import {
  BinIcon,
  ChevronDownIcon,
  CloseIcon,
  CopyIcon,
  DividerIcon,
  LinkIcon,
  PlugIcon,
  PlusSmallIcon,
} from '../../assets/icons';
import { ProgramOwnerSelect } from './ProgramOwnerSelect';
import { StyleVariables } from '../../commons/styleConstants';
import { CampaignSelect } from './CampaignSelect';
import { TextInputUnderlined } from './TextInputUnderlined';
import CurrencyInput from 'react-currency-input-field';
import { User } from '../../user/types';
import { Attachments } from './attachments/Attachments';
import { useAsyncDebounce } from 'react-table';
import { DebounceMsConfig } from '../../config';
import { AppRoutesHelper } from '../../router/AppRoutes';
import { useNotification } from '../../notification/notificationsContext';
import Linkify from 'react-linkify';
import {
  isScheduled,
  isUnscheduled,
  unscheduledEnd,
  unscheduledStart,
} from '../unscheduledProgramUtil';
import useAuth from '../../auth/authContext';
import { Permission } from '../../auth/permissions';
import { PleaseRelogin } from '../../auth/PleaseRelogin';
import { TaskArrayHelpers } from './tasks/CellProps';
import { AddProgramPermissionHelper, ProgramPermissionHelper } from '../programPermissionHelper';
import { useResolvedCampaigns } from '../hooks/useResolvedCampaigns';
import { v4 as uuidv4 } from 'uuid';
import { displayEndDateToInternal, internalEndDateToDisplay } from '../../util/date-utils';
import _ from 'lodash';
import { ProgramDragDropContext } from './ProgramDragDropContext';
import { PROGRAM_TYPE_BACKGROUND_ALPHA } from '../../program-type/util';
import { FooterError } from '../../commons/components/FooterError';
import { recalculateDueDates } from './tasks/smartDueDateUtils';

export const CampaignColor = (props: {
  campaignColor: string;
  isDisabled: boolean;
  onValueChanged: (v: string) => void;
}) => {
  const { campaignColor, isDisabled, onValueChanged } = props;
  const [displayColorPicker, setDisplayColorPicker] = useState(false);
  return (
    <>
      <div
        className={clsx('program-edit__campaign-color-wrapper', { 'cursor-default': isDisabled })}
        style={{ border: `1px solid ${hexToRGB(campaignColor, 0.5)}` }}
        data-test={`program__campaign-color--${campaignColor}`}
        onClick={() => {
          if (isDisabled) return;
          setDisplayColorPicker((v) => !v);
        }}
        onBlur={() => {
          if (isDisabled) return;
          setDisplayColorPicker(false);
        }}
      >
        <div
          className="program__campaign-color my-auto"
          style={{ backgroundColor: hexToRGB(campaignColor, 1) }}
        />

        {!isDisabled && (
          <div className="program-edit__campaign-color--dropdown my-auto">
            <ChevronDownIcon />
          </div>
        )}
      </div>
      {displayColorPicker ? (
        <div className="program__campaign-color-selector">
          <CirclePicker
            colors={defaultColors}
            color={campaignColor}
            onChangeComplete={(color) => {
              setDisplayColorPicker(false);
              onValueChanged(color.hex.slice(1));
            }}
          />
        </div>
      ) : null}
    </>
  );
};

const newProgramCustomField = (): ProgramCustomField => ({
  localId: uuidv4(),
  type: 'text',
  name: '',
  value: '',
  isNew: true,
});

const ProgramEditStatus = ({
  setValue,
  value,
  isDisabled,
}: {
  setValue: (s: ProgramStatusOption) => void;
  value: ProgramStatusOption;
  isDisabled: boolean;
}) => (
  <Form.Group controlId="status" className="growegy-form__row">
    <label className="growegy-form__label">Status</label>
    <div>
      <ColoredSelect
        initialValue={getProgramStatus(value.id)}
        coloredItems={[...ProgramStatusesOptions]}
        onValueChange={(value) => setValue(value)}
        dataTestPrefix="program-edit-statuses"
        disabled={isDisabled}
        menuZIndex={ModalDropdownMenuZIndex}
      />
    </div>
  </Form.Group>
);

const ProgramEditOwner = ({
  setValue,
  value,
  disabled,
}: {
  setValue: (s: User | null) => void;
  value: User | null;
  disabled: boolean;
}) => (
  <Form.Group controlId="owner" className="growegy-form__row">
    <label className="growegy-form__label">Owner</label>
    <div>
      <ProgramOwnerSelect
        initialValue={value}
        onValueChange={(v) => setValue(v)}
        dataTestPrefix="program-owner-select"
        menuZIndex={ModalDropdownMenuZIndex}
        disabled={disabled}
      />
    </div>
  </Form.Group>
);

const isShowDetailsAvailable = (
  value: ResolvedProgramSummary | null,
  onShowCampaignClick: ((v: ResolvedCampaign) => void) | null
) => onShowCampaignClick && value && value.id !== NoCampaign.id && value.id !== UnknownCampaign.id;

const ProgramEditCampaign = ({
  setValue,
  value,
  disabled,
  required,
  hasError,
  campaigns,
  onShowCampaignClick,
}: {
  setValue: (s: ResolvedProgramSummary | null) => void;
  value: ResolvedProgramSummary | null;
  disabled: boolean;
  required: boolean;
  hasError: boolean;
  campaigns: ResolvedCampaign[];
  onShowCampaignClick: ((v: ResolvedCampaign) => void) | null;
}) => (
  <div>
    <Form.Group controlId="campaign" className="growegy-form__row">
      <label className="growegy-form__label">Campaign</label>
      <div className="d-flex">
        <div>
          <CampaignSelect
            campaigns={campaigns}
            initialValue={value}
            onValueChange={(v) => setValue(v)}
            disabled={disabled}
            hasError={hasError}
            isRequired={required}
            dataTestPrefix="program-campaign-select"
            menuZIndex={ModalDropdownMenuZIndex}
          />
        </div>
        {isShowDetailsAvailable(value, onShowCampaignClick) && (
          <DivButton
            isGray={true}
            className="programs-edit__campaign-details-button"
            text="View details"
            dataTest="programs-edit__campaign-details-button"
            onClick={() => {
              if (value && onShowCampaignClick) onShowCampaignClick(value);
            }}
          />
        )}
      </div>
    </Form.Group>
  </div>
);

const ProgramEditDates = ({
  values,
  disabled,
  readOnly,
  onUnscheduledClick,
  onScheduledClick,
  onWholeDayChange,
  onStartChange,
  onEndChange,
  isStartInvalid,
  isEndInvalid,
}: {
  values: ProgramEditFormikModel;
  disabled: boolean;
  readOnly: boolean;
  onUnscheduledClick: () => void;
  onScheduledClick: () => void;
  onWholeDayChange: (isWholeDay: boolean) => void;
  onStartChange: (date: Date) => void;
  onEndChange: (date: Date) => void;
  isStartInvalid: boolean;
  isEndInvalid: boolean;
}) => {
  const wholeDay = values.wholeDay;
  const onStartChangeCallback = useCallback(
    (date: Date | [Date, Date] | null) => {
      if (date instanceof Date) {
        onStartChange(date);
      }
    },
    [onStartChange]
  );
  const onEndChangeCallback = useCallback(
    (date: Date | [Date, Date] | null) => {
      if (date instanceof Date) {
        onEndChange(displayEndDateToInternal(date, wholeDay));
      }
    },
    [onEndChange, wholeDay]
  );

  return (
    <Form.Group controlId="startDateTime" className="growegy-form__row">
      <div className="d-flex align-items-baseline">
        <label className="growegy-form__label">Dates</label>
      </div>
      <>
        {isUnscheduled(values) && (
          <div>
            <ColoredSelect
              initialValue={
                ProgramScheduledTypeOptions.find(
                  (o) => (o.id as ProgramScheduledType) === 'Unscheduled'
                )!
              }
              coloredItems={[...ProgramScheduledTypeOptions]}
              onValueChange={(value) => {
                if ((value.id as ProgramScheduledType) === 'Scheduled') {
                  onScheduledClick();
                }
              }}
              dataTestPrefix="program-scheduled-options"
              disabled={disabled || readOnly}
              menuZIndex={ModalDropdownMenuZIndex}
            />
          </div>
        )}
        {isScheduled(values) && (
          <div className="d-flex flex-row">
            <div className="program-edit__input--min-sized" data-test="program-edit__start-date">
              <DatePicker
                showTimeSelect={!wholeDay}
                timeIntervals={15}
                dateFormat={wholeDay ? 'MMM dd' : 'MMM dd, hh:mm a'}
                selected={(values.startDateTime && new Date(values.startDateTime)) || null}
                disabled={disabled}
                readOnly={readOnly}
                onChange={onStartChangeCallback}
                className={clsx('growegy-form__input', 'program-edit__input-redesigned--sized', {
                  'is-invalid': isStartInvalid,
                })}
              />
            </div>
            <div className="d-flex align-items-center" style={{ margin: '0 12px', width: '16px' }}>
              <span>to</span>
            </div>
            <div className="program-edit__input--min-sized" data-test="program-edit__end-date">
              <DatePicker
                showTimeSelect={!wholeDay}
                timeIntervals={15}
                dateFormat={wholeDay ? 'MMM dd' : 'MMM dd, hh:mm a'}
                selected={
                  (values.endDateTime &&
                    internalEndDateToDisplay(new Date(values.endDateTime), wholeDay)) ||
                  null
                }
                disabled={disabled}
                readOnly={readOnly}
                onChange={onEndChangeCallback}
                minDate={(values.startDateTime && new Date(values.startDateTime)) || null}
                className={clsx('growegy-form__input', 'program-edit__input-redesigned--sized', {
                  'is-invalid': isEndInvalid,
                })}
              />
            </div>
            {!disabled && !readOnly && (
              <div style={{ marginLeft: '8px' }} className="d-flex">
                <DivButton
                  isGray={true}
                  text={wholeDay ? 'Add time' : 'Remove time'}
                  dataTest={wholeDay ? 'program-edit__add-time' : 'program-edit__remove-time'}
                  onClick={() => onWholeDayChange(!wholeDay)}
                />
                <div style={{ margin: '0 16px' }}>
                  <DividerIcon />
                </div>
                <DivButton
                  isGray={true}
                  text="Remove dates"
                  dataTest="program-edit__unscheduled"
                  onClick={() => onUnscheduledClick()}
                />
              </div>
            )}
          </div>
        )}
      </>
    </Form.Group>
  );
};

const ProgramEditBudget = ({
  setValue,
  initialValue,
  disabled,
  readOnly,
  isInvalid,
}: {
  setValue: (s: number | null | undefined) => void;
  initialValue: number;
  disabled: boolean;
  readOnly: boolean;
  isInvalid: boolean;
}) => {
  const [currentValue, setCurrentValue] = useState<number | string>(initialValue);
  const onChange = useAsyncDebounce((v) => {
    setValue(v ?? undefined);
  }, DebounceMsConfig.programEditInputDebounceMs);

  useEffect(() => {
    setCurrentValue(initialValue);
  }, [initialValue]);

  function handleChange(v: number | null | undefined) {
    setCurrentValue(v ?? '');
    onChange(v);
  }

  return (
    <Form.Group controlId="budget" className="growegy-form__row">
      <label className="growegy-form__label">Budget</label>
      <div>
        <CurrencyInput
          data-test="program-edit__budget"
          name="budget"
          id="buget-field"
          placeholder={disabled || readOnly ? '' : 'Add amount, $'}
          intlConfig={{ locale: 'en-US', currency: 'USD' }}
          disabled={disabled}
          readOnly={readOnly}
          allowDecimals={false}
          value={currentValue}
          onValueChange={(_v1, _v2, v) => handleChange(v?.float)}
          prefix="$"
          className={clsx('growegy-form__input', 'program-edit__input-redesigned--sized', {
            'is-invalid': isInvalid,
          })}
        />
        <ErrorMessage
          component="div"
          name="budget"
          className="invalid-feedback"
          data-test="program-edit__budget-validation-error"
        />
      </div>
    </Form.Group>
  );
};

const ProgramEditVendor = ({
  setValue,
  initialValue,
  disabled,
  readOnly,
  isInvalid,
}: {
  setValue: (s: string) => void;
  initialValue: string;
  disabled: boolean;
  readOnly: boolean;
  isInvalid: boolean;
}) => {
  const [currentValue, setCurrentValue] = useState(initialValue ?? '');
  const onChange = useAsyncDebounce((v) => {
    setValue(v ?? undefined);
  }, DebounceMsConfig.programEditInputDebounceMs);

  useEffect(() => {
    setCurrentValue(initialValue || '');
  }, [initialValue]);

  function handleChange(v: string | null) {
    setCurrentValue(v ?? '');
    onChange(v);
  }

  return (
    <Form.Group controlId="Vendor" className="growegy-form__row">
      <label className="growegy-form__label">Vendor</label>
      <div>
        <input
          name="vendor"
          placeholder={disabled || readOnly ? '' : 'Add vendor'}
          value={currentValue}
          disabled={disabled}
          readOnly={readOnly}
          onChange={(
            e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>
          ) => handleChange(e.target.value)}
          data-test="program-edit__vendor"
          className={clsx('growegy-form__input', 'program-edit__input-redesigned--sized', {
            'is-invalid': isInvalid,
          })}
        />
        <ErrorMessage
          component="div"
          name="vendor"
          className="invalid-feedback"
          data-test="program-edit__vendor-validation-error"
        />
      </div>
    </Form.Group>
  );
};

const ProgramEditDescription = ({
  setValue,
  initialValue,
  disabled,
  readOnly,
  isInvalid,
  urlHighlightOn,
  errorText,
  isError,
  errorClassName,
}: {
  setValue: (s: string) => void;
  initialValue: string;
  disabled: boolean;
  readOnly: boolean;
  isInvalid: boolean;
  urlHighlightOn?: true;
  errorText?: string;
  isError?: boolean;
  errorClassName?: string;
}) => {
  const [currentValue, setCurrentValue] = useState(initialValue ?? '');
  const [editing, setEditing] = useState(!urlHighlightOn);
  const inputRef = useRef<HTMLTextAreaElement>(null);
  const onChange = useAsyncDebounce((v) => {
    setValue(v ?? undefined);
  }, DebounceMsConfig.programEditInputDebounceMs);

  useEffect(() => {
    setCurrentValue(initialValue || '');
  }, [initialValue]);

  useEffect(() => {
    if (!inputRef.current || !urlHighlightOn || !editing) return;
    inputRef.current?.focus();
  }, [editing, urlHighlightOn]);

  const handleEditClick = (e: MouseEvent<HTMLDivElement>) => {
    if (!urlHighlightOn) return;
    e.stopPropagation();
    if (editing) return;
    setEditing(true);
  };

  const moveCursor = () => {
    if (inputRef.current) {
      inputRef.current.selectionStart = inputRef.current.value.length;
    }
  };

  const onBlur = () => {
    if (!urlHighlightOn) return;
    setEditing(false);
  };

  const handleChange = (v: string | null) => {
    setCurrentValue(v ?? '');
    onChange(v);
  };

  return (
    <div>
      <label className="growegy-form__section">Description</label>
      <Form.Group controlId="Notes">
        <div
          onClick={handleEditClick}
          onKeyDown={(e) => {
            if (editing) e.stopPropagation();
          }}
          className="program-edit__description-container"
          data-test="program-edit__notes-switcher"
        >
          <>
            {editing || !currentValue ? (
              <>
                <TextareaAutosize
                  name="notes"
                  value={currentValue}
                  placeholder={disabled || readOnly ? '' : 'Add some details...'}
                  disabled={disabled}
                  readOnly={readOnly}
                  ref={inputRef}
                  data-test="program-edit__notes"
                  onChange={(e) => handleChange(e.target.value)}
                  className={clsx('program-edit__description-input', {
                    'is-invalid': isInvalid,
                  })}
                  onFocus={moveCursor}
                  onBlur={onBlur}
                  onKeyDown={(e) => {
                    if (e.key === 'Escape') onBlur();
                  }}
                />
                <ErrorMessage
                  component="div"
                  name="notes"
                  className="invalid-feedback"
                  data-test="program-edit__notes-validation-error"
                />
              </>
            ) : (
              <>
                <div
                  className={clsx('program-edit__description-input', {
                    'is-invalid': isInvalid,
                  })}
                  data-test="program-edit__notes-view"
                >
                  <Linkify
                    componentDecorator={(decoratedHref, decoratedText, key) => (
                      <a
                        target="blank"
                        href={decoratedHref}
                        key={key}
                        onClick={(e) => {
                          e.stopPropagation();
                          setEditing(false);
                        }}
                      >
                        {decoratedText}
                      </a>
                    )}
                  >
                    {currentValue}
                  </Linkify>
                </div>
                {isError && errorText && (
                  <div
                    className={errorClassName ?? 'invalid-feedback'}
                    data-test="program-edit__notes-validation-error"
                  >
                    {errorText}
                  </div>
                )}
              </>
            )}
          </>
        </div>
      </Form.Group>
    </div>
  );
};

const ProgramEditName = ({
  initialValue,
  setValue,
  disabled,
  readonly,
  isInvalid,
  isCampaign,
  action,
  scrollToTaskId,
}: {
  initialValue: string;
  setValue: (s: string) => void;
  disabled: boolean;
  readonly: boolean;
  isCampaign: boolean;
  isInvalid: boolean;
  action: ProgramDialogAction;
  scrollToTaskId: string | null;
}) => {
  const inputNameRef = useRef<HTMLInputElement>(null);
  useEffect(() => {
    if (!disabled && scrollToTaskId === null) inputNameRef.current?.focus();
  }, [action, disabled, scrollToTaskId]);
  const [currentValue, setCurrentValue] = useState(initialValue ?? '');
  const onChange = useAsyncDebounce((v) => {
    setValue(v ?? undefined);
  }, DebounceMsConfig.programEditInputDebounceMs);

  useEffect(() => {
    setCurrentValue(initialValue || '');
  }, [initialValue]);

  function handleChange(v: string | null) {
    setCurrentValue(v ?? '');
    onChange(v);
  }

  return (
    <div className="d-flex align-items-center w-100" style={{ marginTop: '24px' }}>
      <Form.Group controlId="name" className="w-100">
        <input
          type="text"
          autoComplete="off"
          ref={inputNameRef}
          value={currentValue}
          placeholder={isCampaign ? 'Add campaign name' : 'Add program name'}
          onChange={(e) => handleChange(e.target.value)}
          disabled={disabled}
          readOnly={readonly}
          data-test="program-edit__name"
          className={clsx('program-edit__input-redesigned--name w-100', {
            'is-invalid': isInvalid,
          })}
        />
        <ErrorMessage
          component="div"
          name="name"
          className="invalid-feedback"
          data-test="program-edit__name-validation-error"
        />
      </Form.Group>
    </div>
  );
};

const getStartOfDay = (dateTime: Date) => moment(dateTime).startOf('day').toDate();

const getFirstValidationError = (
  touched: FormikTouched<ProgramEditFormikModel>,
  errors: FormikErrors<ProgramEditFormikModel>
) => {
  const touchedSet = new Set(Object.keys(touched));
  const filteredErrors = Object.entries(errors).filter(([key]) => touchedSet.has(key));
  const requiredFields = filteredErrors
    .filter(([, value]) => {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const v: any = value;
      return (
        (typeof v === 'string' && v.toLowerCase().includes('required')) ||
        JSON.stringify(value).toLowerCase().includes('not be one of')
      );
    })
    .map(([key]) => key);

  if (requiredFields.length) {
    return `Fill in the required fields: ${requiredFields.map((f) => _.capitalize(f)).join(', ')}`;
  }

  if (errors.customFields || errors.formulaScope) {
    return 'Custom fields require proper names and values';
  }

  if (errors.customFieldsNameSums) {
    return 'Custom fields names must be unique';
  }

  const wrongValuesFields = filteredErrors.map(([key]) => _.capitalize(key));
  if (wrongValuesFields.length) {
    return `Fix the values of the following fields: ${wrongValuesFields.join(', ')}`;
  }

  return null;
};

export const ProgramEditFormik = ({
  touched,
  errors,
  isValid,
  handleSubmit,
  handleChange,
  handleBlur,
  values,
  setFieldValue,
  setFieldTouched,
  initialValues,
  programTypes,
  action,
  disabled,
  createError,
  updateError,
  deleteError,
  handleHideClick,
  handleRefetchClick,
  handleDeleteClick,
  handleCopyClick,
  handleConvertTaskToProgramClick,
  handleShowCampaignClick,
  scrollToTaskId,
  programPermissionHelper,
  addProgramPermissionHelper,
}: {
  touched: FormikTouched<ProgramEditFormikModel>;
  errors: FormikErrors<ProgramEditFormikModel>;
  isValid: boolean;
  handleSubmit: (e?: React.FormEvent<HTMLFormElement> | undefined) => void;
  handleChange: (
    event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>
  ) => void;
  handleBlur: (event: React.FocusEvent<HTMLInputElement>) => void;
  values: ProgramEditFormikModel;
  setFieldValue: (
    field: keyof ProgramEditFormikModel,
    value: unknown,
    shouldValidate?: boolean | undefined
  ) => void;
  setFieldTouched: (
    field: keyof ProgramEditFormikModel,
    isTouched?: boolean | undefined,
    shouldValidate?: boolean | undefined
  ) => void;
  initialValues: ProgramEditFormikModel;
  disabled: boolean;
  programTypes: ProgramType[];
  createError: unknown;
  updateError: unknown;
  deleteError: unknown;
  handleHideClick: () => void;
  handleRefetchClick: () => Promise<void>;
  handleDeleteClick: () => Promise<void>;
  handleCopyClick: () => void;
  handleConvertTaskToProgramClick: (task: ProgramTask) => void;
  handleShowCampaignClick: (c: ResolvedCampaign) => void;
  action: ProgramDialogAction;
  scrollToTaskId: string | null;
  programPermissionHelper: ProgramPermissionHelper;
  addProgramPermissionHelper: AddProgramPermissionHelper;
}) => {
  const {
    state: { user },
  } = useAuth();

  const handleBlurMemoized = useCallback(
    (event: React.FocusEvent<HTMLInputElement>) => handleBlur(event),
    [handleBlur]
  );
  const inputNameRef = useRef<HTMLInputElement>();
  useEffect(() => {
    if (!disabled && scrollToTaskId === null) inputNameRef.current?.focus();
  }, [action, disabled, scrollToTaskId]);
  const { notifySuccess } = useNotification();
  const { data: campaigns } = useResolvedCampaigns();

  const isCampaign = initialValues.programKind === 'CAMPAIGN';

  const salesforceInputRef = useRef<HTMLInputElement>(null);
  const [submitButtonTooltipId] = useState<string>(
    `submit-button-tooltip__${initialValues.programKind}-${uuidv4()}`
  );

  useEffect(() => {
    if (!salesforceInputRef.current) return;
    if (!values.isSalesforceInputFocused) return;
    salesforceInputRef.current?.focus();
  }, [values.isSalesforceInputFocused]);

  const onScheduledClick = () => {
    if (isUnscheduled(values)) {
      let start: Date;
      let end: Date;
      if (isScheduled(initialValues)) {
        start = initialValues.startDateTime;
        end = initialValues.endDateTime;
      } else {
        start = calculateStartDate(values.wholeDay);
        end = calculateEndDate(start, values.wholeDay);
      }

      setFieldValue('startDateTime', start);
      setFieldValue('endDateTime', end);
      setFieldTouched('startDateTime', true);
      setFieldTouched('endDateTime', true);
      recalculateTaskDueDates(start, end, values.wholeDay);
    }
  };

  const onUnscheduledClick = () => {
    if (isScheduled(values)) {
      const [start, end] = isUnscheduled(initialValues)
        ? [initialValues.startDateTime, initialValues.endDateTime]
        : [unscheduledStart(initialValues.id), unscheduledEnd(initialValues.id)];

      setFieldValue('startDateTime', start);
      setFieldValue('endDateTime', end);
      setFieldValue('wholeDay', true);
      setFieldTouched('startDateTime', true);
      setFieldTouched('endDateTime', true);
      setFieldTouched('wholeDay', true);
      recalculateTaskDueDates(start, end, true);
    }
  };

  const onWholeDayChange = (isWholeDay: boolean) => {
    setFieldValue('wholeDay', isWholeDay);
    let start: Date;
    let end: Date;
    if (isWholeDay) {
      start = getStartOfDay(values.startDateTime);
      end = displayEndDateToInternal(getStartOfDay(values.endDateTime), true);
    } else {
      const displayEnd = internalEndDateToDisplay(values.endDateTime, true);
      start = values.startDateTime;
      end = moment(displayEnd)
        .add(values.startDateTime.valueOf() === displayEnd.valueOf() ? 1 : 0, 'hour')
        .toDate();
    }

    setFieldValue('startDateTime', start);
    setFieldValue('endDateTime', end);
    setFieldTouched('startDateTime', true, true);
    setFieldTouched('endDateTime', true, true);
    setFieldTouched('wholeDay', true, true);
    recalculateTaskDueDates(start, end, isWholeDay);
  };

  const [taskInFocusOrderId, setTaskInFocusOrderId] = useState<number | null>(null);
  const wrapArrayHelpers = (internal: TaskArrayHelpers): TaskArrayHelpers => ({
    push: (value) => {
      setTaskInFocusOrderId(value.orderId);
      internal.push(value);
    },
    insert: (index, value) => {
      setTaskInFocusOrderId(value.orderId);
      internal.insert(index, value);
    },
    remove: (index) => {
      setTaskInFocusOrderId(null);
      internal.remove(index);
    },
    replace: (index, value) => {
      setTaskInFocusOrderId(null);
      internal.replace(index, value);
    },
    move: (from, to) => {
      setTaskInFocusOrderId(null);
      internal.move(from, to);
    },
  });

  const [initialCustomFields, setInitialCustomFields] = useState(initialValues.customFields);
  useEffect(() => setInitialCustomFields(initialValues.customFields), [initialValues]);
  const onCustomFieldDelete = useCallback(
    ({ arrayHelpers, index }: { arrayHelpers: FieldArrayRenderProps; index: number }) => {
      arrayHelpers.remove(index);
      const newCustomFields = [...initialCustomFields];
      newCustomFields.splice(index, 1);
      setInitialCustomFields(newCustomFields);
    },
    [initialCustomFields, setInitialCustomFields]
  );
  const onCustomFieldSetValue = useCallback(
    (field: string, value: unknown, resetTouched?: boolean | undefined) => {
      // @ts-ignore
      setFieldTouched(field, !resetTouched);
      // @ts-ignore
      setFieldValue(field, value);
    },
    [setFieldTouched, setFieldValue]
  );

  if (!user) return <PleaseRelogin />;
  const canChangeRegularField =
    action !== 'UPDATE' || programPermissionHelper.canEditRegularField();
  const canSave = action !== 'UPDATE' || programPermissionHelper.canChangeAnything();

  const canManageAttachments = programPermissionHelper.canManageAttachments();
  const firstValidationError = getFirstValidationError(touched, errors);

  const recalculateTaskDueDates = (programStart: Date, programEnd: Date, wholeDay: boolean) => {
    const updatedTasks = recalculateDueDates(
      programStart,
      programEnd,
      wholeDay,
      values.resolvedTasks,
      false
    );
    if (updatedTasks) {
      setFieldValue('resolvedTasks', updatedTasks, true);
      setFieldTouched('resolvedTasks', true, true);
    }
  };
  return (
    <Form onSubmit={handleSubmit}>
      <div
        className="program-edit__header"
        style={{
          background: isCampaign
            ? hexToRGB(values.campaignColor!, PROGRAM_TYPE_BACKGROUND_ALPHA)
            : hexToRGB(values.type.color, PROGRAM_TYPE_BACKGROUND_ALPHA),
        }}
      >
        <div className="d-flex align-items-center w-100">
          <div className="program-edit__input--min-sized" style={{ minHeight: '38px' }}>
            {isCampaign ? (
              <CampaignColor
                campaignColor={
                  values.programKind === 'CAMPAIGN'
                    ? values.campaignColor!
                    : values.campaign!.campaignColor!
                }
                isDisabled={!canChangeRegularField}
                onValueChanged={(v) => setFieldValue('campaignColor', v)}
              />
            ) : (
              <ColoredSelect
                initialValue={values.type}
                coloredItems={programTypes}
                onValueChange={(value) => setFieldValue('type', value)}
                dataTestPrefix="program-type-select"
                disabled={!canChangeRegularField}
                hasError={!!errors.type && !!touched.type}
                initDummyItem={(id) => ({
                  id: id,
                  name: 'Unknown',
                  color: StyleVariables.unknownEntity,
                })}
                menuZIndex={ModalDropdownMenuZIndex}
              />
            )}
          </div>
          {action === 'UPDATE' && !disabled && (
            <div className="ml-auto d-flex flex-row align-items-center">
              <CopyToClipboard text={AppRoutesHelper.getProgramDeepLinkUrl(initialValues.id)}>
                <DivButton
                  icon={<LinkIcon />}
                  onClick={() => {
                    notifySuccess({ notificationMsg: 'Link is copied.' });
                  }}
                  dataTest="program-edit__copy-link"
                  className="growegy-modal__header-button"
                />
              </CopyToClipboard>
              {addProgramPermissionHelper.canCreate(initialValues.programKind) && (
                <DivButton
                  icon={<CopyIcon />}
                  onClick={handleCopyClick}
                  dataTest="program-edit__copy"
                  className="growegy-modal__header-button"
                />
              )}
              {user.has(Permission.DELETE_PROGRAM) && (
                <DivButton
                  icon={<BinIcon />}
                  onClick={handleDeleteClick}
                  dataTest="program-edit__delete"
                  className="growegy-modal__header-button"
                />
              )}
              <div style={{ margin: '0 12px' }}>
                <DividerIcon />
              </div>
              <DivButton
                icon={<CloseIcon />}
                onClick={handleHideClick}
                dataTest="program-edit__close"
                className="growegy-modal__header-button"
              />
            </div>
          )}
          {action !== 'UPDATE' && !disabled && (
            <div className="ml-auto d-flex flex-row align-items-center">
              <DivButton
                icon={<CloseIcon />}
                onClick={handleHideClick}
                dataTest="program-edit__close"
                className="growegy-modal__header-button"
              />
            </div>
          )}
        </div>
        <ProgramEditName
          isInvalid={!!touched.name && !!errors.name}
          disabled={disabled}
          readonly={!canChangeRegularField}
          initialValue={initialValues.name}
          setValue={(v) => {
            setFieldTouched('name');
            setFieldValue('name', v, true);
          }}
          scrollToTaskId={scrollToTaskId}
          action={action}
          isCampaign={isCampaign}
        />
      </div>

      <div className="growegy-modal__form-body">
        <div className="program-edit__details-container">
          <ProgramEditStatus
            value={values.status}
            setValue={(v) => setFieldValue('status', v)}
            isDisabled={disabled || !canChangeRegularField}
          />
          <ProgramEditOwner
            value={values.owner}
            setValue={(v) => setFieldValue('owner', v)}
            disabled={disabled || !programPermissionHelper.canChangeProgramOwner()}
          />
          {!isCampaign && (
            <ProgramEditCampaign
              value={values.campaign}
              setValue={(v) => setFieldValue('campaign', v)}
              campaigns={programPermissionHelper.getCampaignOptions(campaigns)}
              onShowCampaignClick={handleShowCampaignClick}
              disabled={
                disabled || (action === 'UPDATE' && !programPermissionHelper.canChangeCampaign())
              }
              required={
                action === 'UPDATE'
                  ? !programPermissionHelper.canMakeStandalone()
                  : !addProgramPermissionHelper.canCreateStandaloneProgram()
              }
              hasError={!!errors.campaign && !!touched.campaign}
            />
          )}
          <ProgramEditDates
            values={values}
            disabled={disabled}
            readOnly={!canChangeRegularField}
            onScheduledClick={onScheduledClick}
            onUnscheduledClick={onUnscheduledClick}
            onWholeDayChange={onWholeDayChange}
            onStartChange={(d) => {
              setFieldValue('startDateTime', d, true);
              let newProgramStart = d;
              let newProgramEnd = values.endDateTime;
              if (newProgramEnd <= newProgramStart) {
                newProgramEnd = moment(d)
                  .add(1, values.wholeDay ? 'day' : 'hour')
                  .toDate();
                setFieldValue('endDateTime', newProgramEnd, true);
              }

              setFieldTouched('endDateTime', true, true);
              setFieldTouched('startDateTime', true, true);
              recalculateTaskDueDates(newProgramStart, newProgramEnd, values.wholeDay);
            }}
            onEndChange={(d) => {
              setFieldTouched('endDateTime', true, true);
              setFieldTouched('startDateTime', true, true);
              setFieldValue('endDateTime', d, true);
              recalculateTaskDueDates(values.startDateTime, d, values.wholeDay);
            }}
            isStartInvalid={!!touched.startDateTime && !!errors.startDateTime}
            isEndInvalid={!!touched.endDateTime && !!errors.endDateTime}
          />
          <Form.Group controlId="leads" className="growegy-form__row">
            <label className="growegy-form__label">Leads</label>
            <div className="d-flex">
              <input
                type="number"
                name="actualLeads"
                placeholder={canChangeRegularField ? 'Add actual' : ''}
                value={values.actualLeads === null ? '' : values.actualLeads}
                disabled={disabled}
                readOnly={!canChangeRegularField}
                onChange={(
                  e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>
                ) => {
                  const newValue = e.target.value ? +e.target.value : null;
                  setFieldTouched('actualLeads');
                  setFieldValue('actualLeads', newValue, true);
                }}
                className={clsx('growegy-form__input', 'program-edit__input-redesigned--sized', {
                  'is-invalid': touched.actualLeads && errors.actualLeads,
                })}
                data-test="program-edit__actual-leads"
              />
              <ErrorMessage
                component="div"
                name="actualLeads"
                className="invalid-feedback"
                data-test="program-edit__actual-leads-validation-error"
              />
              <>
                {values.isSalesforceInputFocused && (
                  <div style={{ margin: '0 12px' }}>
                    <TextInputUnderlined
                      value={values.salesforceCampaignId ?? ''}
                      setValue={(v) => {
                        if (!v) {
                          setFieldValue('actualLeads', 0, true);
                          setFieldTouched('actualLeads', true, true);
                          setFieldTouched('salesforceCampaignId', true, true);
                        }
                        setFieldValue('salesforceCampaignId', v, true);
                      }}
                      editing={values.isSalesforceInputFocused}
                      setEditing={(v) => setFieldValue('isSalesforceInputFocused', v)}
                    >
                      {({ onBlur, setValue, value }) => (
                        <div className="search-input__wrapper">
                          <input
                            type="text"
                            name="salesforceCampaignId"
                            value={value}
                            ref={salesforceInputRef}
                            placeholder={canChangeRegularField ? 'Add Salesforce Campaign Id' : ''}
                            disabled={disabled}
                            readOnly={!canChangeRegularField}
                            onBlur={(e) => {
                              setTimeout(() => onBlur(), 100);
                              e.stopPropagation();
                            }}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                              setValue(e.target.value)
                            }
                            data-test="program-edit__salesforceCampaignId"
                            onKeyDown={(e) => {
                              if (e.key === 'Escape') {
                                onBlur();
                                e.stopPropagation();
                              }
                            }}
                            className="program-edit__salesforce-input-redesigned"
                          />
                          <CloseIcon
                            className="search-input__close"
                            onClick={(e) => {
                              setValue('');
                              onBlur();
                              e.stopPropagation();
                            }}
                          />
                        </div>
                      )}
                    </TextInputUnderlined>
                  </div>
                )}
              </>
              <>
                {!values.isSalesforceInputFocused && values.salesforceCampaignId && (
                  <DivButton
                    icon={<PlugIcon />}
                    onClick={() => setFieldValue('isSalesforceInputFocused', true)}
                    className="growegy-modal__header-button"
                    dataTest="program-edit__showSalesforceCampaignId"
                  />
                )}
              </>
              <div
                className="d-flex align-items-center"
                style={{ margin: '0 12px', width: '16px' }}
              >
                <span>of</span>
              </div>
              <div>
                <input
                  type="number"
                  name="leads"
                  placeholder={canChangeRegularField ? 'Add projected' : ''}
                  value={values.leads}
                  disabled={disabled}
                  readOnly={!canChangeRegularField}
                  onChange={(e) => {
                    setFieldTouched('leads');
                    handleChange(e);
                  }}
                  className={clsx('growegy-form__input', 'program-edit__input-redesigned--sized', {
                    'is-invalid': touched.leads && errors.leads,
                  })}
                  data-test="program-edit__leads"
                />
                <ErrorMessage
                  component="div"
                  name="leads"
                  className="invalid-feedback"
                  data-test="program-edit__leads-validation-error"
                />
              </div>
              <>
                {!isCampaign &&
                  !values.isSalesforceInputFocused &&
                  !values.salesforceCampaignId &&
                  !disabled &&
                  canChangeRegularField && (
                    <DivButton
                      isGray={true}
                      text="Use Salesforce"
                      className="program-edit__use-salesforce"
                      dataTest="program-edit__useSalesforceCampaignId"
                      onClick={() => setFieldValue('isSalesforceInputFocused', true)}
                    />
                  )}
              </>
            </div>
          </Form.Group>
          <ProgramEditBudget
            initialValue={initialValues.budget}
            disabled={disabled}
            readOnly={!canChangeRegularField}
            setValue={(v) => {
              setFieldTouched('budget');
              setFieldValue('budget', v ?? '', true);
            }}
            isInvalid={!!touched.budget && !!errors.budget}
          />
          {!isCampaign && (
            <ProgramEditVendor
              initialValue={initialValues.vendor}
              disabled={disabled}
              readOnly={!canChangeRegularField}
              setValue={(v) => {
                setFieldTouched('vendor');
                setFieldValue('vendor', v, true);
              }}
              isInvalid={!!touched.vendor && !!errors.vendor}
            />
          )}
          <FieldArray name="customFields">
            {(arrayHelpers) => (
              <>
                {initialCustomFields.map((customField, index) => (
                  <CustomFieldMemoised
                    formikValues={values}
                    key={`${customField.localId}`}
                    initialCustomField={customField}
                    index={index}
                    disabled={disabled}
                    readOnly={!canChangeRegularField}
                    errors={errors}
                    touched={touched}
                    arrayHelpers={arrayHelpers}
                    onDelete={onCustomFieldDelete}
                    handleBlur={handleBlurMemoized}
                    setFieldValue={onCustomFieldSetValue}
                  />
                ))}

                {!disabled && canChangeRegularField && (
                  <Form.Group className="growegy-form__row">
                    <label className="growegy-form__label" />
                    <DivButton
                      isGray={true}
                      className="common-table__add-new-btn"
                      icon={<PlusSmallIcon />}
                      text="Add new field"
                      dataTest="program-edit__add-custom-field"
                      onClick={() => {
                        const newCustomField = newProgramCustomField();
                        arrayHelpers.push(newCustomField);
                        setInitialCustomFields([...initialCustomFields, newCustomField]);
                      }}
                    />
                  </Form.Group>
                )}
              </>
            )}
          </FieldArray>
        </div>
        <ProgramEditDescription
          initialValue={initialValues.notes}
          disabled={disabled}
          readOnly={!canChangeRegularField}
          setValue={(v) => {
            setFieldTouched('notes');
            setFieldValue('notes', v, true);
          }}
          isInvalid={!!touched.notes && !!errors.notes}
          urlHighlightOn={true}
          isError={!!errors.notes && touched.notes}
          errorText={errors.notes}
          errorClassName="program-edit__input-error"
        />
        {!isCampaign && (
          <Form.Group className="growegy-form__row">
            <div data-test="program-edit__tasks" className="w-100">
              <FieldArray name="resolvedTasks">
                {(arrayHelpers) => (
                  <ProgramDragDropContext
                    programSummaries={[values]}
                    taskArrayHelpersFactory={() => arrayHelpers}
                  >
                    <Tasks
                      program={values}
                      values={values.resolvedTasks}
                      arrayHelpers={wrapArrayHelpers(arrayHelpers)}
                      scrollToTaskId={scrollToTaskId}
                      emphasizeFirstColumn={true}
                      isReadonly={false}
                      taskInFocusOrderId={taskInFocusOrderId}
                      onConvertTaskToProgram={handleConvertTaskToProgramClick}
                    />
                  </ProgramDragDropContext>
                )}
              </FieldArray>
            </div>
          </Form.Group>
        )}
        {isCampaign && (
          <CampaignProgramsTable
            campaign={values}
            onChanged={() => {
              setFieldTouched('isChangedCampaignPrograms');
              setFieldValue('isChangedCampaignPrograms', true, true);
            }}
          />
        )}
        <div>
          <label className="growegy-form__section">Attachments</label>
          <Attachments
            values={values.attachments}
            id={values.id}
            hasAddAccess={canManageAttachments}
            hasDeleteAccess={canManageAttachments}
          />
        </div>
      </div>
      <div className="program-edit__footer">
        <>
          {createError && (
            <FooterError
              dataTest="program-edit__alert-warning"
              message={`Failed to create ${isCampaign ? 'campaign' : 'program'}: ${errorToString(
                createError
              )}`}
            />
          )}
          {updateError && (
            <>
              <FooterError
                dataTest="program-edit__alert-warning"
                message={`Failed to update ${isCampaign ? 'campaign' : 'program'}: ${errorToString(
                  updateError
                )}`}
              />
              <Button
                className="button-apply"
                onClick={handleRefetchClick}
                disabled={disabled}
                data-test="program-edit__refetch"
              >
                Refetch
              </Button>
            </>
          )}
          {deleteError && (
            <FooterError
              dataTest="program-edit__alert-warning"
              message={`Failed to delete program: ${errorToString(deleteError)}`}
            />
          )}
          {!isValid && firstValidationError && (
            <FooterError dataTest="program-edit__validation-error" message={firstValidationError} />
          )}
          <DivButton
            onClick={handleHideClick}
            isGray={true}
            dataTest="program-edit__cancel"
            className="div-button--cancel"
            text={canSave ? 'Cancel' : 'Close'}
          />
          {canSave && (
            <>
              <div data-tooltip-id={submitButtonTooltipId} data-tip-disable={false}>
                <Button
                  type="submit"
                  variant="primary"
                  className="button-apply"
                  disabled={disabled}
                  data-test="program-edit__apply"
                >
                  <span>
                    {action === 'UPDATE'
                      ? `Save ${isCampaign ? 'campaign' : 'program'}`
                      : `Add ${isCampaign ? 'campaign' : 'program'}`}
                  </span>
                </Button>
              </div>
            </>
          )}
        </>
      </div>
      <FormulaScopeUpdater />
    </Form>
  );
};
