import {
  FilteringField,
  FilteringFieldArrayItem,
  FilteringFieldMeta,
  FilteringFieldType,
  MultiFilterRow,
  MultiFilterRowPersistable,
  MultiFilterRows,
  MultiFilterRowsPersistable,
} from './types';
import {
  BooleanFilters,
  DateFilters,
  MultiSelectFilters,
  NumberFilters,
  StringFilters,
} from './filterImplementations';
import {
  CustomFieldType,
  ExistedCustomFields,
  ProgramStatusesOptions,
  ProgramType,
  ResolvedCampaign,
  ResolvedProgramSummary,
  TODO_PROGRAM_STATUS,
  UnknownCampaign,
  UnknownProgramType,
} from '../program/types';
import { newRenderId } from '../util/utils';
import { UnknownProgramOwner, User } from '../user/types';
import { applyFilters } from './filterLogic';
import { isScheduled } from '../program/unscheduledProgramUtil';
import { internalEndDateToDisplay } from '../util/date-utils';

export type SelectedFilter =
  | {
      type: 'PersistedFilter';
      filter: MultiFilterRowsPersistable<ResolvedProgramSummary>;
      index: number;
    }
  | { type: 'NewFilter'; filter: MultiFilterRowsPersistable<ResolvedProgramSummary> };

const defaultValue = (type: FilteringFieldType): unknown => {
  switch (type) {
    case 'boolean':
      return false;
    case 'string':
      return '';
    case 'number':
      return 0;
    case 'date':
      return new Date();
    case 'user':
      return [];
    case 'program-status':
      return [];
    case 'program-type':
      return [];
    case 'campaign':
      return [];
    default:
      throw new Error(`Unsupported multi filter field type: ${type}`);
  }
};

const toValuePersistable: (fieldMetaData: FilteringFieldMeta, filterValue: unknown) => unknown = (
  fieldMetaData: FilteringFieldMeta,
  filterValue: unknown
) => {
  switch (fieldMetaData.type) {
    case 'boolean':
    case 'string':
    case 'number':
      return filterValue;
    case 'date':
      return (filterValue as Date).toISOString();
    case 'user':
    case 'campaign':
    case 'program-type':
    case 'program-status':
      return ((filterValue instanceof Array ? filterValue : []) as { id: string }[]).map(
        ({ id }) => id
      );
    default:
      throw new Error(`Unsupported for persistence multi filter field type: ${fieldMetaData.type}`);
  }
};

const buildFilterMeta = (type: FilteringFieldType): FilteringFieldMeta => ({
  type,
  defaultValue: defaultValue(type),
});

const customFieldTypeToFilteringType = (c: CustomFieldType): FilteringFieldType => {
  switch (c) {
    case 'text':
      return 'string';
    case 'number':
      return 'number';
    case 'date-time':
      return 'date';
    case 'formula':
      return 'number';
  }
  throw new Error(`Unsupported custom field type to be used for filtering: ${c}`);
};

const getFiltersForCustomField = (customField: FilteringFieldArrayItem) => {
  const customFieldExtractor = (s: ResolvedProgramSummary) =>
    s.customFields.find((f) => f.name === customField.name && f.type === customField.type);
  switch (customField.type) {
    case 'text':
      return StringFilters<ResolvedProgramSummary>((s) => customFieldExtractor(s)?.value);
    case 'number':
      return NumberFilters<ResolvedProgramSummary>((s) => customFieldExtractor(s)?.value);
    case 'formula': {
      return NumberFilters<ResolvedProgramSummary>((s) => {
        const formulaField = customFieldExtractor(s);
        if (formulaField && formulaField.type === 'formula') return formulaField.result;
        return undefined;
      });
    }
    case 'date-time':
      return DateFilters<ResolvedProgramSummary>((s) => customFieldExtractor(s)?.value);
  }
  return [];
};

const customFieldToFilteringField = (
  customField: FilteringFieldArrayItem
): FilteringField<ResolvedProgramSummary> | null => {
  const fieldMetaData = buildFilterMeta(customFieldTypeToFilteringType(customField.type));
  const filters = getFiltersForCustomField(customField);
  return {
    fieldIdentity: {
      type: 'ArrayFieldItem',
      arrayField: 'customFields',
      arrayItemValue: { ...customField },
    },
    fieldMetaData,
    label: customField.name,
    filters,
  };
};

const customFieldTypeToNumber = (x: CustomFieldType) => {
  switch (x) {
    case 'text':
      return 0;
    case 'number':
      return 1;
    case 'date-time':
      return 2;
    case 'formula':
      return 3;
  }
  return 0;
};

const compareCustomFieldTypes = (a: CustomFieldType, b: CustomFieldType) =>
  customFieldTypeToNumber(a) - customFieldTypeToNumber(b);

export const getProgramFilteringFields = (
  existedCustomFields: ExistedCustomFields
): FilteringField<ResolvedProgramSummary>[] => {
  const customFilteringFields = getCustomFilteringFields(existedCustomFields);
  const basicFilteringFields = getBasicFilteringFields();

  return basicFilteringFields.concat(customFilteringFields);
};

const getCustomFilteringFields = (
  existedCustomFields: ExistedCustomFields
): FilteringField<ResolvedProgramSummary>[] => {
  const stringFields: FilteringFieldArrayItem[] = Array.from(existedCustomFields.strings).map(
    (name) => ({
      name,
      type: 'text',
    })
  );
  const numberFields: FilteringFieldArrayItem[] = Array.from(existedCustomFields.numbers).map(
    (name) => ({
      name,
      type: 'number',
    })
  );
  const formulaFields: FilteringFieldArrayItem[] = Array.from(existedCustomFields.formulas).map(
    (name) => ({
      name,
      type: 'formula',
    })
  );
  const dateFields: FilteringFieldArrayItem[] = Array.from(existedCustomFields.dates).map(
    (name) => ({
      name,
      type: 'date-time',
    })
  );

  const sorted = stringFields
    .concat(numberFields)
    .concat(dateFields)
    .concat(formulaFields)
    .sort((a, b) => a.name.localeCompare(b.name) || compareCustomFieldTypes(a.type, b.type));
  return sorted.map(customFieldToFilteringField).flatMap((f) => (f !== null ? [f] : []));
};

const getBasicFilteringFields = (): FilteringField<ResolvedProgramSummary>[] => [
  {
    fieldIdentity: {
      type: 'StaticField',
      fieldName: 'name',
    },
    fieldMetaData: buildFilterMeta('string'),
    label: 'Name',
    filters: StringFilters((s) => s.name),
  },
  {
    fieldIdentity: {
      type: 'StaticField',
      fieldName: 'budget',
    },
    fieldMetaData: buildFilterMeta('number'),
    label: 'Budget',
    filters: NumberFilters((s) => s.budget),
  },
  {
    fieldIdentity: {
      type: 'StaticField',
      fieldName: 'leads',
    },
    fieldMetaData: buildFilterMeta('number'),
    label: 'Projected Leads',
    filters: NumberFilters((s) => s.leads),
  },
  {
    fieldIdentity: {
      type: 'StaticField',
      fieldName: 'actualLeads',
    },
    fieldMetaData: buildFilterMeta('number'),
    label: 'Actual Leads',
    filters: NumberFilters((s) => s.actualLeads),
  },
  {
    fieldIdentity: {
      type: 'StaticField',
      fieldName: 'startDateTime',
    },
    fieldMetaData: buildFilterMeta('date'),
    label: 'Start date',
    filters: DateFilters((s) => (isScheduled(s) ? s.startDateTime : null)),
  },
  {
    fieldIdentity: {
      type: 'StaticField',
      fieldName: 'endDateTime',
    },
    fieldMetaData: buildFilterMeta('date'),
    label: 'End date',
    filters: DateFilters((s) =>
      isScheduled(s) ? internalEndDateToDisplay(s.endDateTime, s.wholeDay) : null
    ),
  },
  {
    fieldIdentity: {
      type: 'StaticField',
      fieldName: 'wholeDay',
    },
    fieldMetaData: buildFilterMeta('boolean'),
    label: 'Whole date',
    filters: BooleanFilters((s) => s.wholeDay),
  },
  {
    fieldIdentity: {
      type: 'StaticField',
      fieldName: 'notes',
    },
    fieldMetaData: buildFilterMeta('string'),
    label: 'Notes',
    filters: StringFilters((s) => s.notes),
  },
  {
    fieldIdentity: {
      type: 'StaticField',
      fieldName: 'vendor',
    },
    fieldMetaData: buildFilterMeta('string'),
    label: 'Vendor',
    filters: StringFilters((s) => s.vendor),
  },
  {
    fieldIdentity: {
      type: 'StaticField',
      fieldName: 'salesforceCampaignId',
    },
    fieldMetaData: buildFilterMeta('string'),
    label: 'Salesforce Campaign',
    filters: StringFilters((s) => s.salesforceCampaignId),
  },
  {
    fieldIdentity: {
      type: 'StaticField',
      fieldName: 'owner',
    },
    fieldMetaData: buildFilterMeta('user'),
    label: 'Owner',
    filters: MultiSelectFilters(
      (s) => (s.owner !== null ? s.owner.id : ''),
      (item) => (item as { id: string }).id
    ),
  },
  {
    fieldIdentity: {
      type: 'StaticField',
      fieldName: 'status',
    },
    fieldMetaData: buildFilterMeta('program-status'),
    label: 'Status',
    filters: MultiSelectFilters(
      (s) => s.status.id,
      (item) => (item as { id: string }).id
    ),
  },
  {
    fieldIdentity: {
      type: 'StaticField',
      fieldName: 'type',
    },
    fieldMetaData: buildFilterMeta('program-type'),
    label: 'Type',
    filters: MultiSelectFilters(
      (s) => s.type.id,
      (item) => (item as { id: string }).id
    ),
  },
  {
    fieldIdentity: {
      type: 'StaticField',
      fieldName: 'campaign',
    },
    fieldMetaData: buildFilterMeta('campaign'),
    label: 'Campaign',
    filters: MultiSelectFilters(
      (s) => (s.campaign !== null ? s.campaign.id : ''),
      (item) => (item as { id: string }).id
    ),
  },
];

export const realFilterToPersistable: (f: {
  name: string;
  filter: MultiFilterRows<ResolvedProgramSummary>;
}) => MultiFilterRowsPersistable<ResolvedProgramSummary> = (f: {
  name: string;
  filter: MultiFilterRows<ResolvedProgramSummary>;
}) => {
  const {
    name,
    filter: { conjunction, filters },
  } = f;

  const persistableFilters = filters.map(({ selectedField, selectedFilter, filterValue }) => {
    const { fieldIdentity, fieldMetaData } = selectedField;
    const selectedFilterName = selectedFilter.name;
    const value = toValuePersistable(fieldMetaData, filterValue);
    const result: MultiFilterRowPersistable<ResolvedProgramSummary> = {
      selectedFieldIdentity: fieldIdentity,
      selectedFieldType: fieldMetaData.type,
      selectedFilterName,
      filterValue: value,
    };
    return result;
  });

  return {
    filters: persistableFilters,
    conjunction,
    multiFilterCustomName: name,
  };
};

const newMultiFilterRow = (
  selectedField: FilteringField<ResolvedProgramSummary>,
  selectedFilterName: string | null
) =>
  ({
    renderId: newRenderId(),
    filterValue: selectedField.fieldMetaData.defaultValue,
    selectedField,
    selectedFilter:
      selectedField.filters.find(({ name }) => name === selectedFilterName ?? '') ??
      selectedField.filters[0],
  }) as MultiFilterRow<ResolvedProgramSummary>;

const restoreDefaultValueOrNull = (
  type: FilteringFieldType,
  filterValue: unknown,
  existedUsers: User[],
  existedCampaigns: ResolvedCampaign[],
  existedTypes: ProgramType[]
) => {
  switch (type) {
    case 'boolean': {
      switch (filterValue) {
        case true:
        case 'true':
        case 1:
        case '1':
        case 'on':
        case 'yes':
          return true;
        default:
          return false;
      }
    }
    case 'string': {
      if (typeof filterValue === 'string') return filterValue;
      return null;
    }
    case 'number': {
      if (typeof filterValue === 'number') return filterValue;
      return null;
    }
    case 'date': {
      if (typeof filterValue === 'string') {
        const date = new Date(filterValue);
        return isNaN(date.valueOf()) ? null : date;
      }
      return null;
    }
    case 'user':
      return restoreDefaultArrayValues(filterValue, existedUsers, UnknownProgramOwner);
    case 'campaign':
      return restoreDefaultArrayValues(filterValue, existedCampaigns, UnknownCampaign);
    case 'program-type':
      return restoreDefaultArrayValues(filterValue, existedTypes, UnknownProgramType);
    case 'program-status':
      return restoreDefaultArrayValues(
        filterValue,
        [...ProgramStatusesOptions],
        TODO_PROGRAM_STATUS
      );
    default:
      throw new Error(`Unsupported for restoring multi filter field type: ${type}`);
  }
};

export const newProgramFilterRow = (): MultiFilterRow<ResolvedProgramSummary> => {
  const selectedField = getBasicFilteringFields()[0];
  return newMultiFilterRow(selectedField, null);
};

export const restoreProgramFilterRow = (
  persistedRow: MultiFilterRowPersistable<ResolvedProgramSummary>,
  existedUsers: User[],
  existedTypes: ProgramType[],
  existedCampaigns: ResolvedCampaign[]
): MultiFilterRow<ResolvedProgramSummary> => {
  const selectedField = restoreSelectedFilteringField(
    persistedRow,
    existedUsers,
    existedTypes,
    existedCampaigns
  );
  if (!selectedField) return newProgramFilterRow();
  return newMultiFilterRow(selectedField, persistedRow.selectedFilterName);
};

const restoreDefaultArrayValues: <T extends { id: string }>(
  filterValue: unknown,
  existedArray: T[],
  fallbackValue: T
) => T[] | null = <T extends { id: string }>(
  filterValue: unknown,
  existedArray: T[],
  fallbackValue: T
) => {
  if (filterValue instanceof Array) {
    return filterValue.map((storedId) => {
      if (typeof storedId === 'string') {
        return existedArray.find(({ id }) => id === storedId) ?? { ...fallbackValue, id: storedId };
      }
      return fallbackValue;
    });
  }
  return null;
};

const restoreSelectedFilteringField: (
  persistedRow: MultiFilterRowPersistable<ResolvedProgramSummary>,
  existedUsers: User[],
  existedTypes: ProgramType[],
  existedCampaigns: ResolvedCampaign[]
) => FilteringField<ResolvedProgramSummary> | null = (
  persistedRow: MultiFilterRowPersistable<ResolvedProgramSummary>,
  existedUsers: User[],
  existedTypes: ProgramType[],
  existedCampaigns: ResolvedCampaign[]
) => {
  const selectedFieldWithoutDefaultValue = () => {
    if (persistedRow.selectedFieldIdentity.type === 'StaticField') {
      const persistedFieldName = persistedRow.selectedFieldIdentity.fieldName;
      return getBasicFilteringFields().find(
        (f) =>
          f.fieldIdentity.type === 'StaticField' && f.fieldIdentity.fieldName === persistedFieldName
      );
    }
    return customFieldToFilteringField(persistedRow.selectedFieldIdentity.arrayItemValue);
  };

  const basicFilteringField = selectedFieldWithoutDefaultValue();
  if (!basicFilteringField) return null;
  const selectedValue = restoreDefaultValueOrNull(
    persistedRow.selectedFieldType,
    persistedRow.filterValue,
    existedUsers,
    existedCampaigns,
    existedTypes
  );
  if (selectedValue == null) return basicFilteringField;

  return {
    ...basicFilteringField,
    fieldMetaData: {
      ...basicFilteringField.fieldMetaData,
      defaultValue: selectedValue,
    },
  };
};

export const filterResolvedPrograms: (
  programs: ResolvedProgramSummary[],
  selectedFilter: SelectedFilter | null
) => ResolvedProgramSummary[] = (
  programs: ResolvedProgramSummary[],
  selectedFilter: SelectedFilter | null
) => {
  if (selectedFilter == null)
    return applyFilters(programs, {
      filters: [],
      conjunction: 'And',
    });
  const {
    filter: { filters: persistedFilters, conjunction },
  } = selectedFilter;
  const filters = persistedFilters.map((row) => restoreProgramFilterRow(row, [], [], []));
  return applyFilters(programs, { filters, conjunction });
};
