import { InfoIcon } from '../../assets/icons';
import { Tooltip } from 'react-tooltip';
import { v4 as uuidv4 } from 'uuid';
import React, { useState } from 'react';

export type TooltipPlace =
  | 'top'
  | 'top-start'
  | 'top-end'
  | 'right'
  | 'right-start'
  | 'right-end'
  | 'bottom'
  | 'bottom-start'
  | 'bottom-end'
  | 'left'
  | 'left-start'
  | 'left-end';
export const Info = (props: {
  tooltipText: string;
  tooltipWidth: number;
  tooltipPlace?: TooltipPlace;
  tooltipFixOverflow?: boolean;
}) => {
  const { tooltipText, tooltipWidth, tooltipPlace, tooltipFixOverflow } = props;
  const [tooltipId] = useState(`tooltip-id_${uuidv4()}`);

  return (
    <>
      <button className="icon-button__wrapper" type="button" tabIndex={-1}>
        <InfoIcon
          className="icon-button__icon"
          data-tooltip-id={tooltipId}
          data-tooltip-place={tooltipPlace ?? 'bottom'}
        />
      </button>
      <Tooltip
        id={tooltipId}
        className="info-button__tooltip"
        noArrow={true}
        style={{ width: tooltipWidth }}
        place={'bottom'}
        positionStrategy={tooltipFixOverflow ? 'fixed' : 'absolute'}
      >
        <div className="info-button__tooltip-content">{tooltipText}</div>
      </Tooltip>
    </>
  );
};
