import React, { useCallback, useMemo, useState } from 'react';
import { errorToString } from '../../util/utils';
import { IntegrationDialog } from './IntegrationDialog';
import { useFetchedIntegrationMetadatas } from '../hooks/useFetchedMetadatas';
import { IntegrationsTable } from '../components/IntegrationsTable';
import { IntegrationMetadataDto } from '../integrationApi';
import useAnalyticsPage from '../../web-analytics/hooks/useAnalyticsPage';
import { Page } from '../../web-analytics/Page';
import { ConfirmDialogProps, ConfirmModal } from '../../commons/components/ConfirmModal';
import { useMutation } from '@tanstack/react-query';
import { ResolvedIntegration } from '../types';
import useApi from '../../api/backendApiContext';
import { useQueryCacheManager } from '../../hooks/useQueryCacheManager';
import { useNotification } from '../../notification/notificationsContext';

export type IntegrationsModalState = {
  integration: IntegrationMetadataDto;
} | null;

export const IntegrationsManagement = () => {
  useAnalyticsPage(Page.INTEGRATIONS);

  const { integrationApi } = useApi();
  const { notifySuccess, notifyError } = useNotification();
  const queryCache = useQueryCacheManager();
  const { data, status, error } = useFetchedIntegrationMetadatas();
  const [modalState, setModalState] = useState<IntegrationsModalState | null>(null);

  const [confirmDisconnectModal, setConfirmDisconnectModal] = useState<ResolvedIntegration | null>(
    null
  );

  const { mutateAsync: deleteIntegrationMutation } = useMutation(
    async (change: ResolvedIntegration) => integrationApi.deleteIntegration(change),
    {
      onSettled: async () => queryCache.initTenantIntegrationsRefetch(),
    }
  );

  const handleDisconnectIntegration = useCallback(async () => {
    try {
      await deleteIntegrationMutation(confirmDisconnectModal!);
      notifySuccess({
        notificationMsg: `Integration is deleted.`,
        logMsg: `Integration ${confirmDisconnectModal?.id} is deleted.`,
      });
    } catch (err) {
      notifyError({
        err,
        logMsg: `Integration dialog failed to delete integration ${confirmDisconnectModal!.id}`,
        notificationMsg: `Failed to delete integration`,
      });
    }
    setConfirmDisconnectModal(null);
  }, [deleteIntegrationMutation, confirmDisconnectModal, notifySuccess, notifyError]);

  const confirmDisconnectIntegration: ConfirmDialogProps = useMemo<ConfirmDialogProps>(
    () => ({
      title: 'Disconnect Growegy and Salesforce?',
      message: 'You’re removing the integration for all users of your team.',
      footer: {
        type: 'show',
        applyButton: {
          label: 'Disconnect',
          variant: 'danger',
          onClick: handleDisconnectIntegration,
        },
        cancelButton: {
          label: 'Cancel',
          onClick: () => setConfirmDisconnectModal(null),
        },
      },
    }),
    [handleDisconnectIntegration]
  );
  const onDelete = (integration: ResolvedIntegration) => {
    setConfirmDisconnectModal(integration);
    setModalState(null);
  };

  const onSelect = (integration: IntegrationMetadataDto) => setModalState({ integration });
  const memoRows = useMemo(() => (data ? data.metadatas : []), [data]);
  if (status === 'loading') {
    return (
      <div className="container">
        <div className="alert alert-secondary" role="alert">
          Loading...
        </div>
      </div>
    );
  }

  if (status === 'error') {
    return (
      <div className="alert alert-warning" role="alert" data-test="user-list__error">
        Could not load integrations: {errorToString(error)}
      </div>
    );
  }

  if (status === 'success' && data) {
    return (
      <div>
        <div className="personal-integrations__container">
          <IntegrationsTable onDelete={onDelete} onSelect={onSelect} data={memoRows} />
          {modalState && (
            <IntegrationDialog
              integrationMetadata={modalState.integration}
              onHide={() => setModalState(null)}
              onDelete={onDelete}
            />
          )}
          {confirmDisconnectModal && <ConfirmModal {...confirmDisconnectIntegration} />}
        </div>
      </div>
    );
  }

  return (
    <>
      <h1>status {status}</h1>
      <p>
        <>data {data}</>
      </p>
    </>
  );
};
