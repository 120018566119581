import { useMutation } from '@tanstack/react-query';
import { BILLING_PLAN_YEAR } from '../billingPlans';
import { analyticsTrack } from '../../web-analytics/webAnalytics';
import { AnalyticsEvent } from '../../web-analytics/AnalyticsEvent';
import useApi from '../../api/backendApiContext';
import { useQueryCacheManager } from '../../hooks/useQueryCacheManager';
import { useNotification } from '../../notification/notificationsContext';
import useAnalytics from '../../web-analytics/webAnalyticsContext';

export const useUpgradeToAnnualPlanMutation = (props: {
  source: string;
  onSuccess?: () => void;
  onError?: () => void;
}) => {
  const { source, onSuccess, onError } = props;
  const { billingApi } = useApi();
  const queryCacheManager = useQueryCacheManager();
  const { notifySuccess, notifyError } = useNotification();
  const analytics = useAnalytics();

  return useMutation(async () => billingApi.changeBillingPlan(BILLING_PLAN_YEAR.name), {
    onMutate: async () => {
      await queryCacheManager.updateBillingCustomer({
        pendingPlanChange: BILLING_PLAN_YEAR.name,
        changePlanOptions: [],
      });
    },
    onSuccess: async () => {
      await queryCacheManager.invalidateBillingCustomer();
      analyticsTrack(analytics, AnalyticsEvent.BILLING_PLAN_UPGRADED, { source });
      const message = `Your request to upgrade to ${BILLING_PLAN_YEAR.displayName} plan has been accepted.`;
      notifySuccess({ logMsg: message, notificationMsg: message });
      onSuccess && onSuccess();
    },
    onError: async () => {
      await queryCacheManager.invalidateBillingCustomer();
      analyticsTrack(analytics, AnalyticsEvent.BILLING_PLAN_UPGRADE_FAILED, { source });
      const message = `Something went wrong. Please try to submit the request to upgrade to ${BILLING_PLAN_YEAR.displayName} plan later or contact support.`;
      notifyError({ logMsg: message, notificationMsg: message });
      onError && onError();
    },
  });
};
