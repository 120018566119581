import { ProgramSummary } from '../types';
import { analyticsTrack } from '../../web-analytics/webAnalytics';
import { AnalyticsEvent } from '../../web-analytics/AnalyticsEvent';
import { useMutation } from '@tanstack/react-query';
import useApi from '../../api/backendApiContext';
import useAnalytics from '../../web-analytics/webAnalyticsContext';
import { isUnscheduled, unscheduledEnd, unscheduledStart } from '../unscheduledProgramUtil';
import { useQueryCacheManager } from '../../hooks/useQueryCacheManager';

export const useCreateProgramsMutation = () => {
  const { programApi } = useApi();
  const analytics = useAnalytics();
  const queryCacheManager = useQueryCacheManager();

  const createPrograms = async (programs: ProgramSummary[]) => {
    const { createdPrograms, affectedCampaigns } = await programApi.createPrograms(programs);
    analyticsTrack(analytics, AnalyticsEvent.PROGRAM_CREATED, { count: programs.length });

    return { createdPrograms, affectedCampaigns };
  };

  return useMutation(async (programs: ProgramSummary[]) => createPrograms(programs), {
    onSuccess: async ({ createdPrograms, affectedCampaigns }) => {
      for (const createdProgram of createdPrograms) {
        const p = isUnscheduled(createdProgram)
          ? {
              ...createdProgram,
              startDateTime: unscheduledStart(createdProgram.id),
              endDateTime: unscheduledEnd(createdProgram.id),
            }
          : createdProgram;
        if (p.programKind === 'PROGRAM') await queryCacheManager.onCreate(p.id, p);
        else queryCacheManager.onCampaignCreate(p);
      }

      if (affectedCampaigns) {
        for (const affectedCampaign of affectedCampaigns) {
          queryCacheManager.onCampaignUpdate(affectedCampaign);
        }
      }
    },
  });
};
