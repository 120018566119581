import CloudWatchSpaLoggerConfig from './cloudWatchSpaLoggerConfig';
import { isNullOrEmpty } from '../../program/util';
import { SpaLogger } from '../spaLogger';
import { ICurrentUser } from '../../currentuser/currentUser';

const { Logger } = require('aws-cloudwatch-log-browser');

export default class CloudWatchSpaLogger implements SpaLogger {
  private readonly logger: typeof Logger;

  private readonly local: boolean;

  public constructor(config: CloudWatchSpaLoggerConfig) {
    this.logger = new Logger(config);
    this.local = config.local;
  }

  public info(message: string, ...optionalParams: any[]) {
    if (isNullOrEmpty(optionalParams)) {
      console.info(message);
      this.logToCloudWatch('info', message);
    } else {
      console.info(message, optionalParams);
      this.logToCloudWatch('info', `${message} ${JSON.stringify(optionalParams)}`);
    }
  }

  public warn(message: string, ...optionalParams: any[]) {
    if (isNullOrEmpty(optionalParams)) {
      console.warn(message);
      this.logToCloudWatch('warn', message);
    } else {
      console.warn(message, optionalParams);
      this.logToCloudWatch('warn', `${message} ${JSON.stringify(optionalParams)}`);
    }
  }

  public error(message: string, ...optionalParams: any[]) {
    if (isNullOrEmpty(optionalParams)) {
      console.error(message);
      this.logToCloudWatch('error', message);
    } else {
      console.error(message, optionalParams);
      this.logToCloudWatch('error', `${message} ${JSON.stringify(optionalParams)}`);
    }
  }

  private logToCloudWatch(category: string, details: string) {
    if (!this.local) {
      this.logger.log({ category, details });
    }
  }

  public pushEvent(
    name: string,
    attributes?: Record<string, string>,
    domain?: string,
    options?: {
      skipDedupe?: boolean;
    }
  ): void {
    this.info(`Event: ${name}`, attributes, domain, options);
  }

  public pushAlertEvent(
    name: string,
    attributes?: Record<string, string>,
    domain?: string,
    options?: {
      skipDedupe?: boolean;
    }
  ): void {
    this.pushEvent(name, attributes, domain, options);
  }

  public setUser(user: ICurrentUser | null): void {
    this.info(`Set User: ${user}`);
  }

  public async wrapWithTelemetry<T extends unknown>(promise: Promise<T>): Promise<T> {
    return promise;
  }
}
