import {
  FileBmpIcon,
  FileCsvIcon,
  FileDocIcon,
  FileDocxIcon,
  FileGifIcon,
  FileHtmIcon,
  FileHtmlIcon,
  FileIcon,
  FileJpegIcon,
  FileJpgIcon,
  FileOdtIcon,
  FilePdfIcon,
  FilePngIcon,
  FilePptIcon,
  FilePptxIcon,
  FileTiffIcon,
  FileTxtIcon,
  FileXlsIcon,
  FileXlsxIcon,
  FileZipIcon,
} from '../../../assets/icons';
import React, { ReactElement, SVGProps } from 'react';

const normalSize = {
  width: '20px',
  height: '20px',
};

const largeSize = {
  width: '72px',
  height: '72px',
};

export const FileTypeIcon = (
  props: SVGProps<SVGSVGElement> & { extension: string; large?: boolean }
): ReactElement => {
  const { extension, large } = props;
  const size = large ? largeSize : normalSize;
  const svgProps = { ...props, width: size.width, height: size.height };
  switch (extension.toLowerCase()) {
    case 'bmp':
      return <FileBmpIcon {...svgProps} />;
    case 'csv':
      return <FileCsvIcon {...svgProps} />;
    case 'doc':
      return <FileDocIcon {...svgProps} />;
    case 'docx':
      return <FileDocxIcon {...svgProps} />;
    case 'gif':
      return <FileGifIcon {...svgProps} />;
    case 'htm':
      return <FileHtmIcon {...svgProps} />;
    case 'html':
      return <FileHtmlIcon {...svgProps} />;
    case 'jpeg':
      return <FileJpegIcon {...svgProps} />;
    case 'jpg':
      return <FileJpgIcon {...svgProps} />;
    case 'odt':
      return <FileOdtIcon {...svgProps} />;
    case 'pdf':
      return <FilePdfIcon {...svgProps} />;
    case 'png':
      return <FilePngIcon {...svgProps} />;
    case 'ppt':
      return <FilePptIcon {...svgProps} />;
    case 'pptx':
      return <FilePptxIcon {...svgProps} />;
    case 'tiff':
    case 'tif':
      return <FileTiffIcon {...svgProps} />;
    case 'txt':
      return <FileTxtIcon {...svgProps} />;
    case 'xls':
      return <FileXlsIcon {...svgProps} />;
    case 'xlsx':
      return <FileXlsxIcon {...svgProps} />;
    case 'zip':
      return <FileZipIcon {...svgProps} />;
    default:
      return (
        <>
          <FileIcon {...svgProps} />
          {large && (
            <div className="file-type-icon__container">
              <div
                className="file-type-icon__extension"
                style={{
                  color: props.fill,
                }}
              >
                {extension}
              </div>
            </div>
          )}
        </>
      );
  }
};
