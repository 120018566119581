import { getEmptyProgramSummary } from '../../util';
import React from 'react';
import { ICurrentUser } from '../../../currentuser/currentUser';
import { ProgramSummary } from '../../types';
import { Dropdown } from 'react-bootstrap';
import { PlusButton } from '../PlusButton';
import { useAddProgramPermissionHelper } from '../../programPermissionHelper';
import { CampaignIcon, ProgramIcon } from '../../../assets/icons';
import { Info } from '../../../commons/components/Info';

const DropdownButton = React.forwardRef<HTMLDivElement, React.HTMLProps<HTMLDivElement>>(
  ({ onClick }, ref) => (
    <div>
      <div ref={ref} data-test="programs__create-something">
        <PlusButton onClick={onClick} />
      </div>
    </div>
  )
);

export const AddNewProgram = ({
  onCreate,
  user,
  isScheduledMode,
}: {
  onCreate: (p: ProgramSummary) => void;
  user: ICurrentUser | null;
  isScheduledMode: boolean;
}) => {
  const addProgramPermissionHelper = useAddProgramPermissionHelper();

  let canCreateProgram = addProgramPermissionHelper.canCreateProgram();
  let canCreateCampaign = addProgramPermissionHelper.canCreateCampaign();
  return (
    <Dropdown
      onSelect={(eventKey) => {
        onCreate(
          getEmptyProgramSummary(
            '',
            true,
            null,
            null,
            user ? user.userId : null,
            isScheduledMode,
            eventKey === 'PROGRAM' ? 'PROGRAM' : 'CAMPAIGN'
          )
        );
      }}
    >
      <Dropdown.Toggle as={DropdownButton} className="d-flex align-items-center" />
      <Dropdown.Menu title="" style={{ width: 170 }}>
        {canCreateProgram && (
          <Dropdown.Item eventKey="PROGRAM" data-test="programs__create-program">
            <ProgramIcon className="plus-button__menu-item-icon" />
            Program
          </Dropdown.Item>
        )}
        {canCreateCampaign && (
          <Dropdown.Item eventKey="CAMPAIGN" data-test="programs__create-campaign">
            <CampaignIcon className="plus-button__menu-item-icon" />
            <div className="flex-grow-1">Campaign</div>
            <Info
              tooltipText="Group related Programs into Campaigns for an extra layer of organization"
              tooltipWidth={166}
            />
          </Dropdown.Item>
        )}
      </Dropdown.Menu>
    </Dropdown>
  );
};
