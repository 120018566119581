import { Button } from 'react-bootstrap';
import React from 'react';
import { BoxSpinner } from './Spinner';
import { useQueryCacheManager } from '../../hooks/useQueryCacheManager';

const FailedToLoadQuery = (props: {
  text: string;
  dataTestPrefix: string;
  onReload: () => void;
}) => {
  const { text, dataTestPrefix, onReload } = props;
  return (
    <div className="failed-to-load__container">
      <div data-test={`${dataTestPrefix}-failed-to-load__text`}>{text}</div>
      <Button
        className="button-apply"
        onClick={onReload}
        data-test={`${dataTestPrefix}-failed-to-load__reload-button`}
      >
        Reload
      </Button>
    </div>
  );
};

export const QueryStatus = (props: {
  queryKey: string[];
  queryStatus: 'success' | 'error' | 'loading';
  failedToLoadText: string;
  dataTestPrefix: string;
}) => {
  const { queryKey, queryStatus, failedToLoadText, dataTestPrefix } = props;
  const queryCacheManager = useQueryCacheManager();
  return queryStatus === 'loading' ? (
    <BoxSpinner />
  ) : queryStatus === 'error' || true ? (
    <FailedToLoadQuery
      text={failedToLoadText}
      dataTestPrefix={dataTestPrefix}
      onReload={() => {
        queryCacheManager.invalidateQuery(queryKey);
      }}
    />
  ) : (
    <></>
  );
};
