import React, { ReactElement, useCallback, useMemo } from 'react';
import Select, {
  components,
  ControlProps,
  FormatOptionLabelMeta,
  GroupBase,
  MenuListProps,
  OptionProps,
} from 'react-select';
import { ChevronDownIcon } from '../../assets/icons';
import { StyleVariables } from '../../commons/styleConstants';
import { FilterOptionOption } from 'react-select/dist/declarations/src/filters';
import { min } from 'lodash';
import clsx from 'clsx';

const GrowegySelectControl = <T extends object>(
  props: { dataTestPrefix: string } & React.PropsWithChildren<ControlProps<T, false, GroupBase<T>>>
) => {
  const { dataTestPrefix, children } = props;
  return (
    <div className="growegy-select__control" data-test={`${dataTestPrefix}__control-wrapper`}>
      <components.Control {...props}>{children}</components.Control>
    </div>
  );
};

export const growegySelectControlFactory =
  <T extends object>(dataTestPrefix: string) =>
  (baseProps: React.PropsWithChildren<ControlProps<T, false, GroupBase<T>>>) => (
    <GrowegySelectControl {...baseProps} dataTestPrefix={dataTestPrefix}>
      {baseProps.children}
    </GrowegySelectControl>
  );

export const growegySelectMenuListFactory =
  <T extends object>(dataTestPrefix: string) =>
  (baseProps: React.PropsWithChildren<MenuListProps<T, false, GroupBase<T>>>) => (
    <div data-test={`${dataTestPrefix}__menu-list-wrapper`}>
      <components.MenuList {...baseProps}>{baseProps.children}</components.MenuList>
    </div>
  );

export const GrowegySelectDropdownIndicator = () => (
  <ChevronDownIcon
    className="growegy-select__dropdown_indicator flex-shrink-0"
    data-test="growegy-select__dropdown_indicator"
  />
);

const MenuPadding = 4;

export const GrowegySelect = <T extends { id: string }>(props: {
  options: T[] | undefined;
  selectedOption: T | null;
  placeholder?: string;
  disabled?: boolean;
  hasError?: boolean;
  autoFocus?: boolean;
  isSearchable?: boolean;
  isRequired?: boolean;
  isClearable?: boolean;
  backspaceRemovesValue?: boolean;
  dataTestPrefix: string;
  menuZIndex?: number;
  width?: number;
  borderWidth?: string;
  menuAlignment?: 'left' | 'right';
  filterOption?: <Option extends unknown>(
    option: FilterOptionOption<Option>,
    input: string
  ) => boolean;
  formatOptionLabel: (item: T, x: FormatOptionLabelMeta<T>) => ReactElement;
  onChange?: (option: T | null) => void;
  onBlur?: () => void;
  onMenuOpen?: () => void;
  onMenuClose?: () => void;
}) => {
  const {
    options,
    selectedOption,
    placeholder,
    disabled,
    hasError,
    autoFocus,
    isSearchable,
    isRequired,
    isClearable,
    backspaceRemovesValue,
    dataTestPrefix,
    menuZIndex,
    width,
    borderWidth,
    menuAlignment,
    filterOption,
    formatOptionLabel,
    onChange,
    onBlur,
    onMenuOpen,
    onMenuClose,
  } = props;

  const optionComponent = useCallback(
    (baseProps: React.PropsWithChildren<OptionProps<T, false, GroupBase<T>>>) => (
      <div
        className={clsx('growegy-select__option', {
          'growegy-select__option--selected': baseProps.isSelected,
        })}
      >
        <components.Option {...baseProps}>{baseProps.children}</components.Option>
      </div>
    ),
    []
  );
  return (
    <Select
      options={options}
      value={selectedOption}
      isDisabled={disabled}
      autoFocus={autoFocus}
      openMenuOnFocus={autoFocus}
      filterOption={filterOption}
      getOptionValue={(option) => option.id}
      formatOptionLabel={formatOptionLabel}
      onChange={onChange}
      onBlur={onBlur}
      onMenuOpen={onMenuOpen}
      onMenuClose={onMenuClose}
      placeholder={disabled ? '' : placeholder}
      maxMenuHeight={360}
      menuShouldScrollIntoView={true}
      isSearchable={isSearchable ?? true}
      required={isRequired ?? false}
      isClearable={isClearable}
      backspaceRemovesValue={backspaceRemovesValue}
      components={useMemo(
        () => ({
          Control: growegySelectControlFactory<T>(dataTestPrefix),
          MenuList: growegySelectMenuListFactory<T>(dataTestPrefix),
          Option: optionComponent,
          DropdownIndicator: GrowegySelectDropdownIndicator,
        }),
        [dataTestPrefix, optionComponent]
      )}
      styles={{
        indicatorSeparator: () => ({
          display: 'none',
        }),
        indicatorsContainer: () => (disabled ? { display: 'none' } : {}),
        clearIndicator: () => ({
          display: 'none',
        }),
        option: (base) => ({
          ...base,
          padding: '10px 12px',
          cursor: 'pointer',
          background: 'transparent',
          '&:hover': {
            background: 'transparent',
          },
          '&:focus': {
            background: 'transparent',
          },
          '&:active': {
            background: 'transparent',
          },
        }),
        menu: (base) => ({
          ...base,
          minWidth: `${(width ?? 160) + MenuPadding * 2}px`,
          maxWidth: '368px',
          width: 'fit-content',
          padding: 0,
          left: !menuAlignment || menuAlignment === 'left' ? `-${MenuPadding}px` : undefined,
          right: menuAlignment === 'right' ? `-${MenuPadding}px` : undefined,
          background: 'white',
          borderRadius: StyleVariables.borderRadius,
          ...(menuZIndex !== undefined ? { zIndex: menuZIndex } : {}),
        }),
        menuList: (base) => ({
          ...base,
          padding: `4px`,
        }),
        valueContainer: (base) => ({
          ...base,
          padding: '0 0 0 10px',
        }),
        control: (base, controlProps) => ({
          ...base,
          ...(width ? { width: width } : {}),
          minWidth: `${min([disabled ? 50 : 160, width])}px`,
          maxWidth: '400px',
          minHeight: '32px',
          cursor: 'pointer',
          lineHeight: '20px',
          borderRadius: StyleVariables.borderRadius,
          borderWidth: borderWidth ? borderWidth : '2px',
          borderColor: hasError
            ? StyleVariables.red500
            : disabled
            ? 'transparent'
            : StyleVariables.neutral200,
          backgroundColor: controlProps.menuIsOpen || controlProps.isFocused ? 'white' : 'inherit',
          boxShadow: 'none',
          '&:hover': !hasError
            ? {
                borderColor: StyleVariables.neutral300,
                boxShadow: 'none',
              }
            : {},
          '&:focus-within': !hasError
            ? {
                borderColor: StyleVariables.cyan500,
                boxShadow: 'none',
              }
            : {},
        }),
      }}
    />
  );
};
