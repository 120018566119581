import {
  NoCampaign,
  ProgramKind,
  ResolvedCampaign,
  ResolvedProgramSummary,
  ResolvedProgramTask,
} from './types';
import { Permission } from '../auth/permissions';
import { ICurrentUser } from '../currentuser/currentUser';
import useAuth from '../auth/authContext';
import { useResolvedCampaigns } from './hooks/useResolvedCampaigns';
import { useMemo } from 'react';

export type AddProgramPermissionHelper = ReturnType<typeof useAddProgramPermissionHelper>;
export type ProgramPermissionHelper = ReturnType<typeof getProgramPermissionHelper>;

export const useAddProgramPermissionHelper = () => {
  const {
    state: { user },
  } = useAuth();
  const { data: campaigns } = useResolvedCampaigns();
  const hasAssignedCampaigns = useMemo(() => {
    if (!user) return false;
    return campaigns?.some((c) => c.owningUserId === user.userId) ?? false;
  }, [campaigns, user]);

  let canCreateProgram = () => {
    if (!user) return false;
    if (user.has(Permission.ADD_PROGRAM)) return true;
    return user.has(Permission.ADD_ASSIGNED_PROGRAM) && hasAssignedCampaigns;
  };
  let canCreateStandaloneProgram = () => {
    if (!user) return false;
    return user.has(Permission.ADD_PROGRAM);
  };
  const canCreateProgramInCampaign = (campaign: ResolvedCampaign) => {
    if (!user) return false;
    if (user.has(Permission.ADD_PROGRAM)) return true;
    return (
      user.has(Permission.ADD_ASSIGNED_PROGRAM) &&
      !!campaign.owningUserId &&
      campaign.owningUserId === user.userId
    );
  };
  const canCreateCampaign = () => {
    if (!user) return false;
    return user.has(Permission.ADD_CAMPAIGN);
  };
  const canCreate = (kind: ProgramKind) => {
    switch (kind) {
      case 'PROGRAM':
        return canCreateProgram();
      case 'CAMPAIGN':
        return canCreateCampaign();
    }
  };
  return {
    canCreateProgram,
    canCreateStandaloneProgram,
    canCreateProgramInCampaign,
    canCreateCampaign,
    canCreate,
  };
};

export const useProgramPermissionHelper = (program: ResolvedProgramSummary) => {
  const {
    state: { user },
  } = useAuth();
  return getProgramPermissionHelper(user, program);
};

export const getProgramPermissionHelper = (
  user: ICurrentUser | null,
  program: ResolvedProgramSummary
) => {
  const hasReplaceProgramPermission = !!user && user.has(Permission.REPLACE_PROGRAM);
  const hasReplaceAssignedProgramPermission =
    !!user && user.has(Permission.REPLACE_ASSIGNED_PROGRAM);
  const ownsProgram = !!user && program.owningUserId === user.userId;
  const ownsCampaign =
    !!user && !!program.campaign && program.campaign.owningUserId === user.userId;
  const ownsAnyTask = () =>
    !!user && program.resolvedTasks.some((t) => t.owningUserId === user.userId);
  const ownsAnythingInHierarchy = () => {
    if (!user) return false;
    if (ownsProgram) return true;
    if (ownsCampaign) return true;
    return ownsAnyTask();
  };

  return {
    canChangeCampaign: (): boolean => {
      if (!user) return false;
      if (program.programKind !== 'PROGRAM') return false;
      if (hasReplaceProgramPermission) return true;
      if (!program.campaign) return false;
      return hasReplaceAssignedProgramPermission && program.campaign.owningUserId === user.userId;
    },
    getCampaignOptions: (allCampaigns: ResolvedCampaign[] | null): ResolvedCampaign[] => {
      if (!user) return [];
      if (program.programKind !== 'PROGRAM') return [];
      if (!allCampaigns) return [NoCampaign];
      if (hasReplaceProgramPermission) return [...allCampaigns, NoCampaign];
      if (hasReplaceAssignedProgramPermission) {
        if (program.id && program.campaign && !ownsCampaign) return [program.campaign];
        return allCampaigns.filter((c) => c.owningUserId === user.userId);
      }
      return program.campaign ? [program.campaign] : [NoCampaign];
    },
    canMakeStandalone: (): boolean => {
      if (!user) return false;
      if (program.programKind !== 'PROGRAM') return false;
      if (hasReplaceProgramPermission) return true;
      return hasReplaceAssignedProgramPermission && !program.campaign;
    },
    canChangeProgramOwner: (): boolean => {
      if (!user) return false;
      if (hasReplaceProgramPermission) return true;
      if (hasReplaceAssignedProgramPermission) {
        if (program.programKind === 'CAMPAIGN') return false;
        if (ownsCampaign) return true;
      }
      return false;
    },
    canEditRegularField: (): boolean => {
      if (!user) return false;
      if (hasReplaceProgramPermission) return true;
      if (hasReplaceAssignedProgramPermission) {
        if (ownsProgram) return true;
        if (
          program.programKind === 'PROGRAM' &&
          program.campaign &&
          program.campaign.owningUserId === user.userId
        )
          return true;
      }
      return false;
    },
    canChangeAnything: (): boolean => {
      if (!user) return false;
      if (
        hasReplaceProgramPermission ||
        user.hasAtLeastOne(Permission.ADD_PROGRAM_ATTACHMENT, Permission.DELETE_PROGRAM_ATTACHMENT)
      )
        return true;
      return (
        (hasReplaceAssignedProgramPermission ||
          user.hasAtLeastOne(
            Permission.ADD_ASSIGNED_PROGRAM_ATTACHMENT,
            Permission.DELETE_ASSIGNED_PROGRAM_ATTACHMENT
          )) &&
        ownsAnythingInHierarchy()
      );
    },
    canManageAttachments: (): boolean => {
      if (!user) return false;
      if (user.has(Permission.ADD_PROGRAM_ATTACHMENT)) return true;
      if (user.has(Permission.ADD_ASSIGNED_PROGRAM_ATTACHMENT)) {
        if (ownsProgram) return true;
        if (ownsCampaign) return true;
        if (ownsAnyTask()) return true;
      }
      return false;
    },
    canEditRegularTaskField: (task: ResolvedProgramTask): boolean => {
      if (!user) return false;
      if (hasReplaceProgramPermission) return true;
      if (hasReplaceAssignedProgramPermission) {
        if (ownsCampaign) return true;
        if (ownsProgram) return true;
        if (task.owningUserId === user.userId) return true;
      }
      return false;
    },
    canReorderTasks: (): boolean => {
      if (!user) return false;
      if (hasReplaceProgramPermission) return true;
      if (hasReplaceAssignedProgramPermission) {
        if (ownsCampaign) return true;
        if (ownsProgram) return true;
      }
      return false;
    },
    canReassignTask: (): boolean => {
      if (!user) return false;
      if (hasReplaceProgramPermission) return true;
      if (hasReplaceAssignedProgramPermission) {
        if (ownsCampaign) return true;
        if (ownsProgram) return true;
      }
      return false;
    },
    canCreateTask: (): boolean => {
      if (!user) return false;
      if (hasReplaceProgramPermission) return true;
      if (hasReplaceAssignedProgramPermission) {
        if (ownsCampaign) return true;
        if (ownsProgram) return true;
        if (ownsAnyTask()) return true;
      }
      return false;
    },
    canCreateTaskForAnyUser: (): boolean => {
      if (!user) return false;
      if (hasReplaceProgramPermission) return true;
      if (hasReplaceAssignedProgramPermission) {
        if (ownsCampaign) return true;
        if (ownsProgram) return true;
      }
      return false;
    },
    canDeleteTask: (task: ResolvedProgramTask): boolean => {
      if (!user) return false;
      if (hasReplaceProgramPermission) return true;
      if (hasReplaceAssignedProgramPermission) {
        if (ownsCampaign) return true;
        if (ownsProgram) return true;
        if (!task.id) return true;
      }
      return false;
    },
  };
};
