import useAuth from '../../auth/authContext';
import { usePing } from '../hooks/usePing';
import { useEffect, useRef } from 'react';
import useAnalytics from '../../web-analytics/webAnalyticsContext';
import { analyticsTrack } from '../../web-analytics/webAnalytics';
import { AnalyticsEvent } from '../../web-analytics/AnalyticsEvent';

export const SanityChecker = () => {
  const analytics = useAnalytics();
  const {
    state: { user },
  } = useAuth();
  const { status } = usePing();
  const statusRef = useRef<typeof status>(status);
  useEffect(() => {
    statusRef.current = status;
  }, [status]);

  useEffect(() => {
    logger.info('Client sanity check: starting.');
    const timeoutId = setTimeout(() => {
      if (user && statusRef.current !== 'success') {
        if (statusRef.current === 'loading') {
          logger.error('Client sanity check: failed.');
          analyticsTrack(analytics, AnalyticsEvent.SANITY_CHECK_FAILED);
        } else {
          logger.error('Failed to load metadata.');
        }
      } else {
        logger.info('Client sanity check: passed.');
      }
    }, 10_000);

    return () => clearTimeout(timeoutId);
  }, [user, analytics]);
  return <></>;
};
