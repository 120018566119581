import React from 'react';
import { ResolvedProgramTask } from '../../types';

interface Props {
  showCampaigns: boolean;
  onShowHideCampaigns: (visible: boolean) => void;
  showTasks: boolean;
  onShowHideTasks: (visible: boolean) => void;
  isDisplayCampaigns: boolean;
  taskFields: { id: keyof ResolvedProgramTask; title: string; visible: boolean }[];
  onShowTaskField: (id: keyof ResolvedProgramTask, visible: boolean) => void;
}

const CalendarEventSelector = ({
  showCampaigns,
  onShowHideCampaigns,
  showTasks,
  onShowHideTasks,
  isDisplayCampaigns,
  taskFields,
  onShowTaskField,
}: Props) => (
  <>
    {isDisplayCampaigns && (
      <>
        <div className="switch-input-container">
          <input
            id="calendar-display-settings__show-campaigns"
            type="checkbox"
            className="custom-control-input"
            data-test="calendar-display-settings__show-campaigns"
            onChange={(event) => onShowHideCampaigns(event.target.checked)}
            checked={showCampaigns}
          />
          <label
            htmlFor="calendar-display-settings__show-campaigns"
            className="custom-control-label calendar-display-settings__check-label"
          >
            Show campaigns
          </label>
        </div>
        <div className="calendar-display-settings__separator" />
      </>
    )}
    <div style={{ marginBottom: '5px' }} className="d-flex align-items-center">
      <div className="switch-input-container w-100">
        <input
          id="calendar-display-settings__show-tasks"
          type="checkbox"
          className="custom-control-input"
          data-test="calendar-display-settings__show-tasks"
          onChange={(event) => onShowHideTasks(event.target.checked)}
          checked={showTasks}
        />
        <label
          htmlFor="calendar-display-settings__show-tasks"
          className="custom-control-label  calendar-display-settings__check-label"
        >
          Show tasks
        </label>
      </div>
    </div>
    {taskFields.map(({ id: taskFieldId, title, visible }) => (
      <div
        key={`calendar-display-settings__tasks-field-${taskFieldId}`}
        className="calendar-display-settings__draggable-container"
      >
        <div className="d-flex align-items-center">
          <div className="drag-handle drag-handle--small" />
        </div>
        <div className="d-flex align-items-center w-100">
          <div className="switch-input-container w-100">
            <input
              type="checkbox"
              className="custom-control-input"
              id={`calendar-display-settings__show-tasks-field-${taskFieldId}-id`}
              onChange={(event) => onShowTaskField(taskFieldId, event.target.checked)}
              checked={visible}
            />
            <label
              className="custom-control-label calendar-display-settings__check-label"
              htmlFor={`calendar-display-settings__show-tasks-field-${taskFieldId}-id`}
            >
              {title}
            </label>
          </div>
        </div>
      </div>
    ))}

    <div className="calendar-display-settings__separator" />
  </>
);

export default CalendarEventSelector;
