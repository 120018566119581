import _ from 'lodash';
import { SystemRole } from '../auth/systemRole';
import { ICurrentUser } from '../currentuser/currentUser';

export enum AppRoute {
  home = '/',
  signIn = '/signin',
  signUp = '/signup',
  signOut = '/sign-out',
  settings = '/settings',
  notFound = '/not-found',
  calendar = '/calendar',
  programs = '/programs',
  analytics = '/analytics',
  deeplink = '/program/:programId',
  insights = '/insights',
  billing = '/billing',
  ai = '/ai',
}

export const AppRoutes = {
  Home: AppRoute.home,
  Calendar: AppRoute.calendar,
  NotFound: AppRoute.notFound,
  Settings: AppRoute.settings,
  Auth: {
    signIn: AppRoute.signIn,
    signUp: AppRoute.signUp,
    signOut: AppRoute.signOut,
  },
  Program: {
    programs: AppRoute.programs,
    deeplink: AppRoute.deeplink,
  },
  Analytics: AppRoute.analytics,
  Recommendations: AppRoute.insights,
  GrowegyAI: AppRoute.ai,
  Billing: AppRoute.billing,
};

type RestrictRule = Array<SystemRole> | 'NON_AUTH' | 'ANY_ONE';

type RestrictRules = {
  navigation: RestrictRule;
  visibility: RestrictRule;
};

// eslint-disable-next-line consistent-return
const getRestrictRule = (r: AppRoute): RestrictRules => {
  switch (r) {
    case AppRoute.signIn:
    case AppRoute.signUp:
      return { navigation: 'NON_AUTH', visibility: 'NON_AUTH' };
    case AppRoute.notFound:
      return { navigation: 'ANY_ONE', visibility: 'ANY_ONE' };
    case AppRoute.signOut:
      return { navigation: 'ANY_ONE', visibility: [] };
    case AppRoute.settings:
    case AppRoute.home:
    case AppRoute.calendar:
    case AppRoute.deeplink:
    case AppRoute.programs:
    case AppRoute.analytics:
    case AppRoute.insights:
    case AppRoute.ai:
    case AppRoute.billing:
      return { navigation: [], visibility: [] };
  }
};

const isAvailable = (user: ICurrentUser | null, rule: RestrictRule): boolean => {
  switch (rule) {
    case 'ANY_ONE':
      return true;
    case 'NON_AUTH':
      return !user;
    default:
      return !!user && (rule.length === 0 || _.intersection(user.roles, rule).length > 0);
  }
};

export const isRouteAvailable = (user: ICurrentUser | null, route: AppRoute): boolean => {
  const { navigation } = getRestrictRule(route);
  return isAvailable(user, navigation);
};

export const isRouteVisible = (user: ICurrentUser | null, route: AppRoute): boolean => {
  const { visibility } = getRestrictRule(route);
  return isAvailable(user, visibility);
};

const getProgramDeepLinkRoute = (programId: string) =>
  AppRoutes.Program.deeplink.replace(':programId', programId);

const getProgramDeepLinkUrl = (programId: string) => {
  const route = getProgramDeepLinkRoute(programId);
  return `${window.location.origin}${route}`;
};

export const AppRoutesHelper = {
  getProgramDeepLinkUrl,
  getProgramDeepLinkRoute,
};
