import React, { useMemo } from 'react';
import Select from 'react-select';
import _ from 'lodash';
import { FieldArrayRenderProps, FormikErrors, FormikTouched } from 'formik';
import {
  ExistedCustomFields,
  ProgramStatusesOptions,
  ProgramStatusOption,
  ProgramType,
  ResolvedCampaign,
  ResolvedProgramSummary,
} from '../types';
import { FormikTextInput } from '../../commons/components/FormikTextInput';
import { FormikNumberInput } from '../../commons/components/FormikNumberInput';
import { FormikDateTimeInput } from '../../commons/components/FormikDateTimeInput';
import {
  MultiFilterConjunction,
  MultiFilterConjunctions,
  MultiFilterRows,
  MultiFilterRow,
  FilteringFieldType,
  FilteringFieldIdentity,
} from '../../multifilter/types';
import { FormikMultiSelect } from '../../commons/components/FormikMultiSelect';
import { User } from '../../user/types';
import { extractUserName } from '../../user/utils';
import { FormikBooleanRadioInput } from './FormikBooleanRadioInput';
import { getProgramFilteringFields } from '../../multifilter/programFiltering';
import { DebounceMsConfig } from '../../config';
import { DivButton } from '../../commons/components/DivButton';
import { CloseIcon } from '../../assets/icons';

export type ProgramsMultiFilterRowProps = {
  existedUsers: Array<User>;
  existedTypes: Array<ProgramType>;
  existedCampaigns: Array<ResolvedCampaign>;
  existedCustomFields: ExistedCustomFields;
  filteringRowData: MultiFilterRow<ResolvedProgramSummary>;
  conjunctionData: MultiFilterConjunction;
  index: number;
  disabled: boolean;
  errors: FormikErrors<MultiFilterRows<ResolvedProgramSummary>>;
  touched: FormikTouched<MultiFilterRows<ResolvedProgramSummary>>;
  arrayHelpers: FieldArrayRenderProps;
  handleBlur: (event: React.FocusEvent<HTMLInputElement>) => void;
  setFieldValue: (field: string, value: unknown) => void;
};

const fieldIdentityToReactSelectValue = (
  fieldIdentity: FilteringFieldIdentity<ResolvedProgramSummary>,
  type: FilteringFieldType
): string => {
  if (fieldIdentity.type === 'ArrayFieldItem') {
    const {
      arrayField,
      arrayItemValue: { type: itemType, name: itemName },
    } = fieldIdentity;
    return `${arrayField}-${itemType}-${itemName}-${type}`;
  }
  return fieldIdentity.fieldName;
};

export const ProgramsMultiFilterRow = ({
  existedUsers,
  existedTypes,
  existedCampaigns,
  existedCustomFields,
  filteringRowData,
  conjunctionData, // can be restored
  index,
  disabled,
  errors,
  touched,
  handleBlur,
  setFieldValue,
  arrayHelpers,
}: ProgramsMultiFilterRowProps) => {
  const isError = (key: keyof MultiFilterRow<ResolvedProgramSummary>): boolean => {
    const fieldErrors = errors.filters?.[index];
    const touchedFields = touched.filters?.[index];
    return !!(
      fieldErrors &&
      typeof fieldErrors !== 'string' &&
      fieldErrors[key] &&
      touchedFields &&
      touchedFields[key]
    );
  };

  const fieldNameError = isError('filterValue');

  const options = useMemo(
    () =>
      getProgramFilteringFields(existedCustomFields)
        .map((u) => ({
          label: u.label,
          value: fieldIdentityToReactSelectValue(u.fieldIdentity, u.fieldMetaData.type),
          field: u,
        }))
        .sort((a, b) => a.label.localeCompare(b.label)),
    [existedCustomFields]
  );

  return (
    <div
      className="programs-filter__row"
      key={filteringRowData.renderId}
      data-test={`programs-filter__row-${index}`}
    >
      <div className="programs-filter__column programs-filter__column--conjunction">
        {index > 0 ? (
          <Select
            isMulti={false}
            value={{
              label: conjunctionData,
              value: conjunctionData,
            }}
            isSearchable={false}
            components={{ IndicatorSeparator: () => null }}
            options={MultiFilterConjunctions.map((u) => ({
              label: u,
              value: u,
            }))}
            onChange={(selected) => {
              const value = selected !== null ? selected.value : MultiFilterConjunctions[0];
              setFieldValue(`conjunction`, value);
            }}
            className={`data-test__programs-filter__conjunction-wrapper-${index}`}
            classNamePrefix={`data-test__programs-filter__conjunction-${index}`}
            styles={{
              menu: (provided) => ({
                ...provided,
                borderRadius: '8px',
              }),
              control: (provided) => ({
                ...provided,
                borderRadius: '8px',
              }),
            }}
          />
        ) : (
          <span data-test={`programs-filter__conjunction-wrapper-${index}`}>Where</span>
        )}
      </div>
      <div className="programs-filter__column programs-filter__column--field">
        <Select
          isMulti={false}
          value={{
            label: filteringRowData.selectedField.label,
            value: fieldIdentityToReactSelectValue(
              filteringRowData.selectedField.fieldIdentity,
              filteringRowData.selectedField.fieldMetaData.type
            ),
            field: filteringRowData.selectedField,
          }}
          isSearchable={true}
          components={{ IndicatorSeparator: () => null }}
          options={options}
          onChange={(selected) => {
            if (selected !== null) {
              const { field } = selected;
              const sameFilter = field.filters.find(
                (f) => f.name === filteringRowData.selectedFilter.name
              );
              setFieldValue(`filters.${index}.selectedField`, field);
              if (
                sameFilter &&
                ['boolean', 'string', 'number', 'date'].find((t) => t === field.fieldMetaData.type)
              ) {
                setFieldValue(`filters.${index}.selectedFilter`, sameFilter);
              } else {
                setFieldValue(`filters.${index}.selectedFilter`, field.filters[0]);
                setFieldValue(`filters.${index}.filterValue`, field.fieldMetaData.defaultValue);
              }
            }
          }}
          className={`data-test__programs-filter__field-wrapper-${index}`}
          classNamePrefix={`data-test__programs-filter__field-${index}`}
          styles={{
            menu: (provided) => ({
              ...provided,
              borderRadius: '8px',
            }),
            control: (provided) => ({
              ...provided,
              borderRadius: '8px',
            }),
          }}
        />
      </div>
      <div className="programs-filter__column programs-filter__column--filter">
        <Select
          isMulti={false}
          value={{
            label: filteringRowData.selectedFilter.name,
            value: filteringRowData.selectedFilter.name,
            filter: filteringRowData.selectedFilter,
          }}
          isSearchable={true}
          components={{ IndicatorSeparator: () => null }}
          options={filteringRowData.selectedField.filters.map((u) => ({
            label: u.name,
            value: u.name,
            filter: u,
          }))}
          onChange={(selected) => {
            if (selected !== null) {
              const { filter } = selected;
              setFieldValue(`filters.${index}.selectedFilter`, filter);
            }
          }}
          className={`data-test__programs-filter__filter-wrapper-${index}`}
          classNamePrefix={`data-test__programs-filter__filter-${index}`}
          styles={{
            menu: (provided) => ({
              ...provided,
              borderRadius: '8px',
            }),
            control: (provided) => ({
              ...provided,
              borderRadius: '8px',
            }),
          }}
        />
      </div>
      <div className="programs-filter__column programs-filter__column--value">
        {(() => {
          const { filterValue, selectedFilter, selectedField } = filteringRowData;
          if (selectedFilter.paramsCount === 0) return null;
          switch (selectedField.fieldMetaData.type) {
            case 'boolean':
              return (
                <FormikBooleanRadioInput
                  formikFieldName={`filters.${index}.filterValue`}
                  setFieldValue={setFieldValue}
                  dataTest={`programs-filter__boolean-value-${index}`}
                />
              );
            case 'string':
              return (
                <FormikTextInput
                  initialFieldValue={typeof filterValue === 'string' ? filterValue : ''}
                  formikFieldName={`filters.${index}.filterValue`}
                  disabled={disabled}
                  readOnly={false}
                  isError={fieldNameError}
                  dataTest={`programs-filter__text-value-${index}`}
                  className="programs-filter--text-input"
                  setFieldValue={(v) => setFieldValue(`filters.${index}.filterValue`, v)}
                  debounceMs={DebounceMsConfig.programMultiFilterInputDebounceMs}
                />
              );
            case 'number':
              return (
                <FormikNumberInput
                  initialFieldValue={typeof filterValue === 'number' ? filterValue : 0}
                  formikFieldName={`filters.${index}.filterValue`}
                  disabled={disabled}
                  readOnly={false}
                  isError={fieldNameError}
                  dataTest={`programs-filter__number-value-${index}`}
                  handleBlur={handleBlur}
                  setFieldValue={(v) => setFieldValue(`filters.${index}.filterValue`, v)}
                  className="programs-filter--text-input"
                  debounceMs={DebounceMsConfig.programMultiFilterInputDebounceMs}
                />
              );
            case 'date':
              return (
                <FormikDateTimeInput
                  initialFieldValue={filterValue instanceof Date ? filterValue : new Date()}
                  formikFieldName={`filters.${index}.filterValue`}
                  disabled={disabled}
                  readOnly={false}
                  isError={fieldNameError}
                  dataTest={`programs-filter__date-value-${index}`}
                  handleBlur={handleBlur}
                  setFieldValue={setFieldValue}
                  className="programs-filter--text-input"
                />
              );
            case 'user':
              return (
                <FormikMultiSelect
                  defaultValues={filteringRowData.filterValue as Array<User>}
                  onChange={(users) => setFieldValue(`filters.${index}.filterValue`, users)}
                  toOption={(u) => ({ label: extractUserName(u), value: u.id })}
                  availableValues={existedUsers}
                  className={`data-test__programs-filter--user-value-wrapper-${index}`}
                  classNamePrefix={`data-test__programs-filter--user-value-${index}`}
                  isSearchable={true}
                />
              );
            case 'program-status':
              return (
                <FormikMultiSelect
                  defaultValues={filteringRowData.filterValue as Array<ProgramStatusOption>}
                  onChange={(statuses) => setFieldValue(`filters.${index}.filterValue`, statuses)}
                  toOption={(s) => ({
                    label: s.name,
                    value: s.id,
                  })}
                  availableValues={ProgramStatusesOptions.map((x) => x)}
                  className={`data-test__programs-filter--status-value-wrapper-${index}`}
                  classNamePrefix={`data-test__programs-filter--status-value-${index}`}
                  isSearchable={true}
                />
              );
            case 'program-type':
              return (
                <FormikMultiSelect
                  defaultValues={filteringRowData.filterValue as Array<ProgramType>}
                  onChange={(programTypes) =>
                    setFieldValue(`filters.${index}.filterValue`, programTypes)
                  }
                  toOption={(t) => ({ label: _.capitalize(t.name), value: t.id })}
                  availableValues={existedTypes}
                  className={`data-test__programs-filter--type-value-wrapper-${index}`}
                  classNamePrefix={`data-test__programs-filter--type-value-${index}`}
                  isSearchable={true}
                />
              );
            case 'campaign':
              return (
                <FormikMultiSelect
                  defaultValues={filteringRowData.filterValue as Array<ResolvedCampaign>}
                  onChange={(campaigns) => setFieldValue(`filters.${index}.filterValue`, campaigns)}
                  toOption={(t) => ({ label: _.capitalize(t.name), value: t.id })}
                  availableValues={[...existedCampaigns].sort((a, b) =>
                    a.name.localeCompare(b.name)
                  )}
                  className={`data-test__programs-filter--campaign-value-wrapper-${index}`}
                  classNamePrefix={`data-test__programs-filter--campaign-value-${index}`}
                  isSearchable={true}
                />
              );
            default:
              return <></>;
          }
        })()}
      </div>
      <div className="programs-filter__column">
        <DivButton
          icon={<CloseIcon />}
          onClick={() => arrayHelpers.remove(index)}
          dataTest={`programs-filter__delete-${index}`}
        />
      </div>
    </div>
  );
};
