import React, { useCallback, useMemo, useState } from 'react';
import { GoogleCalendarIcon } from '../../assets/icons';
import { googleConfig } from '../../commons/config';
import { useFetchedGoogleOauth } from '../hooks/useFetchedGoogleOauth';
import useAnalytics from '../../web-analytics/webAnalyticsContext';
import { analyticsTrack } from '../../web-analytics/webAnalytics';
import { AnalyticsEvent } from '../../web-analytics/AnalyticsEvent';
import { CodeResponse, useGoogleLogin } from '@react-oauth/google';
import { useQueryCacheManager } from '../../hooks/useQueryCacheManager';
import { useMutation } from '@tanstack/react-query';
import useApi from '../../api/backendApiContext';
import { ConfirmDialogProps, ConfirmModal } from '../../commons/components/ConfirmModal';
import { useNotification } from '../../notification/notificationsContext';
import { errorToString, getHttpErrorCode } from '../../util/utils';
import { notConnectedGoogleOauth } from '../googleCalendarApi';
import googleCalendarPermissionsImage from '../../assets/images/google-calendar-permissions.png';
import { BASE_URL } from '../../commons/utils';
import { IntegrationTile } from '../../commons/components/IntegrationTile';

const requiredGoogleScopes = [
  'https://www.googleapis.com/auth/userinfo.email',
  'https://www.googleapis.com/auth/calendar.app.created',
  'https://www.googleapis.com/auth/calendar.calendarlist.readonly',
];

export const GoogleCalendarSettings = (props: { userId: string }) => {
  const analytics = useAnalytics();

  const { userId } = props;
  const {
    query: { isLoading, isError, data },
    queryStatusComponent,
  } = useFetchedGoogleOauth(userId);
  const { googleCalendarApi } = useApi();
  const { notifySuccess, notifyError } = useNotification();

  const isActive = !!data && data.isConfigured && !data.isDisconnected;
  const isDisconnected = !!data && data.isConfigured && data.isDisconnected;

  const queryCacheManager = useQueryCacheManager();

  const { isLoading: isConnecting, mutate: connect } = useMutation(
    async (authCode: string) => googleCalendarApi.exchangeAuthCode(userId, authCode, BASE_URL),
    {
      onSuccess: async (googleOauth) => {
        await queryCacheManager.setGoogleOauth(userId, googleOauth);
        const message = 'Synchronization with Google Calendar has been successfully setup';
        notifySuccess({
          notificationMsg: message,
          logMsg: message,
        });
        analyticsTrack(analytics, AnalyticsEvent.GOOGLE_CALENDAR_CONNECTED);
      },
      onError: (e) => {
        const message = errorToString(
          e,
          'Something when wrong while setting up synchronization with Google Calendar. Please try again.'
        );
        notifyError({
          notificationMsg: message,
          logMsg: message,
        });
      },
    }
  );

  const login = useGoogleLogin({
    onSuccess: async (
      codeResponse: Omit<CodeResponse, 'error' | 'error_description' | 'error_uri'>
    ) => {
      const actualScopes = codeResponse.scope.split(' ');
      const missingScopes = requiredGoogleScopes.filter((x) => !actualScopes.includes(x));
      if (missingScopes.length > 0) {
        analyticsTrack(analytics, AnalyticsEvent.GOOGLE_CALENDAR_MISSING_PERMISSIONS);
        setConfirmReconnectModal(true);
        return;
      }
      await connect(codeResponse.code);
    },
    flow: 'auth-code',
    scope: requiredGoogleScopes.join(' '),
    overrideScope: true,
    ux_mode: 'popup',
  });

  const { isLoading: isDisconnecting, mutate: disconnect } = useMutation(
    async () => googleCalendarApi.deleteGoogleOauth(userId),
    {
      onSuccess: async () => {
        await queryCacheManager.setGoogleOauth(userId, notConnectedGoogleOauth);
        const message = 'Synchronization with Google Calendar has been successfully stopped.';
        notifySuccess({
          notificationMsg: message,
          logMsg: message,
        });
        analyticsTrack(analytics, AnalyticsEvent.GOOGLE_CALENDAR_DISCONNECTED);
      },
      onError: (e) => {
        const message = errorToString(
          e,
          'Something when wrong while disconnecting from Google Calendar. Please try again.'
        );
        notifyError({
          notificationMsg: message,
          logMsg: message,
        });
      },
    }
  );

  const [confirmDisconnectModal, setConfirmDisconnectModal] = useState(false);
  const [confirmReconnectModal, setConfirmReconnectModal] = useState(false);

  const onDisconnectConfirmed = useCallback(async () => {
    setConfirmDisconnectModal(false);
    await disconnect();
  }, [disconnect]);

  const disconnectConfirmDialogProps: ConfirmDialogProps = useMemo<ConfirmDialogProps>(
    () => ({
      title: 'Disconnect Growegy and Google Calendar?',
      message: 'You’ll be able to reconnect at any time.',
      footer: {
        type: 'show',
        applyButton: {
          label: 'Disconnect',
          variant: 'danger',
          onClick: onDisconnectConfirmed,
        },
        cancelButton: {
          label: 'Cancel',
          onClick: () => setConfirmDisconnectModal(false),
        },
      },
    }),
    [onDisconnectConfirmed]
  );

  const reconnectConfirmDialogProps: ConfirmDialogProps = useMemo<ConfirmDialogProps>(
    () => ({
      title: 'Growegy needs more permissions to synchronize with Google Calendar',
      message:
        'Google Calendar could not be connected because not all the requested permissions have been granted. ' +
        'Please start over and grant all the requested permissions.',
      footer: {
        type: 'show',
        applyButton: {
          label: 'Connect',
          variant: 'primary',
          onClick: () => {
            setConfirmReconnectModal(false);
            login();
          },
        },
        cancelButton: {
          label: 'Cancel',
          onClick: () => setConfirmReconnectModal(false),
        },
      },
    }),
    [login]
  );

  const { isLoading: isSchedulingResync, mutate: scheduleResync } = useMutation(
    async () => googleCalendarApi.scheduleResync(userId),
    {
      onSuccess: () => {
        const message =
          'Re-synchronization with Google Calendar has been successfully scheduled. It may take a couple of minutes.';
        notifySuccess({
          notificationMsg: message,
          logMsg: message,
        });
        analyticsTrack(analytics, AnalyticsEvent.GOOGLE_CALENDAR_RESYNC_SCHEDULED);
      },
      onError: async (e) => {
        if (getHttpErrorCode(e) === 404) {
          await queryCacheManager.setGoogleOauth(userId, notConnectedGoogleOauth);
        }
        const message = errorToString(
          e,
          'Something when wrong while scheduling re-synchronization with Google Calendar. Please try again.'
        );
        notifyError({
          notificationMsg: message,
          logMsg: message,
        });
      },
    }
  );

  return (
    <>
      <IntegrationTile
        isActive={isActive}
        isDisconnected={isDisconnected}
        isConnecting={isConnecting}
        isDisconnecting={isDisconnecting}
        isSchedulingResync={isSchedulingResync}
        isLoading={isLoading}
        isError={isError}
        queryStatusComponent={queryStatusComponent}
        title="Google Calendar"
        subtitle={data && data.isConfigured ? data.email : null}
        privacyPolicyUrl="https://www.growegy.com/resources/about/privacy-policy/"
        learnMoreUrl={googleConfig.calendarHelpUrl}
        learnMoreViewedEvent={AnalyticsEvent.GOOGLE_CALENDAR_HELP_VIEWED}
        onConnectClick={login}
        onDisconnectClick={() => setConfirmDisconnectModal(true)}
        onResyncClick={scheduleResync}
        icon={<GoogleCalendarIcon className="integration-row-icon" />}
      >
        <div>
          Keep your Google Calendar and Growegy completely in sync with this real-time, two-way
          integration.
        </div>
        <div>
          This automatically syncs programs owned by you and the associated tasks, as well as tasks
          assigned to you in other programs.
        </div>
      </IntegrationTile>
      {confirmDisconnectModal && <ConfirmModal {...disconnectConfirmDialogProps} />}
      {confirmReconnectModal && (
        <ConfirmModal {...reconnectConfirmDialogProps}>
          <div className="mt-5 w-100">
            <img
              src={googleCalendarPermissionsImage}
              alt="Grant all the requested Google Calendar permissions"
              width="400px"
              height="auto"
              className="ml-auto mr-auto d-block"
            />
          </div>
        </ConfirmModal>
      )}
    </>
  );
};
