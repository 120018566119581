import React, { PropsWithChildren, useEffect } from 'react';
import { Navigate } from 'react-router-dom';
import useAuth from '../auth/authContext';
import { AppRoute, AppRoutes, isRouteAvailable } from './AppRoutes';
import { useIsFirstRender } from '../hooks/useIsFirstRender';
import { AuthAction } from '../auth/authStateTypes';

const RoutePostProcessWrapper = (props: { children: React.ReactNode; dispatch: () => void }) => {
  const { children, dispatch } = props;

  const firstRender = useIsFirstRender();
  useEffect(() => {
    if (firstRender) {
      setTimeout(() => dispatch());
    }
  }, [firstRender, dispatch]);

  return <>{children}</>;
};

const getAuthAction = (path: AppRoute): AuthAction | null => {
  switch (path) {
    case AppRoute.signIn:
    case AppRoute.signOut:
      return { type: 'SIGNOUT' };
    default:
      return null;
  }
};

export const RequireAuth = (
  props: {
    path: AppRoute;
  } & PropsWithChildren
) => {
  const { path, children } = props;
  const {
    state: { user },
    dispatch,
  } = useAuth();
  const isAuthorized = isRouteAvailable(user, path);
  if (isAuthorized) {
    const action = getAuthAction(path);
    if (action) {
      return (
        <RoutePostProcessWrapper dispatch={() => dispatch(action)}>
          {children}
        </RoutePostProcessWrapper>
      );
    }
    return <>{children}</>;
  }

  return (
    <Navigate
      to={{
        pathname: user ? AppRoutes.Home : AppRoutes.Auth.signIn,
      }}
      state={{}}
    />
  );
};
