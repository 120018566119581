import React, { useState } from 'react';
import { InitialStep } from './InitialStep';
import { FinalStep } from './FinalStep';
import { FeedbackStep } from './FeedbackStep';
import { CancelationReason } from '../../types';

export const CancellationWizard = () => {
  const [step, setStep] = useState<'initial' | 'feedback' | 'final' | null>('initial');
  const [selectedReasons, setSelectedReasons] = useState<CancelationReason[]>([]);
  const [comment, setComment] = useState('');

  return (
    <>
      {step === 'initial' && (
        <InitialStep onNext={() => setStep('feedback')} onCancel={() => setStep(null)} />
      )}
      {step === 'feedback' && (
        <FeedbackStep
          selectedReasons={selectedReasons}
          onSelectedReasonsChanged={setSelectedReasons}
          comment={comment}
          onCommentChanged={setComment}
          onGoBackClicked={() => setStep('initial')}
          onBillingPlanCanceled={() => setStep('final')}
        />
      )}
      {step === 'final' && <FinalStep />}
    </>
  );
};
