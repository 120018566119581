import { AxiosInstance } from 'axios';
import { User, UserSettings, UserStatus } from './types';
import { parseRole } from '../auth/systemRole';

export type UserDto = {
  id: string;
  username: string;
  role: string;
  status: UserStatus;
  firstName: string | null;
  lastName: string | null;
  avatar: string | null;
};

export type UsersDto = {
  users: Array<UserDto>;
};

type ActionStatusDto = {
  status: number;
  msg: string;
};

export type UserSettingsDto = {
  firstName: string | null;
  lastName: string | null;
  title: string | null;
  location: string | null;
  version?: number | null;
};

export type NotificationSettingsDto = {
  inAppEnabled: boolean;
  emailEnabled: boolean;
  version?: number | null;
};

export type UserApi = ReturnType<typeof getUserApi>;

const toUserSettings = (userSettingsDto: UserSettingsDto): UserSettings => {
  const { firstName, lastName, title, location, version } = userSettingsDto;
  return {
    firstName: firstName ?? '',
    lastName: lastName ?? '',
    title: title ?? '',
    location: location ?? '',
    version: version ?? undefined,
  };
};

const extractUserSettingsDto = (userSettings: UserSettings): UserSettingsDto => {
  const { firstName, lastName, title, location, version } = userSettings;
  return {
    firstName,
    lastName,
    title,
    location,
    version,
  };
};

const userDtoToUser = (dto: UserDto): User => {
  const { id, status, username, role, firstName, lastName, avatar } = dto;
  const avatarWithMeta = avatar ? `data:image/png;base64,${avatar}` : avatar;
  const userRole = parseRole(role);
  if (userRole) {
    return {
      id,
      status,
      email: username,
      role: userRole,
      firstName: firstName ?? '',
      lastName: lastName ?? '',
      avatar: avatarWithMeta,
    };
  }
  throw new Error(`Unexpected user role ${role} for ${JSON.stringify(dto)}`);
};

export function getUserApi(axiosInstance: AxiosInstance) {
  const createUser = async (user: { username: string; role: string }) =>
    axiosInstance.post<UserDto>('/users', user).then((r) => userDtoToUser(r.data));

  const updateUser = async (user: { id: string; role: string }) => {
    const { id, role: newRole } = user;
    return axiosInstance
      .patch<ActionStatusDto>(`/users/${id}`, { role: newRole })
      .then((r) => r.data);
  };

  const resendPassword = async (id: string) =>
    axiosInstance.put<ActionStatusDto>(`/users/${id}/password`).then((r) => r.data);

  const getUserSettings = async (id: string): Promise<UserSettings | null> =>
    axiosInstance
      .get<UserSettingsDto>(`/users/${id}/settings`)
      .then(({ data }) => toUserSettings(data));

  const getUsers = async () => {
    logger.info('Getting users');
    const users = await axiosInstance
      .get<UsersDto>('/users')
      .then((r) =>
        r.data.users.sort((a, b) => a.username.localeCompare(b.username)).map(userDtoToUser)
      );

    return users.sort((a, b) => a.email.localeCompare(b.email));
  };

  const getUser = async (id: string) =>
    axiosInstance.get<UserDto>(`/users/${id}`).then((r) => userDtoToUser(r.data));

  const deleteUser = async (id: string) =>
    axiosInstance.delete<ActionStatusDto>(`/users/${id}`).then((r) => r.data);

  const getAvatar = async (id: string): Promise<Blob> =>
    axiosInstance
      .get<Blob>(`/users/${id}/avatar`, {
        responseType: 'blob',
      })
      .then((r) => r.data);

  const changeAvatar = async (id: string, file: File) =>
    axiosInstance
      .put<ActionStatusDto>(`/users/${id}/avatar`, file, {
        headers: { 'Content-Type': 'image/png' },
      })
      .then((r) => r.data);

  const deleteAvatar = async (id: string) =>
    axiosInstance.delete<ActionStatusDto>(`/users/${id}/avatar`).then((r) => r.data);

  const updateUserSettings = async (id: string, userSettings: UserSettings) => {
    await axiosInstance.put<ActionStatusDto>(
      `/users/${id}/settings`,
      extractUserSettingsDto(userSettings)
    );
  };

  const deleteUserSettings = async (id: string) => {
    await axiosInstance.delete(`/users/${id}/settings`);
  };

  const getNotificationSettings = async (id: string) =>
    axiosInstance
      .get<NotificationSettingsDto>(`/notifications/user/${id}/settings`)
      .then((r) => r.data);

  const updateNotificationSettings = async (
    id: string,
    notificationSettings: NotificationSettingsDto
  ) =>
    axiosInstance
      .put<ActionStatusDto>(`/notifications/user/${id}/settings`, notificationSettings)
      .then((r) => r.data);

  return {
    createUser,
    updateUser,
    resendPassword,
    getUsers,
    getUser,
    deleteUser,
    getAvatar,
    changeAvatar,
    deleteAvatar,
    getUserSettings,
    updateUserSettings,
    deleteUserSettings,
    getNotificationSettings,
    updateNotificationSettings,
  };
}
