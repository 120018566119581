import { useFetchedPromptVariables } from './useFetchedPromptVariables';
import { QueryData } from '../../../program/types';
import { PromptVariable } from '../../types';

export const useFetchedPromptVariable = (
  variableName: string | null
): { query: QueryData<PromptVariable | null>; queryStatusComponent: JSX.Element } => {
  const { query, queryStatusComponent } = useFetchedPromptVariables();
  if (variableName === null)
    return {
      query: {
        data: null,
        error: null,
        status: 'success',
        fetchStatus: 'idle',
        isStale: false,
      },
      queryStatusComponent: <></>,
    };

  const promptVariable = variableName
    ? query.data?.find((x) => x.variableValue.name === variableName) ?? null
    : null;
  const resultQuery: QueryData<PromptVariable | null> = { ...query, data: promptVariable };
  return { query: resultQuery, queryStatusComponent };
};
