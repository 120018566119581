import { PersonalRecommendations } from './types';

export const RecommendationsLocalStorageKey = 'Recommendations/';

export const extractVersions: (recommendations: PersonalRecommendations) => string[] = (
  recommendations
) =>
  recommendations.recommendations
    .sort((a, b) => a.name.localeCompare(b.name))
    .map((r) => r.version);
