import { ConfirmModal } from '../../../commons/components/ConfirmModal';
import React, { useMemo } from 'react';
import useApi from '../../../api/backendApiContext';
import { useQueryCacheManager } from '../../../hooks/useQueryCacheManager';
import { useMutation } from '@tanstack/react-query';
import _ from 'lodash';
import clsx from 'clsx';
import { CheckSmallIcon } from '../../../assets/icons';
import { StyleVariables } from '../../../commons/styleConstants';
import { CancelationReason, Customer } from '../../types';
import useAnalytics from '../../../web-analytics/webAnalyticsContext';
import { analyticsTrack } from '../../../web-analytics/webAnalytics';
import { AnalyticsEvent } from '../../../web-analytics/AnalyticsEvent';

const CancellationReasons: CancelationReason[] = [
  { text: 'I didn’t use it as expected', code: 'not_used_as_expected' },
  { text: 'I only used it to build credit', code: 'only_credit' },
  { text: 'I need more features', code: 'missing_features' },
  { text: 'Ease of use was less than expected', code: 'hard_to_use' },
  { text: 'I found an alternative', code: 'alternative' },
  { text: 'It’s too expensive', code: 'expensive' },
];

const OTHER_REASON: CancelationReason = { text: 'Other reason', code: 'other' };

const CancellationReason = (props: {
  reason: string;
  isSelected: boolean;
  onSelectedChanged: (selected: boolean) => void;
}) => {
  const { reason, isSelected, onSelectedChanged } = props;
  return (
    <button
      className={clsx('cancellation-feedback__reason-container', {
        'cancellation-feedback__reason-container--selected': isSelected,
      })}
      onClick={() => onSelectedChanged(!isSelected)}
    >
      <div className="cancellation-feedback__reason-body">
        <div className="flex-grow-1 text-left" data-test="cancellation-feedback__reason">
          {reason}
        </div>
        {isSelected && (
          <CheckSmallIcon
            fill={StyleVariables.cyan500}
            data-test="cancellation-feedback__reason-tick"
          />
        )}
      </div>
    </button>
  );
};

export const FeedbackStep = (props: {
  selectedReasons: CancelationReason[];
  onSelectedReasonsChanged: (selectedReasons: CancelationReason[]) => void;
  comment: string;
  onCommentChanged: (comment: string) => void;
  onGoBackClicked: () => void;
  onBillingPlanCanceled: (customer: Customer) => void;
}) => {
  const {
    selectedReasons,
    onSelectedReasonsChanged,
    comment,
    onCommentChanged,
    onGoBackClicked,
    onBillingPlanCanceled,
  } = props;
  const { billingApi } = useApi();
  const queryCacheManager = useQueryCacheManager();
  const analytics = useAnalytics();

  const { mutate: cancelPlan, status: cancelPlanStatus } = useMutation(
    async (selectedReasons: CancelationReason[]) =>
      billingApi.cancelBillingPlan({ comment, reasons: selectedReasons.map((x) => x.text) }),
    {
      onError: async () => {
        await queryCacheManager.invalidateBillingCustomer();
        await queryCacheManager.invalidateInvoices();
      },
      onSuccess: async (customer, reasons) => {
        await queryCacheManager.updateBillingCustomer(customer);
        await queryCacheManager.invalidateInvoices();
        onBillingPlanCanceled(customer);
        analyticsTrack(analytics, AnalyticsEvent.BILLING_PLAN_CANCELED, {
          reason: reasons.map((x) => x.code),
        });
      },
    }
  );
  const reasons = useMemo(() => [..._.shuffle(CancellationReasons), OTHER_REASON], []);

  return (
    <ConfirmModal
      title="Tell us why you are cancelling this plan"
      subTitle="You can select multiple options."
      ariaHideApp={false}
      shouldCloseOnOverlayClick={false}
      shouldCloseOnEsc={true}
      footer={{
        type: 'show',
        extraButtons: [
          {
            label: 'Go back',
            variant: 'light',
            disabled: cancelPlanStatus === 'loading' || cancelPlanStatus === 'success',
            onClick: onGoBackClicked,
          },
          {
            label: 'Cancel plan',
            disabled: cancelPlanStatus === 'loading' || cancelPlanStatus === 'success',
            variant: 'danger',
            onClick: () => cancelPlan(selectedReasons),
          },
        ],
      }}
    >
      {reasons.map((x) => (
        <CancellationReason
          key={`reason_${x.code}`}
          reason={x.text}
          isSelected={selectedReasons.indexOf(x) >= 0}
          onSelectedChanged={(isSelected) => {
            if (isSelected) onSelectedReasonsChanged([...selectedReasons, x]);
            else onSelectedReasonsChanged(selectedReasons.filter((r) => r.code !== x.code));
          }}
        />
      ))}
      <div className="cancellation-feedback__comment-wrapper">
        <textarea
          className="cancellation-feedback__comment"
          data-test="cancellation-feedback__comment"
          placeholder="Share your feedback with us…"
          value={comment}
          maxLength={1024}
          onChange={(event) => {
            onCommentChanged(event.target.value);
          }}
        />
      </div>
    </ConfirmModal>
  );
};
