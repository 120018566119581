/* eslint-disable react-hooks/rules-of-hooks */
import { ProgramRow } from '../../types';
import React, { useEffect, useState, MouseEvent, useCallback } from 'react';
import { CellProps } from './ProgramCells';
import { User } from '../../../user/types';
import { ProgramOwnerSelect, UserWithAvatar } from '../ProgramOwnerSelect';
import { GrowegySelectMockup } from '../GrowegySelectMockup';
import { ProgramTableCellWidth } from '../../util';

const OwnerCell =
  ({ dataTest, isReadonly }: { dataTest?: string; isReadonly?: boolean }) =>
  ({
    onProgramUpdate,
    onRowEditChange,
    value: origValue,
    row,
    readOnly,
  }: CellProps<ProgramRow, User | null>) => {
    const [value, setValue] = useState<User | null>(origValue);
    const [editing, setEditing] = useState(false);

    useEffect(() => {
      setValue(origValue);
    }, [origValue]);

    const onEditClick = useCallback(
      (e: MouseEvent<HTMLDivElement>) => {
        if (row.isGrouped) return;
        e.stopPropagation();
        if (readOnly || isReadonly) return;
        if (editing) return;
        setEditing(true);
        onRowEditChange(true);
      },
      [row.isGrouped, readOnly, editing, onRowEditChange]
    );

    const cancel = useCallback(() => {
      setValue(origValue);
      setEditing(false);
      onRowEditChange(false);
    }, [onRowEditChange, origValue]);

    const handleOwnerChange = (owner: User | null) => {
      if (owner?.id !== row.original.owningUserId) {
        onProgramUpdate({
          original: row.original,
          updates: [{ field: 'owner', value: owner }],
        });
      }
      cancel();
    };

    return (
      <div
        data-test={dataTest}
        style={{ width: '100%' }}
        onClick={onEditClick}
        onKeyDown={(e) => {
          if (editing) e.stopPropagation();
        }}
      >
        {!row.isGrouped &&
          (editing ? (
            <ProgramOwnerSelect
              dataTestPrefix={`${dataTest ? `${dataTest}-select` : undefined}`}
              disabled={isReadonly || readOnly}
              onBlur={cancel}
              initialValue={value}
              onValueChange={handleOwnerChange}
              autoFocus={true}
              width={ProgramTableCellWidth}
            />
          ) : (
            <GrowegySelectMockup placeholderText="Add owner" readOnly={isReadonly || readOnly}>
              {value ? <UserWithAvatar user={value} /> : undefined}
            </GrowegySelectMockup>
          ))}
      </div>
    );
  };

export default OwnerCell;
