import { useQuery } from '@tanstack/react-query';
import { ProgramTypesCacheKey } from '../../hooks/useQueryCacheManager';
import useApi from '../../api/backendApiContext';
import { ReactQueryConstants } from '../../commons/constants';

export const useFetchedProgramTypes = () => {
  const { programApi } = useApi();
  return useQuery(ProgramTypesCacheKey, async () => programApi.getProgramTypes(), {
    staleTime: ReactQueryConstants.programTypesStaleTime,
    refetchOnWindowFocus: false,
  });
};
