import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Form } from 'react-bootstrap';
import { ErrorMessage, Field } from 'formik';
import { ColoredItem, ProgramEditFormikModel } from '../types';
import { formatFormulaValue, MathJsIdentity } from '../formulaUtil';
import { Mention, MentionsInput } from 'react-mentions';

import clsx from 'clsx';
import { useAsyncDebounce } from 'react-table';
import { DebounceMsConfig } from '../../config';
import { PencilButton } from '../../commons/components/PencilButton';
import { FormulaInputHelp } from './FormulaInputHelp';
import { ColoredSelect } from './ColoredSelect';
import { StyleVariables } from '../../commons/styleConstants';
import { ModalDropdownMenuZIndex } from '../util';

const alphabet = /(\b(\w+))$/;

export type PrefixAndSuffix = { prefix: string | null; suffix: string | null };
type PrefixSuffixOption = ColoredItem & PrefixAndSuffix;

const NUMBER_PREFIX_SUFFIX = {
  id: 'number',
  name: 'Number',
  prefix: null,
  suffix: null,
  color: StyleVariables.black,
};
const PERCENT_PREFIX_SUFFIX = {
  id: 'percent',
  name: 'Percent, %',
  prefix: null,
  suffix: '%',
  color: StyleVariables.black,
};
const CURRENCY_PREFIX_SUFFIX = {
  id: 'currency',
  name: 'Currency, $',
  prefix: '$',
  suffix: null,
  color: StyleVariables.black,
};
const prefixSuffixOptions: PrefixSuffixOption[] = [
  NUMBER_PREFIX_SUFFIX,
  PERCENT_PREFIX_SUFFIX,
  CURRENCY_PREFIX_SUFFIX,
];

const STATE_OK = 'OK';
const STATE_EMPTY = 'Empty';
const STATE_ERROR = 'Error';

const getPrefixSuffixOption = (prefixSuffix: PrefixAndSuffix): PrefixSuffixOption => {
  const filtered = prefixSuffixOptions.filter(
    (x) => x.prefix === prefixSuffix.prefix && x.suffix === prefixSuffix.suffix
  );
  if (filtered.length > 0) return filtered[0];
  return {
    id: 'other',
    name: 'Other',
    color: StyleVariables.black,
    prefix: prefixSuffix.prefix,
    suffix: prefixSuffix.suffix,
  };
};

type CustomParamsFormulaInputProps = {
  formikValues: ProgramEditFormikModel;
  initialValue: { formula: string } & PrefixAndSuffix;
  mathJsId: string;
  disabled: boolean;
  readOnly: boolean;
  dataTest: string;
  setFieldValue: (v: { formula: string } & PrefixAndSuffix) => void;
  isError: boolean;
};

export const FormulaInput = ({
  formikValues,
  initialValue,
  mathJsId,
  disabled,
  readOnly,
  dataTest,
  setFieldValue,
  isError,
}: CustomParamsFormulaInputProps) => {
  const mentionsData = useMemo(
    () => formikValues.mentionsData.filter(({ id }) => id !== mathJsId),
    [formikValues.mentionsData, mathJsId]
  );

  const [currentValue, setCurrentValue] = useState<{ formula: string } & PrefixAndSuffix>(
    initialValue
  );

  const [editMode, setEditMode] = useState<{ isOn: boolean; reason: 'pencil-button' | 'other' }>({
    isOn: isError,
    reason: 'other',
  });
  useEffect(() => {
    if ((isError || !currentValue.formula) && !editMode.isOn)
      setEditMode({ isOn: true, reason: 'other' });
  }, [isError, editMode.isOn, currentValue.formula]);

  const inputRef = useRef<HTMLInputElement>(null);
  useEffect(() => {
    if (!editMode.isOn || editMode.reason !== 'pencil-button') return;
    const input = inputRef.current;
    if (input) {
      input.focus();
      input.selectionStart = input.value.length;
    }
  }, [editMode]);

  const formulaResult = formikValues.formulaScope[mathJsId];
  const formattedFormulaResult = useMemo(() => {
    return formatFormulaValue(
      formulaResult,
      currentValue.prefix ?? null,
      currentValue.suffix ?? null
    );
  }, [formulaResult, currentValue.prefix, currentValue.suffix]);
  const calculateDisplayResult = useCallback(() => {
    return {
      value: currentValue.formula ? formattedFormulaResult : 'Get value',
      isError:
        isError ||
        ((formulaResult === undefined ||
          !Number.isFinite(formulaResult) ||
          Number.isNaN(formulaResult)) &&
          !!currentValue.formula),
      state:
        isError && currentValue.formula
          ? STATE_ERROR
          : currentValue.formula
          ? STATE_OK
          : STATE_EMPTY,
    };
  }, [currentValue.formula, formulaResult, formattedFormulaResult, isError]);
  const [displayFormulaResult, setDisplayFormulaResult] = useState(calculateDisplayResult());
  const setDisplayFormulaResultDebounce = useAsyncDebounce(
    () => setDisplayFormulaResult(calculateDisplayResult()),
    3000
  );
  const [formulaEditorInFocus, setFormulaEditorInFocus] = useState(false);
  useEffect(() => {
    if (
      (displayFormulaResult.isError && !isError) ||
      (formulaResult !== undefined &&
        Number.isFinite(formulaResult) &&
        !Number.isNaN(formulaResult)) ||
      !formulaEditorInFocus
    ) {
      setDisplayFormulaResult(calculateDisplayResult());
    } else {
      setDisplayFormulaResultDebounce();
    }
  }, [
    formulaResult,
    isError,
    displayFormulaResult.isError,
    setDisplayFormulaResultDebounce,
    calculateDisplayResult,
    formulaEditorInFocus,
  ]);

  const setFieldValueDebounce = useAsyncDebounce(() => {
    setFieldValue(currentValue);
  }, DebounceMsConfig.programEditInputDebounceMs);

  const handleChange = (formula: string) => {
    setCurrentValue((prevState) => ({ ...prevState, formula: formula }));
    setFieldValueDebounce();
  };

  const handlePrefixSuffixChange = (prefixSuffixOption: PrefixSuffixOption) => {
    setCurrentValue((prevState) => ({
      ...prevState,
      prefix: prefixSuffixOption.prefix,
      suffix: prefixSuffixOption.suffix,
    }));
    setFieldValueDebounce();
  };

  return (
    <>
      {!editMode.isOn && (
        <Form.Group controlId={mathJsId} className="formula-input__edit-view-wrapper">
          <div
            className={clsx({
              'formula-input__result-wrapper': !readOnly && !disabled,
              'formula-input__result-wrapper--readonly': readOnly || disabled,
            })}
            data-test={`${dataTest}-result-wrapper`}
          >
            <Field
              type="text"
              name={`formulaScope.${mathJsId}`}
              value={formattedFormulaResult}
              readOnly={true}
              className="formula-input__result"
              data-test={dataTest}
            />
            {!readOnly && (
              <div className="formula-input__edit-button-wrapper">
                <PencilButton
                  onEdit={() => {
                    setEditMode({ isOn: true, reason: 'pencil-button' });
                  }}
                  dataTest={`${dataTest}-formulaEdit`}
                />
              </div>
            )}
            <ErrorMessage
              component="div"
              name={`formulaScope.${mathJsId}`}
              className="invalid-feedback py-3"
              data-test={`${dataTest}-validation-error`}
            />
          </div>
        </Form.Group>
      )}
      {editMode.isOn && (
        <div
          className="formula-input__edit-edit-wrapper"
          onKeyDown={(e) => {
            if (e.key === 'Escape' || e.key === 'Enter') {
              e.preventDefault();
              e.stopPropagation();
              if (!isError && currentValue.formula) {
                setEditMode({ isOn: false, reason: 'other' });
              }
            }
          }}
          onBlur={(e) => {
            if (e.currentTarget && e.relatedTarget && e.currentTarget.contains(e.relatedTarget))
              return;
            if (!isError && currentValue.formula) {
              setEditMode({ isOn: false, reason: 'other' });
            }
          }}
        >
          <div className="formula-input__editor-and-prefix-suffix-wrapper">
            <div
              className={clsx('formula-input__editor-wrapper', {
                'error-border': displayFormulaResult.isError,
              })}
              data-test={`${dataTest}-formula-editor`}
            >
              <MentionsInput
                disabled={disabled}
                readOnly={readOnly}
                singleLine={false}
                aria-multiline={true}
                data-test={`${dataTest}-formulaExpression`}
                value={currentValue.formula}
                placeholder="Enter formula"
                className="formula-input-mentions"
                a11ySuggestionsListLabel="Suggested fields"
                onChange={(e) => handleChange(e.target.value)}
                onFocus={() => setFormulaEditorInFocus(true)}
                onBlur={(e, clickedSuggestion) => {
                  setFormulaEditorInFocus(false);
                  if (clickedSuggestion) e.stopPropagation();
                }}
                inputRef={inputRef}
              >
                <Mention
                  markup={`@[__display__](${MathJsIdentity}:__id__)`}
                  trigger={alphabet}
                  data={mentionsData}
                  appendSpaceOnAdd={true}
                  renderSuggestion={(highlightedDisplay) => (
                    <span
                      data-test={`${dataTest}-formula-suggestion--${highlightedDisplay.display}`}
                    >
                      {highlightedDisplay.display}
                    </span>
                  )}
                  className="formula-input-mentions__mention-text"
                />
              </MentionsInput>

              <div className="formula-input__editor-result-container">
                <div
                  className={clsx('formula-input__editor-result', {
                    'formula-input__editor-result--error':
                      displayFormulaResult.state === STATE_ERROR,
                    'formula-input__editor-result--empty':
                      displayFormulaResult.state === STATE_EMPTY,
                    'formula-input__editor-result--calculated':
                      displayFormulaResult.state === STATE_OK,
                  })}
                  tabIndex={0}
                >
                  =&nbsp;
                  <span data-test={`${dataTest}-editor-result`}>{displayFormulaResult.value}</span>
                </div>
              </div>
            </div>
            <ColoredSelect
              initialValue={getPrefixSuffixOption(currentValue)}
              dataTestPrefix={`${dataTest}-prefix-suffix`}
              coloredItems={prefixSuffixOptions}
              disabled={disabled || readOnly}
              onValueChange={handlePrefixSuffixChange}
              isSearchable={false}
              isRequired={true}
              menuZIndex={ModalDropdownMenuZIndex}
            />
          </div>
          <FormulaInputHelp dataTest={dataTest} />
        </div>
      )}
    </>
  );
};
