import { useMediaQuery } from 'react-responsive';

const TABLET_BREAKPOINT = 768;
const DESKTOP_BREAKPOINT = 1024;

export const useIsSmallerThanTablet = () => {
  return useMediaQuery({ query: `(max-width: ${TABLET_BREAKPOINT - 1}px)` });
};

export const useIsTabletOrLarger = () => {
  return useMediaQuery({ query: `(min-width: ${TABLET_BREAKPOINT}px)` });
};

export const useIsSmallerThanDesktop = () => {
  return useMediaQuery({ query: `(max-width: ${DESKTOP_BREAKPOINT - 1}px)` });
};

export const useIsDesktop = () => {
  return useMediaQuery({ query: `(min-width: ${DESKTOP_BREAKPOINT}px)` });
};
