import { Customer } from '../../types';
import { getScheduledCancellationDate, isPlanAlreadyTerminated } from '../../utils';
import moment from 'moment';

export const CanceledBanner = (props: { customer: Customer }) => {
  const { customer } = props;
  const scheduledCancellationDate = getScheduledCancellationDate(customer.activeSubscription);
  const text = isPlanAlreadyTerminated(customer.activeSubscription)
    ? 'Your plan has been canceled.'
    : scheduledCancellationDate !== null
    ? `Your plan has been canceled. It’s still available until the end of your billing period on ${moment(
        scheduledCancellationDate
      ).format('MMMM D, YYYY')}.`
    : null;

  if (!text) return null;
  return (
    <div className="billing__cancelled-note" data-test="billing__cancelled-note">
      {text}
    </div>
  );
};
