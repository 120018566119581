import { useAsyncDebounce } from 'react-table';
import React, { MouseEvent, useEffect, useRef, useState } from 'react';
import { CloseIcon, SearchIcon } from '../../assets/icons';
import { DivButton } from '../../commons/components/DivButton';
import { TextInputUnderlined } from './TextInputUnderlined';
import { DebounceMsConfig } from '../../config';

export const GlobalFilter = ({
  filterValue,
  setFilterValue,
  alwaysExpanded,
  placeholder,
  dataTest,
}: {
  filterValue: string;
  setFilterValue: (v?: string) => void;
  alwaysExpanded?: boolean;
  placeholder?: string;
  dataTest?: string;
}) => {
  const [value, setValue] = useState(filterValue || '');
  const [editing, setEditing] = useState(false);
  const onChange = useAsyncDebounce((v) => {
    setFilterValue(v || undefined);
  }, DebounceMsConfig.tableGlobalFilterDebounceMs);

  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    setValue(filterValue || '');
  }, [filterValue]);

  useEffect(() => {
    if (!inputRef.current) return;
    if (!editing) return;
    inputRef.current?.focus();
  }, [editing]);

  const onEditClick = (e: MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
    setEditing(true);
  };

  const handleChange = (v: string | null) => {
    setValue(v || '');
    onChange(v);
  };

  return (
    <>
      {alwaysExpanded || !!value || editing ? (
        <TextInputUnderlined
          value={value}
          setValue={handleChange}
          editing={editing}
          setEditing={setEditing}
        >
          {({ onBlur, onFocus, setValue, value }) => (
            <div className="search-input__wrapper">
              <SearchIcon className="search-input__magnify" />
              <input
                className="search-input__input search-input__input--placeholder w-100"
                data-test={dataTest ?? 'programs-table__search'}
                ref={inputRef}
                value={value || ''}
                onBlur={onBlur}
                onFocus={() => onFocus()}
                onChange={(e) => setValue(e.target.value)}
                placeholder={placeholder ?? 'Search'}
                onKeyDown={(e) => {
                  if (e.key === 'Escape') {
                    setValue(null);
                    setEditing(false);
                  }
                }}
              />
              <CloseIcon
                className="search-input__close"
                onClick={() => {
                  setValue(null);
                  setEditing(false);
                }}
              />
            </div>
          )}
        </TextInputUnderlined>
      ) : (
        <>
          <DivButton
            icon={<SearchIcon />}
            onClick={onEditClick}
            text="Search"
            dataTest="programs-table__search-button"
          />
        </>
      )}
    </>
  );
};
