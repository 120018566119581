import moment from 'moment-timezone';
import { adjustToLocal } from '../../../util/date-utils';
import {
  DueDateFormulaAnchor,
  MAX_ABSOLUTE_OFFSET,
  ProgramTaskStatus,
  TaskDueDateFormula,
} from '../../types';
import { isUnscheduled } from '../../unscheduledProgramUtil';

export const adjustEndDate = (programEndDate: Date) =>
  moment(programEndDate).isSame(moment(programEndDate).startOf('day'))
    ? moment(programEndDate).subtract(1, 'day').toDate()
    : programEndDate;

const toLosAngeles = (date: Date) => moment(date).tz('America/Los_Angeles');

export const getProgramStartAnchor = (programStart: Date, wholeDay: boolean) => {
  return wholeDay
    ? moment(programStart).startOf('day').toDate()
    : moment(adjustToLocal(toLosAngeles(programStart).startOf('day').toDate(), wholeDay))
        .startOf('day')
        .toDate();
};

export const getProgramEndAnchor = (programEndDate: Date, wholeDay: boolean) => {
  if (wholeDay) {
    return moment(programEndDate).isSame(moment(programEndDate).startOf('day'))
      ? moment(programEndDate).subtract(1, 'day').toDate()
      : programEndDate;
  } else {
    const losAngelesTimestamp = toLosAngeles(programEndDate);
    const adjustedLosAngelesProgramEnd = losAngelesTimestamp.isSame(
      moment(losAngelesTimestamp).startOf('day')
    )
      ? losAngelesTimestamp.subtract(1, 'day')
      : losAngelesTimestamp;
    return moment(adjustToLocal(adjustedLosAngelesProgramEnd.startOf('day').toDate(), true))
      .startOf('day')
      .toDate();
  }
};

export const recalculateDueDate = (
  programStart: Date,
  programEnd: Date,
  wholeDay: boolean,
  formula: TaskDueDateFormula,
  status: ProgramTaskStatus,
  prevDueDate: Date | null
) => {
  if (isUnscheduled({ startDateTime: programStart })) return null;
  if (status === 'done') return prevDueDate;
  const anchorDate =
    formula.anchor === 'PROGRAM_START_DATE'
      ? getProgramStartAnchor(programStart, wholeDay)
      : getProgramEndAnchor(programEnd, wholeDay);
  return moment(anchorDate).startOf('day').add(formula.offset, 'days').toDate();
};

export const recalculateDueDates = <
  T extends {
    dueDateTime: Date | null;
    dueDateFormula: TaskDueDateFormula | null;
    status: ProgramTaskStatus;
  },
>(
  programStart: Date,
  programEnd: Date,
  wholeDay: boolean,
  tasks: T[],
  onlyChanged: boolean
): T[] | null => {
  if (!tasks.find((t) => !!t.dueDateFormula)) return null;
  return tasks
    .map((t) => ({
      task: t,
      newDueDateTime:
        t.dueDateFormula === null
          ? t.dueDateTime
          : recalculateDueDate(
              programStart,
              programEnd,
              wholeDay,
              t.dueDateFormula,
              t.status,
              t.dueDateTime
            ),
    }))
    .filter((x) => !onlyChanged || x.task.dueDateTime?.valueOf() !== x.newDueDateTime?.valueOf())
    .map((x) => ({
      ...x.task,
      dueDateTime: x.newDueDateTime,
    }));
};

export const recalculateFormula = (
  value: Date | null,
  programStart: Date,
  programEnd: Date,
  wholeDay: boolean,
  currentFormula: TaskDueDateFormula | null,
  status: ProgramTaskStatus
) => {
  if (status === 'done') return currentFormula;
  const valueMoment = moment(value);
  const programStartMoment = moment(getProgramStartAnchor(programStart, wholeDay));
  const programEndMoment = moment(getProgramEndAnchor(programEnd, wholeDay));

  let offset: number = 0;
  let anchor: DueDateFormulaAnchor = 'PROGRAM_END_DATE';
  if (value === null || isUnscheduled({ startDateTime: programStart })) {
    if (currentFormula) {
      anchor = currentFormula.anchor;
      offset = currentFormula.offset;
    } else {
      anchor = 'PROGRAM_END_DATE';
      offset = -1;
    }
  } else if (valueMoment.isSame(programStartMoment, 'day')) {
    anchor = 'PROGRAM_START_DATE';
    offset = 0;
  } else if (valueMoment.isSame(programEndMoment, 'day')) {
    anchor = 'PROGRAM_END_DATE';
    offset = 0;
  } else if (valueMoment.isBetween(programStartMoment, programEndMoment, 'day', '()')) {
    const offsetEnd = programEndMoment.diff(valueMoment, 'days');
    const offsetStart = valueMoment.diff(programStartMoment, 'days');
    if (offsetStart < offsetEnd) {
      anchor = 'PROGRAM_START_DATE';
      offset = offsetStart;
    } else {
      anchor = 'PROGRAM_END_DATE';
      offset = -offsetEnd;
    }
  } else if (valueMoment.isBefore(programStartMoment, 'day')) {
    anchor = 'PROGRAM_START_DATE';
    offset = -programStartMoment.diff(valueMoment, 'days');
  } else if (valueMoment.isAfter(programEndMoment, 'day')) {
    anchor = 'PROGRAM_END_DATE';
    offset = valueMoment.diff(programEndMoment, 'days');
  }
  const newFormula: TaskDueDateFormula | null =
    Math.abs(offset) <= MAX_ABSOLUTE_OFFSET
      ? {
          anchor,
          offset,
          offsetUnit: 'DAY',
        }
      : null;
  return newFormula;
};
