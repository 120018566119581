import { useQuery } from '@tanstack/react-query';
import { AllCustomFieldsCacheKey } from '../../hooks/useQueryCacheManager';
import useApi from '../../api/backendApiContext';
import { ReactQueryConstants } from '../../commons/constants';

export const useFetchedAllCustomFields = () => {
  const { programApi } = useApi();
  return useQuery(AllCustomFieldsCacheKey, async () => programApi.getAllCustomFields(), {
    staleTime: ReactQueryConstants.allCustomFieldsStaleTime,
    refetchOnWindowFocus: false,
  });
};
