import { ProgramRow } from '../../types';
import React, { ChangeEvent, MouseEvent, useEffect, useRef, useState } from 'react';
import clsx from 'clsx';
import { CellProps } from './ProgramCells';
import { getProgramNameWithTasksCount } from '../../util';
import { TriangleDownSmallIcon, TriangleRightSmallIcon } from '../../../assets/icons';
import { PencilButton } from '../../../commons/components/PencilButton';

const NameCell = ({
  onProgramUpdate,
  onRowEditChange,
  value: origValue,
  row,
  readOnly,
}: CellProps<ProgramRow, string>) => {
  const [value, setValue] = useState(origValue);
  const [editing, setEditing] = useState(false);
  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => setValue(origValue), [origValue]);

  useEffect(() => {
    if (!inputRef.current) return;
    inputRef.current?.focus();
  }, [editing]);

  function onEditClick(e: MouseEvent<HTMLButtonElement>) {
    if (row.isGrouped) return;
    e.stopPropagation();
    setEditing(true);
    onRowEditChange(true);
  }

  function handleChange(e: ChangeEvent<HTMLInputElement>) {
    setValue(e.target.value);
  }

  function confirm() {
    if (!value.trim().length) {
      setValue(row.original.name);
    } else if (value !== row.original.name) {
      onProgramUpdate({
        original: row.original,
        updates: [{ field: 'name', value }],
      });
    }
    setEditing(false);
    onRowEditChange(false);
  }

  function cancel() {
    setValue(origValue);
    setEditing(false);
    onRowEditChange(false);
  }

  return (
    <div
      className={clsx('text-capitalize text-break programs-table__name-cell', {
        'programs-table__cell-editing': editing,
        'programs-table__cell-edit-disabled': row.isGrouped || readOnly,
      })}
      data-test="programs-table__name-cell"
      onClick={(e) => {
        if (editing) e.stopPropagation();
      }}
      onKeyDown={(e) => {
        if (editing) e.stopPropagation();
      }}
    >
      {editing ? (
        <input
          className="programs-table__cell-input"
          ref={inputRef}
          value={value}
          readOnly={readOnly}
          onBlur={confirm}
          onChange={handleChange}
          onKeyDown={(e) => {
            if (e.key === 'Escape') cancel();
            if (e.key === 'Enter') confirm();
          }}
        />
      ) : (
        <>
          {!!row.original.resolvedTasks.length && (
            <div
              className="programs-table__editable-cell--squared2"
              data-test="programs-table__name-cell-expander"
              onClick={(e) => {
                row.toggleRowExpanded();
                e.stopPropagation();
              }}
            >
              {row.isExpanded ? <TriangleDownSmallIcon /> : <TriangleRightSmallIcon />}
            </div>
          )}
          <div data-test="programs-table__name-cell-title">
            {getProgramNameWithTasksCount(value, row.original.resolvedTasks)}
          </div>
          {!readOnly && (
            <div className="programs-table__name-cell-edit-container">
              <PencilButton onEdit={onEditClick} dataTest="programs-table__name-cell-edit" />
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default NameCell;
