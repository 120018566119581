import { useMutation } from '@tanstack/react-query';
import {
  ChatPromptAndAnswer,
  TOOL_CALL_USER_RESULT_OK,
  TOOL_CALL_USER_RESULT_REJECT,
  TOOL_CREATE_PROGRAMS,
  ToolCallResult,
} from '../../types';
import useApi from '../../../api/backendApiContext';
import { useQueryCacheManager } from '../../../hooks/useQueryCacheManager';
import useAuth from '../../../auth/authContext';
import { delay } from '../../../commons/utils';
import { ANSWER_MESSAGE_STATUS_ERROR, ANSWER_MESSAGE_STATUS_OK } from '../../growegyAIApi';
import { useCreateProgramsMutation } from '../../../program/hooks/useCreateProgramsMutation';
import { unresolveProgramSummary } from '../../../program/util';
import { NoProgramOwner } from '../../../user/types';

export const useCreateMessageMutation = (onMutate: () => void, onSuccess: () => void) => {
  const { growegyAIApi } = useApi();
  const queryCacheManager = useQueryCacheManager();
  const {
    state: { user },
  } = useAuth();

  const { mutateAsync: createPrograms } = useCreateProgramsMutation();

  return useMutation(
    async (variables: {
      chatId: string;
      promptAndAnswer: ChatPromptAndAnswer;
      systemMessage: string | null;
      answerContent?: string;
    }) => {
      const { chatId, promptAndAnswer, systemMessage, answerContent } = variables;

      const toolCallResults: ToolCallResult[] | null = promptAndAnswer.promptExtensions
        ? await Promise.all(
            promptAndAnswer.promptExtensions
              .filter((x) => x.resolvedToolCall.name === TOOL_CREATE_PROGRAMS)
              .map(async (x): Promise<ToolCallResult> => {
                if (x.userResult === 'REJECT') {
                  return {
                    id: x.resolvedToolCall.id,
                    name: x.resolvedToolCall.name,
                    content: {
                      user_result: TOOL_CALL_USER_RESULT_REJECT,
                      created_programs: [],
                      message: 'The user has rejected to create programs.',
                    },
                  };
                }
                const { createdPrograms } = await createPrograms(
                  x.resolvedToolCall.arguments.programs.map(unresolveProgramSummary)
                );
                return {
                  id: x.resolvedToolCall.id,
                  name: x.resolvedToolCall.name,
                  content: {
                    user_result: TOOL_CALL_USER_RESULT_OK,
                    created_programs: createdPrograms.map((p) => ({
                      id: p.id,
                      name: p.name,
                      program_type_id: p.typeId,
                      all_day: p.wholeDay,
                      start_date_time: p.startDateTime.toISOString(),
                      end_date_time: p.endDateTime.toISOString(),
                      owner_user_id: p.owningUserId ?? NoProgramOwner.id,
                    })),
                  },
                };
              })
          )
        : null;

      if (!answerContent) {
        return growegyAIApi.createMessage(
          user!.userId,
          chatId,
          promptAndAnswer.prompt.clientId,
          promptAndAnswer.prompt.content,
          systemMessage,
          promptAndAnswer.prompt.promptId,
          toolCallResults
        );
      } else {
        await delay(500);
        return Promise.resolve({
          prompt: promptAndAnswer.prompt,
          answer: {
            ...promptAndAnswer.answer,
            content: answerContent,
            date: new Date(),
            isPlaceholder: false,
          },
          answerStatus: ANSWER_MESSAGE_STATUS_OK,
        } as ChatPromptAndAnswer);
      }
    },
    {
      onMutate: (variables) => {
        const { chatId, promptAndAnswer } = variables;
        queryCacheManager.onMessagesAdded(chatId, promptAndAnswer.prompt, promptAndAnswer.answer);
        onMutate();
      },
      onSuccess: (promptAndAnswer: ChatPromptAndAnswer, variables) => {
        const placeholderAnswer = variables.promptAndAnswer.answer;

        queryCacheManager.onMessagesUpdated(
          variables.chatId,
          promptAndAnswer.prompt,
          promptAndAnswer.answer ??
            (placeholderAnswer && promptAndAnswer.answerStatus === ANSWER_MESSAGE_STATUS_ERROR
              ? {
                  ...placeholderAnswer,
                  isPlaceholder: false,
                  content:
                    'Something went wrong while composing an answer. Please try sending the prompt again.',
                }
              : null)
        );
        if (variables.promptAndAnswer.prompt.promptId) {
          queryCacheManager.addRecentlyUsedPrompt(variables.promptAndAnswer.prompt.promptId);
        }

        onSuccess();
      },
      onError: (e: Error, variables) => {
        const { chatId, promptAndAnswer } = variables;
        queryCacheManager.onMessageFailed(
          chatId,
          promptAndAnswer.prompt.clientId,
          promptAndAnswer.answer?.clientId ?? null,
          e?.message ?? null
        );
      },
    }
  );
};
