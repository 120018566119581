/* eslint-disable react-hooks/rules-of-hooks */
import {
  ProgramPartialUpdateData,
  ProgramPartialUpdateField,
  ProgramRow,
  ResolvedProgramEndDateField,
  ResolvedProgramStartDateField,
} from '../../types';
import React, { MouseEvent, useEffect, useRef, useState } from 'react';
import { CellProps } from './ProgramCells';
import moment from 'moment';
import DatePicker from 'react-datepicker';
import {
  displayEndDateToInternal,
  formatDateTimeCell,
  internalEndDateToDisplay,
} from '../../../util/date-utils';
import { GrowegySelectMockup } from '../GrowegySelectMockup';
import { recalculateDueDates } from '../tasks/smartDueDateUtils';

const DateTimeCell =
  (dataTest: string, field: ProgramPartialUpdateField) =>
  ({
    onProgramUpdate,
    onRowEditChange,
    value: origValue,
    row,
    readOnly,
  }: CellProps<ProgramRow, Date>) => {
    const [value, setValue] = useState<Date | null>(origValue);
    const [editing, setEditing] = useState(false);
    const inputRef = useRef<DatePicker>(null);

    useEffect(() => {
      setValue(origValue);
    }, [origValue]);

    useEffect(() => {
      if (!inputRef.current) return;
      inputRef.current?.setFocus();
    }, [editing]);

    const handleEditClick = (e: MouseEvent<HTMLDivElement>) => {
      if (row.isGrouped) return;
      e.stopPropagation();
      if (readOnly) return;
      if (editing) return;
      setEditing(true);
      onRowEditChange(true);
    };

    const confirm = (value: Date | null) => {
      if (field.type === 'ArrayFieldItem' && field.arrayField === 'customFields') {
        onProgramUpdate({
          original: row.original,
          updates: [
            {
              field: 'customFields',
              customFieldName: field.arrayItemValue.name,
              customFieldData: {
                type: 'date-time',
                value: value,
              },
            },
          ],
        });
      } else if (
        field.type === 'StaticField' &&
        (field.fieldName === ResolvedProgramStartDateField ||
          field.fieldName === ResolvedProgramEndDateField)
      ) {
        if (!value) return;

        const originalStart = row.original.startDateTime;
        const originalEnd = row.original.endDateTime;
        const wholeDay = row.original.wholeDay;
        const adjustedValue =
          field.fieldName === ResolvedProgramEndDateField
            ? displayEndDateToInternal(value, wholeDay)
            : value;
        const updatedStart =
          field.fieldName === ResolvedProgramStartDateField
            ? adjustedValue
            : adjustedValue.valueOf() <= originalStart.valueOf()
            ? moment(adjustedValue)
                .subtract(1, wholeDay ? 'day' : 'hour')
                .toDate()
            : originalStart;
        const updatedEnd =
          field.fieldName === ResolvedProgramEndDateField
            ? adjustedValue
            : adjustedValue.valueOf() >= originalEnd.valueOf()
            ? moment(adjustedValue)
                .add(1, wholeDay ? 'day' : 'hour')
                .toDate()
            : originalEnd;

        const updates: ProgramPartialUpdateData[] = [];
        if (originalStart.valueOf() !== updatedStart.valueOf()) {
          updates.push({ field: 'startDateTime', value: updatedStart });
        }
        if (originalEnd.valueOf() !== updatedEnd.valueOf()) {
          updates.push({ field: 'endDateTime', value: updatedEnd });
        }
        const updatedTasks = recalculateDueDates(
          updatedStart,
          updatedEnd,
          wholeDay,
          row.original.resolvedTasks,
          true
        );
        const tasksAfterChange = recalculateDueDates(
          updatedStart,
          updatedEnd,
          wholeDay,
          row.original.resolvedTasks,
          false
        );
        if (updatedTasks && tasksAfterChange && updatedTasks.length > 0) {
          updates.push({
            field: 'resolvedTasks',
            value: {
              changes: updatedTasks.map((x) => ({ changeType: 'UPDATE', task: x })),
              tasksAfterChange,
            },
          });
        }

        if (updates.length > 0) {
          onProgramUpdate({ original: row.original, updates });
        }
      }
      setEditing(false);
      onRowEditChange(false);
    };

    const cancel = () => {
      setValue(origValue);
      setEditing(false);
      onRowEditChange(false);
    };

    const displayValue =
      value && field.type === 'StaticField' && field.fieldName === ResolvedProgramEndDateField
        ? internalEndDateToDisplay(value, row.original.wholeDay)
        : value;

    const showTime = field.type !== 'StaticField' || !row.original?.wholeDay;
    return (
      <div
        data-test={dataTest}
        onClick={handleEditClick}
        onKeyDown={(e) => {
          if (editing) e.stopPropagation();
        }}
      >
        {editing ? (
          <DatePicker
            showTimeSelect={showTime}
            ref={inputRef}
            onBlur={cancel}
            onClickOutside={cancel}
            onCalendarClose={() => confirm(value)}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                confirm(value);
              }
              if (e.key === 'Escape') {
                cancel();
              }
            }}
            timeIntervals={15}
            dateFormat={showTime ? 'MM/dd/yyyy, hh:mm a' : 'MM/dd/yyyy'}
            selected={displayValue}
            onSelect={(d) => confirm(d)}
            onChange={(d: Date | null) => setValue(d)}
            className="programs-table__cell-input"
            readOnly={readOnly}
            isClearable={false}
          />
        ) : (
          <GrowegySelectMockup readOnly={readOnly}>
            {formatDateTimeCell(displayValue, showTime ? 'MMM DD, hh:mm a' : 'MMM DD')}
          </GrowegySelectMockup>
        )}
      </div>
    );
  };

export default DateTimeCell;
