import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { SideBar } from './navigation/SideBar';
import useAuth, { AuthProvider } from './auth/authContext';
import { ApiProvider } from './api/backendApiContext';
import AppRouter from './router/AppRouter';
import { reactQueryConfig } from './config';
import { NotificationProvider } from './notification/notificationsContext';
import { WebAnalyticsProvider } from './web-analytics/webAnalyticsContext';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { googleConfig, msalConfig, outlookConfig } from './commons/config';
import { BrowserRouter } from 'react-router-dom';
import { onQueryRemoved } from './hooks/useQueryCacheManager';
import { ChatContextsProvider } from './growegy-ai/chat/chatContext';
import { PublicClientApplication } from '@azure/msal-browser';
import React, { PropsWithChildren } from 'react';
import { MsalProvider } from '@azure/msal-react';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: reactQueryConfig,
  },
});

queryClient.getQueryCache().subscribe((event) => {
  if (event.type === 'removed') onQueryRemoved(event.query);
});

const GoogleOAuthProviderIfEnabled = (props: PropsWithChildren) =>
  googleConfig.enabled ? (
    <GoogleOAuthProvider clientId={googleConfig.clientId}>{props.children}</GoogleOAuthProvider>
  ) : (
    <>{props.children}</>
  );

const msalInstance = new PublicClientApplication(msalConfig);
const MsalProviderIfEnabled = (props: PropsWithChildren) =>
  outlookConfig.enabled ? (
    <MsalProvider instance={msalInstance}>{props.children}</MsalProvider>
  ) : (
    <>{props.children}</>
  );

const ChatContextProviderIfLoggedIn = (props: PropsWithChildren) => {
  const {
    state: { user },
  } = useAuth();
  return !!user ? (
    <ChatContextsProvider>{props.children}</ChatContextsProvider>
  ) : (
    <>{props.children}</>
  );
};

const App = () => (
  <div className="app">
    <QueryClientProvider client={queryClient}>
      <NotificationProvider>
        <BrowserRouter>
          <WebAnalyticsProvider>
            <AuthProvider>
              <ApiProvider>
                <ChatContextProviderIfLoggedIn>
                  <GoogleOAuthProviderIfEnabled>
                    <MsalProviderIfEnabled>
                      <SideBar />
                      <AppRouter />
                    </MsalProviderIfEnabled>
                  </GoogleOAuthProviderIfEnabled>
                </ChatContextProviderIfLoggedIn>
              </ApiProvider>
            </AuthProvider>
          </WebAnalyticsProvider>
        </BrowserRouter>
        <ReactQueryDevtools initialIsOpen={false} />
      </NotificationProvider>
    </QueryClientProvider>
  </div>
);

export default App;
