import React from 'react';
import {
  AuthenticationDetails,
  CognitoRefreshToken,
  CognitoUser,
  CognitoUserSession,
  ISignUpResult,
} from 'amazon-cognito-identity-js';
import CognitoPool from './cognitoPool';
import { AuthAction } from './authStateTypes';
import { createUser, IUserTokens } from '../currentuser/currentUser';
import { errorToString } from '../util/utils';
import { Analytics } from '@segment/analytics-next';
import { analyticsTrack } from '../web-analytics/webAnalytics';
import { AnalyticsEvent } from '../web-analytics/AnalyticsEvent';

export type SignInResult =
  | { type: 'CONFIRM_CODE' }
  | { type: 'OK'; tokens: IUserTokens }
  | { type: 'CHANGE_PASSWORD'; cognitoUser: CognitoUser };

export type RefreshTokenApi = (email: string, refreshToken: string) => Promise<string>;

const getUser = (email: string) =>
  new CognitoUser({
    Username: email.toLowerCase(),
    Pool: CognitoPool,
  });

export const signUp = async (email: string, password: string): Promise<'OK' | 'CONFIRM_CODE'> =>
  new Promise((resolve, reject) => {
    CognitoPool.signUp(
      email,
      password,
      [],
      [],
      (err: Error | undefined, data: ISignUpResult | undefined) => {
        if (err) {
          logger.error('Cognito sign up failed: ', err);
          reject(err.message);
        } else if (data?.userConfirmed === false) {
          resolve('CONFIRM_CODE');
        } else {
          resolve('OK');
        }
      }
    );
  });

export const confirmCode = async (
  email: string,
  code: string,
  analytics: Analytics | undefined
): Promise<void> =>
  new Promise<void>((resolve, reject) => {
    getUser(email).confirmRegistration(code, false, (err) => {
      if (err) {
        logger.error('confirm code failed: ', err);
        const errMsg = err.message
          ? `${err.message}`
          : `code confirmation failed, please try again`;
        reject(errMsg);
        analyticsTrack(analytics, AnalyticsEvent.USER_SIGN_UP_EMAIL_VERIFICATION_FAILED);
      } else {
        resolve();
        analyticsTrack(analytics, AnalyticsEvent.USER_SIGN_UP_EMAIL_VERIFIED);
      }
    });
  });

export const resendCode = async (email: string): Promise<void> =>
  new Promise((resolve, reject) => {
    getUser(email).resendConfirmationCode((err) => {
      if (err) {
        logger.error('resend code failed: ', err);
        reject(err.message);
      } else {
        resolve();
      }
    });
  });

export const signIn = async (email: string, password: string): Promise<SignInResult> =>
  new Promise((resolve, reject) => {
    const authDetails = new AuthenticationDetails({
      Username: email,
      Password: password,
    });
    const user: CognitoUser = getUser(email);
    user.authenticateUser(authDetails, {
      onSuccess: (session: CognitoUserSession) => {
        const tokens = {
          id: session.getIdToken().getJwtToken(),
          access: session.getAccessToken().getJwtToken(),
          refresh: session.getRefreshToken().getToken(),
        };
        resolve({ type: 'OK', tokens });
      },

      onFailure: (err) => {
        logger.error('authentication failed: ', err);

        if (err.code === 'UserNotConfirmedException') {
          resolve({ type: 'CONFIRM_CODE' });
        } else {
          const errorMsg = err.message
            ? `${err.message}`
            : `authentication failed, please try again`;
          reject(errorMsg);
        }
      },

      newPasswordRequired: (userAttributes, requiredAttributes) => {
        logger.info(
          'authentication succeeded but new password required:',
          userAttributes,
          requiredAttributes
        );
        resolve({ type: 'CHANGE_PASSWORD', cognitoUser: user });
      },
    });
  });

export const newPassword = async (
  password: string,
  cognitoUser: CognitoUser
): Promise<IUserTokens> =>
  new Promise((resolve, reject) => {
    cognitoUser.completeNewPasswordChallenge(password, [], {
      onFailure: (err) => {
        logger.error('new password update failed: ', err);

        const error = err.message ? `${err.message}` : `authentication failed, please try again`;
        reject(error);
      },

      onSuccess: (session: CognitoUserSession) => {
        resolve({
          id: session.getIdToken().getJwtToken(),
          access: session.getAccessToken().getJwtToken(),
          refresh: session.getRefreshToken().getToken(),
        });
      },
    });
  });

export const forgotPassword = async (email: string): Promise<void> =>
  new Promise((resolve, reject) => {
    getUser(email).forgotPassword({
      onSuccess: (data) => {
        const errMsg = `Forgot password succeeded. Unexpected AWS cognito behavior. Must not come here: ${JSON.stringify(
          data
        )}`;
        logger.error(errMsg);
        reject(errMsg);
      },
      onFailure: (err) => {
        let errorMsg = '';
        try {
          errorMsg = JSON.stringify(err);
        } catch {}
        logger.error(`forgot password failed for email ${email} `, err);
        logger.pushEvent('forgot password failed: ', { email, error: errorMsg });
        reject(err.message);
      },
      inputVerificationCode: () => {
        resolve();
      },
    });
  });

export const resetPassword = async (
  email: string,
  code: string,
  password: string
): Promise<SignInResult> => {
  await new Promise<void>((resolve, reject) => {
    getUser(email).confirmPassword(code, password, {
      onSuccess: () => {
        resolve();
      },
      onFailure: (error) => {
        logger.error('Reset password failed: ', error);
        reject(error.message);
      },
    });
  });
  return signIn(email, password);
};

function getRefreshTokenApi(dispatch: React.Dispatch<AuthAction>): RefreshTokenApi {
  const signOut = () => {
    dispatch({ type: 'SIGNOUT' });
  };

  const notifyAuthContext = (session: CognitoUserSession) => {
    const tokens = {
      id: session.getIdToken().getJwtToken(),
      access: session.getAccessToken().getJwtToken(),
      refresh: session.getRefreshToken().getToken(),
    };
    const authUser = createUser(tokens);

    if (authUser) {
      dispatch({
        type: 'TOKEN_REFRESHED',
        tokens,
      });
    } else {
      signOut();
    }
  };

  const initiateRefreshing = async (email: string, refreshToken: string) =>
    new Promise<string>((resolve, reject) => {
      getUser(email).refreshSession(
        new CognitoRefreshToken({ RefreshToken: refreshToken }),
        (err, session: CognitoUserSession) => {
          if (err) {
            logger.error(`AuthApi. Refresh token failed: ${errorToString(err)}`);
            signOut();
            reject(err);
          } else {
            logger.info(
              `AuthApi. Refresh token succeeded. Expiration ${session
                .getAccessToken()
                .getExpiration()} `
            );
            notifyAuthContext(session);
            resolve(session.getAccessToken().getJwtToken());
          }
        }
      );
    });

  let refreshingPromise: Promise<string> | null;

  return async (email: string, refreshToken: string): Promise<string> => {
    if (!refreshingPromise) {
      refreshingPromise = initiateRefreshing(email, refreshToken);
      refreshingPromise
        .then(() => {
          setTimeout(() => {
            refreshingPromise = null;
          }, 5000);
        })
        .catch(() => {
          refreshingPromise = null;
        });
    }
    return refreshingPromise;
  };
}

export default getRefreshTokenApi;
