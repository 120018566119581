export const ExecuteToolsMessageInput = (props: {
  onModify: () => void;
  onProceed: () => void;
}) => {
  const { onModify, onProceed } = props;
  return (
    <div className="execute-tools-message-input__container">
      <button
        className="execute-tools-message-input__button"
        data-test="execute-tools-message-input__modify"
        onClick={onModify}
      >
        Modify
      </button>
      <button
        className="execute-tools-message-input__button"
        data-test="execute-tools-message-input__proceed"
        onClick={onProceed}
      >
        Proceed
      </button>
    </div>
  );
};
