import {
  NoCampaign,
  ProgramType,
  ResolvedCampaign,
  ResolvedProgramSummary,
  ResolvedProgramTask,
} from '../../types';
import { hexAToRGB, hexToRGB } from '../../util';
import { CalendarEventInput, CalendarEventType, CalendarViewType } from '../../containers/types';
import moment from 'moment';
import _ from 'lodash';
import { endDateToQuarterDate, startDateToQuarterDate } from './quarterTimeTransformations';
import { StyleVariables } from '../../../commons/styleConstants';
import {
  isScheduled,
  isUnscheduled,
  unscheduledEnd,
  unscheduledStart,
} from '../../unscheduledProgramUtil';
import { ProgramPermissionHelper, getProgramPermissionHelper } from '../../programPermissionHelper';
import { ICurrentUser } from '../../../currentuser/currentUser';

export const programToEvent = (
  program: ResolvedProgramSummary,
  programPermissionHelper: ProgramPermissionHelper
): CalendarEventInput => ({
  id: program.id,
  title: program.name,
  start: program.startDateTime,
  end: program.endDateTime,
  allDay: program.wholeDay,
  classNames: ['program-calendar__common-event', 'program-calendar__program-event'],
  backgroundColor: hexAToRGB(program.type.color, 0.12),
  borderColor: hexToRGB(program.type.color, null),
  textColor: hexToRGB(program.type.color, null),
  display: 'block',
  editable: programPermissionHelper.canEditRegularField(),
  calendarEventType: CalendarEventType.program,
  origResolvedSummary: program,
});

export const campaignToEvent = (
  program: ResolvedProgramSummary,
  expanded: boolean
): CalendarEventInput => {
  const color = program.campaignColor ?? StyleVariables.black2;
  return {
    id: program.id,
    title: program.name,
    start: program.startRangeDateTime,
    end: program.endRangeDateTime,
    allDay: program.wholeDay,
    classNames: [
      'program-calendar__common-event',
      'program-calendar__common-event--program',
      'program-calendar__campaign-event',
    ],
    backgroundColor: hexToRGB('#ffffff', 0.1),
    borderColor: hexToRGB(color, null),
    textColor: hexToRGB(color, null),
    display: 'block',
    editable: false,
    calendarEventType: CalendarEventType.campaign,
    origResolvedSummary: program,
    expanded,
  };
};

const typeToMarkerEvent = (type: ProgramType, quarterFirstDate: Date): CalendarEventInput => ({
  title: 'Program Type Separator',
  start: new Date(1970, 0, 1),
  end: moment(quarterFirstDate).add(50, 'years').toDate(),
  allDay: true,
  display: 'block',
  editable: false,
  calendarEventType: CalendarEventType.typeMarker,
  origProgramType: type,
  className: 'calendar__program-type-marker',
});

export const campaignToMarkerEvent = (
  campaign: ResolvedCampaign,
  quarterFirstDate: Date,
  isExpanded: boolean
): CalendarEventInput => ({
  title: 'Program Type Separator',
  start: new Date(1970, 0, 1),
  end: moment(quarterFirstDate).add(50, 'years').toDate(),
  allDay: true,
  display: 'block',
  calendarEventType: CalendarEventType.campaignMarker,
  origCampaign: campaign,
  className: isExpanded
    ? 'calendar__campaign-marker--expanded'
    : 'calendar__campaign-marker--collapsed',
});

const programToQuarterEvent = (
  program: ResolvedProgramSummary,
  quarterFirstDate: Date,
  programPermissionHelper: ProgramPermissionHelper
): CalendarEventInput => {
  const start = startDateToQuarterDate(quarterFirstDate, program.startDateTime);
  const end = endDateToQuarterDate(quarterFirstDate, program.endDateTime);

  return {
    ...programToEvent(program, programPermissionHelper),
    start,
    end,
    allDay: true,
  };
};

const taskToQuarterEvent = (
  task: ResolvedProgramTask,
  program: ResolvedProgramSummary,
  quarterFirstDate: Date,
  programPermissionHelper: ProgramPermissionHelper
): CalendarEventInput => {
  const date = task.dueDateTime
    ? startDateToQuarterDate(quarterFirstDate, task.dueDateTime)
    : undefined;

  return {
    ...taskToEvent(task, program, programPermissionHelper),
    start: date,
    end: date,
    allDay: true,
  };
};

const campaignToQuarterEvent = (
  program: ResolvedProgramSummary,
  expanded: boolean,
  quarterFirstDate: Date
): CalendarEventInput => {
  const start = startDateToQuarterDate(quarterFirstDate, program.startRangeDateTime);
  const end = endDateToQuarterDate(quarterFirstDate, program.endRangeDateTime);

  return {
    ...campaignToEvent(program, expanded),
    start,
    end,
    allDay: true,
  };
};

const taskToEvent = (
  task: ResolvedProgramTask,
  program: ResolvedProgramSummary,
  programPermissionHelper: ProgramPermissionHelper
): CalendarEventInput => ({
  id: task.id ?? undefined,
  title: task.name,
  start: task.dueDateTime ?? undefined,
  end: task.dueDateTime ?? undefined,
  allDay: true,
  classNames: ['program-calendar__common-event', 'program-calendar__task-event'],
  backgroundColor: hexToRGB('#ffffff', 0.1),
  borderColor: hexToRGB(program.type.color, task.status !== 'done' ? null : 0.3),
  textColor: hexToRGB(program.type.color, task.status !== 'done' ? null : 0.6),
  display: 'block',
  editable: programPermissionHelper.canEditRegularTaskField(task),
  calendarEventType: CalendarEventType.task,
  origResolvedSummary: program,
  origTask: task,
});

const programToEvents = (
  program: ResolvedProgramSummary,
  includeTasks: boolean,
  isQuarterView: boolean,
  rangeFirstDate: Date,
  user: ICurrentUser
): CalendarEventInput[] => {
  const programPermissionHelper = getProgramPermissionHelper(user, program);
  let programEvents: CalendarEventInput[] = [];
  if (isScheduled(program)) {
    programEvents = [
      isQuarterView
        ? programToQuarterEvent(program, rangeFirstDate, programPermissionHelper)
        : programToEvent(program, programPermissionHelper),
    ];
  }
  let taskEvents: CalendarEventInput[] = [];
  if (includeTasks) {
    taskEvents = program.resolvedTasks
      .filter((task) => !!task.dueDateTime)
      .map((task) =>
        isQuarterView
          ? taskToQuarterEvent(task, program, rangeFirstDate, programPermissionHelper)
          : taskToEvent(task, program, programPermissionHelper)
      );
  }

  return programEvents.concat(taskEvents);
};

const campaignToEvents = (
  program: ResolvedProgramSummary,
  expanded: boolean,
  viewType: CalendarViewType,
  rangeFirstDate: Date
): CalendarEventInput[] => {
  if (viewType === 'monthGridQuarter') {
    return [campaignToQuarterEvent(program, expanded, rangeFirstDate)];
  }
  return [campaignToEvent(program, expanded)];
};

const intervalsIntersect = (
  firstIntervalStart: Date,
  firstIntervalEnd: Date,
  secondIntervalStart: Date,
  secondIntervalEnd: Date
) => firstIntervalStart < secondIntervalEnd && firstIntervalEnd > secondIntervalStart;

export const programsToEvents = (
  programs: ResolvedProgramSummary[],
  campaigns: ResolvedCampaign[],
  expandedCampaigns: Set<string>,
  programTypes: ProgramType[],
  includeTasks: boolean,
  viewType: CalendarViewType,
  rangeFirstDate: Date,
  isCampaignGroupingEnabled: boolean,
  user: ICurrentUser
): CalendarEventInput[] =>
  isCampaignGroupingEnabled
    ? eventsGroupedByCampaigns(
        programs,
        campaigns,
        expandedCampaigns,
        includeTasks,
        viewType,
        rangeFirstDate,
        user
      )
    : eventsNonGroupedByCampaigns(programs, includeTasks, viewType, rangeFirstDate, user);

const eventsNonGroupedByCampaigns = (
  programs: ResolvedProgramSummary[],
  includeTasks: boolean,
  viewType: CalendarViewType,
  rangeFirstDate: Date,
  user: ICurrentUser
): CalendarEventInput[] => {
  const isQuarterView = viewType === 'monthGridQuarter';
  const events = programs.flatMap((program) =>
    programToEvents(program, includeTasks, isQuarterView, rangeFirstDate, user)
  );
  if (isQuarterView) {
    const exclusiveQuarterLastDate = moment(rangeFirstDate).add(3, 'months').toDate();
    const typeMarkers = _(programs)
      .filter((p) =>
        intervalsIntersect(p.startDateTime, p.endDateTime, rangeFirstDate, exclusiveQuarterLastDate)
      )
      .groupBy((p) => p.type.id)
      .map((ps) => ps[0].type)
      .map((t) => typeToMarkerEvent(t, rangeFirstDate))
      .value();
    return [...events, ...typeMarkers];
  }
  return events;
};

const noCampaign: () => ResolvedCampaign = () => ({
  ...NoCampaign,
  startDateTime: unscheduledStart(NoCampaign.id),
  endDateTime: unscheduledEnd(NoCampaign.id),
  startRangeDateTime: unscheduledStart(NoCampaign.id),
  endRangeDateTime: unscheduledEnd(NoCampaign.id),
  name: 'Programs without Campaigns',
  campaignColor: '70797b',
});

const extendRangeDateTime = (originalDate: Date, dateToCompare: Date, isStart: boolean) => {
  if (isUnscheduled({ startDateTime: dateToCompare })) {
    return originalDate;
  }
  if (isUnscheduled({ startDateTime: originalDate })) {
    return dateToCompare;
  }
  if (isStart) {
    return originalDate.valueOf() < dateToCompare.valueOf() ? originalDate : dateToCompare;
  } else {
    return originalDate.valueOf() > dateToCompare.valueOf() ? originalDate : dateToCompare;
  }
};

const eventsGroupedByCampaigns = (
  origPrograms: ResolvedProgramSummary[],
  campaigns: ResolvedCampaign[],
  expandedCampaigns: Set<string>,
  includeTasks: boolean,
  viewType: CalendarViewType,
  rangeFirstDate: Date,
  user: ICurrentUser
): CalendarEventInput[] => {
  const scheduledCampaigns: [string, ResolvedCampaign][] = campaigns
    .filter((c) => isScheduled(c))
    .map((c) => [c.id, _.cloneDeep(c)]);
  const campaignsFromPrograms: [string, ResolvedCampaign][] = origPrograms
    .map((p) => p.campaign ?? noCampaign())
    .map((c) => [c.id, _.cloneDeep(c)]);

  const campaignMap = new Map<string, ResolvedCampaign>([
    ...scheduledCampaigns,
    ...campaignsFromPrograms,
  ]);

  origPrograms.forEach((p) => {
    const campaignId = p.campaign?.id ?? NoCampaign.id;
    const campaign = campaignMap.get(campaignId)!;

    campaign.startRangeDateTime = extendRangeDateTime(
      campaign.startRangeDateTime,
      p.startRangeDateTime,
      true
    );
    campaign.startRangeDateTime = extendRangeDateTime(
      campaign.startRangeDateTime,
      p.startDateTime,
      true
    );
    campaign.endRangeDateTime = extendRangeDateTime(
      campaign.endRangeDateTime,
      p.endRangeDateTime,
      false
    );
    campaign.endRangeDateTime = extendRangeDateTime(
      campaign.endRangeDateTime,
      p.endDateTime,
      false
    );

    p.resolvedTasks.forEach(({ dueDateTime }) => {
      if (dueDateTime === null) return;
      const taskStart = dueDateTime;
      const taskEnd = moment(dueDateTime).add(1, 'day').toDate();
      campaign.startRangeDateTime = extendRangeDateTime(
        campaign.startRangeDateTime,
        taskStart,
        true
      );
      campaign.endRangeDateTime = extendRangeDateTime(campaign.endRangeDateTime, taskEnd, false);
    });
  });
  const programs = origPrograms.map((p) => {
    const campaign = campaignMap.get(p.campaign?.id ?? NoCampaign.id)!;
    return { ...p, campaign };
  });

  const isQuarterView = viewType === 'monthGridQuarter';
  const eventsFromProgramsAndTasks = programs
    .filter(({ campaign }) => expandedCampaigns.has(campaign.id))
    .flatMap((program) =>
      programToEvents(program, includeTasks, isQuarterView, rangeFirstDate, user)
    );
  const campaignsArray = Array.from(campaignMap.values()).sort((a, b) => a.id.localeCompare(b.id));
  const eventsFromCampaigns = campaignsArray.flatMap((campaign) =>
    campaignToEvents(campaign, expandedCampaigns.has(campaign.id), viewType, rangeFirstDate)
  );

  if (isQuarterView) {
    const exclusiveQuarterLastDate = moment(rangeFirstDate).add(3, 'months').toDate();
    const campaignMarkers = campaignsArray
      .filter((p) =>
        intervalsIntersect(
          p.startRangeDateTime,
          p.endRangeDateTime,
          rangeFirstDate,
          exclusiveQuarterLastDate
        )
      )
      .map((c) => campaignToMarkerEvent(c, rangeFirstDate, expandedCampaigns.has(c.id)));
    return [...eventsFromProgramsAndTasks, ...eventsFromCampaigns, ...campaignMarkers];
  }
  return [...eventsFromProgramsAndTasks, ...eventsFromCampaigns];
};

export const unscheduledProgramsGroupedByCampaigns = (
  origPrograms: ResolvedProgramSummary[],
  campaigns: ResolvedCampaign[],
  expandedCampaigns: Set<string>
): {
  campaign: ResolvedCampaign;
  programs: ResolvedProgramSummary[];
}[] => {
  const unscheduledCampaigns: [string, ResolvedCampaign][] = campaigns
    .filter((c) => isUnscheduled(c))
    .map((c) => [c.id, _.cloneDeep(c)]);

  const campaignsFromPrograms: [string, ResolvedCampaign][] = origPrograms.map((p) => {
    const c = p.campaign ?? noCampaign();
    return [c.id, _.cloneDeep(c)];
  });

  const campaignMap = new Map<string, ResolvedCampaign>([
    ...unscheduledCampaigns,
    ...campaignsFromPrograms,
  ]);

  const expandedPrograms = _(origPrograms)
    .map((p) => {
      const campaign = campaignMap.get(p.campaign?.id ?? NoCampaign.id)!;
      return { ...p, campaign };
    })
    .filter(({ campaign }) => expandedCampaigns.has(campaign.id))
    .groupBy((p) => p.campaign.id)
    .value();

  return Array.from(campaignMap.values())
    .sort((a, b) => a.name.localeCompare(b.name))
    .map((campaign) => {
      const programs: ResolvedProgramSummary[] = expandedPrograms[campaign.id] ?? [];
      return {
        campaign,
        programs,
      };
    });
};
