import React, { useCallback } from 'react';
import { AttachmentMessage } from './AttachmentMessage';
import { useQueryCacheManager } from '../../../hooks/useQueryCacheManager';

export const FailedToDownloadAttachmentMessage = (props: {
  programId: string;
  attachmentId: string;
  fileName: string;
}) => {
  const { programId, attachmentId, fileName } = props;
  const queryCacheManager = useQueryCacheManager();
  const invalidateCache = useCallback(() => {
    queryCacheManager.initAttachmentContentRefetch(programId, attachmentId);
  }, [queryCacheManager, programId, attachmentId]);

  return (
    <AttachmentMessage
      fileName={fileName}
      buttonText="Try again"
      message="Attachment could not be downloaded"
      onButtonClick={invalidateCache}
      dataTestPrefix="attachment-view__failed-to-download"
      isError={true}
    />
  );
};
