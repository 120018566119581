import { useQueries } from '@tanstack/react-query';
import moment from 'moment';
import useApi from '../../api/backendApiContext';
import {
  OneMonthSummaries,
  ProgramSummaries,
  ProgramSummariesFetchError,
  ProgramSummary,
  QueryData,
} from '../types';
import { programSummariesLimit } from '../../config';
import { errorToString } from '../../util/utils';
import { useQueryCacheManager } from '../../hooks/useQueryCacheManager';
import { ReactQueryConstants } from '../../commons/constants';

const loadTillEnd = async (
  startMonth: Date,
  getProgramSummaries: (
    from: Date,
    to: Date,
    limit: number,
    lastEvaluatedKey: string | null
  ) => Promise<ProgramSummaries>
): Promise<OneMonthSummaries> => {
  const endDate = moment(startMonth).add(1, 'month').toDate();
  const result: ProgramSummary[] = [];

  async function loadNext(lastEvaluatedKey: string | null) {
    const response = await getProgramSummaries(
      startMonth,
      endDate,
      programSummariesLimit,
      lastEvaluatedKey
    );
    result.push(...response.summaries);
    if (response.lastEvaluatedKey) {
      await loadNext(response.lastEvaluatedKey);
    }
  }

  await loadNext(null);
  return {
    summaries: result.sort((a, b) => a.startDateTime.valueOf() - b.startDateTime.valueOf()),
    startMonth,
  };
};

const toProgramSummariesResult = (
  data: unknown,
  isError: boolean,
  error: unknown,
  status: 'error' | 'loading' | 'success',
  fetchStatus: 'fetching' | 'paused' | 'idle',
  dataUpdatedAt: number,
  errorUpdatedAt: number,
  isStale: boolean
): QueryData<OneMonthSummaries | null> => {
  if (!data || isError) {
    return {
      data: null,
      error,
      status,
      fetchStatus,
      dataUpdatedAt,
      errorUpdatedAt,
      isStale,
    };
  }

  return {
    data: data as OneMonthSummaries,
    error,
    status,
    fetchStatus,
    dataUpdatedAt,
    errorUpdatedAt,
    isStale,
  };
};

export const useFetchedProgramSummaries = (props: {
  monthsStart?: Date[];
}): QueryData<OneMonthSummaries | null>[] => {
  const { monthsStart } = props;
  const { programApi } = useApi();
  const programCache = useQueryCacheManager();

  const results = useQueries({
    queries: (monthsStart ?? []).map((startDate) => {
      const fetch: () => Promise<OneMonthSummaries> = async () => {
        try {
          return await loadTillEnd(startDate, programApi.getProgramSummaries);
        } catch (err) {
          throw new ProgramSummariesFetchError(startDate, errorToString(err));
        }
      };

      return {
        queryKey: programCache.getMonthly(startDate),
        queryFn: async () => fetch(),
        refetchOnWindowFocus: false,
        staleTime: ReactQueryConstants.programSummariesStaleTime,
        refetchInterval: ReactQueryConstants.programSummariesRefetchInterval,
      };
    }),
  });

  return results.map(
    ({ data, isError, error, status, fetchStatus, dataUpdatedAt, errorUpdatedAt, isStale }) =>
      toProgramSummariesResult(
        data,
        isError,
        error,
        status,
        fetchStatus,
        dataUpdatedAt,
        errorUpdatedAt,
        isStale
      )
  );
};
