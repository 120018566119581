import _ from 'lodash';
import { ProgramAttachment } from '../../types';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { AttachmentCarouselHeader } from './AttachmentCarouselHeader';
import { AttachmentCarouselBody, IndexedProgramAttachment } from './AttachmentCarouselBody';
import useAnalyticsPage from '../../../web-analytics/hooks/useAnalyticsPage';
import { Page } from '../../../web-analytics/Page';

const adjustIndex = (index: number, count: number) => Math.min(Math.max(index, 0), count - 1);

export const AttachmentCarousel = (props: {
  programId: string;
  attachments: ProgramAttachment[];
  selectedIndex: number;
  onDelete: (attachment: ProgramAttachment) => void;
  onClose: () => void;
}) => {
  const { programId, attachments, selectedIndex, onDelete, onClose } = props;

  useAnalyticsPage(Page.ATTACHMENT_VIEW);
  const [currentAttachment, setCurrentAttachment] = useState<IndexedProgramAttachment>({
    attachment: attachments[selectedIndex],
    index: selectedIndex,
  });
  useEffect(() => {
    const newIndex = adjustIndex(currentAttachment.index, attachments.length);
    const newCurrentAttachment = {
      attachment: attachments[newIndex],
      index: newIndex,
    };
    if (_.isEqual(newCurrentAttachment, currentAttachment)) return;
    setCurrentAttachment(newCurrentAttachment);
  }, [attachments, currentAttachment]);

  const containerRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    if (!containerRef.current) return;
    containerRef.current.focus();
  }, []);

  const onPrevClick = useCallback(() => {
    const newIndex = adjustIndex(currentAttachment.index - 1, attachments.length);
    if (newIndex === currentAttachment.index) return;
    if (newIndex === 0) containerRef.current?.focus();
    setCurrentAttachment({ attachment: attachments[newIndex], index: newIndex });
  }, [attachments, currentAttachment, setCurrentAttachment]);

  const onNextClick = useCallback(() => {
    const newIndex = adjustIndex(currentAttachment.index + 1, attachments.length);
    if (newIndex === currentAttachment.index) return;
    if (newIndex === attachments.length - 1) containerRef.current?.focus();
    setCurrentAttachment({ attachment: attachments[newIndex], index: newIndex });
  }, [attachments, currentAttachment, setCurrentAttachment]);

  const onContainerKeyDown = useCallback(
    (e: React.KeyboardEvent<HTMLDivElement>) => {
      if (e.altKey || e.ctrlKey || e.shiftKey || e.metaKey) return;
      if (e.key === 'ArrowLeft') onPrevClick();
      else if (e.key === 'ArrowRight') onNextClick();
    },
    [onPrevClick, onNextClick]
  );

  const body = useMemo(
    () => (
      <AttachmentCarouselBody
        programId={programId}
        attachments={attachments}
        currentAttachment={currentAttachment}
        onPrevClick={onPrevClick}
        onNextClick={onNextClick}
      />
    ),
    [programId, attachments, currentAttachment, onPrevClick, onNextClick]
  );

  return (
    <div
      className="attachments-view__container"
      onKeyDown={onContainerKeyDown}
      tabIndex={-1}
      ref={containerRef}
    >
      <AttachmentCarouselHeader
        programId={programId}
        attachment={currentAttachment.attachment}
        onDelete={onDelete}
        onClose={onClose}
      />
      {body}
    </div>
  );
};
