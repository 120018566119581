import { MultiFilterRows } from './types';

export const applyFilters = <T>(entities: T[], filter: MultiFilterRows<T>): T[] => {
  if (filter.filters.length === 0) return entities;
  const filterFunctions = filter.filters.map(({ selectedFilter, filterValue }) =>
    selectedFilter.fn(filterValue)
  );

  return entities
    .map((program) => ({
      program,
      filteringResults: filterFunctions.map((fn) => fn(program)),
    }))
    .filter((p) =>
      filter.conjunction === 'And'
        ? p.filteringResults.every((r) => r)
        : p.filteringResults.some((r) => r)
    )
    .map((p) => p.program);
};
