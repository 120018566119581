import useApi from '../../api/backendApiContext';
import { useQuery } from '@tanstack/react-query';
import { useQueryCacheManager } from '../../hooks/useQueryCacheManager';
import { ProgramSummary, QueryData } from '../types';
import { ReactQueryConstants } from '../../commons/constants';

export const useFetchedCampaignPrograms = (props: {
  campaignId?: string;
  disabled?: boolean;
}): QueryData<ProgramSummary[] | undefined> => {
  const { programApi } = useApi();
  const { campaignId, disabled } = props;

  const programCache = useQueryCacheManager();
  return useQuery(
    programCache.getCampaignProgramsCacheName(campaignId ?? 'no-campaign-id-provided'),
    async () => {
      if (campaignId) return programApi.getCampaignPrograms(campaignId);
      return [];
    },
    {
      staleTime: ReactQueryConstants.campaignProgramsStaleTime,
      refetchOnWindowFocus: false,
      enabled: !!campaignId && !disabled,
    }
  );
};
