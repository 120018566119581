import { useQuery } from '@tanstack/react-query';
import { useQueryCacheManager } from '../../hooks/useQueryCacheManager';
import useApi from '../../api/backendApiContext';

export const useFetchedTenantIntegration = (props: { integrationId: string }) => {
  const { integrationId } = props;
  const { integrationApi } = useApi();
  const programCache = useQueryCacheManager();
  return useQuery(
    programCache.getTenantIntegrationCacheName(integrationId),
    async () => integrationApi.getIntegration(integrationId),
    {
      refetchOnWindowFocus: false,
    }
  );
};
