import { TemplateMessage } from './TemplateMessage';
import React from 'react';
import { TextAndIcon } from '../../../commons/components/TextAndIcon';
import { ArrowRightUpIcon, PaperPlaneIcon } from '../../../assets/icons';
import { growegyAIConfig } from '../../../commons/config';
import { CHAT_TYPE_CREATE_PROGRAM, CHAT_TYPE_GENERAL, ChatType } from '../../dto';
import { AI_MESSAGE_SENDER } from '../../types';

const SAMPLE_PROMPTS = {
  [CHAT_TYPE_GENERAL]: [
    'What are best practices for writing an engaging email subject line?',
    'What marketing channels have the lowest ROI?',
    'What is the most popular type of content in the digital marketing space?',
    'What are the pros and cons of Keyword Research tools?',
    'What are best practices for writing engaging email copy for a marketing campaign?',
  ],
  [CHAT_TYPE_CREATE_PROGRAM]: [
    'Create 5 social media polls about private money lending targeted to first time home buyers.',
    'Create 5 social media programs promoting the book release and schedule them for every Monday.',
    'Create programs to schedule a Business Strategy Meeting with my Growegy Account Manager on the 21st of every month for the next 6 months.',
  ],
};

const SamplePrompt = (props: {
  content: string;
  dataTest: string;
  onSend: (content: string) => void;
}) => {
  const { content, dataTest, onSend } = props;
  return (
    <button className="intro-message__sample" data-test={dataTest} onClick={() => onSend(content)}>
      <TextAndIcon
        text={content}
        icon={<PaperPlaneIcon className="intro-message__send-icon" />}
        textDataTest={'sample-prompt__text'}
        iconPosition="right"
        textAlign="left"
        textClassName="intro-message__sample-text"
      />
    </button>
  );
};

export const IntroMessage = (props: {
  chatType: ChatType;
  onSend: (content: string) => void;
  onOpenLibraryClick: () => void;
}) => {
  const { chatType, onSend, onOpenLibraryClick } = props;
  return (
    <TemplateMessage sender={AI_MESSAGE_SENDER} chatType={chatType}>
      <div data-test="intro-message">
        <div className="growegy-font14--weight700">
          {chatType === CHAT_TYPE_CREATE_PROGRAM
            ? 'I am Program AI Creator and can create programs for you.'
            : 'I’m Growegy AI, your creative marketing collaborator.'}
        </div>
        <div>
          Not sure where to start? You can try these prompts or write your own in the field below.
        </div>
        <div className="intro-message__sample-list-container">
          <>
            {SAMPLE_PROMPTS[chatType].map((x, i) => (
              <SamplePrompt
                key={`sample_prompt_${i}`}
                content={x}
                dataTest={`intro-message__sample-prompt-${i}`}
                onSend={onSend}
              />
            ))}
            {growegyAIConfig.libraryEnabled && chatType === CHAT_TYPE_GENERAL && (
              <button
                className="intro-message__sample"
                data-test="intro-message__open-library"
                onClick={onOpenLibraryClick}
              >
                <TextAndIcon
                  text="View more in Library"
                  icon={<ArrowRightUpIcon className="intro-message__send-icon" />}
                  iconPosition="right"
                  textAlign="left"
                  textClassName="intro-message__open-library"
                />
              </button>
            )}
          </>
        </div>
      </div>
    </TemplateMessage>
  );
};
