import { useRef } from 'react';
import {
  getProgramStatus,
  OneMonthResolvedSummaries,
  OneMonthSummaries,
  ProgramSummary,
  ProgramType,
  QueryData,
  ResolvedCampaign,
  ResolvedProgramSummaries,
  ResolvedProgramSummary,
  UnknownCampaign,
  UnknownProgramType,
} from '../types';
import { useQueryCacheManager } from '../../hooks/useQueryCacheManager';
import { useFetchedProgramSummaries } from './useFetchedProgramSummaries';
import { useFetchedProgramTypes } from './useFetchedProgramTypes';
import { useFetchedUsers } from '../../user/hooks/useFetchedUsers';
import { UnknownProgramOwner, User } from '../../user/types';
import {
  aggregateQueries,
  resolveCampaign,
  resolveProgramOwner,
  resolveProgramSummaryProgramType,
} from '../util';
import { useResolvedCampaigns } from './useResolvedCampaigns';
import { useFetchedCampaignPrograms } from './useFetchedCampaignPrograms';

const resolveSummaries = (
  summaries: ProgramSummary[],
  types: ProgramType[],
  users: User[],
  campaigns: ResolvedCampaign[]
): ResolvedProgramSummary[] =>
  summaries.map((s) => {
    const type = resolveProgramSummaryProgramType(s, types);
    const owner = resolveProgramOwner(s, users);
    const resolvedTasks = s.tasks.map((t, i) => ({
      ...t,
      owner: resolveProgramOwner(t, users),
      orderId: i,
    }));
    const campaign = resolveCampaign(s, campaigns);

    return { ...s, type, owner, resolvedTasks, campaign, status: getProgramStatus(s.status) };
  });

const resolve = (
  summariesByMonth: QueryData<OneMonthSummaries | null>[],
  types: ProgramType[],
  users: User[],
  campaigns: ResolvedCampaign[]
) =>
  summariesByMonth.map((oneMonthQuery: QueryData<OneMonthSummaries | null>) => {
    const { data, status, fetchStatus, error, isStale } = oneMonthQuery;
    const resolvedOneMonth: OneMonthResolvedSummaries | null = data
      ? {
          startMonth: data.startMonth,
          summaries: resolveSummaries(data.summaries, types, users, campaigns),
        }
      : null;
    const hasUnresolvedTypes =
      !!resolvedOneMonth &&
      !!resolvedOneMonth.summaries.find((s) => s.type.id === UnknownProgramType.id);
    const hasUnresolvedOwners =
      !!resolvedOneMonth &&
      !!resolvedOneMonth.summaries.find((s) => s.owner && s.owner.id === UnknownProgramOwner.id);
    const hasUnresolvedCampaigns =
      !!resolvedOneMonth &&
      !!resolvedOneMonth.summaries.find((s) => s.campaign && s.campaign.id === UnknownCampaign.id);
    return {
      resolvedOneMonthQuery: {
        data: resolvedOneMonth,
        status,
        fetchStatus,
        error,
        isStale,
      },
      hasUnresolvedTypes,
      hasUnresolvedOwners,
      hasUnresolvedCampaigns,
    };
  });

const campaignProgramsQueryToMonthlyProgramQuery = (
  query: QueryData<ProgramSummary[] | undefined>
): QueryData<OneMonthSummaries | null>[] => {
  const { data, ...rest } = query;
  const whateverMonthMustBeProvided = new Date(`1500-01-15T00:00:00Z`);
  const queryResult = data
    ? ({
        startMonth: whateverMonthMustBeProvided,
        summaries: data,
      } as OneMonthSummaries)
    : null;

  return [{ ...rest, data: queryResult }];
};

export const useResolvedProgramSummaries = (props: {
  monthsStart?: Date[];
  campaignId?: string;
  disabled?: boolean;
}): ResolvedProgramSummaries => {
  const { monthsStart, campaignId, disabled } = props;
  const programCache = useQueryCacheManager();
  const monthlyProgramsQuery = useFetchedProgramSummaries({
    monthsStart,
  });

  const campaignProgramsQuery = useFetchedCampaignPrograms({ campaignId, disabled });

  const summaryResults: QueryData<OneMonthSummaries | null>[] = monthsStart
    ? monthlyProgramsQuery
    : campaignProgramsQueryToMonthlyProgramQuery(campaignProgramsQuery);

  const programTypesQuery = useFetchedProgramTypes();
  const usersQuery = useFetchedUsers();
  const campaignsQuery = useResolvedCampaigns();

  const typeResolvingAttempt = useRef<number>(0);
  const ownerResolvingAttempt = useRef<number>(0);
  const campaignResolvingAttempt = useRef<number>(0);

  const resolvedResults: {
    resolvedOneMonthQuery: QueryData<OneMonthResolvedSummaries | null>;
    hasUnresolvedTypes: boolean;
    hasUnresolvedOwners: boolean;
    hasUnresolvedCampaigns: boolean;
  }[] = resolve(
    summaryResults ?? [],
    programTypesQuery.data?.types ?? [],
    usersQuery.data ?? [],
    campaignsQuery.data ?? []
  );
  const typesAreResolved = !resolvedResults.find((r) => r.hasUnresolvedTypes);
  const ownersAreResolved = !resolvedResults.find((r) => r.hasUnresolvedOwners);
  const campaignsAreResolved = !resolvedResults.find((r) => r.hasUnresolvedCampaigns);

  const aggregateQueryMetadata = aggregateQueries(
    summaryResults,
    !typesAreResolved || !ownersAreResolved || !campaignsAreResolved,
    [
      {
        metadata: programTypesQuery,
        initiateReload: async () => programCache.initTypesRefetch(),
        attemptCounter: typeResolvingAttempt,
      },
      {
        metadata: usersQuery,
        initiateReload: async () => programCache.initUsersRefetch(),
        attemptCounter: ownerResolvingAttempt,
      },
      {
        metadata: campaignsQuery,
        initiateReload: async () => programCache.initCampaignsRefetch(),
        attemptCounter: campaignResolvingAttempt,
      },
    ]
  );

  return {
    data: resolvedResults.map((r) => r.resolvedOneMonthQuery),
    ...aggregateQueryMetadata,
  };
};
