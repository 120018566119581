export const passwordRequirements = {
  pattern:
    // eslint-disable-next-line
    /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[^$*.\[\]{}()?"!@#%&/\\,><':;|_~`=+\-])[A-Za-z\d^$*.\[\]{}()?"!@#%&/\\,><':;|_~`=+\-]{8,98}$/,
  explainMessage:
    'At least 8 characters including 1 uppercase, 1 lowercase, 1 number and 1 special character',
};

export const confirmCodeRequirements = {
  pattern: /^[0-9]{6,6}$/,
  explainMessage: 'Code must contain 6 digits',
};
