import { ProgramRow } from '../../types';
import React from 'react';
import { CellProps } from './ProgramCells';
import { getProgramNameWithTasksCount } from '../../util';
import { TriangleDownSmallIcon, TriangleRightSmallIcon } from '../../../assets/icons';

const NameReadonlyCell = ({ value, row }: CellProps<ProgramRow, string>) => (
  <div className="text-capitalize text-break programs-table__name-cell">
    <>
      {!!row.original.resolvedTasks.length && (
        <div
          className="programs-table__editable-cell--squared2"
          data-test="programs-table__name-cell-expander"
          onClick={(e) => {
            row.toggleRowExpanded();
            e.stopPropagation();
          }}
        >
          {row.isExpanded ? <TriangleDownSmallIcon /> : <TriangleRightSmallIcon />}
        </div>
      )}
      <div data-test="programs-table__name-cell-title">
        {getProgramNameWithTasksCount(value, row.original.resolvedTasks)}
      </div>
    </>
  </div>
);

export default NameReadonlyCell;
