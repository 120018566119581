import { useMutation } from '@tanstack/react-query';
import { useQueryCacheManager } from '../../hooks/useQueryCacheManager';
import useApi from '../../api/backendApiContext';
import { useNotification } from '../../notification/notificationsContext';
import { errorToString } from '../../util/utils';

export const useFinalizeInvoicesMutation = () => {
  const queryCacheManager = useQueryCacheManager();
  const { billingApi } = useApi();
  const { notifyError } = useNotification();
  return useMutation(async () => billingApi.finalizeInvoices(), {
    onError: async (e) => {
      const message = errorToString(
        e,
        'Something when wrong while refreshing invoices. Please try again or contact support.'
      );
      notifyError({
        notificationMsg: message,
        logMsg: message,
      });
      await queryCacheManager.invalidateInvoices();
      await queryCacheManager.invalidateBillingCustomer();
    },
    onSuccess: async (invoices) => {
      await queryCacheManager.updateInvoices(invoices);
      await queryCacheManager.invalidateBillingCustomer();
    },
  });
};
