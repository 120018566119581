import { useEffect, useState } from 'react';
import { LogoGrowegy } from '../../assets/icons';
import { CognitoUser } from 'amazon-cognito-identity-js';
import {
  confirmCode as apiConfirmCode,
  forgotPassword as apiForgotPwd,
  newPassword as apiNewPwd,
  resendCode as apiResetCode,
  resetPassword as apiResetPwd,
  signIn as apiSignIn,
  SignInResult,
  signUp as apiSignUp,
} from '../authApi';
import SignUp from '../components/SignUp';
import ConfirmCode from '../components/ConfirmCode';
import useAuth from '../authContext';
import SignIn from '../components/SignIn';
import { createUser, IUserTokens } from '../../currentuser/currentUser';
import NewPassword from '../components/NewPassword';
import ResetPassword from '../components/ResetPassword';
import ForgotPasswordInit from '../components/ForgotPasswordInit';
import useAnalytics from '../../web-analytics/webAnalyticsContext';
import { analyticsIdentifyUser, analyticsTrack } from '../../web-analytics/webAnalytics';
import { AnalyticsEvent } from '../../web-analytics/AnalyticsEvent';
import { useNavigate } from 'react-router-dom';
import { AppRoutes } from '../../router/AppRoutes';

type AuthScreen =
  | { type: 'SIGN_UP' }
  | { type: 'SIGN_IN' }
  | { type: 'CONFIRM_CODE'; email: string }
  | { type: 'CHANGE_PASSWORD'; cognitoUser: CognitoUser }
  | { type: 'FORGOT_PASSWORD'; email?: string }
  | { type: 'AUTHENTICATED'; tokens: IUserTokens };

const AuthContainer = (props: { tab: 'SIGN_IN' | 'SIGN_UP' }) => {
  const { tab } = props;
  const [authScreen, setAuthScreen] = useState<AuthScreen>({ type: props.tab });

  const { dispatch } = useAuth();
  const analytics = useAnalytics();
  useEffect(() => {
    if (authScreen.type === 'AUTHENTICATED') {
      const { tokens } = authScreen;
      dispatch({ type: 'AUTHENTICATED', tokens });
    }
  });

  const navigate = useNavigate();

  const changeTab = (newTab: 'SIGN_IN' | 'SIGN_UP'): void => {
    if (newTab !== tab) {
      if (authScreen.type !== newTab) {
        navigate(newTab === 'SIGN_IN' ? AppRoutes.Auth.signIn : AppRoutes.Auth.signUp, {
          relative: 'route',
        });
      }
    }
  };

  const changeScreen = (newScreen: AuthScreen): void => {
    if (newScreen.type === 'SIGN_UP' || newScreen.type === 'SIGN_IN') {
      changeTab(newScreen.type);
    }
    setAuthScreen(newScreen);
  };

  const signUp = async (email: string, password: string): Promise<void> => {
    analyticsTrack(analytics, AnalyticsEvent.USER_SIGN_UP_DETAILS_SUBMITTED);
    const signUpResult = await apiSignUp(email, password);
    switch (signUpResult) {
      case 'OK':
        changeScreen({ type: 'SIGN_IN' });
        break;
      case 'CONFIRM_CODE':
        changeScreen({ type: 'CONFIRM_CODE', email });
        break;
    }
    analyticsTrack(analytics, AnalyticsEvent.USER_SIGN_UP_DETAILS_ACCEPTED);
  };

  const confirmCode = async (email: string, code: string): Promise<void> => {
    analyticsTrack(analytics, AnalyticsEvent.USER_SIGN_UP_EMAIL_VERIFICATION_SUBMITTED);
    await apiConfirmCode(email, code, analytics);
    analyticsTrack(analytics, AnalyticsEvent.USER_SIGNED_UP);
    changeScreen({ type: 'SIGN_IN' });
  };

  const handleSignInResult = (signInResult: SignInResult, email: string): void => {
    switch (signInResult.type) {
      case 'OK': {
        const currentUser = createUser(signInResult.tokens);
        if (currentUser && signInResult?.tokens) {
          analyticsIdentifyUser(analytics, currentUser);
          changeScreen({ type: 'AUTHENTICATED', tokens: signInResult?.tokens });
        } else {
          throw new Error(
            `Received tokens from cognito on sign-in but couldn't build a current user from them: ${JSON.stringify(
              signInResult.tokens
            )}`
          );
        }
        break;
      }
      case 'CONFIRM_CODE':
        changeScreen({ type: 'CONFIRM_CODE', email });
        break;
      case 'CHANGE_PASSWORD':
        changeScreen({
          type: 'CHANGE_PASSWORD',
          cognitoUser: signInResult.cognitoUser,
        });
        break;
    }
  };

  const signIn = async (email: string, password: string): Promise<void> => {
    const signInResult = await apiSignIn(email, password);
    handleSignInResult(signInResult, email);
  };

  const changePassword = async (password: string, cognitoUser: CognitoUser): Promise<void> => {
    const tokens = await apiNewPwd(password, cognitoUser);
    analyticsTrack(analytics, AnalyticsEvent.USER_PASSWORD_CHANGED);
    const currentUser = createUser(tokens);
    if (currentUser) {
      changeScreen({ type: 'AUTHENTICATED', tokens });
    } else {
      throw new Error(
        `Received tokens from cognito on change password but couldn't build a current user from them: ${JSON.stringify(
          tokens
        )}`
      );
    }
  };

  const resetPassword = async (email: string, code: string, password: string): Promise<void> => {
    const signInResult = await apiResetPwd(email, code, password);
    handleSignInResult(signInResult, email);
  };

  const initForgotPassword = async (email: string): Promise<void> => {
    await apiForgotPwd(email);
    changeScreen({ type: 'FORGOT_PASSWORD', email });
  };

  const getComponent = () => {
    switch (authScreen.type) {
      case 'SIGN_UP':
        return <SignUp onSignUp={signUp} />;
      case 'CONFIRM_CODE':
        return (
          <ConfirmCode
            onConfirm={async (code) => confirmCode(authScreen.email, code)}
            resend={async () => apiResetCode(authScreen.email)}
          />
        );
      case 'SIGN_IN':
        return (
          <SignIn
            onSignIn={signIn}
            toForgotPassword={() => changeScreen({ type: 'FORGOT_PASSWORD' })}
          />
        );
      case 'CHANGE_PASSWORD':
        return (
          <NewPassword
            onSubmit={async (password) => changePassword(password, authScreen.cognitoUser)}
          />
        );
      case 'FORGOT_PASSWORD': {
        const { email } = authScreen;
        return email ? (
          <ResetPassword
            onReset={async (code, password) => resetPassword(email, code, password)}
            resend={async () => apiForgotPwd(email)}
          />
        ) : (
          <ForgotPasswordInit onSubmit={initForgotPassword} />
        );
      }
      case 'AUTHENTICATED':
      default:
        return <></>;
    }
  };

  return (
    <div className="auth-container">
      <div className="dialog-container">
        {getComponent()}

        {tab === 'SIGN_IN' ? (
          <div className="auth__sign" onClick={() => changeTab('SIGN_UP')}>
            Don’t have an account?{' '}
            <span className="auth__sign-color" data-test="auth__sign-up">
              Sign up
            </span>
          </div>
        ) : (
          <div className="auth__sign" onClick={() => changeTab('SIGN_IN')}>
            Already have an account?{' '}
            <span className="auth__sign-color" data-test="auth__sign-in">
              Sign in
            </span>
          </div>
        )}
      </div>
      <div className="auth-content">
        <div className="auth-content__title">
          <img src={LogoGrowegy} className="auth-content__logo" alt="Logo" />
          <div className="auth-content__name">Growegy</div>
        </div>
        <div className="auth-content__subtitle">
          Track, manage, and analyze all your marketing activities, beautifully.
        </div>
        <ul className="auth-content__list">
          <li className="list-item">
            Track and share projects or activities in a timeline, calendar, and table view.
          </li>
          <li className="list-item">
            Manage task assignments and due dates to deliver promotional projects as expected.
          </li>
          <li className="list-item">
            Integrate with Salesforce campaigns to see real-time lead creation and pipeline
            creation.
          </li>
          <li className="list-item">
            See projected versus actual campaign performance, customer acquisition costs, and return
            on investment.
          </li>
        </ul>
      </div>
    </div>
  );
};

export default AuthContainer;
