import UserSettingsEdit from './UserSettingsEdit';
import useAnalyticsPage from '../web-analytics/hooks/useAnalyticsPage';
import { Page } from '../web-analytics/Page';
import useAuth from '../auth/authContext';
import { PleaseRelogin } from '../auth/PleaseRelogin';

const UserProfile = () => {
  useAnalyticsPage(Page.USER_PROFILE);

  const {
    state: { user },
  } = useAuth();
  if (!user) return <PleaseRelogin />;

  return (
    <div className="user-settings">
      <UserSettingsEdit userId={user.userId} email={user.email} roles={user.roles} />
    </div>
  );
};

export default UserProfile;
