import DatePicker from 'react-datepicker';
import { ErrorMessage } from 'formik';
import React from 'react';
import clsx from 'clsx';

type FormikDateTimeInputProps = {
  formikFieldName: string;
  initialFieldValue: Date;
  disabled: boolean;
  readOnly: boolean;
  isError: boolean;
  dataTest: string;
  setFieldValue: (field: string, value: Date) => void;
  handleBlur: (event: React.FocusEvent<HTMLInputElement>) => void;
  errorClassName?: string;
  className: string;
};

export const FormikDateTimeInput = ({
  formikFieldName,
  initialFieldValue,
  disabled,
  readOnly,
  setFieldValue,
  handleBlur,
  isError,
  dataTest,
  errorClassName,
  className,
}: FormikDateTimeInputProps) => (
  <>
    <div style={{ minWidth: '150px' }} data-test={dataTest}>
      <DatePicker
        showTimeSelect={true}
        timeIntervals={15}
        dateFormat="MMM dd, hh:mm a'"
        name={formikFieldName}
        selected={initialFieldValue}
        disabled={disabled}
        readOnly={readOnly}
        onChange={(d) => {
          if (d instanceof Date) setFieldValue(formikFieldName, d);
        }}
        onBlur={handleBlur}
        className={clsx(className, { 'is-invalid': isError })}
      />
    </div>

    <ErrorMessage
      component="div"
      name={formikFieldName}
      className={errorClassName ?? 'invalid-feedback'}
      data-test={`${dataTest}-validation-error`}
    />
  </>
);
