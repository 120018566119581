import { nonBreakableSpace } from '../util/utils';

export type BillingPlanMonth = {
  price: number | null;
  regularBenefits: string[];
  specialBenefits: string[];
};

export type BillingPlanName = 'month' | 'year';

export type BillingPlanFeatures = {
  gpt4: boolean;
};

export type BillingPlan = {
  name: BillingPlanName;
  displayName: string;
  scheduleDescription: string;
  timelineLookAheadMonths: number;
  features: BillingPlanFeatures;
  months: BillingPlanMonth[];
};

const BILLING_PLAN_MONTH_PRICE = 55;
const BILLING_PLAN_YEAR_PRICE = 200;

export const Benefit = {
  MARKETING_APP: 'Marketing app',
  BASIC_AI: 'Basic AI (GPT-3.5)',
  ADVANCED_AI: 'Advanced AI (GPT-4)',
  CREDIT_REPORT: 'Credit report',
  CREDIT_REPORT_SENT: 'Credit report sent',
  HIGHER_CREDIT_LIMIT: 'Higher credit limit',
  HIGHER_CREDIT_LIMIT_REPORT_SENT: `Higher credit limit report${nonBreakableSpace}sent`,
  HIGHER_CREDIT_LIMIT_REPORTING_IN_PROGRESS: `Higher credit limit reporting${nonBreakableSpace}in${nonBreakableSpace}progress`,
  PAID_AD_AND_IMAGES: `Set of social/paid ad copy &${nonBreakableSpace}images`,
  SEO_REVIEW: 'SEO technical review',
  BOTTLE_OPENERS_OR_BUSINESS_CARDS: '5 engraved bottle openers or metal business cards',
  WEB_DESIGN_REVIEW: 'Website design review & recommendations',
  SEO_CONTENT_REVIEW: 'SEO content review & keyword suggestions',
  GOOGLE_ADS_SETUP: 'Google Ads account setup',
};

export const BILLING_PLAN_MONTH: BillingPlan = {
  name: 'month',
  displayName: 'Monthly',
  scheduleDescription: `$${BILLING_PLAN_MONTH_PRICE}/month`,
  timelineLookAheadMonths: 3,
  features: {
    gpt4: false,
  },
  months: [
    {
      price: BILLING_PLAN_MONTH_PRICE,
      regularBenefits: [Benefit.MARKETING_APP, Benefit.BASIC_AI, Benefit.CREDIT_REPORT],
      specialBenefits: [],
    },
    {
      price: BILLING_PLAN_MONTH_PRICE,
      regularBenefits: [Benefit.MARKETING_APP, Benefit.BASIC_AI, Benefit.CREDIT_REPORT],
      specialBenefits: [],
    },
    {
      price: BILLING_PLAN_MONTH_PRICE,
      regularBenefits: [Benefit.MARKETING_APP, Benefit.BASIC_AI, Benefit.CREDIT_REPORT],
      specialBenefits: [],
    },
    {
      price: BILLING_PLAN_MONTH_PRICE,
      regularBenefits: [Benefit.MARKETING_APP, Benefit.BASIC_AI, Benefit.CREDIT_REPORT],
      specialBenefits: [],
    },
    {
      price: BILLING_PLAN_MONTH_PRICE,
      regularBenefits: [Benefit.MARKETING_APP, Benefit.BASIC_AI, Benefit.CREDIT_REPORT],
      specialBenefits: [Benefit.PAID_AD_AND_IMAGES],
    },
    {
      price: BILLING_PLAN_MONTH_PRICE,
      regularBenefits: [Benefit.MARKETING_APP, Benefit.BASIC_AI, Benefit.CREDIT_REPORT],
      specialBenefits: [Benefit.SEO_REVIEW],
    },
    {
      price: BILLING_PLAN_MONTH_PRICE,
      regularBenefits: [Benefit.MARKETING_APP, Benefit.BASIC_AI, Benefit.CREDIT_REPORT],
      specialBenefits: [Benefit.BOTTLE_OPENERS_OR_BUSINESS_CARDS],
    },
    {
      price: BILLING_PLAN_MONTH_PRICE,
      regularBenefits: [Benefit.MARKETING_APP, Benefit.BASIC_AI, Benefit.CREDIT_REPORT],
      specialBenefits: [Benefit.WEB_DESIGN_REVIEW],
    },
    {
      price: BILLING_PLAN_MONTH_PRICE,
      regularBenefits: [Benefit.MARKETING_APP, Benefit.BASIC_AI, Benefit.CREDIT_REPORT],
      specialBenefits: [],
    },
    {
      price: BILLING_PLAN_MONTH_PRICE,
      regularBenefits: [Benefit.MARKETING_APP, Benefit.BASIC_AI, Benefit.CREDIT_REPORT],
      specialBenefits: [Benefit.SEO_CONTENT_REVIEW],
    },
    {
      price: BILLING_PLAN_MONTH_PRICE,
      regularBenefits: [Benefit.MARKETING_APP, Benefit.BASIC_AI, Benefit.CREDIT_REPORT],
      specialBenefits: [],
    },
    {
      price: BILLING_PLAN_MONTH_PRICE,
      regularBenefits: [Benefit.MARKETING_APP, Benefit.BASIC_AI, Benefit.CREDIT_REPORT],
      specialBenefits: [Benefit.GOOGLE_ADS_SETUP],
    },
  ],
};

export const BILLING_PLAN_YEAR: BillingPlan = {
  name: 'year',
  displayName: 'Annual',
  scheduleDescription: `$${BILLING_PLAN_YEAR_PRICE} × 3/year`,
  timelineLookAheadMonths: 0,
  features: {
    gpt4: true,
  },
  months: [
    {
      price: BILLING_PLAN_YEAR_PRICE,
      regularBenefits: [Benefit.MARKETING_APP, Benefit.ADVANCED_AI, Benefit.HIGHER_CREDIT_LIMIT],
      specialBenefits: [Benefit.PAID_AD_AND_IMAGES],
    },
    {
      price: BILLING_PLAN_YEAR_PRICE,
      regularBenefits: [Benefit.MARKETING_APP, Benefit.ADVANCED_AI, Benefit.HIGHER_CREDIT_LIMIT],
      specialBenefits: [Benefit.SEO_REVIEW, Benefit.BOTTLE_OPENERS_OR_BUSINESS_CARDS],
    },
    {
      price: BILLING_PLAN_YEAR_PRICE,
      regularBenefits: [Benefit.MARKETING_APP, Benefit.ADVANCED_AI, Benefit.HIGHER_CREDIT_LIMIT],
      specialBenefits: [
        Benefit.WEB_DESIGN_REVIEW,
        Benefit.SEO_CONTENT_REVIEW,
        Benefit.GOOGLE_ADS_SETUP,
      ],
    },
    {
      price: null,
      regularBenefits: [Benefit.MARKETING_APP, Benefit.ADVANCED_AI, Benefit.HIGHER_CREDIT_LIMIT],
      specialBenefits: [],
    },
  ],
};

export const getBillingPlan = (planName: string): BillingPlan | null => {
  switch (planName) {
    case BILLING_PLAN_MONTH.name:
      return BILLING_PLAN_MONTH;
    case BILLING_PLAN_YEAR.name:
      return BILLING_PLAN_YEAR;
    default:
      return null;
  }
};
