import { FormatOptionLabelMeta } from 'react-select';
import React, { useCallback } from 'react';
import { useColoredItemState } from '../hooks/useColoredItemState';
import { ColoredItem } from '../types';
import { FormatOptionLabelContext } from 'react-select/dist/declarations/src/Select';
import { Info } from '../../commons/components/Info';
import { GrowegySelect } from './GrowegySelect';

const formatOptionLabel = (
  coloredItem: ColoredItem,
  context: FormatOptionLabelContext,
  dataTestPrefix: string,
  showInfo: boolean
) => (
  <div className="growegy-select__label">
    <span
      className="growegy-select__text"
      style={{ color: coloredItem.color }}
      data-test={`${dataTestPrefix}__option-row-${coloredItem.name.replace(/ /g, '-')}`}
    >
      {coloredItem.name}
    </span>
    {context === 'menu' && showInfo && coloredItem.description && (
      <Info
        tooltipText={coloredItem.description}
        tooltipWidth={200}
        tooltipPlace={'right-end'}
        tooltipFixOverflow={true}
      />
    )}
  </div>
);

export const ColoredSelect = <T extends ColoredItem>(props: {
  initialValue: T;
  coloredItems: T[];
  onValueChange: (value: T) => void;
  dataTestPrefix: string;
  disabled?: boolean;
  hasError?: boolean;
  isSearchable?: boolean;
  isRequired?: boolean;
  initDummyItem?: (id: string) => T;
  autoFocus?: boolean;
  width?: number;
  showInfo?: boolean;
  menuZIndex?: number;
}) => {
  const {
    initialValue,
    onValueChange,
    coloredItems,
    disabled,
    hasError,
    isSearchable,
    isRequired,
    dataTestPrefix,
    initDummyItem,
    autoFocus,
    width,
    showInfo,
    menuZIndex,
  } = props;

  const { options, selectedOption, setSelectedOption } = useColoredItemState<T>({
    selected: { ...initialValue, value: initialValue.name },
    onValueChange,
    options: coloredItems.map((p) => ({ ...p, value: p.name })),
    initDummyItem,
  });

  const onChange = useCallback(
    (c: T | null) => {
      if (c) {
        setSelectedOption({ ...c, value: c.name });
      }
    },
    [setSelectedOption]
  );

  const formatOptionLabelCallback = useCallback(
    (item: ColoredItem, x: FormatOptionLabelMeta<ColoredItem>) =>
      formatOptionLabel(item, x.context, dataTestPrefix, showInfo ?? false),
    [dataTestPrefix, showInfo]
  );

  return (
    <GrowegySelect
      disabled={disabled}
      hasError={hasError}
      autoFocus={autoFocus}
      options={options}
      selectedOption={selectedOption}
      formatOptionLabel={formatOptionLabelCallback}
      onChange={onChange}
      isSearchable={isSearchable}
      isRequired={isRequired}
      dataTestPrefix={dataTestPrefix}
      width={width}
      menuZIndex={menuZIndex}
    />
  );
};
