import { AxiosInstance } from 'axios';
import { Integration, IntegrationAttribute } from './types';

type MetadataParameterDto = {
  name: string;
  type: string;
};

export type IntegrationMetadataDto = {
  id: string;
  name: string;
  description: string;
  icon: string;
  parameters: Array<MetadataParameterDto>;
};

export type IntegrationMetadatasDto = {
  metadatas: Array<IntegrationMetadataDto>;
};

type IntegrationDto = {
  attributes: { [name: string]: string } | null;
  version?: number;
};

export type IntegrationStateDto = {
  integrationType: string;
  status: string;
  errorMessage: string;
};

export type IntegrationApi = ReturnType<typeof getIntegrationApi>;

const toIntegration = (integrationId: string, integrationDto: IntegrationDto): Integration => {
  const attributes: IntegrationAttribute[] = Object.entries<string>(
    integrationDto.attributes || []
  ).map(([name, value]) => ({ name, value }));
  return { id: integrationId, attributes, version: integrationDto.version };
};

const toIntegrationDto = (integration: Integration): IntegrationDto => {
  const attributes = integration.attributes.reduce<{ [id: string]: string }>((result, attr) => {
    const newAttr: { [id: string]: string } = {};
    newAttr[attr.name] = attr.value;
    return { ...result, ...newAttr };
  }, {});

  return { attributes, version: integration.version };
};

export const getIntegrationApi = (axiosInstance: AxiosInstance) => {
  const getMetadata = async (): Promise<IntegrationMetadatasDto> =>
    axiosInstance.get<IntegrationMetadatasDto>('/integration-metadatas').then((r) => r.data);

  const getIntegration = async (integrationId: string): Promise<Integration> => {
    const integrationDto = await axiosInstance
      .get<IntegrationDto>(`/tenants/current/integrations/${integrationId}`)
      .then((r) => r.data);
    return toIntegration(integrationId, integrationDto);
  };

  const updateIntegration = async (integration: Integration): Promise<Integration> => {
    const integrationDto = await axiosInstance
      .put<IntegrationDto>(
        `/tenants/current/integrations/${integration.id}`,
        toIntegrationDto(integration)
      )
      .then((r) => r.data);
    return toIntegration(integration.id, integrationDto);
  };

  const deleteIntegration = async (integration: Integration): Promise<Integration> => {
    const integrationDto = await axiosInstance
      .delete<IntegrationDto>(`/tenants/current/integrations/${integration.id}`, {
        data: toIntegrationDto(integration),
      })
      .then((r) => r.data);
    return toIntegration(integration.id, integrationDto);
  };

  const getIntegrationState = async (integrationId: string): Promise<IntegrationStateDto> =>
    axiosInstance
      .get<IntegrationStateDto>(`/tenants/current/integrations/${integrationId}/state`)
      .then((r) => r.data);

  return {
    getMetadata,
    getIntegration,
    updateIntegration,
    deleteIntegration,
    getIntegrationState,
  };
};
