import { ErrorMessage } from 'formik';
import React, { useEffect, useRef, useState } from 'react';
import clsx from 'clsx';
import { useAsyncDebounce } from 'react-table';
import Linkify from 'react-linkify';

type CustomParamsTextInputProps = {
  formikFieldName: string;
  initialFieldValue: string;
  disabled: boolean;
  readOnly: boolean;
  isError: boolean;
  dataTest: string;
  className: string;
  errorClassName?: string;
  placeholder?: string;
  setFieldValue: (v?: string) => void;
  debounceMs: number;
  urlHighlightOn?: true;
  autoFocus?: boolean;
  errorText?: string;
};

export const FormikTextInput = ({
  formikFieldName,
  initialFieldValue,
  disabled,
  readOnly,
  setFieldValue,
  isError,
  dataTest,
  className,
  errorClassName,
  placeholder,
  debounceMs,
  urlHighlightOn,
  autoFocus,
}: CustomParamsTextInputProps) => {
  const [currentValue, setCurrentValue] = useState(initialFieldValue ?? '');
  const [editing, setEditing] = useState(!urlHighlightOn || autoFocus);
  const inputRef = useRef<HTMLInputElement>(null);
  const onChange = useAsyncDebounce((v) => {
    setFieldValue(v ?? undefined);
  }, debounceMs);

  useEffect(() => {
    setCurrentValue(initialFieldValue || '');
  }, [initialFieldValue]);

  useEffect(() => {
    if (!inputRef.current || !urlHighlightOn || !editing) return;
    inputRef.current?.focus();
  }, [editing, urlHighlightOn]);

  function handleChange(v: string | null) {
    setCurrentValue(v ?? '');
    onChange(v);
  }

  function handleFocus(e: React.FocusEvent<HTMLDivElement>) {
    if (!urlHighlightOn) return;
    if (e.target instanceof HTMLAnchorElement) return;
    if (readOnly || disabled || editing) return;
    setEditing(true);
  }

  function onBlur() {
    if (!urlHighlightOn) return;
    setEditing(false);
  }

  return (
    <div
      data-test={`${dataTest}-switcher`}
      onFocus={handleFocus}
      onKeyDown={(e) => {
        if (editing) e.stopPropagation();
      }}
    >
      <>
        {editing || !currentValue ? (
          <>
            <input
              type="text"
              ref={inputRef}
              name={formikFieldName}
              value={currentValue}
              placeholder={placeholder ?? 'Field value'}
              onChange={(e) => handleChange(e.target.value)}
              disabled={disabled}
              readOnly={readOnly}
              className={clsx(className, { 'is-invalid': isError })}
              data-test={dataTest}
              autoFocus={autoFocus ?? false}
              onBlur={onBlur}
              onKeyDown={(e) => {
                if (e.key === 'Escape') onBlur();
                if (e.key === 'Enter') onBlur();
              }}
            />
            <ErrorMessage
              component="div"
              name={formikFieldName}
              className={errorClassName ?? 'invalid-feedback'}
              data-test={`${dataTest}-validation-error`}
            />
          </>
        ) : (
          <>
            <div
              className={clsx('d-flex align-items-center', className)}
              data-test={`${dataTest}-view`}
              tabIndex={0}
            >
              <Linkify
                componentDecorator={(decoratedHref, decoratedText, key) => (
                  <a
                    target="blank"
                    href={decoratedHref}
                    key={key}
                    onClick={(e) => {
                      e.stopPropagation();
                      setEditing(false);
                    }}
                  >
                    {decoratedText}
                  </a>
                )}
              >
                <div className="text--truncated">{currentValue}</div>
              </Linkify>
            </div>

            <ErrorMessage
              component="div"
              name={formikFieldName}
              className={errorClassName ?? 'invalid-feedback'}
              data-test={`${dataTest}-validation-error`}
            />
          </>
        )}
      </>
    </div>
  );
};
