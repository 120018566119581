import { ProgramType } from '../program/types';

export const DATA_SERIES_PROJECTED_LEADS = 'projectedLeads';
export const DATA_SERIES_ACTUAL_LEADS = 'actualLeads';
export type DataSeriesName = typeof DATA_SERIES_PROJECTED_LEADS | typeof DATA_SERIES_ACTUAL_LEADS;

export const GROUP_BY_TYPE = 'type';
export const GROUP_BY_MONTH = 'month';
export type GroupByFieldName = typeof GROUP_BY_TYPE | typeof GROUP_BY_MONTH;

export type LineDataPoint = {
  projectedLeads: number;
  actualLeads: number;
  timestamp: Date;
};

export type LineDataSeries = {
  points: LineDataPoint[];
};

export type GroupingDataItem = {
  key: { typeId: string | null; month: Date | null };
  value: {
    projectedLeads: number;
    actualLeads: number;
  };
};

export type GroupingDataItems = GroupingDataItem[];

export type ResolvedGroupingDataItem = Omit<GroupingDataItem, 'key'> & {
  key: { type: ProgramType | null; month: Date | null };
};

export type ResolvedGroupingDataItems = ResolvedGroupingDataItem[];

export type EnterpriseChartsAmount = {
  amount: number;
};

export type EnterpriseChartData = {
  orderingId: string;
  title: string;
  html: string;
  width: number | null;
  height: number | null;
  rowId: number;
  columnIdx: number;
  filterName: string;
};

export type EnterpriseCharts = {
  charts: EnterpriseChartData[];
};

export type EnterpriseAnalyticLayoutRow = {
  tab: string;
  title: string;
  id: number;
  idxWithinTab: number;
  showBorder: boolean;
  showChartTitles: boolean;
};

export type EnterpriseAnalyticLayoutRows = {
  layoutRows: EnterpriseAnalyticLayoutRow[];
};
