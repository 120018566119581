import React from 'react';
import axios from 'axios';
import useAuth from '../auth/authContext';
import { getUserApi, UserApi } from '../user/userApi';
import { getProgramApi, ProgramApi } from '../program/programApi';
import { useAxios } from './axiosClient';
import { getIntegrationApi, IntegrationApi } from '../integrations/integrationApi';
import { AnalyticsApi, getAnalyticsApi } from '../analytics/analyticsApi';
import {
  getPersonalRecommendationsApi,
  PersonalRecommendationsApi,
} from '../recommendations/recommendationsApi';
import { readCommonConfig } from '../commons/config';
import { getBillingApi, BillingApi } from '../billing/billingApi';
import { getGoogleCalendarApi, GoogleCalendarApi } from '../google-calendar/googleCalendarApi';
import { GrowegyAIApi, getGrowegyAIApi } from '../growegy-ai/growegyAIApi';
import { getOutlookApi, OutlookApi } from '../outlook/outlookApi';
import { getMetaApi, MetaApi } from '../meta/metaApi';

type ApiContextProps = {
  metaApi: MetaApi;
  userApi: UserApi;
  programApi: ProgramApi;
  integrationApi: IntegrationApi;
  analyticsApi: AnalyticsApi;
  personalRecommendationsApi: PersonalRecommendationsApi;
  billingApi: BillingApi;
  googleCalendarApi: GoogleCalendarApi;
  outlookApi: OutlookApi;
  growegyAIApi: GrowegyAIApi;
};

const ApiContext = React.createContext<ApiContextProps>({
  metaApi: getMetaApi(axios.create()),
  userApi: getUserApi(axios.create()),
  programApi: getProgramApi(axios.create()),
  integrationApi: getIntegrationApi(axios.create()),
  analyticsApi: getAnalyticsApi(axios.create()),
  personalRecommendationsApi: getPersonalRecommendationsApi(axios.create()),
  billingApi: getBillingApi(axios.create()),
  googleCalendarApi: getGoogleCalendarApi(axios.create()),
  outlookApi: getOutlookApi(axios.create()),
  growegyAIApi: getGrowegyAIApi(axios.create()),
});

export const ApiProvider = (props: React.PropsWithChildren<{}>) => {
  const {
    refreshTokenApi: authApi,
    state: { tokens },
  } = useAuth();

  const { backendApiUrl } = readCommonConfig();
  const axiosInstance = useAxios(`${backendApiUrl}/v1`, authApi, tokens);
  const apiContextProps: ApiContextProps = {
    metaApi: getMetaApi(axiosInstance),
    userApi: getUserApi(axiosInstance),
    programApi: getProgramApi(axiosInstance),
    integrationApi: getIntegrationApi(axiosInstance),
    analyticsApi: getAnalyticsApi(axiosInstance),
    personalRecommendationsApi: getPersonalRecommendationsApi(axiosInstance),
    billingApi: getBillingApi(axiosInstance),
    googleCalendarApi: getGoogleCalendarApi(axiosInstance),
    outlookApi: getOutlookApi(axiosInstance),
    growegyAIApi: getGrowegyAIApi(axiosInstance),
  };
  return <ApiContext.Provider value={apiContextProps} {...props} />;
};

export default function useApi(): ApiContextProps {
  const context = React.useContext(ApiContext);
  if (!context) {
    throw new Error(`useBackendApi must be used within an BackendApiContext`);
  }
  return context;
}
