import useApi from '../../api/backendApiContext';
import { useQueryCacheManager } from '../../hooks/useQueryCacheManager';
import { useQuery } from '@tanstack/react-query';
import { ReactQueryConstants } from '../../commons/constants';

export const useFetchedEnterpriseCharts = () => {
  const { analyticsApi } = useApi();
  return useQuery(
    useQueryCacheManager().getEnterpriseChartsCacheName(),
    async () => analyticsApi.getEnterpriseCharts(),
    {
      staleTime: ReactQueryConstants.enterpriseChartsStaleTime,
      refetchOnWindowFocus: false,
    }
  );
};
