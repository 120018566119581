import { NewTabLink } from '../../commons/components/NewTabLink';
import React from 'react';
import { billingPortalConfig } from '../../commons/config';

export const LoyaltyBenefitConsultation = () => {
  return (
    <div
      className="billing__loyalty-benefit-consultation-container"
      data-test="billing__loyalty-benefit-consultation-container"
    >
      <span>Schedule your loyalty benefit consultation · </span>
      <NewTabLink href={billingPortalConfig.loyaltyBenefitsConsultationUrl}>Schedule</NewTabLink>
    </div>
  );
};
