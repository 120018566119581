import { useMemo, useState } from 'react';
import { useMutation } from '@tanstack/react-query';
import useApi from '../../api/backendApiContext';
import { errorToString } from '../../util/utils';
import { UsersTable } from '../components/UsersTable';
import { UserDialog, UserModalState } from './UserDialog';
import { User, UserRow } from '../types';
import { useFetchedUsers } from '../hooks/useFetchedUsers';
import useAnalyticsPage from '../../web-analytics/hooks/useAnalyticsPage';
import { Page } from '../../web-analytics/Page';
import useAuth from '../../auth/authContext';
import { PleaseRelogin } from '../../auth/PleaseRelogin';
import { Permission } from '../../auth/permissions';

const getRows = (data: User[]): UserRow[] =>
  data.map(({ id, email, role, status, firstName, lastName, avatar }) => ({
    id,
    email,
    firstName,
    lastName,
    username: `${firstName} ${lastName}`.trim(),
    role,
    status,
    avatar,
  }));

export const UsersManagement = () => {
  useAnalyticsPage(Page.USERS_MANAGEMENT);

  const { userApi } = useApi();
  const { data, status, error } = useFetchedUsers();
  const { error: resendError, mutateAsync: resendPwdMutation } = useMutation(
    async (userId: string) => userApi.resendPassword(userId)
  );

  const [modalState, setModalState] = useState<UserModalState | null>(null);
  const {
    state: { user },
  } = useAuth();

  const onUpdate = (user: User) => setModalState({ action: 'UPDATE', user: { ...user } });
  const onCreate = (user: User) => setModalState({ action: 'CREATE', user: { ...user } });
  const onDelete = (user: User) => setModalState({ action: 'DELETE', user: { ...user } });
  const onRefresh = async (userId: string) => resendPwdMutation(userId);
  const memoRows = useMemo(() => (data ? getRows(data) : []), [data]);
  if (status === 'loading') {
    return (
      <div className="alert alert-secondary" role="alert">
        Loading...
      </div>
    );
  }

  if (!user) return <PleaseRelogin />;

  if (status === 'error') {
    return (
      <div className="alert alert-warning" role="alert" data-test="user-list__error">
        Could not load users: {errorToString(error)}
      </div>
    );
  }

  if (status === 'success' && data) {
    return (
      <>
        <div>
          <>
            {resendError && (
              <div
                className="card-footer alert alert-danger alert-dismissible fade show"
                data-test="user-list__item-resend-password-error"
              >
                <strong>Resend password failed!</strong> {errorToString(resendError)}
              </div>
            )}
            <UsersTable
              onUpdate={onUpdate}
              onDelete={onDelete}
              onCreate={onCreate}
              onRefresh={onRefresh}
              data={memoRows}
              hasAddPermission={user.has(Permission.ADD_USER)}
              hasDeletePermission={user.has(Permission.DELETE_USER)}
              hasRefreshPermission={user.has(Permission.RESEND_TEMP_PASSWORD)}
              hasReplacePermission={user.has(Permission.UPDATE_USER)}
            />
          </>
        </div>
        {modalState && (
          <UserDialog
            action={modalState.action}
            user={modalState.user}
            onHide={() => setModalState(null)}
          />
        )}
      </>
    );
  }

  return (
    <div>
      <h1>status {status}</h1>
      <p>
        <>data {data}</>
      </p>
    </div>
  );
};
