import { PropsWithChildren, useCallback } from 'react';
import { parseTaskDraggableId, parseTaskDroppableId } from './tasks/utils';
import { DragDropContext, DropResult } from '@hello-pangea/dnd';
import { ResolvedProgramSummary } from '../types';
import { TaskArrayHelpers } from './tasks/CellProps';
import useAuth from '../../auth/authContext';
import { getProgramPermissionHelper } from '../programPermissionHelper';

const zIndexClassName = 'programs-table__tasks--frontmost';

const addZIndexClass = () => {
  const element = document
    .querySelector('.tasks-table__row--dragging')
    ?.closest('.programs-table__tasks');
  if (element && !element.classList.contains(zIndexClassName)) {
    element.classList.add(zIndexClassName);
  }
};

const clearZIndexClass = () =>
  document
    .querySelectorAll(`.${zIndexClassName}`)
    .forEach((x) => x.classList.remove(zIndexClassName));

export const ProgramDragDropContext = (
  props: PropsWithChildren<{
    programSummaries: ResolvedProgramSummary[];
    taskArrayHelpersFactory: (programSummary: ResolvedProgramSummary) => TaskArrayHelpers;
  }>
) => {
  const { programSummaries, taskArrayHelpersFactory } = props;

  const {
    state: { user },
  } = useAuth();

  const onDragEnd = useCallback(
    (result: DropResult) => {
      clearZIndexClass();

      if (result.destination === null) return;

      const parsedDraggableId = parseTaskDraggableId(result.draggableId);
      if (!parsedDraggableId) return;
      const oldProgramId = parsedDraggableId.programId;

      const parsedDroppableId = parseTaskDroppableId(result.destination.droppableId);
      if (!parsedDroppableId) return;
      const { programId: newProgramId, dropArea } = parsedDroppableId;

      const sourceProgramSummary = programSummaries.find((x) => x.id === oldProgramId);
      if (!sourceProgramSummary) return;

      const destinationProgramSummary =
        oldProgramId === newProgramId
          ? sourceProgramSummary
          : programSummaries.find((x) => x.id === newProgramId);
      if (!destinationProgramSummary) return;

      const fromIndex = result.source.index;
      if (fromIndex < 0 || fromIndex >= sourceProgramSummary.resolvedTasks.length) return;
      const toIndex =
        dropArea === 'tasks'
          ? result.destination.index
          : destinationProgramSummary.resolvedTasks.length;
      if (toIndex < 0 || toIndex > destinationProgramSummary.resolvedTasks.length) return;

      if (oldProgramId === newProgramId) {
        if (dropArea === 'program') return;
        if (fromIndex === toIndex) return;
        const programPermissionHelper = getProgramPermissionHelper(user, destinationProgramSummary);
        if (!programPermissionHelper.canReorderTasks()) return;

        const arrayHelper = taskArrayHelpersFactory(destinationProgramSummary);
        arrayHelper.move(fromIndex, toIndex);
      } else {
        const task = sourceProgramSummary.resolvedTasks[fromIndex];
        if (!task) return;
        const sourcePermissionHelper = getProgramPermissionHelper(user, sourceProgramSummary);
        if (!sourcePermissionHelper.canDeleteTask(task)) return;
        const destinationPermissionHelper = getProgramPermissionHelper(
          user,
          destinationProgramSummary
        );
        if (
          !destinationPermissionHelper.canCreateTaskForAnyUser() &&
          (!destinationPermissionHelper.canCreateTask() || task.owningUserId !== user?.userId)
        )
          return;

        const oldArrayHelper = taskArrayHelpersFactory(sourceProgramSummary);
        oldArrayHelper.remove(fromIndex);
        const newArrayHelper = taskArrayHelpersFactory(destinationProgramSummary);
        newArrayHelper.insert(toIndex, { ...task, id: null, orderId: toIndex });
      }
    },
    [programSummaries, taskArrayHelpersFactory, user]
  );

  return (
    <DragDropContext onDragStart={addZIndexClass} onDragEnd={onDragEnd}>
      {props.children}
    </DragDropContext>
  );
};
