import React, { PropsWithChildren, useContext } from 'react';

export const CHAT_REALM_GROWEGY_AI = 'Growegy AI Realm';
export const CHAT_REALM_PROGRAMS = 'Programs Realm';
const CHAT_REALMS = [CHAT_REALM_GROWEGY_AI, CHAT_REALM_PROGRAMS] as const;
export type ChatRealm = (typeof CHAT_REALMS)[number];

type ChatRealmContextProps = {
  realm: ChatRealm | null;
};

const ChatRealmContext = React.createContext<ChatRealmContextProps>({
  realm: null,
});

export const useChatRealmContext = () => {
  return useContext(ChatRealmContext);
};

export const ChatRealmContextProvider = (props: PropsWithChildren<{ realm: ChatRealm }>) => {
  const { realm, children } = props;

  return (
    <ChatRealmContext.Provider
      value={{
        realm,
      }}
    >
      {children}
    </ChatRealmContext.Provider>
  );
};
