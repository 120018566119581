import { BILLING_PLAN_YEAR } from '../billingPlans';
import { Timeline } from './Timeline';
import React from 'react';
import { generateInvoicesAndBenefits } from '../utils';
import clsx from 'clsx';

export const AnnualPlanBenefitsWithTimeline = (props: { outlineHeader?: boolean }) => {
  const { outlineHeader } = props;
  const { invoicesAndBenefits, historicInvoices } = generateInvoicesAndBenefits(
    BILLING_PLAN_YEAR,
    []
  );
  return (
    <>
      <div className={clsx('upgrade-to-annual__header-container', { '': outlineHeader })}>
        <span className="growegy-font14--weight500 growegy-font-em">
          {outlineHeader
            ? `${BILLING_PLAN_YEAR.scheduleDescription}`
            : `${BILLING_PLAN_YEAR.displayName} plan · ${BILLING_PLAN_YEAR.scheduleDescription}`}
        </span>
        <span className="upgrade-to-annual__badge">Save 10%</span>
      </div>
      <Timeline
        segmentsPerYear={BILLING_PLAN_YEAR.months.length}
        invoicesAndBenefits={invoicesAndBenefits}
        historicInvoices={historicInvoices}
      />
    </>
  );
};
