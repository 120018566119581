import _ from 'lodash';
import { NoProgramOwner, UnknownProgramOwner, User } from './types';

const getUserName = (
  user: User | null,
  extractor: (s: { firstName: string; lastName: string }) => string,
  fallback: string
): string => {
  if (user) {
    if (user.id === UnknownProgramOwner.id) return 'Unknown';
    if (user.id === NoProgramOwner.id) return 'No owner';
    const userName = extractor(user);
    return userName.trim().length ? userName.trim() : fallback;
  }
  return fallback;
};

const cutOffEmail = (email: string) => email.split('@')[0];

export const extractUserInitials = (user: User | null, email: string): string => {
  const shortEmail = cutOffEmail(email).trim();
  return getUserName(
    user,
    (s) => {
      const first = s.firstName.trim().length ? s.firstName.trim()[0].toUpperCase() : '';
      const last = s.lastName.trim().length ? s.lastName.trim()[0].toUpperCase() : '';
      return `${first}${last}`;
    },
    shortEmail.length ? shortEmail[0].toUpperCase() : '?'
  );
};

export const extractUserName = (user: User): string => {
  return getUserName(
    user,
    (s) => {
      const first = s.firstName.trim().length ? _.upperFirst(s.firstName.trim()) : '';
      const last = s.lastName.trim().length ? _.upperFirst(s.lastName.trim()) : '';
      return `${first} ${last}`;
    },
    cutOffEmail(user.email ?? '').trim()
  );
};
