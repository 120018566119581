import _ from 'lodash';
import React from 'react';
import useAnalyticsPage from '../../web-analytics/hooks/useAnalyticsPage';
import { Page } from '../../web-analytics/Page';
import { IFrameChart } from '../components/IFrameChart';
import { EnterpriseAnalyticLayoutRow, EnterpriseChartData } from '../types';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';
import clsx from 'clsx';

type EnterpriseChartsCell = { filterTab: string; chart: EnterpriseChartData }[];

type RowWithCharts = {
  row: EnterpriseAnalyticLayoutRow;
  cells: { columnId: number; cell: EnterpriseChartsCell }[];
};

const matchRowsAndChars = (
  rows: EnterpriseAnalyticLayoutRow[],
  charts: EnterpriseChartData[]
): RowWithCharts[] =>
  _([...rows])
    .sortBy((r) => r.idxWithinTab)
    .value()
    .flatMap((row) => ({
      row,
      cells: _(charts)
        .filter((c) => c.rowId === row.id)
        .groupBy((c) => c.columnIdx)
        .map((columnGroup) => {
          const columnId = columnGroup[0].columnIdx;
          const cell = _(columnGroup)
            .groupBy((c) => c.filterName)
            .map((filterGroup) => ({ filterTab: filterGroup[0].filterName, chart: filterGroup[0] }))
            .sortBy((c) => c.chart.orderingId)
            .value();
          return { columnId, cell };
        })
        .sortBy((c) => c.columnId)
        .value(),
    }))
    .filter(({ cells }) => cells.length > 0);

const EnterpriseAnalyticCell = (props: {
  cell: EnterpriseChartsCell;
  row: EnterpriseAnalyticLayoutRow;
}) => {
  const { cell, row } = props;

  if (cell.length === 0) return <div />;
  if (cell.length === 1) {
    const [{ chart }] = cell;
    return (
      <IFrameChart
        key={chart.title}
        src={chart.html}
        title={chart.title}
        height={chart.height}
        width={chart.width}
        showBorder={!row.showBorder}
        showChartTitles={row.showChartTitles}
      />
    );
  }

  return (
    <div>
      <Tabs>
        <TabList>
          {cell.map((c) => (
            <Tab key={`tab-header-${c.filterTab}`}>{c.filterTab}</Tab>
          ))}
        </TabList>
        {cell.map(({ filterTab, chart }) => (
          <TabPanel key={`tab-panel-${filterTab}`}>
            <IFrameChart
              key={chart.title}
              src={chart.html}
              title={chart.title}
              height={chart.height}
              width={chart.width}
              showBorder={!row.showBorder}
              showChartTitles={row.showChartTitles}
            />
          </TabPanel>
        ))}
      </Tabs>
    </div>
  );
};

export const EnterpriseAnalytics = (props: {
  rows: EnterpriseAnalyticLayoutRow[];
  charts: EnterpriseChartData[];
}) => {
  const { rows, charts } = props;
  useAnalyticsPage(Page.ANALYTICS_ENTERPRISE);
  const rowsWithCharts = matchRowsAndChars(rows, charts);

  return (
    <div className="analytics__container analytics__body-container analytics__body-container--enterprise">
      <div className="d-flex flex-column">
        {rowsWithCharts.map(({ row, cells }) => (
          <div
            key={`row-wrapper-${row.id}`}
            className={clsx('analytics__enterprise-row', {
              'container-border': row.showBorder,
            })}
          >
            {row.title && (
              <div className="analytics__enterprise-row-header">
                <span>{row.title}</span>
              </div>
            )}
            <div className="analytics__enterprise-cells">
              {cells.map(({ cell }) => (
                <EnterpriseAnalyticCell
                  key={`cell-${
                    cell.length ? `${cell[0].chart.orderingId}-${cell[0].chart.title} ` : 'empty'
                  }-for-row-${row.id}`}
                  cell={cell}
                  row={row}
                />
              ))}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};
