import { ProgramType } from '../../program/types';
import { DivButton } from '../../commons/components/DivButton';
import { PlusIcon } from '../../assets/icons';
import React from 'react';
import { defaultColors } from '../../commons/constants';

const ProgramTypeRow = (props: { programType: ProgramType; onUpdate: () => void }) => (
  <div className="d-flex">
    <div
      className="program-type-row"
      data-test="program-type-row"
      key={props.programType.id}
      onClick={props.onUpdate}
      role="button"
      tabIndex={0}
      onKeyDown={(event) => {
        if (event.key === 'Enter') {
          props.onUpdate();
        }
      }}
    >
      <div className="program-type-row-name">
        <div className="program-type-row__label-wrapper">
          <span
            className="program-type-row__label"
            data-test={`program-type-row__label-${props.programType.name.replace(/ /g, '-')}`}
          >
            {props.programType.name}
          </span>
        </div>
      </div>
      <div className="program-type-row-color">
        <div
          className="program-type-row__color"
          data-test={`program-type-row__color--${props.programType.color}`}
          style={{ backgroundColor: props.programType.color }}
        />
      </div>
    </div>
  </div>
);

export const ProgramTypesList = (props: {
  data: ProgramType[];
  onUpdate: (user: ProgramType) => void;
  onCreate: (user: ProgramType) => void;
}) => {
  const { data, onUpdate, onCreate } = props;
  return (
    <>
      <div className="program-type-headers">
        <div className="program-type-header-name">Name</div>
        <div className="program-type-header-color">Color</div>
      </div>
      <div className="program-type-body">
        {data.map((d) => (
          <ProgramTypeRow key={d.id} programType={d} onUpdate={() => onUpdate(d)} />
        ))}
      </div>
      <DivButton
        icon={<PlusIcon />}
        onClick={() => onCreate({ id: '', name: '', color: defaultColors[0] })}
        text="Add new"
        dataTest="program-type__add-new-row"
        className="program-type__add-new-btn"
      />
    </>
  );
};
