import _ from 'lodash';
import React, { useCallback, useEffect, useState } from 'react';
import { NoCampaign, ResolvedCampaign, UnknownCampaign } from '../types';
import clsx from 'clsx';
import { FilterOptionOption } from 'react-select/dist/declarations/src/filters';
import { StyleVariables } from '../../commons/styleConstants';
import { GrowegySelect } from './GrowegySelect';
import { hexToRGB } from '../util';

const filterOption = <Option extends unknown>(
  option: FilterOptionOption<Option>,
  input: string
): boolean => {
  const campaign = option.data as ResolvedCampaign;
  if (campaign.id === UnknownCampaign.id || campaign.id === NoCampaign.id) return false;

  return campaign.name.toLowerCase().includes(input.toLowerCase().trim());
};

export const CampaignPlaceholderText = 'Add campaign';

export const CampaignSelectOption = (props: { campaign: ResolvedCampaign; disabled: boolean }) => {
  const { campaign, disabled } = props;

  if (campaign.id === NoCampaign.id)
    return disabled ? (
      <></>
    ) : (
      <div className="campaign-select__no-campaign-placeholder">{CampaignPlaceholderText}</div>
    );

  const color =
    campaign.id !== NoCampaign.id &&
    campaign.id !== UnknownCampaign.id &&
    campaign.campaignColor !== null
      ? hexToRGB(campaign.campaignColor!, 1)
      : StyleVariables.black2;
  return (
    <div
      className="campaign-select__option-wrapper-redesigned"
      style={{ borderColor: color, color }}
    >
      <span
        className={clsx('campaign-select__option-redesigned', {
          'text-truncate': campaign.id !== NoCampaign.id,
        })}
        data-test={`program-campaign-select__option-row--${campaign.name.replace(/ /g, '-')}`}
        style={{ color }}
      >
        {campaign.name}
      </span>
    </div>
  );
};

export const CampaignSelect = (props: {
  campaigns: ResolvedCampaign[];
  initialValue: ResolvedCampaign | null;
  onBlur?: () => void;
  onValueChange: (v: ResolvedCampaign | null) => void;
  autoFocus?: boolean;
  disabled: boolean;
  hasError?: boolean;
  isRequired?: boolean;
  dataTestPrefix?: string;
  menuZIndex?: number;
  width?: number;
}) => {
  const {
    campaigns,
    initialValue,
    onBlur,
    onValueChange,
    autoFocus,
    disabled,
    hasError,
    isRequired,
    dataTestPrefix,
    menuZIndex,
    width,
  } = props;

  const [selectedOption, setSelected] = useState<ResolvedCampaign>(
    initialValue === null ? NoCampaign : initialValue
  );

  useEffect(() => {
    setSelected(initialValue === null ? NoCampaign : initialValue);
  }, [initialValue]);

  const onChange = useCallback(
    (c: ResolvedCampaign | null) => {
      setSelected(c === null ? NoCampaign : c);
      onValueChange(c?.id === NoCampaign.id ? null : _.cloneDeep(c));
    },
    [onValueChange]
  );

  const formatOptionLabelCallback = useCallback(
    (campaign: ResolvedCampaign) => CampaignSelectOption({ campaign, disabled }),
    [disabled]
  );

  return (
    <GrowegySelect
      disabled={disabled}
      hasError={hasError}
      placeholder={CampaignPlaceholderText}
      autoFocus={autoFocus}
      options={campaigns.sort((a, b) => a.name.localeCompare(b.name))}
      filterOption={filterOption}
      selectedOption={selectedOption}
      formatOptionLabel={formatOptionLabelCallback}
      onChange={onChange}
      onBlur={onBlur}
      isRequired={isRequired}
      isClearable={
        selectedOption && selectedOption.id !== NoCampaign.id && !disabled && !isRequired
      }
      dataTestPrefix={dataTestPrefix ?? 'campaign-select'}
      menuZIndex={menuZIndex}
      width={width}
    />
  );
};
