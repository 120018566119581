import { FetchStatus, QueryStatus } from '@tanstack/react-query';
import { useRef } from 'react';
import { ProgramType, ProgramTypes, QueryData, UnknownProgramType } from '../../program/types';
import { useQueryCacheManager } from '../../hooks/useQueryCacheManager';
import { useFetchedProgramTypes } from '../../program/hooks/useFetchedProgramTypes';
import {
  getCommonFetchStatus,
  getCommonQueryStatus,
  resolveGroupingItemProgramType,
} from '../../program/util';
import {
  DataSeriesName,
  GroupByFieldName,
  GroupingDataItem,
  GroupingDataItems,
  ResolvedGroupingDataItem,
  ResolvedGroupingDataItems,
} from '../types';
import { useFetchedGroupingItems } from './useFetchedGroupingItems';
import { ProgramMultiFiltersDto } from '../analyticsApi';

const resolveGroupingDataItem = (
  groupingItems: GroupingDataItem[],
  types: ProgramType[]
): ResolvedGroupingDataItem[] =>
  groupingItems.map((x) => {
    const type = x.key.typeId ? resolveGroupingItemProgramType(x, types) : null;

    return {
      key: {
        type,
        month: x.key.month,
      },
      value: {
        ...x.value,
      },
    };
  });

type ProgramTypeQuery = {
  data: ProgramTypes | undefined;
  error: unknown;
  status: QueryStatus;
  fetchStatus: FetchStatus;
};

const resolve = (groupingDataItems: GroupingDataItems | undefined, types: ProgramType[]) => {
  const resolvedGroupingDataItems: ResolvedGroupingDataItems | null = groupingDataItems
    ? resolveGroupingDataItem(groupingDataItems, types)
    : null;
  const typesAreResolved =
    !!resolvedGroupingDataItems &&
    !resolvedGroupingDataItems.find((x) => x.key.type?.id === UnknownProgramType.id);

  return { resolvedGroupingDataItems, typesAreResolved };
};

export const useResolvedGroupingItems = (props: {
  fromDate: number;
  toDate: number;
  groupBy: GroupByFieldName[];
  groupOf: DataSeriesName[];
  filter: ProgramMultiFiltersDto | null;
}) => {
  const cacheManager = useQueryCacheManager();
  const {
    data: groupingDataItems,
    error: groupingItemsError,
    status: groupingItemsStatus,
    fetchStatus: groupingItemsFetchStatus,
    isStale: groupingItemsIsStale,
  } = useFetchedGroupingItems(props);

  const {
    data: programTypesData,
    error: typesError,
    status: typesStatus,
    fetchStatus: typesFetchStatus,
  }: ProgramTypeQuery = useFetchedProgramTypes();

  const typeResolvingAttempt = useRef<number>(0);

  const resolutionResults: {
    typesAreResolved: boolean;
    resolvedGroupingDataItems: ResolvedGroupingDataItems | null;
  } = resolve(groupingDataItems, programTypesData?.types ?? []);
  const resultStatus = getCommonQueryStatus([groupingItemsStatus, typesStatus]);
  const resultFetchStatus = getCommonFetchStatus([groupingItemsFetchStatus, typesFetchStatus]);

  const result: QueryData<ResolvedGroupingDataItems | null> = {
    data: resolutionResults.resolvedGroupingDataItems,
    error: groupingItemsError,
    status: resultStatus,
    fetchStatus: resultFetchStatus,
    isStale: groupingItemsIsStale,
  };

  if (typesError) {
    return { ...result, status: resultStatus, error: typesError };
  }

  if (resolutionResults.typesAreResolved) {
    typeResolvingAttempt.current = 0;
    return result;
  }

  if (typeResolvingAttempt.current < 2) {
    if (resultStatus === 'loading') {
      return result; // just return result as is unless all queries are done
    }
    typeResolvingAttempt.current += 1;
    cacheManager.initTypesRefetch();
    return { ...result, status: 'loading' };
  }

  return result;
};
