import { ReactElement, useCallback, useMemo, useState } from 'react';
import { Button } from 'react-bootstrap';
import clsx from 'clsx';

export type SubTabMetadata = {
  id: string;
  hasAccess: boolean;
  dataTest: string;
  title: string | ReactElement;
  body: ReactElement;
};

export type TopLevelTabMetadata = {
  title: string;
  dataTest: string;
  subTabs: SubTabMetadata[];
};

const TopLevelTab = (props: {
  title: string;
  dataTest?: string;
  isSelected?: boolean;
  onClick?: () => void;
}) => {
  const { title, dataTest, isSelected, onClick } = props;
  return (
    <Button
      type="button"
      className={clsx('multilevel-tab-panel__top-level-tab', {
        'multilevel-tab-panel__top-level-tab--selected': isSelected,
      })}
      data-test={dataTest}
      onClick={onClick}
    >
      {title}
    </Button>
  );
};

const SubTab = (props: {
  title: string | ReactElement;
  dataTest: string;
  isSelected: boolean;
  onClick: () => void;
}) => {
  const { title, dataTest, isSelected, onClick } = props;
  return (
    <Button
      type="button"
      data-test={dataTest}
      className={clsx('multilevel-tab-panel__sub-tab', {
        'multilevel-tab-panel__sub-tab--selected': isSelected,
      })}
      onClick={onClick}
    >
      {title}
    </Button>
  );
};

export const MultilevelTabPanel = (props: { tabs: TopLevelTabMetadata[] }) => {
  const { tabs } = props;

  const availableTabs = useMemo(
    () =>
      tabs
        .filter((x) => x.subTabs.some((subTab) => subTab.hasAccess))
        .map((x) => ({ ...x, subTabs: x.subTabs.filter((subTab) => subTab.hasAccess) })),
    [tabs]
  );
  const [selectedTopLevelTab, setSelectedTopLevelTab] = useState(
    availableTabs.length > 0 ? availableTabs[0] : null
  );
  const [selectedSubTab, setSelectedSubTab] = useState(
    selectedTopLevelTab && selectedTopLevelTab.subTabs.length > 0
      ? selectedTopLevelTab.subTabs[0]
      : null
  );

  const onTopLevelTabClick = useCallback(
    (topLevelTabMetadata: TopLevelTabMetadata) => {
      if (selectedTopLevelTab?.title === topLevelTabMetadata.title) return;
      setSelectedTopLevelTab(topLevelTabMetadata);
      if (topLevelTabMetadata.subTabs.length > 0) {
        setSelectedSubTab(topLevelTabMetadata.subTabs[0]);
      } else {
        setSelectedSubTab(null);
      }
    },
    [selectedTopLevelTab]
  );

  const onSubTabClick = useCallback(
    (subTab: SubTabMetadata) => {
      if (selectedSubTab?.title === subTab.title) return;
      setSelectedSubTab(subTab);
    },
    [selectedSubTab]
  );

  return (
    <div className="multilevel-tab-panel__container">
      <div className="multilevel-tab-panel__header">
        <div className="multilevel-tab-panel__top-level-tabs">
          {availableTabs.map((x) => (
            <TopLevelTab
              key={x.title}
              dataTest={x.dataTest}
              title={x.title}
              isSelected={selectedTopLevelTab?.title === x.title}
              onClick={() => onTopLevelTabClick(x)}
            />
          ))}
        </div>
        <div className="multilevel-tab-panel__sub-tabs">
          <div className="multilevel-tab-panel__sub-tab-space" />
          {selectedTopLevelTab?.subTabs
            .filter((x) => x.hasAccess)
            .map((x) => (
              <SubTab
                key={x.id}
                dataTest={x.dataTest}
                title={x.title}
                isSelected={x.title === selectedSubTab?.title}
                onClick={() => onSubTabClick(x)}
              />
            ))}
          <div className="multilevel-tab-panel__sub-tab-space" />
        </div>
        <div className="multilevel-tab-panel__after-tabs">
          <div className="multilevel-tab-panel__top-level-tabs invisible" style={{ height: 0 }}>
            {availableTabs.map((x) => (
              <TopLevelTab key={x.title} title={x.title} />
            ))}
          </div>
          <div id="settings-actions-portal-placeholder" />
        </div>
      </div>
      <div className="multilevel-tab-panel__body">{selectedSubTab?.body}</div>
    </div>
  );
};
