import { ErrorMessage } from 'formik';
import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import { useAsyncDebounce } from 'react-table';

type CustomParamsNumberInputProps = {
  formikFieldName: string;
  initialFieldValue: number;
  disabled: boolean;
  readOnly: boolean;
  isError: boolean;
  dataTest: string;
  handleBlur: (event: React.FocusEvent<HTMLInputElement>) => void;
  className: string;
  errorClassName?: string;
  setFieldValue: (v?: unknown) => void;
  debounceMs: number;
};

export const FormikNumberInput = ({
  formikFieldName,
  initialFieldValue,
  disabled,
  readOnly,
  handleBlur,
  isError,
  dataTest,
  className,
  errorClassName,
  setFieldValue,
  debounceMs,
}: CustomParamsNumberInputProps) => {
  const [currentValue, setCurrentValue] = useState<number | string>(initialFieldValue);
  const onChange = useAsyncDebounce((v) => {
    setFieldValue(v);
  }, debounceMs);

  useEffect(() => {
    setCurrentValue(initialFieldValue ?? '');
  }, [initialFieldValue]);

  function handleChange(v: string) {
    const newValue = v ? +v : '';
    setCurrentValue(newValue);
    onChange(newValue);
  }

  return (
    <div>
      <input
        type="number"
        name={formikFieldName}
        value={currentValue}
        placeholder="Field value"
        disabled={disabled}
        readOnly={readOnly}
        onChange={(e) => handleChange(e.target.value)}
        onBlur={handleBlur}
        className={clsx(className, { 'is-invalid': isError })}
        data-test={dataTest}
      />
      <ErrorMessage
        component="div"
        name={formikFieldName}
        className={errorClassName ?? 'invalid-feedback'}
        data-test={`${dataTest}-validation-error`}
      />
    </div>
  );
};
