import React, { ForwardedRef, forwardRef, useCallback, useEffect, useRef, useState } from 'react';
import { CellProps } from './CellProps';
import {
  ColoredItem,
  DueDateFormulaAnchor,
  ResolvedProgramTask,
  TaskDueDateFormula,
} from '../../types';
import DatePicker from 'react-datepicker';
import clsx from 'clsx';
import {
  ChevronCircleIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
  SmartStarIcon,
} from '../../../assets/icons';
import { DivButton } from '../../../commons/components/DivButton';
import { Button } from 'react-bootstrap';
import moment from 'moment';
import { isUnscheduled } from '../../unscheduledProgramUtil';
import { Info } from '../../../commons/components/Info';
import { StyleVariables } from '../../../commons/styleConstants';
import { ColoredSelect } from '../ColoredSelect';
import { adjustEndDate, recalculateDueDate, recalculateFormula } from './smartDueDateUtils';
import { PROGRAM_TYPE_BACKGROUND_ALPHA } from '../../../program-type/util';
import { hexToRGB } from '../../util';

const DIRECTION_OPTIONS: ColoredItem[] = [
  { name: 'before', id: 'before', color: StyleVariables.black },
  { name: 'on', id: 'on', color: StyleVariables.black },
  { name: 'after', id: 'after', color: StyleVariables.black },
];

const ANCHOR_OPTIONS: ColoredItem[] = [
  { name: 'End date', id: 'PROGRAM_END_DATE', color: StyleVariables.black },
  { name: 'Start date', id: 'PROGRAM_START_DATE', color: StyleVariables.black },
];

const DatePickerCustomInput = forwardRef(
  (props: { formula: TaskDueDateFormula | null }, ref: ForwardedRef<HTMLInputElement>) => {
    const { formula } = props;
    const inputProps = props as React.DetailedHTMLProps<
      React.InputHTMLAttributes<HTMLInputElement>,
      HTMLInputElement
    >;
    return (
      <div>
        <input
          type="text"
          {...inputProps}
          style={{ paddingRight: '30px', ...inputProps.style }}
          ref={ref}
        ></input>{' '}
        {formula && (
          <div className="task-due-date__smart-star-container">
            <span className="task-due-date__smart-star-positioner">{inputProps.value ?? ''}</span>
            <SmartStarIcon
              className="task-due-date__input-smart-star"
              data-test="task-due-date__input-smart-star"
            />
          </div>
        )}
      </div>
    );
  }
);

const formulaToText = (formula: TaskDueDateFormula) => {
  const anchorText = formula.anchor === 'PROGRAM_START_DATE' ? 'start date' : 'end date';
  if (formula.offset === 0) return `on ${anchorText}`;
  const absoluteOffset = Math.abs(formula.offset);
  const offsetText = absoluteOffset === 1 ? '1 day' : `${absoluteOffset} days`;
  const directionText = formula.offset < 0 ? 'before' : 'after';
  return `${offsetText} ${directionText} ${anchorText}`;
};

export const TaskDueDateCell = ({
  value: origValue,
  row,
  arrayHelpers,
  isReadonly,
  onPopoverOpen,
  onPopoverClose,
  programStart,
  programEnd,
  wholeDay,
  programTypeColor,
}: CellProps<ResolvedProgramTask>) => {
  const [value, setValue] = useState<Date | null>(origValue);
  const [formula, setFormula] = useState(row.original.dueDateFormula);
  const [offsetIsEmpty, setOffsetIsEmpty] = useState(false);
  const datePickerRef = useRef<DatePicker>(null);
  const unscheduled = isUnscheduled({ startDateTime: programStart });
  const smartOnly = unscheduled && !!formula;

  const closePopover = () => {
    datePickerRef.current?.setOpen(false);
  };

  const applySmartDueDate = () => {
    confirm(value, formula);
    closePopover();
  };

  const clearFromSmartDueDate = () => {
    setValue(null);
    setFormula(null);
    confirm(null, null);
    closePopover();
  };

  const onDateChange = (date: Date | null) => {
    setValue(date);
    if (!formula) {
      confirm(date, null);
    } else {
      adjustFormula(date, false);
    }
  };

  const onCalendarOpen = () => {
    if (onPopoverOpen) onPopoverOpen();
  };

  const onCalendarClose = () => {
    if (onPopoverClose) onPopoverClose();
    setValue(row.original.dueDateTime);
    setFormula(row.original.dueDateFormula);
  };

  const switchSmartDueDate = (isOn: boolean) => {
    if (isOn) {
      const newValue = unscheduled ? null : value;
      if (value !== newValue) setValue(newValue);
      adjustFormula(newValue, true);
    } else {
      setFormula(null);
    }
  };

  const confirm = (value: Date | null, formula: TaskDueDateFormula | null) => {
    if (
      value?.toISOString() !== row.original.dueDateTime?.toISOString() ||
      formula !== row.original.dueDateFormula
    ) {
      arrayHelpers.replace(Number(row.id), {
        ...row.original,
        dueDateTime: value,
        dueDateFormula: formula,
      });
    }
  };

  const adjustFormula = (value: Date | null, forceUpdate: boolean) => {
    const newFormula = recalculateFormula(
      value,
      programStart,
      programEnd,
      wholeDay,
      formula,
      forceUpdate ? 'to-do' : row.values.status
    );
    setFormula(newFormula);
  };

  useEffect(() => {
    if (!formula) return;
    const recalculatedSmartDueDate = recalculateDueDate(
      programStart,
      programEnd,
      wholeDay,
      formula,
      row.values.status,
      value
    );
    if (recalculatedSmartDueDate?.valueOf() !== value?.valueOf()) {
      setValue(recalculatedSmartDueDate);
    }
  }, [programStart, programEnd, wholeDay, formula, value, row.values.status]);

  const getCalendarDayClassName = (date: Date) => {
    const dateMoment = moment(date);
    const programStartMoment = moment(programStart);
    const programEndMoment = moment(adjustEndDate(programEnd));

    return clsx('task-due-date__calendar-day', {
      'task-due-date__calendar-day--range': dateMoment.isBetween(
        programStartMoment,
        programEndMoment,
        'day',
        '[]'
      ),
      'task-due-date__calendar-day--range-start': dateMoment.isSame(programStartMoment, 'day'),
      'task-due-date__calendar-day--range-end': dateMoment.isSame(programEndMoment, 'day'),
    });
  };

  const getDayBackgroundColor = (date: Date) => {
    const dateMoment = moment(date);
    const programStartMoment = moment(programStart);
    const programEndMoment = moment(adjustEndDate(programEnd));

    return dateMoment.isBetween(programStartMoment, programEndMoment, 'day', '[]')
      ? hexToRGB(programTypeColor, PROGRAM_TYPE_BACKGROUND_ALPHA)
      : undefined;
  };

  const onOffsetChanged = useCallback(
    (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) => {
      if (!formula) return;
      if (!e.target.value) {
        setOffsetIsEmpty(true);
        return;
      }
      const offset = (formula.offset < 0 ? -1 : 1) * (e.target.value ? +e.target.value : 1);
      setFormula({ ...formula, offset });
      setOffsetIsEmpty(false);
    },
    [formula]
  );
  const onDirectionChanged = useCallback(
    (selected: ColoredItem) => {
      if (!formula) return;
      if (selected !== null) {
        setFormula({
          ...formula,
          offset:
            (selected.id === 'before' ? -1 : selected.id === 'on' ? 0 : 1) *
            Math.max(1, Math.abs(formula.offset)),
        });
      }
    },
    [formula]
  );
  const onAnchorChanged = useCallback(
    (selected: ColoredItem) => {
      if (!formula) return;
      if (selected !== null) {
        setFormula({
          ...formula,
          anchor: selected.id as DueDateFormulaAnchor,
        });
      }
    },
    [formula]
  );
  return (
    <div
      className="tasks-table__due-date-cell"
      data-test="tasks-table__task-due-date"
      onKeyDown={(e) => {
        if (e.key === 'Escape' && datePickerRef.current?.isCalendarOpen()) e.stopPropagation();
      }}
    >
      <DatePicker
        ref={datePickerRef}
        calendarClassName={clsx('task-due-date__calendar', {
          'task-due-date__calendar--unscheduled': smartOnly,
        })}
        dateFormat="MMM dd"
        className={clsx(
          'growegy-form__input--no-border growegy-form__placeholder-on-hover task-due-date__input--sized',
          {
            'growegy-form__input--readonly': isReadonly,
            'tasks-table__input--done': value && row.values.status === 'done',
          }
        )}
        openToDate={value ?? undefined}
        shouldCloseOnSelect={!formula}
        selected={value}
        onChange={onDateChange}
        onSelect={onDateChange}
        isClearable={!isReadonly}
        clearButtonClassName="d-none"
        disabledKeyboardNavigation={true}
        popperPlacement="bottom-start"
        readOnly={isReadonly}
        value={smartOnly ? formulaToText(formula) : undefined}
        customInput={<DatePickerCustomInput formula={formula} />}
        onCalendarOpen={onCalendarOpen}
        onCalendarClose={onCalendarClose}
        onClickOutside={() => {
          if (!formula && !!row.original.dueDateFormula) {
            confirm(value, null);
          }
        }}
        onKeyDown={(e) => {
          if (e.key === 'Enter') {
            confirm(value, formula);
          }
        }}
        placeholderText={isReadonly || formula ? '' : 'Add due date'}
        weekDayClassName={() => (smartOnly ? 'd-none' : '')}
        renderDayContents={(dayOfMonth, date) => {
          if (!date) return undefined;
          return (
            <div
              className={smartOnly ? 'd-none' : getCalendarDayClassName(date)}
              style={{ backgroundColor: getDayBackgroundColor(date) }}
            >
              {date && date.valueOf() === value?.valueOf() ? (
                <div className="task-due-date__selected-day">{dayOfMonth}</div>
              ) : (
                dayOfMonth
              )}
            </div>
          );
        }}
        renderCustomHeader={({ date, increaseMonth, decreaseMonth, changeMonth, changeYear }) =>
          !smartOnly && (
            <div className="task-due-date__navigation-panel">
              <span
                className="task-due-date__dates-navigator--month-name"
                data-test="date-picker__month-year"
              >
                {`${moment(date).format('MMMM YYYY')}`}
              </span>
              <div className="dates-navigator__panel">
                <DivButton
                  icon={<ChevronLeftIcon />}
                  onClick={decreaseMonth}
                  small={true}
                  dataTest="date-picker__prev"
                />
                <DivButton
                  icon={<ChevronCircleIcon />}
                  onClick={() => {
                    const today = new Date();
                    changeMonth(today.getMonth());
                    changeYear(today.getFullYear());
                  }}
                  small={true}
                  dataTest="date-picker__today"
                />
                <DivButton
                  icon={<ChevronRightIcon />}
                  onClick={increaseMonth}
                  small={true}
                  dataTest="date-picker__next"
                />
              </div>
            </div>
          )
        }
      >
        <div className="smart-due-date__container">
          <div className="switch-input-container w-100">
            <input
              type="checkbox"
              className="custom-control-input"
              id="smart-due-date-enabled-checkbox"
              onChange={(e) => switchSmartDueDate(e.target.checked)}
              checked={!!formula}
              data-test="smart-due-date__checkbox"
            />
            <label
              className="custom-control-label calendar-display-settings__check-label"
              htmlFor="smart-due-date-enabled-checkbox"
              data-test="smart-due-date__switch"
            >
              <div>
                <div className="d-flex align-items-center flex-row" style={{ columnGap: '2px' }}>
                  <div>Smart due date</div>
                  <Info
                    tooltipText="Automatically move the task's due date when you reschedule your program"
                    tooltipWidth={176}
                  />
                </div>
                {row.values.status === 'done' && !!formula && (
                  <div
                    className="d-flex align-items-center flex-row flex-wrap"
                    style={{ columnGap: '2px', maxWidth: '300px' }}
                  >
                    <span>The Smart due date sync works only for unchecked tasks</span>
                  </div>
                )}
              </div>
            </label>
          </div>
          {formula && (
            <>
              <div className="smart-due-date__settings-container">
                <input
                  type="text"
                  pattern="\d+"
                  maxLength={4}
                  value={offsetIsEmpty ? '' : Math.abs(formula.offset)}
                  onBlur={() => setOffsetIsEmpty(false)}
                  onChange={onOffsetChanged}
                  className={clsx('smart-due-date__offset', {
                    invisible: formula.offset === 0,
                  })}
                  data-test="smart-due-date__offset"
                />
                <span
                  className={clsx('smart-due-date__days-label', {
                    invisible: formula.offset === 0,
                  })}
                >
                  days
                </span>
                <ColoredSelect
                  initialValue={
                    DIRECTION_OPTIONS.find(
                      (x) =>
                        x.id ===
                        (formula.offset < 0 ? 'before' : formula.offset === 0 ? 'on' : 'after')
                    ) ?? DIRECTION_OPTIONS[0]
                  }
                  coloredItems={DIRECTION_OPTIONS}
                  onValueChange={onDirectionChanged}
                  isSearchable={false}
                  dataTestPrefix="smart-due-date-direction"
                  width={88}
                />
                <ColoredSelect
                  initialValue={
                    ANCHOR_OPTIONS.find((x) => x.id === formula.anchor) ?? ANCHOR_OPTIONS[0]
                  }
                  coloredItems={ANCHOR_OPTIONS}
                  onValueChange={onAnchorChanged}
                  isSearchable={false}
                  dataTestPrefix="smart-due-date-anchor"
                  width={112}
                />
              </div>
              <div className="smart-due-date__buttons-container">
                <DivButton
                  onClick={clearFromSmartDueDate}
                  isGray={true}
                  className="div-button--cancel"
                  dataTest="smart-due-date__clear"
                  text="Clear"
                />
                <Button
                  onClick={applySmartDueDate}
                  variant="primary"
                  className="button-apply"
                  data-test="smart-due-date__apply"
                >
                  Set
                </Button>
              </div>
            </>
          )}
        </div>
      </DatePicker>
    </div>
  );
};
