import {
  ChatMessage,
  ResolvedChatMessage,
  CLIENT_MESSAGE_SENDER,
  AI_MESSAGE_SENDER,
} from '../../types';
import { User } from '../../../user/types';
import React, { ForwardedRef, forwardRef } from 'react';
import { InlineSpinner } from '../../../commons/components/Spinner';
import { TemplateMessage } from './TemplateMessage';
import { Button } from 'react-bootstrap';
import { FooterError } from '../../../commons/components/FooterError';
import { StyleVariables } from '../../../commons/styleConstants';
import { PromptWithPlaceholders } from '../../library/components/PromptWithPlaceholders';
import { ChatType } from '../../dto';
import { CreateProgramsMessageContent } from '../../../program/components/ai/CreateProgramsMessageContent';
import { CreateProgramsResultMessageContent } from '../../../program/components/ai/CreateProgramsResultMessageContent';
import Markdown from 'react-markdown';
import remarkGfm from 'remark-gfm';

export const TextMessage = forwardRef(
  (
    props: {
      chatType: ChatType;
      message: ResolvedChatMessage;
      user: User;
      onSendAgain: (message: ChatMessage) => void;
      maxAiResponses: number;
    },
    ref: ForwardedRef<HTMLDivElement>
  ) => {
    const { chatType, message, user, onSendAgain, maxAiResponses } = props;
    const isTokenLimitError =
      message.errorMessage && message.errorMessage.indexOf('token limit') > 0;
    return (
      <TemplateMessage
        sender={message.sender === CLIENT_MESSAGE_SENDER ? user : AI_MESSAGE_SENDER}
        chatType={chatType}
        ref={ref}
      >
        <div className="message-list__message-content">
          {message.isPlaceholder ? (
            <div
              className="message-list__message-loading"
              data-test="message-list__message-loading"
            >
              Working on it…
              <InlineSpinner />
            </div>
          ) : (
            <div>
              <div
                style={{
                  color: message.sendFailed ? StyleVariables.neutral500 : undefined,
                }}
                data-test="message-list__message-content"
              >
                {message.sender === CLIENT_MESSAGE_SENDER && message.wizardStepNumber === 0 ? (
                  <PromptWithPlaceholders
                    libraryPromptContent={message.content ? message.content : ''}
                  />
                ) : message.resolvedToolCalls && message.resolvedToolCalls.length > 0 ? (
                  <CreateProgramsMessageContent message={message} />
                ) : message.resolvedToolCallResults &&
                  message.resolvedToolCallResults.length > 0 ? (
                  <CreateProgramsResultMessageContent message={message} />
                ) : message.sender === AI_MESSAGE_SENDER ? (
                  <Markdown
                    className="message-list__message-ai-sub-content"
                    remarkPlugins={[remarkGfm]}
                  >
                    {message.content}
                  </Markdown>
                ) : (
                  message.content
                )}
              </div>
              <div className="d-flex justify-content-end">
                {message.sender === 'ai' && message.aiMessageNumber !== undefined && (
                  <span className="growegy-font12--weight800">{`${message.aiMessageNumber} of ${maxAiResponses}`}</span>
                )}
              </div>
              {message.sendFailed && (
                <div className="d-flex flex-row" style={{ columnGap: '36px', marginTop: '8px' }}>
                  <FooterError
                    message={
                      isTokenLimitError && message.errorMessage
                        ? message.errorMessage
                        : 'This message was not sent.'
                    }
                    dataTest="message-list__error-message"
                  />
                  {!isTokenLimitError && (
                    <Button
                      className="button-apply"
                      onClick={() => onSendAgain(message)}
                      data-test="message-list__send-again-message"
                    >
                      Send again
                    </Button>
                  )}
                </div>
              )}
            </div>
          )}
        </div>
      </TemplateMessage>
    );
  }
);
