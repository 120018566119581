import { useFetchedChatMessages } from './useFetchedChatMessages';
import { useFetchedProgramTypes } from '../../../program/hooks/useFetchedProgramTypes';
import { useFetchedUsers } from '../../../user/hooks/useFetchedUsers';
import {
  AI_MESSAGE_SENDER,
  ChatMessage,
  ResolvedChatMessage,
  ResolvedToolCall,
  ResolvedToolCallResult,
  TOOL_CREATE_PROGRAMS,
  ToolCall,
  ToolCallResult,
} from '../../types';
import {
  normalizeCreateProgramArgument,
  resolveCreateProgramToolCallResult,
} from '../../../program/components/ai/util';
import { ProgramType } from '../../../program/types';
import { User } from '../../../user/types';
import { useMemo } from 'react';

const resolveToolCall = (
  toolCall: ToolCall,
  programTypes: ProgramType[] | undefined,
  users: User[] | undefined
): ResolvedToolCall[] =>
  toolCall.name !== TOOL_CREATE_PROGRAMS
    ? []
    : [
        {
          id: toolCall.id,
          name: toolCall.name,
          arguments: {
            programs: toolCall.arguments.new_programs.map((x) =>
              normalizeCreateProgramArgument(x, programTypes ?? [], users ?? [])
            ),
          },
        },
      ];

const resolveToolCallResult = (
  toolCallResult: ToolCallResult,
  programTypes: ProgramType[] | undefined,
  users: User[] | undefined
): ResolvedToolCallResult[] => {
  return toolCallResult.name !== TOOL_CREATE_PROGRAMS
    ? []
    : [
        {
          id: toolCallResult.id,
          name: toolCallResult.name,
          content: {
            user_result: toolCallResult.content.user_result,
            created_programs: toolCallResult.content.created_programs.map((x) =>
              resolveCreateProgramToolCallResult(x, programTypes ?? [], users ?? [])
            ),
          },
        },
      ];
};

export const useResolvedChatMessages = (chatId: string, canBeStale: boolean) => {
  const { query: messagesQuery, queryStatusComponent } = useFetchedChatMessages(chatId, canBeStale);

  const { data: programTypes } = useFetchedProgramTypes();
  const { data: users } = useFetchedUsers();
  const resolvedMessages: ResolvedChatMessage[] | undefined = useMemo(() => {
    let aiMessageNumber = 0;
    return messagesQuery.data
      ?.filter((x) => !x.isCancelled)
      .flatMap((x) => {
        const resolvedToolCallResults = x.toolCallResults?.flatMap((x) =>
          resolveToolCallResult(x, programTypes?.types, users)
        );
        return !resolvedToolCallResults ||
          resolvedToolCallResults.length === 0 ||
          x.sender === AI_MESSAGE_SENDER
          ? [
              {
                ...x,
                resolvedToolCalls: x.toolCalls?.flatMap((x) =>
                  resolveToolCall(x, programTypes?.types, users)
                ),
                resolvedToolCallResults: resolvedToolCallResults,
                aiMessageNumber:
                  x.sender === AI_MESSAGE_SENDER ? (aiMessageNumber += 1) : undefined,
              },
            ]
          : [
              { ...x, toolCallResults: undefined } as ChatMessage,
              {
                ...x,
                id: `${x.id}_tool-call-result`,
                clientId: `${x.clientId}_tool-call-result`,
                content: '',
                timestamp: x.timestamp,
                sender: AI_MESSAGE_SENDER,
                promptId: null,
                toolCallResults: x.toolCallResults,
                resolvedToolCallResults,
                aiMessageSender: (aiMessageNumber += 1),
              },
            ];
      });
  }, [messagesQuery.data, programTypes, users]);
  return { query: { ...messagesQuery, data: resolvedMessages }, queryStatusComponent };
};
