import DatePicker from 'react-datepicker';
import { DATE_FORMAT } from '../../analytics/formats';

export const CustomDateRangeSelector = (props: {
  fromDate: Date;
  toDate: Date;
  onDatesChanged: (fromDate: Date, toDate: Date) => void;
}) => {
  const { fromDate, toDate, onDatesChanged } = props;
  return (
    <div className="analytics__date-range-container">
      <div data-test="analytics__from-date" style={{ display: 'inline-block' }}>
        <DatePicker
          id="fromDatePicker"
          showTimeSelect={false}
          dateFormat={DATE_FORMAT}
          selected={fromDate}
          onChange={(d) => {
            if (d instanceof Date) {
              onDatesChanged(d, toDate);
            }
          }}
          className="analytics__date-picker"
        />
      </div>
      <label className="analytics__to-label">to</label>
      <div data-test="analytics__to-date" style={{ display: 'inline-block' }}>
        <DatePicker
          id="toDatePicker"
          showTimeSelect={false}
          dateFormat={DATE_FORMAT}
          selected={toDate}
          onChange={(d) => {
            if (d instanceof Date) {
              onDatesChanged(fromDate, d);
            }
          }}
          minDate={fromDate}
          className="analytics__date-picker"
        />
      </div>
    </div>
  );
};
