import React from 'react';

const Spinner = (props: { size: 'big' | 'small' | 'full-screen' }) => {
  const { size } = props;
  return (
    <div
      className={
        size === 'big'
          ? 'growegy-spinner--big'
          : size === 'small'
          ? 'growegy-spinner'
          : 'growegy-spinner--full-screen'
      }
      role="status"
    >
      <span className="sr-only">Loading...</span>
    </div>
  );
};

export const InlineSpinner = () => <Spinner size="small" />;
export const BoxSpinner = () => (
  <div className="growegy-spinner_container--box">
    <Spinner size="big" />
  </div>
);
export const FullScreenSpinner = () => <Spinner size="full-screen" />;
