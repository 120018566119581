import React, { MouseEvent } from 'react';
import clsx from 'clsx';
import { PlusIcon } from '../../assets/icons';

export const PlusButton = ({
  isSelected,
  onClick,
  dataTest,
}: {
  isSelected?: boolean;
  onClick?: (e: MouseEvent<HTMLDivElement>) => void;
  dataTest?: string;
}) => (
  <div
    tabIndex={0}
    role="button"
    aria-label="Add new program"
    onClick={onClick}
    data-test={dataTest}
    className="plus-button--container"
  >
    <div
      className={clsx('plus-button', {
        'plus-button--selected': isSelected,
      })}
    >
      <div className="plus-button__bottom-layer">
        <div className="plus-button__top-layer">
          <PlusIcon className="plus-button__icon" />
        </div>
      </div>
    </div>
  </div>
);
