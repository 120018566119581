import { Messenger } from '../../../growegy-ai/chat/components/Messenger';
import React, { useEffect, useRef, useState } from 'react';
import { ArrowDown, CalendarWithSolidSpark, ChevronLeftIcon } from '../../../assets/icons';
import { useProgramsChatContext } from '../../hooks/useProgramsChatContext';
import { DivButton } from '../../../commons/components/DivButton';
import { IconWithGradientBackground } from '../../../commons/components/IconWithGradientBackground';
import {
  CHAT_REALM_PROGRAMS,
  ChatRealmContextProvider,
} from '../../../growegy-ai/chat/chatRealmContext';
import { useChatContext } from '../../../growegy-ai/chat/chatContext';
import { ChatList } from '../../../growegy-ai/chat/components/ChatList';
import { CHAT_TYPE_CREATE_PROGRAM } from '../../../growegy-ai/dto';
import clsx from 'clsx';
import { useForceRerender } from '../../../commons/hooks/useForceRerender';

export const ProgramsChat = () => (
  <ChatRealmContextProvider realm={CHAT_REALM_PROGRAMS}>
    <ProgramsChatInternal />
  </ChatRealmContextProvider>
);

const ProgramsChatInternal = () => {
  const { getCurrentChat, setCurrentChat } = useChatContext();
  const [chat, setChat] = useState(getCurrentChat());
  const [expanded, setExpanded] = useState(false);
  const programsChatContext = useProgramsChatContext();
  const gptModelSelectDiv = useRef<HTMLDivElement | null>(null);
  const [view, setView] = useState<'chat' | 'chat-list'>('chat');
  const forceRerender = useForceRerender();
  useEffect(forceRerender, [forceRerender, expanded]);

  return (
    <>
      {expanded && (
        <div className="messenger__popup">
          <div className="messenger__popup-container">
            <div className={clsx('messenger__popup-header', { 'd-none': view !== 'chat' })}>
              <DivButton
                className="messenger__popup-back-button"
                dataTest="messenger__popup-back-button"
                icon={<ChevronLeftIcon />}
                onClick={() => setView('chat-list')}
              />
              <div className="messenger__popup-chat-title">{chat.title}</div>
              <div
                ref={gptModelSelectDiv}
                className="gpt-model-selector__wrapper"
                id="chat-model-select-portal-placeholder"
              />
            </div>
            {view === 'chat' ? (
              <>
                <div className="flex-grow-1 overflow-auto">
                  <Messenger
                    chat={chat}
                    systemMessage={programsChatContext}
                    slimGptModelSelect={true}
                    gptModelSelectDiv={gptModelSelectDiv.current}
                    onChatCreated={(chat) => {
                      setCurrentChat(chat);
                      setChat(chat);
                    }}
                    onOpenLibraryClick={() => {}}
                    onNewChatClick={() => {}}
                  />
                </div>
              </>
            ) : (
              <ChatList
                chatType={CHAT_TYPE_CREATE_PROGRAM}
                selectedChatId={getCurrentChat().id}
                onChatSelected={(chat) => {
                  setCurrentChat(chat);
                  setChat(chat);
                  setView('chat');
                }}
              />
            )}
          </div>
        </div>
      )}
      <div className="messenger__popup-toggle">
        <IconWithGradientBackground
          icon={
            expanded ? (
              <ArrowDown className="growegy-ai__floating-button-icon" />
            ) : (
              <CalendarWithSolidSpark className="growegy-ai__floating-button-icon" />
            )
          }
          isButton={true}
          onClick={() => setExpanded(!expanded)}
          dataTest="calendar__ai-toggle"
        />
      </div>
    </>
  );
};
