import _ from 'lodash';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Button, Form, Overlay, Popover } from 'react-bootstrap';
import { FieldArray, Formik } from 'formik';
import {
  ExistedCustomFields,
  ProgramCustomFieldMeta,
  ProgramType,
  ResolvedCampaign,
  ResolvedProgramSummary,
} from '../types';
import { MultiFilterRows, MultiFilterRowsPersistable } from '../../multifilter/types';
import { ProgramsMultiFilterRow } from './ProgramsMultiFilterRow';
import { User } from '../../user/types';
import {
  newProgramFilterRow,
  realFilterToPersistable,
  restoreProgramFilterRow,
  SelectedFilter,
} from '../../multifilter/programFiltering';
import {
  BinIcon,
  CloseIcon,
  CopyIcon,
  DividerIcon,
  FilterIcon,
  FilterWithPlusIcon,
  PencilIcon,
  PlusIcon,
} from '../../assets/icons';
import { analyticsTrack } from '../../web-analytics/webAnalytics';
import { AnalyticsEvent } from '../../web-analytics/AnalyticsEvent';
import useAnalytics from '../../web-analytics/webAnalyticsContext';
import { DivButton } from '../../commons/components/DivButton';
import clsx from 'clsx';
import { ButtonColored } from './ButtonColored';
import { useMemoCompare } from '../../hooks/useMemoCompare';
import { useFetchedUsers } from '../../user/hooks/useFetchedUsers';
import { useFetchedProgramTypes } from '../hooks/useFetchedProgramTypes';
import { useFetchedAllCustomFields } from '../hooks/useFetchedAllCustomFields';
import { areCustomFieldsSame, extractExistedCustomFields } from '../util';
import { useResolvedCampaigns } from '../hooks/useResolvedCampaigns';
import { MoreMenu } from '../../commons/components/MoreMenu';

const notNullSelectedFilter: (
  f: SelectedFilter | null,
  filterRows: MultiFilterRowsPersistable<ResolvedProgramSummary>
) => SelectedFilter = (
  f: SelectedFilter | null,
  filterRows: MultiFilterRowsPersistable<ResolvedProgramSummary>
) => {
  if (f === null) return { type: 'NewFilter', filter: filterRows };
  return { ...f, filter: filterRows };
};

export type ProgramsMultiFilterProps = {
  persistedFilters: MultiFilterRowsPersistable<ResolvedProgramSummary>[];
  appliedFilter: SelectedFilter | null;
  applyFilter: (filters: SelectedFilter | null) => void;
  persistFilters: (filters: MultiFilterRowsPersistable<ResolvedProgramSummary>[]) => void;
  noFormula: boolean;
};

const persistableToReal: ({
  filter,
  existedUsers,
  existedTypes,
  existedCampaigns,
}: {
  filter: MultiFilterRowsPersistable<ResolvedProgramSummary>;
  existedUsers: User[];
  existedTypes: ProgramType[];
  existedCampaigns: ResolvedCampaign[];
}) => MultiFilterRows<ResolvedProgramSummary> & {
  name: string | null;
} = ({
  filter,
  existedUsers,
  existedTypes,
  existedCampaigns,
}: {
  filter: MultiFilterRowsPersistable<ResolvedProgramSummary>;
  existedUsers: User[];
  existedTypes: ProgramType[];
  existedCampaigns: ResolvedCampaign[];
}) => {
  const { multiFilterCustomName, filters, conjunction } = filter;

  const multiFilterRows = filters.map((row) =>
    restoreProgramFilterRow(row, existedUsers, existedTypes, existedCampaigns)
  );

  return { filters: multiFilterRows, conjunction, name: multiFilterCustomName };
};

const realToPersistable: (
  filter: MultiFilterRows<ResolvedProgramSummary> & {
    name: string | null;
  }
) => MultiFilterRowsPersistable<ResolvedProgramSummary> = (
  f: MultiFilterRows<ResolvedProgramSummary> & {
    name: string | null;
  }
) => {
  const { name, ...filter } = f;
  return realFilterToPersistable({ filter, name: name ?? '' });
};

const FilterForm = ({
  initialValues,
  handleClearClick,
  handleApplyClick,
  handleSaveClick,
  existedUsers,
  existedTypes,
  customFields,
  existedCampaigns,
}: {
  initialValues: {
    filter: MultiFilterRowsPersistable<ResolvedProgramSummary>;
    mode: 'Apply' | 'Save';
  };
  handleClearClick: () => void;
  handleApplyClick: (filters: MultiFilterRowsPersistable<ResolvedProgramSummary>) => void;
  handleSaveClick: (filter: MultiFilterRowsPersistable<ResolvedProgramSummary>) => void;
  existedUsers: User[] | undefined;
  existedTypes: ProgramType[] | undefined;
  existedCampaigns: ResolvedCampaign[] | null;
  customFields: ProgramCustomFieldMeta[] | undefined;
}) => {
  const analytics = useAnalytics();
  const [mode, setMode] = useState<'Apply' | 'Save'>(initialValues.mode);
  useEffect(() => setMode(initialValues.mode), [initialValues.mode]);
  const existedCustomFields = useMemoCompare<ExistedCustomFields>(
    extractExistedCustomFields([{ customFields: customFields ?? [] }]),
    areCustomFieldsSame
  );
  const initDataWithDeps = useMemoCompare(
    {
      filter: initialValues.filter,
      existedUsers: existedUsers ?? [],
      existedTypes: existedTypes ?? [],
      existedCampaigns: existedCampaigns ?? [],
    },
    (a, b) => _.isEqual(a, b)
  );

  const formikInitialValues: MultiFilterRows<ResolvedProgramSummary> & {
    name: string | null;
  } = useMemo(() => persistableToReal(initDataWithDeps), [initDataWithDeps]);
  const inputRef = useRef<HTMLInputElement>(null);
  useEffect(() => {
    if (!inputRef.current) return;
    if (mode === 'Save') inputRef.current?.focus();
  }, [mode]);
  return (
    <Formik
      initialValues={formikInitialValues}
      onSubmit={(filter) => {
        if (mode === 'Save' && filter.name) {
          handleSaveClick(realToPersistable(filter));
        }
        if (mode === 'Apply') {
          handleApplyClick(realToPersistable(filter));
        }
      }}
      enableReinitialize={true}
    >
      {({ touched, errors, handleSubmit, handleBlur, values, setFieldValue }) => {
        if (values.filters.length === 0) {
          setFieldValue('filters', [newProgramFilterRow()]);
        }
        return (
          <Form onSubmit={handleSubmit} className="container">
            <FieldArray name="filters">
              {(arrayHelpers) => (
                <div>
                  <div className="programs-filter__header">
                    {mode === 'Apply' && (
                      <input
                        type="text"
                        value={values.name ? values.name : 'Showing programs'}
                        placeholder="Add filter name"
                        disabled={true}
                        onChange={async (e) => setFieldValue('name', e.target.value, true)}
                        className={clsx('programs-filter__header-title pl-0 w-100')}
                      />
                    )}
                    {mode === 'Save' && (
                      <input
                        ref={inputRef}
                        type="text"
                        value={values.name ?? ''}
                        placeholder="Add filter name"
                        data-test="programs-filter__name"
                        onChange={async (e) => setFieldValue('name', e.target.value, true)}
                        className={clsx('programs-filter__header-title pl-0 w-100')}
                      />
                    )}
                  </div>
                  <div className="programs-filter__body">
                    {values.filters.map((filter, index) => (
                      <ProgramsMultiFilterRow
                        key={filter.renderId}
                        filteringRowData={filter}
                        conjunctionData={values.conjunction}
                        index={index}
                        disabled={false}
                        errors={errors}
                        touched={touched}
                        arrayHelpers={arrayHelpers}
                        handleBlur={handleBlur}
                        setFieldValue={setFieldValue}
                        existedUsers={existedUsers || []}
                        existedTypes={existedTypes || []}
                        existedCustomFields={existedCustomFields}
                        existedCampaigns={existedCampaigns || []}
                      />
                    ))}
                    <div style={{ marginTop: '10px', marginBottom: '10px' }}>
                      <DivButton
                        isGray={true}
                        icon={<PlusIcon />}
                        onClick={() => {
                          analyticsTrack(analytics, AnalyticsEvent.PROGRAM_FILTER_ADDED);
                          arrayHelpers.push(newProgramFilterRow());
                        }}
                        text="Add condition"
                        dataTest="programs-filter__add"
                        className="programs-filter__add"
                      />
                    </div>
                  </div>
                  <div className="programs-filter__footer">
                    <div className="d-flex justify-content-between">
                      <div className="d-flex">
                        {mode === 'Apply' && (
                          <ButtonColored
                            dataTest="programs-filter__save"
                            onClick={() => setMode('Save')}
                            text="Save"
                            icon={<FilterWithPlusIcon />}
                          />
                        )}
                      </div>
                      <div className="d-flex">
                        <DivButton
                          onClick={() =>
                            mode === 'Apply'
                              ? setTimeout(() => handleClearClick(), 200)
                              : setMode('Apply')
                          }
                          isGray={true}
                          dataTest="programs-filter__clear"
                          className="programs-filter__clear"
                          text={mode === 'Apply' ? `Clear` : `Cancel`}
                        />
                        <Button
                          type="submit"
                          variant="primary"
                          className="button-apply"
                          disabled={mode === 'Save' && !values.name}
                          data-test="programs-filter__apply"
                        >
                          {mode === 'Apply' ? `Apply filter` : `Save filter`}
                        </Button>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </FieldArray>
          </Form>
        );
      }}
    </Formik>
  );
};

const PersistedFilters = (props: {
  persisted: { name: string; applied: boolean }[];
  onApply: (v: { type: 'PersistedFilter'; index: number } | { type: 'NewFilter' }) => void;
  onEdit: (v: { index: number }) => void;
  onDuplicate: (v: { index: number }) => void;
  onSelect: (v: { index: number }) => void;
  onDelete: (v: { index: number }) => void;
}) =>
  props.persisted.length === 0 ? null : (
    <div className="d-flex flex-column">
      {props.persisted.map(({ name, applied }, index) => (
        <DivButton
          key={`${name}-${applied}-${index}`}
          onClick={() => props.onSelect({ index })}
          className="programs-filter__persisted"
          dataTest={`programs-filter__persisted--${name}`}
          icon={<FilterIcon />}
          text={name}
          isSelected={applied}
          menu={
            <MoreMenu
              menuItems={[
                {
                  type: 'standard',
                  text: 'Edit',
                  icon: <PencilIcon />,
                  dataTest: 'programs-filter__persisted-filter-menu--edit',
                  onClick: () => props.onEdit({ index }),
                },
                {
                  type: 'standard',
                  text: 'Duplicate',
                  icon: <CopyIcon />,
                  dataTest: 'programs-filter__persisted-filter-menu--duplicate',
                  onClick: () => props.onDuplicate({ index }),
                },
                {
                  type: 'standard',
                  text: 'Delete',
                  icon: <BinIcon />,
                  dataTest: 'programs-filter__persisted-filter-menu--delete',
                  onClick: () => props.onDelete({ index }),
                },
              ]}
              menuButtonDataTest="programs-filter__persisted-filter-menu"
            />
          }
        />
      ))}
      <div className="programs-filter__add-new-container">
        <DivButton
          className="programs-filter__add-new"
          onClick={() => props.onApply({ type: 'NewFilter' })}
          icon={<PlusIcon />}
          text="Add new"
        />
      </div>
    </div>
  );
export const ProgramsMultiFilter = ({
  persistedFilters,
  appliedFilter,
  applyFilter,
  persistFilters,
  noFormula,
}: ProgramsMultiFilterProps) => {
  const analytics = useAnalytics();
  const existedUsers = useFetchedUsers();
  const existedTypes = useFetchedProgramTypes();
  const customFields = useFetchedAllCustomFields();
  const existedCampaigns = useResolvedCampaigns();
  const [show, setShow] = useState(false);
  const [editableFilter, setEditableFilter] = useState<SelectedFilter | null>(null);
  const target = useRef(null);

  const showButtonClick = () => {
    if (!show) {
      analyticsTrack(analytics, AnalyticsEvent.PROGRAM_FILTER_OPENED);
      if (appliedFilter) {
        duplicate(appliedFilter.filter, false);
      } else if (!persistedFilters.length) {
        setEditableFilter(
          (f) =>
            f ?? {
              type: 'NewFilter',
              filter: { filters: [], conjunction: 'And', multiFilterCustomName: '' },
            }
        );
      } else {
        setEditableFilter(null);
      }
    }
    setShow((s) => !s);
  };
  const onSelectPersisted = (index: number) => {
    if (index >= persistedFilters.length) return;
    const filter: MultiFilterRowsPersistable<ResolvedProgramSummary> = persistedFilters[index];
    setShow(true);
    setEditableFilter({ type: 'PersistedFilter', index, filter });
  };

  const onDeletePersisted = (index: number) => {
    if (index >= persistedFilters.length) return;
    const newPersistedFilters = [...persistedFilters];
    newPersistedFilters.splice(index, 1);
    persistFilters(newPersistedFilters);
    if (editableFilter?.type === 'PersistedFilter' && editableFilter.index === index) {
      setEditableFilter(null);
    }
    if (appliedFilter?.type === 'PersistedFilter' && appliedFilter.index === index) {
      applyFilter(null);
    }
    if (!newPersistedFilters.length) {
      setShow(false);
    }
  };

  const duplicate = (
    filter: MultiFilterRowsPersistable<ResolvedProgramSummary>,
    isNameSpecified: boolean
  ) => {
    const copyFilter = {
      ..._.cloneDeep(filter),
      multiFilterCustomName: isNameSpecified ? `${filter.multiFilterCustomName} (Copy)` : '',
    };
    setEditableFilter({ type: 'NewFilter', filter: copyFilter });
  };

  const onDuplicatePersisted = (index: number, isNameSpecified: boolean) => {
    if (index >= persistedFilters.length) return;
    const persistedFilter = persistedFilters[index];
    duplicate(persistedFilter, isNameSpecified);
  };
  const onApplyPersistedOrNew = (
    f: { type: 'PersistedFilter'; index: number } | { type: 'NewFilter' }
  ) => {
    switch (f.type) {
      case 'PersistedFilter': {
        if (f.index >= persistedFilters.length) return;
        const filter = persistedFilters[f.index];
        applyFilter({ type: 'PersistedFilter', index: f.index, filter });
        setShow(false);
        break;
      }
      case 'NewFilter':
        setEditableFilter(
          (f) =>
            f ?? {
              type: 'NewFilter',
              filter: { filters: [], conjunction: 'And', multiFilterCustomName: '' },
            }
        );
        break;
      default:
        break;
    }
  };
  const handleClearClick = () => {
    applyFilter(null);
    setEditableFilter(null);
    setShow(false);
  };
  const handleApplyClick = (filter: MultiFilterRowsPersistable<ResolvedProgramSummary>) => {
    applyFilter(notNullSelectedFilter(editableFilter, filter));
    setEditableFilter(null);
    setShow(false);
  };
  const handleSaveClick = (filter: MultiFilterRowsPersistable<ResolvedProgramSummary>) => {
    const prevPersisted = [...persistedFilters];
    if (editableFilter?.type === 'PersistedFilter') {
      prevPersisted[editableFilter.index] = filter;
    } else {
      prevPersisted.push(filter);
    }
    persistFilters(prevPersisted);
    applyFilter({
      type: 'PersistedFilter',
      filter,
      index:
        editableFilter?.type === 'PersistedFilter'
          ? editableFilter.index
          : prevPersisted.length - 1,
    });
    setEditableFilter(null);
    setShow(false);
  };
  return (
    <>
      <div ref={target}>
        <DivButton
          text={`Filter${
            appliedFilter?.filter.filters.length ? ` · ${appliedFilter?.filter.filters.length}` : ''
          }`}
          icon={<FilterIcon />}
          onClick={showButtonClick}
          dataTest="programs-filter__button"
          textDataTest="programs-filter__button-title"
          className={clsx({
            'programs-filter-button--highlighted': !!appliedFilter,
          })}
          menu={
            <>
              {appliedFilter?.filter.filters.length && (
                <div className="d-flex">
                  <DividerIcon className="programs-filter__separator" />
                  <div
                    className="programs-filter__close-btn"
                    data-test="programs-filter__close-btn"
                    onClick={(e) => {
                      e.stopPropagation();
                      handleClearClick();
                    }}
                  >
                    <CloseIcon />
                  </div>
                </div>
              )}
            </>
          }
        />
      </div>
      <Overlay
        show={show && !!editableFilter}
        target={target.current}
        placement="bottom-start"
        rootClose
        onHide={() => {
          setShow(false);
        }}
      >
        <Popover id="popover-contained" className="programs-filter">
          <Popover.Content>
            <FilterForm
              existedTypes={existedTypes.data?.types}
              existedUsers={existedUsers.data}
              existedCampaigns={existedCampaigns.data}
              customFields={customFields.data?.filter(({ type }) =>
                noFormula ? type !== 'formula' : true
              )}
              handleClearClick={handleClearClick}
              handleApplyClick={handleApplyClick}
              handleSaveClick={handleSaveClick}
              initialValues={{
                filter: editableFilter?.filter ?? {
                  filters: [],
                  conjunction: 'And',
                  multiFilterCustomName: '',
                },
                mode: 'Apply',
              }}
            />
          </Popover.Content>
        </Popover>
      </Overlay>
      <Overlay
        show={show && !editableFilter}
        target={target.current}
        placement="bottom-start"
        rootClose
        onHide={() => {
          setShow(false);
        }}
      >
        <Popover id="popover-contained" className="programs-filter">
          <Popover.Content>
            <PersistedFilters
              persisted={persistedFilters.map(({ multiFilterCustomName }, index) => ({
                name: multiFilterCustomName,
                applied: appliedFilter?.type === 'PersistedFilter' && appliedFilter.index === index,
              }))}
              onEdit={({ index }) => onSelectPersisted(index)}
              onDelete={({ index }) => onDeletePersisted(index)}
              onDuplicate={({ index }) => onDuplicatePersisted(index, true)}
              onSelect={({ index }) => onDuplicatePersisted(index, false)}
              onApply={(f) => onApplyPersistedOrNew(f)}
            />
          </Popover.Content>
        </Popover>
      </Overlay>
    </>
  );
};
