/* eslint-disable react-hooks/rules-of-hooks */
import { ProgramPartialUpdateField, ProgramRow } from '../../types';
import { ChangeEvent, MouseEvent, useEffect, useRef, useState } from 'react';
import clsx from 'clsx';
import { CellProps } from './ProgramCells';

const decimalFormatter = new Intl.NumberFormat('en-US');

const NumberCell =
  (dataTest: string, field: ProgramPartialUpdateField) =>
  ({
    onProgramUpdate,
    onRowEditChange,
    value: origValue,
    row,
    readOnly,
  }: CellProps<ProgramRow, number>) => {
    const [value, setValue] = useState<string>(`${origValue ?? ''}`);
    const [editing, setEditing] = useState(false);
    const inputRef = useRef<HTMLInputElement>(null);

    useEffect(() => {
      setValue(`${origValue ?? ''}`);
    }, [origValue]);

    useEffect(() => {
      if (!inputRef.current) return;
      inputRef.current?.focus();
    }, [editing]);

    function handleEditClick(e: MouseEvent<HTMLDivElement>) {
      if (row.isGrouped) return;
      e.stopPropagation();
      if (readOnly) return;
      if (editing) return;
      setEditing(true);
      onRowEditChange(true);
    }

    function handleChange(e: ChangeEvent<HTMLInputElement>) {
      const { value } = e.target;
      setValue(value);
    }

    function confirm() {
      const v = value === '' ? null : parseFloat(value);
      if (v !== null && isNaN(v)) {
        cancel();
        return;
      }

      if (
        field.type === 'StaticField' &&
        (field.fieldName === 'leads' || field.fieldName === 'actualLeads')
      ) {
        const originalValue = row.original[field.fieldName];
        if (originalValue !== v) {
          onProgramUpdate({
            original: row.original,
            updates: [{ field: field.fieldName, value: v }],
          });
        }
      } else if (field.type === 'ArrayFieldItem' && field.arrayField === 'customFields') {
        const originalField = row.original.customFields.find(
          ({ name, type }) =>
            field.arrayItemValue.name === name && field.arrayItemValue.type === type
        );

        if (originalField?.value !== v) {
          onProgramUpdate({
            original: row.original,
            updates: [
              {
                field: 'customFields',
                customFieldName: field.arrayItemValue.name,
                customFieldData: {
                  type: 'number',
                  value: v,
                },
              },
            ],
          });
        }
      }
      setEditing(false);
      onRowEditChange(false);
    }

    function cancel() {
      setValue(`${origValue ?? ''}`);
      setEditing(false);
      onRowEditChange(false);
    }

    const formattedValue =
      value !== '' ? decimalFormatter.format(isNaN(parseFloat(value)) ? 0 : parseFloat(value)) : '';

    return (
      <div
        className="m-0 p-0"
        data-test={dataTest}
        onClick={handleEditClick}
        onKeyDown={(e) => {
          if (editing) e.stopPropagation();
        }}
      >
        {editing ? (
          <input
            ref={inputRef}
            className="programs-table__cell-input"
            value={value ?? ''}
            onBlur={confirm}
            onChange={handleChange}
            onKeyDown={(e) => {
              if (e.key === 'Escape') cancel();
              if (e.key === 'Enter') confirm();
            }}
            readOnly={readOnly}
          />
        ) : (
          <div
            className={clsx('programs-table__editable-cell', {
              'programs-table__cell-edit-disabled': row.isGrouped || readOnly,
            })}
          >
            {formattedValue}
          </div>
        )}
      </div>
    );
  };

export default NumberCell;
