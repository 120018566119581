import CloudWatchSpaLogger from './cloudWatchSpaLogger';
import CloudWatchSpaLoggerConfig from './cloudWatchSpaLoggerConfig';
import { ConsoleSpaLogger } from '../consoleSpaLogger';

const { createLogStream } = require('aws-cloudwatch-log-browser');

export default class CloudWatchSpaLoggerInit {
  public static async init() {
    const config = this.readConfig();

    globalThis.logger = await this.create(config);
  }

  private static async create(config: CloudWatchSpaLoggerConfig): Promise<CloudWatchSpaLogger> {
    return (await createLogStream(config.logStreamName, config)
      .then((data: object) => {
        console.log(data, 'Stream creation succeeded');
        return new CloudWatchSpaLogger(config);
      })
      .catch((err: Error) => {
        if (err.message.match('The specified log stream already exists')) {
          console.info('Stream already exists. Everything is fine.');
          return new CloudWatchSpaLogger(config);
        }

        console.error(err, 'Stream creation failed');
        return new ConsoleSpaLogger();
      })) as Promise<CloudWatchSpaLogger>;
  }

  private static readConfig(): CloudWatchSpaLoggerConfig {
    const local = this.isLocalEnv(); // Optional. If set to true, the log will fall back to the standard 'console.log'.

    const logGroupName = local ? '<does_not_matter>' : this.logGroupName();
    const logStreamName = local ? '<does_not_matter>' : this.logStreamName();
    const uploadFreq = local ? 10_000 : this.uploadFrequency(); // Optional. Send logs to AWS LogStream in batches after 10 seconds intervals.
    const accessKeyId = local ? '<does_not_matter>' : this.awsAccessKeyId();
    const secretAccessKey = local ? '<does_not_matter>' : this.awsSecretAccessKey();
    const region = local ? '<does_not_matter>' : this.awsRegion();

    return new CloudWatchSpaLoggerConfig(
      logGroupName,
      logStreamName,
      local,
      uploadFreq,
      accessKeyId,
      secretAccessKey,
      region
    );
  }

  private static logGroupName(): string {
    return process.env.REACT_APP_CW_LOG_GROUP_NAME == null
      ? ''
      : process.env.REACT_APP_CW_LOG_GROUP_NAME;
  }

  private static logStreamName(): string {
    return `mpc-${new Date().toISOString().slice(0, 19).replace(/:/g, '-')}-
    abc-${(Math.random() * 1e32).toString(36).slice(0, 15)}`;
  }

  private static isLocalEnv(): boolean {
    return process.env.REACT_APP_LOG_IS_LOCAL_ENV == null
      ? true
      : process.env.REACT_APP_LOG_IS_LOCAL_ENV.toLowerCase() === 'true';
  }

  private static uploadFrequency(): number {
    return process.env.REACT_APP_CW_LOG_UPLOAD_FREQUENCY_MS == null
      ? -1
      : parseInt(process.env.REACT_APP_CW_LOG_UPLOAD_FREQUENCY_MS, 10);
  }

  private static awsAccessKeyId(): string {
    return process.env.REACT_APP_CW_LOG_AWS_ACCESS_KEY_ID == null
      ? ''
      : process.env.REACT_APP_CW_LOG_AWS_ACCESS_KEY_ID;
  }

  private static awsSecretAccessKey(): string {
    return process.env.REACT_APP_CW_LOG_AWS_SECRET_ACCESS_KEY == null
      ? ''
      : process.env.REACT_APP_CW_LOG_AWS_SECRET_ACCESS_KEY;
  }

  private static awsRegion(): string {
    return process.env.REACT_APP_CW_LOG_AWS_REGION == null
      ? ''
      : process.env.REACT_APP_CW_LOG_AWS_REGION;
  }
}
