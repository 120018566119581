import React, { MouseEvent } from 'react';
import clsx from 'clsx';

export const DivTab = ({
  text,
  isSelected,
  onClick,
  textDataTest,
  dataTest,
  className,
}: {
  text: string;
  isSelected?: boolean;
  onClick?: (e: MouseEvent<HTMLDivElement>) => void;
  textDataTest?: string;
  dataTest?: string;
  className?: string;
}) => (
  <div
    data-test={dataTest}
    onClick={onClick}
    className={clsx(`div-tab ${className ?? ''}`, {
      'div-tab--selected': isSelected,
    })}
  >
    <span className="div-tab__title" data-test={textDataTest ?? ''}>
      {text}
    </span>
  </div>
);
