import React from 'react';
import { StripeCustomerAddressDto } from '../dto';
import { DivButton } from '../../commons/components/DivButton';
import { analyticsTrack } from '../../web-analytics/webAnalytics';
import { AnalyticsEvent } from '../../web-analytics/AnalyticsEvent';
import { useMutation } from '@tanstack/react-query';
import useAnalyticsPage from '../../web-analytics/hooks/useAnalyticsPage';
import { Page } from '../../web-analytics/Page';
import useAnalytics from '../../web-analytics/webAnalyticsContext';
import useApi from '../../api/backendApiContext';

import { openUrlInNewTab } from '../../util/utils';
import { Customer } from '../types';
import { useIsTabletOrLarger } from '../../responsive/hooks/hooks';

const AddressTile = ({ address }: { address: StripeCustomerAddressDto }) => {
  return (
    <>
      {!!address.line1 && (
        <div>
          <span className="growegy-label14--moderrat" data-test="billing-details__address-line1">
            {address.line1}
          </span>
        </div>
      )}
      {!!address.line2 && (
        <div>
          <span className="growegy-label14--moderrat" data-test="billing-details__address-line2">
            {address.line2}
          </span>
        </div>
      )}
      <div>
        <span
          className="growegy-label14--moderrat"
          data-test="billing-details__address-line3"
        >{`${address.city}, ${address.state} ${address.postalCode} ${address.country}`}</span>
      </div>
    </>
  );
};
export const BillingDetailsSection = ({ customer }: { customer: Customer }) => {
  useAnalyticsPage(Page.BILLING);
  const analytics = useAnalytics();
  const { billingApi } = useApi();

  const editBillingPersonalInformation = async () => {
    const billingPortalSession = await billingApi.createBillingPortalSession();
    logger.info('Redirecting to billing portal');
    openUrlInNewTab(
      billingPortalSession.url,
      {
        analytics,
        analyticsEvent: AnalyticsEvent.BILLING_BILLING_INFORMATION_CHANGE_OPEN,
      },
      '/customer/update'
    );
  };

  const { isLoading, mutate: openBillingBillingInfomationChangeMutation } = useMutation(
    editBillingPersonalInformation,
    {
      onError: () => {
        analyticsTrack(analytics, AnalyticsEvent.BILLING_PORTAL_FAILED);
      },
    }
  );

  const isTabletOrLarger = useIsTabletOrLarger();

  const labelClasses = 'col-3 billing-table__column align-self-start growegy-title14--moderat';
  const valueClasses = 'col-9 billing-table__column';
  const onUpdateDetailsClick = () => {
    if (!isLoading) openBillingBillingInfomationChangeMutation();
  };
  return isTabletOrLarger ? (
    <div>
      <div className="billing-section__header">Billing details</div>
      <div className="billing-flex-table__row">
        <div className={labelClasses}>Email</div>
        <div className={valueClasses}>
          <span className="growegy-label14--moderrat" data-test="billing-details__email">
            {customer.email}
          </span>
        </div>
      </div>
      <div className="billing-flex-table__row">
        <div className={labelClasses}>Billing address</div>
        <div className={valueClasses}>
          <AddressTile address={customer.addressDto} />
        </div>
      </div>
      <div className="billing-flex-table__row">
        <div className={labelClasses}>Phone number</div>
        <div className={valueClasses}>
          <span className="growegy-label14--moderrat" data-test="billing-details__phone">
            {customer.addressDto.phone}
          </span>
        </div>
      </div>
      <div className="row common-table__row billing-flex-table__header">
        <div className={labelClasses} />
        <DivButton
          isGray={true}
          text="Update details"
          onClick={onUpdateDetailsClick}
          className="common-table__add-new-btn"
        />
      </div>
    </div>
  ) : (
    <div>
      <div className="billing-section__header">Billing details</div>
      <div className="billing-flex-table__row">
        <div className="billing-table__column--label-value">
          <div className="growegy-title14--moderat">Email</div>
          <span className="growegy-label14--moderrat" data-test="billing-details__email">
            {customer.email}
          </span>
        </div>
      </div>
      <div className="billing-flex-table__row">
        <div className="billing-table__column--label-value">
          <div className="growegy-title14--moderat">Billing address</div>
          <AddressTile address={customer.addressDto} />
        </div>
      </div>
      <div className="billing-flex-table__row">
        <div className="billing-table__column--label-value">
          <div className="growegy-title14--moderat">Phone number</div>
          <span className="growegy-label14--moderrat" data-test="billing-details__phone">
            {customer.addressDto.phone}
          </span>
        </div>
      </div>
      <div className="row common-table__row billing-flex-table__header">
        <DivButton
          isGray={true}
          text="Update details"
          onClick={onUpdateDetailsClick}
          className="common-table__add-new-btn"
        />
      </div>
    </div>
  );
};
