import { googleConfig, outlookConfig } from '../commons/config';
import { ICurrentUser } from '../currentuser/currentUser';

const isUserDomainAllowedForGoogleCalendar = (
  user: ICurrentUser | null,
  restrictedDomains: string[] | undefined
) => {
  const filteredRestrictedDomains = restrictedDomains?.filter((d) => !!d);
  return (
    !filteredRestrictedDomains ||
    !filteredRestrictedDomains.length ||
    !!filteredRestrictedDomains.find(
      (domain) => user && user.email.toLowerCase().endsWith(domain.toLowerCase())
    )
  );
};
export const isGoogleCalendarAvailable = (user: ICurrentUser | null) =>
  googleConfig.enabled &&
  isUserDomainAllowedForGoogleCalendar(user, googleConfig.restrictedDomainsOnly);

export const isOutlookAvailable = (user: ICurrentUser | null) =>
  outlookConfig.enabled &&
  isUserDomainAllowedForGoogleCalendar(user, outlookConfig.restrictedDomainsOnly);
