import React from 'react';

import './index.scss';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { createRoot } from 'react-dom/client';
import { initializeLogger } from './log/initialize';
import { FaroErrorBoundary } from '@grafana/faro-react';
import { outlookConfig } from './commons/config';

initializeLogger();

const isMsalRedirect = window.location.pathname === outlookConfig.redirectPath;
const container = document.getElementById('root');
if (!isMsalRedirect && container) {
  const root = createRoot(container);
  root.render(
    <React.StrictMode>
      <FaroErrorBoundary>
        <App />
      </FaroErrorBoundary>
    </React.StrictMode>
  );
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
