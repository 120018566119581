import { ResolvedProgramTask } from '../../types';
import { User } from '../../../user/types';

export const createNewTask = (orderId: number, owner: User | null): ResolvedProgramTask => ({
  id: null,
  name: '',
  description: null,
  dueDateTime: null,
  dueDateFormula: null,
  owningUserId: owner?.id ?? null,
  owner: owner,
  status: 'to-do',
  orderId,
  shouldFocus: true,
});

export const cloneTasks = (tasks: ResolvedProgramTask[]): ResolvedProgramTask[] => [
  ...tasks.map((t) => ({ ...t })),
];

export const getTaskDraggableId = (programId: string, taskId: string, unique: string) =>
  `drag/program:${programId}/task:${taskId}/unique:${unique}`;
// eslint-disable-next-line max-len
const taskDraggableIdRegex =
  /^drag\/program:(?<programId>[^/]*)\/task:(?<taskId>[^/]*)\/unique:[^/]+$/;
export const parseTaskDraggableId = (
  draggableId: string
): { programId: string | null; taskId: string | null } | null => {
  const match = draggableId.match(taskDraggableIdRegex);
  return !match || match.length !== 3
    ? null
    : {
        programId: match[1],
        taskId: match[2],
      };
};

export const getTaskTableDroppableId = (programId: string) => `drop/program:${programId}/tasks`;
const parseTaskDroppableIdRegex = /^drop\/program:(?<programId>[^/]*)\/tasks$/;
export const getProgramDroppableId = (programId: string) => `drop/program:${programId}`;
const parseProgramDroppableIdRegex = /^drop\/program:(?<programId>[^/]*)$/;
export const parseTaskDroppableId = (
  dropId: string
): { programId: string | null; dropArea: 'tasks' | 'program' | null } | null => {
  const tasksMatch = dropId.match(parseTaskDroppableIdRegex);
  if (tasksMatch && tasksMatch.length === 2) {
    return {
      programId: tasksMatch[1],
      dropArea: 'tasks',
    };
  }
  const programMatch = dropId.match(parseProgramDroppableIdRegex);
  if (programMatch && programMatch.length === 2)
    return {
      programId: programMatch[1],
      dropArea: 'program',
    };
  return null;
};
