const borderRadius = '8px';
const grayAlpha4 = 'rgba(25, 28, 29, 0.04)';
const grayAlpha8 = 'rgba(25, 28, 29, 0.08)';
const grayAlpha16 = 'rgba(25, 28, 29, 0.16)';
const grayAlpha4NonTransparent = 'rgb(246, 246, 246)';
const black = '#191c1d';
const black2 = '#70797b';
const blue = '#00b5ce';
const red = '#f24822';
const yellow = '#ffc700';
const purple = 'rgb(239, 236, 255)';
const neutral50 = '#fafafa';
const neutral100 = '#f5f5f5';
const neutral200 = '#e5e5e5';
const neutral300 = '#d4d4d4';
const neutral500 = '#737373';
const neutral900 = '#171717';
const cyan100 = '#cffafe';
const cyan500 = '#06b6d4';
const red500 = '#ef4444';
const green100 = '#dcfce7';
const green500 = '#22C55e';
const unknownEntity = '#9b9b9b';

export const StyleVariables = {
  borderRadius,
  grayAlpha4,
  grayAlpha8,
  grayAlpha16,
  grayAlpha4NonTransparent,
  black,
  black2,
  blue,
  red,
  yellow,
  purple,
  neutral50,
  neutral100,
  neutral200,
  neutral300,
  neutral500,
  neutral900,
  cyan100,
  cyan500,
  red500,
  unknownEntity,
  green100,
  green500,
};
