import { useFetchedIntegrationMetadatas } from './useFetchedMetadatas';
import { useFetchedTenantIntegration } from './useFetchedTenantIntegration';
import { QueryData } from '../../program/types';
import { Integration, ResolvedIntegration, ResolvedIntegrationAttribute } from '../types';
import { IntegrationMetadatasDto } from '../integrationApi';
import { getCommonFetchStatus, getCommonQueryStatus } from '../../program/util';

const resolveIntegration = (
  metadataDto: IntegrationMetadatasDto | undefined,
  integration: Integration | undefined
): ResolvedIntegration | null => {
  if (metadataDto && integration) {
    const metadata = metadataDto.metadatas.find((m) => m.id === integration.id);
    if (!metadata) return null;

    const attributes = metadata.parameters.map(({ name, type }) => {
      const value = integration.attributes.find((attr) => attr.name === name)?.value || '';
      const resolvedAttr: ResolvedIntegrationAttribute = {
        name,
        value,
        type: type === 'secure-string' ? 'secure-string' : 'string',
      };
      return resolvedAttr;
    });

    return {
      id: integration.id,
      name: metadata.name,
      icon: metadata.icon,
      description: metadata.description,
      version: integration.version,
      attributes,
    };
  }

  return null;
};

export const useResolvedIntegration = (props: {
  integrationId: string;
}): QueryData<ResolvedIntegration | null> => {
  const { integrationId } = props;

  const {
    data: metadatasData,
    error: metadatasError,
    status: metadatasStatus,
    fetchStatus: metadatasFetchStatus,
    isStale: areMetadatasStale,
  } = useFetchedIntegrationMetadatas();

  const {
    data: integrationData,
    error: integrationError,
    status: integrationStatus,
    fetchStatus: integrationFetchStatus,
    isStale: isIntegrationStale,
  } = useFetchedTenantIntegration({ integrationId });

  return {
    data: resolveIntegration(metadatasData, integrationData),
    status: getCommonQueryStatus([metadatasStatus, integrationStatus]),
    fetchStatus: getCommonFetchStatus([metadatasFetchStatus, integrationFetchStatus]),
    error: metadatasError || integrationError || null,
    isStale: areMetadatasStale || isIntegrationStale,
  };
};
