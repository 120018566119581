const oneSecond = 1000;
const oneMinute = oneSecond * 60;
export const ReactQueryConstants = {
  campaignProgramsStaleTime: oneMinute,
  campaignsStaleTime: oneMinute,
  campaignsRefetchInterval: oneMinute,
  usersStaleTime: oneMinute * 10,
  dataSeriesStaleTime: oneMinute * 10,
  integrationMetadatasStaleTime: oneMinute * 5,
  userAvatarStaleTime: oneMinute * 5,
  userSettingsStaleTime: oneMinute * 5,
  notificationSettingsStaleTime: oneMinute * 5,
  personalIntegrationSettingsStaleTime: oneMinute * 5,
  programSummariesStaleTime: oneSecond * 30,
  programSummariesRefetchInterval: oneSecond * 30,
  programTypesStaleTime: oneMinute,
  programAttachmentsStaleTime: oneSecond * 20,
  fullProgramStaleTime: oneSecond * 20,
  analyticsGroupingStaleTime: oneMinute * 10,
  personalRecommendationsStaleTime: oneMinute * 10,
  personalRecommendationsRefetchTime: oneMinute * 10,
  enterpriseChartsAmountStaleTime: oneSecond * 20,
  enterpriseChartsStaleTime: oneSecond * 20,
  enterpriseAnalyticLayoutStaleTime: oneSecond * 20,
  allCustomFieldsStaleTime: oneMinute,
  chatsStaleTime: oneMinute,
  chatsRefetchInterval: oneMinute,
  chatsMessagesStaleTime: oneMinute * 10,
  promptVariablesStaleTime: oneMinute,
  promptVariablesRefetchInterval: 2 * oneMinute,
  promptRecentUsagesStaleTime: 5 * oneMinute,
  promptRecentUsagesRefetchInterval: 5 * oneMinute,
  promptsStaleTime: oneMinute * 10,
  promptsRefetchTime: oneMinute * 10,
  billingStaleTime: oneMinute * 10,
  billingRefetchTime: oneMinute * 10,
};

export const defaultColors = [
  '#ef4444',
  '#f97316',
  '#f59E0b',
  '#eab308',
  '#84cc16',
  '#22c55e',
  '#10b981',
  '#14b8a6',
  '#06b6d4',
  '#0ea5e9',
  '#3b82f6',
  '#6366f1',
  '#8b5cf6',
  '#a855f7',
  '#d946ef',
  '#ec4899',
  '#f43f5e',
  '#64748b',
  '#71717a',
  '#78716c',
];

export const INTERPUNCT_CHARACTER = '·';
