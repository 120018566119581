import { CustomFieldType } from '../program/types';

export type MultiFilterFn<T> = (value: unknown) => (entity: T) => boolean;

export type MultiFilterInstance<T> = { name: string; paramsCount: number; fn: MultiFilterFn<T> };

export type FilteringFieldType =
  | 'boolean'
  | 'string'
  | 'number'
  | 'date'
  | 'user'
  | 'program-status'
  | 'program-type'
  | 'campaign';

export type FilteringFieldArrayItem = { name: string; type: CustomFieldType };

export type FilteringFieldIdentity<T> =
  | {
      type: 'StaticField';
      fieldName: keyof T;
    }
  | {
      type: 'ArrayFieldItem';
      arrayField: keyof T;
      arrayItemValue: FilteringFieldArrayItem;
    };

export type FilteringFieldMeta = {
  type: FilteringFieldType;
  defaultValue: unknown;
};

export type FilteringField<T> = {
  fieldIdentity: FilteringFieldIdentity<T>;
  fieldMetaData: FilteringFieldMeta;
  label: string;
  filters: MultiFilterInstance<T>[];
};

export type MultiFilterRow<T> = {
  renderId: string;
  selectedField: FilteringField<T>;
  selectedFilter: MultiFilterInstance<T>;
  filterValue: unknown;
};

export const MultiFilterConjunctions = ['And', 'Or'] as const;
export type MultiFilterConjunction = (typeof MultiFilterConjunctions)[number];

export type MultiFilterRows<T> = {
  filters: MultiFilterRow<T>[];
  conjunction: MultiFilterConjunction;
};

export type MultiFilterRowPersistable<T> = {
  selectedFieldIdentity: FilteringFieldIdentity<T>;
  selectedFieldType: FilteringFieldType;
  selectedFilterName: string;
  filterValue: unknown;
};

export type MultiFilterRowsPersistable<T> = {
  multiFilterCustomName: string;
  conjunction: MultiFilterConjunction;
  filters: MultiFilterRowPersistable<T>[];
};
