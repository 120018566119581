import React, { MouseEvent, ReactElement } from 'react';
import { Button } from 'react-bootstrap';

export const ButtonColored = ({
  icon,
  onClick,
  text,
  dataTest,
}: {
  onClick?: (e: MouseEvent<HTMLDivElement>) => void;
  text?: string;
  icon?: ReactElement;
  dataTest?: string;
}) => (
  <Button data-test={dataTest} variant="primary" onClick={onClick} className="button-colored">
    <div className="content-container">
      {icon && <div className="icon-container">{icon}</div>}
      <span className="text-container">{text}</span>
    </div>
  </Button>
);
