import { ErrorCode, FileRejection } from 'react-dropzone';

export const hasErrorOfType = (fileRejections: FileRejection[], errorCode: ErrorCode) =>
  fileRejections.some((x) => x.errors.some((e) => e.code === errorCode));
export const getFileExtension = (filename: string): string => {
  const lastDotIndex = filename.lastIndexOf('.');
  return lastDotIndex === -1 ? '' : filename.substring(lastDotIndex + 1);
};
export const saveDownloadFile = (blob: Blob, fileName: string): void => {
  const url = window.URL.createObjectURL(new File([blob], fileName));
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', fileName);
  document.body.appendChild(link);
  link.click();
};
export const downloadFile = (url: string, fileName: string): void => {
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', fileName);
  document.body.appendChild(link);
  link.click();
};
