import _ from 'lodash';
import useApi from '../../api/backendApiContext';
import { useQuery } from '@tanstack/react-query';
import { useQueryCacheManager } from '../../hooks/useQueryCacheManager';
import { ReactQueryConstants } from '../../commons/constants';
import { useLocalStorage } from '@rehooks/local-storage';
import { extractVersions, RecommendationsLocalStorageKey } from '../utils';

export const usePersonalRecommendations = (prefix: string) => {
  const { personalRecommendationsApi } = useApi();
  return useQuery(
    useQueryCacheManager().getPersonalRecommendationsCacheName(),
    async () => personalRecommendationsApi.getRecommendations(prefix),
    {
      staleTime: ReactQueryConstants.personalRecommendationsStaleTime,
      refetchOnWindowFocus: false,
      refetchInterval: ReactQueryConstants.personalRecommendationsRefetchTime,
    }
  );
};

export const usePersistedPersonalRecommendations = (prefix: string) => {
  const result = usePersonalRecommendations(prefix);

  const [versions, setVersions] = useLocalStorage<string[]>(
    `${RecommendationsLocalStorageKey}versions`,
    []
  );

  if (result.data) {
    const ls = extractVersions(result.data);
    const newVersions = !_(ls).difference(versions).isEmpty();
    if (newVersions) setVersions(ls);
  }

  return result;
};

export const useIsNewVersionPersonalRecommendations = () => {
  const result = usePersonalRecommendations('');

  const [versions] = useLocalStorage<string[]>(`${RecommendationsLocalStorageKey}versions`, []);

  if (result.data) {
    const ls = extractVersions(result.data);
    const newVersions = !_(ls).difference(versions).isEmpty();
    if (newVersions) {
      return true;
    }
  }

  return false;
};
