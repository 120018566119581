// Hook
import { useEffect, useRef } from 'react';

export const usePrevious = <T>(next: T, compare: (a: T, b: T) => boolean) => {
  const previousRef = useRef<T>();
  const previous = previousRef.current;

  const isEqual = previous ? compare(previous, next) : false;

  useEffect(() => {
    if (!isEqual) {
      previousRef.current = next;
    }
  });

  return { isEqual, previous };
};

export const useMemoCompare = <T>(next: T, compare: (a: T, b: T) => boolean): T => {
  const { isEqual, previous } = usePrevious(next, compare);

  return isEqual ? previous ?? next : next;
};

export const useMemoCompareMerge = <T>(
  next: T,
  compare: (a: T, b: T) => boolean,
  merge: (a: T, b: T) => T
): T => {
  const { isEqual, previous } = usePrevious(next, compare);

  if (isEqual) {
    return previous ?? next;
  }

  return previous ? merge(previous, next) : next;
};
