import { ResolvedProgramTask } from '../../types';
import React, { MouseEvent, useCallback, useEffect, useState } from 'react';
import { CellProps } from './CellProps';
import { User } from '../../../user/types';
import { ProgramOwnerSelect, UserWithAvatar } from '../ProgramOwnerSelect';
import clsx from 'clsx';
import { GrowegySelectMockup } from '../GrowegySelectMockup';

const placeholderText = 'Add assignee';

export const TaskOwnerInputCell = ({
  value: origValue,
  row,
  arrayHelpers,
  isReadonly,
  onPopoverOpen,
  onPopoverClose,
}: CellProps<ResolvedProgramTask, User | null>) => {
  const [value, setValue] = useState<User | null>(origValue);
  const [editing, setEditing] = useState(false);

  useEffect(() => {
    setValue(origValue);
  }, [origValue]);

  const onEditClick = useCallback(
    (e: MouseEvent<HTMLDivElement>) => {
      if (row.isGrouped || isReadonly) return;
      e.stopPropagation();
      if (editing) return;
      setEditing(true);
    },
    [row.isGrouped, isReadonly, editing]
  );

  const handleOwnerChange = (owner: User | null) => {
    if (owner?.id !== row.original.owningUserId) {
      arrayHelpers.replace(Number(row.id), {
        ...row.original,
        owningUserId: owner ? owner.id : null,
        owner,
      });
    }
    cancel();
  };

  const cancel = () => {
    setValue(origValue);
    setEditing(false);
  };
  return (
    <div
      className="tasks-table__owner-cell"
      data-test="tasks-table__owner-cell"
      onClick={onEditClick}
      onKeyDown={(e) => {
        if (editing) e.stopPropagation();
      }}
    >
      {editing && !isReadonly ? (
        <ProgramOwnerSelect
          dataTestPrefix="task-owner-select"
          onBlur={cancel}
          disabled={isReadonly}
          placeholder={placeholderText}
          initialValue={value}
          onValueChange={handleOwnerChange}
          menuZIndex={3}
          autoFocus={true}
          optionClassName={row.original.status === 'done' ? 'tasks-table__owner-cell--grayed' : ''}
          isClearable={true}
          onMenuOpen={onPopoverOpen}
          onMenuClose={onPopoverClose}
        />
      ) : (
        <GrowegySelectMockup placeholderText={placeholderText} readOnly={isReadonly}>
          {value ? (
            <UserWithAvatar
              user={value}
              dataTestPrefix="task-owner-select"
              className={clsx({
                'tasks-table__owner-cell--grayed': row.original.status === 'done',
              })}
            />
          ) : undefined}
        </GrowegySelectMockup>
      )}
    </div>
  );
};
