import React, { useEffect } from 'react';
import { PromptVariable } from '../../types';
import { useFetchedPromptVariables } from '../hooks/useFetchedPromptVariables';
import { DivButton } from '../../../commons/components/DivButton';
import { CollapseIcon, ExpandIcon } from '../../../assets/icons';
import { useLocalStorage } from '@rehooks/local-storage';
import { AiVariablesLocalStorageKey } from '../../../program/util';
import { VariableWithCollapsingHeader } from '../components/VariableWithCollapsingHeader';
import { useNotification } from '../../../notification/notificationsContext';
import { useUpdateVariableMutation } from '../hooks/useUpdateVariableMutation';
import { NoVariablesHelp } from '../components/NoVariablesHelp';

export const VariablesTab = () => {
  const { query: variablesQuery, queryStatusComponent: variablesQueryStatusComponent } =
    useFetchedPromptVariables();
  const { mutateAsync: updateVariableMutation, isError, error } = useUpdateVariableMutation();
  const { notifyError } = useNotification();

  useEffect(() => {
    if (isError && error) {
      notifyError({
        err: error,
        logMsg: `Failed to update variable`,
        notificationMsg: 'Update failed',
      });
    }
  }, [isError, error, notifyError]);

  const [collapsed, setCollapsed] = useLocalStorage<string[]>(
    `${AiVariablesLocalStorageKey}collapsedVariableIds`,
    []
  );
  const isCollapedAllGroups = collapsed.length < (variablesQuery.data?.length ?? 0);

  const setCollapsedVariable = (variableId: string, isCollapsed: boolean) => {
    if (!isCollapsed) {
      setCollapsed(collapsed.filter((v) => v !== variableId));
      return;
    }

    if (collapsed.find((v) => v === variableId)) {
      return;
    }

    setCollapsed([...collapsed, variableId]);
  };

  const toggleAll = () => {
    if (isCollapedAllGroups) {
      setCollapsed(variablesQuery.data?.map((v) => v.id ?? '') ?? []);
      return;
    }

    setCollapsed([]);
  };

  const onUpdate = async (newValue: PromptVariable) => {
    await updateVariableMutation(newValue);
  };

  return variablesQuery.data ? (
    <div>
      <div className="overflow-auto">
        {variablesQuery.data.length > 0 ? (
          <>
            <div className="d-flex justify-content-end">
              <DivButton
                icon={isCollapedAllGroups ? <CollapseIcon /> : <ExpandIcon />}
                text={isCollapedAllGroups ? 'Collapse all groups' : 'Expand all groups'}
                className="variables-table__collapse-button"
                dataTest={`variables-table__${isCollapedAllGroups ? 'collapse' : 'expand'}-button`}
                onClick={toggleAll}
              />
            </div>
            <>
              {variablesQuery.data
                .filter((v) => v.variableValue.type === 'string')
                .sort((a, b) => a.variableValue.name.localeCompare(b.variableValue.name))
                .concat(
                  variablesQuery.data
                    .filter((v) => v.variableValue.type === 'list')
                    .sort((a, b) => a.variableValue.name.localeCompare(b.variableValue.name))
                )
                .map((v) => (
                  <VariableWithCollapsingHeader
                    key={`variable-${v.id}-with-header`}
                    variable={v}
                    isCollapsed={!!collapsed.find((value) => value === v.id)}
                    onUpdate={async (v) => onUpdate(v)}
                    onCollapse={(isCollapsed) => {
                      setCollapsedVariable(v.id ?? '', isCollapsed);
                    }}
                  />
                ))}
            </>
          </>
        ) : (
          <NoVariablesHelp />
        )}
      </div>
    </div>
  ) : (
    variablesQueryStatusComponent
  );
};
