import { CellProps as BaseCellProps } from 'react-table';
import { ProgramPartialUpdateData, ProgramRow, ProgramType } from '../../types';
import { usdFormatter } from '../../util';
import { isScheduled } from '../../unscheduledProgramUtil';
import { formatDateTimeCell } from '../../../util/date-utils';

export type CellProps<D extends object, V = never> = BaseCellProps<D, V> & {
  onProgramUpdate: (data: { original: ProgramRow; updates: ProgramPartialUpdateData[] }) => void;
  onRowEditChange: (editing: boolean) => void;
  readOnly: boolean;
};

export const SimpleStringCell =
  ({ dataTest, className }: { dataTest?: string; className?: string }) =>
  ({ value }: CellProps<ProgramRow, string>) => (
    <span className={className} data-test={dataTest}>
      {value}
    </span>
  );

export const SimpleNumericCell =
  ({ dataTest, className }: { dataTest?: string; className?: string }) =>
  ({ value }: CellProps<ProgramRow, number | null>) => (
    <span className={className} data-test={dataTest}>
      {value ? `${value}` : ''}
    </span>
  );

export const SimpleMoneyCell =
  ({ dataTest, className }: { dataTest?: string; className?: string }) =>
  ({ value }: CellProps<ProgramRow, number>) => (
    <span className={className} data-test={dataTest}>
      {usdFormatter.format(value)}
    </span>
  );

export const SimpleProgramTypeCell =
  ({ dataTest, className }: { dataTest?: string; className?: string }) =>
  ({ value, row }: CellProps<ProgramRow, ProgramType>) =>
    row.isGrouped ? (
      <div />
    ) : (
      <span className={className ?? ''} data-test={dataTest} style={{ color: `${value.color}` }}>
        {value.name}
      </span>
    );

export const SimpleDateCell =
  ({ dataTest, className }: { dataTest?: string; className?: string }) =>
  ({ value }: CellProps<ProgramRow, Date | null>) => (
    <span className={className} data-test={dataTest}>
      {value && isScheduled({ startDateTime: value }) ? formatDateTimeCell(value) : ''}
    </span>
  );
