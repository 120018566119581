import { Permission } from './permissions';
import { StyleVariables } from '../commons/styleConstants';

const basicPermissions = [
  Permission.SAVE_REPLACE_AVATAR,
  Permission.GET_AVATAR,
  Permission.DELETE_AVATAR,
  Permission.SAVE_REPLACE_USER_SETTINGS,
  Permission.GET_USER_SETTINGS,
  Permission.GET_ALL_USERS,
  Permission.GET_PROGRAM_TYPES,
  Permission.SAVE_REPLACE_NOTIFICATION_SETTINGS,
  Permission.GET_NOTIFICATION_SETTINGS,
  Permission.EXCHANGE_GOOGLE_AUTH_TOKEN,
  Permission.GET_GOOGLE_OAUTH,
  Permission.DELETE_GOOGLE_OAUTH,
  Permission.RESYNC_GOOGLE_CALENDAR,
];

const guestPermissions = basicPermissions.concat([
  Permission.GET_ASSIGNED_PROGRAM_ATTACHMENT,
  Permission.GET_ASSIGNED_PROGRAM_ATTACHMENT_METADATA,
  Permission.GET_ASSIGNED_PROGRAM_PAGE,
  Permission.GET_ASSIGNED_CAMPAIGNS,
  Permission.GET_ALL_ASSIGNED_PROGRAMS,
  Permission.GET_ASSIGNED_PROGRAM,
  Permission.GET_ASSIGNED_CAMPAIGN_PROGRAMS,
  Permission.GET_ASSIGNED_CAMPAIGN_PROGRAMS_METADATA,
  Permission.ADD_ASSIGNED_PROGRAM,
  Permission.ADD_ASSIGNED_PROGRAM_ATTACHMENT,
  Permission.DELETE_ASSIGNED_PROGRAM_ATTACHMENT,
  Permission.REPLACE_ASSIGNED_PROGRAM,
  Permission.USE_CHAT_GPT,
  Permission.USE_PROMPT_LIBRARY_GPT,
  Permission.CRUD_PROMPT_LIBRARY_GPT,
  Permission.CRUD_VARIABLES_GPT,
]);

const viewerPermissions = basicPermissions.concat([
  Permission.GET_CAMPAIGNS,
  Permission.GET_PROGRAM_PAGE,
  Permission.GET_PROGRAM,
  Permission.GET_PROGRAM_ATTACHMENT,
  Permission.GET_PROGRAM_ATTACHMENT_METADATA,
  Permission.GET_CAMPAIGN_PROGRAMS,
  Permission.GET_CAMPAIGN_PROGRAMS_METADATA,
]);

const userPermissions = viewerPermissions.concat([
  Permission.GET_ALL_PROGRAMS,
  Permission.GET_DATA_SERIES,
  Permission.GET_GROUPED_DATA,
  Permission.GET_TENANT_RECOMMENDATIONS,
  Permission.DELETE_USER_SETTINGS,
  Permission.ADD_PROGRAM,
  Permission.ADD_CAMPAIGN,
  Permission.DELETE_PROGRAM,
  Permission.REPLACE_PROGRAM,
  Permission.ADD_PROGRAM_ATTACHMENT,
  Permission.DELETE_PROGRAM_ATTACHMENT,
  Permission.USE_CHAT_GPT,
  Permission.USE_PROMPT_LIBRARY_GPT,
  Permission.CRUD_PROMPT_LIBRARY_GPT,
  Permission.CRUD_VARIABLES_GPT,
]);

const adminPermissions: Permission[] = Object.keys(Permission)
  .filter((x) => {
    return isNaN(Number(x));
  })
  .map((x) => Permission[x as keyof typeof Permission])
  .filter((x) => x !== Permission.BATCH_INTEGRATIONS);

export const ADMIN_ROLE = {
  id: 'admin',
  name: 'Admin',
  permissions: new Set(adminPermissions),
  description:
    'An admin is the highest role with no limitations.' +
    ' The first user who created an account is always an admin, but this can be changed later.',
  color: StyleVariables.black,
};
export const USER_ROLE = {
  id: 'user',
  name: 'User',
  permissions: new Set(userPermissions),
  description:
    'A user is the default role, with the ability to do most actions. ' +
    'They only can’t access certain general account settings.',
  color: StyleVariables.black,
};
export const VIEWER_ROLE = {
  id: 'viewer',
  name: 'Viewer',
  permissions: new Set(viewerPermissions),
  description: 'Viewers can only view campaigns, programs, and tasks; they cannot edit anything.',
  color: StyleVariables.black,
};
export const GUEST_ROLE = {
  id: 'guest',
  name: 'Guest',
  permissions: new Set(guestPermissions),
  description:
    'Guests can view and edit objects assigned directly to them. ' +
    'They can also view associated campaigns, programs, and tasks, although the edit permisisons can be limited.',
  color: StyleVariables.black,
};

export type SystemRole = {
  id: string;
  name: string;
  permissions: Set<Permission>;
  description: string;
  color: string;
};

export const SYSTEM_ROLES: SystemRole[] = [ADMIN_ROLE, USER_ROLE, GUEST_ROLE, VIEWER_ROLE];

export function parseRole(roleName: string): SystemRole | null {
  switch (roleName) {
    case ADMIN_ROLE.id:
      return ADMIN_ROLE;
    case USER_ROLE.id:
      return USER_ROLE;
    case VIEWER_ROLE.id:
      return VIEWER_ROLE;
    case GUEST_ROLE.id:
      return GUEST_ROLE;
    default:
      return null;
  }
}
