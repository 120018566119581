import React from 'react';
import { PromptVariableStringValue } from '../../types';
import { VariableRowCell } from './VariableRowCell';
import { MoreMenu } from '../../../commons/components/MoreMenu';
import { BinIcon } from '../../../assets/icons';

export const VariableListItem = ({
  variableName,
  origValue,
  onUpdate,
  onDelete,
  idxOfList,
}: {
  variableName: string;
  origValue: PromptVariableStringValue;
  onUpdate: (newValue: PromptVariableStringValue) => Promise<void>;
  onDelete: () => Promise<void>;
  idxOfList: number;
}) => {
  const onValueUpdate: (newValue: string) => Promise<void> = async (newValue: string) => {
    if (newValue.trim()) {
      await onUpdate({ ...origValue, value: newValue });
    }
  };

  const onDescriptionUpdate: (newValue: string) => Promise<void> = async (newValue: string) => {
    await onUpdate({ ...origValue, description: newValue });
  };

  const valuePlaceholder = `${variableName} ${idxOfList + 1}`;
  const descriptionPlaceholder = !!origValue.value
    ? `${origValue.value} description`
    : `${variableName} ${idxOfList + 1} description`;

  return (
    <div
      data-test="variables-table__list-item-row"
      className="variables-table__row variables-table__row--highlighted d-flex"
    >
      <VariableRowCell
        origValue={origValue.value}
        placeholder={valuePlaceholder}
        onUpdate={async (v) => onValueUpdate(v)}
        style={{ maxWidth: '25%' }}
        textMaxLength={40}
        forbidEmpty={true}
      />
      <VariableRowCell
        origValue={origValue.description}
        placeholder={descriptionPlaceholder}
        onUpdate={async (v) => onDescriptionUpdate(v)}
        style={{ maxWidth: '95%' }}
        textMaxLength={300}
      />
      <MoreMenu
        menuItems={[
          {
            type: 'standard',
            text: 'Delete',
            icon: <BinIcon />,
            dataTest: 'variables-table__delete-menu-button',
            onClick: async () => onDelete(),
          },
        ]}
        menuButtonDataTest="variables-table__menu-button"
        menuDataTest="variables-table__menu"
      />
    </div>
  );
};
