import { TextAndIcon } from '../../../commons/components/TextAndIcon';
import { PaperPlaneIcon } from '../../../assets/icons';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { TemplateMessage } from './TemplateMessage';
import { VariableValueList } from './VariableValueList';
import { ValueList } from './ValueList';
import TextareaAutosize from 'react-textarea-autosize';
import { INTERPUNCT_CHARACTER } from '../../../commons/constants';
import { Button } from 'react-bootstrap';
import { useFetchedPromptVariable } from '../../variables/hooks/useFetchedPromptVariable';
import { useAddValueToVariableMutation } from '../../variables/hooks/useAddValueToVariableMutation';
import {
  MAX_PROMPT_VALUE_DESCRIPTION_LENGTH,
  PromptVariableStringValue,
  PromptVariableStringValueOrAdhoc,
} from '../../types';
import _ from 'lodash';
import { v4 as uuidv4 } from 'uuid';
import {
  cleanUpVariableValue,
  compareStringValueOrAdhoc,
  variableValueToContent,
} from '../../util';
import { LibraryPromptWizardStepState, useChatContext } from '../chatContext';
import { PlaceholderMetadataDto } from '../../dto';

const validateDescription = (s: string) =>
  s.length > 0 && s.length <= MAX_PROMPT_VALUE_DESCRIPTION_LENGTH;

const getMinMaxCountConstraints = (
  metadata: PlaceholderMetadataDto | null
): { minCount: number; maxCount: number } => {
  if (!metadata) return { minCount: 1, maxCount: 10 };
  if (metadata.type === 'StringMetadata' || metadata.type === 'NumberMetadata')
    return { minCount: 1, maxCount: 1 };
  return { minCount: metadata?.minSizeLimit ?? 1, maxCount: metadata?.maxSizeLimit ?? 10 };
};

const valuesToContent = (values: PromptVariableStringValue[]) => {
  const filteredValues = _(values.map(variableValueToContent).filter((x) => !!x))
    .uniq()
    .value();
  if (filteredValues.length === 0) return '';
  if (filteredValues.length === 1) return filteredValues[0];
  return filteredValues.map((x) => `${INTERPUNCT_CHARACTER} ${x}`).join('\n');
};

const SendToChatTextAndIcon = () => <TextAndIcon text="Send to chat" icon={<PaperPlaneIcon />} />;

export const VariableMessage = (props: {
  chatId: string;
  variableName: string | null;
  predefinedValues: string[] | null;
  metadata: PlaceholderMetadataDto | null;
  onSend: (content: string) => void;
}) => {
  const { chatId, variableName, predefinedValues, metadata, onSend } = props;
  const { getChatContext, setChatContext } = useChatContext();
  const { libraryPromptWizardState } = getChatContext(chatId);
  const [addNewMode, setAddNewMode] = useState(
    libraryPromptWizardState?.currentStepState.addNewMode
  );
  const [selectedValues, setSelectedValues] = useState<PromptVariableStringValueOrAdhoc[]>(
    libraryPromptWizardState?.currentStepState.selectedValues ?? []
  );
  const [newValueDescription, setNewValueDescription] = useState(
    libraryPromptWizardState?.currentStepState.newValueDescription ?? ''
  );
  const [adhocValues, setAdhocValues] = useState<PromptVariableStringValueOrAdhoc[]>(
    libraryPromptWizardState?.currentStepState.adhocValues ?? []
  );
  const [saveAsVariableEnabled, setSaveAsVariableEnabled] = useState(true);

  const updateChatContext = useCallback(
    (changes: Partial<LibraryPromptWizardStepState>) => {
      const { libraryPromptWizardState: currentWizardState } = getChatContext(chatId);
      if (!currentWizardState) return;
      setChatContext(chatId, {
        libraryPromptWizardState: {
          ...currentWizardState,
          currentStepState: { ...currentWizardState.currentStepState, ...changes },
        },
      });
    },
    [chatId, getChatContext, setChatContext]
  );
  const { minCount, maxCount } = useMemo(() => getMinMaxCountConstraints(metadata), [metadata]);
  const sendImmediately = maxCount === 1;
  // const [autoSend, setAutoSend] = useState(false);

  const sendCallback = useCallback(
    (valuesToSend: PromptVariableStringValue[]) => {
      const content = valuesToContent(valuesToSend);
      if (content.length === 0) return;
      onSend(content);
    },
    [onSend]
  );

  const {
    query: { data: promptVariable, status: promptVariableStatus },
    queryStatusComponent,
  } = useFetchedPromptVariable(variableName);

  const onNewClick = useCallback(() => {
    setAddNewMode(true);
    setNewValueDescription('');
    updateChatContext({ addNewMode: true, newValueDescription: '' });
  }, [updateChatContext]);

  const onCancelAddClick = useCallback(() => {
    setAddNewMode(false);
    setNewValueDescription('');
    updateChatContext({ addNewMode: false, newValueDescription: '' });
  }, [updateChatContext]);

  const { mutateAsync: addValueToVariableMutation } = useAddValueToVariableMutation();
  const predefinedValuesMemoized = useMemo<PromptVariableStringValueOrAdhoc[]>(
    () =>
      predefinedValues?.map((x) => ({
        type: 'string',
        name: '',
        value: '',
        description: x,
        kind: 'adhoc',
        id: uuidv4(),
      })) ?? [],
    [predefinedValues]
  );

  const onSelectedValueChanged = useCallback(
    (value: PromptVariableStringValueOrAdhoc, isSelected: boolean) => {
      let newSelectedValues: PromptVariableStringValueOrAdhoc[];
      if (
        isSelected &&
        selectedValues.length < maxCount &&
        !selectedValues.some((x) => compareStringValueOrAdhoc(x, value))
      ) {
        newSelectedValues = [...selectedValues, value];
      } else if (!isSelected) {
        newSelectedValues = selectedValues.filter((x) => !compareStringValueOrAdhoc(x, value));
      } else {
        return;
      }
      setSelectedValues(newSelectedValues);
      updateChatContext({ selectedValues: newSelectedValues });
      if (isSelected && sendImmediately) {
        sendCallback(newSelectedValues);
      }
    },
    [selectedValues, updateChatContext, maxCount, sendCallback, sendImmediately]
  );

  const onAddClick = useCallback(async () => {
    const description = cleanUpVariableValue(newValueDescription);
    if (!validateDescription(description)) return;
    let newValue: PromptVariableStringValueOrAdhoc;
    if (saveAsVariableEnabled && variableName != null) {
      await addValueToVariableMutation({
        valueDescription: description,
        promptVariable,
        variableName,
      });
      newValue = {
        type: 'string',
        name: '',
        value: '',
        description,
        kind: 'saved',
        id: null,
      };
    } else {
      newValue = {
        type: 'string',
        name: '',
        value: '',
        description,
        kind: 'adhoc',
        id: uuidv4(),
      };
      const newAdhocValues = [...adhocValues, newValue];
      setAdhocValues(newAdhocValues);
      updateChatContext({ adhocValues: newAdhocValues });
    }
    onSelectedValueChanged(newValue, true);
    setAddNewMode(false);
    setNewValueDescription('');
    updateChatContext({ addNewMode: false, newValueDescription: '' });
  }, [
    adhocValues,
    newValueDescription,
    saveAsVariableEnabled,
    addValueToVariableMutation,
    promptVariable,
    variableName,
    onSelectedValueChanged,
    updateChatContext,
  ]);

  useEffect(() => {
    if (addNewMode && textAreaRef.current) {
      textAreaRef.current.focus();
    }
  }, [addNewMode]);

  const textAreaRef = useRef<HTMLTextAreaElement | null>(null);

  return (
    <TemplateMessage sender={'none'}>
      <div className="variable-message__container">
        {addNewMode ? (
          <TextareaAutosize
            ref={textAreaRef}
            className="variable-message__new-value-text-box"
            data-test="variable-message__new-value-text-box"
            placeholder={`Enter ${variableName} description`}
            value={newValueDescription}
            maxLength={MAX_PROMPT_VALUE_DESCRIPTION_LENGTH}
            onChange={(event) => {
              setNewValueDescription(event.target.value);
              updateChatContext({ newValueDescription: event.target.value });
            }}
            onKeyDown={async (e) => {
              if (e.key === 'Enter' && !e.shiftKey) {
                e.preventDefault();
                e.stopPropagation();
                await onAddClick();
              } else if (e.key === 'Escape') {
                e.preventDefault();
                e.stopPropagation();
                onCancelAddClick();
              }
            }}
          />
        ) : (
          <>
            {predefinedValues ? (
              <ValueList
                values={predefinedValuesMemoized}
                selectedValues={selectedValues}
                onSelectionChanged={onSelectedValueChanged}
              />
            ) : variableName ? (
              promptVariableStatus === 'success' ? (
                <VariableValueList
                  variableName={variableName}
                  promptVariable={promptVariable}
                  selectedValues={selectedValues}
                  adhocValues={adhocValues}
                  onSelectionChanged={onSelectedValueChanged}
                  onAddNewClick={onNewClick}
                />
              ) : (
                queryStatusComponent
              )
            ) : (
              <></>
            )}
          </>
        )}
        <div className="variable-message__new-value-footer">
          {addNewMode ? (
            <>
              <div className="flex-grow-1">
                <div className="switch-input-container" style={{ width: '150px' }}>
                  <input
                    type="checkbox"
                    className="custom-control-input"
                    id="variable-message__save-as-variable"
                    onChange={() => setSaveAsVariableEnabled(!saveAsVariableEnabled)}
                    checked={saveAsVariableEnabled}
                  />
                  <label
                    className="custom-control-label calendar-display-settings__check-label"
                    data-test="variable-message__save-as-variable-switch"
                    htmlFor="variable-message__save-as-variable"
                  >
                    Save as variable
                  </label>
                </div>
              </div>
              <Button
                className="button-default"
                data-test="variable-message__cancel-new-value-button"
                onClick={onCancelAddClick}
              >
                Cancel
              </Button>
            </>
          ) : (
            <></>
          )}
          {addNewMode && (
            <Button
              className="variable-message__apply-button"
              data-test="variable-message__add-new-value-button"
              disabled={!validateDescription(cleanUpVariableValue(newValueDescription))}
              onClick={onAddClick}
            >
              {sendImmediately ? <SendToChatTextAndIcon /> : `Add ${variableName}`}
            </Button>
          )}
          {!addNewMode && !sendImmediately && (
            <Button
              className="variable-message__apply-button"
              data-test="variable-message__send-button"
              disabled={selectedValues.length < minCount || selectedValues.length > maxCount}
              onClick={() => sendCallback(selectedValues)}
            >
              <SendToChatTextAndIcon />
            </Button>
          )}
        </div>
      </div>
    </TemplateMessage>
  );
};
