import React from 'react';
import { Form } from 'react-bootstrap';
import { FormikErrors, FormikTouched } from 'formik';
import { ResolvedIntegration, ResolvedIntegrationAttribute } from '../types';
import { AttributeTextInput } from './AttributeTextInput';

export type IntegrationAttributeProps = {
  attribute: ResolvedIntegrationAttribute;
  index: number;
  disabled: boolean;
  errors: FormikErrors<ResolvedIntegration>;
  touched: FormikTouched<ResolvedIntegration>;
};

export const IntegrationAttribute = ({
  attribute,
  index,
  disabled,
  errors,
  touched,
}: IntegrationAttributeProps) => {
  const isError = (key: keyof ResolvedIntegrationAttribute): boolean => {
    const fieldErrors = errors.attributes?.[index];
    const touchedFields = touched.attributes?.[index];
    return !!(
      fieldErrors &&
      typeof fieldErrors !== 'string' &&
      fieldErrors[key] &&
      touchedFields &&
      touchedFields[key]
    );
  };

  const fieldTextValueError = isError('value');

  return (
    <div key={attribute.name} data-test={`integration-edit__attribute-row-${attribute.name}`}>
      <Form.Group
        controlId={attribute.name}
        className="integration-edit__form-group growegy-label14"
      >
        <label
          htmlFor={attribute.name}
          className="integration-edit__label"
          data-test={`integration-edit__attributes-label--${attribute.name}`}
        >
          {attribute.name}
        </label>
        <div className="integration-edit__field">
          {(() => {
            switch (attribute.type) {
              case 'string':
                return (
                  <AttributeTextInput
                    type="string"
                    fieldValue={attribute.value}
                    disabled={disabled}
                    formikFieldName={`attributes.${index}.value`}
                    isError={fieldTextValueError}
                    dataTest={`integration-edit__attributes-string--${attribute.name}`}
                  />
                );
              case 'secure-string':
                return (
                  <AttributeTextInput
                    type="password"
                    fieldValue={attribute.value}
                    disabled={disabled}
                    formikFieldName={`attributes.${index}.value`}
                    isError={fieldTextValueError}
                    dataTest={`integration-edit__attributes-secure-string--${attribute.name}`}
                  />
                );
              default:
                return <></>;
            }
          })()}
        </div>
      </Form.Group>
    </div>
  );
};
