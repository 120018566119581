import { useResolvedProgramSummaries } from '../../hooks/useResolvedProgramSummaries';
import moment from 'moment';
import { errorToString } from '../../../util/utils';
import {
  ColoredItem,
  ProgramSummariesFetchError,
  ResolvedCampaign,
  ResolvedProgramEndDateField,
  ResolvedProgramStartDateField,
  ResolvedProgramSummaries,
  ResolvedProgramSummary,
  ResolvedProgramTypeField,
  UnknownProgramType,
} from '../../types';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Draggable } from '@fullcalendar/interaction';
import _ from 'lodash';
import { programToEvent, unscheduledProgramsGroupedByCampaigns } from './calendarEventFactory';
import { UnscheduledViewType } from '../../containers/types';
import { CampaignCalendarEvent } from './CampaignCalendarEvent';
import { isUnscheduled, unscheduledProgramsMonths } from '../../unscheduledProgramUtil';
import { filterResolvedPrograms, SelectedFilter } from '../../../multifilter/programFiltering';
import clsx from 'clsx';
import useAuth from '../../../auth/authContext';
import { PleaseRelogin } from '../../../auth/PleaseRelogin';
import { ICurrentUser } from '../../../currentuser/currentUser';
import {
  getProgramPermissionHelper,
  useAddProgramPermissionHelper,
} from '../../programPermissionHelper';
import { useMemoCompare } from '../../../hooks/useMemoCompare';
import { ProgramCalendarEvent } from './ProgramCalendarEvent';

export const SummaryErrors = ({
  queryResults,
}: {
  queryResults: ResolvedProgramSummaries['data'];
}) => (
  <>
    {queryResults
      .filter(({ status }) => status === 'error')
      .map(({ error }, idx) => {
        if (error instanceof ProgramSummariesFetchError) {
          return (
            <div
              key={`calendar-error-${moment(error.startMonth).format('MMM, YYYY')}`}
              data-test={`calendar__loading-error-${moment(error.startMonth).format('MMM-YYYY')}`}
              className="alert alert-warning"
              role="alert"
            >
              Could not load programs for {moment(error.startMonth).format('MMM, YYYY')}:{' '}
              {errorToString(error)}
            </div>
          );
        }

        return (
          <div className="alert alert-warning" role="alert" key={idx}>
            Could not load programs: {errorToString(error)}
          </div>
        );
      })}
  </>
);

const notApplicableColumns = new Set<string>([
  ResolvedProgramTypeField,
  ResolvedProgramStartDateField,
  ResolvedProgramEndDateField,
]);

interface Props {
  showCampaigns: boolean;
  visibleColumns: string[];
  onClick: (program: ResolvedProgramSummary) => void;
  onCreate: (
    arg?: { type: 'typeId'; value?: string } | { type: 'campaignId'; value?: string }
  ) => void;
  isCampaignGrouping: boolean;
  allCampaigns: ResolvedCampaign[];
  selectedFilter: SelectedFilter | null;
}

const ProgramsGroupedByTypes = (props: {
  allPrograms: ResolvedProgramSummary[];
  onClick: (program: ResolvedProgramSummary) => void;
  showCampaigns: boolean;
  applicableVisibleColumns: string[];
  user: ICurrentUser;
  onCreate: (typeId?: string) => void;
}) => {
  const { allPrograms, onClick, showCampaigns, applicableVisibleColumns, user, onCreate } = props;
  return (
    <>
      {_.chain(allPrograms)
        .compact()
        .sort((p1, p2) => p1.type.name.localeCompare(p2.type.name))
        .groupBy((p) => p.type.id)
        .map((programs) => {
          const { type } = programs[0];
          return (
            <ProgramTypeGrouping
              key={type.id}
              type={type}
              programs={programs}
              onClick={onClick}
              showCampaigns={showCampaigns}
              applicableVisibleColumns={applicableVisibleColumns}
              user={user}
              onCreate={onCreate}
            />
          );
        })
        .value()}
    </>
  );
};

const ProgramTypeGrouping = (props: {
  type: ColoredItem;
  programs: ResolvedProgramSummary[];
  onClick: (program: ResolvedProgramSummary) => void;
  showCampaigns: boolean;
  applicableVisibleColumns: string[];
  user: ICurrentUser;
  onCreate: (typeId?: string) => void;
}) => {
  const { type, programs, onClick, showCampaigns, applicableVisibleColumns, user, onCreate } =
    props;
  const addProgramPermissionHelper = useAddProgramPermissionHelper();
  return (
    <div
      key={type.id}
      style={{ color: type?.color || UnknownProgramType.color }}
      className="program-calendar__unscheduled-type"
    >
      <div className="program-calendar__unscheduled-type-title">{type?.name}</div>
      <div className="program-calendar__unscheduled-programs-container">
        {programs.map((program) => (
          <ProgramCalendarEvent
            key={program.id}
            program={program}
            user={user}
            onClick={onClick}
            showCampaigns={showCampaigns}
            applicableVisibleColumns={applicableVisibleColumns}
            viewType={UnscheduledViewType}
            shortcutFieldNamesOnly={true}
          />
        ))}
      </div>
      <div
        className={clsx('program-calendar__unscheduled-add-new', {
          invisible: !addProgramPermissionHelper.canCreateProgram(),
        })}
        onClick={() => onCreate(type?.id)}
      />
    </div>
  );
};

const ProgramsGroupedByCampaigns = (props: {
  allPrograms: ResolvedProgramSummary[];
  allCampaigns: ResolvedCampaign[];
  expandedCampaigns: Set<string>;
  onClick: (program: ResolvedProgramSummary) => void;
  showCampaigns: boolean;
  applicableVisibleColumns: string[];
  user: ICurrentUser;
  onCreate: (campaignId?: string) => void;
  onToggle: (campaignId: string) => void;
}) => {
  const {
    allPrograms,
    allCampaigns,
    expandedCampaigns,
    onClick,
    showCampaigns,
    applicableVisibleColumns,
    user,
    onCreate,
    onToggle,
  } = props;
  const groups = unscheduledProgramsGroupedByCampaigns(
    allPrograms,
    allCampaigns,
    expandedCampaigns
  );

  return (
    <>
      {groups.map(({ campaign, programs }) => (
        <ProgramCampaignGrouping
          campaign={campaign}
          isExpanded={expandedCampaigns.has(campaign.id)}
          key={campaign.id}
          programs={programs}
          user={user}
          onClick={onClick}
          showCampaigns={showCampaigns}
          applicableVisibleColumns={applicableVisibleColumns}
          onCreate={onCreate}
          onToggleCampaign={(c) => onToggle(c.id)}
        />
      ))}
    </>
  );
};

const ProgramCampaignGrouping = (props: {
  campaign: ResolvedCampaign;
  programs: ResolvedProgramSummary[];
  onClick: (program: ResolvedProgramSummary) => void;
  showCampaigns: boolean;
  applicableVisibleColumns: string[];
  user: ICurrentUser;
  onCreate: (campaignId: string) => void;
  onToggleCampaign: (campaign: ResolvedCampaign) => void;
  isExpanded: boolean;
}) => {
  const {
    campaign,
    programs,
    onClick,
    showCampaigns,
    applicableVisibleColumns,
    user,
    onCreate,
    onToggleCampaign,
    isExpanded,
  } = props;
  const addProgramPermissionHelper = useAddProgramPermissionHelper();
  return (
    <div key={campaign.id} className="program-calendar__unscheduled-campaign-grouping">
      <CampaignCalendarEvent
        programSummary={campaign}
        onToggleCampaign={(c) => onToggleCampaign(c)}
        onOpen={(c) => onClick(c)}
        isExpanded={isExpanded}
        isUnscheduledList={true}
        visibleColumns={applicableVisibleColumns}
      />
      <div className="program-calendar__unscheduled-programs-container">
        {programs.map((program) => (
          <ProgramCalendarEvent
            key={program.id}
            program={program}
            user={user}
            onClick={onClick}
            showCampaigns={showCampaigns}
            applicableVisibleColumns={applicableVisibleColumns}
            viewType={UnscheduledViewType}
            shortcutFieldNamesOnly={true}
          />
        ))}
      </div>
      {isExpanded && (
        <div
          className={clsx('program-calendar__unscheduled-add-new', {
            'd-none': !addProgramPermissionHelper.canCreateProgramInCampaign(campaign),
          })}
          onClick={() => onCreate(campaign.id)}
        />
      )}
    </div>
  );
};

export const UnscheduledPrograms = ({
  showCampaigns,
  visibleColumns,
  onClick,
  onCreate,
  isCampaignGrouping,
  allCampaigns,
  selectedFilter,
}: Props) => {
  const {
    state: { user },
  } = useAuth();
  const {
    data: summaryQueryResults,
    error: summaryQueryError,
    status,
  } = useResolvedProgramSummaries({
    monthsStart: unscheduledProgramsMonths,
  });
  const containerRef = useRef<HTMLDivElement | null>(null);
  const [expanded, setExpanded] = useState<{ [name: string]: boolean }>({});
  const nonFilteredPrograms = useMemo(
    () =>
      status === 'loading' ? [] : summaryQueryResults.flatMap((summary) => summary.data?.summaries),
    [status, summaryQueryResults]
  );

  const filteredPrograms = useMemo(
    () =>
      filterResolvedPrograms(
        _(nonFilteredPrograms)
          .compact()
          .filter((p) => isUnscheduled(p))
          .value(),
        selectedFilter
      ),
    [selectedFilter, nonFilteredPrograms]
  );

  const onCampaignToggle = (campaignId: string) => {
    if (!isCampaignGrouping) return;
    setExpanded((prev) => {
      const newValue = { ...prev };
      newValue[campaignId] = !prev[campaignId];
      return newValue;
    });
  };

  const cachedFilteredPrograms = useMemoCompare(filteredPrograms, (a, b) => _.isEqual(a, b));

  useEffect(() => {
    if (!containerRef.current) return () => {};
    const draggable = new Draggable(containerRef.current, {
      itemSelector: '.program-calendar__unscheduled-program--draggable',
      eventData: (el) => {
        const program = cachedFilteredPrograms.find(
          (p) => p && p.id === el.getAttribute('data-programid')
        );
        if (!program) return null;
        const programPermissionHelper = getProgramPermissionHelper(user, program);
        return programToEvent(program, programPermissionHelper);
      },
    });
    return () => draggable.destroy();
  }, [cachedFilteredPrograms, user]);

  const applicableVisibleColumns = useMemo(
    () => visibleColumns.filter((c) => !notApplicableColumns.has(c)),
    [visibleColumns]
  );

  const expandedCampaignsSet = useMemo(
    () =>
      new Set(
        Object.entries(expanded)
          .filter(([, expanded]) => expanded)
          .map(([id]) => id)
      ),
    [expanded]
  );
  const addProgramPermissionHelper = useAddProgramPermissionHelper();
  if (!user) return <PleaseRelogin />;

  return (
    <div className="calendar__unscheduled-programs" data-test="calendar__unscheduled-programs">
      <>
        {addProgramPermissionHelper.canCreateProgram() && (
          <div
            className="program-calendar__unscheduled-add-new-no-type"
            onClick={() => onCreate()}
          />
        )}
        <SummaryErrors queryResults={summaryQueryResults} />
        {summaryQueryError && (
          <div
            data-test="calendar__general-loading-error"
            className="alert alert-warning"
            role="alert"
          >
            Could not load programs: {errorToString(summaryQueryError)}
          </div>
        )}
        <div ref={containerRef} className="calendar__unscheduled-programs-list">
          {!isCampaignGrouping && (
            <ProgramsGroupedByTypes
              allPrograms={cachedFilteredPrograms}
              onClick={onClick}
              showCampaigns={showCampaigns}
              applicableVisibleColumns={applicableVisibleColumns}
              user={user}
              onCreate={(typeId) => onCreate({ type: 'typeId', value: typeId })}
            />
          )}
          {isCampaignGrouping && (
            <ProgramsGroupedByCampaigns
              allPrograms={cachedFilteredPrograms}
              allCampaigns={allCampaigns}
              expandedCampaigns={expandedCampaignsSet}
              onToggle={onCampaignToggle}
              onClick={onClick}
              showCampaigns={showCampaigns}
              applicableVisibleColumns={applicableVisibleColumns}
              user={user}
              onCreate={(campaignId) => onCreate({ type: 'campaignId', value: campaignId })}
            />
          )}
        </div>
      </>
    </div>
  );
};
