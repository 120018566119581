import React, { useEffect } from 'react';
import { useQueryClient } from '@tanstack/react-query';
import { useLocalStorage } from '@rehooks/local-storage';
import _ from 'lodash';
import { AuthAction, AuthState } from './authStateTypes';
import getRefreshTokenApi, { RefreshTokenApi } from './authApi';
import { areUsersEqual, createUser, IUserTokens, NonAuthTokens } from '../currentuser/currentUser';
import useAnalytics from '../web-analytics/webAnalyticsContext';
import { analyticsTrack } from '../web-analytics/webAnalytics';
import { AnalyticsEvent } from '../web-analytics/AnalyticsEvent';

type AuthContextProps = {
  state: AuthState;
  dispatch: React.Dispatch<AuthAction>;
  refreshTokenApi: RefreshTokenApi;
};

const initialState: AuthState = {
  user: null,
  tokens: null,
  fromStorage: false,
};

const AuthContext = React.createContext<AuthContextProps>({
  state: initialState,
  dispatch: () => initialState,
  refreshTokenApi: getRefreshTokenApi(() => initialState),
});

export const AuthProvider = (props: React.PropsWithChildren<{}>) => {
  const [storageTokens, setStorageTokens] = useLocalStorage<IUserTokens>('userTokens');
  const queryClient = useQueryClient();
  const analytics = useAnalytics();

  const authReducer = (prevState: AuthState, action: AuthAction): AuthState => {
    switch (action.type) {
      case 'AUTHENTICATED': {
        const { tokens } = action;
        const user = createUser(tokens);
        const tokensAreSame = _.isEqual(prevState.tokens, tokens);
        const userIsSame = areUsersEqual(prevState.user, user);
        logger.info(
          `authReducer. action ${action.type}, userIsSame ${userIsSame}, tokensAreSame ${tokensAreSame}`
        );
        if (tokensAreSame && userIsSame) {
          return prevState;
        }
        return {
          user: userIsSame ? prevState.user : user,
          tokens: tokensAreSame ? prevState.tokens : tokens,
          fromStorage: false,
        };
      }
      case 'TOKEN_REFRESHED': {
        const { tokens } = action;
        const tokensAreSame = _.isEqual(prevState.tokens, tokens);
        logger.info(`authReducer. action ${action.type}, tokensAreSame ${tokensAreSame}`);
        if (tokensAreSame) {
          return prevState;
        }

        return {
          user: prevState.user,
          tokens: action.tokens,
          fromStorage: false,
        };
      }
      case 'STORAGE_CHANGED': {
        const { tokens } = action;
        const user = createUser(tokens);
        const tokensAreSame = _.isEqual(prevState.tokens, tokens);
        const userIsSame = areUsersEqual(prevState.user, user);
        logger.info(
          `authReducer. action ${action.type}, userIsSame ${userIsSame}, tokensAreSame ${tokensAreSame}`
        );
        if (tokensAreSame && userIsSame) {
          return prevState;
        }

        return {
          user: userIsSame ? prevState.user : user,
          tokens: tokensAreSame ? prevState.tokens : tokens,
          fromStorage: true,
        };
      }
      case 'SIGNOUT':
        logger.info(`authReducer. action ${action.type}`);
        if (prevState.user !== null) {
          if (analytics?.user().id()) {
            analyticsTrack(analytics, AnalyticsEvent.USER_SIGNED_OUT);
            analytics?.reset();
          }
          return {
            user: null,
            tokens: null,
            fromStorage: false,
          };
        }
        return prevState;
      default:
        return prevState;
    }
  };

  const [state, dispatch] = React.useReducer(authReducer, {
    user: createUser(storageTokens),
    tokens: storageTokens,
    fromStorage: false,
  });
  useEffect(() => {
    const { user, tokens, fromStorage } = state;
    if (fromStorage) return;

    logger.setUser(user);
    if (!user) {
      logger.pushEvent('User cleared');
      setStorageTokens(NonAuthTokens);
    } else {
      logger.pushEvent('User changed', { token_expiration: `${user.tokenExpiration}` });
      setStorageTokens(tokens!);
    }
  }, [setStorageTokens, state]);

  useEffect(() => {
    const savedUser = createUser(storageTokens);
    dispatch({ type: 'STORAGE_CHANGED', tokens: storageTokens });
    logger.setUser(savedUser);
    if (savedUser) {
      logger.pushEvent('User changed', { token_expiration: `${savedUser.tokenExpiration}` });
    } else {
      logger.pushEvent('User cleared');
    }
  }, [storageTokens]);

  useEffect(() => {
    const { user } = state;

    if (!user) {
      queryClient.resetQueries();
      queryClient.cancelQueries();
      queryClient.invalidateQueries();
    }
  }, [queryClient, state]);

  return (
    <AuthContext.Provider
      value={{
        state,
        dispatch,
        refreshTokenApi: getRefreshTokenApi(dispatch),
      }}
      {...props}
    />
  );
};

export default function useAuth(): AuthContextProps {
  const context = React.useContext(AuthContext);
  if (!context) {
    throw new Error(`useAuth must be used within an AuthContext`);
  }
  return context;
}
