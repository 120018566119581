import { ErrorMessage, Field } from 'formik';

type FormikBooleanInputProps = {
  formikFieldName: string;
  setFieldValue: (field: string, value: Boolean) => void;
  dataTest: string;
};

export const FormikBooleanRadioInput = ({
  formikFieldName,
  setFieldValue,
  dataTest,
}: FormikBooleanInputProps) => (
  <>
    <div role="group" aria-labelledby="true of false" className="program-edit__input">
      <label className="programs-filter__radio-option">
        <Field
          type="radio"
          name={formikFieldName}
          value={true}
          onChange={() => setFieldValue(formikFieldName, true)}
        />
        <span>True</span>
      </label>
      <label className="programs-filter__radio-option">
        <Field
          type="radio"
          name={formikFieldName}
          value={false}
          onChange={() => setFieldValue(formikFieldName, false)}
        />
        <span>False</span>
      </label>
    </div>
    <ErrorMessage
      component="div"
      name={formikFieldName}
      className="invalid-feedback"
      data-test={`${dataTest}-validation-error`}
    />
  </>
);
