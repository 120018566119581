import { ProgramAttachment } from '../types';
import { useMutation } from '@tanstack/react-query';
import { useQueryCacheManager } from '../../hooks/useQueryCacheManager';
import {
  createAttachmentContentQueryParams,
  ProgramAttachmentWithUrl,
} from './useAttachmentsContent';
import useApi from '../../api/backendApiContext';
import { useNotification } from '../../notification/notificationsContext';

import { downloadFile } from '../../util/file-utils';

export const useAttachmentDownloadMutation = () => {
  const cacheManager = useQueryCacheManager();
  const { programApi } = useApi();
  const { notifyError } = useNotification();

  return useMutation(
    async (props: { programId: string; attachment: ProgramAttachment }) => {
      const { programId, attachment } = props;
      const queryParams = createAttachmentContentQueryParams(
        programApi,
        cacheManager.getProgramAttachmentContentCacheName(programId, attachment.id),
        programId,
        attachment,
        true
      );
      const response: ProgramAttachmentWithUrl = await cacheManager.fetchQuery(queryParams);
      if (!response.url) throw Error('Failed to download the file');
      downloadFile(response.url, attachment.name);
      logger.info(`${attachment.name} Downloaded`);
    },
    {
      onError: (err, variables) => {
        notifyError({
          err,
          notificationMsg: `Failed to download the file`,
          logMsg: `Failed to download the file ${variables.attachment.name}`,
        });
      },
    }
  );
};
