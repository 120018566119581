import { useMutation } from '@tanstack/react-query';
import { PromptMark } from '../../types';
import useAuth from '../../../auth/authContext';
import useApi from '../../../api/backendApiContext';
import { useQueryCacheManager } from '../../../hooks/useQueryCacheManager';

export const useUpdatePromptMarkMutation = () => {
  const {
    state: { user },
  } = useAuth();
  const { growegyAIApi } = useApi();
  const queryCacheManager = useQueryCacheManager();

  return useMutation(
    async (promptMark: PromptMark) => {
      if (!user || !promptMark.promptId) return null;
      return growegyAIApi.updatePromptMark(user.userId, promptMark);
    },
    {
      onMutate: (promptMark) => {
        if (!promptMark) return;
        queryCacheManager.updatePromptMark(promptMark);
      },
      onError: async () => {
        await queryCacheManager.invalidatePromptMarks();
      },
    }
  );
};
