import useApi from '../../../api/backendApiContext';
import { useQuery } from '@tanstack/react-query';
import { useQueryCacheManager } from '../../../hooks/useQueryCacheManager';
import useAuth from '../../../auth/authContext';
import { ReactQueryConstants } from '../../../commons/constants';
import { QueryStatus } from '../../../commons/components/QueryStatus';

export const useFetchedPromptVariables = () => {
  const { growegyAIApi } = useApi();
  const {
    state: { user },
  } = useAuth();
  const queryCacheManager = useQueryCacheManager();
  const queryKey = queryCacheManager.getPromptVariablesCacheKey();
  const query = useQuery(
    queryKey,
    async () => {
      if (!user) {
        return [];
      }
      return growegyAIApi.getVariables(user.userId);
    },
    {
      staleTime: ReactQueryConstants.promptVariablesStaleTime,
      refetchInterval: ReactQueryConstants.promptVariablesRefetchInterval,
      refetchOnWindowFocus: true,
    }
  );
  const queryStatusComponent = (
    <QueryStatus
      queryKey={queryKey}
      queryStatus={query.status}
      dataTestPrefix="chats"
      failedToLoadText="Something went wrong while loading the prompt variables."
    />
  );

  return { query, queryStatusComponent };
};
