export type IntegrationAttributeType = 'string' | 'secure-string';

export const INTEGRATION_STATE_CONNECT = 'Connect';
export const INTEGRATION_STATE_ACTIVE = 'Active';
export const INTEGRATION_STATE_DISCONNECTED = 'Disconnected';
export type IntegrationState =
  | typeof INTEGRATION_STATE_CONNECT
  | typeof INTEGRATION_STATE_ACTIVE
  | typeof INTEGRATION_STATE_DISCONNECTED;

export type IntegrationAttribute = {
  name: string;
  value: string;
};

export type Integration = {
  id: string;
  attributes: Array<IntegrationAttribute>;
  version?: number;
};

export type ResolvedIntegrationAttribute = IntegrationAttribute & {
  type: IntegrationAttributeType;
};

export type ResolvedIntegration = {
  id: string;
  name: string;
  description: string;
  icon: string;
  version?: number;
  attributes: Array<ResolvedIntegrationAttribute>;
};
