import { readFaroConfig } from '../../commons/config';
import FaroSpaLogger from './faroSpaLogger';

export default class FaroSpaLoggerInit {
  public static init() {
    const config = readFaroConfig();

    global.logger = new FaroSpaLogger(config);
  }
}
