import { useFetchedProgramTypes } from './useFetchedProgramTypes';
import useAuth from '../../auth/authContext';
import { useFetchedUsers } from '../../user/hooks/useFetchedUsers';
import { join } from 'lodash';

export const useProgramsChatContext = () => {
  const { data: users } = useFetchedUsers();
  const {
    state: { user },
  } = useAuth();
  const { data: programTypes } = useFetchedProgramTypes();

  if (!users || !user || !programTypes) return null;

  const additionalTimeDate = `Current user local time: ${new Date()}.\n\n`;
  const additionalUsersContext = `All existing users: \n${join(
    users.map(
      (x) =>
        `* ${x.firstName ? `first name: ${x.firstName}, ` : ''}${
          x.lastName ? `last name: ${x.lastName}, ` : ''
        }email: ${x.email}, id: "${x.id}"`
    ),
    ',\n'
  )}\n\n`;

  const additionalCurrentUserContext = `Current user id is: "${user.userId}"\n\n`;

  const additionalProgramTypesContext = `Program types: \n${join(
    programTypes.types.map((x) => `* Name: ${x.name}, id: "${x.id}"`),
    ',\n'
  )}\n\n`;

  const speedUpContext =
    'Important: do not take long to respond, respond to prompts within 30 seconds!\n';

  return (
    additionalTimeDate +
    additionalUsersContext +
    additionalCurrentUserContext +
    additionalProgramTypesContext +
    speedUpContext
  );
};
