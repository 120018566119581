import React, { ChangeEvent, useEffect } from 'react';
import { ResolvedProgramTask } from '../../types';
import { CellProps } from './CellProps';
import clsx from 'clsx';
import { useTextAreaWithAutoHeightStateRef } from '../../../hooks/useTextAreaWithAutoHeightRef';

const BorderHeight = 2;

export const TaskNameCell = ({
  value: origValue,
  cell,
  row,
  arrayHelpers,
  isReadonly,
  focus,
}: CellProps<ResolvedProgramTask>) => {
  const { value, setValue, textAreaRef } = useTextAreaWithAutoHeightStateRef({
    borderHeight: BorderHeight,
    origValue: origValue ?? '',
  });

  useEffect(() => {
    if (!textAreaRef.current || !focus) return;
    textAreaRef.current.focus();
  }, [textAreaRef, focus]);

  const handleChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    const { value } = e.target;
    setValue(value);
  };

  const confirm = () => {
    if (value !== origValue) {
      const field = cell.column.id as keyof typeof row.original;
      arrayHelpers.replace(Number(row.id), { ...row.original, [field]: value });
    }
  };

  const cancel = () => setValue(origValue);

  return (
    <div className="tasks-table__name-cell">
      <textarea
        className={clsx('tasks-table__task-name-textarea', 'text-wrap', {
          'tasks-table__task-name-textarea--readonly': isReadonly,
          'tasks-table__input--done': row.values.status === 'done',
        })}
        data-test="tasks-table__task-name"
        placeholder={isReadonly ? '' : 'Add task'}
        value={value}
        maxLength={255}
        onBlur={confirm}
        onChange={handleChange}
        onKeyDown={(e) => {
          if (e.key === 'Escape') cancel();
          if (e.key === 'Enter') {
            e.preventDefault();
            confirm();
          }
        }}
        ref={textAreaRef}
        readOnly={isReadonly}
      />
    </div>
  );
};
